<template>
  <BaseSheet
    :color="!image ? 'grey lighten-3' : 'transparent'"
    class="d-flex justify-center align-center relative"
    :style="formStyle"
  >
    <BaseLinearLoader
      :active="isEditingCourse"
      indeterminate
      class="absolute top"
    />
    <BaseSheet
      v-if="errorMessages"
      class="absolute bottom ma-1 pa-2"
      color="white"
    >
      <span class="text-caption error--text font-weight-bold">{{
        errorMessagesfrontend /
        src /
        modules /
        Editor /
        Components /
        CourseDetailImageForm.vue
      }}</span>
    </BaseSheet>
    <form ref="form" class="d-none">
      <input
        ref="imageInput"
        type="file"
        accept="image/jpeg,image/png,image/jpg"
        @change="onFileChange"
      />
    </form>
    <BaseButton
      v-if="!image && !archived"
      v-show="!fileLocalUrl || errorMessages"
      icon="$mdiPlus"
      color="grey"
      small
      @click="focusImageInput()"
    >
      {{ $t("utils.addCoverPicture") }}
    </BaseButton>
    <div v-if="image">
      <BaseSheet
        color="grey lighten-3"
        class="image-action edit rounded-b-circle rounded-t-circle mr-12 elevation-4"
      >
        <BaseButtonIcon
          v-if="!archived"
          icon="$mdiPencilOutline"
          color="primary"
          @click="focusImageInput()"
        />
      </BaseSheet>
    </div>
  </BaseSheet>
</template>

<script>
export default {
  name: "CourseDetailImageForm",
  props: {
    image: {
      type: String,
      required: false,
      default: () => "",
    },
    archived: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      errorMessages: "",
      fileLocalUrl: "",
      isEditingCourse: false,
    };
  },
  computed: {
    imageErrors() {
      let errors = [];
      if (this.image && !/.jpg$|.jpeg$|.png$/.test(this.image.type))
        errors.push(this.$t("utils.pleaseProvideAnImage"));
      if (this.image && this.image.size > 25000000)
        errors.push(this.$t("utils.imageTooBig", { size: "4" }));
      return errors;
    },
    formStyle() {
      if (!this.fileLocalUrl)
        return this.image
          ? {
              backgroundImage: "url(" + this.image + ")",
              backgroundSize: "cover",
            }
          : {};
      return {
        backgroundImage: "url(" + this.fileLocalUrl + ")",
        backgroundSize: "cover",
      };
    },
  },
  watch: {
    isEditingCourse(val) {
      if (!val) {
        return (this.fileLocalUrl = "");
      }
    },
  },
  methods: {
    focusImageInput() {
      this.$refs.imageInput.click();
      this.errorMessages = "";
      this.fileLocalUrl = "";
    },
    removeImage() {
      this.$store
        .dispatch("course/editCourseImage", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          image: new File(),
        })
        .then(() => {
          this.$emit("delete-image");
          this.errorMessages = "";
          this.fileLocalUrl = "";
        });
    },
    onFileChange(event) {
      if (event.target.files[0]) {
        const file = event.target.files[0];
        if (!/.jpg$|.jpeg$|.png$/.test(file.type)) {
          return (this.errorMessages = this.$t("utils.pleaseProvideAnImage"));
        }
        this.fileLocalUrl = URL.createObjectURL(file);
        if (file.size > 4000000) {
          return (this.errorMessages = this.$t("utils.imageTooBig", {
            size: "4",
          }));
        }
        this.errorMessages = "";
        this.editCourseImage(file);
        this.$refs.imageInput.value = "";
      }
    },
    editCourseImage(image) {
      this.isEditingCourse = true;
      this.$store
        .dispatch("course/editCourseImage", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          image,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.editImageConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        })
        .finally(() => {
          this.isEditingCourse = false;
        });
    },
  },
};
</script>

<style scoped>
.image-action {
  position: absolute;
  top: 10px;
}
.image-action.edit {
  right: -40px;
}
</style>
