<template>
  <div>
    <Board class="my-6">
      <template slot="header">
        <div class="d-flex justify-space-between align-center">
          <BaseTitle class="d-block" v-text="$tc('campaign.campaign', 2)" />
        </div>
      </template>
      <template slot="main">
        <BaseDataTable
          :headers="CAMPAIGNS_LIST_HEADER"
          :items="campaigns"
          :loading="isFetchingCampaigns"
          :hide-default-footer="true"
          :loading-text="$t('utils.loadingData')"
          class="elevation-1 mb-6"
        >
          <template #item="{ item }">
            <tr :class="{ 'row--link': user.isStaff }">
              <td>
                <a
                  v-if="user.isStaff"
                  :href="`/organization/${activeOrganization.id}/course/${item.course.id}/campaign/${item.id}/`"
                >
                  <BaseParagraph text="primary" v-text="item.name" />
                </a>
                <BaseParagraph v-else text="primary" v-text="item.name" />
              </td>
              <td>
                <a
                  v-if="user.isStaff"
                  :href="`/organization/${activeOrganization.id}/course/${item.course.id}/campaign/${item.id}/`"
                >
                  <div v-if="item.assign">
                    <BaseParagraph
                      class="mb-2 d-block"
                      v-text="item.assign.email"
                    />
                    <BaseDescription
                      class="d-block"
                      text="primary"
                      v-text="
                        collaboratorName(item)
                      "
                    />
                  </div>
                </a>
                <div v-else-if="item.assign">
                  <BaseParagraph
                    class="mb-2 d-block"
                    v-text="item.assign.email"
                  />
                  <BaseDescription
                    class="d-block"
                    text="primary"
                    v-text="collaboratorName(item)"
                  />
                </div>
              </td>
              <td>
                <a
                  v-if="user.isStaff"
                  :href="`/organization/${activeOrganization.id}/course/${item.course.id}/campaign/${item.id}/`"
                >
                  <BaseMarkdownViewer
                    :content="truncateDescription(item.course.description)"
                  />
                </a>
                <BaseMarkdownViewer
                  v-else
                  :content="truncateDescription(item.course.description)"
                />
              </td>
              <td>
                <a
                  v-if="user.isStaff"
                  :href="`/organization/${activeOrganization.id}/course/${item.course.id}/campaign/${item.id}/`"
                >
                  <div class="d-flex justify-center align-center">
                    <BaseChip
                      v-if="item.status == 'CLOSED'"
                      type="primary"
                      target="_blank"
                    >
                      {{ $tc("campaign.status.closed") }}
                    </BaseChip>
                    <BaseChip
                      v-else-if="item.status == 'DRAFT'"
                      type="tertiary"
                      target="_blank"
                    >
                      {{ $tc("campaign.status.draft") }}
                    </BaseChip>
                    <BaseChip
                      v-else-if="item.status == 'IN_PROGRESS'"
                      type="secondary"
                      target="_blank"
                    >
                      {{ $tc("campaign.status.launched") }}
                    </BaseChip>
                  </div>
                </a>
                <div v-else class="d-flex justify-center align-center">
                  <BaseChip
                    v-if="item.status == 'CLOSED'"
                    type="primary"
                    target="_blank"
                  >
                    {{ $tc("campaign.status.closed") }}
                  </BaseChip>
                  <BaseChip
                    v-else-if="item.status == 'DRAFT'"
                    type="tertiary"
                    target="_blank"
                  >
                    {{ $tc("campaign.status.draft") }}
                  </BaseChip>
                  <BaseChip
                    v-else-if="item.status == 'IN_PROGRESS'"
                    type="secondary"
                    target="_blank"
                  >
                    {{ $tc("campaign.status.launched") }}
                  </BaseChip>
                </div>
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "../Board";

import { getOrganizationCampaigns } from "../../Services/organizationDashboard.service";

import { mapState } from "vuex";

export default {
  name: "OrganizationDashboardCampaigns",
  components: {
    Board,
  },
  data() {
    return {
      campaigns: [],
      isFetchingCampaigns: false,
      CAMPAIGNS_LIST_HEADER: [
        {
          text: this.$tc("utils.name", 1),
          value: "name",
        },
        {
          text: this.$tc("campaign.manager"),
          value: "responsable",
        },
        {
          text: this.$tc("utils.description", 1),
          value: "fullname",
          width: "50%",
          sortable: false,
        },
        {
          text: this.$tc("utils.status"),
          value: "function",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
   
  },
  watch: {
    "activeOrganization.id"() {
      this.reset();
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    collaboratorName(item){
      if(!item?.assign) return "";
      return `${item.assign?.firstname} ${item.assign?.lastname}`;
    },
    reset() {
      this.campaigns = [];
      this.isFetchingCampaigns = false;
    },
    initialize() {
      this.isFetchingCampaigns = true;
      this.fetchCampaigns(1);
    },
    async fetchCampaigns(page) {
      const res = await getOrganizationCampaigns(
        this.activeOrganization.id,
        page
      );
      this.campaigns = res.data.results;

      if (res.data.next) {
        this.fetchCampaigns(res.data.next);
      }
      this.isFetchingCampaigns = false;
    },
    truncateDescription(description) {
      return description.length > 200
        ? description.substring(0, 100) + "..."
        : description;
    },
  },
};
</script>
