<template>
  <span>
    <a v-if="url" :href="url" v-bind="$attrs" v-on="$listeners">
      <slot />
    </a>
    <router-link v-else :to="to">
      <slot />
    </router-link>
  </span>
</template>

<script>
export default {
  name: "BaseFrontOfficeHyperlink",
  props: {
    url: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object, null],
      default: null,
    },
  },
};
</script>

<style scoped>
a {
  display: inline-block;
  background: var(--cosmo-gradient-secondary);
  background-clip: content-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  border-image: linear-gradient(147.45deg, #2f479d 19.48%, #ff5b60 144.95%);
  border-image-slice: 1;
}
</style>
