var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Board',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('BaseTitle',{staticClass:"mb-4",domProps:{"textContent":_vm._s(_vm.$tc('organization.campaign', 10))}}),_c('BaseParagraph',{attrs:{"text":"secondary"},domProps:{"textContent":_vm._s(_vm.$t('course.campaignDescription'))}})]},proxy:true},{key:"main",fn:function(){return [_c('BaseDataTable',{staticClass:"elevation-1",attrs:{"items":_vm.campaigns,"loading":_vm.isFetchingCourseCampaign,"headers":_vm.CAMPAIGNS_HEADER,"no-data-text":_vm.$tc('organization.campaign', 0)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                name: 'campaignDetail',
                params: {
                  organizationId: _vm.$route.params.organizationId,
                  courseId: _vm.$route.params.courseId,
                  campaignId: item.id,
                },
              })}}},[_c('td',[_c('BaseDescription',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('td',{staticClass:"text-center"},[(Number.parseInt(item.assign.id))?_c('div',[_c('BaseDescription',{domProps:{"textContent":_vm._s(item.assign.email)}})],1):_c('div',[_c('BaseDescription',{attrs:{"text":"disabled","italic":""},domProps:{"textContent":_vm._s(_vm.$t('answer.noContent'))}})],1)]),_c('td',{staticClass:"text-center"},[(item.description)?_c('BaseDescription',{domProps:{"textContent":_vm._s(
                  item.description.length > 50
                    ? item.description.substring(0, 49) + '...'
                    : item.description
                )}}):_c('BaseDescription',{attrs:{"text":"disabled","italic":""},domProps:{"textContent":_vm._s(_vm.$t('answer.noContent'))}})],1),_c('td',{staticClass:"text-center"},[(/^DRAFT$/.test(item.status))?_c('BaseChip',{attrs:{"type":"tertiary"}},[_vm._v(" "+_vm._s(_vm.$tc("campaign.status.draft"))+" ")]):(/^IN_PROGRESS$/.test(item.status))?_c('BaseChip',{attrs:{"type":"secondary"}},[_vm._v(" "+_vm._s(_vm.$tc("campaign.status.launched"))+" ")]):(/^CLOSED$/.test(item.status))?_c('BaseChip',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.$tc("campaign.status.closed"))+" ")]):_vm._e()],1)])]}}])})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center"},[(!_vm.course.archived)?_c('BaseButton',{attrs:{"disabled":!_vm.course.isValid,"icon":"$mdiPlus"},on:{"click":function () {
              _vm.$store.commit('course/setCampaignCreateAndEditDialog', true);
            }}},[_vm._v(" "+_vm._s(_vm.$t("campaign.new"))+" ")]):_vm._e()],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }