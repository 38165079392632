import { render, staticRenderFns } from "./FrontofficeLayout.vue?vue&type=template&id=9426df1e&scoped=true"
import script from "./FrontofficeLayout.vue?vue&type=script&lang=js"
export * from "./FrontofficeLayout.vue?vue&type=script&lang=js"
import style0 from "./FrontofficeLayout.vue?vue&type=style&index=0&id=9426df1e&prod&lang=css"
import style1 from "./FrontofficeLayout.vue?vue&type=style&index=1&id=9426df1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9426df1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VAppBarNavIcon,VMain})
