<template>
  <div>
    <BaseModal
      :value="dialogState"
      :modal-title="$t('folder.assignOwnerForm.title')"
      is-complex
      width="900"
      @close="reset"
    >
      <template #modal>
        <v-autocomplete
          v-model="selected"
          :loading="contactsFilters.loading"
          :filter="() => true"
          :search-input.sync="contactsFilters.search"
          :menu-props="{ offsetY: true }"
          :items="contacts"
          item-text="email"
          item-value="id"
          dense
          small-chips
          outlined
          active
        >
          <template #selection="{ item }">
            <v-chip color="primary" label small>
              <span class="pr-2">
                {{ item.email }}
              </span>
              <v-icon small @click="() => (selected = null)">
                {{ "$mdiClose" }}
              </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton
          :disabled="isInvalid"
          type="primary"
          @click="confirmAndSubmit()"
        >
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
    <FolderConfirmChangeContactDialog
      :dialog-state="openConfirmDialog"
      :email="email"
      @close="openConfirmDialog = false"
      @validate="submit(true)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClientAPI from "@/modules/Client/Services/client.api";
import FolderConfirmChangeContactDialog from "./FolderConfirmChangeContactDialog.vue";

export default {
  name: "FolderChangeOwnerDialog",
  components: {
    FolderConfirmChangeContactDialog,
  },
  data: function () {
    return {
      selected: -1,
      folderId: null,
      contacts: [],
      isFetchingContacts: false,
      openConfirmDialog: false,
      email: "",
      contactsFilters: {
        loading: false,
        page: 1,
        pageSize: 10,
        search: "",
        next: undefined,
        searchTimeoutRef: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isFolderChangeClient,
      activeFolder: (state) => state.folder.activeFolder,
    }),
    isInvalid() {
      return !this.selected || this.selected < 0;
    },
  },

  watch: {
    async dialogState(val) {
      if (val) {
        await this.fetchOrganizationContacts();
      }
      if (val && this.selected == -1) {
        this.selected = this.activeFolder?.client?.id;
      }
    },
    "contactsFilters.search"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterOrganizationContacts();
    },
  },
  methods: {
    reset() {
      this.$store.commit("folder/setFolderChangeClient", false);
    },
    confirmAndSubmit() {
      if (this.activeFolder.client && !this.activeFolder.client.isTemporary) {
        this.email = this.contacts.find((c) => c.id == this.selected)?.email;
        this.openConfirmDialog = true;
        return;
      }
      this.submit();
    },
    async submit(featchWorkflow) {
      this.openConfirmDialog = false;
      let organizationId = this.$route.params.organizationId;
      let folderId = this.$route.params.folderId;
      let payload = { client: this.selected };
      if (this.selected !== -1)
        try {
          await this.$store.dispatch("folder/updateFolder", {
            organizationId,
            folderId,
            payload,
          });
          if (featchWorkflow) await this.fetchWorkflow();
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.ownerAction.success"),
            type: "SUCCESS",
          });
        } catch {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.ownerAction.error"),
            type: "ERROR",
          });
        }
      this.reset();
    },
    async fetchOrganizationContacts() {
      this.contactsFilters.loading = true;
      try {
        this.contactsFilters.page = 1;
        const res = await ClientAPI.getOrganizationClients(
          this.$route.params.organizationId,
          this.contactsFilters.page,
          this.contactsFilters.pageSize,
          this.contactsFilters.search
        );
        this.contacts = [...res.data.results];
        this.contactsFilters.next = res.data.next;
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.contactsFilters.loading = false;
    },
    filterOrganizationContacts() {
      if (this.contactsFilters.searchTimeoutRef)
        clearTimeout(this.contactsFilters.searchTimeoutRef);
      this.contactsFilters.searchTimeoutRef = setTimeout(async () => {
        this.fetchOrganizationContacts();
      }, 500);
    },
    async fetchWorkflow() {
      if (!this.activeFolder?.workflow) return;
      this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", null);
      await this.$store.dispatch("workflow/fetchActiveWorkflow", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.activeFolder?.workflow,
      });
    },
  },
};
</script>
<style scoped>
.select-width {
  width: 100%;
}
</style>
