<template>
  <div>
    <div class="d-flex align-center">
      <BaseAvatar
        v-if="user"
        :size="78"
        class="mr-6 flex-shrink-0"
        :src="user.avatar ? user.avatar : null"
      />
      <BaseTitle v-if="user">
        <span v-text="$t('messages.help.welcome')" />
        &nbsp;
        <span class="primary--text" v-text="user.firstName" />
      </BaseTitle>
    </div>
    <DashboardMyInvitations color="grey" />
  </div>
</template>

<script>
import DashboardMyInvitations from "../Components/DashboardMyInvitations";

import { mapState } from "vuex";
export default {
  name: "Dashboard",
  components: {
    DashboardMyInvitations,
  },
  computed: {
    ...mapState({
      organizations: (state) => state.organization.organizations,
      user: (state) => state.account.user,
    }),
  },
  created() {
    this.redirectToFolderList();
  },
  methods: {
    redirectToFolderList() {
      if (this.organizations.length) {
        return this.$router.push({
          name: "folderList",
          params: {
            organizationId: this.organizations[0]?.id,
          },
        });
      }
    },
  },
};
</script>
