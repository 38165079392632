import CampaignAPI from "./campaign.api";

export function readAllCampaign(organization = null, status = null) {
  return CampaignAPI.readAllCampaign(organization, status);
}

export function getOrganizationCampaigns(organizationId) {
  return CampaignAPI.getOrganizationCampaigns(organizationId);
}
export function getOrganizationCampaignsServices(organizationId) {
  return CampaignAPI.getOrganizationCampaingnServices(organizationId);
}
export function postRelaunchInvitationRecordDisagreedFromCampaign({
  organizationId,
  courseId,
  recordId,
  payload,
  campaignId,
}) {
  return CampaignAPI.postRelaunchInvitationRecordDisagreedFromCampaign({
    organizationId,
    courseId,
    recordId,
    payload,
    campaignId,
  });
}
export function postCreateCopyCampaignAnonymousLink({
  organizationId,
  courseId,
  recordId,
  campaignId,
  settings,
}) {
  return CampaignAPI.postCreateCopyCampaignAnonymousLink({
    organizationId,
    courseId,
    recordId,
    campaignId,
    settings,
  });
}
export function postRelaunchInvitationRecordInProgressFromCampaign({
  organizationId,
  courseId,
  recordId,
  payload,
  campaignId,
}) {
  return CampaignAPI.postRelaunchInvitationRecordInProgressFromCampaign({
    organizationId,
    courseId,
    recordId,
    payload,
    campaignId,
  });
}
