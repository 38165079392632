let plugins = [];

// Import Stores
import WalletCourse from "./walletCourse";
import WalletOrganization from "./walletOrganization";

let stores = {
  walletCourse: WalletCourse,
  walletOrganization: WalletOrganization,
};

export { plugins, stores };
