<template>
  <v-layout v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-layout>
</template>

<script>
export default {
  name: "BaseLayout",
};
</script>
