<template>
  <div
    class="container py-2 d-flex justify-center align-center"
    :style="`min-height:${size}px;`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "WalletToolShow",
  props: {
    size: {
      type: String,
      default: "60",
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #fff0eb;
  min-height: 48px;
  padding: 6px 8px;
  border-radius: 10px;
}
</style>
