import personalDataAPI from "./personalData.api";
import PersonalData from "@/core/Models/PersonalData";

export async function fetchOrganizationPersonalData({ organizationId }) {
  let page = 1;
  let results = [];
  while (page) {
    const res = await personalDataAPI.getOrganizationPersonalData({
      organizationId,
      page: 1,
    });
    results = [...results, ...res.data.results];
    page = res.data.next;
  }
  return results.map((r) => new PersonalData(r));
}
