<template>
  <v-app-bar flat class="cosmo-wallet-top-bar d-none d-sm-block">
    <router-link
      v-if="isAuthenticated"
      class="cosmo-app-bar-link d-sm-flex left-slider-on-hover ml-auto mt-7"
      :to="{ name: 'WalletAccount' }"
    >
      <BaseImage
        :src="
          $findStatic(
            $route.name === 'WalletAccount'
              ? 'cosmoWalletActiveProfile'
              : 'cosmoWalletProfileDark'
          )
        "
      />
      <span
        class="align-self-center ml-3"
        :class="{ active_link: $route.name === 'WalletAccount' }"
        v-text="$t('navigation.myAccount')"
      />
    </router-link>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TheFrontOfficeAppBarAccount",

  computed: {
    ...mapState({
      isAuthenticated: (state) => state.account.isAuthenticated,
    }),
  },
};
</script>

<style scoped>
.cosmo-wallet-top-bar {
  z-index: 100;
  background-color: #ffffff !important;
  height: 90px !important;
  position: sticky;
  top: 0;
}
@media (max-width: 599px) {
  .cosmo-wallet-main-wrapper {
    height: 150px !important;
  }
}

.cosmo-app-bar-link {
  text-decoration: none;
}
.left-slider-on-hover {
  transition: all 0.2s ease-in-out;
  position: relative;
}
.left-slider-on-hover::before,
.hover::after {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 0px;
  height: 3px;
  transition-duration: 0.75s;
  opacity: 0;
  left: 0;
  background-color: #ff5621;
  border-radius: 2px;
}
.left-slider-on-hover:hover {
  cursor: pointer;
}
.left-slider-on-hover:hover::after,
.left-slider-on-hover:hover::before {
  width: 100%;
  opacity: 1;
}
.active_link {
  color: #ff5621;
}
</style>
