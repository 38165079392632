import { render, staticRenderFns } from "./LoginSignupLayout.vue?vue&type=template&id=7c7104fa&scoped=true"
import script from "./LoginSignupLayout.vue?vue&type=script&lang=js"
export * from "./LoginSignupLayout.vue?vue&type=script&lang=js"
import style0 from "./LoginSignupLayout.vue?vue&type=style&index=0&id=7c7104fa&prod&lang=css"
import style1 from "./LoginSignupLayout.vue?vue&type=style&index=1&id=7c7104fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7104fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
