<template>
  <v-snackbar v-bind="$attrs" v-on="$listeners">
    <template #action>
      <slot name="action" />
    </template>
    <slot />
  </v-snackbar>
</template>

<script>
export default {
  name: "BaseSnackbar",
};
</script>
