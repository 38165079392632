var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._t("activators")],2)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"item-list justify-center"},[_c('div',{staticClass:"title-container"},[_c('BaseCaption',{attrs:{"text":"secondary","normal-size":""},domProps:{"textContent":_vm._s(
            _vm.docxTemplates.length
              ? _vm.$t('utils.chooseTemplate')
              : _vm.$t('utils.noTemplate')
          )}}),(_vm.docxTemplates.length)?_c('div',{staticClass:"underline-div"}):_vm._e()],1)]),_vm._l((_vm.docxTemplates),function(template,index){return _c('v-list-item',{key:index,staticClass:"item-list"},[_c('div',{staticClass:"content",on:{"click":function($event){$event.stopPropagation();return _vm.generateDocument(template)}}},[_c('BaseParagraph',{attrs:{"type":"primary"},domProps:{"textContent":_vm._s(template.name)}})],1)])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }