import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Record > Answer - API
  getRecordQuestionSuggestion({ organizationId, datatypeSlug }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/datatype/${datatypeSlug}/suggestion/`
    );
  },
  patchAnswer(answerId, payload) {
    return cosmoAPI.patch(`/answer/${answerId}/`, payload);
  },
};
