import datatypeAPI from "@/core/Services/datatype.api";

import Datatype from "@/core/Models/Datatype";

export default {
  namespaced: true,
  state: {
    datatypes: [],
  },
  mutations: {
    SET_DATATYPES(state, payload) {
      state.datatypes = payload
        ? payload.map((datatype) => new Datatype(datatype))
        : [];
    },
  },
  actions: {
    async fetchDatatypes({ commit, state }) {
      if (!state.datatypes.length) {
        let page = 1;
        let datatypes = [];
        do {
          const res = await datatypeAPI.getDatatypes({ page });
          datatypes = [...datatypes, ...res.data.results];
          page = res.data.next;
        } while (page);
        commit("SET_DATATYPES", datatypes);
      }
      return state.datatypes;
    },
  },
};
