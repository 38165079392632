<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiEyeOutline } from "@mdi/js";
export default {
  name: "MdiEyeOutline",
  data() {
    return {
      svgPath: mdiEyeOutline,
    };
  },
};
</script>
