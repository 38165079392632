<template>
  <div>
    <div v-if="isFetchingFolderOffers">
      <v-skeleton-loader type="image, list-item-avatar-three-line" />
    </div>
    <div v-else>
      <BaseFrontOfficeExpansionPanels
        v-if="walletOffers.length"
        v-model="expansionPanelsValue"
        class="mb-4"
        multiple
      >
        <BaseFrontOfficeExpansionPanel
          v-for="offer in walletOffers"
          :id="`offer-${offer.id}`"
          :key="'offer' + offer.id"
          expand
          type="primary"
        >
          <template slot="header">
            <div class="header">
              <div class="left_section">
                <BaseFrontOfficeSubtitle uppercase v-text="offer.title" />
                <BaseFrontOfficeDescription
                  uppercase
                  v-text="
                    `${$t('folder.createDateAt')} ${$d(
                      offer.dateCreated,
                      'detailed'
                    )}`
                  "
                />
              </div>
              <div class="right_section">
                <v-chip :color="`${getStatus(offer, 'color')} lighten-5`">
                  <BaseParagraph
                    text="secondary"
                    :color="getStatus(offer, 'color')"
                    v-text="getStatus(offer, 'text')"
                  />
                </v-chip>
              </div>
            </div>
          </template>
          <template slot="content">
            <WalletOfferItem :offer="offer" />
          </template>
        </BaseFrontOfficeExpansionPanel>
      </BaseFrontOfficeExpansionPanels>
      <WalletToolShow v-else size="100">
        <BaseParagraph
          class="font"
          style="font-size: 1.1em !important"
          v-text="$t('folder.records.noOffers')"
        />
      </WalletToolShow>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WalletOfferItem from "./WalletOfferItem.vue";
import WalletToolShow from "./WalletToolShow.vue";
export default {
  name: "WalletOffers",
  components: { WalletOfferItem, WalletToolShow },
  data() {
    return {
      expansionPanelsValue: [0],
    };
  },
  computed: {
    ...mapState({
      walletOffers: (state) => state.walletCourse.folderOffers,
      isFetchingFolderOffers: (state) =>
        state.walletCourse.isFetchingFolderOffers,
    }),
  },

  watch: {
    walletOffers: {
      handler() {
        if (!this.$route.query.offer_id) return;
        const indexOfOffer = this.walletOffers.findIndex(
          (offer) => offer.id === +this.$route.query.offer_id
        );
        return (this.expansionPanelsValue = [indexOfOffer]);
      },
      immediate: true,
      deep: true,
    },
  },

  updated() {
    this.focusOfferItem();
  },
  methods: {
    focusOfferItem() {
      const element = document.getElementById(
        `offer-${this.$route.query.offer_id}`
      );
      element?.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });
    },
    getStatus(offer, type) {
      switch (offer.offerType) {
        case "CONSULT": {
          if (offer.clients.status === "PENDING")
            return type === "color"
              ? "grey"
              : this.$t("folder.offerStatus.waitingForConsultation");
          return type === "color"
            ? "purple"
            : this.$tc("folder.offerStatus.offerConsulted", 0);
        }
        case "VALIDATION": {
          if (offer.clients.status === "PENDING")
            return type === "color"
              ? "grey"
              : this.$t("folder.offerStatus.waitingForValidation");
          if (offer.clients.status === "ACCEPT")
            return type === "color"
              ? "green"
              : this.$tc("folder.offerStatus.offerValidated", 0);
          return type === "color"
            ? "red"
            : this.$tc("folder.offerStatus.offerDeclined", 0);
        }
        case "MIXTE": {
          switch (offer.clients.action) {
            case "CONSULT": {
              if (offer.clients.status === "PENDING")
                return type === "color"
                  ? "grey"
                  : this.$t("folder.offerStatus.waitingForConsultation");
              return type === "color"
                ? "purple"
                : this.$tc("folder.offerStatus.offerConsulted", 0);
            }
            case "VALIDATION": {
              if (offer.clients.status === "PENDING")
                return type === "color"
                  ? "grey"
                  : this.$t("folder.offerStatus.waitingForValidation");
              if (offer.clients.status === "ACCEPT")
                return type === "color"
                  ? "green"
                  : this.$tc("folder.offerStatus.offerValidated", 0);
              return type === "color"
                ? "red"
                : this.$tc("folder.offerStatus.offerDeclined", 0);
            }
            default: {
              if (offer.clients.status === "PENDING")
                return type === "color"
                  ? "orange"
                  : this.$t("folder.offerStatus.waitingForSignature");
              if (offer.clients.status === "ACCEPT" || offer.signedFiles.length)
                return type === "color"
                  ? "green"
                  : this.$t("folder.offerStatus.OfferSigned");
              if (offer.clients.status === "CANCELED") {
                return type === "color"
                  ? "green"
                  : this.$t("utils.cancelOffer.cancel");
              }
              return type === "color"
                ? "red"
                : this.$t("folder.offerStatus.signatureRefused");
            }
          }
        }
        default: {
          if (offer.clients.status === "PENDING")
            return type === "color"
              ? "orange"
              : this.$t("folder.offerStatus.waitingForSignature");
          if (offer.clients.status === "ACCEPT" || offer.signedFiles.length)
            return type === "color"
              ? "green"
              : this.$t("folder.offerStatus.OfferSigned");
          if (offer.clients.status === "CANCELED") {
            return type === "color"
              ? "green"
              : this.$t("utils.cancelOffer.cancel");
          }
          return type === "color"
            ? "red"
            : this.$t("folder.offerStatus.signatureRefused");
        }
      }
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  & > * {
    flex: none;
  }
  .left_section {
    margin-right: 8px;
  }
  .right_section {
    margin: 8px 0;
  }
}
</style>
