import Organization from "./Organization";

export default class Agreement {
  constructor({
    organization = Number.NaN,
    active = false,
    dateUpdated = Date.now(),
  }) {
    this.organization = organization
      ? new Organization({ id: organization })
      : new Organization();
    this.active = active;
    this.dateUpdated = new Date(dateUpdated);
  }
}
