<template>
  <div>
    <div
      v-if="displayCourseInfo && !isMobile"
      class="organization-logo d-flex align-center flex-column"
    >
      <BaseFrontOfficeImage
        v-if="courseInfo.organization.logo"
        :src="courseInfo.organization.logo"
        height="208px"
        width="431px"
        contain
      />
      <BaseFrontOfficeImage
        v-else
        :src="$findStatic('cosmoLogo')"
        height="208px"
        width="431px"
        contain
      />
    </div>
    <div
      v-if="!isProfile && !isMobile"
      class="d-block d-md-flex align-center"
      :class="{
        'with-space-between': displayCourseInfo,
      }"
    >
      <div v-if="displayCourseInfo" class="content d-flex flex-column">
        <div class="emphase">
          <BaseFrontOfficeTitle
            class="course-name"
            bold
            v-text="courseInfo.name"
          />
        </div>
        <BaseMarkdownViewer
          class="campaign-description"
          :content="courseInfo.description"
        />
        <BaseFrontOfficeImage
          v-if="courseInfo.image"
          :src="courseInfo.getImage"
          class="course_image"
          contain
        />
        <BaseFrontOfficeImage
          v-else
          :src="$findStatic('ethicalIllustration')"
          class="course_image"
          contain
        />
      </div>
      <div
        v-if="!displayCourseInfo"
        class="d-none d-md-flex flex-column justify-center align-center"
        style="width: 40%"
      >
        <div style="height: 300px" class="mb-6">
          <BaseFrontOfficeImage
            :src="$findStatic('cosmoIllustration')"
            width="300"
            contain
          />
        </div>
      </div>
      <div
        :class="{
          'flex-grow-1': !displayCourseInfo,
          'with-width': displayCourseInfo,
        }"
      >
        <BaseFrontOfficeBoard class="my-8 my-sm-0 elevation-5">
          <router-view />
        </BaseFrontOfficeBoard>
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletAuth",
  data() {
    return {
      courseInfo: null,
      isMobile: true,
    };
  },
  computed: {
    displayCourseInfo() {
      return !!this.$route.params.accessCampaign;
    },
    isProfile() {
      return (
        this.$route.fullPath.indexOf("/auth/profile") != -1 ||
        this.$route.fullPath.indexOf("/auth/password/change") != -1 ||
        this.$route.fullPath.indexOf("/auth/rm-rf") != -1
      );
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.checkWindowWidth();
  },
  methods: {
    checkWindowWidth() {
      this.isMobile = !(window.innerWidth > 600);
    },
    async initialize() {
      if (this.displayCourseInfo) {
        this.courseInfo = this.$route.params.accessCampaign.campaign.course;
      }
    },
  },
};
</script>

<style scoped>
.organization-logo {
  margin-bottom: 50px;
  margin-top: 50px;
}

.content {
  width: 40%;
  margin-top: 60px;
}

.emphase {
  border-left: 2px solid #ff5621;
  padding-left: 16px;
  margin-bottom: 24px;
}

.content .emphase .course-name {
  font-size: 30px;
  line-height: 49.1px;
  font-weight: 700;
}

.campaign-description {
  font-size: 14px;
  width: 300px !important;
  margin-bottom: 24px !important;
  color: #4f4f4f;
  font-weight: 800;
  line-height: 16.94px;
}

.content .course_image {
  width: 300px !important;
  margin-bottom: 24px !important;
  border-radius: 10px;
}

.with-width {
  width: 40%;
  margin-bottom: 74.5px;
}

.with-space-between {
  display: flex;
  justify-content: space-around;
  align-items: flex-start !important;
}
</style>
