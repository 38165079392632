import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Record > Answer - API

  postImpersona(payload) {
    return cosmoAPI.post("/user/impersona/", {
      ...payload,
    });
  },
};
