<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      min-width="auto"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #activator="{ on, attrs }">
        <div class="menu">
          <BaseTextField
            v-model="time"
            outlined
            append-icon="$mdiClockOutline"
            clear-icon="$mdiClose"
            clearable
            readonly
            v-bind="attrs"
            v-on="on"
            @focusin="isFocused = true"
            @focusout="isFocused = false"
          />
          <span
            class="date-label"
            :class="{ focused: isFocused }"
            v-text="label"
          />
        </div>
      </template>
      <v-time-picker v-model="time" format="24hr" no-title scrollable clearable>
        <v-spacer />
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="menu = false"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="$refs.menu.save(time)">
          {{ $t("utils.validate") }}
        </BaseButton>
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "BaseTimeInput",
  props: {
    valueTime: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      menu: false,
      time: null,
      isFocused: false,
    };
  },

  watch: {
    time() {
      this.$emit("update-value", this.time);
    },
    valueTime() {
      this.time = this.valueTime;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot {
  padding: 0 0 0 12px !important;
  .v-input__append-inner {
    &:last-child {
      margin-top: 0px !important;
    }
    .v-input__icon--append {
      background: #f1f2f8;
      padding: 21px !important;
    }
  }
}
.date-label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 14px;
  color: #707080;
  padding: 0 3px;
  background: #fff;
}
.focused {
  color: #2536cc !important;
}
</style>
