<template>
  <v-list-group
    :value="opened"
    active-class="custom__list-group--active"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
    <template #activator>
      <slot name="activator" />
    </template>
  </v-list-group>
</template>

<script>
export default {
  name: "BaseListGroup",
  props: {
    opened: {
      type: Boolean,
    },
  },
};
</script>

<style>
.custom__list-group--active {
  background-color: var(--v-primary-lighten5);
}
</style>
