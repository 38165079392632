<template>
  <div>
    <div class="detail_container">
      <v-expansion-panels
        v-for="(group, index) in groupedQuestionsByCategory"
        :key="`gg-${group.id}-${index}`"
        class="detail_panel"
        :value="0"
        :class="{
          first: index === 0,
          last: index === groupedQuestionsByCategory.length - 1,
        }"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="d-flex">
              <BaseIcon icon="$mdiLock" grey class="mr-4" />
              <BaseFrontOfficeSubtitle v-text="group.name" />
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pannel_content">
              <WalletLabelData
                v-for="(question, i) in group.questions"
                :key="`qq-${question.id}-${index}-${i}`"
                :type-question="question.typeQuestion"
                :content="
                  question.answer ? question.answer.content : question.answer
                "
                :name="question.name"
                :answer-icon-required="false"
                hide-action
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import WalletLabelData from "./WalletLabelData.vue";

export default {
  name: "WalletAnswerCosmoForm",
  components: { WalletLabelData },
  props: {
    cosmoFormDetail: {
      type: Array,
      required: true,
    },
  },
  computed: {
    groupedQuestionsByCategory() {
      if (!this.cosmoFormDetail || !this.cosmoFormDetail.length) return [];
      const groupedQuestions = [];
      this.cosmoFormDetail.forEach((question) => {
        if (question.category?.id) {
          const group = groupedQuestions.find(
            (gc) => gc.id === question.category?.id
          );
          if (group) {
            group.questions.push(question);
            group.questions = group.questions.sort((a, b) => a.order - b.order);
          } else {
            groupedQuestions.push({
              ...question.category,
              questions: [question],
            });
          }
        }
      });
      const withoutCategoryGroup = {
        id: null,
        order: 0,
        name: this.$t("category.uncategorized"),
        questions: this.cosmoFormDetail
          .filter((question) => !question.category || !question.category.id)
          .sort((a, b) => a.order - b.order),
      };
      if (withoutCategoryGroup.questions.length) {
        groupedQuestions.unshift(withoutCategoryGroup);
      }
      return groupedQuestions.sort((a, b) => a.order - b.order);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail_container {
  .pannel_content {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    @media (min-width: 600px) {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: repeat(2, calc(50% - 8px));
    }
  }
}

::v-deep .detail_container {
  margin-bottom: 16px;
  box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
    0px 6px 10px rgba(46, 45, 193, 0.06);
  border-radius: 15px;
  .v-expansion-panels {
    overflow: hidden;
    .v-expansion-panel {
      &::before {
        box-shadow: none !important;
      }
    }
    .v-expansion-panel-header {
      background-color: #ebeffe !important;
      transition: all 0.5s;
      &.v-expansion-panel-header--active {
        border-radius: 15px !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    .v-expansion-panel-content__wrap {
      padding: 16px;
    }
    &.first {
      border-radius: 15px 15px 0 0 !important;
    }
    &.last {
      border-radius: 0 0 15px 15px !important;
    }
  }
}
</style>
