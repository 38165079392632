<template>
  <div class="cosmoform_container">
    <div class="content">
      <div class="bord" v-text="$t('cosmoform.thanks.title')" />
      <div
        class="banner"
        :class="{
          banner_center:
            !cosmoformOrganization || !cosmoformOrganization.organization.logo,
        }"
      >
        <div
          v-if="
            cosmoformOrganization && cosmoformOrganization.organization.logo
          "
          class="logo_container"
        >
          <img :src="cosmoformOrganization.organization.logo" class="logo" />
        </div>
        <div class="label" v-text="$t('cosmoform.thanks.message')" />
      </div>
      <div
        v-if="
          user &&
          cosmoformOrganization &&
          !cosmoformOrganization.isPublic &&
          folderId
        "
        class="actions"
      >
        <v-btn
          color="primary"
          rounded
          x-large
          :to="{
            name: 'WalletOrganizationRecordsFolder',
            params: {
              organizationId: cosmoformOrganization.organization.id,
              folderID: folderId,
            },
          }"
        >
          {{ $t("utils.cancel") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ThanksPage",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      cosmoformOrganization: (state) => state.cosmoform.cosmoFormOrganization,
    }),
    folderId() {
      return localStorage.folderId;
    },
  },
};
</script>

<style lang="scss" scoped>
.cosmoform_container {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background: #f9f8fb;
  padding: 32px;
  position: relative;
  .content {
    max-width: 1130px;
    margin: auto;
    & > * {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .bord {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
      0px 10px 10px rgba(46, 45, 193, 0.06);
    border-radius: 20px;
    color: #4f4f4f;
    font-size: 24px;
    line-height: 1.5em;
  }
  .banner {
    display: flex;
    align-items: center;
    padding: 24;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    min-height: 90px;
    &.banner_center {
      justify-content: center;
    }
    .logo_container {
      height: 80px;
      border-radius: 5px;
      flex: none;
      .logo {
        width: 200px;
        height: 100%;
        object-fit: contain;
      }
    }
    .label {
      font-size: 16px;
      line-height: 22px;
      color: #4f4f4f;
      margin: 0 16px;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}
</style>
