import { FILE_TYPE } from "./types.utils";

/**
 * Transform the file to base64 URL
 * @param {File} file The file to transfer to base64
 * @returns Promise of Base64 file
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}
/**
 * Convert file url to JS FILE
 * @param {String} fileURL file url
 * @param {String} filename  file name
 */
export async function fileURLToFile(fileURL, filename) {
  return dataURLtoFile(await getDataBlob(fileURL), filename.split("?")[0]);
}

/**
 *
 * @param {String} dataurl data url
 * @param {String} filename file name
 * @returns
 */
export function dataURLtoFile(dataurl, filename) {
  if (!dataurl) return;
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let extension = mime.split("/")[1];
  switch (extension) {
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      extension = "xlsx";
      break;
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      extension = "docx";
      break;
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      extension = "pptx";
      break;
    default:
      extension;
      break;
  }
  return new File([u8arr], `${filename}.${extension}`, { type: mime });
}

/**
 *
 * @param {Blob} d
 * @returns
 */
export async function parseURI(d) {
  var reader = new FileReader();
  reader.readAsDataURL(d);
  return new Promise((res) => {
    reader.onload = (e) => {
      res(e.target.result);
    };
  });
}

/**
 *
 * @param {String} url file url
 * @returns Promise of file url
 */
export async function getDataBlob(url) {
  var res = await fetch(url);
  var blob = await res.blob();
  var uri = await parseURI(blob);
  return uri;
}

export function desactivatePreviewMode() {
  return window.addEventListener(
    "beforeunload",
    () => {
      localStorage.isPreview = false;
    },
    false
  );
}

export async function convertFileToPdf(file) {
  const payload = new FormData();
  payload.append("files", file);
  const myInit = {
    method: "POST",
    body: payload,
  };
  const response = await fetch("/api/web/utils/to_pdf/", myInit);
  const myBlob = await response.blob();
  return URL.createObjectURL(myBlob);
}

export function getFileTypeByName(fileName) {
  const lowerCaseFilename = fileName.toLowerCase();
  const pdfExtensions = [".pdf"];
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".svg",
    ".webp",
    ".apng",
    ".ico",
  ];
  if (pdfExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
    return FILE_TYPE.PDF;
  if (imageExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
    return FILE_TYPE.IMG;
  return FILE_TYPE.OTHER;
}
