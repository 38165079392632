// MODULES
import axios from "axios";
/* From snake_case object to camelCase */
import camelcaseKeys from "camelcase-keys";
import Oauth from "@/core/Models/Oauth";
import { COSMO_APP } from "@/main";

import * as Sentry from "@sentry/vue";

// Axios instance for cosmo Api service calls
export const cosmoAPI = axios.create({
  baseURL: `${process.env.VUE_APP_COSMO_URL_API}/api/web`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export const cosmoWEB = axios.create({
  baseURL: `${process.env.VUE_APP_COSMO_URL_API}`,
});

// ADD AUTHENTIFICATION FOR HEADER CALL
export function setBearerToken(accessToken) {
  cosmoAPI.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}
// REMOVE AUTHENTIFICATION FOR HEADER CALL
export function deleteBearerToken() {
  delete cosmoAPI.defaults.headers.common["Authorization"];
}

/**
 * Get token for the user management authentication fron the server.
 * Powered by OAuth2.
 * @param {String} username Username of the user to connect
 * @param {String} password Password of the user to connect
 * @returns oauth object of the connected user
 */
export async function getToken(username, password) {
  const payload = new FormData();
  payload.append("grant_type", Oauth.GrantType.password);
  payload.append("client_id", Oauth.clientId);
  payload.append("client_secret", Oauth.clientSecret);
  payload.append("username", username.toLowerCase());
  payload.append("password", password);
  const res = await cosmoWEB.post("/o/token/", payload);
  const oauth = new Oauth(res.data);
  Oauth.localStorage = oauth;
  setBearerToken(oauth?.accessToken);
  return oauth;
}

/**
 * Refresh the connected user access token.
 * @returns
 */
export async function refreshToken() {
  const oauth = Oauth.localStorage;
  const payload = new FormData();
  payload.append("grant_type", Oauth.GrantType.refreshToken);
  payload.append("client_id", Oauth.clientId);
  payload.append("client_secret", Oauth.clientSecret);
  payload.append("refresh_token", oauth?.refreshToken);

  try {
    const res = await cosmoWEB.post("/o/token/", payload);
    const newOauth = new Oauth(res.data);
    Oauth.localStorage = newOauth;
    setBearerToken(newOauth?.accessToken);
    return newOauth;
  } catch (error) {
    if (error.response.status === 400) {
      return logoutUser();
    }
  }
}

/**
 * Revoke Oauth2 token.
 * Removes it from local storage.
 * Delete the bearer token from the header of requests of the cosmo API.
 * @returns boolean of the success
 */
export async function revokeToken() {
  try {
    const oauth = Oauth.localStorage;
    const payload = new FormData();
    payload.append("client_id", Oauth.clientId);
    payload.append("client_secret", Oauth.clientSecret);
    payload.append("token", oauth?.accessToken);
    await cosmoWEB.post("/o/revoke_token/", payload);
    Oauth.localStorage = null;
    deleteBearerToken();
  } catch (error) {
    return false;
  }
  return true;
}

export async function logoutUser() {
  try {
    await revokeToken();
    Oauth.localStorage = null;
    if (COSMO_APP.$route.meta.authenticationRequired) {
      return location.replace("/auth/login");
    }
  } catch (error) {
    return false;
  }
}

cosmoWEB.interceptors.response.use((response) => {
  // /* From snake_case object to camelCase data response */
  response.data = camelcaseKeys(response.data, { deep: true });
  // Return a successful response back to the calling service
  return response;
});

// API SERVICE INTERCEPTOR
cosmoAPI.interceptors.response.use(
  (response) => {
    // /* From snake_case object to camelCase data response */
    response.data = camelcaseKeys(response.data, { deep: true });
    // Return a successful response back to the calling service
    return response;
  },
  async (error) => {
    if (error?.response?.status !== 401 || error?.response?.status === 500)
      throw error;

    // Logout user if refresh token does not exist in Localstorage
    if (Oauth.localStorage?.refreshToken == null) {
      await logoutUser();
    }
    // Access Token invalid: try to refresh token then request again
    const oauth = Oauth.localStorage;
    if (!oauth) {
      location.replace("/auth/login");
    }
    await refreshToken();
    try {
      // Try request again with new token
      return await cosmoAPI.request({
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: "Bearer " + Oauth.localStorage?.accessToken,
        },
      });
    } catch (err) {
      if (error?.response) {
        const errorMessage = `${error.response.status}: ${
          error.response?.data?.error_description ||
          error.response?.data?.error ||
          error.response?.statusText
        }`;
        Sentry.captureException(new Error(errorMessage));
      }
      await logoutUser();
    }
  }
);
