<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('utils.assignedCollaborator')"
    is-unique
    @close="reset"
  >
    <template #modal>
      <base-autocomplete
        v-model="form.collaborator"
        :menu-props="{ offsetY: true }"
        :loading="isLoading"
        :items="assignableCollaborators"
        item-text="email"
        item-value="id"
      />
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton :disabled="isLoading" type="primary" @click="submit">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { getCollaboratorAssignedToStatus } from "../../Admin/Services/workflow.service";

export default {
  name: "FolderAssignCollaboratorDialog",
  data() {
    return {
      form: {
        collaborator: null,
      },
      isLoading: false,
      assignableCollaborators: [],
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) =>
        state.folder.modals.isFolderAssignCollaboratorDialogOpen,
      activeFolder: (state) => state.folder.activeFolder,
    }),
  },
  watch: {
    dialogState(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },
  methods: {
    reset() {
      this.$store.commit("folder/SET_MODAL_ASSIGN_COLLABORATOR_STATUS", false);
      this.form = {
        collaborator: null,
      };
    },
    async initialize() {
      this.isLoading = true;
      if (this.activeFolder) {
        await this.fetchAssignableCollaborators();
        this.form.collaborator = this.activeFolder.assign?.id;
      }
      this.isLoading = false;
    },
    async fetchAssignableCollaborators() {
      if (
        !isNaN(this.activeFolder.workflow) &&
        !isNaN(this.activeFolder.status)
      ) {
        const collaboratorsAssignedResult = (
          await getCollaboratorAssignedToStatus(
            this.$route.params.organizationId,
            this.activeFolder.workflow,
            this.activeFolder.status
          )
        )?.data?.results;

        if (collaboratorsAssignedResult.length > 0) {
          this.assignableCollaborators = collaboratorsAssignedResult.map(
            (collaboratorAssignation) => ({
              ...collaboratorAssignation.collaborator.user,
            })
          );
        } else {
          const organizationCollaboratorsResult = await this.$store.dispatch(
            "workflow/fetchOrganizationCollaborators",
            {
              organizationId: this.$route.params.organizationId,
            }
          );
          this.assignableCollaborators = organizationCollaboratorsResult?.map(
            (collaborator) => ({
              ...collaborator.user,
            })
          );
        }
      } else {
        this.assignableCollaborators = [];
      }
    },
    async submit() {
      let payload = { assign: this.form.collaborator };
      try {
        await this.$store.dispatch("folder/updateFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
          payload,
        });
        this.$store.commit("folder/SET_ACTIVE_FOLDER", {
          ...this.activeFolder,
          assign: payload.assign
            ? this.assignableCollaborators.find((as) => as.id == payload.assign)
            : null,
        });
        const successMessage = this.$t(
          payload.assign
            ? "folder.assignCollaboratorForm.successAdd"
            : "folder.assignCollaboratorForm.successRemove"
        );
        this.$store.dispatch("snackbar/active", {
          message: successMessage,
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.assignCollaboratorForm.error"),
          type: "ERROR",
        });
      }
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
}
.modal_content {
  & > * {
    margin-top: 24px !important;
  }
}
.modal_actions {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
}
</style>
