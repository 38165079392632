<template>
  <BaseModal
    :value="isOpen"
    :modal-title="$t('course.deleteActionAsk')"
    @click:outside="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="$t('course.deleteActionAsk')" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "CourseDeleteDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    submit() {
      this.$store
        .dispatch("course/deleteCourse", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$store.state.course.course.id,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.deleteConfirmation"),
            type: "SUCCESS",
          });
          if (this.$route.name !== "courseList") {
            this.$router.push({
              name: "courseList",
              params: { organizationId: this.$route.params.organizationId },
            });
          }
          this.$emit("deleted");
          this.$emit("close");
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },
  },
};
</script>
