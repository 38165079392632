<template>
  <BaseSheet
    class="cosmo-front-office-board"
    :class="{
      'title-board': /^title$/.test(type),
      'pa-4': isPaddingNeeded,
      'pa-0': paddingSimple,
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="header" />
    <div :class="{ 'mt-6': $slots.header, 'mb-6': $slots.footer }">
      <slot />
    </div>
    <slot name="footer" />
  </BaseSheet>
</template>

<script>
export default {
  name: "BaseFrontOfficeBoard",
  props: {
    type: {
      type: String,
      default: "default",
    },
    isPaddingNeeded: {
      type: Boolean,
      default: true,
    },
    paddingSimple: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.cosmo-front-office-board {
  z-index: 2;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 20px !important;
  padding: 32px;
  margin-bottom: 24px;
  font-size: 16px;
  font-family: "Nunito-SemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #4f4f4f;
}
.title-board {
  border: 1px solid #ebebeb !important;
  box-sizing: border-box !important;
  padding: 24px !important;
  border-radius: 20px !important;
  box-shadow: 0px 2px 4px rgb(46 45 193 / 10%),
    0px 10px 10px rgb(46 45 193 / 6%) !important;
  -webkit-box-shadow: 0px 2px 4px rgb(46 45 193 / 10%),
    0px 10px 10px rgb(46 45 193 / 6%) !important;
  text-align: center !important;
  z-index: 102 !important;
}
@media (max-width: 599px) {
  .title-board {
    position: relative !important;
    top: 0px !important;
  }
}
</style>
