<template>
  <v-expansion-panel v-bind="$attrs" v-on="$listeners">
    <v-expansion-panel-header>
      <slot name="header" />
    </v-expansion-panel-header>
    <v-expansion-panel-content :eager="eager">
      <slot name="content" />
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "BaseExpensionPanels",
  props: {
    eager: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
