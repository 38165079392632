import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Record - API
  postRecord(campaignSlug) {
    return cosmoAPI.post(`/wallet/campaign/${campaignSlug}/record/`);
  },
  /**
   * @param {string} recordStatus Status of the record (agree, not_agree, in_progress)
   * @returns Promise
   */
  getRecords(recordStatus, page) {
    const params = {
      page,
    };
    if (recordStatus) {
      params.status_record = recordStatus;
    }
    return cosmoAPI.get("/wallet/record/", {
      params,
    });
  },
  getRecord({ recordId, settings }) {
    return cosmoAPI.get(`/record/${recordId}/`, settings);
  },
  deleteFileFromRecord({ endpoint, payload }) {
    return cosmoAPI.delete(`${endpoint}`, { data: payload });
  },
  postRecordThanks({ recordId, agreementStatus, settings }) {
    return cosmoAPI.post(
      `/record/${recordId}/thanks/`,
      {
        agree: agreementStatus,
      },
      settings
    );
  },
  updateRecordAgreement(recordId, agreementStatus) {
    return cosmoAPI.post(`/wallet/record/${recordId}/agreement/`, {
      agreement: agreementStatus,
    });
  },
  getRecordQuestionCurrent({ recordId, settings }) {
    return cosmoAPI.get(`/record/${recordId}/question/current/`, settings);
  },
  getRecordQuestionGroup({ recordId, groupId, settings }) {
    return cosmoAPI.get(`/record/${recordId}/group/${groupId}/`, settings);
  },
  postRecordQuestionAnswer({ recordId, payload, questionId, settings }) {
    return cosmoAPI.post(
      `/record/${recordId}/answer/`,
      {
        ...payload,
        question: questionId,
      },
      settings
    );
  },
  getRecordNotCompleted() {
    return cosmoAPI.get("/wallet/record/", {
      params: {
        status_record: "in_progress",
      },
    });
  },
  //#endregion
  getWalletRecord(recordId) {
    return cosmoAPI.get(`/wallet/record/${recordId}/`);
  },
  getRecordQuestion({ recordId, page }) {
    return cosmoAPI.get(`/wallet/record/${recordId}/question/`, {
      page,
    });
  },
  patchWalletRecordQuestionAnswer({ recordId, questionId, payload }) {
    return cosmoAPI.patch(
      `/wallet/record/${recordId}/question/${questionId}/answer/`,
      {
        ...payload,
      }
    );
  },
  getCourseQuestionJump({
    organizationId,
    courseId,
    stepId,
    questionId,
    settings,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/course/${courseId}/step/${stepId}/question/${questionId}/jump-anonymous/`,
      settings
    );
  },
};
