<template>
  <BaseModal
    :value="isOpen"
    :modal-title="
      isEditing
        ? $t('course.forms.question.group.editTitle')
        : $t('course.forms.question.group.title')
    "
    @close="$emit('close')"
  >
    <template #modal>
      <div>
        <p>
          <BaseParagraph
            v-text="$t('course.forms.question.group.questionDescription')"
          />
        </p>
        <p>
          <BaseParagraph
            v-text="
              $t('course.forms.question.group.groupementQuestionSubDescription')
            "
          />
        </p>
        <v-form ref="form" @submit.prevent="submit()">
          <BaseTextField
            v-model="form.groupLabel"
            :label="$t('course.forms.question.group.questionName')"
            :error-messages="groupLabelErrors"
            :has-error="!!groupLabelErrors"
            @change="$v.form.groupLabel.$touch()"
          />
        </v-form>
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :disabled="$v.form.$invalid"
        :loading="isLoading"
        color="primary"
        type="primary"
        @click="submit"
      >
        {{ isEditing ? $t("utils.edit") : $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CourseAPI from "../Services/course.api";

export default {
  name: "CourseQuestionGroupCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
    },
    questionsToGroup: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        groupLabel: "",
      },
      questionLength: null,
      isLoading: false,
    };
  },

  computed: {
    groupLabelErrors() {
      if (!this.$v.form.groupLabel.$dirty) {
        return "";
      }
      if (!this.$v.form.groupLabel.required) {
        return this.$t("course.forms.question.label.requiredQuestion");
      }
      return "";
    },
  },
  created() {
    if (this.isEditing) this.form.groupLabel = this.group.label;
  },
  validations: {
    form: {
      groupLabel: {
        required,
      },
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.$v.form.$reset();
      this.$emit("close");
    },
    submit() {
      if (this.isEditing) return this.updateGroupQuestion();
      return this.createGroupQuestions();
    },
    async updateGroupQuestion() {
      this.isLoading = true;
      try {
        const payload = { label: this.form.groupLabel };
        await CourseAPI.patchQuestionGroup({
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.$store.state.course.activeStep.id,
          groupId: this.group.id,
          payload,
        });
        await this.$store.dispatch("course/fetchStep", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.$store.state.course.activeStep.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.forms.question.group.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.forms.question.group.snackbar.editError"),
          type: "ERROR",
        });
      }
      this.isLoading = false;
      this.reset();
    },
    async createGroupQuestions() {
      this.isLoading = true;
      this.questionLength =
        this.$store.state.course.activeStep?.questions?.length;
      let stepId = this.$store.state.course.activeStep.id;
      try {
        if (this.questionsToGroup.length >= 2) {
          const res = await this.$store.dispatch("course/groupQuestions", {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            stepId,
            label: this.form.groupLabel,
          });

          this.questionsToGroup.forEach((question) => {
            let payload = {
              organizationId: this.$route.params.organizationId,
              courseId: this.$route.params.courseId,
              stepId,
              questionId: question.id,
              question: {
                group: res.data.id,
              },
            };
            this.$store.dispatch("course/patchQuestion", payload).then(() => {
              this.$store.dispatch("course/fetchStep", {
                organizationId: this.$route.params.organizationId,
                courseId: this.$route.params.courseId,
                stepId,
              });
            });
          });
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.forms.question.group.snackbar.success"),
            type: "SUCCESS",
          });
          // Display TheSnackbar
        }
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.forms.question.group.snackbar.error"),
          type: "ERROR",
        });
      }
      this.isLoading = false;
      this.reset();
    },
  },
};
</script>
