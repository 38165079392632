<template>
  <v-btn
    class="rounded-pill"
    :class="buttonClasses"
    depressed
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="d-flex align-center justify-center">
      <BaseIcon
        v-if="icon"
        :icon="icon"
        :size="16"
        class="mr-2"
        :class="'button-icon-' + type"
      />
      <div>
        <slot />
      </div>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "BaseFrontOfficeButton",
  props: {
    type: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClasses() {
      let classes = [`cosmo-button-${this.type}`];
      if (this.disabled) {
        classes.push("cosmo-button-disabled");
      }
      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
.v-btn {
  height: 48px !important;
  font-weight: 700;
  line-height: 22px;
  font-family: var(--cosmo-font-nunito);
  min-width: 125px !important;
  min-height: 50px !important;
}

/* Primary button */
.v-btn.cosmo-button-primary {
  background-color: var(--v-walletPrimary-base);
  color: var(--v-walletGrey-lighten5);
}
.v-btn.cosmo-button-primary:hover {
  transition: all 0.75s ease;
  background-color: var(--v-walletAccent-base);
  color: var(--v-walletGrey-lighten5);
}

/* Secondary button */
.v-btn.cosmo-button-secondary {
  background-color: var(--v-walletGrey-lighten5);
  border: 1.25px solid var(--v-walletGrey-lighten3);
  color: var(--v-walletPrimary-base);
}
.v-btn.cosmo-button-secondary:hover {
  transition: all 0.75s ease;
  border: 1.25px solid var(--v-walletAccent-base);
}

/* Disabled button */
.v-btn.cosmo-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
