import Client from "./Client";
import { OfferReminder } from "./OfferReminder";
import { YousignMember } from "./YousignMember";
import { EMAIL_STATUS } from "@/core/Utils/types.utils";
export class OfferClient {
  constructor({
    id = Number.NaN,
    client = null,
    member = null,
    dateUpdated = null,
    dateCreated = null,
    offer = Number.NaN,
    reasonDecline = "",
    status = "PENDING",
    reminders = [],
    yousignLink = null,
    certificate = "",
    email = null,
    action = "",
    order = null,
  } = {}) {
    this.id = Number.parseInt(id);
    this.client = client ? new Client(client) : null;
    this.member = member ? new YousignMember(member) : null;
    this.dateCreated = dateCreated ? new Date(dateCreated) : null;
    this.dateUpdated = dateUpdated ? new Date(dateUpdated) : null;
    this.status = status;
    this.reasonDecline = reasonDecline;
    this.offer = offer;
    this.reminders = reminders
      ? reminders.map((r) => new OfferReminder({ ...r }))
      : [];
    this.yousignLink = yousignLink;
    this.certificate = certificate;
    this.email = email;
    this.action = action;
    this.order = order;
  }

  getLastMailStatus() {
    if (!this.reminders?.length) return null;
    const emailStatus = this.reminders[0].transactionalEmail;
    if (emailStatus) {
      switch (emailStatus.status) {
        case EMAIL_STATUS.PENDING:
          emailStatus.label = "utils.pending";
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.IN_PROGRESS:
          emailStatus.label = "utils.inProgess";
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.SUCCESS:
          emailStatus.label = "utils.success";
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.ERROR:
          emailStatus.label = "utils.error";
          emailStatus.icon = "$mdiEmailAlertOutline";
          break;
        case EMAIL_STATUS.SENT:
          emailStatus.label = "utils.emailSent";
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.CLICKED:
          emailStatus.label = "utils.clicked";
          emailStatus.icon = "$mdiEmailOpenOutline";
          break;
        case EMAIL_STATUS.SPAM:
          emailStatus.label = "utils.spam";
          emailStatus.icon = "$emailSpamIcon";
          break;
        case EMAIL_STATUS.OPEN:
          emailStatus.label = "utils.emailOpen";
          emailStatus.icon = "$mdiEmailOpenOutline";
          break;
      }
    }
    return emailStatus;
  }
}
