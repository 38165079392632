import Status from "./Status";

export default class Workflow {
  constructor({
    id = Number.NaN,
    name = "",
    primaryStatus = Number.NaN,
    statuses = [],
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.primaryStatus = Number.parseInt(primaryStatus);
    this.statuses = statuses
      ? statuses.map((status) => new Status(status))
      : [];
  }
}
