<template>
  <BaseRow>
    <BaseCol v-if="isEditingAllowed" cols="1">
      <v-form
        ref="groupForm"
        class="text-center pa-2 d-flex justify-center align-center"
      >
        <v-checkbox
          v-if="!question.group"
          class="ma-0 pa-0"
          hide-details
          :disabled="!isPresentInGroupIndex || isInMiddleOfQuestion"
          @click.stop
          @change="$emit('toggle-group-question', $event, question)"
        />
        <BaseButtonIcon
          v-else
          x-small
          color="primary"
          icon="$mdiUngroup"
          class="ma-0 pa-0"
          @click.stop="$emit('ungroup-question', question)"
        />
      </v-form>
    </BaseCol>
    <BaseCol :cols="isEditingAllowed ? 3 : 6" class="pa-2 d-flex align-center">
      <div class="d-flex align-center py-2">
        <div>
          <BaseDescription
            text="secondary"
            class="d-block"
            color="primary"
            bold
            v-text="
              `${question.label.substring(0, 30)} ${
                question.label.length > 30 ? '...' : ''
              } ${question.required ? '*' : ''}`
            "
          />
          <BaseDescription
            text="secondary"
            uppercase
            class="d-block"
            v-text="
              `${question.name.substring(0, 22)} ${
                question.name.length > 22 ? '...' : ''
              } ${question.required ? '*' : ''}`
            "
          />
        </div>
        <a
          v-if="question.image"
          :href="question.image"
          target="blank"
          class="ml-4"
        >
          <BaseImage
            :src="question.image"
            height="32px"
            width="72px"
            class="rounded-5"
          />
        </a>
      </div>
    </BaseCol>
    <BaseCol
      :cols="isEditingAllowed ? 2 : 3"
      class="text-center pa-2 d-flex justify-center align-center"
    >
      <div v-if="question.datatype" class="d-flex flex-column">
        <BaseIcon icon="$mdiCheck" color="primary" />
        <BaseDescription
          text="secondary"
          uppercase
          v-text="question.datatype"
        />
      </div>
      <div v-else class="d-flex flex-column">
        <BaseIcon icon="$mdiClose" color="primary" />
        <BaseDescription
          v-if="question.slug"
          text="secondary"
          uppercase
          v-text="question.slug"
        />
      </div>
    </BaseCol>
    <BaseCol
      :cols="isEditingAllowed ? 2 : 3"
      class="text-center pa-2 d-flex justify-center align-center"
    >
      <BaseDescription
        text="disabled"
        uppercase
        v-text="getDisplayTextForTypeQuestion(question.typeQuestion)"
      />
    </BaseCol>
    <BaseCol
      v-if="isEditingAllowed"
      cols="1"
      class="text-center pa-2 d-flex justify-center align-center"
      @click="stop"
    >
      <BaseButtonIcon
        v-if="
          !question.group && /SELECT|RADIOBUTTON/.test(question.typeQuestion)
        "
        class="logicjump"
        icon="$mdiSourceBranch"
        :color="colorJump"
        x-small
        :disabled="isLogicJumbDisabled"
        @click.stop="$emit('logicjump', question)"
      />
    </BaseCol>
    <BaseCol class="d-flex justify-center">
      <BaseIcon icon="$mdiHelpCircle" color="primary" class="mr-2" />
      <BaseIcon icon="$mdiFileQuestion" color="primary" />
    </BaseCol>
    <BaseCol
      v-if="isEditingAllowed"
      cols="3"
      class="text-center pa-2 d-flex justify-center align-center"
    >
      <div class="d-flex justify-end">
        <span class="mx-4" />
        <BaseButtonIcon
          x-small
          color="primary"
          icon="$mdiPencilOutline"
          class="mr-2"
          @click.stop="$emit('edit-question', question)"
        />
        <BaseButtonIcon
          color="secondary"
          x-small
          icon="$mdiTrashCanOutline"
          @click.stop="$emit('delete-question', question)"
        />
      </div>
    </BaseCol>
  </BaseRow>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CourseDetailStepQuestionRow",
  props: {
    isEditingAllowed: {
      type: Boolean,
      required: true,
    },
    isLogicJumbDisabled: {
      type: Boolean,
    },
    stepId: {
      type: Number,
      required: true,
    },
    questionsToGroup: {
      type: Array,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    orderedStepQuestions: {
      type: Array,
      default: null,
    },
    isGroupLastQuestion: {
      type: Boolean,
    },
    isGroup: {
      type: Boolean,
    },
  },
  data() {
    return {
      logicalJump: [],
    };
  },
  computed: {
    ...mapState({
      questionsAvailableToGroup: (state) =>
        state.course.questionsAvailableToGroup,
    }),
    isPresentInGroupIndex() {
      if (!this.questionsAvailableToGroup.length) return true;
      return this.questionsAvailableToGroup.includes(this.question.order);
    },
    isInMiddleOfQuestion() {
      if (this.questionsAvailableToGroup.length < 5) return false;
      let questionIndexGroup = [...this.questionsAvailableToGroup];
      const middleQuestionOrder = questionIndexGroup.splice(
        2,
        this.questionsAvailableToGroup.length - 4
      );
      return middleQuestionOrder.includes(this.question.order);
    },
    colorJump() {
      return this.logicalJump.length ? "#18a300" : "primary";
    },
  },
  watch: {
    questionsToGroup(newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length > 0) {
        this.$refs.groupForm.reset();
      }
    },

    question(newVal) {
      if (newVal) this.logicalJump = [...newVal.logicjumps];
    },
  },
  created() {
    if (this.question) this.logicalJump = [...this.question.logicjumps];
  },
  methods: {
    stop(event) {
      if (this.isLogicJumbDisabled) return event.stopPropagation();
    },
    getDisplayTextForTypeQuestion(type) {
      switch (type) {
        case "TEXT":
          return this.$t("question.types.text");
        case "TEXTAREA":
          return this.$t("question.types.textarea");
        case "NUMBER":
          return this.$t("question.types.number");
        case "DATE":
          return this.$t("question.types.date");
        case "DATETIME":
          return this.$t("question.types.datetime");
        case "RADIOBUTTON":
          return this.$t("question.types.radio");
        case "CHECKBOX":
          return this.$t("question.types.checkbox");
        case "SELECT":
          return this.$t("question.types.select");
        case "FILE":
          return this.$t("question.types.file");
        case "MULTIPLE_FILE":
          return `${this.$t("question.types.file")} (s)`;
        case "SIGNATURE":
          return this.$t("question.types.signature");
        case "DATAGRID":
          return this.$t("question.types.datagrid");
        case "ROW_DATAGRID":
          return this.$t("question.types.rowDatagrid");
        case "MESSAGE":
          return this.$t("question.types.message");
      }
    },
  },
};
</script>
<style scoped>
.logicjump {
  &:disabled {
    opacity: 0.6;
    &.v-btn > .v-btn__content .v-icon {
      color: #2536cc !important;
    }
  }
}
.col-3 {
  max-width: 20% !important;
}
</style>
