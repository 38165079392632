<template>
  <div>
    <div v-if="campaignAccess">
      <div
        class="cosmo-welcome-static-image d-none d-sm-flex justify-end align-center"
      >
        <BaseFrontOfficeImage
          :src="$findStatic('ethicalIllustration')"
          max-width="640"
        />
      </div>
      <div class="cosmo-welcome-content mt-8">
        <div class="d-flex align-center flex-column">
          <BaseImage
            v-if="campaignAccess.campaign.course.organization.logo"
            :src="campaignAccess.campaign.course.organization.logo"
            height="208px"
            width="431px"
            contain
          />
          <BaseImage
            v-else
            :src="$findStatic('cosmoLogo')"
            height="208px"
            width="431px"
            contain
          />
        </div>
        <div v-if="folderClosed" class="record_archived_alert">
          <div class="alert_icon">
            <BaseIcon icon="$mdiLock" />
          </div>
          <div class="alert_text">
            <div v-text="$tc('utils.recordArchivedAlert', 1)" />
            <div v-text="$tc('utils.recordArchivedAlert', 2)" />
          </div>
        </div>
        <div class="content d-flex flex-column align-start">
          <div class="emphase">
            <BaseFrontOfficeTitle
              class="course_name"
              bold
              v-text="campaignAccess.campaign.course.name"
            />
          </div>
          <BaseMarkdownViewer
            v-if="campaignAccess.campaign && campaignAccess.campaign.course"
            class="campaign-description"
            :content="campaignAccess.campaign.course.description"
          />

          <BaseFrontOfficeImage
            v-else
            class="course_image"
            :src="$findStatic('ethicalIllustration')"
            width="300px"
            contain
          />
        </div>
      </div>
      <div class="cosmo-start-course-button">
        <div>
          <div class="d-flex justify-center align-center">
            <div
              v-ripple
              class="circles"
              :class="{ loading: isLoading }"
              @click="submit()"
            >
              <BaseIcon color="white" icon="$mdiLockOpen" />
            </div>
            <BaseFrontOfficeButton
              class="submit_btn"
              :disabled="folderClosed"
              :loading="isLoading"
              @click="submit()"
            >
              {{ $t("campaign.welcome.startCourse") }}
            </BaseFrontOfficeButton>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width: 100%; height: 100vh"
    >
      <BaseFrontOfficeProgressCircular
        color="walletPrimary"
        indeterminate
        size="74"
      />
    </div>
  </div>
</template>

<script>
import { desactivatePreviewMode } from "@/core/Utils/file.utils";
import RecordApi from "../Services/record.api";

import {
  WelcomeAccessStatus,
  WelcomeActionStatus,
} from "@/core/Utils/types.utils";

export default {
  name: "WalletWelcome",
  props: {
    campaignSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      recordId: null,
      campaignAccess: null,
      isLoading: false,
    };
  },
  computed: {
    folderClosed() {
      if (this.campaignAccess?.folderState == "CLOSED") return true;
      return false;
    },
  },
  created() {
    this.initialize();
    desactivatePreviewMode();
  },
  methods: {
    async initialize() {
      this.recordId = Number.parseInt(this.$route.query.record_pk);
      try {
        if (!this.recordId || this.$route.query.demo) {
          return await this.createRecord();
        }
        const res = await this.$store.dispatch(
          "walletCourse/getAccessCampaign",
          {
            campaignSlug: this.campaignSlug,
            recordId: this.recordId,
            sc: this.$route.query.sc,
          }
        );
        this.campaignAccess = res.data;

        if (this.campaignAccess.folderState == "CLOSED") return;
        if (this.campaignAccess.action == WelcomeActionStatus.NeedAuth) {
          return this.redirectToLoginWithNextQuery(this.campaignAccess);
        }
      } catch (error) {
        if (!error?.response?.data) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.oopsError"),
            type: "ERROR",
          });
          return window.location.replace("/404/");
        }
        const { code } = error?.response?.data;

        switch (code) {
          case WelcomeAccessStatus.AlreadyAnswered:
          case WelcomeAccessStatus.CampaignNotReady:
            window.location.replace("/404/");
            return;
          case WelcomeAccessStatus.RecordReserved:
            window.location.replace(`/campaign/${this.recordId}/reserved`);
            return;
          default:
        }
      }
    },
    async createRecord() {
      if (this.recordId) {
        const routePush = {
          name: "WalletCourse",
          params: {
            recordId: this.recordId,
          },
        };
        if (this.$route.query.sc) {
          routePush["query"] = { sc: this.$route.query.sc };
        }
        return this.$router.push(routePush);
      }
      const res = await RecordApi.postRecord(this.campaignSlug);
      const record = res.data;
      this.$router.push({
        name: "WalletCourse",
        params: {
          recordId: record.id,
        },
      });
    },
    redirectToLoginWithNextQuery(accessCampaign) {
      if (!this.campaignAccess.hasAccount) {
        this.$router.push({
          name: "WalletRegister",
          query: {
            next: this.$route.fullPath,
          },
          params: {
            invitedUserEmail: accessCampaign.email,
            accessCampaign: accessCampaign,
            recordId: this.recordId,
          },
        });
      } else {
        this.$router.push({
          name: "WalletLogin",
          query: {
            next: this.$route.fullPath,
          },
          params: {
            invitedUserEmail: accessCampaign.email,
            accessCampaign: accessCampaign,
            recordId: this.recordId,
          },
        });
      }
    },
    async submit() {
      this.isLoading = true;
      switch (this.campaignAccess.action) {
        case WelcomeActionStatus.CreateRecord:
          {
            await this.createRecord();
          }
          break;
        case WelcomeActionStatus.Answer:
          {
            const routePush = {
              name: "WalletCourse",
              params: {
                recordId: this.recordId,
              },
            };
            if (this.$route.query.sc) {
              routePush["query"] = { sc: this.$route.query.sc };
            }
            this.$router.push(routePush);
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cosmo-welcome-static-image {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100vh;
}
.cosmo-welcome-content {
  z-index: 1;
}
.cosmo-welcome-content .content {
  margin-top: 5vh;
  margin-bottom: 120px;
  margin-left: 10vh;
  max-width: 40vw;
}
@media all and (max-width: 960px) {
  .cosmo-welcome-content .content {
    margin-top: calc(50vh - 280px);
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }
}

.cosmo-welcome-content .content .emphase {
  border-left: 2px solid #ff5621;
  padding-left: 16px;
  margin-bottom: 24px;
  width: 400px;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.cosmo-welcome-content .content .course_name {
  font-size: 30px;
  line-height: 49.1px;
  font-weight: 700;
}

.cosmo-welcome-content .content .campaign-description {
  font-size: 14px;
  width: 300px !important;
  margin-bottom: 24px;
  color: #4f4f4f;
  font-weight: 800;
  line-height: 16.94px;
  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.cosmo-welcome-content .content .course_image {
  border-radius: 10px;
}

@media (max-width: 600px) {
  .course_image {
    display: none;
  }
}

.cosmo-start-course-button .circles {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 84px;
  height: 84px;
  margin-right: -24px;
  border-radius: 50%;
}
.cosmo-start-course-button .circles.loading {
  pointer-events: none;
  cursor: auto;
}

.cosmo-start-course-button .circles::before {
  content: "";
  position: absolute;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 51.56%,
      rgba(252, 192, 15, 0.5) 100%
    ),
    rgba(255, 112, 116, 0.5);
}
.cosmo-start-course-button .circles::after {
  content: "";
  margin-top: 12px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 51.56%,
      rgba(252, 192, 15, 0.5) 100%
    ),
    rgba(255, 112, 116, 0.5);
}
::v-deep .cosmo-start-course-button .circles .v-icon {
  z-index: 3;
}
.cosmo-start-course-button {
  margin-left: -84px;
  z-index: 2;
  position: fixed;
  bottom: 20px;
  width: calc(100% + 84px);
}
.record_archived_alert {
  padding: 8px;
  margin: auto;
  width: 400px;
  text-align: center;
  .alert_icon {
    padding: 8px;
    &::v-deep {
      .v-icon {
        color: #3c3b8e;
      }
    }
  }
  .alert_text {
    padding: 8px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #3c3b8e;
  }
}
.submit_btn {
  &.cosmo-button-disabled {
    background: #ffffff !important;
    opacity: 0.5 !important;
    box-shadow: 0px 1px 1px rgba(60, 59, 142, 0.25),
      0px 6px 8px rgba(60, 59, 142, 0.1);
    border: 1.25px solid #ebebeb;
    &::v-deep {
      .v-btn__content {
        color: #3c3b8e !important;
      }
    }
  }
}
</style>
