import Question from "./Question";

export default class Cosmoform {
  constructor({ id, question, answer = null, updated = false, dateCreated }) {
    this.id = id;
    this.question = new Question(question);
    this.answer = answer && Array.isArray(answer) ? answer[0] : answer;
    this.updated = updated;
    this.dateCreated = dateCreated;
    if (
      this.answer &&
      this.answer.choices?.length > 0 &&
      isNaN(this.answer.choices[0])
    ) {
      this.answer.choices = this.answer.choices.map((c) => c.id);
    }
  }
}

export class CosmoformTemplate {
  constructor({
    id,
    title,
    description,
    emailContent,
    dateCreated,
    dateUpdated,
    accountRequired,
  }) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.emailContent = emailContent;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
    this.accountRequired = accountRequired;
  }

  setDetail({ description, emailContent, dateUpdated, accountRequired }) {
    this.description = description;
    this.emailContent = emailContent;
    this.dateUpdated = dateUpdated;
    this.accountRequired = accountRequired;
  }
}
