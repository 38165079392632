import Course from "./Course";
import User from "./User";
import Record from "./Record";
import Workflow from "./Workflow";

export default class Campaign {
  constructor({
    id = Number.NaN,
    name = "",
    slug = "",
    description = "",
    defaultEmailContent = "",
    status = "",
    assign = null,
    recordsInProgress = [],
    recordsAgreed = [],
    recordsDisagreed = [],
    createFolder = false,
    once = false,
    course = new Course(),
    organizationRequired = false,
    dateCreated = null,
    dateUpdated = null,
    published = false,
    workflow = null,
    nextReminderDate = null,
    autoReminder = null,
    isNotified = true,
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.slug = slug;
    this.description = description;
    this.defaultEmailContent = defaultEmailContent;
    this.status = status;
    this.assign = assign ? new User(assign) : null;
    this.recordsInProgress = recordsInProgress
      ? recordsInProgress.map((record) => new Record(record))
      : [];
    this.recordsAgreed = recordsAgreed
      ? recordsAgreed.map((record) => new Record(record))
      : [];
    this.recordsDisagreed = recordsDisagreed
      ? recordsDisagreed.map((record) => new Record(record))
      : [];
    this.createFolder = createFolder;
    this.once = once;
    this.course = course ? new Course(course) : null;
    this.organizationRequired = organizationRequired;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
    this.published = published;
    this.workflow = workflow ? new Workflow(workflow) : null;
    this.nextReminderDate = nextReminderDate;
    this.autoReminder = autoReminder;
    this.isNotified = isNotified;
  }
}
