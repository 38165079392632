<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-6">
      <BaseTitle v-text="$tc('organization.invitationSent', 2)" />
      <BaseButton type="primary" icon="$mdiPlus" @click="toggleDialog(true)">
        {{ $tc("organization.invite", 4) }}
      </BaseButton>
    </div>
    <BaseDataTable
      :headers="INVITATIONS_LIST_HEADERS"
      :items="invitations"
      :loading="isFetchingInvitations || submittingRemind"
      :loading-text="$t('utils.loadingData')"
      class="elevation-1 mb-6"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <BaseParagraph v-text="item.email" />
          </td>
          <td>
            <BaseParagraph
              v-if="item.function == 'MANAGER'"
              v-text="$t('organization.manager')"
            />
            <BaseParagraph
              v-if="item.function == 'MEMBER'"
              v-text="$t('organization.member')"
            />
          </td>
          <td>
            <BaseButton
              :disabled="submittingRemind"
              type="primary"
              @click="submitRemind(item)"
            >
              <span v-text="$t('utils.relaunch')" />
            </BaseButton>
          </td>
        </tr>
      </template>
    </BaseDataTable>

    <BaseModal
      :value="showDialog"
      :modal-title="$t('organization.invite')"
      is-unique
      @close="resetForm"
    >
      <template #modal>
        <div class="pt-4">
          <v-form ref="form">
            <BaseTextField
              v-model="form.email"
              autofocus
              :label="`${$t('organization.inviteEmail')} *`"
              :has-error="!!emailErrors"
              :error-messages="emailErrors"
            />
            <BaseAutocomplete
              v-model="form.role"
              :label="`${$t('utils.function')} *`"
              solo
              outlined
              :items="COLLABORATOR_ROLE"
              item-text="name"
              item-value="id"
              :error-messages="roleErrors"
              :has-error="!!roleErrors"
            />
          </v-form>
        </div>
      </template>
      <template #actions class="d-flex justify-end">
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="resetForm"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="inviteCollaborator">
          {{ $t("organization.invite") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import {
  getOrganizationInvitations,
  postOrganizationInvitationRemind,
  inviteCollaborator,
} from "../../Services/organizationDashboard.service";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "OrganizationDashboardInvitations",
  mixins: [validationMixin],
  data() {
    return {
      invitations: [],
      isFetchingInvitations: false,
      submittingRemind: false,
      COLLABORATOR_ROLE: [
        {
          name: this.$t("organization.manager"),
          id: "MANAGER",
        },
        {
          name: this.$t("organization.member"),
          id: "MEMBER",
        },
      ],
      INVITATIONS_LIST_HEADERS: [
        {
          text: this.$tc("utils.email", 1),
          value: "email",
        },
        {
          text: this.$tc("utils.function"),
          value: "function",
        },
        {
          text: this.$tc("utils.action", 1),
          sortable: false,
        },
      ],
      showDialog: false,
      form: {
        email: null,
        role: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      activeOrganizationId: (state) =>
        state.organization.activeOrganization
          ? state.organization.activeOrganization.id
          : null,
    }),
    emailErrors() {
      if (!this.$v.form.role.$dirty) {
        return "";
      }
      if (!this.$v.form.email.required) {
        return "Le mail est requis";
      }
      if (!this.$v.form.email.email) {
        return "Vous devez saisir une adresse mail valide";
      }
      return "";
    },
    roleErrors() {
      if (!this.$v.form.role.$dirty) {
        return "";
      }
      if (!this.$v.form.role.required) {
        return "Vous devez saisir une fonction";
      }
      return "";
    },
  },
  watch: {
    activeOrganizationId() {
      this.reset();
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    resetForm() {
      this.form = {
        email: null,
        role: null,
      };
      this.$v.form.$reset();
      this.toggleDialog(false);
    },
    reset() {
      this.resetForm();
      this.isFetchingInvitations = false;
      this.invitations = [];
    },
    async initialize() {
      const organizationId = this.activeOrganizationId;
      this.isFetchingInvitations = true;
      const res = await getOrganizationInvitations(organizationId);
      if (organizationId === this.activeOrganizationId) {
        this.invitations = res.data.results;
      }
      this.isFetchingInvitations = false;
    },
    toggleDialog(state) {
      this.showDialog = state;
    },
    async inviteCollaborator() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      let newInvitation = {
        email: this.form.email,
        function: this.form.role,
      };
      try {
        const res = await inviteCollaborator(
          this.activeOrganizationId,
          newInvitation
        );
        this.invitations.push(res.data);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.invitationSent"),
          type: "SUCCESS",
        });
      } catch (error) {
        if (
          error.response.data.email &&
          error.response.data.email[0] ===
            "Cet email figure déja parmi les invitations envoyées"
        ) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t(
              "organization.errorInvitationSentEmailAlreadyExist"
            ),
            type: "ERROR",
          });
        } else {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("organization.errorInvitationSent"),
            type: "ERROR",
          });
        }
      }
      this.resetForm();
    },
    async submitRemind(invitation) {
      this.submittingRemind = true;
      try {
        await postOrganizationInvitationRemind({
          organizationId: this.activeOrganizationId,
          invitationId: invitation.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.invitationSent"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.errorInvitationSent"),
          type: "ERROR",
        });
      }
      this.submittingRemind = false;
    },
  },
};
</script>
