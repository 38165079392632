<template>
  <div>
    <Board>
      <template #main>
        <BaseTitle class="d-block mb-4" v-text="$tc('campaign.record', 1)" />
        <BaseParagraph
          class="d-block mb-1"
          v-text="$t('campaign.records.description')"
        />
        <BaseParagraph
          v-text="
            $t('campaign.records.agreedRecords', {
              count:
                activeCampaign.recordsAgreed &&
                activeCampaign.recordsAgreed.length
                  ? activeCampaign.recordsAgreed.length
                  : 0,
            })
          "
        />
        <CampaignDetailRecordsList
          :list-headers="recordsHeaders"
          :records="activeCampaign.recordsAgreed"
          :record-status="'AGREED'"
          :is-loading-records="isLoadingRecordsAgreed"
        />
        <BaseExpensionPanels class="my-4" multiple>
          <BaseExpensionPanel
            class="disagreed-panel mb-4"
            expand
            :disabled="isLoadingRecordsDisagreed"
            eager
            background-color="secondary"
          >
            <template slot="header">
              <v-skeleton-loader
                v-if="isLoadingRecordsDisagreed"
                class="skeleton"
                type="text"
              />
              <BaseParagraph
                v-else
                color="secondary"
                v-text="
                  $t('campaign.records.disagreedRecords', {
                    count:
                      activeCampaign.recordsDisagreed &&
                      activeCampaign.recordsDisagreed.length
                        ? activeCampaign.recordsDisagreed.length
                        : 0,
                  })
                "
              />
            </template>
            <template slot="content">
              <CampaignDetailRecordsList
                :list-headers="recordsHeaders"
                :records="activeCampaign.recordsDisagreed"
                :record-status="'DISAGREED'"
                :is-loading-records="isLoadingRecordsDisagreed"
              />
            </template>
          </BaseExpensionPanel>
          <BaseExpensionPanel
            :disabled="isLoadingRecordsInProgress"
            class="in-progress-panel"
            expand
            eager
          >
            <template slot="header">
              <v-skeleton-loader
                v-if="isLoadingRecordsInProgress"
                class="skeleton"
                type="text"
              />
              <BaseParagraph
                v-else
                color="accent"
                v-text="
                  $t('campaign.records.inProgressRecords', {
                    count:
                      activeCampaign.recordsInProgress &&
                      activeCampaign.recordsInProgress.length
                        ? activeCampaign.recordsInProgress.length
                        : 0,
                  })
                "
              />
            </template>
            <template slot="content">
              <CampaignDetailRecordsList
                :list-headers="recordsHeaders"
                :records="activeCampaign.recordsInProgress"
                :record-status="'IN_PROGRESS'"
                :is-loading-records="isLoadingRecordsInProgress"
              />
            </template>
          </BaseExpensionPanel>
        </BaseExpensionPanels>
      </template>
    </Board>
    <RelaunchInvitationRecordDialog
      :reminders="activeCampaign.recordsInProgress"
    />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import CampaignDetailRecordsList from "../Components/CampaignDetailRecordsList";

import { mapState } from "vuex";
import RelaunchInvitationRecordDialog from "../../Folder/Components/RelaunchInvitationRecordDialog.vue";

export default {
  name: "CampaignDetailRecords",
  components: {
    Board,
    CampaignDetailRecordsList,
    RelaunchInvitationRecordDialog,
  },
  props: {
    isFetchingActiveCourse: {
      type: Boolean,
      required: true,
    },
    isFetchingActiveCampaign: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      recordsHeaders: [
        {
          text: this.$tc("organization.client", 1),
          value: "user.email",
          sort: (a, b) => a.localeCompare(b),
        },
        {
          text: `${this.$tc("utils.date", 1)}`,
          value: "dateCreated",
          sort: (a, b) => a - b,
        },
        {
          text: `${this.$t("utils.dateRelaunch")}`,
          value: "dateCreated",
          sort: (a, b) => a - b,
        },
        {
          value: "data-table-expand",
        },
      ],
      isLoadingRecordsAgreed: false,
      isLoadingRecordsDisagreed: false,
      isLoadingRecordsInProgress: false,
    };
  },
  computed: {
    ...mapState({
      activeCampaign: (state) => state.course.activeCampaign,
    }),
  },
  created: function () {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isLoadingRecordsAgreed = true;
      this.isLoadingRecordsDisagreed = true;
      this.isLoadingRecordsInProgress = true;

      Promise.all([
        await this.$store.dispatch(
          "course/fetchOrganizationCourseCampaignRecordsAgreed",
          {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            campaignId: this.activeCampaign.id,
          }
        ),
        await this.$store.dispatch(
          "course/fetchOrganizationCourseCampaignRecordsDisagreed",
          {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            campaignId: this.activeCampaign.id,
          }
        ),
        await this.$store.dispatch(
          "course/fetchOrganizationCourseCampaignRecordsInProgress",
          {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            campaignId: this.activeCampaign.id,
          }
        ),
      ]);
      this.isLoadingRecordsAgreed = false;
      this.isLoadingRecordsDisagreed = false;
      this.isLoadingRecordsInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .disagreed-panel {
  background-color: #fef9fa !important;
}
::v-deep .disagreed-panel .v-expansion-panel-content {
  background-color: white;
}

::v-deep .in-progress-panel {
  background-color: #e6d6ff !important;
}
::v-deep .in-progress-panel .v-expansion-panel-content {
  background-color: white;
}
.skeleton {
  height: 17px;
  width: 100%;
  ::v-deep {
    .v-skeleton-loader__bone {
      height: 17px;
    }
  }
}
</style>
