var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":_vm.isMobile ? 'display' : ''}},[(_vm.displayMenu && !_vm.showMenuBurger)?_c('div',{staticClass:"px-4 pt-3",attrs:{"id":"menu_container"}},[_c('v-btn',{staticClass:"close_btn",attrs:{"icon":""},on:{"click":function($event){_vm.isMobile ? _vm.hideMenu() : _vm.onClickCloseMenuDesktop()}}},[_c('v-icon',{attrs:{"large":"","color":"green darken-2"}},[_vm._v(" $mdiClose ")])],1),_c('div',{staticClass:"menu-item d-flex flex-column align-start mt-9"},[(_vm.isAuthenticated)?[_c('v-list',{staticClass:"nav_list",attrs:{"nav":"","dense":""}},[_c('v-list-item',{staticClass:"mb-4",attrs:{"link":"","to":{ name: 'WalletHome' }},on:{"click":function($event){_vm.isMobile && _vm.hideMenu()}}},[_c('WalletNavigationLink',{attrs:{"link-name":_vm.$t('wallet.mySpace'),"image-name":_vm.$findStatic(
                    _vm.isMobile
                      ? 'cosmoWalletAssistantDark'
                      : 'cosmoWalletAssistant'
                  ),"image-required":"","class-name":_vm.$route.name === 'WalletHome'
                    ? 'active_link'
                    : _vm.isMobile
                    ? 'default_link'
                    : ''}})],1),_vm._l((_vm.organizations),function(organization){return _c('v-list-item',{key:organization.id,staticClass:"mb-4 ml-8",attrs:{"to":{
                name: 'WalletOrganizationRecords',
                params: {
                  organizationId: organization.id,
                },
              }},on:{"click":[function($event){_vm.isMobile && _vm.hideMenu()},function($event){$event.preventDefault();return (function () {
                  _vm.$store.commit(
                    'organization/setActiveOrganization',
                    organization
                  );
                }).apply(null, arguments)}]}},[_c('WalletNavigationLink',{attrs:{"link-name":organization.name,"image-name":_vm.$findStatic(
                    _vm.$route.name === 'WalletOrganizationRecords' &&
                      _vm.$route.params.organizationId === +organization.id
                      ? 'cosmoWalletActiveAssistant'
                      : 'cosmoWalletAssistant'
                  ),"class-name":_vm.$route.name === 'WalletOrganizationRecords' &&
                  _vm.$route.params.organizationId === +organization.id
                    ? 'active_link'
                    : _vm.isMobile
                    ? 'default_link'
                    : ''}})],1)})],2),(_vm.isMobile)?_c('v-list',{staticClass:"nav_list",attrs:{"nav":"","dense":""}},[_c('v-list-item',{staticClass:"mb-4",attrs:{"to":{
                name: 'WalletAccount',
              }},on:{"click":function($event){_vm.isMobile && _vm.hideMenu()}}},[_c('WalletNavigationLink',{attrs:{"link-name":_vm.$t('navigation.myAccount'),"image-name":_vm.$findStatic(
                    _vm.$route.name === 'WalletAccount'
                      ? 'cosmoWalletActiveProfile'
                      : 'cosmoWalletProfileDark'
                  ),"image-required":"","class-name":_vm.$route.name === 'WalletAccount'
                    ? 'active_link'
                    : _vm.isMobile
                    ? 'default_link'
                    : ''}})],1)],1):_vm._e()]:_vm._e()],2)],1):_vm._e()]),(_vm.displayMenu)?_c('div',{staticClass:"backdrop",on:{"click":function($event){_vm.isMobile && _vm.hideMenu()}}}):_vm._e(),(_vm.showMenuBurger && !_vm.isMobile)?_c('div',{staticClass:"menu-burger-button"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showMenu()}}},[_c('v-icon',{staticClass:"menu-burger-icon",attrs:{"x-large":""}},[_vm._v(" $mdiMenu ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }