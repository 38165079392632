<template>
  <BaseFrontOfficeWalletWrapper
    :mobile-title-image="$findStatic('walletDefaultAvatar')"
    :mobile-title-message="$tc('account.introduction', 1)"
  >
    <template #title>
      <BaseFrontOfficeTitle v-text="$t('navigation.myAccount')" />
    </template>
    <!-- Personal Data board -->
    <BaseFrontOfficeBoard>
      <div class="d-flex align-center mb-6">
        <BaseFrontOfficeSubtitle
          class="pr-4"
          v-text="$t('account.myPersonalData')"
        />
        <BaseFrontOfficeDivider />
      </div>
      <div class="mx-7">
        <BaseFrontOfficeParagraph
          class="d-block mb-4"
          v-text="$tc('account.introduction', 1)"
        />
        <BaseFrontOfficeBoard>
          <div class="d-flex align-center">
            <div class="cosmo-account-edit-avatar">
              <BaseFrontOfficeAvatar
                width="95"
                height="95"
                min-width="auto"
                class="user-avatar mr-4 d-none d-md-block"
                :src="
                  (user && user.avatar) || $findStatic('walletDefaultAvatar')
                "
                contain
              />
              <BaseIcon
                v-ripple
                icon="$mdiEdit"
                class="icon"
                @click="$store.commit('account/setIsEditAvatarDialog', true)"
              />
            </div>
            <div class="flex-grow-1">
              <BaseFrontOfficeParagraph
                v-text="`${$t('account.connexionEmail')} ${user && user.email}`"
              />
            </div>
          </div>
        </BaseFrontOfficeBoard>
      </div>
    </BaseFrontOfficeBoard>

    <BaseFrontOfficeBoard>
      <div class="d-flex align-center mb-6">
        <BaseFrontOfficeSubtitle class="pr-4" v-text="$t('account.security')" />
        <BaseFrontOfficeDivider />
      </div>
      <div class="d-flex flex-wrap justify-center justify-md-end">
        <BaseFrontOfficeButton
          type="secondary"
          min-height="45"
          class="mr-4 mb-xs-3 mobile__button action__button"
          @click.prevent="$router.push({ name: 'WalletPasswordChange' })"
          v-text="$t('account.editPassword')"
        />
        <BaseFrontOfficeButton
          class="mobile__button"
          type="secondary"
          min-height="45"
          @click.prevent="$router.push({ name: 'WalletDeleteAccount' })"
          v-text="$t('account.delete')"
        />
      </div>

      <div class="d-flex justify-center justify-md-end mt-4">
        <BaseFrontOfficeButton
          class="mobile__button"
          type="tertiary"
          min-height="45"
          @click="logOut()"
          v-text="$t('navigation.signOut')"
        />
      </div>
    </BaseFrontOfficeBoard>

    <template v-if="!isFetchingPersonalData && tmpOrganizations.length">
      <BaseFrontOfficeBoard
        v-for="(organization, i) in tmpOrganizations"
        :key="`o-${i}`"
      >
        <div class="d-flex align-center mb-6">
          <BaseFrontOfficeSubtitle
            class="pr-4"
            v-text="$t('account.personalInformation')"
          />
          <BaseFrontOfficeDivider />
        </div>
        <div class="mx-7">
          <BaseFrontOfficeParagraph
            class="d-block mb-4"
            v-text="$tc('account.introduction', 2)"
          />
          <BaseFrontOfficeBoard>
            <div class="personal_data_list">
              <WalletLabelData
                v-for="(personalData, pi) in [...organization.personalDatas]"
                :key="`pi-${personalData.id}-${pi}`"
                :type-question="personalData.datatype.typeQuestion"
                :content="personalData ? personalData.content : null"
                :name="personalData.datatype.name"
                @show="displayPersonalData(personalData)"
              />
            </div>
          </BaseFrontOfficeBoard>
        </div>
      </BaseFrontOfficeBoard>
    </template>

    <WalletDisplayPersonalDataDialog
      :dialog-state="dialogState.displayPersonalData"
      :personal-data="activePersonalData"
      @close="
        (dialogState.displayPersonalData = false) && (activePersonalData = null)
      "
    />
    <WalletEditAvatarDialog />
  </BaseFrontOfficeWalletWrapper>
</template>

<script>
import { mapState } from "vuex";

import WalletLabelData from "../Components/WalletLabelData.vue";
import WalletDisplayPersonalDataDialog from "../Components/WalletDisplayPersonalDataDialog.vue";
import WalletEditAvatarDialog from "../Components/WalletEditAvatarDialog.vue";

import { revokeToken } from "@/core/Api/cosmoAxiosInstance";

import { fetchOrganizationPersonalData } from "@/modules/Wallet/Services/personalData.service";
import Organization from "@/core/Models/Organization";

export default {
  name: "WalletAccount",
  components: {
    WalletLabelData,
    WalletDisplayPersonalDataDialog,
    WalletEditAvatarDialog,
  },
  data() {
    return {
      isFetchingPersonalData: false,
      tmpOrganizations: [],
      activePersonalData: null,
      dialogState: {
        displayPersonalData: false,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      organizations: (state) => state.walletOrganization.organizations,
    }),
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.isFetchingPersonalData = true;
      await this.fetchOrganizationsPersonalData();
      this.isFetchingPersonalData = false;
    },
    async fetchOrganizationsPersonalData() {
      for (const organization of this.organizations) {
        this.tmpOrganizations.push(
          new Organization({
            ...organization,
            personalDatas: await fetchOrganizationPersonalData({
              organizationId: organization.id,
            }),
          })
        );
      }
    },
    async logOut() {
      if (await revokeToken()) {
        this.$store.dispatch("core/resetApp");
        this.$store.commit("account/setUser", null);
        this.$router.push("/wallet/auth/login/");
        localStorage.removeItem("oauthAdmin");
      }
    },
    displayPersonalData(personalData) {
      this.activePersonalData = personalData;
      this.dialogState.displayPersonalData = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cosmo-account-edit-avatar {
  position: relative;
}
::v-deep .cosmo-account-edit-avatar .v-image {
  cursor: pointer;
}
::v-deep .cosmo-account-edit-avatar .v-image::after {
  content: "";
  background-color: grey;
  height: 95px;
  width: 95px;
  opacity: 0;
}
::v-deep .cosmo-account-edit-avatar:hover .v-image::after {
  transition: all 0.5s ease;
  opacity: 0.7;
}
::v-deep.cosmo-account-edit-avatar .v-icon {
  cursor: pointer;
  position: absolute;
  top: 1px;
  height: 95px;
  width: 95px;
  color: white !important;
  opacity: 0;
}
::v-deep.cosmo-account-edit-avatar:hover .v-icon {
  transition: all 0.5s ease;
  opacity: 1;
}
@media all and (max-width: 600px) {
  .mobile__button {
    min-width: 90% !important;
    margin: 0px !important;
  }
  .action__button {
    margin-bottom: 16px !important;
  }
}
.personal_data_list {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  @media (min-width: 600px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
  @media (min-width: 1130px) {
    grid-column-gap: calc(4% / 2);
    grid-row-gap: 16px;
    grid-template-columns: repeat(3, 32%);
  }
}
</style>
