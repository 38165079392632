<template>
  <BaseModal
    :value="isOpen"
    :modal-title="$t('course.deleteQuestion')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="message" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="deleteQuestion()">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CourseQuestionDeleteDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      activeStep: (state) => state.course.activeStep,
      activeQuestion: (state) => state.course.activeQuestion,
    }),
    message() {
      return this.$t("course.deleteQuestionActionAsk", {
        count: this.activeQuestion && this.activeQuestion.answersCount,
      });
    },
  },
  methods: {
    deleteQuestion() {
      this.$store
        .dispatch("course/deleteQuestion", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.activeStep.id,
          questionId: this.activeQuestion.id,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.deleteQuestionConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.$emit("close");
    },
  },
};
</script>
