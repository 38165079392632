<template>
  <div class="actions_container">
    <v-progress-linear v-if="isLoading || isSubmitting" active indeterminate />
    <template v-if="!isLoading">
      <div
        v-if="!status.actions || !status.actions.length"
        class="info_section"
        v-text="$t('utils.noAvailableAction')"
      />
      <div v-if="status.actions">
        <div v-for="(action, index) in status.actions" :key="index">
          <v-checkbox
            :input-value="action.enable"
            :label="action.name"
            :disabled="isSubmitting"
            readonly
            @click="toggleStatusAction(action)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "WorkflowStatusActions",
  props: {
    status: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("workflow/fetchStatusActions", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.$route.params.workflowId,
        statusId: this.status.id,
      });
      this.isLoading = false;
    },
    async toggleStatusAction(action) {
      this.isSubmitting = true;
      try {
        await this.$store.dispatch("workflow/toggleStatusAction", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.status.id,
          actionId: action.id,
          enable: !action.enable,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(!action.enable ?
            "workflow.status.snackbar.success.actionAssignedSuccessfully" :
            "workflow.status.snackbar.success.actionUnAssignedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.error.errorAssigningAction"
          ),
          type: "ERROR",
        });
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions_container {
  .info_section {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707080;
  }
}
</style>
