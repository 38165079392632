<template>
  <v-list-item-title v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-list-item-title>
</template>

<script>
export default {
  name: "BaseListItemTitle",
};
</script>
