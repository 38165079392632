<template>
  <div class="drag_container">
    <div
      class="file_drag_container"
      :class="classes"
      @click.stop="$refs['fileDrag'].click()"
      @mouseenter="isDragged = true"
      @mouseleave="isDragged = false"
      @drop.prevent="onDrop"
      @dragover.prevent="isDragged = true"
      @dragenter.prevent="isDragged = true"
      @dragleave.prevent="isDragged = false"
    >
      <div class="img_container">
        <img :src="$findStatic('groupView')" />
      </div>
      <div class="drag_label" v-text="customLabel" />
      <div class="drag_label_btn" v-text="$t('browseFiles')" />
      <input
        v-show="false"
        ref="fileDrag"
        type="file"
        :value="fileInputValue"
        :multiple="multiple"
        @input="handleInputChange"
      />
    </div>
    <div v-if="loading" class="progress">
      <v-progress-circular indeterminate />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFileDrag",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    modelValue: {
      type: [Array],
      default: () => [],
    },
    hasError: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDragged: false,
      fileInputValue: null,
      loading: false,
    };
  },
  computed: {
    classes() {
      const clas = [];
      if (this.isDragged) clas.push("dragged");
      if (this.hasError) clas.push("error_file");
      return clas;
    },
    customLabel() {
      return this.label ? this.label : this.$tc("dragDocuments", 0);
    },
  },
  methods: {
    onDrop(event) {
      this.isDragged = false;
      const tmps = Array.from(event.dataTransfer.files);
      const files = this.multiple ? tmps : [tmps[0]];
      this.insertFiles(files);
    },
    handleInputChange(event) {
      if (!event.target?.files) return;
      this.insertFiles(Array.from(event.target?.files));
    },
    async insertFiles(files) {
      if (!files) return;
      this.loading = true;
      let tmpFiles = this.modelValue?.length ? this.modelValue : [];
      const convertedFiles = [];
      for (const file of files) {
        convertedFiles.push({
          name: file.name,
          url: await this.fileToBase64(file),
        });
      }
      if (this.multiple) tmpFiles = [...tmpFiles, ...convertedFiles];
      else tmpFiles = convertedFiles;
      this.loading = false;
      this.$emit("changeValue", tmpFiles);
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drag_container {
  position: relative;
  .file_drag_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed #9bbdff;
    background: rgba(245, 248, 255, 0.4);
    padding: 20px 0;
    gap: 8px;
    cursor: pointer;
    background: #fff;
    &.dragged {
      background: rgba(155, 189, 255, 0.18);
    }
    &.error_file {
      border: 1px dashed #ff5267;
    }
    .img_container {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: #e9f1ff;
      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    }
    .drag_label {
      color: #353542;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .drag_label_btn {
      text-align: center;
      border-radius: 5px;
      border: 1px solid #eeeef7;
      background: #fff;
      padding: 8px 16px;
      color: #2536cc;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .progress {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
