<template>
  <v-card
    :light="true"
    :class="{ rounded: isRounded }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    isRounded: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.rounded {
  border-radius: 12px !important;
}
</style>
