import { render, staticRenderFns } from "./WalletQuestionForm.vue?vue&type=template&id=d5254874&scoped=true"
import script from "./WalletQuestionForm.vue?vue&type=script&lang=js"
export * from "./WalletQuestionForm.vue?vue&type=script&lang=js"
import style0 from "./WalletQuestionForm.vue?vue&type=style&index=0&id=d5254874&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5254874",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VBtn,VCheckbox,VDatePicker,VFileInput,VForm,VMenu,VRadio,VRadioGroup,VSelect,VSpacer,VTextField,VTextarea,VTimePicker})
