<template>
    <v-icon>
      {{ svgPath }}
    </v-icon>
  </template>
  
  <script>
  import { mdiEmailRemoveOutline  } from "@mdi/js";
  export default {
    name: "MdiEmailRemoveOutline",
    data() {
      return {
        svgPath: mdiEmailRemoveOutline ,
      };
    },
  };
  </script>
  