var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseDataTable',{staticClass:"elevation-1 mb-6",attrs:{"headers":_vm.COLLABORATORS_HEADER,"items":_vm.activeOrganization.collaborators,"loading":_vm.isLoadingActiveOrganizationCollaborators,"loading-text":_vm.$t('utils.loadingData')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.user.email)}})],1),_c('td',[(item.user.fullName)?_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.user.fullName)}}):_c('BaseParagraph',{attrs:{"italic":""},domProps:{"textContent":_vm._s(_vm.$t('answer.noContent'))}})],1),_c('td',[(/^MANAGER$/.test(item.status))?_c('BaseParagraph',{domProps:{"textContent":_vm._s(_vm.$t('organization.manager'))}}):(/^MEMBER$/.test(item.status))?_c('BaseParagraph',{domProps:{"textContent":_vm._s(_vm.$t('organization.member'))}}):_vm._e()],1),(_vm.userStatus === 'MANAGER')?_c('td',[_c('div',{staticClass:"py-4 px-4"},[_c('BaseButtonIcon',{attrs:{"color":"secondary","x-small":"","icon":"$mdiTrashCanOutline"},on:{"click":function () {
                  _vm.$store.commit('organization/SET_ACTIVE_COLLABORATOR', item);
                  _vm.$store.commit(
                    'organization/SET_REMOVE_COLLABORATOR_DIALOG_STATUS',
                    true
                  );
                }}})],1)]):_vm._e()])]}}])}),_c('OrganizationRemoveCollaboratorDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }