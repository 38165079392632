<template>
  <div>
    <BaseDataTable
      :items="status.targetStatuses"
      :headers="TARGET_STATUSES_HEADERS"
      :itemps-per-page="-1"
      hide-default-footer
    >
      <template #item="{ item }">
        <tr>
          <td>
            <BaseParagraph type="primary" v-text="item.statusTo.name" />
          </td>
          <td>
            <BaseParagraph type="primary" v-text="item.label" />
          </td>
          <td>
            <div class="d-flex justify-end">
              <BaseButtonIcon
                x-small
                color="primary"
                icon="$mdiPencilOutline"
                class="mr-2"
                @click="
                  () => {
                    $store.commit('workflow/setActiveStatus', status);
                    $store.commit('workflow/setActiveTargetStatus', item);
                    $store.commit(
                      'workflow/setStatusTargetStatusCreateAndEditDialog',
                      true
                    );
                  }
                "
              />
              <BaseButtonIcon
                color="secondary"
                x-small
                icon="$mdiTrashCanOutline"
                @click="
                  () => {
                    $store.commit('workflow/setActiveStatus', status);
                    $store.commit('workflow/setActiveTargetStatus', item);
                    $store.commit(
                      'workflow/setDeleteStatusTargetStatusDialog',
                      true
                    );
                  }
                "
              />
            </div>
          </td>
        </tr>
      </template>
    </BaseDataTable>
    <BaseDivider grey class="my-2" />
    <div class="d-flex justify-center mt-4">
      <BaseButton
        color="primary"
        :icon="'$mdiClipboardEditOutline'"
        small
        class="mb-2"
        @click="
          () => {
            $store.commit('workflow/setActiveStatus', status);
            $store.commit(
              'workflow/setStatusTargetStatusCreateAndEditDialog',
              true
            );
          }
        "
      >
        {{ $t("workflow.status.addStatusCible") }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WorkflowDetailStatusTargetStatuses",
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TARGET_STATUSES_HEADERS: [
        {
          text: this.$tc("organization.status", 1) + " cible",
          value: "name",
        },
        {
          text: this.$t("question.types.message"),
          value: "message",
        },
        {
          text: this.$tc("utils.action", 2),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
    }),
  },
  created() {
    this.reset();
    this.initialize();
  },
  methods: {
    reset() {
      this.$store.dispatch("workflow/resetTargetStatuses", this.status.id);
    },
    initialize() {
      this.$store.dispatch("workflow/fetchTargetStatuses", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.$route.params.workflowId,
        statusId: this.status.id,
      });
    },
  },
};
</script>
