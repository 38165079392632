<template>
  <v-progress-circular v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-progress-circular>
</template>

<script>
export default {
  name: "BaseProgressCircular",
};
</script>
