export default class WizideeData {
  constructor({
    // Additional CIN DATA
    birthday = "",
    birthPlace = "",
    firstName = "",
    lastName = "",
    usualName = "",
    height = "",
    // Additional Residence Certificate Data
    address = "",
    emissionDate = "",
    // Additional Permit Data
    authority = "",
    deliveryDate = "",
    expirationDate = "",
    driverList = "",
    number = "",
    photo = "",
    signature = "",
    // Additional RIB
    iban = "",
    bic = "",
    holder = "",
    // Additional VITAL_CARD
    socialNumber = "",
    establishment = {},
    establishmentAddress = {},
    establishmentPeriod = {},
    legalUnit = {},
    legalUnitPeriod = {},
  } = {}) {
    this.birthDate = birthday ? birthday.replaceAll(".", "/") : "";
    this.birthPlace = birthPlace;
    this.firstName = firstName;
    this.name = lastName;
    this.useName = usualName;
    this.height = height;
    this.address = address;
    this.addressFullText = address;
    this.releaseDate = emissionDate
      ? emissionDate
      : deliveryDate
      ? deliveryDate.replaceAll(" ", "/").replaceAll(".", "/")
      : "";
    this.authority = authority;
    this.validityDate = expirationDate
      ? expirationDate.replaceAll(".", "/")
      : "";
    this.permitList = driverList;
    this.permitId = number;
    this.photo = photo;
    this.signature = signature;
    this.iban = iban;
    this.bic = bic;
    this.holder = holder;
    this.socialNumber = socialNumber;
    this.establishment = establishment;
    this.establishmentAddress = establishmentAddress;
    this.establishmentPeriod = establishmentPeriod;
    this.legalUnit = legalUnit;
    this.legalUnitPeriod = legalUnitPeriod;
  }
}
