export class YousignMember {
  constructor({
    id = Number.NaN,
    email = "",
    firstName = "",
    lastName = "",
    phoneNumber = "",
    order = Number.NaN,
    proof = "",
    dateCreated = null,
    dateUpdated = null,
  } = {}) {
    this.id = Number.parseInt(id);
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.order = order;
    this.proof = proof;
    this.dateCreated = dateCreated ? new Date(dateCreated) : null;
    this.dateUpdated = dateUpdated ? new Date(dateUpdated) : null;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }
}
