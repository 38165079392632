<template>
  <svg viewBox="0 0 29 26" fill="none">
    <path
      d="M20 5H6C5.20435 5 4.44129 5.31607 3.87868 5.87868C3.31607 6.44129 3 7.20435 3 8V18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H20C20.7956 21 21.5587 20.6839 22.1213 20.1213C22.6839 19.5587 23 18.7956 23 18V8C23 7.20435 22.6839 6.44129 22.1213 5.87868C21.5587 5.31607 20.7956 5 20 5ZM19.59 7L13.71 12.88C13.617 12.9737 13.5064 13.0481 13.3846 13.0989C13.2627 13.1497 13.132 13.1758 13 13.1758C12.868 13.1758 12.7373 13.1497 12.6154 13.0989C12.4936 13.0481 12.383 12.9737 12.29 12.88L6.41 7H19.59ZM21 18C21 18.2652 20.8946 18.5196 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V8.41L10.88 14.29C11.4425 14.8518 12.205 15.1674 13 15.1674C13.795 15.1674 14.5575 14.8518 15.12 14.29L21 8.41V18Z"
      fill="#353542"
    />
    <circle
      cx="22"
      cy="7"
      r="5.25"
      fill="#FBFBFB"
      stroke="#353542"
      stroke-width="1.5"
    />
    <path d="M22 4V7.33333L24 9" stroke="#353542" />
  </svg>
</template>

<script>
export default {
  name: "ScheduledMail",
};
</script>
