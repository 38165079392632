<template>
  <div class="text-description" :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeDescription",
  props: {
    bold: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: () => "walletPrimary",
      validator: function (value) {
        return (
          ["walletPrimary", "walletAccent", "disabled"].indexOf(value) !== -1
        );
      },
    },
    uppercase: {
      type: Boolean,
      default: () => false,
    },
    italic: {
      type: Boolean,
      dafault: () => false,
    },
    truncate: {
      type: Boolean,
      dafault: () => false,
    },
  },
  computed: {
    classObject() {
      return {
        "text-uppercase": this.uppercase,
        [`${this.color ? this.color + "--text" : "text--" + this.text}`]: true,
        "font-italic": this.italic,
        "text-description--bold": this.bold,
        "text-truncate d-block": this.truncate,
      };
    },
  },
};
</script>
<style scoped>
.text-description {
  color: var(--v-walletGrey-base);
  font-size: 10px !important;
  font-weight: 400;
  line-height: 14px;
  font-family: var(--cosmo-font-nunito);
}
.text-description--bold {
  font-weight: 700;
}
</style>
