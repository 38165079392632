<template>
  <v-dialog v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, attrs }">
      <div>
        <slot name="toggle" v-bind="attrs" v-on="on" />
      </div>
    </template>
    <slot name="modal" />
  </v-dialog>
</template>

<script>
export default {
  name: "BaseFrontOfficeDialog",
};
</script>

<style scoped>
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>
