<template>
  <div class="select-container" v-on="$listeners">
    <input type="checkbox" :checked="checked"/>
    <span class="label" v-text="label" />
  </div>
</template>

<script>
export default {
  name: "BaseSelectType",
  props: {
    label: {
      type: String,
      default: "Label",
    },
    checked: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
input {
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-color: #cfcfdc;
}
.select-container {
  height: 56px;
  display: flex;
  gap: 8px;
  justify-content: start;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #cfcfdc;
}
.label {
  color: var(--principale-gris-noir, #353542);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
