<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiRayStartArrow } from "@mdi/js";
export default {
  name: "MdiRayStartArrow",
  data() {
    return {
      svgPath: mdiRayStartArrow,
    };
  },
};
</script>
