var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email_item_container"},[_c('FolderEmailItemHeader',{attrs:{"email":_vm.email,"email-client":_vm.emailClient,"expanded":_vm.expanded},on:{"expandChange":function($event){return _vm.changeExpandStatus()}}}),(_vm.expanded)?[_c('div',{staticClass:"item_body"},[_c('div',{staticClass:"separator"}),_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"email_details"},[_c('div',{staticClass:"email_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('utils.contact', 1))}}),_c('div',{staticClass:"content"},[_c('BaseDataTable',{staticClass:"contact_list",attrs:{"headers":_vm.EMAIL_CLIENT_HEADER,"items":_vm.emailClient,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"contact_item"},[_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.email)}})],1)]),_c('td',[_c('div',{staticClass:"chips",class:item.statusClass,domProps:{"textContent":_vm._s(item.statusLabel)}})]),_c('td',{staticClass:"text-center"},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(
                          item.reminders.length - 1 > 0
                            ? item.reminders.length - 1
                            : 0
                        )}})],1),_c('td',[(item.reminders.length)?_c('div',[_c('button',{staticClass:"relaunch_btn",class:{ relaunched: item.reminders.length > 1 },on:{"click":function($event){return _vm.openRemindDialog(item)}}},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiEmailOutline"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.relaunch'))}})],1)]):_c('div',{domProps:{"textContent":_vm._s(' - ')}})])])]}}],null,false,1320329333)})],1)]),(_vm.email.content)?_c('div',{staticClass:"email_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s('Message')}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"desc_content"},[_c('BaseMarkdownViewer',{attrs:{"content":_vm.email.content,"is-text-caption":""}})],1)])]):_vm._e(),(_vm.email.files.length)?_c('div',{staticClass:"email_detail_item"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s('Fichiers')}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"documents_card"},[_c('div',{staticClass:"email_files"},[_vm._l((_vm.email.files),function(file,i){return _c('div',{key:("f-" + i),staticClass:"offer_file_item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.open(file)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"label",domProps:{"textContent":_vm._s('Nom du fichier:')}}),_c('div',{staticClass:"file_name",domProps:{"textContent":_vm._s(file.name)}})]),_c('div',{staticClass:"file"},[(file.url.includes('.pdf'))?_c('BasePdfViewer',{attrs:{"pdf":file.url,"page":1}}):(
                            ['.docx', '.doc', '.pptx'].some(function (v) { return file.url.includes(v); }
                            )
                          )?_c('base-icon',{attrs:{"icon":"$mdiFileDocument","color":"primary"}}):_c('BaseImage',{attrs:{"src":file.url}})],1),_c('v-btn',{staticClass:"down_btn",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(file.url, file.name)}}},[_c('BaseIcon',{attrs:{"icon":"$mdiDownload"}})],1)],1)})],2)])])]):_vm._e()])])])]:_vm._e(),_c('FolderRelaunchEmailDialog',{attrs:{"email":_vm.email,"email-client":_vm.selectEmail,"dialog-state":_vm.remindDialogState,"organization-id":_vm.organizationId,"folder-id":_vm.folderId},on:{"close":function($event){_vm.remindDialogState = false}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }