<template>
  <TheFrontOfficeBaseApp v-if="isAppReady">
    <TheFrontOfficeNavigationBar ref="navigation" />
    <div id="cosmo-wallet-wrapper">
      <div class="cosmo-wallet-wrapper-background" />
      <div class="justify-end pa-4 toolbar">
        <v-app-bar-nav-icon color="white" @click="onShowMenu()" />
      </div>
      <TheFrontOfficeAppBarAccount />
      <v-main>
        <router-view />
      </v-main>
    </div>
    <TheFrontOfficeFooter />
    <TheFrontOfficeSnackbar />
  </TheFrontOfficeBaseApp>
</template>

<script>
import TheFrontOfficeBaseApp from "@/modules/Wallet/Components/TheFrontOfficeBaseApp";
import TheFrontOfficeNavigationBar from "@/modules/Wallet/Components/TheFrontOfficeNavigationBar";
import TheFrontOfficeFooter from "@/modules/Wallet/Components/TheFrontOfficeFooter";
import TheFrontOfficeSnackbar from "@/modules/Wallet/Components/TheFrontOfficeSnackbar";
import TheFrontOfficeAppBarAccount from "../../modules/Wallet/Components/TheFrontOfficeAppBarAccount.vue";
import Oauth from "../Models/Oauth";
// import { refreshToken } from "../Api/cosmoAxiosInstance";
import { mapState } from "vuex";
import { setBearerToken } from "../Api/cosmoAxiosInstance";

export default {
  name: "FrontofficeLayout",
  components: {
    TheFrontOfficeBaseApp,
    TheFrontOfficeNavigationBar,
    TheFrontOfficeFooter,
    TheFrontOfficeSnackbar,
    TheFrontOfficeAppBarAccount,
  },
  data() {
    return {
      isAppReady: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      hasLoggedNormaly: (state) => state.core.hasLoggedNormaly,
      isAuthenticated: (state) => state.account.isAuthenticated,
    }),
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isAppReady = false;
      if (Oauth.localStorage && !this.hasLoggedNormaly) {
        const oauth = Oauth.localStorage;
        try {
          setBearerToken(oauth.accessToken);
        } catch (error) {
          this.$store.dispatch("core/resetApp");
          this.redirectToLogin();
        }
      }
      if (this.user == null) await this.$store.dispatch("account/fetchUser"); // we fetch user data only if its null
      if (this.isAuthenticated)
        await this.$store.dispatch("walletOrganization/fetchOrganizations");
      this.isAppReady = true;
    },
    redirectToLogin() {
      return this.$router.push({
        name: "Login",
        query: {
          next: this.$route.fullPath,
        },
      });
    },
    onShowMenu() {
      this.$refs.navigation.showMenu();
    },
  },
};
</script>

<style>
:root {
  --cosmo-font-nunito: "Nunito-SemiBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cosmo-font-nunito-secondary: "Nunito";
  --cosmo-gradient-primary: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 51.56%,
      rgba(252, 192, 15, 0.5) 100%
    ),
    #ff7074;
  --cosmo-gradient-secondary: linear-gradient(
      147.45deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    ),
    #ff5b60;
  --cosmo-gradient-tertiary: linear-gradient(
      102.88deg,
      #2f479d 40.71%,
      #965b89 73.04%,
      #ff7074 90.62%,
      rgba(255, 112, 116, 0.2) 101.58%
    ),
    #fcc00f;
}
</style>

<style lang="scss" scoped>
#cosmo-wallet-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
}

@media (max-width: 600px) {
  #cosmo-wallet-wrapper {
    margin-left: 0px;
    height: auto;
    width: auto;
  }
}
.toolbar {
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
}

.theme--light.v-sheet {
  border-color: auto !important;
}
.v-application .rounded-5 {
  border-radius: auto !important;
}
.v-application .pa-6 {
  padding: auto !important;
}
.v-application .mb-6 {
  margin-bottom: auto !important;
}
.v-application {
  background: none;
}

@media all and (max-width: 600px) {
  .cosmo-wallet-wrapper-background {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    background: linear-gradient(
        147.45deg,
        #2f479d 19.48%,
        rgba(47, 71, 157, 0) 144.95%
      ),
      #ff5b60;
  }
}
</style>
