<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('workflow.status.question.deleteChamp')"
    @click:outside="close"
    @keydown.esc="close"
    @close="close"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="message" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="close"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WorkflowStatusQuestionDeleteDialog",
  computed: {
    ...mapState({
      activeStatus: (state) => state.workflow.activeStatus,
      activeQuestion: (state) => state.workflow.activeQuestion,
      dialogState: (state) =>
        state.workflow.modals.isDeleteStatusQuestionDialogOpen,
    }),
    message() {
      return this.$t("workflow.status.question.deleteChampConfirmation", {
        count: this.activeQuestion && this.activeQuestion.answersCount,
      });
    },
  },
  methods: {
    close() {
      this.$store.commit("workflow/setActiveStatus", null);
      this.$store.commit("workflow/setActiveQuestion", null);
      this.$store.commit("workflow/setDeleteStatusQuestionDialog", false);
      this.$emit("close");
    },
    submit() {
      this.$store
        .dispatch("workflow/deleteWorkflowStatusQuestion", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          questionId: this.activeQuestion.id,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.deleteQuestionConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.close();
    },
  },
};
</script>
