<template>
  <div>
    <BaseModal
      :value="dialogState"
      :modal-title="$t('folder.editFolder')"
      is-unique
      @close="reset"
    >
      <template #modal>
        <v-form ref="form" @submit.prevent="submit()">
          <BaseTextField
            v-model="form.name"
            autofocus
            :label="`${$tc('utils.name', 1)} *`"
            :error-messages="nameErrors"
            :has-error="!!nameErrors"
            @changed="$v.form.name.$touch()"
            @blur="$v.form.name.$touch()"
          />
        </v-form>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton :disabled="$v.$invalid" type="primary" @click="submit()">
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "FolderEditNameDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.folderEditNameOpen,
      activeFolder: (state) => state.folder.activeFolder,
    }),
    organizationId() {
      return this.$route.params.organizationId;
    },
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("folder.createAndEditForm.validation.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("folder.createAndEditForm.validation.nameTooLong");
      }
      return "";
    },
  },
  watch: {
    dialogState(newVal) {
      if (newVal && this.activeFolder) {
        this.form.name = this.activeFolder.name;
      }
    },
  },
  methods: {
    reset() {
      this.form = {
        name: "",
      };
      this.$v.form.$reset();
      this.$store.commit("folder/SET_MODAL_FOlDER_EDIT_NAME", false);
    },
    submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.editFolder();
        this.reset();
      }
    },
    editFolder() {
      const payload = {
        name: this.form.name,
      };
      this.$store
        .dispatch("folder/updateFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$store.state.folder.activeFolder.id,
          payload,
        })
        .then(() => {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.createAndEditForm.successEdit"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.createAndEditForm.errorEdit"),
            type: "ERROR",
          });
        });
    },
  },
};
</script>
