<template>
  <BaseSheet elevation="2" class="rounded-10 pa-2 d-flex align-center">
    <div class="flex-grow-1 text-truncate d-block ml-3">
      <BaseDescription
        v-if="/^DATE$/.test(typeQuestion) && content"
        truncate
        bold
        color="primary"
        v-text="$d(new Date(content), 'short')"
      />
      <BaseDescription
        v-else-if="/^DATETIME$/.test(typeQuestion) && content"
        truncate
        bold
        color="primary"
        v-text="$d(new Date(content), 'full')"
      />
      <div
        v-else-if="/MULTIPLE_FILE/.test(typeQuestion)"
        class="d-flex flex-column"
      >
        <a
          v-for="(file, index) in content"
          :key="index"
          :href="file.url"
          target="_blank"
        >
          {{ `${index} - ${file.name}` }}
        </a>
      </div>
      <a
        v-else-if="/FILE|SIGNATURE/.test(typeQuestion)"
        :href="getUrl(content)"
        target="_blank"
      >
        <BaseImage
          v-if="isImage"
          :src="getUrl(content)"
          height="32px"
          width="72px"
          class="rounded-5"
          @error="imageLoadError"
        />
        <BaseDescription
          v-else
          truncate
          bold
          color="primary"
          v-text="$t('answer.seeFolder', { name: name })"
        />
      </a>
      <div
        v-else-if="/DATAGRID|ROW_DATAGRID/.test(typeQuestion) && content"
        style="cursor: pointer;"
        @click="$emit('show-answer', answer)"
      >
        <BaseDescription bold color="primary" v-text="$t('answer.seeTable')" />
      </div>
      <BaseDescription
        v-else-if="
          content &&
          /TEXT|TEXTEARA|SELECT|RADIOBUTTON|CHECKBOX/.test(typeQuestion)
        "
        truncate
        bold
        color="primary"
        v-text="content"
      />
      <BaseDescription
        v-else-if="
          /^NUMBER$/.test(typeQuestion) && !isNaN(parseInt(content, 10))
        "
        truncate
        bold
        color="primary"
        v-text="$n(content.toString())"
      />
      <BaseDescription v-else truncate italic v-text="$t('answer.noContent')" />
      <BaseCaption
        text="secondary"
        uppercase
        truncate
        class="d-block"
        v-text="name"
      />
    </div>
    <BaseButtonIcon
      v-if="/DATAGRID|ROW_DATAGRID/.test(typeQuestion) && content"
      color="primary"
      icon="$mdiChevronRight"
      size="24"
      @click="$emit('show-answer', answer)"
    />
  </BaseSheet>
</template>

<script>
export default {
  name: "RecordAnswer",
  props: {
    answer: {
      type: Object,
      default: null,
    },
    typeQuestion: {
      type: String,
      required: true,
    },
    content: {
      type: [String, Number, Array, Object],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    recordId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isImage: true,
    };
  },
  methods: {
    imageLoadError() {
      this.isImage = false;
    },
    getUrl(content) {
      return content?.length && content[0].url;
    },
  },
};
</script>
