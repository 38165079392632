<template>
  <BaseDialog
    :value="dialogState"
    width="360px"
    @click:outside="reset()"
    @keydown.esc="reset()"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <BaseTitle v-text="$t('campaign.forms.delete.title')" />
        </template>
        <template>
          <BaseParagraph
            class="d-block mt-4"
            v-text="$t('campaign.forms.delete.message')"
          />
        </template>
        <template #actions>
          <v-spacer />
          <BaseButton
            class="mr-4"
            color="gray"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton color="secondary" type="primary" @click="submit()">
            {{ $t("utils.delete") }}
          </BaseButton>
        </template>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CampaignDeleteDialog",
  computed: {
    ...mapState({
      activeCampaign: (state) => state.course.activeCampaign,
      dialogState: (state) => state.course.modals.isCampaignDeleteDialogOpen,
    }),
  },
  methods: {
    reset() {
      this.$store.commit("course/setCampaignDeleteDialog", false);
    },
    submit() {
      this.$store
        .dispatch("course/deleteOrganizationCourseCampaign", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          campaignId: this.activeCampaign.id,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: "Campagne supprimée avec succès",
            type: "SUCCESS",
          });
          this.$store.commit("course/setActiveCampaign", null);
          this.$router.push({
            name: "courseDetail",
            params: {
              organizationId: this.$route.params.organizationId,
              courseId: this.$route.params.courseId,
            },
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.reset();
    },
  },
};
</script>
