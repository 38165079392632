<template>
  <BaseModal
    :value="isOpen"
    :modal-title="$t('utils.duplicate')"
    is-unique
    @close="$emit('close')"
  >
    <template #modal>
      <v-form @submit.prevent="submit">
        <base-text-field
          v-model="name"
          autofocus
          :error-messages="nameErrors"
          :has-error="nameErrors.length > 1"
          :label="$t('course.forms.create.nameLabel')"
          :placeholder="$t('course.forms.create.namePlaceholder')"
          color="primary"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
      </v-form>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :disabled="$v.$invalid"
        color="primary"
        type="primary"
        :submit="true"
        @click="submit()"
      >
        {{ $t("utils.duplicate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

import { mapState } from "vuex";

export default {
  name: "CourseDuplicateDialog",
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      name: "",
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
  },
  computed: {
    ...mapState({
      activeCourse: (state) => state.course.course,
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required &&
        errors.push(this.$t("course.forms.create.nameRequired"));
      !this.$v.name.maxLength &&
        errors.push(this.$t("course.forms.create.nameMaxLength", { n: 100 }));
      return errors;
    },
  },
  watch: {
    isOpen() {
      this.setCourse();
    },
  },
  methods: {
    clear() {
      this.$v.$reset();
      this.name = "";
    },
    setCourse() {
      if (this.activeCourse) {
        this.name = `${this.$t("utils.copyOf")} ${this.activeCourse.name}`;
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.duplicateCourse();
        this.$emit("close");
        this.clear();
      }
    },
    async duplicateCourse() {
      try {
        const res = await this.$store.dispatch("course/duplicateCourse", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          payload: this.name,
        });
        // Display TheSnackbar
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.duplicateConfirmation"),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "courseDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            courseId: res.id,
          },
        });
      } catch (error) {
        // Display TheSnackbar
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>
