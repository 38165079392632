<template>
  <div>
    <Board v-if="activeClient && activeClient.personalData" class="mb-6">
      <template #main>
        <div class="content">
          <div class="d-flex align-center pl-5">
            <BaseParagraph
              text="primary"
              v-text="$tc('client.personalDataSubtitle', 0)"
            />
            <BaseDivider grey class="separator" />
          </div>
          <ClientPersonalDataTable v-if="activeClient.personalData" />
        </div>
      </template>
    </Board>
    <Board v-else-if="isFetchingActiveClient" class="mb-6">
      <template #main>
        <div class="d-flex align-center">
          <BaseSkeletonLoader type="text" width="144px" class="mr-2" />
          <BaseDivider grey />
        </div>
        <BaseLayout row wrap>
          <BaseFlex v-for="index in 5" :key="index" md6>
            <BaseSkeletonLoader
              type="image"
              class="mx-2 my-3 d-block"
              height="68px"
            />
          </BaseFlex>
        </BaseLayout>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import ClientPersonalDataTable from "../Components/ClientPersonalDataTable";
import { mapState } from "vuex";

export default {
  name: "ClientPersonalData",
  components: {
    Board,
    ClientPersonalDataTable,
  },
  computed: {
    ...mapState({
      activeClient: (state) => state.client.activeClient,
      isFetchingActiveClient: (state) => state.client.isFetchingActiveClient,
    }),
  },
};
</script>
<style>
.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.separator {
  margin-left: 10px;
}
</style>
