<template>
  <BaseDialog max-width="750" :value="dialogState" @click:outside="reset()">
    <template #modal>
      <BaseCosmoCard v-if="activeQuestion">
        <template #title>
          <BaseTitle v-text="activeQuestion.name" />
        </template>
        <template>
          <QuestionDatagridPreviwer
            v-if="/^DATAGRID|ROW_DATAGRID$/.test(activeQuestion.typeQuestion)"
            class="mt-4"
            :question="activeQuestion"
          />
        </template>
        <template #actions>
          <v-spacer />
          <BaseButton type="secondary" @click="reset()">
            {{ $t("utils.cancel") }}
          </BaseButton>
        </template>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import QuestionDatagridPreviwer from "@/core/Components/QuestionDatagridPreviwer";
import { mapState } from "vuex";
export default {
  name: "ClientShowAnswerDialog",
  components: {
    QuestionDatagridPreviwer,
  },
  computed: {
    ...mapState({
      activeQuestion: (state) => state.client.activeQuestion,
      dialogState: (state) => state.client.modals.isDisplayAnswerDialogOpen,
    }),
  },
  methods: {
    reset() {
      this.$store.commit("client/setActiveQuestion", null);
      this.$store.commit("client/setDisplayAnswerDialog", false);
    },
  },
};
</script>
