<template>
  <div>
    <BaseFrontOfficeParagraph
      class="d-block mb-4"
      v-text="$t('auth.reset.done.message')"
    />
  </div>
</template>

<script>
export default {
  name: "WalletPasswordResetDone",
};
</script>
