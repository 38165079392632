<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="ql-editor"
    :class="{ 'text-caption': isTextCaption }"
    v-html="content"
  />
</template>

<script>
export default {
  name: "BaseMarkdownViewer",
  props: {
    content: {
      type: String,
      default: "",
    },
    isTextCaption: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~quill/dist/quill.core.css";
.ql-editor {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: auto;
}
.text-caption {
  font-size: 0.625rem !important;
  font-weight: 400;
}
</style>
