import { render, staticRenderFns } from "./FolderArchiveDialog.vue?vue&type=template&id=0274818c&scoped=true"
import script from "./FolderArchiveDialog.vue?vue&type=script&lang=js"
export * from "./FolderArchiveDialog.vue?vue&type=script&lang=js"
import style0 from "./FolderArchiveDialog.vue?vue&type=style&index=0&id=0274818c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0274818c",
  null
  
)

export default component.exports