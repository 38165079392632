<template>
  <div>
    <div class="bo_title mb-6" v-text="$t('client.list')" />
    <Board>
      <template #header class="mb-6">
        <div class="d-flex justify-space-between align-center">
          <BaseButton
            icon="$mdiPlus"
            @click="$store.commit('client/setDisplayClientDialog', true)"
          >
            {{ $t("client.create") }}
          </BaseButton>
          <div>
            <v-text-field
              v-model="searchClientField"
              class="ma-0 pa-0"
              outlined
              :label="$t('client.search')"
              prepend-inner-icon="$mdiMagnify"
              dense
              hide-details
              @input="onSearch"
            />
          </div>
        </div>
      </template>
      <template #main>
        <div>
          <BaseDataTable
            :items="clientsFiltered"
            :headers="CLIENTS_HEADER"
            :loading="isFetchingClients"
            :loading-text="$t('utils.loadingData')"
            sort-by="dateCreated"
            sort-desc
            must-sort
            hide-default-footer
          >
            <template #item="{ item }">
              <tr
                class="row--link"
                @click="
                  $router.push({
                    name: 'clientDetail',
                    params: {
                      organizationId: $route.params.organizationId,
                      clientId: item.id,
                    },
                  })
                "
              >
                <!-- Firstname & Lastname -->
                <td>
                  <span
                    class="text"
                    v-text="
                      item.fullName ? item.fullName : $t('answer.noContent')
                    "
                  />
                </td>
                <!-- Email -->
                <td>
                  <span
                    class="text"
                    v-text="item.email ? item.email : $t('answer.noContent')"
                  />
                </td>
                <!-- Social Reason -->
                <td>
                  <span
                    class="text"
                    v-text="
                      item.societyName
                        ? item.societyName
                        : $t('answer.noContent')
                    "
                  />
                </td>
                <!-- Consentment -->
                <td class="text-end">
                  <BaseChip :type="statusType(item)" :color="statusType(item)">
                    <span
                      class="text_sm"
                      v-text="
                        `${item.recordsAgreeCount}/${item.recordsTotalCount}`
                      "
                    />
                  </BaseChip>
                </td>
              </tr>
            </template>
          </BaseDataTable>
          <div v-if="clientsCount > 10" class="pagination">
            <v-pagination
              v-model="filters.page"
              :total-visible="filters.pageSize"
              :length="clientPages"
              @input="changeFilters"
            />
          </div>
        </div>
      </template>
    </Board>
    <BaseDescription
      v-if="clients"
      class="mt-6"
      v-text="`${clientsCount} contact${clientsCount > 1 ? 's' : ''}`"
    />

    <ClientCreateDialog />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import ClientCreateDialog from "../Components/ClientCreateDialog.vue";

import { mapState } from "vuex";

export default {
  name: "ClientList",
  components: {
    Board,
    ClientCreateDialog,
  },
  data() {
    return {
      filters: {
        pageSize: 10,
        page: 1,
      },
      CLIENTS_HEADER: [
        {
          text: this.$t("utils.fullname"),
          value: "fullName",
          sort: (a, b) => a.localeCompare(b),
        },
        {
          text: this.$tc("utils.email", 1),
          value: "email",
        },
        {
          text: this.$tc("client.socialReason", 2),
          value: "societyName.content",
          sortable: true,
        },
        {
          text: this.$tc("utils.agreement", 2),
          align: "right",
          sortable: false,
        },
      ],
      displayClients: null,
      recordAnswer: null,
      searchClientField: "",
      timeOut: null,
    };
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      clientsCount: (state) => state.client.clientsCount,
      isFetchingClients: (state) => state.client.isFetchingClients,
    }),
    clientPages() {
      return Math.ceil(this.clientsCount / this.filters.pageSize);
    },
    clientsFiltered() {
      const clients = this.clients.map((client) => ({
        ...client,
        fullName: client.fullName ? client.fullName : "Anonyme",
      }));

      return clients;
    },
    breadcrumbsItems() {
      return [
        {
          text: this.$store.state.organization.activeOrganization.name,
          disabled: false,
          exact: true,
          to: {
            name: "organization",
            params: { organizationId: this.$route.params.organizationId },
          },
        },
        {
          text: this.$tc("organization.client", 10),
          exact: true,
          to: { name: "clientList" },
        },
        {
          text: this.$tc("organization.folder", 10),
          disabled: false,
          to: { name: "folderList" },
        },
      ];
    },
  },
  watch: {
    organizationId: {
      handler() {
        this.reset();
        this.initialize();
      },
      immediate: true,
    },
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.$store.commit("client/setClients", []);
      this.$store.commit("client/setClientsCount", 0);
      this.$store.commit("client/setIsFetchingClients", false);
      this.$store.commit("client/setDisplayClientDialog", false);
    },
    initialize() {
      this.$store.dispatch("client/fetchOrganizationClients", {
        organizationId: this.$route.params.organizationId,
        page: this.filters.page,
        pageSize: this.filters.pageSize,
      });
    },
    statusType(item) {
      if (item.recordsAgreeCount == 0) {
        return "primary";
      } else if (item.recordsAgreeCount < item.recordsTotalCount) {
        return "secondary";
      }
      return "tertiary";
    },
    changeFilters() {
      this.$emit("changeValue", this.filters);
      this.$store.dispatch("client/fetchOrganizationClients", {
        organizationId: this.$route.params.organizationId,
        page: this.filters.page,
        pageSize: this.filters.pageSize,
      });
    },

    onSearch(event) {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.filters.page = 1;
        this.$store.dispatch("client/searchClients", {
          organizationId: this.$route.params.organizationId,
          page: this.filters.page,
          pageSize: this.filters.pageSize,
          search: event,
        });
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
}
</style>
