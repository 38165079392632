<template>
  <div class="banner">
    <div v-if="clientList.length > 1" class="action-container">
      <div v-for="client in clientLists" :key="client.action" class="action">
        <div v-for="item in client.items" :key="item.id" class="item-display">
          <span class="email-banner" :class="item.statusClass">{{
            client.action !== "A signer" ? item.email : item.member.email
          }}</span>
          <div class="status-container" :class="item.statusClass">
            <span class="status" :class="item.statusClass">{{
              item.stautsText
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-logo">
      <BaseImage :src="logo" class="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletManagerBanner",
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    clientList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    clientLists() {
      if (!this.clientList && !this.clientList.length) return;

      const res = this.clientList
        ?.map((client) => {
          return {
            ...client,
            action: this.getOfferTypeText(client.action),
            statusClass: this.getClassStatus(client),
            stautsText: this.getStatusText(client),
          };
        })
        .reduce((key, client) => {
          const { action } = client;
          if (!key[action]) {
            key[action] = { action, items: [] };
          }
          key[action].items.push({ ...client });
          return key;
        }, {});

      return res;
    },
    logo() {
      if (!this.organization) return;
      return (
        (this.organization && this.organization?.logo) ||
        this.$findStatic("cosmoWalletCloudData")
      );
    },
    description() {
      if (!this.organization) return;
      return this.organization ? this.organization?.description : null;
    },
    name() {
      if (!this.organization) return;
      return this.organization ? this.organization?.name : null;
    },
  },
  methods: {
    getOfferTypeText(offerType) {
      if (offerType === "CONSULT") return "A consulter";
      if (offerType === "VALIDATION") return "A valider";
      return "A signer";
    },
    getClassStatus(offer) {
      switch (offer.action) {
        case "CONSULT": {
          return offer.status === "PENDING" ? "" : "valid";
        }
        case "VALIDATION": {
          return offer.status === "PENDING"
            ? ""
            : offer.status === "ACCEPT"
            ? "valid"
            : "declined";
        }
        default: {
          if (offer.status === "PENDING") return "pending";
          if (offer.status === "ACCEPT" || offer?.signedFiles?.length)
            return "valid";
          if (offer.status === "CANCELED") {
            return "grey";
          }
          return "red";
        }
      }
    },
    getStatusText(offer) {
      switch (offer.action) {
        case "CONSULT": {
          return offer.status === "PENDING"
            ? this.$t("folder.offerStatus.waitingForConsultation")
            : "consultée";
        }
        case "VALIDATION": {
          return offer.status === "PENDING"
            ? this.$t("folder.offerStatus.waitingForValidation")
            : offer.status === "ACCEPT"
            ? this.$tc("folder.offerStatus.validated")
            : this.$t("folder.offerStatus.signatureRefused");
        }
        default: {
          if (offer.status === "ACCEPT" || offer?.signedFiles?.length)
            return this.$t("folder.offerStatus.OfferSigned");
          if (offer.status === "CANCELED") {
            return this.$t("utils.cancelOffer.cancel");
          }
          return this.$t("folder.offerStatus.waitingForSignature");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 10px;

  @media screen and (max-width: 480px) {
    display: none;
    gap: 15px;
  }

  .banner-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    @media screen and (max-width: 480px) {
      display: none;
    }
    .logo {
      object-fit: contain;
    }
  }

  .action-container {
    width: 320px;
    overflow: auto;
    .action {
      background: #fcfcfc;
      display: flex;
      flex-direction: column;
      align-items: end;
      padding: 5px !important;

      @media screen and (max-width: 480px) {
        width: 100%;
      }

      .title {
        color: #000;
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
.item-display {
  display: flex;
  align-items: center;
  width: 100%;
  .email-banner {
    flex: 1;
    justify-self: flex-end;
    color: #cfcfdc;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    &.valid {
      color: #18a300 !important;
    }
    &.declined {
      color: #ff5267 !important;
    }
  }
  .status-container {
    margin-left: auto;
    display: flex;
    align-items: center;

    .status {
      border-radius: 13px;
      padding: 4px 8px;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #707080;
      background: #eeeef7;

      &.valid {
        color: #18a300 !important;
        background: #f0ffd6;
      }
      &.declined {
        color: #ff5267 !important;
      }
    }
  }
}
</style>
