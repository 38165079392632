<template>
  <BaseModal
    :value="isOpen"
    :modal-title="
      !activeStep
        ? $t('course.createStepDialogTitle')
        : $t('course.editStepDialogTitle')
    "
    is-unique
    @click:outside="
      clear();
      $emit('close');
    "
    @keydown.esc="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <v-form @submit.prevent="submit()">
        <BaseTextField
          v-model="name"
          autofocus
          :error-messages="nameErrors"
          :has-error="nameErrors.length > 0"
          :label="$t('course.forms.step.nameLabel')"
          :placeholder="$t('course.forms.step.namePlaceholder')"
          color="primary"
        />
      </v-form>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="
          () => {
            $emit('close');
            clear();
          }
        "
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="primary" type="primary" class="ml-4" @click="submit()">
        {{ !activeStep ? $t("utils.validate") : $t("utils.edit") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

import { mapState } from "vuex";

export default {
  name: "CourseStepCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      name: "",
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
  },
  computed: {
    ...mapState({
      activeStep: (state) => state.course.activeStep,
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required &&
        errors.push(this.$t("course.forms.step.nameRequired"));
      !this.$v.name.maxLength &&
        errors.push(this.$t("course.forms.step.nameMaxLength", { n: 100 }));
      return errors;
    },
  },
  watch: {
    isOpen(val) {
      val && this.activeStep ? this.setStep() : null;
    },
  },
  methods: {
    clear() {
      this.$v.$reset();
      this.name = "";
    },
    setStep() {
      if (this.activeStep) {
        this.name = this.activeStep.name;
      }
    },
    findNewStep() {
      let steps = this.$store.state.course.course.steps;
      if (steps.length > 0) {
        return Math.max(...steps.map((step) => step.order)) + 1;
      } else {
        return 0;
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (this.activeStep) {
        this.editStep();
      } else {
        this.createStep();
      }
      this.clear();
    },
    createStep() {
      this.$store
        .dispatch("course/createStep", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          payload: {
            name: this.name,
            order: this.findNewStep(),
          },
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.createStepConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        })
        .finally(() => {
          this.$emit("close");
        });
    },
    editStep() {
      this.$store
        .dispatch("course/editStep", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          payload: {
            ...this.activeStep,
            name: this.name,
          },
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.editStepConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        })
        .finally(() => {
          this.$emit("close");
        });
    },
  },
};
</script>
