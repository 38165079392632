<template>
  <BaseDialog max-width="600" :value="dialogState" @click:outside="onClose()">
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="'Envoyer dans Enoxa'" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="onClose()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div class="alert_item">
            <BaseIcon icon="$mdiAlertCircleOutline" color="#FF5267" />
            <span class="alert_label" v-text="alertLabel" />
          </div>
          <div class="alert_text">
            {{ alertText }}
          </div>
          <div v-if="displayMissingField" class="missings">
            <div class="missing_item" v-text="'COSMO_CHANNEL'" />
            <div class="missing_item" v-text="'DOC_DATE'" />
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton type="secondary" @click="onClose()">
            {{ $t("navigation.close") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  name: "SendToEnoxaErrorDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    errorCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    alertLabel() {
      if (this.errorCode == "doc_date-format-must-be-dd/mm/yyyy")
        return this.$t("enoxa.docDateInvalidFormat");
      return this.$t("folder.missingFields");
    },
    alertText() {
      if (this.errorCode == "doc_date-format-must-be-dd/mm/yyyy")
        return this.$t("enoxa.docDateInvalidFormatText");
      return this.$t("enoxa.requiresFieldsError");
    },
    displayMissingField() {
      return this.errorCode != "doc_date-format-must-be-dd/mm/yyyy";
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .alert_item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    .alert_label {
      font-size: 16px;
      line-height: 20px;
      color: #353542;
    }
  }
  .alert_text {
    text-align: center;
    color: #505050;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
  }
  .missings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    .missing_item {
      padding: 6px 8px;
      border-radius: 5px;
      border: 1px solid #2536cc;
      background: #fff;
      color: #505050;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
}
</style>
