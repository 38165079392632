import Question from "./Question";

export default class Step {
  constructor({
    id = Number.NaN,
    name = "",
    order = Number.NaN,
    questions = [],
    validationStatus = "",
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.order = Number.parseInt(order);
    this.questions = questions
      ? questions.map((question) => new Question({ ...question, step: id }))
      : [];
    this.validationStatus = validationStatus;
  }
}
