<template>
  <div>
    <form class="cosmoform" @submit.prevent="submit()">
      <div v-if="/^TEXT$/.test(question.typeQuestion)">
        <v-text-field
          v-model="form.value"
          outlined
          dense
          autofocus
          :hide-details="!valueErrors"
          :error-messages="valueErrors"
          :readonly="question.readOnly"
          :class="{ 'mb-2': !valueErrors }"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^NUMBER$/.test(question.typeQuestion)">
        <v-text-field
          v-model="form.value"
          outlined
          autofocus
          type="number"
          :hide-details="!valueErrors"
          :error-messages="valueErrors"
          :class="{ 'mb-2': !valueErrors }"
          :readonly="question.readOnly"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^TEXTAREA$/.test(question.typeQuestion)">
        <v-textarea
          v-model="form.value"
          outlined
          autofocus
          :hide-details="!valueErrors"
          :error-messages="valueErrors"
          :readonly="question.readOnly"
          :class="{ 'mb-2': !valueErrors }"
          class="full_textarea"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^DATE$/.test(question.typeQuestion)">
        <v-menu
          v-model="showDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on }">
            <div class="date_field">
              <BaseButtonIcon
                class="calendar_icon"
                icon="$mdiCalendarMonth"
                @click="showDatePicker = !showDatePicker"
                v-on="on"
              />
              <v-text-field
                v-model="form.dateField"
                outlined
                dense
                autofocus
                :hide-details="!dateFieldErrors"
                :error-messages="dateFieldErrors"
                :readonly="question.readOnly"
                :class="{ 'mb-2': !dateFieldErrors }"
                @blur="editDateField()"
                @input="editDateField()"
              />
            </div>
          </template>
          <v-date-picker
            class="d-inline-block mr-2"
            color="none"
            locale="fr-fr"
            :first-day-of-week="1"
            :show-current="form.value"
            autofocus
            :error-messages="valueErrors"
            :readonly="question.readOnly"
            @blur="$v.form.value.$touch()"
            @change="dateChanged"
            @input="showDatePicker = false"
          />
        </v-menu>
      </div>
      <div v-else-if="/^DATETIME$/.test(question.typeQuestion)">
        <div>
          <v-menu
            v-model="showDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on }">
              <div class="date_field">
                <BaseButtonIcon
                  class="calendar_icon"
                  icon="$mdiCalendarMonth"
                  @click="showDatePicker = !showDatePicker"
                  v-on="on"
                />
                <v-text-field
                  v-model="form.dateField"
                  outlined
                  :rules="[(v) => !!v || 'Required']"
                  dense
                  autofocus
                  :error-messages="dateFieldErrors"
                  :readonly="question.readOnly"
                  @blur="editDateField()"
                  @input="editDateField()"
                />
              </div>
            </template>
            <v-date-picker
              class="d-inline-block mr-2"
              color="none"
              locale="fr-fr"
              :first-day-of-week="1"
              :show-current="form.value"
              autofocus
              :error-messages="valueErrors"
              :readonly="question.readOnly"
              @blur="$v.form.value.$touch()"
              @input="showDatePicker = false"
              @change="dateChanged"
            />
          </v-menu>
        </div>
        <div>
          <v-menu
            v-model="showTimePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on }">
              <div class="date_field">
                <BaseButtonIcon
                  class="calendar_icon"
                  icon="$mdiClockOutline"
                  @click="showTimePicker = !showTimePicker"
                  v-on="on"
                />
                <v-text-field
                  v-model="form.time"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Required']"
                  hide-details
                  :readonly="question.readOnly"
                  class="mb-2"
                  @blur="editDateField()"
                  @input="editDateField()"
                />
              </div>
            </template>
            <v-time-picker
              v-model="form.time"
              class="d-inline-block"
              color="none"
              height="200"
              format="24hr"
              :error-messages="valueErrors"
              :readonly="question.readOnly"
              @change="$v.form.value.$touch()"
              @blur="$v.form.value.$touch()"
            >
              <v-spacer />
              <v-btn text color="primary" @click="showTimePicker = false">
                {{ $t("utils.ok") }}
              </v-btn>
            </v-time-picker>
          </v-menu>
        </div>
      </div>

      <div v-else-if="/^SELECT$/.test(question.typeQuestion)">
        <v-autocomplete
          v-model="form.value"
          :items="question.choices"
          outlined
          autofocus
          item-text="name"
          item-value="id"
          :readonly="question.readOnly"
          :hide-details="!valueErrors"
          :error-messages="valueErrors"
          :menu-props="{ bottom: true, offsetY: true }"
          :class="{ 'mb-2': !valueErrors }"
          clearable
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^RADIOBUTTON$/.test(question.typeQuestion)">
        <v-radio-group
          v-model="form.value"
          :hide-details="!valueErrors"
          :error-messages="valueErrors"
          :readonly="question.readOnly"
          class="mb-2"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        >
          <v-radio
            v-for="(choice, index) in question.choices"
            :key="index"
            :label="choice.name"
            :value="choice.id"
            @click="onClickRadioButton(form.value)"
          >
            <template #label>
              <div>
                <span v-text="choice.name" />
                <BaseIcon
                  v-show="form.value === choice.id"
                  icon="$mdiCheck"
                  color="#ff7f79"
                  large
                />
              </div>
            </template>
          </v-radio>
          <div
            v-if="question.hasFreeOption"
            class="input"
            :value="selectedOption"
            :class="{ checked: selectedOption }"
            @click.prevent="onClickOther"
          >
            <input
              id="input"
              ref="inputFree"
              v-model="otherValue"
              :type="otherValue.length && checkedOther ? 'radio' : 'text'"
              @change="onChangeField"
              @blur="checkedOther = true"
            />
            <label v-if="checkedOther" class="label" for="input">{{
              otherValue
            }}</label>
            <label
              v-if="!checkedOther && !otherValue.length"
              class="label"
              for="input"
              >{{ "Autre" }}</label
            >
            <BaseIcon
              v-if="selectedOption && checkedOther"
              class="icon"
              icon="$mdiCheck"
              color="#ff7f79"
              large
            />
          </div>
        </v-radio-group>
      </div>
      <div
        v-else-if="/^CHECKBOX$/.test(question.typeQuestion)"
        class="checkboxes"
      >
        <v-checkbox
          v-for="(choice, index) in question.choices"
          :key="index"
          v-model="form.value"
          :value="choice.id"
          :readonly="question.readOnly"
          :error-messages="
            question.choices.length == index + 1
              ? valueErrors
              : valueErrors != ''
              ? ' '
              : null
          "
          prepend-icon="$mdiPlus"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        >
          <template #label>
            <div>
              <span v-text="choice.name" />
              <BaseIcon
                v-show="form.value && form.value.includes(choice.id)"
                icon="$mdiCheck"
                color="#ff7f79"
                large
              />
            </div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-if="question.hasFreeOption && !selectedOption"
          label="Autre"
          @change="onCheck"
          @blur="$v.form.value.$touch()"
        />
        <div class="input-wrapper" @click="onCheck">
          <input
            v-if="selectedOption"
            ref="other"
            v-model="otherValue"
            style="width: 250px"
            class="input checked"
            @change="onChangeField"
          /><BaseIcon
            v-if="selectedOption"
            class="icon"
            icon="$mdiCheck"
            color="#ff7f79"
            large
          />
        </div>
      </div>
      <div v-else-if="/^FILE$/.test(question.typeQuestion)">
        <v-file-input
          v-model="form.value"
          outlined
          truncate-length="50"
          show-size
          hide-details
          class="mb-2"
          append-icon="$mdiFileOutline"
          :placeholder="$t('wallet.form.fileInputPlaceholder')"
          :disabled="question.readOnly"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^MULTIPLE_FILE$/.test(question.typeQuestion)">
        <folder-offer-file-drag
          :offer-files="form.value"
          nature="secondary"
          is-wallet
          is-cosmo-form
          @file-droped="fileInserted"
          @file-name-changed="updateFileName"
          @delete-offer-file="removeFile"
        />
      </div>
      <div v-else-if="/^DATAGRID$/.test(question.typeQuestion)" class="mb-4">
        <vue-excel-editor
          v-model="form.table"
          no-footer
          new-if-bottom
          no-header-edit
          enter-to-south
          no-paging
          no-finding
          no-finding-next
          disable-panel-filter
          disable-panel-setting
          @update="updateTable()"
        >
          <vue-excel-column
            v-for="(choice, index) in question.choices"
            :key="index"
            :field="choice.id.toString()"
            :label="choice.name"
          />
        </vue-excel-editor>
      </div>
      <div
        v-else-if="/^ROW_DATAGRID$/.test(question.typeQuestion)"
        class="mb-4"
      >
        <vue-excel-editor
          v-model="form.table"
          no-footer
          no-header-edit
          no-paging
          no-finding
          no-finding-next
          disable-panel-filter
          disable-panel-setting
          @update="updateTable()"
        >
          <vue-excel-column width="280px" field="name" :label="question.name" />
          <vue-excel-column width="280px" field="" label="" />
        </vue-excel-editor>
      </div>
      <div
        v-else-if="/^SIGNATURE$/.test(question.typeQuestion)"
        class="signed_pad_container"
      >
        <VueSignaturePad
          ref="signaturePad"
          v-model="form.value"
          style="position: relative"
          class="signed_pad"
          :options="signaturePadOptions"
        />
        <BaseSheet
          color="grey lighten-3"
          class="signed_pad_action edit rounded-b-circle rounded-t-circle elevation-"
        >
          <BaseButtonIcon
            :disabled="!form.value"
            icon="$mdiUndoVariant"
            color="primary"
            @click="
              () => {
                $refs.signaturePad.undoSignature();
                signaturePadOnEnd();
              }
            "
          />
        </BaseSheet>
        <BaseSheet
          color="grey lighten-3"
          class="signed_pad_action mt-14 edit rounded-b-circle rounded-t-circle elevation-"
        >
          <BaseButtonIcon
            :disabled="!form.value"
            icon="$mdiTrashCanOutline"
            color="secondary"
            @click="
              () => {
                $refs.signaturePad.clearSignature();
                signaturePadOnEnd();
              }
            "
          />
        </BaseSheet>
      </div>
    </form>
    <div class="cosmoform_footer">
      <div v-if="/^FILE$/.test(question.typeQuestion)">
        <span
          v-if="form.value"
          class="link-url"
          @click="displayFile()"
          v-text="$t('folder.records.seeFile')"
        />
      </div>
      <BaseButton
        v-if="!question.readOnly"
        :loading="loading"
        :disabled="disabled"
        class="ml-3"
        @click="$emit('is-saving')"
      >
        {{ $t("utils.save") }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import camelCase from "lodash.camelcase";
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";
import { dataURLtoFile, fileToBase64 } from "@/core/Utils/file.utils";
import FolderOfferFileDrag from "../../Folder/Components/FolderOffer/FolderOfferFileDrag.vue";

export default {
  name: "CosmoformForm",
  components: { FolderOfferFileDrag },
  mixins: [validationMixin],
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [Object, String, Number, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      previouslyRadioOptionSelected: null,
      form: {
        value: null,
        table: [],
        time: null,
        dateField: null,
        other: "",
      },
      signaturePadOptions: {
        penColor: "#000",
        onEnd: this.signaturePadOnEnd,
      },
      dateIsValid: false,
      showDatePicker: false,
      showTimePicker: false,
      selectedOption: false,
      otherValue: "",
      checkedOther: false,
    };
  },

  computed: {
    helpText() {
      if (!this.question) return [""];

      return this.question?.helpText.split("\n");
    },
    valueErrors() {
      if (!this.$v.form.value.$dirty) {
        return "";
      }
      if (this.question.required && !this.$v.form.value.required) {
        return "Le champ est requis";
      }
      if (
        /^NUMBER$/.test(this.question.typeQuestion) &&
        !this.$v.form.value.integer
      ) {
        return "Le champ doit être un nombre entier";
      }
      return "";
    },
    dateFieldErrors() {
      if (!this.$v.form.dateField.$dirty) {
        return "";
      }
      if (this.question.required && !this.$v.form.dateField.required) {
        return "Le champ est requis";
      }
      if (!this.dateIsValid) {
        return "Le champ doit être une date valide (DD/MM/YYYY)";
      }
      return "";
    },
    newDataGridRow() {
      let defaultObject = {};
      for (const choice of this.question.choices) {
        defaultObject[choice?.id] = "";
      }
      return defaultObject;
    },
  },
  watch: {
    question() {
      this.reset();
      this.initialize();
    },
    form: {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  validations() {
    let validations = {
      form: {
        value: {},
        time: {},
        dateField: {},
      },
    };
    if (this.question.required) {
      validations.form.value = {
        required,
      };
      if (/^DATE/.test(this.question.typeQuestion)) {
        validations.form.dateField = {
          required,
        };
      }
      if (/^DATETIME$/.test(this.question.typeQuestion)) {
        validations.form.time = {
          required,
        };
      }
    }
    if (/^NUMBER$/.test(this.question.typeQuestion)) {
      validations.form.value = {
        ...validations.form.value,
        integer,
      };
    }
    return validations;
  },
  mounted() {
    this.reset();
    this.initialize();
  },
  methods: {
    async fileInserted({ value }) {
      // insert file locally to use vuelidate+vuex
      let fileList = [];
      for (const file of value) {
        fileList.push(file);
      }

      if (Array.isArray(this.form.value)) {
        this.form.value = [...this.form.value, ...value];
        return this.change();
      }
      this.form.value = fileList;
      await this.change();
    },
    async updateFileName({ value, index }) {
      const newFile = new File([this.form.value[index]], `${value}`, {
        type: this.form.value[index].type,
      });
      this.form.value.splice(index, 1, newFile);
      await this.change();
    },
    async removeFile(index) {
      this.form.value.splice(index, 1);
      await this.change();
    },
    async onClickRadioButton(selected) {
      if (this.previouslyRadioOptionSelected == selected) {
        this.form.value = null;
        this.checkedOther = false;
        this.previouslyRadioOptionSelected = null;
        return;
      }
      this.selectedOption = false;
      this.otherValue = "";
      this.previouslyRadioOptionSelected = selected;
      await this.change();
    },
    onClickOther() {
      this.selectedOption = !this.selectedOption;
      this.form.value = null;
      if (this.otherValue.length) {
        this.checkedOther = true;
      }

      this.$nextTick(async () => {
        this.$refs.inputFree.focus();
        this.checkedOther = false;
        if (!this.selectedOption) {
          this.otherValue = "";
        }
        await this.change();
      });
    },
    onCheck() {
      this.selectedOption = !this.selectedOption;
      this.$nextTick(async () => {
        if (!this.selectedOption) {
          this.otherValue = "";
        } else this.$refs.other.focus();
        await this.change();
      });
    },
    displayFile() {
      const uri = URL.createObjectURL(this.form.value);
      open(uri);
    },
    reset() {
      this.form = {
        value: null,
        time: null,
        dateField: null,
      };
      this.checkedOther = false;
      this.$v.form.$reset();
    },
    dateChanged(event) {
      this.form.value = event;
      this.form.dateField = this.form.value.split("-").reverse().join("/");
      this.$v.form.value.$touch();
      this.validDate();
    },
    initialize() {
      /**
       * Workaround because signaturePad size sets to 0 if in a dialog.
       * When page is initialized, we set it to right size
       * Link https://github.com/neighborhood999/vue-signature-pad/issues/62#issuecomment-458891526
       */
      if (/^SIGNATURE$/.test(this.question.typeQuestion)) {
        this.$nextTick(function () {
          this.$refs.signaturePad.resizeCanvas();
        });
      }

      /**
       * if there is already an answer we set the right input to it's actual
       * value
       */
      if (this.modelValue) {
        this.initializeForm(this.question.typeQuestion, this.modelValue);
      } else {
        if (/^CHECKBOX$/.test(this.question.typeQuestion)) {
          this.form.value = [];
        } else if (/^DATE$/.test(this.question.typeQuestion)) {
          this.form.dateField = new Date().toLocaleDateString("fr", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          this.editDateField();
        } else if (/^DATAGRID$/.test(this.question.typeQuestion)) {
          this.form.table = [this.newDataGridRow];
        } else if (/^ROW_DATAGRID$/.test(this.question.typeQuestion)) {
          let tablePayload = this.question.choices.map((choice) => {
            let payload = {
              name: choice.name,
              "": "",
            };
            return payload;
          });
          this.form.table = tablePayload;
        }
      }
    },
    async initializeForm(typeQuestion, answer) {
      switch (typeQuestion) {
        case "TEXT":
        case "TEXTAREA":
        case "NUMBER":
          this.form.value = answer.content;
          break;
        case "DATE":
          {
            this.form.value = answer.content.substr(0, 10);
            this.form.dateField = new Date().toLocaleDateString("fr", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            this.editDateField();
            this.dateIsValid = true;
          }
          break;
        case "DATETIME":
          {
            const timeContent = answer.content.replace(":00+00:00", "");
            this.form.value = timeContent.substr(0, 10);
            this.form.dateField = `${this.form.value.substr(
              8,
              2
            )}/${this.form.value.substr(5, 2)}/${this.form.value.substr(0, 4)}`;
            this.form.time = timeContent.substr(11, 5);
            this.dateIsValid = true;
          }
          break;
        case "SELECT":
        case "RADIOBUTTON":
          {
            if (answer.choices) {
              this.form.value = answer.choices[0];
            } else if (answer.content) {
              const choice = this.question.choices.find(
                (c) => c.name == answer.content
              );
              this.form.value = choice.id;
            }
            this.previouslyRadioOptionSelected = this.form.value;
            if (
              this.question.hasFreeOption &&
              this.question.answer.freeOptionContent?.length
            ) {
              this.otherValue = this.question.answer.freeOptionContent;
              this.selectedOption = true;
              this.checkedOther = true;
            }
          }
          break;
        case "CHECKBOX":
          {
            if (
              this.question.hasFreeOption &&
              this.question.answer.freeOptionContent?.length
            ) {
              this.otherValue = this.question.answer.freeOptionContent;
              this.selectedOption = true;
              this.checkedOther = true;
            }
            if (answer.choices) {
              return (this.form.value = [...answer.choices]);
            }
            const choiceAnswers = answer?.content?.split(", ");
            this.form.value = choiceAnswers?.map(
              (choiceAnswer) =>
                this.question.choices.find(
                  (questionChoice) => questionChoice?.name === choiceAnswer
                )?.id
            );
          }
          break;
        case "FILE":
          {
            this.form.value = answer.content[0]?.url
              ? this.dataURLtoFile(
                  answer.content[0]?.url,
                  answer.content[0]?.name
                )
              : dataURLtoFile(
                  answer.content[0].encoded_file,
                  answer.content[0].name
                );
          }
          break;
        case "SIGNATURE":
          {
            this.form.value = answer.content;
          }
          break;
        case "MULTIPLE_FILE":
          {
            const values = answer?.content?.map(async (file) => {
              if (!file.url) {
                return file;
              }
              return this.dataURLtoFile(file.url, file.name);
            });
            this.form.value = await Promise.all(values);
          }
          break;
        case "DATAGRID":
          {
            if (answer.content && Object.keys(answer.content).length > 0) {
              this.form.table = answer.content.map((row) => {
                const keys = Object.keys(row);
                let payload = {};
                keys.forEach((key) => {
                  const choice = this.question.choices.find(
                    (c) => camelCase(c.name) == camelCase(key)
                  );
                  if (choice?.id) return (payload[choice?.id] = row[key]);
                });
                return payload;
              });
            } else {
              this.form.table = [this.newDataGridRow];
            }
            this.updateTable();
          }
          break;
        case "ROW_DATAGRID":
          if (answer.content && Object.keys(answer.content).length > 0) {
            this.form.table = answer.content.map((elem) => {
              const key = Object.keys(elem).find((q) => q != "");
              let payload = {};
              payload.name = elem[key];
              payload[""] = elem[""];
              return payload;
            });
          } else {
            let tablePayload = this.question.choices.map((choice) => {
              let payload = {
                name: choice?.name,
                "": "",
              };
              return payload;
            });
            this.form.table = tablePayload;
          }
          break;
      }
    },
    validDate() {
      this.dateIsValid =
        /^(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))$/.test(
          this.form.dateField
        );
    },
    async getBase64FileByUrl(url) {
      var res = await fetch(url);
      var blob = await res.blob();
      var uri = await this.parseURI(blob);
      return uri;
    },
    async parseURI(d) {
      var reader = new FileReader();
      reader.readAsDataURL(d);
      return new Promise((res) => {
        reader.onload = (e) => {
          res(e.target.result);
        };
      });
    },
    dataURLtoFile(dataurl, filename) {
      if (!dataurl) return;
      let arr = dataurl.split(","),
        mime = arr[0]?.match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        fileToBase64;
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    editDateField() {
      let newVal = this.form.dateField;
      if (!newVal) {
        return;
      }
      if (newVal.length > 10) {
        this.$nextTick(() => {
          this.form.dateField = newVal.substr(0, 10);
          newVal = this.form.dateField;
        });
      }
      this.validDate();
      this.$v.form.dateField.$touch();
      if (this.dateIsValid) {
        this.form.value = newVal.split("/").reverse().join("-");
        this.$v.form.value.$touch();
      }
    },
    /**
     * Function hooked to the signature pad for the data signature only.
     * It updates the value of the form with the file data of the signature
     */
    signaturePadOnEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.form.value = isEmpty ? null : data;
    },
    /**
     * Function to build the question payload in order to make it ready for the endpoints
     */
    async buildAnswer() {
      let payload = {};
      switch (this.question.typeQuestion) {
        case "TEXT":
        case "TEXTAREA":
        case "DATE":
        case "NUMBER":
        case "SIGNATURE":
          payload.content = this.form.value ? this.form.value : null;
          break;
        case "FILE":
          {
            if (!this.form.value) return;
            let image = await fileToBase64(this.form.value);
            payload.content = [
              {
                name: this.form.value.name,
                encoded_file: image,
              },
            ];
          }
          break;
        case "MULTIPLE_FILE":
          {
            if (!this.form.value) return;
            const values = this.form?.value?.map(async (file) => ({
              name: file.name,
              encoded_file: file.encoded_file
                ? file.encoded_file
                : await fileToBase64(file),
            }));
            const result = await Promise.all(values);
            payload.content = result;
          }
          break;
        case "RADIOBUTTON":
          if (this.question.hasFreeOption && this.otherValue.length) {
            payload.freeOptionContent = this.otherValue;
          } else payload.choices = this.form.value && [this.form.value];
          break;
        case "SELECT":
          if (Number.isSafeInteger(this.form.value)) {
            payload.choices = [this.form.value];
          } else {
            payload.choices = this.form.value;
          }
          break;
        case "CHECKBOX": {
          if (Number.isSafeInteger(this.form.value)) {
            payload.choices = [this.form.value];
          } else {
            payload.choices =
              this.question.hasFreeOption && this.otherValue.length
                ? [
                    ...new Set(
                      [...this.form.value, `free__${this.otherValue}`]
                        .filter((item) => item)
                        .sort((a, b) => {
                          if (typeof a === "string" && typeof b === "number")
                            return 1;
                          if (typeof a === "number" && typeof b === "string")
                            return -1;
                          return 0;
                        })
                    ),
                  ]
                : [...new Set([...this.form.value.filter((item) => item)])];
          }

          let index = this.form.value.indexOf(
            this.form.value.find(
              (item) => typeof item === "string" && item.includes("free__")
            )
          );
          if (this.question.hasFreeOption && this.otherValue.length)
            payload.freeOptionContent = this.otherValue;
          else {
            payload.freeOptionContent = "";
            if (index > -1) {
              this.form.value.splice(index, 1);
              payload.choices = this.form.value;
            }
          }
          break;
        }
        case "DATETIME":
          payload.content =
            this.form.value.substr(0, 10) + " " + this.form.time;
          break;
        case "DATAGRID":
          payload.content = this.form.value;
          break;
        case "ROW_DATAGRID":
          payload.content = this.form.value;
          break;
        default:
          break;
      }
      return payload;
    },
    async change() {
      this.$v.form.$touch();
      const payload = await this.buildAnswer();
      if (this.otherValue.length) {
        this.$emit("changeValue", payload);
      } else {
        if (!this.$v.form.$invalid) {
          if (!this.form.value) {
            this.$emit("changeValue", {
              content: null,
            });
          } else {
            this.$emit("changeValue", payload);
            if (/^RADIOBUTTON$/.test(this.question.typeQuestion)) {
              this.$emit("submit", true);
            }
          }
        } else {
          this.$emit("changeValue", null);
        }
      }
    },
    async onChangeField(inputValue) {
      this.otherValue = inputValue.target.value;
      await this.change();
    },
    updateTable() {
      if (/^ROW_DATAGRID$/.test(this.question.typeQuestion)) {
        this.form.value = this.form.table.map((elem) => {
          delete elem.$id;
          return elem;
        });
        return;
      }
      this.form.value = this.form.table.map((row) => {
        const rowKeys = Object.keys(row).filter((k) => k !== "$id");
        let objectToReturn = {};
        for (const key of rowKeys) {
          const choice = this.question.choices.find(
            (c) => c.id == Number.parseInt(key)
          );
          if (choice.name) objectToReturn[choice.name] = row[key];
        }
        return objectToReturn;
      });
    },
    async submit() {
      await this.change().then(() => {
        this.$emit("submit");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cosmoform {
  font-family: "Nunito";
  .input-wrapper {
    position: relative;
    top: 5px;

    .icon {
      position: absolute;
      top: 7px;
      right: 5px;
    }
  }
  .input {
    position: relative;
    border-radius: 25px;
    min-width: 128px;
    height: 48px;
    background-color: #3c3b8e;
    cursor: pointer;
    padding: 10px;

    .icon {
      position: absolute;
      top: 5px;
      right: 0px;
    }
    #input {
      cursor: pointer;
      font-family: "Nunito";
      ::placeholder {
        color: #fff;
        font-size: 16px;
        text-align: center;
      }
    }
    .label {
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      text-align: center;
      display: block;
      position: relative;
      bottom: 20px;
      font-family: "Nunito";
    }
    & input[type="radio"] {
      opacity: 0.011;
      z-index: 100;
    }
    &.checked {
      transition: 0s ease;
      color: white !important;
      background: linear-gradient(
          165.56deg,
          #2f479d 19.48%,
          rgba(47, 71, 157, 0) 144.95%
        ),
        #ff5b60 !important;
      border-color: #ffffff;
    }

    ::v-deep input {
      color: #fff;
    }
  }
  .help_texp {
    font-size: 12px;
    line-height: 16px;
    color: #666;
    padding: 0 8px;
    margin-bottom: 16px;
  }
  .date_field {
    position: relative;
    .calendar_icon {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      padding: 20px;
      right: 8px;
      ::v-deep .v-icon__svg {
        fill: #ff7074;
      }
    }
  }
  .checkboxes {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .v-input--checkbox {
    margin: 0px 8px 0px 0px;
  }
  &::v-deep {
    .v-input--radio-group__input {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
  }
  .v-radio {
    margin: 0px 8px 0px 4px;
  }
  .full_textarea {
    width: 100% !important;
    ::v-deep .v-text-field__slot {
      min-width: auto !important;
      max-width: auto !important;
      width: 100% !important;
    }
  }
  .signed_pad_container {
    display: flex;
    position: relative;
    margin-bottom: 16px;
    .signed_pad {
      border-radius: 5px;
      background: #fff;
      width: 100%;
      aspect-ratio: 1/1;
      box-shadow: 0px 0px 2px #c4c4c4;
    }
    .signed_pad_action {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
.cosmoform_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}
::v-deep .v-input__slot fieldset {
  border: 1px solid #ff7074;
  background-color: white;
}
::v-deep .v-picker__title {
  height: 100px;
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    #ff7074 !important;
}
::v-deep .v-input--selection-controls__input {
  display: none;
}
::v-deep .v-input--radio-group__input .v-radio,
::v-deep .v-input--checkbox .v-input__slot {
  box-sizing: border-box;
  height: 48px;
  min-width: 128px;
  border-radius: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 0px;
  border: 1px solid #ebebeb;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #3c3b8e !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}
::v-deep .v-input--radio-group__input .v-radio {
  background-color: #3c3b8e !important;
  color: white !important;
}
::v-deep .v-radio.v-item--active,
::v-deep .v-input--checkbox.v-input--is-label-active .v-input__slot {
  transition: 0s ease;
  color: white !important;
  background: linear-gradient(
      165.56deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    ),
    #ff5b60 !important;
  border-color: #ffffff;
}
::v-deep .theme--light.v-label {
  color: inherit !important;
}
::v-deep .v-input__prepend-outer {
  display: none;
}
.link-url {
  color: #2536cc;
  text-decoration: underline;
  cursor: pointer;
}
</style>
