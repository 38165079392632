let plugins = [];

// Import Stores
import Cosmoform from "./Cosmoform";

let stores = {
  cosmoform: Cosmoform,
};

export { plugins, stores };
