<template>
  <v-avatar class="base_avatar" :size="size" v-bind="$attrs" v-on="$listeners">
    <v-img :src="src ? src : $findStatic('defaultAvatar')" :cover="true" />
  </v-avatar>
</template>

<script>
export default {
  name: "BaseAvatar",
  props: {
    src: {
      type: String,
      default: () => "",
    },
    size: {
      type: Number,
      default: () => 40,
    },
  },
};
</script>

<style scoped lang="scss">
.base_avatar {
  &::v-deep {
    .v-image__image--cover {
      background-size: contain !important ;
    }
  }
}
</style>
