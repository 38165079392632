<template>
  <div class="wizidee_data_container">
    <template>
      <a :href="content" target="_blank" class="personal_file">
        <BaseFrontOfficeImage :src="content" class="full_image" />
      </a>
      <div class="wizidee_data">
        <template v-for="(data, i) in wizideeDataList">
          <div v-if="data.value" :key="`data${i}`" class="data">
            <div class="key" v-text="data.key" />
            <div v-if="data.isImage" class="value">
              <BaseFrontOfficeImage :src="data.value" />
            </div>
            <div v-else class="value" v-text="data.value" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "WalletPreviewWizideeData",
  props: {
    wizideeData: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    wizideeDataList() {
      return [
        {
          key: this.$t("utils.authority"),
          value: this.wizideeData.authority,
        },
        {
          key: this.$t("utils.id"),
          value: this.wizideeData.permitId,
        },
        {
          key: this.$t("utils.permitList"),
          value: this.wizideeData.permitList,
        },
        {
          key: this.$t("utils.useName"),
          value: this.wizideeData.useName,
        },
        {
          key: this.$tc("utils.name", 1),
          value: this.wizideeData.name,
        },
        {
          key: this.$t("utils.firstName"),
          value: this.wizideeData.firstName,
        },
        {
          key: this.$t("utils.birthDate"),
          value: this.wizideeData.birthDate,
        },
        {
          key: this.$t("utils.birthPlace"),
          value: this.wizideeData.birthPlace,
        },
        {
          key: this.$t("utils.height"),
          value: this.wizideeData.height,
        },
        {
          key: this.$t("utils.addressFullText"),
          value: this.wizideeData.addressFullText,
        },
        {
          key: this.$t("utils.validityDate"),
          value: this.wizideeData.validityDate,
        },
        {
          key: this.$tc("utils.releaseDate", 1),
          value: this.wizideeData.releaseDate,
        },
        {
          key: this.$t("utils.signature"),
          value: this.wizideeData.signature,
          isImage: true,
        },
      ];
    },
    content() {
      return this.question.answer
        ? this.question.answer.content[0].url
        : this.question.datatype && this.question.datatype.personalData
        ? this.question.datatype.personalData.content
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.wizidee_data_container {
  display: flex;
  .personal_file {
    display: inline-block;
    width: 50%;
    flex: none;
  }
  .wizidee_data {
    flex-grow: 1;
    margin-left: 24px;
    .data {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      margin-bottom: 0.7em;
      .key {
        flex: none;
        font-size: 14px;
        color: #919191;
      }
      .value {
        margin-left: 16px;
        word-break: break-all;
        color: #4f4f4f;
      }
    }
  }
}
</style>
