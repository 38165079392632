<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiPencilOffOutline } from "@mdi/js";
export default {
  name: "MdiPencilOffOutline",
  data() {
    return {
      svgPath: mdiPencilOffOutline,
    };
  },
};
</script>
