<template>
  <div
    class="offer-file"
    :class="{ 'wallet-file': isWallet, 'cosmo-file': isCosmoForm }"
  >
    <div
      v-if="needIteration && !isWallet && nature === 'secondary'"
      class="input-label"
      v-text="`${question && question.name} ${indexFile + 1}`"
    />

    <div class="offer-file-name">
      <base-icon
        v-if="!isWallet"
        icon="$mdiFileDocument"
        medium
        :color="isDisabled ? '#666666' : '#9BBDFF'"
      />
      <v-text-field
        v-if="isEditing"
        v-model="fileName"
        class="input-name"
        single-line
        @keydown.enter="
          () => {
            isEditing = !isEditing;
            if (!isEditing) {
              $emit('file-name-changed', { value: fileName, index: indexFile });
            }
          }
        "
      />
      <div
        v-else
        class="label mr-4"
        :class="{ 'disabled-label': isDisabled, 'not-hoverable': nonEditable }"
        @dblclick="enableEdit"
        v-text="fileName"
      />
      <base-button
        v-if="!isDisabled && !isWallet"
        height="24"
        outlined
        class="btn-edit"
        @click.stop="
          () => {
            isEditing = !isEditing;
            if (!isEditing) {
              $emit('file-name-changed', { value: fileName, index: indexFile });
            }
          }
        "
      >
        <base-icon icon="$mdiPencilOutline" size="16px" class="mr-2" />
        <label v-text="isEditing ? 'Valider' : 'Editer'" />
      </base-button>
    </div>
    <div v-if="!isDisabled" class="icon-container">
      <base-icon
        icon="$mdiEye"
        medium
        color="#707080"
        class="icon"
        @click="open"
      />
      <base-icon
        icon="$mdiClose"
        medium
        color="#707080"
        class="icon"
        @click.stop="deleteFile(indexFile)"
      />
    </div>
    <folder-file-confirm-delete-dialog
      :dialog-state="dialogState"
      :file-to-delete="fileToDelete"
      @delete="$emit('delete-offer-file', indexToDelete)"
      @close="dialogState = false"
    />
  </div>
</template>

<script>
import FolderFileConfirmDeleteDialog from "./FolderFileConfirmDeleteDialog.vue";
export default {
  name: "FolderOfferFile",
  components: { FolderFileConfirmDeleteDialog },
  props: {
    file: {
      type: [File, Object],
      default: () => ({
        name: "file.pdf",
      }),
    },
    indexFile: {
      type: Number,
      default: 1,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    isWallet: {
      type: Boolean,
    },
    isCosmoForm: {
      type: Boolean,
    },
    needIteration: {
      type: Boolean,
      default: true,
    },
    question: {
      type: Object,
      default: () => {},
    },
    nature: {
      type: String,
      default: "primary",
    },
    nonEditable: {
      type: Boolean,
    },
    initialFiles: {
      type: Array,
      default: () => [],
    },
    isNotOffer: {
      type: Boolean,
    },
  },
  data() {
    return {
      fileName: "",
      isEditing: false,
      fileToDelete: null,
      indexToDelete: 0,
      dialogState: false,
    };
  },

  watch: {
    file: {
      handler() {
        this.fileName = this.file.name;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    open() {
      open(URL.createObjectURL(this.file));
    },
    enableEdit() {
      if (!this.nonEditable) {
        return (this.isEditing = !this.isEditing);
      }
    },
    deleteFile(index) {
      if (!this.isNotOffer) return this.$emit("delete-offer-file", index);
      this.fileToDelete = this.initialFiles.length && this.initialFiles[index];
      this.indexToDelete = index;
      this.dialogState = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-file {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 16px;
  border-radius: 5px;
  border: 1px solid #eeeef7;
  background: #fff;
  .offer-file-name {
    display: flex;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
    width: calc(100% - 85px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .label {
      color: #707080;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      width: calc(100% - 160px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media (max-width: 500px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 160px;
      }
    }
  }
  .icon-container {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 85px;
    height: 100%;
  }
}
.prim-icon {
  width: 17px !important;
  height: 17px !important;
}
.icon {
  cursor: pointer;
}
.btn-edit {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}
.input-name {
  max-width: 350px !important;
  margin-top: 6px;
  color: #707080;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.input-label {
  position: absolute;
  width: calc(100% - 150px);
  top: -7px;
  left: 12px;
  height: 12px;
  padding: 0 4px;
  background: #fff;
  color: var(--principale-gris-texte, #707080);
  /* Label input */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.018px;
}
.disabled-label {
  color: var(--principale-gris-noir, #353542);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
  text-decoration-line: underline;
}
.wallet-file {
  padding: 8px 17px;
  align-items: center;
  gap: 10px;
  height: 54px !important;
  border-radius: 25px;
  border: 1px solid #ff7074 !important;
  color: #4f4f4f;

  .label {
    font-family: Nunito !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 16px !important;
    padding: 4px 6px;
    &:hover {
      border-radius: 5px;
      border: 1px solid var(--Placeholder, #d9d9d9);
    }
  }
}
.not-hoverable {
  &:hover {
    border: none !important;
  }
}
.v-text-field {
  &::v-deep {
    .v-input__slot {
      background: #fff !important;
      font-family: Nunito !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-size: 16px !important;
    }
  }
}
.cosmo-file {
  border-radius: 5px !important;
  border: 1px solid var(--Linear-stroke, #3c3b8e) !important;
}
</style>
