<template>
  <router-view v-if="organizations.length" :key="$route.fullPath" />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Organization",
  computed: {
    ...mapState({
      organizations: (state) => state.organization.organizations,
      organizationsPromise: (state) => state.organization.organizationsPromise,
    }),
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    routeName: {
      handler() {
        this.updateActiveOrganization();
      },
      immediate: true,
    },
    "$route.params.organizationId"() {
      this.updateActiveOrganization();
    },
  },
  created() {
    this.initialize();
  },
  destroyed() {
    this.$store.commit("organization/setActiveOrganization", null);
  },
  methods: {
    async initialize() {
      if (this.$route.name === "organizationList") {
        this.$router.push({
          name: "organizationDashboard",
          params: {
            organizationId: this.organizations && this.organizations[0]?.id,
          },
        });
      } else if (this.$route.name === "organization") {
        this.$router.push({
          name: "organizationDashboard",
          params: {
            organizationId: this.$route.params.organizationId,
          },
        });
      }
    },
    updateActiveOrganization() {
      let organizationId = this.$route.params.organizationId;
      if (!organizationId) {
        organizationId = this.organizations && this.organizations[0]?.id;
      }
      this.$store.dispatch(
        "organization/setActiveOrganizationById",
        organizationId
      );
    },
  },
};
</script>
