<template>
  <v-dialog
    :max-width="600"
    persistant
    v-bind="$attrs"
    v-on="$listeners"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <div class="d-flex flex-column modal-container">
        <div class="modal-header">
          <BaseTitle v-text="modalTitle" />
          <BaseIcon
            icon="$mdiClose"
            color="#CFCFDC"
            class="icon-modal"
            @click.prevent="$emit('close')"
          />
        </div>

        <div class="modal-content">
          <slot name="modal" />
        </div>
        <div class="modal-actions">
          <slot name="actions" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "WalletManagerBaseModal",
  props: {
    question: {
      type: Object,
      default: null,
    },
    askModificationClicked: {
      type: Boolean,
    },
    record: {
      type: Object,
      default: null,
    },
    modalTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 20px;
}

.modal-content {
  text-align: center;
  border-bottom: 1px solid #eeeef7;
  padding: 24px 35px;
}
.modal-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 24px 35px;
  border-bottom: 1px solid #eeeef7;
}
.modal-actions {
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 24px 35px;
;
}
</style>
