<template>
  <span
    class="text--title"
    :class="{
      'text--title-white': white,
      'text--title-uppercase': uppercase,
      'text--title--xlarge': xlargeSize,
      'text--title-secondary': secondaryStyle,
    }"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "BaseFrontOfficeTitle",
  props: {
    white: {
      type: Boolean,
    },
    uppercase: {
      type: Boolean,
    },
    xlargeSize: {
      type: Boolean,
    },
    secondaryStyle: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.text--title {
  color: var(--v-walletPrimary-base);
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  font-family: var(--cosmo-font-nunito);
}
.text--title-secondary {
  font-family: var(--cosmo-font-nunito-secondary) !important;
}
.text--title--xlarge {
  font-size: 36px;
}
.text--title-white {
  color: var(--v-walletGrey-lighten5);
}
.text--title-uppercase {
  text-transform: uppercase;
}

@media all and (max-width: 600px) {
  .text--title--xlarge {
    font-size: 24px !important;
  }
}
</style>
