<template>
  <div class="services_container">
    <div class="service_header">
      <BaseFrontOfficeParagraph v-text="'Suggestions'" />
    </div>
    <div class="service_content">
      <div v-for="service in services" :key="service.id" class="service_item">
        <BaseFrontOfficeIcon icon="$mdiTag" color="primary" class="mr-4" />
        <a :href="`/campaign/${service.slug}/welcome`" v-text="service.name" />
        <BaseFrontOfficeIcon
          class="right_section"
          icon="$mdiMessageText"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrganizationServices",
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.services_container {
  margin: 16px;
  margin-bottom: 24px;
  width: calc(100% - 32px);
  border-radius: 10px;
  border: 1px solid #ebebeb;
  .service_header {
    height: 56px;
    margin: 0 16px;
    width: calc(100% - 32px);
    margin: auto;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service_content {
    .service_item {
      display: flex;
      align-items: center;
      padding: 0 24px;
      width: 100%;
      height: 56px;
      background: #fff;
      transition: all ease-in-out 0.4s;
      cursor: pointer;
      &:hover {
        background: #ebebeb;
      }
      .right_section {
        margin-left: auto;
      }
    }
  }
}
</style>
