<template>
  <BaseFrontOfficeDialog
    max-width="850"
    :value="dialogState"
    @click:outside="reset()"
    @keydown.esc="reset()"
  >
    <template #modal>
      <div v-if="question" class="modal_container">
        <template>
          <div class="d-flex justify-end">
            <BaseFrontOfficeButtonIcon icon="$mdiClose" @click="reset()" />
          </div>
          <div class="chat_item">
            <BaseImage class="chat_avatar" :src="avatarSource" />
            <div class="chat_content" :class="{ full_width: fullWidth }">
              <template>
                <div class="mb-3">
                  <span class="text-question" v-text="questionText" />
                </div>
                <div>
                  <WalletQuestionForm
                    v-if="editMode"
                    v-model="form.value"
                    full-width
                    :question="question"
                    @submit="submitForm()"
                  />
                  <template v-else>
                    <WalletPreviewWizideeData
                      v-if="
                        /^FILE|SIGNATURE$/.test(question.typeQuestion) &&
                        wizideeData
                      "
                      :question="question"
                      :wizidee-data="wizideeData"
                    />
                    <WalletPreviewData
                      v-else
                      :name="question.name"
                      full-image
                      :question="question"
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>
          <div v-if="question.validation == 'UPDATE_REQUESTED'" class="actions">
            <BaseButton
              v-if="!editMode"
              icon="$mdiPencilOutline"
              @click="editMode = !editMode"
            >
              {{ $t("utils.edit") }}
            </BaseButton>
            <BaseButton
              v-else
              :disabled="question.required && !(form.value || form.value == '')"
              :loading="isSubmitting || waitingForUpdate"
              @click="submitQuestion()"
            >
              {{ $t("utils.save") }}
            </BaseButton>
          </div>
        </template>
      </div>
    </template>
  </BaseFrontOfficeDialog>
</template>

<script>
import WalletQuestionForm from "../Components/WalletQuestionForm";
import WalletPreviewData from "../Components/WalletPreviewData";
import WalletPreviewWizideeData from "./WalletPreviewWizideeData.vue";
import RecordAPI from "@/modules/Wallet/Services/record.api";
import Question from "@/core/Models/Question";

export default {
  name: "WalletSafeRecordEditAnswerDialog",
  components: {
    WalletQuestionForm,
    WalletPreviewData,
    WalletPreviewWizideeData,
  },
  props: {
    dialogState: {
      type: Boolean,
    },
    activeRecord: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        value: null,
      },
      editMode: false,
      isSubmitting: false,
      waitingForUpdate: false,
    };
  },
  computed: {
    fullWidth() {
      if (!this.question) return false;
      return (
        this.wizideeData || /^MULTIPLE_FILE$/.test(this.question.typeQuestion)
      );
    },
    wizideeData() {
      if (!this.question) return null;
      return this.question.answer.wizideeData;
    },
    questionText() {
      if (!this.question) return "";
      return this.question.required
        ? `${this.question.label}*`
        : this.question.label;
    },
    avatarSource() {
      if (!this.activeRecord) return this.$findStatic("cosmoAvatar");
      const organizationAvatar = this.activeRecord.campaign.course.organization
        .avatar;
      return organizationAvatar
        ? organizationAvatar
        : this.$findStatic("cosmoAvatar");
    },
  },
  methods: {
    reset() {
      this.form.value = null;
      this.editMode = false;
      this.$emit("close");
    },
    submitForm() {
      if (!/^RADIOBUTTON$/.test(this.question.typeQuestion)) {
        this.submitQuestion();
      }
    },
    getPayload(payload, question) {
      if (!payload) return [];
      if (question.typeQuestion === "MULTIPLE_FILE") {
        return payload.content ? payload.content : [];
      }
      if (["FILE", "SIGNATURE"].includes(question.typeQuestion)) {
        return payload.content ? payload.content : [];
      }
      if (payload.choices) {
        return payload.choices;
      }
      if (payload.content) {
        return [payload.content];
      }

      if (payload.freeOptionContent) {
        return [`free__${payload.freeOptionContent}`];
      }

      return [];
    },
    async submitQuestion() {
      this.isSubmitting = true;
      try {
        if (/^DATAGRID$/.test(this.question.typeQuestion)) {
          this.waitingForUpdate = true;
          await new Promise((resolve) => setTimeout(resolve, 500));
          this.waitingForUpdate = false;
        }
        let setContentValue = this.getPayload(this.form.value, this.question);

        await RecordAPI.patchWalletRecordQuestionAnswer({
          recordId: this.activeRecord.id,
          questionId: this.question.id,
          payload: {
            set_content: setContentValue,
          },
        });
        const updatedQuestion = new Question({
          ...this.question,
          validation: "UPDATED",
        });
        if (this.form.value?.content)
          updatedQuestion.answer.content = this.form.value?.content;
        else if (this.form.value?.choices)
          updatedQuestion.answer.choices = this.form.value?.choices;
        this.$emit("updateQuestion", { ...updatedQuestion });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("wallet.record.createAndEditDialog.success"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("wallet.record.createAndEditDialog.error"),
          type: "ERROR",
        });
      }
      this.isSubmitting = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_container {
  padding: 24px;
  background: #fff;
  .chat_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .chat_avatar {
      width: 32px;
      height: 32px !important;
      flex: none;
    }
    .chat_content {
      background-color: #f7f7f7;
      border-radius: 0px 20px 20px 20px;
      padding: 16px 24px;
      position: relative;
      font-size: 18px !important;
      line-height: 22px;
      font-family: var(--cosmo-font-nunito);
      color: #4f4f4f;
      width: 50%;
      margin: 0 12px;
      &.full_width {
        width: calc(100% - 64px);
      }
      @media (max-width: 600px) {
        width: calc(100% - 24px);
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
    padding-bottom: 8px;
    & > * {
      margin-left: 16px;
    }
  }
}
.text-question {
  color: var(--Noir---Gris-texte, #4f4f4f);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
