import { render, staticRenderFns } from "./QuestionTemplateList.vue?vue&type=template&id=5060f35b&scoped=true"
import script from "./QuestionTemplateList.vue?vue&type=script&lang=js"
export * from "./QuestionTemplateList.vue?vue&type=script&lang=js"
import style0 from "./QuestionTemplateList.vue?vue&type=style&index=0&id=5060f35b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5060f35b",
  null
  
)

export default component.exports