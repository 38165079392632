<template>
  <div>
    <Board>
      <template #header>
        <div class="header-container">
          <div class="d-flex align-center pl-5 title-po">
            <BaseParagraph class="mr-2" text="primary" v-text="'Dossiers'" />
            <BaseDivider grey />
          </div>
          <div v-if="activeFilters.length" class="header">
            <div class="header_center">
              <div class="filters">
                <div class="filter_icon">
                  <BaseIcon icon="$mdiFilterOutline" />
                </div>
                <div
                  v-for="(filter, i) in activeFilters"
                  :key="`f-${i}`"
                  class="filter_item"
                >
                  <div v-if="filter.prependIcon" class="filter_item_icon">
                    <BaseIcon :icon="filter.prependIcon" />
                  </div>
                  <div class="filter_item_label">
                    {{ filter.label }}
                  </div>
                  <div class="close_icon" @click="removeFilter(filter)">
                    <BaseIcon icon="$mdiClose" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #main>
        <FolderListTable
          v-model="activeFolder.filters"
          :folder-detail="activeFolder.detail"
          :loading="activeFolder.loading"
          :organization-id="organizationId"
          @inputValue="handleInputFilter('CLOSE')"
          @changeValue="fetchActiveFolders()"
        />
      </template>
    </Board>
  </div>
</template>
<script>
import Board from "@/core/Components/Board";
import Folder from "@/core/Models/Folder";
import FolderListTable from "@/modules/Folder/Components/FolderListTable.vue";
import { findOrganizationFolders } from "@/modules/Folder/Services/folder.service";
import { mapState } from "vuex";
export default {
  name: "FolderList",
  components: {
    Board,
    FolderListTable,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      activeFolder: {
        loading: true,
        filters: {
          pageSize: 10,
          page: 1,
          ordering: "",
          q: "",
          name: "",
          status: [],
          assign: [],
          workflow: [],
          client: [],
        },
        detail: {
          count: 0,
          next: 0,
          previous: 0,
          folders: [],
        },
      },
      folderStateFilter: "OPEN",
      queryString: "",
      searchTimeoutRef: null,
    };
  },
  computed: {
    ...mapState({
      activeClient: (state) => state.client.activeClient,
    }),
    folderActiveTabLabel() {
      const count = this.activeFolder.detail.count;
      return `${this.$tc("utils.activeFolder", count ? count : 1)} (${count})`;
    },
    folderArchivedTabLabel() {
      const count = this.archivedFolder.detail.count;
      return `${this.$tc(
        "utils.archivedFolders",
        count ? count : 1
      )} (${count})`;
    },
    activeFilters() {
      const filters = [];
      let tmpFilters = this.activeFolder.filters;
      if (tmpFilters.ordering) {
        const orderFilter = {
          prependIcon: tmpFilters.ordering.startsWith("-")
            ? "$mdiArrowDown"
            : "$mdiArrowUp",
          label: "",
          folderState: this.folderStateFilter,
          key: "ordering",
        };
        switch (tmpFilters.ordering) {
          case "name":
          case "-name":
            orderFilter.label = "Dossier";
            break;
          case "client__email":
          case "-client__email":
            orderFilter.label = "Contact";
            break;
          case "workflow__name":
          case "-workflow__name":
            orderFilter.label = "Workflow";
            break;
          case "status__name":
          case "-status__name":
            orderFilter.label = "Status";
            break;
          case "assign__email":
          case "-assign__email":
            orderFilter.label = "Collaborateur";
            break;
          case "date_updated":
          case "-date_updated":
            orderFilter.label = "Date de modification";
            break;
        }
        filters.push(orderFilter);
      }
      if (tmpFilters.q) {
        filters.push({
          label: `Dossier : ${tmpFilters.q}`,
          folderState: this.folderStateFilter,
          key: "q",
        });
      }
      if (tmpFilters.name) {
        filters.push({
          label: `Dossier : ${tmpFilters.name}`,
          folderState: this.folderStateFilter,
          key: "name",
        });
      }
      if (tmpFilters.workflow?.length) {
        filters.push({
          label: `${tmpFilters.workflow?.length} Workflow${
            tmpFilters.workflow?.length > 1 ? "s" : ""
          } : ${tmpFilters.workflow[0]}${
            tmpFilters.workflow.length > 1 ? ".." : ""
          }`,
          folderState: this.folderStateFilter,
          key: "workflow",
        });
      }
      if (tmpFilters.status?.length) {
        filters.push({
          label: `${tmpFilters.status?.length} Statut${
            tmpFilters.status?.length > 1 ? "s" : ""
          } : ${tmpFilters.status[0]}${
            tmpFilters.status.length > 1 ? ".." : ""
          }`,
          folderState: this.folderStateFilter,
          key: "status",
        });
      }
      if (tmpFilters.assign?.length) {
        filters.push({
          label: `${tmpFilters.assign?.length} Collaborateur${
            tmpFilters.assign?.length > 1 ? "s" : ""
          } : ${tmpFilters.assign[0]}${
            tmpFilters.assign.length > 1 ? ".." : ""
          }`,
          folderState: this.folderStateFilter,
          key: "assign",
        });
      }
      return filters;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.activeFolder.filters.client.push(this.activeClient.email);
      try {
        await this.fetchActiveFolders();
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async fetchActiveFolders() {
      this.activeFolder.loading = true;
      if (this.$route.query.status && this.$route.query.workflow) {
        this.activeFolder.filters.status.push(this.$route.query.status);
        this.activeFolder.filters.workflow.push(this.$route.query.workflow);
      }
      const res = await findOrganizationFolders({
        organizationId: this.organizationId,
        filters: this.activeFolder.filters,
      });
      this.activeFolder.detail = {
        ...res.data,
        folders: res.data.results.map((f) => {
          const res = new Folder(f);
          if (f.client) {
            res.client.firstName = f?.client?.firstname;
            res.client.lastName = f?.client?.lastname;
          }
          return res;
        }),
      };
      this.activeFolder.loading = false;
    },

    removeFilter(filter) {
      if (filter.key == "q") this.queryString = "";
      this.activeFolder.filters[filter.key] = Array.isArray(
        this.activeFolder.filters[filter.key]
      )
        ? []
        : "";
      if (this.$route.query.status)
        this.$router.push({
          name: "folderList",
          params: { organizationId: this.$route.params.organizationId },
        });
      this.fetchActiveFolders();
    },
    handleInputFilter(folderState) {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        if (folderState == "OPEN") return this.fetchActiveFolders();
        this.fetchArchivedFolders();
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  .header_left {
    width: 167px;
    flex: none;
  }
  .header_right {
    width: 250px;
    flex: none;
  }
  .header_center {
    width: calc(100% - 417px);
    overflow: hidden;
  }
  .filters {
    display: flex;
    padding: 0 24px;
    align-items: center;
    .filter_icon {
      flex: none;
      background: #f4f7ff;
      border-radius: 9px;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::v-deep {
        .v-icon {
          height: 20px;
          width: 20px;
          color: #242533;
        }
      }
    }

    .filter_item {
      border-radius: 36px;
      background: #f4f7ff;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      margin-left: 8px;
      .filter_item_icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
            color: #707080;
          }
        }
      }
      .filter_item_label {
        color: #242533;
        font-family: "Inter";
        font-size: 12px;
        line-height: 20px;
        font-style: normal;
        font-weight: 500;
      }
      .close_icon {
        width: 18px;
        height: 18px;
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
            color: #2536cc;
          }
        }
      }
    }
  }
}
.tab_items {
  overflow: visible;
}
.header-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
</style>
