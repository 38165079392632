var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activeFolder)?_c('BaseModal',{attrs:{"value":_vm.dialogState,"modal-title":_vm.$t('folder.logs.history')},on:{"close":_vm.reset},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('BaseDataTable',{staticClass:"elevation-1 ma-6",attrs:{"headers":_vm.headers,"items":_vm.folderLogsTemp,"items-per-page":_vm.itemPerPage,"options":_vm.options,"loading":_vm.isFetchingFolderLogs,"server-items-length":_vm.folderLogsCount,"loading-text":_vm.$t('utils.loadingData')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('BaseParagraph',{staticClass:"ml-auto mr-auto d-block mb-1",attrs:{"text":"secondary"},domProps:{"textContent":_vm._s(
                  ((_vm.$d(new Date(item.dateCreated), 'veryshort')) + " " + (_vm.$d(
                    new Date(item.dateCreated),
                    'time'
                  )))
                )}})],1)]),_c('td',[_c('div',{staticClass:"d-flex flex-column align-start py-5"},[_c('LogsLabel',{attrs:{"folder-logs":item}})],1)])])]}}],null,false,580807844)})]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"ml-auto",attrs:{"text":"","color":"#707080"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")])]},proxy:true}],null,false,1104208981)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }