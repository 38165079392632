var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview_container"},[_c('div',{staticClass:"left_section"},[(_vm.loading)?[_c('div',{staticClass:"question_label"},[_c('v-skeleton-loader',{staticClass:"skeleton",attrs:{"type":"text"}})],1),_vm._m(0)]:[_c('div',{staticClass:"check_btn",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"color":"primary","hide-details":""},model:{value:(_vm.isCheck),callback:function ($$v) {_vm.isCheck=$$v},expression:"isCheck"}})],1),_c('div',{staticClass:"question_label",domProps:{"textContent":_vm._s(_vm.personalData.datatype.name)}}),_c('div',{staticClass:"question_answer"},[(
            Array.isArray(_vm.personalData.content) &&
            /DATAGRID|ROW_DATAGRID/.test(_vm.personalData.datatype.typeQuestion)
          )?[_c('a',{staticClass:"link_content",attrs:{"target":"_blank"},domProps:{"textContent":_vm._s(_vm.$t('utils.goTable'))}})]:(
            _vm.answerContent &&
            !/DATAGRID|ROW_DATAGRID/.test(_vm.personalData.datatype.typeQuestion)
          )?[(_vm.personalData.datatype.typeQuestion === 'MULTIPLE_FILE')?_c('div',{staticClass:"file-content"},_vm._l((_vm.answerContent),function(link,i){return _c('a',{key:("" + i),staticClass:"link_content",domProps:{"textContent":_vm._s(("Voir fichier (" + i + ")"))},on:{"click":function($event){$event.stopPropagation();return _vm.open(link.url)}}})}),0):(
              _vm.personalData.datatype.typeQuestion !== 'MULTIPLE_FILE' &&
              /FILE|SIGNATURE/.test(_vm.personalData.datatype.typeQuestion)
            )?_c('div',[(_vm.personalData.extractedData)?_c('div',{staticClass:"extracted__data",on:{"click":function($event){$event.stopPropagation();_vm.dialogState = true}}},[_vm._v(" "+_vm._s(_vm.$t("folder.records.seeFile"))+" ")]):_c('a',{staticClass:"link_content",attrs:{"href":_vm.answerContent,"target":"_blank"},domProps:{"textContent":_vm._s(_vm.$t('folder.records.seeFile'))},on:{"click":function($event){$event.stopPropagation();}}}),_c('client-wezidee-dialog',{attrs:{"dialog-state":_vm.dialogState,"personal-data":_vm.personalData},on:{"close":function($event){_vm.dialogState = false}}})],1):_c('div',{staticClass:"text_content",class:{ clickable: _vm.personalData.extractedData },on:{"click":function($event){$event.stopPropagation();return (function () {
                if (_vm.personalData.extractedData) { return (_vm.dialogState = true); }
              }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.answerContent)+" "),_c('client-wezidee-dialog',{attrs:{"dialog-state":_vm.dialogState,"personal-data":_vm.personalData},on:{"close":function($event){_vm.dialogState = false}}})],1)]:_c('div',{staticClass:"no_content"})],2)]],2),_c('div',{staticClass:"right_section"},[_c('button',{staticClass:"edit_btn"},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiPencilOutline"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.edit'))}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question_answer"},[_c('div',{staticClass:"no_content"})])}]

export { render, staticRenderFns }