<template>
  <v-footer id="cosmo-footer" class="d-none d-sm-block" padless />
</template>

<script>
export default {
  name: "TheFrontOfficeFooter",
};
</script>

<style scoped>
#cosmo-footer {
  z-index: 200 !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(
      102.88deg,
      #2f479d 40.71%,
      #965b89 73.04%,
      #ff7074 90.62%,
      rgba(255, 112, 116, 0.2) 101.58%
    ),
    #fcc00f;
}
</style>
