import User from "./User";

export default class Cancellation {
    constructor({
      author=null,
      date=  null,
      reason="",
    } = {}) {
     this.author = author? new User(author):null;
     this.date= new Date(date);
     this.reason= reason;
    }
  }