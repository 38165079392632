<template>
  <Board class="mb-6">
    <template #header>
      <BaseTitle class="mb-6" v-text="$t('utils.myInvitations')" />
    </template>
    <template #main>
      <BaseDataTable
        :items="myInvitations"
        :loading="isLoadingMyInvitations"
        :loading-text="$t('organization.loadingMyInvitations')"
        class="list_container"
        hide-default-header
        hide-default-footer
        :no-data-text="$t('organization.dashboardNoInvitation')"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <BaseParagraph color="grey" v-text="item.organization.name" />
            </td>
            <td>
              <BaseParagraph
                v-if="item.function == 'MANAGER'"
                color="grey"
                v-text="$t('account.managerRole')"
              />
              <BaseParagraph
                v-else
                color="grey"
                v-text="$t('account.memberRole')"
              />
            </td>
            <td>
              <div class="d-flex py-4 justify-end align-center">
                <BaseButton
                  type="purple"
                  color="purple"
                  class="mr-2"
                  @click="declineInvitation(item.id)"
                >
                  {{ $t("utils.refuse") }}
                </BaseButton>
                <BaseButton
                  type="success"
                  color="success"
                  @click="acceptInvitation(item.id)"
                >
                  {{ $t("utils.accept") }}
                </BaseButton>
              </div>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </template>
  </Board>
</template>

<script>
import Board from "@/core/Components/Board";
import {
  getUserInvitations,
  acceptInvitation,
  declineInvitation,
} from "../Services/dashboard.service";

export default {
  name: "MyInvitationList",
  components: {
    Board,
  },
  data() {
    return {
      myInvitations: [],
      isLoadingMyInvitations: false,
    };
  },
  created() {
    this.fetchMyInvitations();
  },
  methods: {
    fetchMyInvitations() {
      this.isLoadingMyInvitations = true;
      getUserInvitations()
        .then((res) => {
          this.myInvitations = res.data.results;
        })
        .finally(() => {
          this.isLoadingMyInvitations = false;
        });
    },
    acceptInvitation(invitationId) {
      acceptInvitation(invitationId)
        .then(async () => {
          this.$store.commit("organization/setOrganizations", []);
          this.$store.dispatch("organization/fetchOrganizations");
          await this.$store.dispatch("account/fetchUser");
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("account.organizationJoined"),
            type: "SUCCESS",
          });
          this.myInvitations = this.myInvitations.filter((inv) => {
            return inv.id != invitationId;
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("account.recordfailed"),
            type: "ERROR",
          });
        });
    },
    declineInvitation(invitationId) {
      declineInvitation(invitationId)
        .then(() => {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("account.refusalSaved"),
            type: "SUCCESS",
          });
          this.myInvitations = this.myInvitations.filter((inv) => {
            return inv.id != invitationId;
          });
        })
        .catch(() => {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("account.recordfailed"),
            type: "ERROR",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list_container {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  td {
    &:first-child {
      padding-left: 32px !important;
    }
    &:last-child {
      padding-right: 32px !important;
    }
  }
}
</style>
