import Dashboard from "../Pages/Dashboard.vue";
import Auth from "../Pages/Auth.vue";
import Account from "../Pages/Account.vue";
import AccountEditPassword from "../Pages/AccountEditPassword.vue";
import SignatoryList from "../Pages/SignatoryList.vue";
import AccountDelete from "../Pages/AccountDelete.vue";
import WalletLogin from "@/modules/Wallet/Pages/Auth/WalletLogin.vue";
import WalletRegister from "@/modules/Wallet/Pages/Auth/WalletRegister.vue";
import AuthPassword from "@/modules/Wallet/Pages/Auth/WalletAuthPassword.vue";
import PasswordReset from "@/modules/Wallet/Pages/Auth/WalletPasswordReset.vue";
import PasswordResetDone from "@/modules/Wallet/Pages/Auth/WalletPasswordResetDone.vue";
import PasswordResetConfirm from "@/modules/Wallet/Pages/Auth/WalletPasswordResetConfirm.vue";
import PasswordResetComplete from "@/modules/Wallet/Pages/Auth/WalletPasswordResetComplete.vue";

export let DashboardRoute = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "profile/",
        name: "account",
        component: Account,
        props: true,
        meta: {
          authenticationRequired: true,
        },
      },
      {
        path: "profile/signatory/",
        name: "SignatoryList",
        component: SignatoryList,
        props: true,
        meta: {
          authenticationRequired: true,
        },
      },
      {
        path: "password/",
        name: "AuthPassword",
        component: AuthPassword,
        children: [
          {
            path: "reset/",
            name: "PasswordReset",
            component: PasswordReset,
          },
          {
            path: "reset/done/",
            name: "PasswordResetDone",
            component: PasswordResetDone,
          },
          {
            path: "reset/confirm/:token/",
            name: "PasswordResetConfirm",
            component: PasswordResetConfirm,
            props: true,
          },
          {
            path: "reset/complete/",
            name: "PasswordResetComplete",
            component: PasswordResetComplete,
          },
          {
            path: "change/",
            name: "accountEditPassword",
            component: AccountEditPassword,
            props: true,
          },
        ],
      },
      {
        path: "rm-rf/",
        name: "accountDelete",
        component: AccountDelete,
        props: true,
      },
      {
        path: "login/:next?",
        name: "Login",
        component: WalletLogin,
        props: true,
      },
      {
        path: "signup/",
        name: "Register",
        component: WalletRegister,
        props: true,
      },
    ],
  },
];
