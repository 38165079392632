<template>
  <div class="organization-container d-flex flex-column">
    <div class="organization-header-information">
      <div class="organization-logo mb-5">
        <slot name="logo" />
      </div>
      <div class="d-flex justify-space-between">
        <div class="organization-description">
          <slot name="description" />
        </div>
        <div class="organization-contact">
          <slot name="contact" />
        </div>
      </div>
    </div>
    <BaseFrontOfficeDivider type="grey" class="my-6" />
    <div class="organization-content m-2">
      <slot name="content" />
    </div>
    <div class="organization-footer">
      <slot name="footer" class="orga" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeWalletWrapperOrganization",
};
</script>

<style scoped>
.organization-container {
  margin: 0px 4vw;
  height: auto;
}
.organization-header-information {
  display: flex;
  flex-direction: column;
}
.organization-logo {
  padding: 5px 0px;
}
.organization-description {
  flex-basis: 70%;
  flex-shrink: 2;
}
.organization-contact {
  flex-basis: 20%;
  flex-shrink: 1;
}
.organization-content {
  min-height: 45vh;
}
.organization-footer {
  min-height: 15vh;
}
</style>
