<template>
  <div
    class="pin-container"
    :style="`background-color:${bgColor}`"
    v-on="$listeners"
  >
    <base-icon :icon="icon" medium :color="active ? 'primary' : '#00000099'" />
    <span class="label" :class="{ 'active-label': active }" v-text="label" />
  </div>
</template>

<script>
export default {
  name: "BasePinPoint",
  props: {
    icon: {
      type: String,
      default: "$mdiAccountCircle",
    },
    label: {
      type: String,
      default: "Utilisateurs",
    },
    bgColor: {
      type: String,
      default: "#E2ECFF",
    },
    active: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.pin-container {
  height: 36px;
  border-radius: 5px;
  width: auto;
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 6px;
  cursor: pointer;
}
.label {
  color: #353542;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
}
.active-label {
  color: #2536cc;
}
</style>
