<template>
  <div>
    <v-card v-bind="$attrs" v-on="$listeners">
      <v-card-text :class="{ 'pa-0': noPadding }">
        <div class="start">
          <slot name="start" />
        </div>
        <div class="middle">
          <slot name="middle" />
        </div>
        <div class="end">
          <slot name="end" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeCardItem",
  props: {
    noPadding: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card {
  border-radius: 20px;
  box-shadow: 0px 6px 10px rgba(46, 45, 193, 0.06),
    inset 0px 2px 4px rgba(46, 45, 193, 0.1) !important;
}
::v-deep .v-card__text {
  display: flex;
  align-items: center;
  .start {
    margin: 16px;
  }
  .middle {
    flex-grow: 1;
  }
  .end {
    margin: 16px;
  }
}
</style>
