<template>
  <div class="d-flex align-center">
    <v-pagination v-bind="$attrs" v-on="$listeners" />
    <span class="pg-numbers" v-text="`${nbPages} pages`" />
  </div>
</template>

<script>
export default {
  name: "BasePagination",
  props: {
    nbPages: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.pg-numbers {
  color: #cfcfdc;
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  @media (max-width: 960px) {
    font-size: 10px;
  }
}
::v-deep .v-pagination__navigation {
  box-shadow: none;
  @media (max-width: 960px) {
    font-size: 14px;
    height: 24px;
    width: 24px;
  }
  
}
::v-deep .v-pagination__item--active {
  background-color: #f4f7ff !important;
  color: #2536cc !important;
}
::v-deep .v-pagination__item {
  box-shadow: none;
  @media (max-width: 960px) {
    font-size: 14px;
    height: 24px;
    width: 24px;
  }
}
</style>
