<template>
    <v-icon>
      {{ svgPath }}
    </v-icon>
  </template>
  
  <script>
  import { mdiFileQuestion } from "@mdi/js";
  export default {
    name: "MdiFileQuestion",
    data() {
      return {
        svgPath: mdiFileQuestion ,
      };
    },
  };
  </script>
  