let plugins = [];

// Import Stores
import WorkflowStore from "./workflow";

let stores = {
  workflow: WorkflowStore,
};

export { plugins, stores };
