<template>
  <v-btn
    :color="buttonProps.color"
    :text="buttonProps.text"
    :outlined="buttonProps.outlined"
    v-bind="$attrs"
    depressed
    :disabled="disabled"
    :type="typeProp"
    v-on="$listeners"
  >
    <div class="d-flex align-center justify-center">
      <BaseIcon
        v-if="icon"
        :icon="icon"
        :color="color"
        small
        :size="16"
        class="mr-2"
      />
      <div class="btn_label" :class="textClasses">
        <slot />
      </div>
      <BaseIcon
        v-if="appendIcon"
        :icon="appendIcon"
        :color="color"
        small
        :size="16"
        class="ml-2"
      />
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      default: () => "primary",
    },
    color: {
      type: String,
      default: () => "primary",
    },
    text: {
      type: Boolean,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: String,
      default: () => "",
    },
    appendIcon: {
      type: String,
      default: () => "",
    },
    submit: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    buttonProps() {
      if (this.type) {
        return {
          color: `${this.color} lighten-5`,
          text: this.text,
          outlined: this.outlined,
        };
      }
      return {};
    },
    typeProp() {
      if (this.submit) return "submit";
      return "button";
    },
    textClasses() {
      if (this.disabled) return {};
      return {
        [`${this.color}--text`]: this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-progress-circular--indeterminate {
  color: #2536cc;
}
</style>
