<template>
  <div>
    <div v-if="isReady">
      <v-form>
        <BaseFrontOfficeTextField
          v-model="form.password"
          :label="`${$t('account.password')}*`"
          :type="show.password ? 'text' : 'password'"
          :error-messages="passwordErrors"
          :append-icon="showPasswordIcon"
          placeholder="•••••••••"
          @click:append="show.password = !show.password"
          @change="$v.form.password.$touch()"
          @blur="$v.form.password.$touch()"
        >
          <template #tooltip>
            <v-tooltip v-model="open" top>
              <template #activator="{ on }">
                <BaseFrontOfficeIcon
                  color="walletPrimary"
                  icon="$mdiInformation"
                  v-on="on"
                />
              </template>
              <ul>
                <li
                  v-for="(warning, index) in passwordWarnings"
                  :key="`${warning.id}-${index}`"
                  v-text="warning.value"
                />
              </ul>
            </v-tooltip>
          </template>
        </BaseFrontOfficeTextField>
        <BaseFrontOfficeTextField
          v-model="form.confirmPassword"
          :label="`${$t('account.confirmPassword')}*`"
          :type="show.confirmPassword ? 'text' : 'password'"
          :error-messages="confirmPasswordErrors"
          :append-icon="showConfirmPasswordIcon"
          placeholder="•••••••••"
          @click:append="show.confirmPassword = !show.confirmPassword"
          @change="$v.form.confirmPassword.$touch()"
          @blur="$v.form.confirmPassword.$touch()"
        />
      </v-form>
      <div class="d-flex flex-column mb-4">
        <BaseFrontOfficeButton
          class="ml-auto"
          icon="$mdiSend"
          :disabled="$v.form.$invalid"
          @click="submit()"
        >
          {{ $t("utils.send") }}
        </BaseFrontOfficeButton>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <BaseProgressCircular
        class="my-6"
        color="primary"
        indeterminate
        size="72"
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "WalletPasswordResetConfirm",
  mixins: [validationMixin],
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      isReady: false,
      isLoading: false,
      form: {
        password: "",
        confirmPassword: "",
      },
      show: {
        password: false,
        confirmPassword: false,
      },
      passwordWarnings: [
        {
          value: this.$t(
            "account.editForm.warning.passwordDifferentFromPersonalData"
          ),
          id: 0,
        },
        { value: this.$t("account.editForm.errors.newPasswordLength"), id: 1 },
        {
          value: this.$t("account.editForm.warning.passwordShouldBeUnique"),
          id: 2,
        },
        {
          value: this.$t("account.editForm.warning.passwordNotAllNumeric"),
          id: 3,
        },
      ],
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(255),
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(255),
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    showPasswordIcon() {
      if (this.form.password === "") return "";
      return this.show.password ? "$mdiEye" : "$mdiEyeOff";
    },
    showConfirmPasswordIcon() {
      if (this.form.confirmPassword === "") return "";
      return this.show.confirmPassword ? "$mdiEye" : "$mdiEyeOff";
    },
    //#region Errors
    passwordErrors() {
      if (!this.$v.form.password.$dirty) return [];

      let errors = [];
      if (!this.$v.form.password.required)
        errors = [...errors, this.$t("wallet.signup.errors.passwordRequired")];

      if (!this.$v.form.password.minLength)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordLength"),
        ];
      return errors;
    },
    confirmPasswordErrors() {
      if (!this.$v.form.confirmPassword.$dirty) return [];

      let errors = [];
      if (!this.$v.form.confirmPassword.required)
        errors = [
          ...errors,
          this.$t("wallet.signup.errors.confirmPasswordRequired"),
        ];

      if (!this.$v.form.confirmPassword.minLength)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordLength"),
        ];

      if (!this.$v.form.confirmPassword.sameAsPassword) {
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordConfirm"),
        ];
      }
      return errors;
      //#endregion
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isReady = false;
      try {
        await this.$store.dispatch(
          "account/passwordResetValidateToken",
          this.token
        );
      } catch (error) {
        if (this.$route.fullPath.indexOf("/wallet") != -1) {
          this.$router.push({ name: "WalletLogin" });
        } else {
          this.$router.push({ name: "Login" }).catch((err) => {
            throw err;
          });
        }
      }
      this.isReady = true;
    },
    async submit() {
      this.$v.form.$touch();
      this.submitError = "";
      if (this.$v.form.$invalid) {
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch("account/passwordResetConfirm", {
          token: this.token,
          password: this.form.password,
        });
        if (this.$route.fullPath.indexOf("/wallet") != -1) {
          this.$router.push({ name: "WalletPasswordResetComplete" });
        } else {
          this.$router.push({ name: "PasswordResetComplete" });
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.snackbar.passwordError"),
            type: "ERROR",
          });
        }
      }
    },
  },
};
</script>
