<template>
  <v-row>
    <v-col offset="2" cols="2">
      <router-link
        class="link"
        :to="{
          name: 'folderList',
          params: { organizationId: $route.params.organizationId },
          query: { status: status.name, workflow: workflow.name },
        }"
      >
        <div v-if="workflow" class="text" v-text="status.name" />
      </router-link>
    </v-col>
    <v-col cols="5">
      <BaseProgressLinear :value="progessBarValue" class="mt-3" color="teal" />
    </v-col>
    <v-col cols="2">
      <span
        class="text"
        v-text="
          `${status.foldersCount} ${$tc('organization.folder', folderLength)}`
        "
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "StatisticsPanel",
  props: {
    status: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      default: null,
    },
  },
  computed: {
    progessBarValue() {
      if (!this.status || !this.workflow) return 0;
      return +(this.status.foldersCount * 100) / this.workflow.foldersCount;
    },
    folderLength() {
      if (this.status.foldersCount <= 1) return 1;
      return 2;
    },
  },
};
</script>
<style lang="scss">
.link {
  text-decoration-color: #353542;
}
</style>
