<template>
  <div>
    <BaseButton
      v-if="!passwordEdited"
      :to="{ name: 'account' }"
      type="secondary"
      icon="$mdiChevronLeft"
      class="mb-6"
    >
      {{ $t("utils.back") }}
    </BaseButton>
    <Board class="mb-6">
      <template #main>
        <div>
          <BaseTitle class="mb-4" v-text="$t('client.account.editPassword')" />
          <BaseDivider :grey="true" />
          <div v-if="!passwordEdited">
            <v-text-field
              ref="oldPassword"
              v-model="$v.form.oldPassword.$model"
              autofocus
              :label="$t('client.account.editForm.oldPassword')"
              :error-messages="oldPasswordErrors"
              :type="show.oldPassword ? 'text' : 'password'"
              :append-icon="show.oldPassword ? '$mdiEye' : '$mdiEyeOff'"
              @click:append="show.oldPassword = !show.oldPassword"
              @change="$v.form.oldPassword.$touch()"
              @blur="$v.form.oldPassword.$touch()"
            />
            <v-text-field
              ref="newPassword1"
              v-model="$v.form.newPassword1.$model"
              :label="$t('client.account.editForm.newPassword')"
              :error-messages="newPassword1Errors"
              :type="show.newPassword1 ? 'text' : 'password'"
              :append-icon="show.newPassword1 ? '$mdiEye' : '$mdiEyeOff'"
              @click:append="show.newPassword1 = !show.newPassword1"
              @change="$v.form.newPassword1.$touch()"
              @blur="$v.form.newPassword1.$touch()"
            />
            <v-text-field
              ref="newPassword2"
              v-model="$v.form.newPassword2.$model"
              :label="$t('client.account.editForm.confirmNewPassword')"
              :error-messages="newPassword2Errors"
              :type="show.newPassword2 ? 'text' : 'password'"
              :append-icon="show.newPassword2 ? '$mdiEye' : '$mdiEyeOff'"
              @click:append="show.newPassword2 = !show.newPassword2"
              @change="$v.form.newPassword2.$touch()"
              @blur="$v.form.newPassword2.$touch()"
            />
            <div class="d-flex justify-end">
              <BaseButton
                type="primary"
                :disabled="$v.$invalid"
                @click="submit"
              >
                {{ "Valider" }}
              </BaseButton>
            </div>
          </div>
          <div v-else>
            <BaseParagraph
              class="d-inline-block mt-10 mb-8"
              type="primary"
              v-text="$t('client.account.editForm.success')"
            />
            <div class="d-flex justify-end">
              <BaseButton type="primary" :to="{ name: 'account' }">
                {{ $t("client.account.editForm.returnToMyAccount") }}
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </Board>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

import Board from "@/core/Components/Board";

export default {
  name: "AccountEditPassword",
  components: {
    Board,
  },
  mixins: [validationMixin],
  data() {
    return {
      passwordEdited: false,
      form: {
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      show: {
        oldPassword: false,
        newPassword1: false,
        newPassword2: false,
      },
    };
  },
  validations: {
    form: {
      oldPassword: {
        required,
      },
      newPassword1: {
        required,
        minLength: minLength(8),
      },
      newPassword2: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs("newPassword1"),
      },
    },
  },
  computed: {
    oldPasswordErrors() {
      if (!this.$v.form.oldPassword.$dirty) {
        return [];
      }
      let errors = [];
      if (!this.$v.form.oldPassword.required) {
        errors.push(
          this.$t("client.account.editForm.errors.oldPasswordRequired")
        );
      }
      return errors;
    },
    newPassword1Errors() {
      if (!this.$v.form.newPassword1.$dirty) {
        return [];
      }
      let errors = [];
      if (!this.$v.form.newPassword1.required) {
        errors.push(
          this.$t("client.account.editForm.errors.newPasswordRequired")
        );
      }
      if (!this.$v.form.newPassword1.minLength) {
        errors.push(
          this.$t("client.account.editForm.errors.newPasswordLength")
        );
      }
      return errors;
    },
    newPassword2Errors() {
      if (!this.$v.form.newPassword2.$dirty) {
        return [];
      }
      let errors = [];
      if (!this.$v.form.newPassword2.required) {
        errors.push(
          this.$t("client.account.editForm.errors.newPasswordRequired")
        );
      }
      if (!this.$v.form.newPassword2.minLength) {
        errors.push(
          this.$t("client.account.editForm.errors.newPasswordLength")
        );
      }
      if (!this.$v.form.newPassword2.sameAsPassword) {
        errors.push(
          this.$t("client.account.editForm.errors.newPasswordConfirm")
        );
      }
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch("account/updateUserPassword", {
          oldPassword: this.form.oldPassword,
          newPassword1: this.form.newPassword1,
          newPassword2: this.form.newPassword2,
        });
        this.passwordEdited = true;
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.editForm.successSnackbar"),
          type: "SUCCESS",
        });
      } catch (error) {
        if (
          error.response.data.password_old &&
          error.response.data.password_old[0] === "Mot de passe invalide"
        ) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("client.account.wrongPassword"),
            type: "ERROR",
          });
          this.$refs.oldPassword.focus();
        } else {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("client.account.editForm.error"),
            type: "ERROR",
          });
        }
      }
    },
  },
};
</script>
