<template>
  <div class="input-time d-flex justify-center align-center">
    <input v-model="time" type="time" class="input-class" />
  </div>
</template>

<script>
export default {
  name: "BaseTimePickers",
  data() {
    return {
      time: null,
    };
  },
  watch: {
    time: {
      handler() {
        this.$emit("update-time", this.time);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus-visible {
  outline: none !important;
}
.input-time {
  height: 40px;
  width: 90px;
  border: 1px solid #eeeef7;
  border-radius: 5px;
}
</style>
