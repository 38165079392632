<template>
  <div :class="iconType">
    <v-icon v-bind="$attrs" :color="color" v-on="$listeners">
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "#ff5621",
    },
    iconType: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.xlarge >>> .v-icon {
  width: 40px !important;
  height: 40px !important;
}
.xlarge >>> .v-icon__svg {
  width: 80px !important;
  height: 80px !important;
}
</style>
