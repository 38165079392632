<template>
  <v-file-input
    ref="fileInput"
    dense
    :value="val"
    :class="{ 'error-input': hasError, focused: isFocused }"
    v-bind="$attrs"
    v-on="$listeners"
    @focusin="isFocused = true"
    @focusout="isFocused = false"
    @click:append="openFileInput"
    @change="onChange"
  />
</template>

<script>
export default {
  name: "BaseFileInput",
  props: {
    hasError: {
      type: Boolean,
    },
    val: {
      type: [File, String, null],
      default: () => null,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    onChange(input) {
      this.$emit("changed", { value: input });
    },
  },
};
</script>

<style lang="scss" scoped>
.focused {
  ::v-deep .v-input__slot {
    color: #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff !important;
  }
}
::v-deep .v-input__slot {
  padding: 0 0 0 12px !important;
  .v-input__append-inner {
    background: #f1f2f8;
    margin-top: 0px !important;
    .v-input__icon--append {
      height: 40px !important;
      width: 40px !important;
    }
  }
}
.error-input {
  ::v-deep .v-input__slot {
    color: #ff5267;
    box-shadow: 0px 0px 6px 1px #ffc6c6;
    .v-icon {
      color: #ff5267 !important;
    }
  }
}
</style>
