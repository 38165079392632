<template>
  <div
    v-ripple
    class="preview_cosmoform"
    @click="
      () => {
        $store.commit('cosmoform/SET_Fill_COSMOFORM_DIALOG_STATE', true);
        $store.commit('cosmoform/SET_SELECTED_COSMOFORM', cosmoform);
      }
    "
  >
    <div class="question_name">
      <p class="label">
        {{ cosmoform.question.label }}
        <span class="required">{{
          cosmoform.question.required ? "*" : ""
        }}</span>
      </p>

      <BaseIcon
        v-if="cosmoform.question.readOnly"
        class="icon"
        small
        icon="$mdiPencilOffOutline"
      />
    </div>
    <div v-if="content" class="content">
      <template
        v-if="
          /^FILE|MULTIPLE_FILE|SIGNATURE$/.test(cosmoform.question.typeQuestion)
        "
      >
        {{ $t("utils.file") }}
      </template>
      <template
        v-else-if="
          /^DATAGRID|ROW_DATAGRID$/.test(cosmoform.question.typeQuestion)
        "
      >
        {{ $t("utils.table") }}
      </template>
      <template v-else-if="/^DATE$/.test(cosmoform.question.typeQuestion)">
        {{ $d(new Date(content), "short") }}
      </template>
      <template v-else>
        {{ content }}
      </template>
    </div>
    <div v-else class="no_content" v-text="$t('utils.noContent')" />
  </div>
</template>

<script>
import Cosmoform from "@/core/Models/Cosmoform";

export default {
  name: "PreviewCosmoform",
  props: {
    cosmoform: {
      type: Cosmoform,
      required: true,
    },
  },

  computed: {
    content() {
      if (!this.cosmoform.answer) return null;
      if (
        this.cosmoform.answer.freeOptionContent &&
        this.cosmoform.question.typeQuestion === "RADIOBUTTON"
      )
        return this.cosmoform.answer.freeOptionContent;
      if (
        this.cosmoform.answer.content ||
        !isNaN(this.cosmoform.answer.content)
      )
        return this.cosmoform.answer.content?.toString();

      if (this.cosmoform.answer.choices.length) {
        let content = "";
        if (
          this.cosmoform.answer.choices.length === 1 &&
          this.cosmoform.answer.freeOptionContent
        )
          content += this.cosmoform.answer.freeOptionContent;
        else
          this.cosmoform.answer.choices.forEach((choice, idx) => {
            if (!choice || !idx) content;
            if (choice && typeof choice === "string")
              content += "," + this.cosmoform.answer.freeOptionContent;
            else {
              if (idx === 0) {
                content = this.cosmoform.question.choices.find(
                  (c) => c["id"] === choice
                ).name;
              } else if (choice) {
                content += `, ${
                  this.cosmoform.question.choices.find(
                    (c) => c["id"] === choice
                  ).name
                }`;
              }
            }
          });

        const _temp = [...new Set(content.split(","))];

        return _temp.join(",");
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview_cosmoform {
  padding: 16px;
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
    0px 6px 10px rgba(46, 45, 193, 0.06);
  width: 100%;
  .question_name {
    display: flex;
    align-items: center;
    padding-bottom: 0.2em;
    .label {
      font-weight: bold;
      font-size: 16px;
      line-height: 14px;
      text-transform: uppercase;
      color: #8b8b98;

      .required {
        color: #ff5252;
      }
    }
    .icon {
      margin: 0 4px;
    }
  }

  .content {
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #2536cc;
  }
  .no_content {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #c4c4c4;
    text-transform: uppercase;
  }
}
</style>
