<template>
  <div class="wsc_container">
    <div class="wsc_header">
      <div class="header_left">
        <div class="category_label" v-text="'Catégorie :'" />
        <v-chip
          class="ma-2 text-uppercase"
          text-color="#353542"
          color="#F4F7FF"
        >
          {{ statusCategory.name }}
        </v-chip>
      </div>
      <div v-if="statusCategory.id" class="header_right">
        <BaseButtonIcon
          color="gray"
          outlined
          tile
          class="rounded"
          icon="$mdiArrowDownThick"
          :disabled="isLast"
          @click="changeStatusCategoryOrder(statusCategory.order + 1)"
        />
        <BaseButtonIcon
          color="gray"
          outlined
          tile
          class="rounded"
          icon="$mdiArrowUpThick"
          :disabled="isFirst"
          @click="changeStatusCategoryOrder(statusCategory.order - 1)"
        />
        <BaseButton type="tertiary" @click="editCategory()">
          {{ $t("utils.edit") }}
        </BaseButton>
        <BaseButton color="secondary" @click="confirmRemoveStatusCategory()">
          {{ $t("utils.delete") }}
        </BaseButton>
      </div>
    </div>
    <div class="wsc_body">
      <div class="category_questions">
        <div class="category_question_header">
          <div class="header_label" v-text="$t('utils.fields')" />
          <div class="header_label" v-text="$t('utils.category')" />
          <div class="header_label" v-text="$tc('course.personalData', 1)" />
          <div class="header_label" v-text="$tc('utils.type', 1)" />
          <div class="header_label" v-text="'Aides'" />
          <div class="header_label" v-text="$tc('utils.type', 2)" />
        </div>
        <VueDraggable
          :list="questionList"
          draggable=".category_question_item"
          ghost-class="dragged_item"
          :group="`StatusCategory-${statusCategory.statusId}`"
          @end="onDragEnd"
        >
          <div
            v-for="(question, i) in questionList"
            :key="`q-${question.id}-${i}`"
            class="category_question_item"
            @click.stop="$emit('editingQuestion', { statusCategory, question })"
          >
            <div class="item_section">
              <v-icon color="primary">
                {{ "$mdiDotsGrid" }}
              </v-icon>
              <div class="question_detail">
                <div class="question_label">
                  {{ question.label }}{{ question.required ? "*" : "" }}
                </div>
                <div class="question_name" v-text="question.name" />
              </div>
            </div>
            <div class="item_section">
              <v-chip class="ma-2">
                {{ statusCategory.name }}
              </v-chip>
            </div>
            <div class="item_section">
              <div class="question_type">
                {{ question.typeQuestion }}
              </div>
            </div>
            <div class="item_section">
              <div v-if="question.datatype" class="d-flex flex-column">
                <BaseIcon icon="$mdiCheck" color="primary" />
                <BaseDescription
                  text="secondary"
                  uppercase
                  v-text="question.datatype"
                />
              </div>
              <div v-else-if="question.slug" class="d-flex flex-column">
                <BaseDescription
                  text="secondary"
                  uppercase
                  v-text="question.slug"
                />
              </div>
            </div>
            <div class="item_section">
              <BaseIcon
                icon="$mdiHelpCircle"
                color="primary"
                class="mr-2"
                :class="!question.helpText ? 'disable' : ''"
              />
              <BaseIcon
                icon="$mdiFileQuestion"
                color="primary"
                :class="!question.helpFile ? 'disable' : ''"
              />
            </div>
            <div class="item_section">
              <BaseButtonIcon
                small
                color="primary"
                icon="$mdiPencilOutline"
                @click.stop="
                  $emit('editingQuestion', { statusCategory, question })
                "
              />
              <BaseButtonIcon
                color="secondary"
                small
                icon="$mdiTrashCanOutline"
                @click.stop="$emit('removeQuestion', { question })"
              />
            </div>
          </div>
        </VueDraggable>
      </div>
    </div>
    <div class="wsc_footer">
      <v-btn
        class="primary lighten-5"
        color="primary"
        text
        @click="$emit('addQuestion', { statusCategory })"
      >
        <v-icon>
          {{ "$mdiPlus" }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import VueDraggable from "vuedraggable";
export default {
  name: "WorkflowStatusCategory",
  components: {
    VueDraggable,
  },
  props: {
    statusCategory: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionList: [],
    };
  },
  watch: {
    "statusCategory.questions": {
      handler(value) {
        this.questionList = [...value];
      },
      immediate: true,
    },
  },
  methods: {
    async changeStatusCategoryOrder(order) {
      await this.$store.dispatch("workflow/moveStatusCategory", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.$route.params.workflowId,
        statusId: this.statusCategory.statusId,
        categoryId: this.statusCategory.id,
        order: order,
      });
    },
    onDragEnd(event) {
      let movedQuestion = event.item._underlying_vm_;
      let tagertStatusCategory;
      if (event.oldIndex === event.newIndex && !event.pullMode) {
        return;
      } else if (event.pullMode) {
        tagertStatusCategory = event.to.__vue__.$parent.statusCategory;
      } else {
        tagertStatusCategory = this.statusCategory;
      }
      const payload = {
        ...movedQuestion,
        help_text: movedQuestion.helpText,
        order:
          tagertStatusCategory.questions.length > event.newIndex
            ? tagertStatusCategory.questions[event.newIndex].order
            : tagertStatusCategory.questions[event.newIndex - 1].order + 1,
        category: tagertStatusCategory.id,
        has_free_option: movedQuestion.hasFreeOption,
      };
      if (!payload.image) {
        delete payload.image;
      }
      this.editQuestion(movedQuestion, payload);
    },
    async editQuestion(question, payload) {
      try {
        await this.$store.dispatch("workflow/editWorkflowStatusQuestion", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.statusCategory.statusId,
          questionId: question.id,
          payload: payload,
          deletedOptionsIds: [],
          updateState: false,
        });
        this.$store.dispatch("snackbar/active", {
          message: "Champ modifié avec succès",
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    confirmRemoveStatusCategory() {
      this.$store.commit(
        "workflow/SET_REMOVE_STATUS_CATEGORY_MODAL_STATUS",
        true
      );
      this.$store.commit(
        "workflow/SET_ACTIVE_STATUS_CATEGORY",
        this.statusCategory
      );
    },
    editCategory() {
      this.$store.commit("workflow/SET_ACTIVE_STATUS_CATEGORY", {
        id: this.statusCategory.id,
        name: this.statusCategory.name,
        organizationId: this.$route.params.organizationId,
        statusId: this.statusCategory.statusId,
      });
      this.$store.commit(
        "workflow/SET_EDIT_QUESTION_CATEGORY_MODAL_STATUS",
        true
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wsc_container {
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 16px;
  margin: 16px 0;
  .wsc_header {
    padding: 8px 16px 24px;
    display: flex;
    border-bottom: 1px solid #eeeef7;
    .header_left {
      display: flex;
      align-items: center;
      .category_label {
        padding: 0 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #353542;
      }
    }
    .header_right {
      display: flex;
      align-items: center;
      margin-left: auto;
      & > * {
        margin: 0px 8px;
      }
    }
  }
  .wsc_body {
    .category_questions {
      .category_question_header {
        margin: 8px 0;
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 2fr 2fr 100px;
        .header_label {
          padding: 24px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 18px;
          color: #707080;
          text-align: center;
          &:first-child {
            padding-left: 40px;
            text-align: start;
          }
        }
        .red {
          background: red;
        }
      }
      .category_question_item {
        margin: 8px 0;
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 2fr 2fr 100px;
        background: #ffffff;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        border: none;
        cursor: pointer;
        &.dragged_item {
          border: 1px dashed #2536cc;
          opacity: 0.5;
          background: #c8ebfb !important;
        }
        .item_section {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;

          .disable {
            opacity: 0.6;
          }
          &:first-child {
            justify-content: flex-start;
            & > * {
              margin: 8px;
            }
          }
          &:last-child {
            justify-content: flex-end;
            & > * {
              margin: 0 4px;
            }
          }
          .question_detail {
            .question_label {
              font-family: "Nunito";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #2536cc;
            }
            .question_name {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 16px;
              text-transform: uppercase;
              color: #707080;
            }
          }
          .question_type {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #707080;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .wsc_footer {
    display: flex;
    padding: 8px;
    justify-content: center;
  }
}
</style>
