<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiAccount } from "@mdi/js";
export default {
  name: "MdiAccount",
  data() {
    return {
      svgPath: mdiAccount,
    };
  },
};
</script>
