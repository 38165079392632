<template>
  <div>
    <BaseFrontOfficeParagraph
      v-if="label"
      class="d-block mb-3"
      v-text="label"
    />
    <div class="d-flex" :class="{ large }">
      <v-file-input
        v-model="internValue"
        outlined
        show-size
        dense
        prepend-icon
        truncate-length="50"
        prepend-inner-icon="$mdiUpload"
        :error-messages="imageErrors"
        :accept="types"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <a
        v-if="isImage"
        :href="localImage ? localImage : image"
        target="blank"
        class="flex-grow-0 ml-4"
      >
        <BaseImage
          :src="localImage ? localImage : image"
          height="40px"
          width="72px"
          class="rounded-4"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "BaseFrontOfficeFileInput",
  mixins: [validationMixin],
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: File,
      default: null,
    },
    types: {
      type: String,
      default: "image/jpeg,image/png,image/jpg,application/pdf",
    },
    maxSize: {
      type: Number,
      default: 2000000, // 8 Mo
    },
    required: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
  },
  data() {
    return {
      internValue: null,
      localImage: "",
    };
  },
  validations() {
    let validations = {
      internValue: {},
    };
    if (this.required) {
      validations.internValue = {
        required,
      };
    }
    return validations;
  },
  computed: {
    isImage() {
      return (
        !!this.internValue &&
        (/^image\/jpg$/.test(this.internValue.type) ||
          /^image\/jpeg$/.test(this.internValue.type) ||
          /^image\/png$/.test(this.internValue.type))
      );
    },
    imageErrors() {
      let errors = [];
      if (this.internValue) {
        let typeContained = false;
        const typesArray = this.types.split(",");
        for (const type of typesArray) {
          if (type === this.internValue.type) {
            typeContained = true;
            break;
          }
        }
        if (!typeContained) {
          const tempImageTexts = typesArray
            .map((tempImageText) => {
              return tempImageText
                .replace("image/", "")
                .replace("application/", "")
                .toUpperCase();
            })
            .join(", ");
          errors.push(
            this.$t("utils.pleaseProvideAnImageError", {
              types: tempImageTexts,
            })
          );
        }
        if (this.internValue.size > this.maxSize) {
          errors.push(
            this.$t("utils.imageTooBig", { size: this.maxSize / 1000000 })
          );
        }
      }
      if (
        this.required &&
        this.$v.internValue.$dirty &&
        !this.$v.internValue.required
      ) {
        errors.push(this.$t("utils.fileRequired"));
      }
      return errors;
    },
  },
  watch: {
    value() {
      this.internValue = this.value;
    },
    internValue(value) {
      this.$v.internValue.$touch();
      if (value) {
        this.localImage = URL.createObjectURL(value);
        this.$emit("changeValue", value);
      } else {
        this.$emit("change", null);
      }
      this.$emit("errors", !!this.imageErrors.length);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  position: relative;
  min-width: 250px;
  border-radius: 25px;
  background: var(--cosmo-gradient-primary) !important;
  padding: 12px 24px;
}
.large {
  &::v-deep .v-input__slot {
    padding: 14px 24px !important;
    .v-input__prepend-inner {
      margin: 2px !important;
    }
    .v-input__append-inner {
      margin: 2px !important;
    }
  }
}
::v-deep .v-input__slot fieldset {
  border-radius: 25px;
  margin: 1px 2px 2px 2px !important;
  border: hidden;
  background-color: var(--v-walletGrey-lighten5);
}

::v-deep .v-icon__svg {
  color: var(--v-walletAccent-base) !important;
  z-index: 1;
}
</style>
