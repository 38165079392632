var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview_container",class:{
    selectable: _vm.selectable,
    disable: _vm.disabled,
    checked: _vm.isCheck,
    expanded: _vm.expanded,
    loading: _vm.loading,
    update__form: _vm.updateNeeded,
    validated__form: _vm.validated,
    updated__form: _vm.updated,
  },on:{"click":function($event){return _vm.$emit('questionClick', [_vm.question])}}},[_c('div',{staticClass:"left_section"},[(_vm.loading)?[_c('div',{staticClass:"question_label"},[_c('v-skeleton-loader',{staticClass:"skeleton",attrs:{"type":"text"}})],1),_vm._m(0)]:(_vm.question)?[_c('div',{staticClass:"check_btn",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"color":"primary","hide-details":""},model:{value:(_vm.isCheck),callback:function ($$v) {_vm.isCheck=$$v},expression:"isCheck"}})],1),_c('div',{staticClass:"question_label",domProps:{"textContent":_vm._s(_vm.question.name)}}),_c('div',{staticClass:"question_answer"},[(
            _vm.content && /DATAGRID|ROW_DATAGRID/.test(_vm.question.typeQuestion)
          )?[_c('span',{staticClass:"link_content",domProps:{"textContent":_vm._s(_vm.$t('utils.goTable'))}})]:(
            _vm.content && !/DATAGRID|ROW_DATAGRID/.test(_vm.question.typeQuestion)
          )?[(_vm.question.typeQuestion === 'MULTIPLE_FILE')?_c('span',{staticClass:"link_content",domProps:{"textContent":_vm._s(("Voir " + (_vm.content.length) + " fichier(s)"))}}):(
              _vm.question.typeQuestion !== 'MULTIPLE_FILE' &&
              /FILE|SIGNATURE/.test(_vm.question.typeQuestion)
            )?_c('span',{staticClass:"link_content",domProps:{"textContent":_vm._s(_vm.$t('folder.records.seeFile'))}}):_c('div',{staticClass:"text_content",domProps:{"textContent":_vm._s(_vm.content)}})]:_c('div',{staticClass:"no_content"})],2)]:_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question_answer"},[_c('div',{staticClass:"no_content"})])}]

export { render, staticRenderFns }