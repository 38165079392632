let plugins = [];

// Import Stores
import AccountStore from "./account";

let stores = {
  account: AccountStore,
};

export { plugins, stores };
