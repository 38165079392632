<template>
  <BaseModal
    :value="dialogState"
    :modal-title="
      isCreateForm ? 'Créer un nouveau statut' : 'Modifier le nom du statut'
    "
    is-unique
    @close="reset"
  >
    <template #modal>
      <v-form ref="form" @submit.prevent="submit()">
        <BaseTextField
          v-model="form.name"
          autofocus
          :placeholder="$tc('utils.name', 1) + '*'"
          :error-messages="nameErrors"
          :has-error="!!nameErrors"
          @changed="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
      </v-form>
    </template>

    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton :disabled="$v.$invalid" type="primary" @click="submit">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "WorkflowCreateAndEditStatusDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: null,
      },
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) =>
        state.workflow.modals.isCreateAndEditStatusDialogOpen,
      activeStatus: (state) => state.workflow.activeStatus,
    }),
    isCreateForm() {
      return this.$store.state.workflow.activeStatus == null;
    },
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("workflow.status.error.statusNameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t(
          "workflow.status.error.statusNameMustBeLessThan255Characters"
        );
      }
      return "";
    },
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  watch: {
    dialogState(newVal) {
      if (newVal && this.activeStatus) {
        this.form.name = this.activeStatus.name;
      }
    },
  },
  methods: {
    reset() {
      this.form = {
        name: null,
      };
      this.$v.$reset();
      this.$store.commit("workflow/setModalCreateAndEditStatusDialog", false);
      this.$store.commit("workflow/setActiveStatus", null);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isCreateForm ? await this.create() : await this.edit();
      this.reset();
    },
    async create() {
      try {
        const payload = {
          name: this.form.name,
        };
        await this.$store.dispatch("workflow/createWorkflowStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          payload,
        });

        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.success.statusCreatedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.error.errorCreatingStatus"
          ),
          type: "ERROR",
        });
      }
    },
    async edit() {
      try {
        const payload = {
          name: this.form.name,
        };
        await this.$store.dispatch("workflow/updateWorkflowStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          payload,
        });

        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.success.statusModifiedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.error.errorChangingStatus"
          ),
          type: "ERROR",
        });
      }
    },
  },
};
</script>
