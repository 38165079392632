<template>
  <div v-if="isConsulted">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseStepperContent",
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isConsulted() {
      return this.$parent?.consultedStep == this.step;
    },
  },
};
</script>
