<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('organization.deleteCollaborator')"
    @click:outside="close"
    @close="close"
  >
    <template v-if="activeCollaborator" #modal>
      <div class="modal_content text-center">
        <div
          class="alert_danger"
          v-text="$t('organization.removeCollaborator.alert')"
        />
        <div
          class="mt-2 alert_danger text-normal"
          v-text="
            $t('organization.removeCollaborator.message', {
              email: activeCollaborator.user.email,
            })
          "
        />
      </div>
    </template>

    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="close"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OrganizationRemoveCollaboratorDialog",
  data() {
    return {
      isSubmiting: false,
    };
  },
  computed: {
    ...mapState({
      activeCollaborator: (state) => state.organization.activeCollaborator,
      dialogState: (state) => state.organization.isRemoveCollaboratorModalOpen,
    }),
  },
  methods: {
    close() {
      this.$store.commit("organization/SET_ACTIVE_COLLABORATOR", null);
      this.$store.commit(
        "organization/SET_REMOVE_COLLABORATOR_DIALOG_STATUS",
        false
      );
    },
    async submit() {
      this.isSubmiting = true;
      try {
        await this.$store.dispatch("organization/removeCollaborator", {
          collaboratorId: this.activeCollaborator.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.removeCollaborator.removeSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 16px 0 16px;
  flex-wrap: wrap;
  .modal_header_title {
    width: 100%;
    margin-bottom: 0.4em;
  }
}
.modal_content {
  padding-top: 24px;
  & > * {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .alert_danger {
    padding: 0 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ff5267;
  }
  .text-normal {
    color: #353542;
  }
}
.modal_footer {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
}
</style>
