<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('folder.workflow.assignCollaborator')"
    @click:outside="reset"
    @keydown.esc="reset"
    @close="reset"
  >
    <template #modal>
      <v-list>
        <v-list-item
          v-for="(assignation, index) in collaboratorsList"
          :key="index"
          class="list-item"
        >
          <v-checkbox
            :input-value="assignation.isAssigned"
            :label="assignation.user.email"
            @change.passive="toggleAddDelCollaborator(assignation)"
          />
        </v-list-item>
      </v-list>
    </template>

    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="
          () => {
            $store.commit('workflow/setActiveStatus', null);
            $store.commit(
              'workflow/setCollaboratorsManagementStatusDialogOpen',
              false
            );
          }
        "
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        type="primary"
        @click="
          () => {
            $store.commit('workflow/setActiveStatus', null);
            $store.commit(
              'workflow/setCollaboratorsManagementStatusDialogOpen',
              false
            );
          }
        "
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapState } from "vuex";

export default {
  name: "WorkFlowCollaboratorsAddAndDelDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: null,
      },
      collaboratorsList: [],
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) =>
        state.workflow.modals.isCollaboratorsManagementStatusDialogOpen,
      activeStatus: (state) => state.workflow.activeStatus,
      collaboratorsAssigned: (state) =>
        state.workflow.activeStatus.collaboratorsAssigned,
    }),
    ...mapGetters({
      collaborators: "workflow/collaborators",
    }),
  },
  watch: {
    dialogState(newVal) {
      if (newVal) {
        this.collaboratorsList = this.getCollaboratorsList(this.collaborators);
      }
    },
  },
  methods: {
    reset() {
      this.$store.commit(
        "workflow/setCollaboratorsManagementStatusDialogOpen",
        false
      );
      this.$store.commit("workflow/setActiveStatus", null);
    },
    getCollaboratorsList(value) {
      const results = value.map((collaborator) => {
        const assignedCollaborator = this.activeStatus.collaboratorsAssigned
          ?.length
          ? this.activeStatus.collaboratorsAssigned.find(
              (c) => c.collaborator.user.id == collaborator.user.id
            )
          : false;

        const res = {
          ...collaborator,
          isAssigned: !!assignedCollaborator,
          assignmentId: assignedCollaborator ? assignedCollaborator.id : null,
        };
        return res;
      });

      return results;
    },
    async addCollaboratorToStatus(collaboratorId) {
      try {
        await this.$store.dispatch("workflow/addCollaboratorToStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          collaboratorId,
        });
        this.collaboratorsList = this.collaboratorsList.map((collaborator) => {
          return {
            ...collaborator,
            isAssigned:
              collaborator.id == collaboratorId
                ? false
                : collaborator.isAssigned,
          };
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "folder.workflow.snackbar.success.collaboratorAssignedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
    },
    async delCollaboratorToStatus(collaboratorId, assignmentId) {
      try {
        await this.$store.dispatch("workflow/delCollaboratorToStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          assignStatusId: assignmentId,
        });

        this.collaboratorsList = this.collaboratorsList.map((collaborator) => {
          return {
            ...collaborator,
            isAssigned:
              collaborator.id == collaboratorId
                ? false
                : collaborator.isAssigned,
          };
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "folder.workflow.snackbar.success.collaboratorUnAssignedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "folder.workflow.snackbar.error.collaboratorUnAssignationError"
          ),
          type: "ERROR",
        });
      }
    },
    toggleAddDelCollaborator(collaborator) {
      if (collaborator.isAssigned) {
        this.delCollaboratorToStatus(
          collaborator.id,
          collaborator.assignmentId
        );
      } else {
        this.addCollaboratorToStatus(collaborator.user.id);
      }
    },
  },
};
</script>
<style scoped>
.list-item {
  margin-bottom: -22px;
}
</style>
