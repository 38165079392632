<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('folder.statusForm.title')"
    is-unique
    @close="reset"
  >
    <template #modal>
      <div class="d-flex flex-column">
        <BaseSelect
          v-model="form.targetStatus"
          :label="'Choix du statut*'"
          :items="isLoadingTargetStatuses ? [] : targetStatuses"
          solo
          item-text="message"
          item-value="statusTo.id"
          :loading="isLoadingTargetStatuses"
          :error-messages="targetStatusErrors"
          @change="setAssignableCollaborators()"
        />
        <BaseSelect
          v-model="form.collaborator"
          :disabled="!form.targetStatus"
          solo
          :label="'E-mail du collaborateur assigne'"
          :menu-props="{ offsetY: true }"
          :loading="isLoadingCollaborators"
          :items="assignableCollaborators"
          item-text="user.email"
          item-value="user.id"
        />
        <BaseTextField
          v-model="form.message"
          :label="'Message pour le collaborateur'"
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click="submit">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { getCollaboratorAssignedToStatus } from "../../Admin/Services/workflow.service";

export default {
  name: "FolderChangeStatusDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        targetStatus: null,
        collaborator: null,
        message: "",
      },
      isLoadingTargetStatuses: false,
      isLoadingCollaborators: false,
      collaboratorsTargetAssigned: [],
      assignableCollaborators: [],
    };
  },
  validations: {
    form: {
      targetStatus: {
        required,
      },
      collaborator: {
        required,
      },
      message: {},
    },
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
      dialogState: (state) =>
        state.folder.modals.isFolderChangeStatusDialogOpen,
      activeFolder: (state) => state.folder.activeFolder,
      activeTargetStatus: (state) => state.workflow.activeTargetStatus,
      collaborators: (state) => state.workflow.collaborators,
      statusTemplate: (state) => state.folder.statusTemplate,
    }),
    activeStatus() {
      return this.activeWorkflow?.statuses.find(
        (s) => s.id == this.activeFolder?.status
      );
    },

    targetStatuses() {
      if (this.activeStatus?.targetStatuses?.length == 0) {
        return this.activeWorkflow?.statuses
          ?.filter((s) => {
            return s.id != this.activeFolder?.status;
          })
          ?.map((status) => {
            return {
              label: status.name,
              statusTo: status,
              message: status.name,
            };
          });
      }

      return this.activeStatus?.targetStatuses.map((statut) => ({
        ...statut,
        message: statut.label,
        label: statut.statusTo.name,
      }));
    },
    //#region Form errors
    targetStatusErrors() {
      if (!this.$v.form.targetStatus.$dirty) {
        return "";
      }
      if (!this.$v.form.targetStatus.required) {
        return this.$t("folder.statusForm.statusRequired");
      }
      return "";
    },
    //#endregion
  },
  watch: {
    dialogState(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },
  methods: {
    reset() {
      this.form.targetStatus = null;
      this.$v.$reset();
      this.$store.dispatch(
        "workflow/resetTargetStatuses",
        this.activeStatus.id
      );
      this.$store.commit("folder/setModalFolderChangeStatusDialog", false);
      this.form.collaborator = null;
      this.form.message = "";
      this.collaboratorsTargetAssigned = [];
    },
    async initialize() {
      this.isLoadingTargetStatuses = true;
      await Promise.all[
        (this.$store.dispatch("workflow/fetchOrganizationCollaborators", {
          organizationId: this.$route.params.organizationId,
        }),
        this.$store.dispatch("workflow/fetchTargetStatuses", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.activeWorkflow.id,
          statusId: this.activeStatus.id,
        }))
      ];
      if (this.statusTemplate) {
        const { collaborator, status } = this.statusTemplate;
        this.form.targetStatus = status;
        this.form.collaborator = collaborator;
        await this.setAssignableCollaborators();
      }

      this.isLoadingTargetStatuses = false;
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }
      const payloadSetCollaborator = {
        assign: this.form.collaborator,
        status: this.form.targetStatus,
        message: this.form.message,
      };
      this.reset();
      try {
        const res = await this.$store.dispatch(
          "folder/setFolderCollaboratorStatus",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            payload: payloadSetCollaborator,
          }
        );
        // update current active folder
        this.$store.commit("folder/SET_ACTIVE_FOLDER", {
          ...this.activeFolder,
          ...res.data,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.statusForm.success"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.statusForm.error"),
          type: "ERROR",
        });
      }
    },
    async setAssignableCollaborators() {
      this.isLoadingCollaborators = true;
      const collaboratorsAssignedResult = (
        await getCollaboratorAssignedToStatus(
          this.$route.params.organizationId,
          this.activeWorkflow.id,
          this.form.targetStatus
        )
      )?.data?.results;
      if (collaboratorsAssignedResult.length > 0) {
        this.assignableCollaborators = collaboratorsAssignedResult.map(
          (collaboratorAssignation) => ({
            ...collaboratorAssignation.collaborator,
          })
        );
      } else {
        this.assignableCollaborators = this.collaborators;
      }
      this.isLoadingCollaborators = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
}

.modal_actions {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
}
</style>
