var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isProfile)?_c('div',[_c('BaseFrontOfficeTitle',{domProps:{"textContent":_vm._s(_vm.$t('auth.reset.title'))}}),_c('BaseFrontOfficeDivider',{staticClass:"my-4",attrs:{"type":"grey"}}),_c('router-view'),_c('div',{staticClass:"d-flex justify-end mb-4"},[(_vm.$route.fullPath.indexOf('/wallet') != -1)?_c('router-link',{staticClass:"link_signup",attrs:{"to":{
          name: 'WalletLogin',
          query: {
            next: _vm.$route.query.next,
          },
          params: {
            accessCampaign: _vm.$route.params.accessCampaign,
          },
        }},domProps:{"textContent":_vm._s(_vm.$t('auth.reset.goBack'))}}):_c('router-link',{staticClass:"link_signup",attrs:{"to":{ name: 'Login' }},domProps:{"textContent":_vm._s(_vm.$t('auth.reset.goBack'))}})],1),_c('WalletHasToRegisterVue')],1):_c('div',[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }