<template>
  <div class="toolbar">
    <div class="section_left">
      <v-breadcrumbs :items="breadcrumbItems" divider=">">
        <template #item="{ item }">
          <v-breadcrumbs-item
            href="#"
            :disabled="item.disabled"
            @click.prevent="navigateTo(item)"
          >
            <div class="breadcrumbs_item" v-text="$t(item.label)" />
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="section_right">
      <div v-if="user" class="user">
        <div class="user_info text_x_sm">
          <div v-if="user.fullName" v-text="user.fullName" />
          <div v-text="user.email" />
        </div>
        <BaseMenu bottom offset-y :close-on-content-click="false">
          <template #activator="{ on, attrs }">
            <BaseAvatar
              width="38"
              height="38"
              min-width="auto"
              class="user_avatar"
              :src="user ? user.avatar : ''"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <template #content>
            <BaseList>
              <section class="d-flex justify-start mx-3 flex-column">
                <BaseDescription
                  v-if="user"
                  text="primary"
                  v-text="user.firstName + ' ' + user.lastName"
                />
                <BaseDescription
                  v-if="user"
                  text="secondary"
                  v-text="user.email"
                />
                <BaseDivider class="mb-1 mt-2" :grey="true" />
                <BaseButton
                  :to="{ name: 'account' }"
                  type="secondary"
                  class="items"
                  icon="$mdiAccount"
                >
                  {{ $t("navigation.myAccount") }}
                </BaseButton>

                <BaseButton
                  type="secondary"
                  icon="$mdiPowerStandby"
                  class="items"
                  @click="logout"
                >
                  {{ $t("navigation.signOut") }}
                </BaseButton>
                <BaseButton
                  v-if="isImpersona"
                  type="secondary"
                  icon="$mdiPowerStandby"
                  class="items"
                  color="error"
                  @click="removeImpersona"
                >
                  Impersona
                </BaseButton>
              </section>
            </BaseList>
          </template>
        </BaseMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { revokeToken } from "../Api/cosmoAxiosInstance";
export default {
  name: "TheAppBar",
  data() {
    return {
      breadcrumbItems: [],
    };
  },
  watch: {
    $route: {
      handler(value) {
        this.breadcrumbItems = value.meta.breadcrumbs
          ? value.meta.breadcrumbs
          : [];
      },
      immediate: true,
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    isImpersona() {
      return localStorage.getItem("oauthAdmin");
    },
  },
  methods: {
    async logout() {
      if (await revokeToken()) {
        this.$store.dispatch("core/resetApp");
        this.$router.push("/auth/login/").catch((err) => {
          throw err;
        });
        localStorage.removeItem("oauthAdmin");
      }
    },
    removeImpersona() {
      const oauth = localStorage.getItem("oauthAdmin");
      this.$router.push({ name: "account" });
      localStorage.setItem("oauth", oauth);
      localStorage.removeItem("oauthAdmin");
      location.reload();
    },
    navigateTo(route) {
      const params = route.params
        ? { ...route.params, ...this.$route.params }
        : this.$route.params;
      this.$router.push({
        name: route.name,
        params: params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 8px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(20, 20, 243, 0.1),
    0px 2px 2px rgba(0, 15, 144, 0.05),
    inset 5px 0px 17px -6px rgba(20, 20, 243, 0.1);
  .section_left {
    padding: 0 8px;
    .breadcrumbs_item {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .section_right {
    margin-left: auto;
    padding: 0 8px;
    .user {
      display: flex;
      align-items: center;
      .user_info {
        text-align: end;
        margin-right: 8px;
      }
    }
  }
}
.user_avatar {
  border: 0.5px solid #cfcfdc;
  box-shadow: inset 0px 3px 3px rgba(48, 48, 62, 0.2);
}
.items {
  display: flex;
  justify-content: flex-start;
}
</style>
