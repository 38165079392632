<template>
  <BaseModal
    :value="isOpen"
    :modal-title="
      course && !course.archived ? $t('course.archive') : $t('course.unarchive')
    "
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph
          v-text="
            course && !course.archived
              ? $t('course.archiveActionAsk')
              : $t('course.unarchiveActionAsk')
          "
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click.exact="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit()">
        {{
          course && !course.archived
            ? $t("utils.archive")
            : $t("utils.unarchive")
        }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CourseToggleArchiveDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      course: (state) => state.course.course,
      archivedCourses: (state) => state.course.archivedCourses,
    }),
  },
  methods: {
    submit() {
      const isArchived = this.course.archived;
      this.$store
        .dispatch("course/courseToggleArchive", {
          organizationId: this.$route.params.organizationId,
          courseId: this.course.id,
          actualStateIsArchived: this.course.archived,
        })
        .then(() => {
          this.$emit("toggled");
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: !isArchived
              ? this.$t("course.archiveConfirmation")
              : this.$t("course.unarchiveConfirmation"),
            type: "SUCCESS",
          });
          this.$store.commit("course/setDisplayActiveCourses", true);
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.$emit("close");
    },
  },
};
</script>
