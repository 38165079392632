var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"value":_vm.dialogState,"modal-title":_vm.$t('campaign.forms.invite.title'),"is-unique":""},on:{"close":_vm.reset},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('v-combobox',{staticClass:"email_input elevation-0",attrs:{"items":_vm.suggestedSignatories,"filter":_vm.filterSignatory,"search-input":_vm.queryText,"chips":"","clearable":"","label":_vm.$t('folder.askInformationForm.email'),"multiple":"","outlined":"","dense":"","item-value":"email","error-messages":_vm.emailErrors},on:{"update:searchInput":function($event){_vm.queryText=$event},"update:search-input":function($event){_vm.queryText=$event},"input":_vm.testComboboxEmail,"change":function($event){return _vm.handleEmailsChange()},"blur":function($event){return _vm.$v.form.emails.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mail-chips",attrs:{"input-value":selected,"close":"","color":_vm.isError(item) ? 'red' : '',"text-color":_vm.isError(item) ? 'white' : ''},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(" "+_vm._s(item))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"signatory_item"},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":item.icon}}),_c('div',{staticClass:"email",domProps:{"textContent":_vm._s(item.email)}}),(item.lastName || item.firstname)?_c('div',{staticClass:"separator",domProps:{"textContent":_vm._s('-')}}):_vm._e(),(item.lastName)?_c('div',{staticClass:"name",domProps:{"textContent":_vm._s(item.lastName)}}):_vm._e(),(item.firstName)?_c('div',{staticClass:"firstname",domProps:{"textContent":_vm._s(item.firstName)}}):_vm._e()],1)]}}]),model:{value:(_vm.form.emails),callback:function ($$v) {_vm.$set(_vm.form, "emails", $$v)},expression:"form.emails"}}),_c('BaseMarkdownEditor',{staticClass:"editor-content",attrs:{"label":((_vm.$t('utils.mailContent')) + "*"),"with-link":false},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('v-switch',{attrs:{"label":_vm.$t('campaign.forms.invite.createFolder'),"inset":""},model:{value:(_vm.form.createFolder),callback:function ($$v) {_vm.$set(_vm.form, "createFolder", $$v)},expression:"form.createFolder"}})]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"disabled":_vm.form.emails.length < 1,"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }