import CosmoUser from "./CosmoUser";
import Organization from "./Organization";
import { COLLABORATOR_ROLE } from "@/core/Utils/types.utils";

export class UserRole {
  constructor({ organization = NaN, role = "" }) {
    this.organization = organization;
    this.role = role;
  }
}
export default class User extends CosmoUser {
  constructor({
    id = Number.NaN,
    email = "",
    firstname = "",
    lastname = "",
    avatar = "",
    isStaff = false,
    activeOrganization = null,
    phoneNumber = "",
    roles = [],
  } = {}) {
    super({ id, email, firstname, lastname });
    this.avatar = avatar;
    this.isStaff = isStaff;
    this.phoneNumber = phoneNumber;
    this.activeOrganization = activeOrganization
      ? new Organization(activeOrganization)
      : null;
    this.roles = roles?.length
      ? roles.map((r) => new UserRole({ ...r, role: r.function }))
      : [];
  }

  isManager(organizationId) {
    return (
      this.roles.find((r) => r.organization == organizationId)?.role ==
      COLLABORATOR_ROLE.MANAGER
    );
  }
}
