<template>
  <div>
    <!-- USER LOADED - Information display board -->
    <Board v-if="user" class="mb-6">
      <template #main>
        <div class="d-flex">
          <BaseAvatar
            :size="54"
            class="mr-6 flex-shrink-0 align-self-center"
            :src="user.avatar ? user.avatar : null"
          />
          <div class="mr-6 align-self-center">
            <BaseTitle v-text="user.fullName" />
            <BaseParagraph v-if="user.email" v-text="user.email" />
          </div>

          <BaseButton
            class="align-self-center"
            type="primary"
            @click="toggleAvatarDialog(true)"
          >
            {{ $t("client.account.editAvatar") }}
          </BaseButton>
        </div>
      </template>
    </Board>
    <!-- LOADING USER -->
    <Board v-else class="mb-6">
      <template #main>
        <BaseSkeletonLoader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
        />
      </template>
    </Board>
    <!-- USER Invitation List -->
    <DashboardMyInvitations />
    <!-- ACTIONS LIST -->
    <Board v-if="user" class="mb-6">
      <template #main>
        <BaseList nav>
          <!-- EDIT PASSWORD LINK -->
          <BaseListItem class="my-2" :to="{ name: 'SignatoryList' }">
            <BaseListItemContent>
              <BaseListItemTitle>
                <BaseParagraph
                  color="primary"
                  type="primary"
                  class="font-weight-bold"
                  v-text="$tc('utils.signatory', 2)"
                />
              </BaseListItemTitle>
            </BaseListItemContent>
          </BaseListItem>
          <BaseDivider grey />
          <BaseListItem class="my-2" :to="{ name: 'accountEditPassword' }">
            <BaseListItemContent>
              <BaseListItemTitle>
                <BaseParagraph
                  color="primary"
                  type="primary"
                  class="font-weight-bold"
                  v-text="$t('client.account.editPassword')"
                />
              </BaseListItemTitle>
            </BaseListItemContent>
          </BaseListItem>
          <BaseDivider grey />
          <BaseListItem
            color="secondary"
            class="my-2"
            :to="{ name: 'accountDelete' }"
          >
            <BaseListItemContent>
              <BaseListItemTitle>
                <BaseParagraph
                  color="secondary"
                  type="secondary"
                  class="font-weight-bold"
                  v-text="$t('client.account.delete')"
                />
              </BaseListItemTitle>
            </BaseListItemContent>
          </BaseListItem>
        </BaseList>
      </template>
    </Board>
    <div class="footer">
      <a
        class="footer_link"
        href="https://www.devngo.fr/"
        target="_blank"
        v-text="$t('client.account.myCoKnowMore')"
      />
      <a
        class="footer_link"
        href="https://www.devngo.fr/conditions-generales-dutilisation/"
        target="_blank"
        v-text="$t('client.account.cgu')"
      />
    </div>
    <!-- EDIT AVATAR IMAGE DIALOG -->
    <BaseModalFileUpload
      :title="avatarTitle"
      :is-open="AvatarDialogState"
      :file="avatar"
      :validate-btn-text="validateText"
      :place-holder="$t('client.account.avatarFieldLabel')"
      @change="changeAvatar"
      @validate="updateAvatar"
      @close="toggleAvatarDialog(false)"
    />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import DashboardMyInvitations from "../Components/DashboardMyInvitations";

import { mapState } from "vuex";

export default {
  name: "Account",
  components: {
    Board,
    DashboardMyInvitations,
  },
  data() {
    return {
      AvatarDialogState: false,
      avatar: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    avatarTitle() {
      if (!this.user?.avatar) return this.$tc("client.account.importAvatar", 1);
      return this.$tc("client.account.importAvatar", 2);
    },
    validateText() {
      if (!this.user?.avatar) return this.$t("client.account.changeAvatar");
      return this.$t("utils.edit");
    },
  },
  watch: {
    user: {
      async handler() {
        if (!this.user?.avatar) return;
        await this.getAvatar();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleAvatarDialog(status) {
      this.AvatarDialogState = status;
    },
    async getAvatar() {
      const res = await fetch(this.user.avatar);
      const blob = await res.blob();
      const extension = blob.type.split("/");
      const file = new File([blob], `avatar.${extension[1]}`, {
        type: blob.type,
      });
      this.avatar = file;
    },
    changeAvatar(avatar) {
      this.avatar = avatar;
    },
    async updateAvatar() {
      try {
        await this.$store.dispatch("account/updateUserAvatar", {
          avatar: this.avatar,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.avatarChangeSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.avatarChangeError"),
          type: "ERROR",
        });
      }
      this.toggleAvatarDialog(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  .footer_link {
    margin-left: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2536cc;
  }
}
</style>
