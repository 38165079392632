<template>
  <BaseModal
    :value="dialogState"
    :modal-title="
      isCreateForm
        ? $t('workflow.targetStatus.createAndEditForm.createTitle')
        : $t('workflow.targetStatus.createAndEditForm.editTitle')
    "
    @click:outside="reset"
    @keydown.esc="reset"
    @close="reset"
  >
    <template #modal>
      <i18n
        class="text d-block my-2"
        path="workflow.targetStatus.createAndEditForm.sourceStatusLabel"
      >
        <template #statusName>
          <span
            class="blue-text"
            v-text="`${activeStatus && activeStatus.name}`"
          />
        </template>
      </i18n>
      <i18n
        v-if="!isCreateForm && activeTargetStatus.statusTo"
        class="text d-block my-2"
        path="workflow.targetStatus.createAndEditForm.targetStatusLabel"
      >
        <template #statusName>
          <span
            class="blue-text"
            v-text="`${activeTargetStatus.statusTo.name}`"
          />
        </template>
      </i18n>
      <v-form ref="form" @submit.prevent="submit()">
        <BaseSelect
          v-if="isCreateForm"
          v-model="form.targetStatus"
          solo
          outlined
          :items="allowedTargetStatuses"
          :placeholder="
            $t('workflow.targetStatus.createAndEditForm.targetStatus') + ' *'
          "
          item-text="name"
          item-value="id"
          :error-messages="targetStatusErrors"
          @change="$v.form.targetStatus.$touch()"
          @blur="$v.form.targetStatus.$touch()"
        />

        <BaseTextField
          v-model="form.message"
          :placeholder="
            $t('workflow.targetStatus.createAndEditForm.label') + ' *'
          "
          :error-messages="messageErrors"
          :has-error="messageErrors !== ''"
          @changed="$v.form.message.$touch()"
          @blur="$v.form.message.$touch()"
        />
      </v-form>
    </template>

    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" :disabled="$v.$invalid" @click="submit">
        {{ $t("folder.statusForm.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";

import { required } from "vuelidate/lib/validators";

export default {
  name: "WorkflowStatusTargetStatusCreateAndEditDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        targetStatus: null,
        message: null,
      },
    };
  },
  validations() {
    if (this.isCreateForm) {
      return {
        form: {
          targetStatus: {
            required,
          },
          message: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          message: {
            required,
          },
        },
      };
    }
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
      activeStatus: (state) => state.workflow.activeStatus,
      activeTargetStatus: (state) => state.workflow.activeTargetStatus,
      dialogState: (state) =>
        state.workflow.modals.isCreateAndEditStatusTargetStatusDialogOpen,
    }),
    allowedTargetStatuses() {
      return this.activeWorkflow?.statuses.filter(
        (s) =>
          (s.id !== this.activeStatus?.id &&
            !this.activeStatus?.targetStatuses?.some(
              (st) => st.statusTo.id == s.id
            )) ||
          (!this.isCreateForm && this.activeTargetStatus.statusTo.id == s.id)
      );
    },
    isCreateForm() {
      return !this.activeTargetStatus;
    },
    //#region Form Validation Errors
    targetStatusErrors() {
      if (!this.$v.form.targetStatus.$dirty) {
        return "";
      }
      if (!this.$v.form.targetStatus.required) {
        return this.$t("utils.mandatory");
      }
      return "";
    },
    messageErrors() {
      if (!this.$v.form.message.$dirty) {
        return "";
      }
      if (!this.$v.form.message.required) {
        return this.$t("utils.mandatory");
      }
      return "";
    },
    //#endregion
  },
  watch: {
    dialogState(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },
  methods: {
    reset() {
      this.form = {
        targetStatus: null,
        message: null,
      };
      this.$v.$reset();
      this.$store.commit("workflow/setActiveStatus", null);
      this.$store.commit("workflow/setActiveTargetStatus", null);
      this.$store.commit(
        "workflow/setStatusTargetStatusCreateAndEditDialog",
        false
      );
    },
    initialize() {
      if (!this.isCreateForm) {
        this.form.targetStatus = this.activeTargetStatus.statusTo.id;
        this.form.message = this.activeTargetStatus.label;
      }
    },
    submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        if (this.isCreateForm) {
          this.create();
        } else {
          this.edit();
        }
        this.reset();
      }
    },
    create() {
      const payload = {
        label: this.form.message,
        status_to: this.form.targetStatus,
      };
      this.$store
        .dispatch("workflow/postTargetStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          payload,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t(
              "workflow.targetStatus.createAndEditForm.successCreate"
            ),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },
    edit() {
      const payload = {
        label: this.form.message,
      };
      this.$store
        .dispatch("workflow/updateTargetStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          targetStatusId: this.activeTargetStatus.id,
          payload,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t(
              "workflow.targetStatus.createAndEditForm.successEdit"
            ),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },
  },
};
</script>
<style scoped>
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.blue-text {
  color: #2536cc;
}
</style>
