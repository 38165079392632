import ClientFullDataView from "../Pages/ClientFullDataView.vue";

export default [
  {
    path: "/organization/:organizationID/folder/:folderID/client-full-data",
    name: "clientFullData",
    component: ClientFullDataView,
    props: true,
    meta: {
      authenticationRequired: true,
    },
  },
];
