<template>
  <div class="answer_container">
    <div class="answer_item" @click="$emit('editQuestion', question)">
      <div class="c-record-answer-lock">
        <div
          class="c-record-answer-lock-image d-flex justify-center align-center"
        >
          <BaseIcon color="white" icon="$mdiPencilOutline" />
        </div>
      </div>
      <div class="d-flex align-center">
        <WalletQuestionAnswerContent
          :datatype-suggestion="datatypeSuggestion"
          :question="question"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WalletQuestionAnswerContent from "../Components/WalletQuestionAnswerContent";

import Question from "@/core/Models/Question";

export default {
  name: "WalletQuestionAnswer",
  components: {
    WalletQuestionAnswerContent,
  },
  props: {
    question: {
      type: [Question, Object],
      default: new Question(),
    },
    datatypeSuggestion: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.answer_container {
  display: flex;
  justify-content: flex-end;
}
.answer_item {
  display: flex;
  position: relative;
  overflow: auto;
  min-height: 70px;
  max-width: 400px;
  min-width: 128px;
  border: 1px solid #3c3b8e;
  border-radius: 30px 30px 0px 30px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Nunito-SemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #4f4f4f;
  padding-left: 64px;
  cursor: pointer;
  .c-record-answer-lock {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
  }
  .c-record-answer-lock-image {
    width: 64px;
    height: 64px;
    border-top-right-radius: 60px;
    background: linear-gradient(
        172.39deg,
        #2f479d 19.48%,
        rgba(47, 71, 157, 0) 144.95%
      ),
      #ff5b60;
  }
}
</style>
