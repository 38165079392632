<template>
  <div
    class="offer_item_container"
    :class="{
      container_secondary: isPending || isRefused,
    }"
  >
    <!-- HEADER -->
    <FolderOfferItemHeader
      :offer="offer"
      :offer-clients="offerClients"
      :is-pending="isPending"
      :is-refused="isRefused"
      :is-canceled="isOfferCanceled"
      :expanded="expanded"
      :is-spam="isSpam"
      @expandChange="changeExpandStatus()"
    />

    <!-- BODY -->
    <template v-if="expanded">
      <div :class="getHeaderClass">
        <div class="separator" />
        <div
          v-if="
            (offer.offerType === OFFER_TYPES.SIGNATURE &&
              offer.status == 'PENDING') ||
            (offer.offerType === OFFER_TYPES.CONSULT && !isOfferCanceled)
          "
          class="btn-cancel"
        >
          <BaseButton
            color="secondary"
            class="ml-auto"
            icon="$mdiCloseCircleOutline"
            @click="
              offer.offerType === OFFER_TYPES.CONSULT
                ? (offerConsultCancelDialogState = true)
                : openCancelDialog(offer)
            "
          >
            Annuler l’offre en cours
          </BaseButton>
        </div>
        <div class="item_content">
          <div class="offer_details">
            <!-- CONTACT -->
            <div v-if="offerClients.length" class="offer_detail_item">
              <div
                class="title"
                v-text="$tc('utils.contact', offerClients.length)"
              />
              <div class="content">
                <div
                  v-if="offer.procedure && offer.procedure.sequentialSignatures"
                  class="simple_alert"
                  v-text="$t('folder.signatureOffer')"
                />
                <BaseDataTable
                  :headers="OFFER_CLIENT_HEADER"
                  :items="offerClients"
                  hide-default-footer
                  class="contact_list"
                >
                  <template #item="{ item }">
                    <tr class="contact_item">
                      <td>
                        <v-tooltip v-if="item.lastMailStatus" bottom>
                          <template #activator="{ on, attrs }">
                            <div v-bind="attrs" class="email_status" v-on="on">
                              <BaseIcon
                                class="email_status_icon"
                                :class="{
                                  danger:
                                    item.lastMailStatus.status == 'ERROR' ||
                                    item.lastMailStatus.status == 'SPAM',
                                }"
                                :icon="item.lastMailStatus.icon"
                              />
                            </div>
                          </template>
                          <span v-text="$t(item.lastMailStatus.label)" />
                        </v-tooltip>
                        <div v-else class="text-center" v-text="' - '" />
                      </td>
                      <td>
                        <div class="d-flex align-center">
                          <div
                            v-if="
                              offer.procedure &&
                              offer.procedure.sequentialSignatures
                            "
                            class="index"
                            :class="item.chipsClass"
                            v-text="item.order"
                          />
                          <BaseParagraph v-text="item.email" />
                        </div>
                      </td>
                      <td>
                        <BaseParagraph
                          v-text="item.fullName ? item.fullName : ' - '"
                        />
                      </td>
                      <td>
                        <BaseParagraph
                          v-text="item.phoneNumber ? item.phoneNumber : ' - '"
                        />
                      </td>
                      <td>
                        <div
                          class="chips"
                          :class="item.chipsClass"
                          v-text="item.statusLabel"
                        />
                      </td>
                      <td class="text-center">
                        <BaseParagraph
                          v-text="
                            item.reminders.length - 1 > 0
                              ? item.reminders.length - 1
                              : 0
                          "
                        />
                      </td>
                      <td>
                        <div
                          v-if="
                            (!isOfferCanceled && item.status == 'PENDING') ||
                            (offer.publishDate && isPublished)
                          "
                        >
                          <button
                            class="relaunch_btn"
                            :class="{ relaunched: item.reminders.length > 1 }"
                            @click="openRemindDialog(item)"
                          >
                            <BaseIcon class="icon" icon="$mdiEmailOutline" />
                            <span
                              v-text="
                                item.reminders.length > 1
                                  ? $t('utils.relaunchAgain')
                                  : $t('utils.relaunch')
                              "
                            />
                          </button>
                        </div>
                        <div v-else v-text="' - '" />
                      </td>
                    </tr>
                  </template>
                </BaseDataTable>
              </div>
            </div>
            <!-- Document -->
            <div v-if="offer.files.length" class="offer_detail_item">
              <div
                class="title"
                v-text="$tc('utils.document', offer.files.length)"
              />
              <div class="content">
                <div class="documents_card">
                  <div class="offer_files">
                    <!-- ITEM -->
                    <template
                      v-if="
                        offer.offerType !== OFFER_TYPES.SIGNATURE ||
                        (offer && offer.status !== 'ACCEPT')
                      "
                    >
                      <div
                        v-for="(file, i) in offer.files"
                        :key="`f-${i}`"
                        class="offer_file_item"
                        @click.stop.prevent="open(file.offerFile)"
                      >
                        <div class="file_name" v-text="file.name" />
                        <div class="file">
                          <BasePdfViewer
                            v-if="file.offerFile.includes('.pdf')"
                            :pdf="file.offerFile"
                            :page="1"
                          />
                          <base-icon
                            v-else-if="
                              ['.docx', '.doc', '.pptx'].some((v) =>
                                file.offerFile.includes(v)
                              )
                            "
                            icon="$mdiFileDocument"
                            color="primary"
                          />
                          <BaseImage v-else :src="file.offerFile" />
                        </div>
                        <v-btn
                          class="down_btn"
                          icon
                          @click.stop.prevent="
                            downloadFile(file.offerFile, file.name)
                          "
                        >
                          <BaseIcon icon="$mdiDownload" />
                        </v-btn>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        v-for="(file, i) in offer.signedFiles"
                        :key="`sf-${i}`"
                        class="offer_file_item"
                        @click.stop.prevent="open(file.signedPdf)"
                      >
                        <div class="file_name" v-text="file.name" />
                        <div class="file">
                          <BasePdfViewer :pdf="file.signedPdf" :page="1" />
                        </div>
                        <v-btn
                          class="down_btn"
                          icon
                          @click.stop.prevent="
                            downloadFile(file.signedPdf, file.name)
                          "
                        >
                          <BaseIcon icon="$mdiDownload" />
                        </v-btn>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- Document -->
            <div
              v-if="
                offer.offerType === OFFER_TYPES.SIGNATURE &&
                offerClients.some((c) => c.member && c.member.proof)
              "
              class="offer_detail_item"
            >
              <div class="title" v-text="$t('folder.proofOfSignature')" />
              <div class="content">
                <div class="documents_card">
                  <div v-if="offerClients.length" class="offer_files">
                    <!-- ITEM -->
                    <template v-for="(client, i) in offerClients">
                      <div
                        v-if="client.member.proof"
                        :key="`cf-${i}`"
                        class="offer_file_item"
                        @click.stop.prevent="open(client.member.proof)"
                      >
                        <div class="file_name" v-text="client.fullName" />
                        <div class="file">
                          <BasePdfViewer :pdf="client.member.proof" :page="1" />
                        </div>
                        <v-btn
                          class="down_btn"
                          icon
                          @click.stop.prevent="
                            downloadFile(client.member.proof, client.fullName)
                          "
                        >
                          <BaseIcon icon="$mdiDownload" />
                        </v-btn>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- Description section -->
            <div v-if="offer.description" class="offer_detail_item">
              <div class="title" v-text="$t('utils.emailContent')" />
              <div class="content">
                <div class="desc_content">
                  <BaseMarkdownViewer
                    :content="offer.description"
                    is-text-caption
                  />
                </div>
              </div>
            </div>
            <div class="offer_detail_item">
              <template>
                <div v-if="isRefused" class="content">
                  <div
                    class="title title__primary"
                    v-text="$t('utils.refusedMotif')"
                  />
                  <div class="refus__container">
                    <div
                      v-for="client in offer.clients.filter(
                        (client) => client.status === 'DECLINE'
                      )"
                      :key="client.id"
                      class="desc_content"
                    >
                      <span v-text="`${$t('utils.by')} `" />
                      <span class="email">{{ client.email }}</span>
                      <span v-text="' |'" />
                      <span> {{ getDate(client.dateCreated) }}</span>
                      <p>{{ client.reasonDecline }}</p>
                    </div>
                  </div>
                </div>
                <div v-else-if="isOfferCanceled" class="content">
                  <div
                    class="title title__primary"
                    v-text="$t('utils.cancelReason')"
                  />
                  <div class="refus__container">
                    <div class="desc_content">
                      <span v-text="`${$t('utils.by')} `" />
                      <span class="email">{{
                        offer.cancellation.author.email
                      }}</span>
                      <span v-text="' |'" />
                      <span> {{ getDate(offer.cancellation.date) }} </span>
                      <p>{{ offer.cancellation.reason }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <FolderOfferRemindDialog
      :offer="offer"
      :offer-client="selectedOfferClient"
      :dialog-state="remindDialogState"
      :organization-id="organizationId"
      :folder-id="folderId"
      @close="remindDialogState = false"
    />
    <FolderOfferCancelDialog
      v-if="selectedOfferClient && selectedOfferClient.procedure"
      :dialog-state="offerCancelDialogState"
      :organization-id="organizationId"
      :procedure="selectedOfferClient.procedure"
      @close="offerCancelDialogState = false"
    />
    <FolderOfferConsultCancelDialog
      v-if="offerConsultCancelDialogState"
      :dialog-state="offerConsultCancelDialogState"
      :organization-id="organizationId"
      :folder-id="folderId"
      :offer-id="offer.id"
      @close="offerConsultCancelDialogState = false"
    />
  </div>
</template>

<script>
import FolderOfferItemHeader from "./FolderOfferItemHeader";
import FolderOfferRemindDialog from "./FolderOfferRemindDialog";
import FolderOfferCancelDialog from "./FolderOfferCancelDialog";
import FolderOfferConsultCancelDialog from "./FolderOfferConsultCancelDialog.vue";
import * as Sentry from "@sentry/vue";

import { saveAs } from "file-saver";

import { mapGetters } from "vuex";
import { OFFER_TYPES } from "@/core/Utils/types.utils";

export default {
  name: "FolderOfferItem",
  components: {
    FolderOfferItemHeader,
    FolderOfferRemindDialog,
    FolderOfferCancelDialog,
    FolderOfferConsultCancelDialog,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      remindDialogState: false,
      offerCancelDialogState: false,
      offerConsultCancelDialogState: false,
      selectedOfferClient: null,
      expanded: false,
      fileLoading: false,
      OFFER_TYPES: OFFER_TYPES,
      OFFER_CLIENT_HEADER: [
        {
          text: this.$tc("utils.emailStatus", 1),
          value: "emailStatus",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.email", 1),
          value: "email",
        },
        {
          text: this.$tc("utils.contact", 2),
          value: "fullName",
        },
        {
          text: this.$tc("utils.phoneNumber", 2),
          value: "phoneNumber",
        },
        {
          text: this.$t("utils.state"),
          value: "state",
          sortable: false,
        },
        {
          text: this.$t("utils.remindNumber"),
          value: "remindNumber",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.reminder", 2),
          value: "reminder",
          sortable: false,
        },
      ],
      signatories: [],
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    offerClients() {
      let offerClients = [];
      switch (this.offer.offerType) {
        case OFFER_TYPES.CONSULT:
        case OFFER_TYPES.VALIDATION:
          offerClients = this.offer.clients.map((offerClient, index) => {
            return {
              ...offerClient,
              fullName: offerClient?.client?.fullName,
              email: offerClient?.client?.email || offerClient.email,
              phoneNumber: offerClient?.client?.phoneNumber,
              statusLabel: this.getOfferClientStatusLabel(
                offerClient.action,
                offerClient.status,
                index
              ),
              chipsClass: this.getOfferClientStatusChipsClass(
                offerClient.status
              ),
              lastMailStatus: offerClient.getLastMailStatus(),
            };
          });
          break;
        case OFFER_TYPES.MIXTE:
        case OFFER_TYPES.SIGNATURE:
          offerClients = [...this.offer.clients]
            .sort((a, b) => a?.member?.order - b?.member?.order)
            .map((offerClient, index) => {
              return {
                ...offerClient,
                fullName: offerClient?.member?.fullName,
                email: offerClient?.member?.email || offerClient.email,
                phoneNumber: offerClient?.member?.phoneNumber,
                order: offerClient?.member?.order || offerClient.order,
                statusLabel: this.getOfferClientStatusLabel(
                  offerClient.action,
                  offerClient.status,
                  index
                ),
                chipsClass: this.getOfferClientStatusChipsClass(
                  offerClient.status,
                  index
                ),
                lastMailStatus: offerClient.getLastMailStatus(),
              };
            });
          break;
      }

      return offerClients;
    },

    isSpam() {
      const status = [];
      this.offerClients.lenght > 0 &&
        this.offerClients.forEach((item) => {
          if (!item.reminders?.length) return null;
          else status.push(item.reminders[0].transactionalEmail.status);
        });

      return status.includes("SPAM");
    },
    getHeaderClass() {
      if (this.isRefused) return "item_body refused";
      if (this.isSpam) return "item_body is_spam";
      if (this.offer.status === "CANCELED" || this.isOfferCanceled)
        return "item_body is_canceled";

      return "item_body";
    },
    isPublished() {
      if (!this.offer.publishDate) return false;
      return this.offer.publishDate.getTime() < new Date().getTime();
    },
    isPending() {
      switch (this.offer.offerType) {
        case OFFER_TYPES.CONSULT:
          return this.offer.clients.every(
            (client) => client.status === "PENDING"
          );
        case OFFER_TYPES.VALIDATION:
          return this.offer.clients.every(
            (client) => client?.status === "PENDING"
          );

        case OFFER_TYPES.SIGNATURE:
          return this.offer.status === "PENDING" || this.offer.status == null;
        case OFFER_TYPES.MIXTE:
          return this.offer.clients.some(
            (client) => client.status === "PENDING"
          );
      }
      return true;
    },
    isOfferCanceled() {
      return (
        this.offer.offerType == OFFER_TYPES.CONSULT && !!this.offer.cancellation
      );
    },
    isRefused() {
      switch (this.offer.offerType) {
        case OFFER_TYPES.CONSULT:
          return this.offer.clients.some(
            (client) => client.status === "DECLINE"
          );
        case OFFER_TYPES.VALIDATION:
          return this.offer.clients.some(
            (client) => client?.status === "DECLINE"
          );

        case OFFER_TYPES.SIGNATURE:
          return this.offer.status === "DECLINE" || this.offer.status == null;
        case OFFER_TYPES.MIXTE:
          return this.offer.clients.some(
            (client) => client.status === "DECLINE"
          );
      }
      return true;
    },
  },
  methods: {
    async changeExpandStatus() {
      this.expanded = !this.expanded;
      if (
        this.offer.offerType == OFFER_TYPES.SIGNATURE &&
        this.expanded &&
        !this.fileLoading
      ) {
        await this.$store.dispatch("folder/fetchOfferMemberAndSignedFiles", {
          organizationId: this.organizationId,
          procedureId: this.offer?.procedure?.id,
          offerId: this.offer.id,
        });
      }
      if (this.expanded && !this.fileLoading) {
        await this.$store.dispatch("folder/fetchOfferFileList", {
          organizationId: this.organizationId,
          folderId: this.folderId,
          offerId: this.offer?.id,
        });
        this.fileLoading = true;
      }
    },
    getOfferClientStatusLabel(action, status, index) {
      switch (action) {
        case OFFER_TYPES.VALIDATION:
          if (status === "DECLINE") {
            return this.$tc("folder.offerStatus.offerDeclined", 3);
          }
          if (status === "ACCEPT") {
            return this.$tc("folder.offerStatus.offerValidated", 3);
          }
          return this.$tc("folder.offerStatus.waitingForValidation");
        case OFFER_TYPES.SIGNATURE:
          if (status === "CANCELED") {
            return this.$t("folder.offerStatus.offerCanceled");
          }
          if (status === "DECLINE") {
            return this.$t("folder.offerStatus.signatureRefused");
          }

          if (status === "PENDING") {
            if (index === 0 || !this.offer?.procedure?.sequentialSignatures)
              return this.$t("folder.offerStatus.waitingForSignature");
            if (this.offer.clients[index - 1].status === "PENDING")
              return `${this.$t(
                "folder.offerStatus.waitingSignatureSequential"
              )} ${index}`;
            else return this.$t("folder.offerStatus.waitingForSignature");
          }
          return this.$t("folder.offerStatus.OfferSigned");
        case OFFER_TYPES.CONSULT:
          if (status === "PENDING") {
            return this.$t("folder.offerStatus.notConsulted");
          }
          return this.$tc("folder.offerStatus.offerConsulted", 0);
      }
    },
    getOfferClientStatusChipsClass(status, index) {
      switch (this.offer.offerType) {
        case OFFER_TYPES.VALIDATION:
          if (status === "DECLINE") {
            return "chips_danger";
          }
          if (status === "ACCEPT") {
            return "chips_success";
          }
          return "";
        case OFFER_TYPES.CONSULT:
          if (this.isOfferCanceled) return "chips_canceled";
          if (status === "PENDING") {
            return "";
          }
          return "chips_success";
        case OFFER_TYPES.SIGNATURE:
          if (status === "DECLINE") {
            return "chips_danger";
          }
          if (status === "CANCELED") {
            return "chips_canceled";
          }
          if (status === "PENDING") {
            if (index === 0 && this.offer?.procedure?.sequentialSignatures)
              return "chips_active";
            if (
              this.offer.clients[index - 1]?.status === "PENDING" &&
              this.offer?.procedure?.sequentialSignatures
            )
              return "chips_simple";
            else return "chips_active";
          }
          return "chips_success";
      }
    },
    open(link) {
      open(`${link}`);
    },
    downloadFile(fileUrl, name = "download") {
      try {
        saveAs(fileUrl, name);
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setExtra("fileUrl", fileUrl);
          const offer = { ...this.offer };
          scope.setExtra("offer", JSON.stringify(offer));
          Sentry.captureException(error);
        });
      }
    },
    openRemindDialog(offerClient) {
      this.selectedOfferClient = offerClient;
      this.remindDialogState = true;
    },
    openCancelDialog(offerClient) {
      this.selectedOfferClient = offerClient;
      this.offerCancelDialogState = true;
    },
    getStatusAction(action) {
      if (action == OFFER_TYPES.SIGNATURE)
        return this.$t("folder.offerStatus.waitingForSignature");
      if (action == OFFER_TYPES.VALIDATION)
        return this.$tc("folder.offerStatus.waitingForValidation");
      if (action == OFFER_TYPES.CONSULT)
        return this.$t("folder.offerStatus.waitingForConsultation");
    },
    getDate(date) {
      const dateRes = new Date(date).toLocaleDateString("fr", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const time = new Date(date).toLocaleTimeString("fr");
      return `${dateRes} ${time}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer_item_container {
  background: #ffffff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;
  &.container_secondary {
    background: #f5f5f5;
  }
  .item_body {
    border-left: 32px solid #bda0ed;
    position: relative;
    padding-bottom: 34px;
    &.is_spam {
      border-left: 32px solid #ffc084;
    }

    &.is_canceled {
      border-left: 32px solid #eeeef7;
    }
    &.refused {
      border-left: 32px solid #ff5267;
    }

    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .btn-cancel {
      display: flex;
      align-items: center;
      padding: 8px 30px 0 0;
    }
    .item_content {
      padding: 24px;

      .offer_details {
        padding: 16px 24px;
        .offer_detail_item {
          .title {
            color: var(--Principale-Gris-Noir, #353542);
            /* Composant/En-tête tableau */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
          }
          .simple_alert {
            padding: 14px;
            margin-bottom: 16px;
            background: #fafafc;
            border-radius: 5px;
            border: 0.5px solid #cfcfdc;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #353542;
          }
          .content {
            padding: 16px;
          }
          .contact_item {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #353542;
            .index {
              padding: 4px 8px;
              width: 22px;
              height: 23px;
              background: rgba(155, 189, 255, 0.22);
              border-radius: 5px;
              margin-right: 4px;
              &.chips_simple {
                background: none;
              }
            }
            & > * {
              margin-right: 35px;
            }
            &:last-child {
              margin: 0;
            }
            .chips {
              background: #eeeef7;
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #707080;
              padding: 4px 8px;
              border-radius: 24px;
              &.chips_success {
                background: #f0ffd6;
                color: #18a300;
              }
              &.chips_danger {
                background: #fef9fa;
                color: #ff5267;
              }
              &.chips_canceled {
                background: #eeeef7;
                color: #707080;
              }
              &.chips_active {
                background: #e9f1ff;
              }
              &.chips_simple {
                background: #eeeef7;
              }
            }
          }
          .title__primary {
            margin-left: -16px;
          }
          .desc_content {
            background: #ffffff;
            padding: 24px;
            border-radius: 5px;
            color: var(--Principale-Gris-Noir, #353542);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            .email {
              font-weight: 700;
              text-transform: lowercase;
            }
          }
          .documents_card {
            background: #fff;
            border-radius: 5px;
            padding: 12px;
            border: 0.5px solid #cfcfdc;
            .offer_files {
              padding: 12px;
              display: grid;
              width: 100%;
              grid-template-columns: 1fr;
              grid-gap: 16px;
              grid-template-rows: auto;
              max-height: 248px;
              overflow: auto;
              @media (min-width: 1066px) {
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                grid-template-columns: repeat(2, calc(50% - 8px));
              }
              @media (min-width: 1400px) {
                grid-column-gap: calc(4% / 2);
                grid-row-gap: 16px;
                grid-template-columns: repeat(3, 32%);
              }
              @media (min-width: 1600px) {
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                grid-template-columns: repeat(4, calc(25% - 16px));
              }
              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 3px;
                border: 1px solid #eeeef7;
              }

              &::-webkit-scrollbar-thumb {
                background: #b6bdff;
                border-radius: 3px;
              }
              .offer_file_item {
                width: 100%;
                cursor: pointer;
                position: relative;
                .file_name {
                  padding: 0 8px;
                  width: 100%;
                  font-size: 10px;
                  line-height: 24px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #333;
                }
                .file {
                  border: 1px solid #e5e5e5;
                  height: auto;
                  overflow: hidden;
                  width: 100%;
                  height: 200px;
                  & > * {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    margin: 0;
                  }
                }
                .down_btn {
                  background: #f4f7ff;
                  position: absolute;
                  right: 12px;
                  bottom: 12px;
                  box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
                    0px 10px 10px rgba(46, 45, 193, 0.06);
                  &::v-deep {
                    .v-icon {
                      width: 20px;
                      height: 20px;
                      color: #2536cc !important;
                    }
                  }
                }
              }
            }
          }
        }
        .contact_list {
          margin-bottom: 16px;
          background: #fafafc;
          border-radius: 5px;
          border: 0.5px solid #cfcfdc;
          .email_status {
            text-align: center;
            .email_status_icon {
              cursor: pointer;
              padding: 0 4px;
              color: #cfcfdc;
              &::v-deep {
                .v-icon {
                  color: #cfcfdc;
                }
              }
              &.danger {
                &::v-deep {
                  .v-icon {
                    color: #ff5267;
                  }
                }
              }
            }
          }
          .chips {
            background: #eeeef7;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #707080;
            padding: 4px 8px;
            border-radius: 24px;
            display: inline-block;
            &.chips_success {
              background: #f0ffd6;
              color: #18a300;
            }
            &.chips_danger {
              background: #fef9fa;
              color: #ff5267;
            }
          }
          .relaunch_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            background: #f4f7ff;
            border-radius: 5px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #2536cc;
            & > .icon {
              &::v-deep {
                .v-icon {
                  width: 16px;
                  height: 16px;
                  color: #2536cc;
                }
              }
            }
            & > * {
              margin: 0 4px;
            }
            &.relaunched {
              color: #ff6b00;
              background: #fff3f0;
              & > .icon {
                &::v-deep {
                  .v-icon {
                    color: #ff6b00;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .cancel {
    position: absolute;
    right: 64px;
  }
  .refus__container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
}
</style>
