<template>
  <BaseModal
    :value="dialogState"
    :max-width="700"
    is-complex
    :modal-title="$t('editContact.confirmDialog.title')"
    @click:outside="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="d-flex flex-column justify-content-center">
        <span class="text" v-text="$t('editContact.confirmDialog.message')" />
        <span
          class="text danger"
          v-text="
            $t('editContact.confirmDialog.message2', {
              email: email,
            })
          "
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.back") }}
      </BaseButton>
      <BaseButton color="primary" type="primary" @click="$emit('validate')">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "FolderConfirmChangeContactDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    email: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 171.52%;
  text-align: center;
  &.danger {
    color: #ff5267;
  }
}
</style>
