<template>
  <div>
    <BaseFrontOfficeParagraph
      class="d-block mb-2"
      v-text="$t('auth.reset.complete.messageReinitialized')"
    />
    <BaseFrontOfficeParagraph
      class="d-block mb-2"
      v-text="$t('auth.reset.complete.messageReconnect')"
    />
  </div>
</template>

<script>
export default {
  name: "WalletPasswordResetComplete",
};
</script>
