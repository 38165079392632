<template>
  <div class="input-date d-flex justify-center align-center">
    <input v-model="date" type="date" class="input-class" />
  </div>
</template>

<script>
export default {
  name: "BaseDatePickers",
  data() {
    return {
      date: null,
    };
  },
  watch: {
    date: {
      handler() {
        this.$emit("update-date", this.date);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus-visible {
  outline: none !important;
}
.input-date {
  width: 150px;
  height: 40px;
  border: 1px solid #eeeef7;
  border-radius: 5px;
}
</style>
