<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #activator="{ on, attrs }">
        <div class="menu">
          <BaseTextField
            v-model="displayedDate"
            outlined
            append-icon="$mdiCalendarMonth"
            placeholder="JJ/MM/AAAA"
            prepend-icon=""
            clear-icon="$mdiClose"
            clearable
            :has-error="hasError || !dateIsValid"
            :error-messages="dateFieldError"
            v-bind="attrs"
            v-on="on"
            @change="editDateField()"
            @focusin="isFocused = true"
            @focusout="isFocused = false"
          />
          <span
            class="date-label"
            :class="{ focused: isFocused }"
            v-text="label"
          />
        </div>
      </template>
      <v-date-picker v-model="date" no-title scrollable locale="fr">
        <v-spacer />
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="menu = false"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="$refs.menu.save(date)">
          {{ $t("utils.validate") }}
        </BaseButton>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "BaseDateInput",

  props: {
    valueDate: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      menu: false,
      date: null,
      displayedDate: null,
      isFocused: false,
    };
  },
  computed: {
    dateIsValid() {
      if (!this.displayedDate) return true;
      return /^(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))$/.test(
        this.displayedDate
      );
    },
    dateFieldError() {
      if (this.hasError || !this.dateIsValid)
        return this.$t("utils.wrongFormat");
      return "";
    },
  },

  watch: {
    date() {
      this.$emit("update-value", this.date);
    },
    valueDate() {
      this.displayedDate = this.valueDate.split("-").reverse().join("/");
      this.date = this.valueDate;
    },
  },
  methods: {
    editDateField() {
      let newVal = this.displayedDate;
      if (!newVal) {
        this.date = null;
        return;
      }
      if (newVal.length > 10) {
        this.$nextTick(() => {
          this.displayedDate = newVal.substr(0, 10);
          newVal = this.displayedDate;
        });
      }
      this.date = this.dateIsValid
        ? newVal.split("/").reverse().join("-")
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot {
  padding: 0 0 0 12px !important;
  .v-input__append-inner {
    &:last-child {
      margin-top: 0px !important;
    }
    .v-input__icon--append {
      background: #f1f2f8;
      padding: 21px !important;
    }
  }
}
.menu {
  position: relative;
}
.date-label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 14px;
  color: #707080;
  padding: 0 3px;
  background: #fff;
}
.focused {
  color: #2536cc !important;
}
</style>
