<template>
  <div>
    <BaseFrontOfficeBoard
      id="cosmo-wallet-form-share-data"
      class="mt-6 elevation-5"
    >
      <template #header>
        <BaseFrontOfficeTitle v-text="$t('wallet.sharaData.title')" />
      </template>
      <template>
        <BaseFrontOfficeParagraph v-text="message" />
        <div class="d-flex flex-wrap justify-end">
          <BaseFrontOfficeButton
            type="primary"
            icon="$mdiLockOpenOutline"
            :disabled="activeState == STATE.NON_VALIDATION_OF_SHARING"
            :loading="activeState == STATE.VALIDATION_OF_SHARING"
            @click="submit(true)"
          >
            {{ $t("wallet.sharaData.validate") }}
          </BaseFrontOfficeButton>
        </div>
      </template>
    </BaseFrontOfficeBoard>
  </div>
</template>

<script>
import { desactivatePreviewMode } from "@/core/Utils/file.utils";
import RecordAPI from "../Services/record.api";
export default {
  name: "WalletCourseShareData",
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    STATE: {
      DEFAULT: "DEFAULT",
      VALIDATION_OF_SHARING: "VALIDATION_OF_SHARING",
      NON_VALIDATION_OF_SHARING: "NON_VALIDATION_OF_SHARING",
    },
    activeState: "DEFAULT",
  }),
  computed: {
    isFromPreview() {
      return !!localStorage.isPreview && JSON.parse(localStorage.isPreview);
    },
    organization() {
      if (!this.record?.campaign?.course) return;
      return this.record.campaign.course.organization;
    },
    message() {
      return this.$t("wallet.sharaData.message", {
        organizationName: this.organization.name || "",
      });
    },
  },
  mounted() {
    this.initialize();
    desactivatePreviewMode();
  },
  beforeDestroy() {
    localStorage.isPreview = false;
  },

  methods: {
    reset() {},
    initialize() {
      let container = this.$el.querySelector("#cosmo-wallet-form-share-data");
      setTimeout(() => {
        window.scrollTo(0, container.offsetTop);
      });
    },
    async submit(agreementStatus) {
      this.activeState = agreementStatus
        ? this.STATE.VALIDATION_OF_SHARING
        : this.STATE.NON_VALIDATION_OF_SHARING;
      const params = {
        recordId: this.record.id,
        agreementStatus,
      };
      if (this.$route.query.sc) {
        params["settings"] = { params: { sc: this.$route.query.sc } };
      }
      const response = await RecordAPI.postRecordThanks(params);
      if (response.status === 200) {
        this.$router.push({
          name: "courseDetail",
          params: {
            organizationId: this.record.campaign.course.organization.id,
            courseId: this.record.campaign.course.id,
          },
        });
        return;
      } else if (this.record.campaign.course.anonymous) {
        this.$router.push({
          name: "WalletThanks",
          params: {
            recordId: this.record.id,
          },
          query: {
            sc: this.$route.query.sc,
          },
        });
      } else {
        await this.$store.dispatch("walletOrganization/fetchOrganizations");
        this.$router.push({
          name: "WalletOrganizationRecords",
          params: {
            organizationId: this.record.campaign.course.organization.id,
          },
        });
      }
    },
  },
};
</script>
