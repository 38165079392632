<template>
  <div>
    <BaseFrontOfficeParagraph
      v-if="label"
      class="d-block mb-3"
      v-text="label"
    />
    <v-select outlined rounded dense v-bind="$attrs" v-on="$listeners">
      <slot />
    </v-select>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeSelect",
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot {
  min-width: 250px;
  border-radius: 25px;
  background: var(--cosmo-gradient-primary) !important;
}
::v-deep .v-input__slot fieldset {
  border-radius: 25px;
  margin: 1px 2px 2px 2px !important;
  border: hidden;
  background-color: var(--v-walletGrey-lighten5);
}
</style>
