<template>
  <div class="d-flex align-center">
    <v-file-input
      v-model="image"
      :label="label"
      :error-messages="imageErrors"
      :error-count="2"
      prepend-icon="$mdiUpload"
      :accept="types"
    />
    <div class="d-flex align-center">
      <a
        v-if="image && imageErrors.length === 0"
        :href="localImage ? localImage : image"
        target="blank"
        class="flex-grow-0 ml-4"
      >
        <BaseImage
          :src="localImage ? localImage : image"
          height="32px"
          width="72px"
          class="rounded-5"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFieldImage",
  props: {
    label: {
      type: String,
      default: "",
    },
    types: {
      type: String,
      default: "image/jpeg,image/png,image/jpg",
    },
    maxSize: {
      type: Number,
      default: 8000000, // 8 Mo
    },
    reset: {
      type: Boolean,
    },
  },
  data() {
    return {
      image: null,
      localImage: "",
      errorMessages: "",
    };
  },
  computed: {
    imageErrors() {
      let errors = [];
      if (this.image) {
        if (
          !(
            this.types.includes("image/jpg") && /.jpg$/.test(this.image.type)
          ) &&
          !(
            this.types.includes("image/jpeg") && /.jpeg$/.test(this.image.type)
          ) &&
          !(this.types.includes("image/png") && /.png$/.test(this.image.type))
        ) {
          let tempImageTexts = this.types.split(",");
          tempImageTexts = tempImageTexts.map((tempImageText) => {
            return tempImageText.replace("image/", "").toUpperCase();
          });
          tempImageTexts = tempImageTexts.join(", ");
          errors.push(
            this.$t("utils.pleaseProvideAnImageError", {
              types: tempImageTexts,
            })
          );
        }
      }
      if (this.image && this.image.size > this.maxSize) {
        errors.push(
          this.$t("utils.imageTooBig", { size: this.maxSize / 1000000 })
        );
      }
      return errors;
    },
  },
  watch: {
    image(newVal) {
      if (newVal && !this.imageErrors.length) {
        this.localImage = URL.createObjectURL(newVal);
        this.$emit("change", this.image);
      } else {
        this.localImage = "";
        this.$emit("change", null);
      }
    },
    reset() {
      this.image = null;
    },
  },
};
</script>
