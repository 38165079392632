<template>
  <div class="text--title text--primary">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseTitle",
};
</script>

<style scoped>
.text--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
</style>
