<template>
  <div v-if="question" :class="statusSheetClass">
    <div
      v-if="
        question.answer &&
        (question.answer.content ||
          !isNaN(parseInt(question.answer.content, 10)))
      "
      class="question_answer"
    >
      <div
        v-if="/^DATE$/.test(question.typeQuestion)"
        class="label"
        v-text="$d(new Date(question.answer.content), 'short')"
      />
      <div
        v-else-if="/^DATETIME$/.test(question.typeQuestion)"
        class="label"
        v-text="$d(new Date(question.answer.content), 'full')"
      />
      <template v-else-if="/MULTIPLE_FILE/.test(question.typeQuestion)">
        <div v-for="content in question.answer.content" :key="content.id">
          <span
            class="record_question_container"
            target="_blank"
            @click.stop="openMultiFile(content)"
            ><a class="label link">{{ content.label || content.name }}</a></span
          >
        </div>
      </template>
      <a
        v-else-if="/SIGNATURE/.test(question.typeQuestion)"
        class="label link"
        :href="question.answer.content[0] && question.answer.content[0].url"
        target="_blank"
        @click.stop="openFiles"
      >
        question
      </a>
      <a
        v-else-if="/FILE/.test(question.typeQuestion)"
        class="label"
        :href="question.answer.content[0] && question.answer.content[0].url"
        target="_blank"
        @click.stop
      >
        {{ $t("answer.seeFolder", { name: question.name }) }}
      </a>

      <div
        v-else-if="
          /DATAGRID|ROW_DATAGRID/.test(question.typeQuestion) &&
          Array.isArray(question.answer.content)
        "
        class="label"
      >
        {{ $t("answer.seeTable") }}
      </div>
      <div
        v-else-if="
          /TEXT|TEXTEARA|NUMBER|SELECT|RADIOBUTTON|CHECKBOX/.test(
            question.typeQuestion
          )
        "
        class="label"
        v-text="
          !!question.answer.freeOptionContent &&
          question.typeQuestion !== 'CHECKBOX'
            ? question.answer.freeOptionContent
            : question.answer.content
        "
      />
    </div>
    <BaseDescription v-else truncate italic v-text="$t('answer.noContent')" />
  </div>
</template>

<script>
export default {
  name: "RecordQuestionValidation",
  props: {
    question: {
      type: Object,
      default: null,
    },
    askModificationClicked: {
      type: Boolean,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isCheck: false,
    };
  },
  computed: {
    statusSheetClass() {
      const classList = [
        `${
          !/MULTIPLE_FILE/.test(this.question.typeQuestion)
            ? "record_question_container"
            : ""
        }`,
      ];
      switch (this.question.answer?.validationStatus) {
        case "UPDATED":
          classList.push("updated");
          break;
        case "UPDATE_REQUESTED":
          classList.push("updateRequested");
          break;
        case "VALIDATED":
          classList.push("validated");
          break;
      }
      if (this.askModificationClicked) classList.push("editable");
      return classList;
    },
  },
  methods: {
    openFiles() {
      this.question.answer.content.forEach((file) => {
        open(file.url);
      });
    },
    openMultiFile(file) {
      open(file.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.record_question_container {
  position: relative;
  padding: 8px 16px 8px 24px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  min-height: 42px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #cfcfdc;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff;
  }

  &.validated {
    border: 1px solid #18a300;
    &:hover {
      box-shadow: 0px 0px 12px 4px #cdfcc4;
    }
  }
  &.updated {
    border: 1px solid #ffab5a;
    &:hover {
      box-shadow: 0px 0px 12px 4px #f7dfc8;
    }
  }
  &.updateRequested {
    border: 1px solid #ff5267;
    &:hover {
      box-shadow: 0px 0px 12px 4px #f9d1d6;
    }
  }

  .question_answer {
    width: calc(100% - 56px);
    flex: none;
    font-size: 12px;
    line-height: 16px;
    word-break: break-all;
    color: #c4c4c4;
    &.link {
      color: #353542;
      text-decoration: underline;
    }
    .label {
      color: #353542;
      font-size: 16px;
    }
  }
  &.editable {
    .question_answer {
      &.link {
        color: #2536cc;
      }
      .label {
        color: #2536cc;
      }
    }
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
