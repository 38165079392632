<template>
  <svg
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Polygon 1"
      d="M12.5 23L0.808657 0.5L24.1913 0.5L12.5 23Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDown",
};
</script>
