<template>
  <v-chip
    class="rounded-pill"
    :color="chipProps.color"
    :text-color="chipProps.textColor"
    small
    label
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
</template>

<script>
export default {
  name: "BaseFrontOfficeChip",
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    chipProps() {
      switch (this.type) {
        case "neutral": // Neutral
          return {
            color: "walletGrey lighten-3",
            textColor: "walletGrey",
          };
        case "success": // Success
          return {
            color: "walletAlertSuccess lighten-5",
            textColor: "walletAlertSuccess darken-5",
          };
        case "error": // Error
          return {
            color: "walletAlertError lighten-5",
            textColor: "walletAlertError darken-5",
          };
        case "warning": // Warning
          return {
            color: "walletAlertWarning lighten-5",
            textColor: "walletAlertWarning darken-5",
          };
        case "info": // Info
          return {
            color: "walletAlertInfo lighten-5",
            textColor: "walletAlertInfo darken-5",
          };
        case "notification": // Notification
          return {
            color: "walletAlertError",
            textColor: "walletGrey lighten-5",
          };
        default:
          break;
      }
      return {};
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 600;
  font-size: 12px;
}
</style>
