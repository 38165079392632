<template>
  <BaseRow class="font-weight-bold py-2">
    <BaseCol v-if="isEditingAllowed" cols="1" class="text-center pa-2">
      <BaseDescription type="secondary" v-text="'Grouper'" />
    </BaseCol>
    <BaseCol :cols="isEditingAllowed ? 3 : 6" class="pa-2">
      <BaseDescription type="primary" v-text="$tc('course.question', 1)" />
    </BaseCol>
    <BaseCol :cols="isEditingAllowed ? 2 : 3" class="text-center pa-2">
      <BaseDescription type="primary" v-text="$tc('course.personalData', 1)" />
    </BaseCol>
    <BaseCol :cols="isEditingAllowed ? 2 : 3" class="text-center pa-2">
      <BaseDescription type="primary" v-text="$tc('utils.type', 1)" />
    </BaseCol>
    <BaseCol v-if="isEditingAllowed" cols="1" class="text-center pa-2">
      <BaseDescription
        type="primary"
        v-text="$tc('course.conditionalJump', 1)"
      />
    </BaseCol>
    <BaseCol v-if="isEditingAllowed" cols="1" class="d-flex justify-center pa-2 ml-1">
      <BaseDescription type="primary" v-text="'Aides'" />
    </BaseCol>
    <BaseCol v-if="isEditingAllowed" cols="3" class="text-center pa-2">
      <BaseDescription type="primary" v-text="$tc('utils.action', 10)" />
    </BaseCol>
  </BaseRow>
</template>

<script>
export default {
  name: "CourseDetailStepTableHeader",
  props: {
    isEditingAllowed: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.col-3 {
  max-width: 20% !important;
}
</style>
