import Vue from "vue";
import Vuetify from "vuetify/lib";

import { light } from "./themes";
import { Locale } from "./locale";

import iconsValues from "./icons/index";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
    values: {
      ...iconsValues,
    },
  },
  lang: {
    t: (key, ...params) => Locale.t(key, params),
  },
  theme: {
    themes: {
      light: light,
    },

    options: {
      customProperties: true,
    },
  },
});
