<!-- eslint-disable vue/no-v-html -->
<template>
  <BaseDialog
    v-if="isOpen"
    width="unset"
    :value="isOpen"
    @keydown.esc="reset()"
    @click:outside="reset()"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <h2 class="my-2" v-text="$t('folder.logs.history')" />
        </template>
        <template>
          <BaseDataTable
            :headers="headers"
            class="elevation-1 ma-6"
            :items="allRecordsLogs"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <div class="d-flex flex-column align-start">
                    <BaseParagraph
                      text="secondary"
                      class="ml-auto mr-auto d-block mb-1"
                      v-text="
                        `${$d(new Date(item.dateCreated), 'veryshort')} ${$d(
                          new Date(item.dateCreated),
                          'time'
                        )}`
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column align-start py-5">
                    <BaseParagraph
                      text="secondary"
                      class="d-block mb-1 mx-auto"
                      v-html="item.actionDisplay"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </BaseDataTable>
        </template>
        <template #actions>
          <BaseSpacer />
          <BaseButton type="secondary" @click="reset()">
            {{ $t("utils.cancel") }}
          </BaseButton>
        </template>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  name: "RecordRelaunchLogsDialog",
  props: {
    allRecordsLogs: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("utils.creationDate"),
          align: "center",
          value: "dateCreated",
        },
        {
          text: this.$t("utils.category"),
          align: "center",
          value: "data.actionDisplay",
        },
      ],
    };
  },
  methods: {
    reset() {
      this.$emit("close");
    },
  },
};
</script>
