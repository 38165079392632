<template>
  <!-- EDIT AVATAR IMAGE DIALOG -->
  <BaseFrontOfficeDialog
    :value="dialogState"
    max-width="640"
    :persistent="isSubmiting"
    @click:outside="!isSubmiting && reset()"
    @keydown.esc="!isSubmiting && reset()"
  >
    <template #modal>
      <BaseFrontOfficeCard>
        <template #title>
          <BaseTitle v-text="$t('client.account.importAvatar')" />
        </template>
        <template>
          <BaseFrontOfficeFieldImage
            v-model="form.avatar"
            class="mt-4"
            :label="$t('client.account.avatarFieldLabel')"
            :max-size="1000000"
          />
        </template>
        <template #actions class="d-flex justify-end">
          <v-spacer />
          <BaseFrontOfficeButton
            :disabled="isSubmiting"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseFrontOfficeButton>
          <BaseFrontOfficeButton
            :disabled="!form.avatar"
            :loading="isSubmiting"
            type="primary"
            @click="submit()"
          >
            {{ $t("client.account.changeAvatar") }}
          </BaseFrontOfficeButton>
        </template>
      </BaseFrontOfficeCard>
    </template>
  </BaseFrontOfficeDialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WalletEditAvatarDialog",
  data() {
    return {
      form: {
        avatar: null,
      },
      isSubmiting: false,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.account.modals.isEditAvatarDialogOpen,
    }),
  },
  methods: {
    reset() {
      this.isSubmiting = false;
      this.form.image = null;
      this.$store.commit("account/setIsEditAvatarDialog", false);
    },
    async submit() {
      this.isSubmiting = true;
      try {
        await this.$store.dispatch("account/updateUserAvatar", {
          avatar: this.form.avatar,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.avatarChangeSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.avatarChangeError"),
          type: "ERROR",
        });
      }
      this.reset();
    },
  },
};
</script>
