import ClientList from "../Pages/ClientList.vue";
import ClientDetail from "../Pages/ClientDetail.vue";

export default [
  {
    path: "client/",
    name: "clientList",
    component: ClientList,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "clientList",
          disabled: true,
          label: "utils.contacts",
        },
      ],
    },
  },
  {
    path: "client/:clientId",
    name: "clientDetail",
    component: ClientDetail,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "clientList",
          disabled: false,
          label: "utils.contacts",
        },
        {
          name: "clientDetail",
          disabled: true,
          label: "utils.specificContacts",
        },
      ],
    },
  },
];
