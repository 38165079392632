<template>
  <v-alert
    class="cosmo-alert"
    :class="`cosmo-alert-${type}`"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="d-flex align-center">
      <div class="mr-4">
        <BaseFrontOfficeIcon
          v-if="type === 'success'"
          icon="$mdiCheck"
          color="walletAlertSuccess"
        />
        <BaseFrontOfficeIcon
          v-else-if="type === 'warning'"
          icon="$mdiHelpCircle"
          color="walletAlertWarning"
        />
        <BaseFrontOfficeIcon
          v-else-if="type === 'info'"
          icon="$mdiInformation"
          color="walletAlertInfo"
        />
        <BaseFrontOfficeIcon v-else icon="$mdiClose" color="walletAlertError" />
      </div>
      <BaseFrontOfficeParagraph>
        <slot />
      </BaseFrontOfficeParagraph>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "BaseFrontOfficeAlert",
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style scoped>
.cosmo-alert-success {
  border-radius: 15px;
  border: var(--v-walletAlertSuccess-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
.cosmo-alert-warning {
  border-radius: 15px;
  border: var(--v-walletAlertWarning-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
.cosmo-alert-info {
  border-radius: 15px;
  border: var(--v-walletAlertInfo-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
.cosmo-alert-error {
  border-radius: 15px;
  border: var(--v-walletAlertError-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
</style>
