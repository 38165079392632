var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.client)?_c('div',{staticClass:"cd_header"},[_c('div',{staticClass:"header_item"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"header_title"},[_vm._v(" "+_vm._s(_vm.$tc("utils.contact", 1))+" ")]),(_vm.client.inOrganizationSignatories)?_c('base-icon',{staticClass:"ml-2",attrs:{"icon":"$mdiDomain","small":"","color":"primary"}}):_c('div',{staticClass:"icon__container ml-2",on:{"click":function($event){{
              if (!_vm.client.inOrganizationSignatories) {
                _vm.dialogState = true;
              }
            }}}},[_c('div',{staticClass:"tool_tip_container"},[_c('div',{staticClass:"msg_container"},[_c('span',{staticClass:"text__tooltip",domProps:{"textContent":_vm._s(_vm.$t('utils.addSignatoryTip'))}}),_c('v-icon',{staticClass:"arrow_down"},[_vm._v("$arrowDown")])],1)]),_c('base-icon',{attrs:{"icon":"$mdiPlus","small":"","color":"white"}})],1)],1),_c('div',{staticClass:"sub_title",domProps:{"textContent":_vm._s(_vm.client.email)}}),(_vm.client.fullName)?_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"item_key",domProps:{"textContent":_vm._s(_vm.client.fullName)}})]):_vm._e(),_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"item_key",domProps:{"textContent":_vm._s(((_vm.$t('utils.phone')) + " :"))}}),_c('div',{staticClass:"item_value",domProps:{"textContent":_vm._s(_vm.client.phone)}})]),_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"item_key",domProps:{"textContent":_vm._s(((_vm.$t('utils.registration')) + " :"))}}),_c('div',{staticClass:"item_value",domProps:{"textContent":_vm._s(_vm.client.matricule)}})]),_c('div',{staticClass:"avatar_container"},[(_vm.client.identityPhoto)?_c('img',{attrs:{"src":_vm.client.identityPhotoUrl}}):_c('span',{staticClass:"first_char",domProps:{"textContent":_vm._s(_vm.client.firstChar)}})]),_c('div',{staticClass:"header_action"},[_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){return _vm.$emit('createFolder')}}},[_vm._v(" "+_vm._s(_vm.$tc("organization.createFolder", 1))+" ")])],1)]),_c('div',{staticClass:"header_item"},[_c('div',{staticClass:"header_title",domProps:{"textContent":_vm._s(_vm.$t('utils.generalInformation'))}}),_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"item_key",domProps:{"textContent":_vm._s(((_vm.$t('utils.startOfContract')) + " :"))}}),_c('div',{staticClass:"item_value",domProps:{"textContent":_vm._s(
            _vm.client.contractStartDate
              ? _vm.$d(new Date(_vm.client.contractStartDate), 'veryshort')
              : ''
          )}})]),_c('div',{staticClass:"item_content"},[_c('div',{staticClass:"item_key",domProps:{"textContent":_vm._s(((_vm.$t('utils.lastAction')) + " :"))}}),_c('div',{staticClass:"item_value",domProps:{"textContent":_vm._s(_vm.lastAction)}})])])]):_vm._e(),_c('OrganizationSignatoryCreateAndEditDialog',{attrs:{"dialog-state":_vm.dialogState,"signatory":_vm.clientSignatory,"client":_vm.client,"from-client":"","organization-id":_vm.organizationId},on:{"close":function($event){_vm.dialogState = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }