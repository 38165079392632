<template>
  <div class="message__content">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseChatMessage",
};
</script>

<style scoped>
.message__content {
  align-self: flex-start;
  background-color: #f7f7f7 !important;
  border-radius: 0px 20px 20px 20px;
  padding: 1rem;
  font-size: 1em;
  line-height: 22px;

  color: #4f4f4f !important;
}
</style>
