<template>
  <div
    class="cosmo-app-bar-link d-flex align-center px-2"
    :class="{ 'left-slider-on-hover': isLink }"
  >
    <BaseFrontOfficeImage v-if="imageRequired" :src="imageName" />
    <span class="ml-3" :class="className" v-text="linkName" />
  </div>
</template>

<script>
export default {
  name: "WalletNavigationLink",
  props: {
    className: {
      type: String,
      validator: (value) =>
        ["active_link", "default_link", ""].indexOf(value) !== -1,
      default: "",
    },
    imageName: {
      type: String,
      default: () => this.$findStatic("cosmoWalletData"),
    },
    imageRequired: {
      type: Boolean,
    },
    linkName: {
      type: String,
      default: "",
    },
    route: {
      type: Object,
      default: () => ({ name: "" }),
    },
    isLink: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.active_link {
  color: #ff5621;
}
.default_link {
  color: #3c3b8e;
}
</style>
