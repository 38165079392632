<template>
  <div class="folder_container">
    <div v-if="fetchingWorkflow" class="folder_header">
      <div v-for="i in 3" :key="`l-${i}`" class="header_item">
        <div class="item_title">
          <v-skeleton-loader class="skeleton" type="text" />
          <v-btn color="grey" class="action" icon disabled>
            <BaseIcon icon="$mdiDotsHorizontal" />
          </v-btn>
        </div>
        <div class="sub_title">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
        <div class="item_content">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
        <div v-if="i == 1" class="item_content">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
      </div>
    </div>
    <template v-if="fetchingWorkflow">
      <div v-if="folder && folder.state == 'CLOSED'" class="archived_alert">
        <div class="archived_alert_icon">
          <BaseIcon icon="$mdiAlertCircle" />
        </div>
        <div class="archived_alert_content">
          <div
            class="archived_info"
            v-text="$t('utils.folderArchivedMessage')"
          />
          <div class="archived_info">
            <div v-text="`${$t('utils.archiveDate')} :`" />
            <div
              class="text_strong"
              v-text="`le ${$d(new Date(folder.dateUpdated), 'detailed')}`"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- HEADER -->
    <div v-if="folder && !fetchingWorkflow" class="folder_header">
      <!-- FOLDER -->
      <div class="header_item">
        <div class="item_title">
          <span v-text="$t('utils.folder')" />
          <v-menu offset-y left>
            <template #activator="{ on, attrs }">
              <v-btn color="grey" class="action" icon v-bind="attrs" v-on="on">
                <BaseIcon icon="$mdiDotsHorizontal" />
              </v-btn>
            </template>
            <div class="menu_list">
              <v-btn
                x-large
                class="menu_list_item"
                block
                text
                :disabled="folder.state == 'CLOSED'"
                @click="
                  $store.commit('folder/SET_MODAL_FOlDER_EDIT_NAME', true)
                "
              >
                <BaseIcon class="icon" icon="$mdiPencilOutline" small left />
                <span class="label" v-text="$t('folder.editName')" />
              </v-btn>
              <v-btn
                x-large
                class="menu_list_item"
                block
                text
                @click="$store.commit('folder/setFolderLogsDialogOpen', true)"
              >
                <BaseIcon class="icon" icon="$mdiClockOutline" small left />
                <span class="label" v-text="$t('folder.seeHistory')" />
              </v-btn>
              <v-btn
                v-if="folder.state == 'OPEN'"
                x-large
                class="menu_list_item"
                block
                text
                @click="
                  $store.commit('folder/SET_MODAL_FOLDER_ARCHIVE_STATUS', true)
                "
              >
                <BaseIcon class="icon" icon="$mdiArchive" small left />
                <span class="label" v-text="$t('utils.archive')" />
              </v-btn>
            </div>
          </v-menu>
        </div>
        <div class="d-flex flex-row">
          <div
            class="pastille"
            :style="{ backgroundColor: getValidationColor }"
          />
          <div class="sub_title" v-text="folder.name" />
        </div>

        <div class="item_content">
          <div class="item_key" v-text="`${$t('utils.creation')} : `" />
          <div
            class="item_value"
            v-text="$d(new Date(folder.dateCreated), 'detailed')"
          />
        </div>
        <div class="item_content">
          <div class="item_key" v-text="`${$t('utils.lastEdit')} : `" />
          <div
            class="item_value"
            v-text="$d(new Date(folder.dateUpdated), 'detailed')"
          />
        </div>
        <div v-if="folder.state == 'CLOSED'" class="item_content_right">
          <button
            class="unarchive_btn"
            @click="
              $store.commit('folder/SET_MODAL_FOLDER_ARCHIVE_STATUS', true)
            "
          >
            <BaseIcon class="icon" icon="$mdiFolderRefreshOutline" />
            <span v-text="$t('utils.unarchive')" />
          </button>
        </div>
      </div>
      <!-- CONTACT -->
      <div class="header_item">
        <div class="item_title">
          <span v-text="$tc('utils.contact', 1)" />
          <v-menu offset-y left>
            <div class="menu_list">
              <v-btn
                x-large
                class="menu_list_item"
                :disabled="folder.state == 'CLOSED'"
                block
                text
                @click="$store.commit('folder/setFolderChangeClient', true)"
              >
                <BaseIcon class="icon" icon="$mdiPencilOutline" small left />
                <span class="label" v-text="$t('folder.editClient')" />
              </v-btn>
            </div>
          </v-menu>
        </div>
        <template v-if="folder.client && hasClient">
          <div class="sub_title" v-text="folder.client.email" />
          <div v-if="folder.client.fullName" class="item_content">
            <div class="item_key" v-text="`${$t('utils.fullname')} : `" />
            <div class="item_value" v-text="folder.client.fullName" />
          </div>
          <button
            v-if="folder.state == 'OPEN'"
            class="item_footer_action"
            @click="$store.commit('folder/setFolderChangeClient', true)"
          >
            <BaseIcon class="icon" icon="$mdiAccount" />
            <span v-text="$t('folder.editClient')" />
          </button>
        </template>
        <template v-else>
          <div class="sub_title" v-text="$t('utils.noAssignedContact')" />
          <button
            v-if="folder.state == 'OPEN'"
            class="item_footer_action"
            @click="$store.commit('folder/setFolderChangeClient', true)"
          >
            <BaseIcon class="icon" icon="$mdiAccount" />
            <span v-text="$t('utils.assignedContact')" />
          </button>
        </template>
      </div>
      <!-- WORKFLOW -->
      <div class="header_item">
        <div class="item_title">
          <span v-text="$t('utils.workflow')" />
          <v-menu offset-y left>
            <template #activator="{ on, attrs }">
              <v-btn color="grey" class="action" icon v-bind="attrs" v-on="on">
                <BaseIcon icon="$mdiDotsHorizontal" />
              </v-btn>
            </template>
            <div class="menu_list">
              <v-btn
                x-large
                class="menu_list_item"
                :disabled="folder.state == 'CLOSED'"
                block
                text
                @click="
                  $store.commit('folder/SET_MODAL_FOLDER_EDIT_WORKFLOW', true)
                "
              >
                <BaseIcon class="icon" icon="$mdiPencilOutline" small left />
                <span class="label" v-text="$t('folder.editWorkflow')" />
              </v-btn>
              <v-btn
                x-large
                class="menu_list_item"
                :disabled="folder.state == 'CLOSED'"
                block
                text
                @click="
                  $store.commit(
                    'folder/SET_MODAL_ASSIGN_COLLABORATOR_STATUS',
                    true
                  )
                "
              >
                <BaseIcon class="icon" icon="$mdiPencilOutline" small left />
                <span
                  class="label"
                  v-text="$t('utils.editAssignedCollaborator')"
                />
              </v-btn>
            </div>
          </v-menu>
        </div>
        <template v-if="activeWorkflow">
          <div class="sub_title" v-text="activeWorkflow.name" />
          <div v-if="folder.assign" class="item_content">
            <div class="item_key" v-text="$t('utils.assignedCollaborator')" />
            <div class="item_value" v-text="assignEmail" />
          </div>
        </template>
        <template v-else>
          <div class="sub_title" v-text="$t('utils.noAssignedWorkflow')" />
          <button
            v-if="folder.state == 'OPEN'"
            class="item_footer_action"
            @click="
              $store.commit('folder/SET_MODAL_FOLDER_EDIT_WORKFLOW', true)
            "
          >
            <BaseIcon class="icon" icon="$mdiCogs" />
            <span v-text="$t('utils.assignWorkflow')" />
          </button>
        </template>
      </div>
    </div>

    <!-- WORKFLOW -->
    <div class="folder_body">
      <FolderWokflowDetail
        v-if="activeWorkflow"
        :workflow="activeWorkflow"
        :folder-active-status-id="folder ? folder.status : NaN"
      />
      <FolderRecords
        v-if="folder"
        :organization-id="organizationId"
        :folder-id="folderId"
      />
    </div>

    <!-- DIALOG -->
    <FolderEditNameDialog />
    <FolderLogsShowDialog />
    <FolderChangeOwnerDialog />
    <FolderAssignCollaboratorDialog />
    <FolderEditWorkflowDialog />
    <FolderSendDataToDPAEDialog />
    <FolderSendMailDialog />

    <FolderArchiveDialog />
  </div>
</template>

<script>
import { mapState } from "vuex";

import FolderWokflowDetail from "@/modules/Folder/Components/FolderWokflowDetail";

import FolderEditNameDialog from "@/modules/Folder/Components/FolderEditNameDialog.vue";
import FolderLogsShowDialog from "@/modules/Folder/Components/FolderLogsShowDialog.vue";
import FolderChangeOwnerDialog from "@/modules/Folder/Components/FolderChangeOwnerDialog.vue";
import FolderAssignCollaboratorDialog from "@/modules/Folder/Components/FolderAssignCollaboratorDialog.vue";
import FolderEditWorkflowDialog from "@/modules/Folder/Components/FolderEditWorkflowDialog.vue";
import FolderRecords from "@/modules/Folder/Components/FolderRecords.vue";
import FolderSendDataToDPAEDialog from "../Components/FolderSendDataToDPAEDialog.vue";
import FolderArchiveDialog from "../Components/FolderArchiveDialog.vue";
import FolderSendMailDialog from "../Components/FolderSendMailDialog.vue";

export default {
  name: "FolderDetail",
  components: {
    FolderWokflowDetail,
    FolderEditNameDialog,
    FolderLogsShowDialog,
    FolderChangeOwnerDialog,
    FolderAssignCollaboratorDialog,
    FolderEditWorkflowDialog,
    FolderRecords,
    FolderArchiveDialog,
    FolderSendDataToDPAEDialog,
    FolderSendMailDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      fetchingWorkflow: true,
    };
  },
  computed: {
    ...mapState({
      folder: (state) => state.folder.activeFolder,
      activeWorkflow: (state) => state.workflow.activeWorkflow,
    }),
    workflowId() {
      return this.folder ? this.folder.workflow : null;
    },
    assignEmail() {
      return this.folder?.assign ? this.folder?.assign?.email : "";
    },
    getValidationColor() {
      switch (this.folder.validationStatus) {
        case "UPDATE_REQUESTED":
          return "#ff5267";
        case "UPDATED":
          return "#ffab5a";
        case "VALIDATED":
          return "#18a300";
        default:
          return this.folder.hasSpamEmail ? "#FF6B00" : "#b6bdff";
      }
    },
    hasClient() {
      return !this.folder?.client?.isTemporary;
    },
  },
  watch: {
    folderId: {
      async handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.loadStoreData();
        }
      },
      immediate: true,
    },
    folder: {
      handler(newVal, oldVal) {
        if ((!oldVal && newVal) || (newVal && newVal.name !== oldVal.name)) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
    workflowId: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.fetchWorkflow();
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", null);
    this.$store.commit("folder/SET_ACTIVE_FOLDER", null);
  },
  methods: {
    async loadStoreData() {
      if (!this.organizationId || !this.folderId) return;
      try {
        await this.$store.dispatch("folder/fetchActiveFolder", {
          organizationId: this.organizationId,
          folderId: this.folderId,
        });

        await this.$store.dispatch("folder/fetchClientOwnfolder", {
          organizationId: this.organizationId,
          folderId: this.folderId,
        });
      } catch (error) {
        this.$router.push({
          name: "folderList",
          params: {
            organizationId: this.organizationId,
          },
        });
      }
    },
    async fetchWorkflow() {
      this.fetchingWorkflow = true;
      this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", null);
      if (this.workflowId) {
        await this.$store.dispatch("workflow/fetchActiveWorkflow", {
          organizationId: this.organizationId,
          workflowId: this.workflowId,
        });
      }
      this.fetchingWorkflow = false;
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[1].label = `${this.folder.name} `;
    },
  },
};
</script>

<style lang="scss" scoped>
.folder_container {
  position: relative;
  .archived_alert {
    padding: 16px;
    border-radius: 5px;
    background: #f7e7da;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: sticky;
    top: -32px;
    z-index: 100;
    .archived_alert_icon {
      &::v-deep {
        .v-icon {
          color: #ff6b00;
        }
      }
    }
    .archived_alert_content {
      padding: 0 16px;
      .archived_info {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ff6b00;
        .text_strong {
          font-weight: 600;
          margin-left: 6px;
        }
      }
    }
  }
  .folder_header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    margin-bottom: 24px;
    .header_item {
      border-radius: 5px;
      flex: 1;
      padding: 24px;
      background: #ffffff;
      border: 0.5px solid #ecebf1;
      position: relative;
      width: 100%;
      overflow: hidden;
      word-break: keep-all;
      word-wrap: break-word;
      .pastille {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        padding: 8px;
        margin-right: 10px;
        position: relative;
        top: 5px;
      }
      .item_title {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #707080;
        margin-bottom: 0.3em;
        .action {
          width: 24px;
          height: 24px;
          margin-left: auto;
        }
        .skeleton {
          height: 16px;
          width: 70px;
          ::v-deep {
            .v-skeleton-loader__bone {
              height: 12px;
            }
          }
        }
      }
      .sub_title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #353542;
        margin-bottom: 0.6em;
        .skeleton {
          height: 32px;
          width: 66%;
          ::v-deep {
            .v-skeleton-loader__bone {
              height: 24px;
            }
          }
        }
      }
      .item_content {
        display: flex;
        margin-top: 0.6em;
        .item_key {
          margin-right: 6px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
        }
        .item_value {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
        }
        .skeleton {
          height: 16px;
          width: 50%;
          ::v-deep {
            .v-skeleton-loader__bone {
              height: 12px;
            }
          }
        }
      }
      .item_footer_action {
        position: absolute;
        right: 24px;
        bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #f4f7ff;
        border-radius: 5px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #2536cc;
        & > .icon {
          &::v-deep {
            .v-icon {
              width: 16px;
              height: 16px;
              color: #2536cc;
            }
          }
        }
        & > * {
          margin: 0 4px;
        }
      }

      .item_content_right {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
        .unarchive_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          background: #f7e7da;
          border-radius: 5px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #ff6b00;
          & > .icon {
            &::v-deep {
              .v-icon {
                width: 16px;
                height: 16px;
                color: #ff6b00;
              }
            }
          }
          & > * {
            margin: 0 4px;
          }
        }
      }
    }
  }
  .folder_body {
    .stepper_content {
      display: flex;
      .status_section {
        width: calc(100% - 250px);
        flex: none;
      }
      .action_section {
        width: 250px;
        flex: none;
      }
    }
  }
}
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: flex-start;
    &::v-deep {
      .icon {
        color: #cfcfdc;
      }
      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #707080;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      &::v-deep {
        .icon {
          color: #2536cc;
        }
        .label {
          color: #2536cc;
        }
      }
    }
    &.v-btn--disabled {
      &::v-deep {
        .icon {
          .v-icon {
            color: #cfcfdc !important;
          }
        }
        .label {
          color: #cfcfdc;
        }
      }
    }
  }
}
</style>
