<template>
  <v-avatar :size="size" v-bind="$attrs" v-on="$listeners">
    <v-img :src="src ? src : $findStatic('defaultAvatar')" :cover="true" />
  </v-avatar>
</template>

<script>
export default {
  name: "BaseFrontOfficeAvatar",
  props: {
    src: {
      type: String,
      default: () => "",
    },
    size: {
      type: Number,
      default: () => 40,
    },
  },
};
</script>
