<template>
  <BaseSheet
    v-ripple="!fileProp"
    :color="!fileProp ? 'grey lighten-3' : 'transparent'"
    class="d-flex justify-center align-center relative cosmo-image-input-cover"
    :class="{ 'cosmo-image-input-cover-unselected': !fileProp }"
    :height="height"
    @click="!fileProp && focusImageInput()"
    v-on="$listeners"
  >
    <BaseImage v-if="imageSrc" :src="imageSrc" class="file-image-display" />
    <form ref="form" class="d-none">
      <input
        ref="imageInput"
        type="file"
        :accept="
          onlyImage
            ? 'image/jpeg,image/png,image/jpg'
            : onlyPdf
            ? 'application/pdf'
            : ''
        "
        @change="changeImage"
      />
    </form>
    <BaseButton
      v-if="!fileProp"
      v-show="!fileLocalUrl"
      icon="$mdiPlus"
      color="grey"
      small
      @click.stop="focusImageInput()"
    >
      {{ label }}
    </BaseButton>
    <div v-if="fileIsntImage" class="file-isnt-image">
      <BaseIcon icon="$mdiFilePdfBox" size="80" />
      <BaseParagraph v-if="nameRequired" v-text="fileProp.name" />
    </div>
    <div>
      <BaseSheet color="grey lighten-3" class="image-action">
        <BaseButtonIcon
          v-if="fileProp"
          icon="$mdiPencilOutline"
          color="primary"
          @click.stop="focusImageInput()"
        />
        <BaseButtonIcon
          v-if="fileProp || !hideRemove"
          icon="$mdiTrashCanOutline"
          color="secondary"
          @click.stop="removeImage()"
        />
      </BaseSheet>
    </div>
  </BaseSheet>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaseFileFieldDisplay",
  model: {
    prop: "fileProp",
    event: "changeValue",
  },
  props: {
    fileProp: {
      type: [File, Object, String],
      File,
      default: () => {},
    },
    label: {
      type: String,
      default: function () {
        return this.$t("utils.addCoverPicture");
      },
    },
    height: {
      type: Number,
      default: 200,
    },
    hideRemove: {
      type: Boolean,
      default: true,
    },
    onlyImage: {
      type: Boolean,
    },
    directFile: {
      type: Boolean,
    },
    nameRequired: {
      type: Boolean,
      default: true,
    },
    onlyPdf: {
      type: Boolean,
    },
  },
  data() {
    return {
      fileLocalUrl: "",
      fileIsntImage: false,
      edited: false,
    };
  },
  computed: {
    ...mapState({ activeOffer: (state) => state.offer.activeOffer }),
    imageErrors() {
      let errors = [];
      if (this.fileProp && !/.jpg$|.jpeg$|.png$/.test(this.fileProp.type)) {
        errors.push(this.$t("utils.pleaseProvideAnImage"));
      }
      if (this.fileProp && this.fileProp.size > 4000000) {
        errors.push(this.$t("utils.imageTooBig", { size: "4" }));
      }
      return errors;
    },
    imageSrc() {
      if (this.directFile) {
        return this.fileProp;
      }
      if (this.edited) {
        return this.fileProp?.offerFile;
      }
      if (!/.jpg$|.jpeg$|.png$/.test(this.fileProp?.type)) {
        return null;
      }
      if (this.fileLocalUrl) {
        return this.fileLocalUrl;
      }
      if (this.fileProp) {
        return URL.createObjectURL(this.fileProp);
      }
      return null;
    },
  },
  watch: {
    fileProp: {
      handler(file) {
        this.edited = false;
        this.fileIsntImage = false;
        if (!file) {
          return;
        }
        if (this.directFile) return;
        if (file?.offerFile) {
          this.edited = true;
          if (!/.jpg$|.jpeg$|.png$/.test(file.offerFile)) {
            this.fileIsntImage = true;
            return;
          }
          return (this.fileLocalUrl = file);
        }

        if (!file?.offerFile && !/.jpg$|.jpeg$|.png$/.test(file.type)) {
          this.fileIsntImage = true;
          return;
        }
        this.fileLocalUrl = URL.createObjectURL(file);
      },
      immediate: true,
    },
  },

  methods: {
    focusImageInput() {
      this.$refs.imageInput.click();
      this.$emit("changed");
      this.errorMessages = "";
      this.fileLocalUrl = "";
    },
    changeImage(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      this.fileIsntImage = false;
      // patch offerfile
      if (this.edited) {
        try {
          this.$store.dispatch("offer/patchOfferFile", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            offerId: this.activeOffer.id,
            fileId: this.fileProp.id,
            payload: file,
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.snackbar.editSuccess"),
            type: "SUCCESS",
          });
        } catch (error) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.snackbar.editError"),
            type: "ERROR",
          });
        }
      }
      if (!/.jpg$|.jpeg$|.png$/.test(file.type)) {
        if (this.onlyImage) {
          return (this.errorMessages = this.$t("utils.pleaseProvideAnImage"));
        }
        this.fileIsntImage = true;
        if (file.size > 4000000) {
          return (this.errorMessages = this.$t("utils.imageTooBig", {
            size: "4",
          }));
        }
        this.$emit("changeValue", file);
        return;
      }
      this.fileLocalUrl = URL.createObjectURL(file);
      if (file.size > 4000000) {
        return (this.errorMessages = this.$t("utils.imageTooBig", {
          size: "4",
        }));
      }
      this.errorMessages = "";
      this.$refs.imageInput.value = "";
      this.$emit("changeValue", file);
    },
    async removeImage() {
      this.errorMessages = "";
      this.$refs.imageInput.value = "";
      this.fileLocalUrl = null;
      this.$emit("changeValue", null);
      this.$emit("delete-file");
    },
  },
};
</script>

<style scoped>
.cosmo-image-input-cover {
  overflow: hidden;
}
.cosmo-image-input-cover-unselected {
  cursor: pointer;
}
.file-image-display {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}
.image-action {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 5px;
  right: 5px;
}
.file-isnt-image {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep .file-isnt-image .v-icon__svg {
  height: 100%;
  width: 100%;
}
</style>
