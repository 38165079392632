<template>
  <div>
    <BaseDataTable
      :headers="COLLABORATORS_HEADER"
      :items="activeOrganization.collaborators"
      :loading="isLoadingActiveOrganizationCollaborators"
      :loading-text="$t('utils.loadingData')"
      class="elevation-1 mb-6"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <BaseParagraph v-text="item.user.email" />
          </td>
          <td>
            <BaseParagraph
              v-if="item.user.fullName"
              v-text="item.user.fullName"
            />
            <BaseParagraph v-else italic v-text="$t('answer.noContent')" />
          </td>
          <td>
            <BaseParagraph
              v-if="/^MANAGER$/.test(item.status)"
              v-text="$t('organization.manager')"
            />
            <BaseParagraph
              v-else-if="/^MEMBER$/.test(item.status)"
              v-text="$t('organization.member')"
            />
          </td>
          <td v-if="userStatus === 'MANAGER'">
            <div class="py-4 px-4">
              <BaseButtonIcon
                color="secondary"
                x-small
                icon="$mdiTrashCanOutline"
                @click="
                  () => {
                    $store.commit('organization/SET_ACTIVE_COLLABORATOR', item);
                    $store.commit(
                      'organization/SET_REMOVE_COLLABORATOR_DIALOG_STATUS',
                      true
                    );
                  }
                "
              />
            </div>
          </td>
        </tr>
      </template>
    </BaseDataTable>

    <OrganizationRemoveCollaboratorDialog />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganizationRemoveCollaboratorDialog from "./OrganizationRemoveCollaboratorDialog";

export default {
  name: "OrganizationDashboardCollaborators",
  components: {
    OrganizationRemoveCollaboratorDialog,
  },
  data() {
    return {
      COLLABORATORS_HEADER: [
        {
          text: this.$tc("utils.email", 1),
          value: "email",
        },
        {
          text: this.$tc("utils.fullname"),
          value: "fullName",
        },
        {
          text: this.$tc("utils.function"),
          value: "status",
        },
      ],
      userStatus: "MEMBER",
    };
  },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
      isLoadingActiveOrganizationCollaborators: (state) =>
        state.organization.isLoadingActiveOrganizationCollaborators,
      user: (state) => state.account.user,
    }),
  },
  watch: {
    "activeOrganization.id": {
      handler() {
        this.initialize();
      },
      immediate: true,
    },
  },

  methods: {
    async initialize() {
      await this.$store.dispatch(
        "organization/fetchOrganizationCollaborators",
        this.activeOrganization.id
      );
      this.userStatus = this.getUserStatus();
      if (this.userStatus === "MANAGER") {
        this.COLLABORATORS_HEADER.push({
          text: this.$tc("utils.action", 2),
          sortable: false,
        });
      }
    },
    getUserStatus() {
      if (!this.user || !this.activeOrganization.collaborators) return "MEMBER";
      return this.activeOrganization.collaborators.find(
        (c) => c.user.id === this.user.id
      ).status;
    },
  },
};
</script>
