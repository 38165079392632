import { render, staticRenderFns } from "./ClientAskInformationDialog.vue?vue&type=template&id=073df8ce&scoped=true"
import script from "./ClientAskInformationDialog.vue?vue&type=script&lang=js"
export * from "./ClientAskInformationDialog.vue?vue&type=script&lang=js"
import style0 from "./ClientAskInformationDialog.vue?vue&type=style&index=0&id=073df8ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073df8ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
