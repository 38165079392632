<template>
  <div class="signatories">
    <div class="title-container">
      <div class="signatory-title" v-text="'Signataires'" />
      <div class="information">
        <BaseIcon
          small
          icon="$mdiInformation"
          color="primary"
          class="icon-info"
          @mouseenter="informationHovered = true"
          @mouseleave="informationHovered = false"
        />
        <div
          v-show="informationHovered"
          class="text-information"
          v-text="
            'Veuillez cliquer sur les destinataires ci-dessous pour gérer l’emplacement des signatures.'
          "
        />
      </div>
    </div>
    <div v-if="signatories.length" class="signatories-container">
      <base-pin-point
        v-for="(signatory, index) in signatories"
        :key="index"
        :label="getName(signatory)"
        :bg-color="getColor(index)"
        :active="isActiveSignatory(signatory && signatory.email)"
        @click="pinSelectable && setActivePin(signatory && signatory.email)"
      />
    </div>
    <div class="pdf-viewer-files">
      <div
        v-for="(file, index) in files"
        :key="file.name"
        class="files"
        :class="{ 'active-file': activeFile.name === file.name }"
      >
        <div v-if="isValidate(file)" class="validate-file">
          <div class="d-flex align-center">
            <base-icon
              icon="$mdiFileDocument"
              class="icon-file"
              color="primary"
              small
            />
            <span class="file-name" v-text="file.name" />
          </div>

          <Base-icon class="ml-auto" small icon="$mdiCheck" color="primary" />
        </div>
        <div v-else class="files-content">
          <div class="d-flex align-center">
            <base-icon icon="$mdiFileDocument" class="icon-file" small />
            <span class="file-name" v-text="file.name" />
          </div>

          <BaseButton
            class="btn-validation"
            :disabled="isDisabled(file)"
            :loading="loadingValidation === index"
            @click="setActiveFile(index)"
          >
            <Base-icon
              class="icon-validation"
              small
              icon="$mdiCheck"
              color="#707080"
            />
            <span class="ml-2 label" v-text="$t('utils.validate')" />
          </BaseButton>
        </div>
      </div>
    </div>
    <div v-if="pdfSrc" class="pdf-container">
      <div class="pdf-display" :class="{ 'mt-2': !(pdf && pdf.numPages > 1) }">
        <BasePdfViewerSignature
          v-if="isLoading"
          :pdf="pdfSrc"
          :page="actualPage"
          :signatories="signatories"
          :selected-signatory="selectedSignatory"
          class="pdf"
        />
      </div>
    </div>
    <div v-if="pdf" class="pagination-container">
      <BasePagination
        v-model="actualPage"
        :total-visible="5"
        :length="pdf.numPages"
        :nb-pages="pdf.numPages"
      />
    </div>
  </div>
</template>

<script>
const SIGNATORY_COLOR = [
  "#BDA0ED33",
  "#E2ECFF",
  "#beecbe",
  "#DFCCFB",
  "#FFF3DA",
  "#F6FFA6",
];
import pdfvuer from "pdfvuer";
import BaseButton from "../UI/Button/BaseButton.vue";
import { mapState } from "vuex";
import Oauth from "../../Models/Oauth";
import { VignetteSignatureProperty } from "@/core/Utils/types.utils";
export default {
  name: "BasePdfSignaturePositioner",
  components: { BaseButton },
  props: {
    signatories: {
      type: Array,
      default: () => [],
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      informationHovered: false,
      pdfSrc: null,
      loadingValidation: null,
      actualPage: 1,
      pdf: null,
      isLoading: true,
      pinSelectable: true,
      selectedSignatory: [],
      signatoryPosition: [null, null],
      validateFile: [],
      activeFile: {
        src: "",
        name: "",
      },
    };
  },
  computed: {
    allSelected() {
      return this.selectedSignatory.length === this.signatories.length;
    },
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      newOffer: (state) => state.offer.newOffer,
    }),
  },
  created() {
    this.initialize();
  },

  methods: {
    getName(signatory) {
      return `${signatory?.firstName} ${signatory?.lastName}`;
    },
    isDisabled(file) {
      return !(this.allSelected && this.activeFile.name === file.name);
    },
    isValidate(file) {
      return this.validateFile.some(
        (validateFile) => validateFile.name === file.name
      );
    },
    getColor(index) {
      return SIGNATORY_COLOR[index];
    },
    isActiveSignatory(id) {
      return this.selectedSignatory.some((signatory) => signatory === id);
    },
    async convertPDf(file) {
      const oauth = Oauth.localStorage;
      const myHeaders = new Headers({
        Authorization: `Bearer ${oauth.accessToken}`,
      });
      const payload = new FormData();
      payload.append("files", file);
      const myInit = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };

      const response = await fetch("/api/web/utils/to_pdf/", myInit);
      const myBlob = await response.blob();
      return new File([myBlob], file.name, {
        type: "application/pdf",
      });
    },
    async initialize() {
      try {
        this.activeFile =
          this.files[0].type !== "application/pdf"
            ? await this.convertPDf(this.files[0])
            : this.files[0];
        if (this.activeFile) {
          const file = URL.createObjectURL(this.activeFile);
          this.pdf = await pdfvuer.createLoadingTask(file);
          this.pdfSrc = file;
        }
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: error.message || "error",
          type: "ERROR",
        });
      }
    },
    async setActiveFile(index) {
      this.loadingValidation = index;
      this.validate();
      if (this.files.length - 1 == index) {
        this.validateFile.push(this.files[index]);
        this.activeFile = { name: "" };
        this.$emit("all-file-validated");
        return (this.pinSelectable = false);
      }
      this.activeFile =
        this.files[index + 1].type !== "application/pdf"
          ? (this.activeFile = await this.convertPDf(this.files[index + 1]))
          : this.files[index + 1];
      this.isLoading = false;
      this.pdfSrc = URL.createObjectURL(this.activeFile);
      this.$nextTick();
      setTimeout(() => {
        this.isLoading = true;
      }, 30);
      this.loadingValidation = null;
      this.validateFile.push(this.files[index]);
      this.pdf = await pdfvuer.createLoadingTask(this.pdfSrc);
      this.actualPage = 1;
      this.selectedSignatory = [];
    },
    setActivePin(email) {
      if (this.selectedSignatory.some((signatory) => signatory === email)) {
        return (this.selectedSignatory = this.selectedSignatory.filter(
          (signatory) => signatory !== email
        ));
      }
      this.selectedSignatory.push(email);
    },
    getPosition(x = 325, y) {
      const maxHeight = +document.getElementById("pdf-container").offsetHeight;
      const height = VignetteSignatureProperty.height;
      const width = VignetteSignatureProperty.width;
      const y1 = isNaN(y) ? maxHeight - height : maxHeight - y - height;
      const y2 = isNaN(y) ? maxHeight : maxHeight - y;
      const x1 = isNaN(x) ? 325 : x;
      return `${x1},${y1},${x1 + width},${y2}`;
    },
    validate() {
      const elems = document.getElementsByClassName("mydiv");
      const myDivElements = Array.prototype.filter.call(
        elems,
        (testElement) => testElement.nodeName === "DIV"
      );
      const position = myDivElements.map((el) => {
        const x = parseInt(el.style.left.slice(0, el.style.left.length - 2));
        const y = parseInt(el.style.top.slice(0, el.style.top.length - 2));
        return {
          x,
          y,
          width: VignetteSignatureProperty.width,
          height: VignetteSignatureProperty.height,
          page: this.actualPage,
        };
      });
      this.$store.commit("offer/setNewOffer", {
        ...this.newOffer,
        fileSignaturePosition: [
          ...this.newOffer.fileSignaturePosition,
          { signaturePage: this.actualPage, signaturePositions: position },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.signatories {
  display: flex;
  flex-direction: column;
  z-index: 5;
  width: 100%;
  height: 595px;
  border-radius: 5px;
  border: 1px solid #cfcfdc;
  .title-container {
    display: flex;
    padding: 9px 16px;
    align-items: center;
    gap: 10px;
    height: 42px;
    border-bottom: 1px solid #cfcfdc;
    .signatory-title {
      color: #353542;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .signatories-container {
    height: 61px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    width: 100%;
    border-bottom: 1px solid #cfcfdc;
    overflow-x: auto;
    overflow-y: clip;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #eeeef7;
    }

    &::-webkit-scrollbar-thumb {
      background: #ebebeb;
      border-radius: 3px;
    }
  }
  .pdf-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: rgba(235, 235, 235, 0.2);
    border-bottom: 1px solid #cfcfdc;
    .pdf-display {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      padding: 0px 40px;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #eeeef7;
      }

      &::-webkit-scrollbar-thumb {
        background: #ebebeb;
        border-radius: 3px;
      }

      .pdf {
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
          0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      }
    }
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
  }
  .pdf-viewer-files {
    background-color: #fff;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cfcfdc;
    overflow-x: auto;
    overflow-y: clip;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #eeeef7;
    }

    &::-webkit-scrollbar-thumb {
      background: #ebebeb;
      border-radius: 3px;
    }
    .files {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 10px 16px;
      border-right: 1px solid #cfcfdc;
      min-width: 33.33%;
      .files-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .file-name {
          color: #707080;
          margin-left: 10px;
          font-size: 12px;
          font-family: Inter;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        ::v-deep .v-icon {
          color: #9bbdff;
        }
      }
    }
  }
  .active-file {
    background: #9bbdff;
    .file-name {
      color: white !important;
    }
    .icon-file ::v-deep .v-icon {
      color: white !important;
    }
  }
  .mydiv {
    position: absolute;
    top: 0px;
    left: 40px;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
  }

  .mydivheader {
    padding: 10px;
    height: 80px;
    width: 160px;
    cursor: move;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2196f3;
    color: #353542;
  }
}
.validate {
  height: 29px;
  width: auto;
  border-radius: 5px;
  background-color: #2196f3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-left: auto;
  cursor: pointer;
}
.btn-validation {
  height: 23px !important;
  width: 79px;
  margin-left: 10px;
  display: flex;
  padding: 4px;
  /* Bouton/Petit */

  align-items: center;
  &::v-deep .v-btn__content {
    padding-bottom: 1px;
  }

  .label {
    color: #707080 !important;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .icon-validation {
    margin-top: -1px;
  }
}
.validate-file {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .file-name {
    color: #2536cc !important;
    margin-left: 10px;
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.information {
  position: relative;
  width: 100%;
  .icon-info {
    cursor: pointer;
  }
  .text-information {
    position: absolute;
    left: 15px;
    top: -25px;
    height: 35px;
    width: auto;
    padding: 9px 16px;
    border-radius: 5px;
    border: 1px solid #eeeef7;
    background: #fff;
    color: #353542;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    z-index: 100;
    display: flex;
    align-items: center;
  }
}
</style>
