var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{attrs:{"modal-title":_vm.$t('folder.askInformation')},on:{"close":_vm.reset},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('div',{staticClass:"modal_content"},[(_vm.campaignList)?_c('BaseSelect',{attrs:{"items":_vm.campaignList,"label":((_vm.$t('folder.askInformationForm.courseChoise')) + " *"),"error-messages":_vm.campaignErrors,"item-text":"name","item-value":"id","solo":"","loading":_vm.isFetchingCampaign,"need-selection":""},on:{"change":_vm.onSelectCampaign},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-space-between select-content"},[_c('base-paragraph',{domProps:{"textContent":_vm._s(item.name)}}),(item.course.anonymous)?_c('base-paragraph',{domProps:{"textContent":_vm._s('( Sans compte )')}}):_vm._e()],1)]}}],null,false,2488382293),model:{value:(_vm.formData.campaign),callback:function ($$v) {_vm.$set(_vm.formData, "campaign", $$v)},expression:"formData.campaign"}}):_vm._e(),_c('v-combobox',{staticClass:"email_input elevation-0",attrs:{"items":_vm.formatedSignatories,"filter":_vm.filterSignatory,"search-input":_vm.queryText,"chips":"","dense":"","item-text":"email","item-value":"email","disabled":_vm.emailDisabled,"clearable":"","loading":_vm.fetchingsSuggestedSignatories,"label":_vm.$t('folder.askInformationForm.email'),"outlined":"","error-messages":_vm.emailErrors},on:{"update:searchInput":function($event){_vm.queryText=$event},"update:search-input":function($event){_vm.queryText=$event},"change":_vm.emailChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"signatory_item"},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":item.icon}}),_c('div',{staticClass:"email",domProps:{"textContent":_vm._s(item.email)}}),(item.lastName || item.firstname)?_c('div',{staticClass:"separator",domProps:{"textContent":_vm._s('-')}}):_vm._e(),(item.lastName)?_c('div',{staticClass:"name",domProps:{"textContent":_vm._s(item.lastName)}}):_vm._e(),(item.firstName)?_c('div',{staticClass:"firstname",domProps:{"textContent":_vm._s(item.firstName)}}):_vm._e()],1)]}}]),model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('BaseMarkdownEditor',{attrs:{"name":"input-7-1","label":_vm.$t('folder.askInformationForm.contentLabel'),"hint":_vm.$t('folder.askInformationForm.contentHint'),"with-link":false},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"type":"primary","color":"primary","loading":_vm.isSubmiting,"disabled":_vm.$v.formData.$invalid},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.sendInvitation"))+" ")])]},proxy:true}]),model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}}),_c('folder-confirm-assign-dialog',{attrs:{"is-open":_vm.openConfirmModal,"confirm-email":_vm.formData.email},on:{"on-validate":_vm.submit,"close":function () {
        _vm.openConfirmModal = false;
        _vm.isSubmiting = false;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }