<template>
  <div class="d-flex flex-column" :class="{ 'error-input': hasError }">
    <div class="select-pad-container">
      <div
        v-for="(select, i) in selectItems"
        :key="i"
        class="select-item"
        :class="{
          'with-border': i !== 0,
          'selected-item': selectedItem === i,
          'is-disabled': isDisabled(i),
        }"
        @click.prevent="onSelect(select.value, i)"
      >
        <BaseIcon small :icon="select.icon" class="mr-3" color="#CFCFDC" />
        <span class="paraph" v-text="select.title" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FolderOfferSelectPad",
  props: {
    selectItems: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "Type de l’offre*",
    },
    hasError: {
      type: Boolean,
    },
    defaultValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  watch: {
    defaultValue: {
      handler(newVal) {
        this.selectedItem = this.defaultValue || newVal;
      },
      immediate: true,
    },
  },
  methods: {
    onSelect(value, i) {
      if (this.disabled && i !== 2) return;
      this.selectedItem = i;
      this.$emit("selected", value);
    },
    isDisabled(item) {
      return this.disabled && item !== 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707080;
}

.select-pad-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 42px;
  display: flex;
  border: 1px solid gray;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border-radius: 5px;
  color: #cfcfdc;
  overflow: hidden;
  .select-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      color: #353542;
      ::v-deep .v-icon__svg {
        color: #353542;
      }
    }
  }
  .is-disabled {
    cursor: grab;
    &:hover {
      background: #fff;
      color: #cfcfdc;
      ::v-deep .v-icon__svg {
        color: #cfcfdc;
      }
    }
  }
  .with-border {
    border-left: 1px solid gray;
  }
  .selected-item {
    color: #2536cc !important;
    background: #f5f8ff !important;
    ::v-deep .v-icon__svg {
      color: #2536cc !important;
    }
  }
}
.error-input {
  .select-label {
    color: #ff5267;
  }
  .select-pad-container {
    box-shadow: 1px -1px 5px 5px #ffc6c6;
    border: 2px solid #ff5267;
    border-radius: 5px;
    .with-border {
      border-left: 2px solid #ff5267;
    }
  }
}
.paraph {
}
</style>
