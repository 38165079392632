<template>
  <BaseDialog
    max-width="800"
    :persistent="isSubmiting"
    :value="dialogState"
    @click:outside="reset()"
    @keydown.esc="reset"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle
              v-text="
                signatory ? $t('utils.editSignatory') : $t('utils.addSignatory')
              "
            />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div class="input_group">
            <div>
              <div class="label" v-text="`${$tc('utils.email', 1)} *`" />
              <v-text-field
                v-model="form.email"
                :placeholder="$tc('utils.email', 1)"
                :error-messages="emailErrors"
                outlined
              />
            </div>
            <div>
              <div class="label" v-text="`${$tc('utils.phoneNumber', 1)} *`" />
              <v-text-field
                v-model="form.phoneNumber"
                :error-messages="phoneNumberErrors"
                placeholder="0612345678"
                outlined
              />
            </div>
          </div>
          <div class="input_group">
            <div>
              <div class="label" v-text="`${$t('utils.lastName')} *`" />
              <v-text-field
                v-model="form.lastName"
                :error-messages="lastNameErrors"
                :placeholder="$t('utils.lastName')"
                outlined
              />
            </div>
            <div>
              <div class="label" v-text="`${$t('utils.firstName')} *`" />
              <v-text-field
                v-model="form.firstName"
                :error-messages="firstNameErrors"
                :placeholder="$t('utils.firstName')"
                outlined
              />
            </div>
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="save()"
          >
            {{ $t("utils.save") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { Signatory } from "@/core/Models/Signatory";

export default {
  name: "SignatoryCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    dialogState: {
      type: Boolean,
    },
    signatory: {
      type: Signatory,
      default: () => null,
    },
  },
  data() {
    return {
      isSubmiting: false,
      form: {
        email: "",
        phoneNumber: "",
        lastName: "",
        firstName: "",
      },
    };
  },
  computed: {
    emailErrors() {
      if (!this.$v.form.email.$dirty) {
        return "";
      }
      if (!this.$v.form.email.required) {
        return this.$t("utils.emailRequired");
      }
      if (!this.$v.form.email.email) {
        return this.$t("utils.emailError");
      }
      return "";
    },
    phoneNumberErrors() {
      if (!this.$v.form.phoneNumber.$dirty) {
        return "";
      }
      if (!this.$v.form.phoneNumber.required) {
        return this.$t("utils.phoneNumberRequired");
      }
      if (!this.$v.form.phoneNumber.phoneNumber) {
        return this.$t("utils.phoneNumberError");
      }
      return "";
    },
    lastNameErrors() {
      if (!this.$v.form.lastName.$dirty) {
        return "";
      }
      if (!this.$v.form.lastName.required) {
        return this.$t("utils.lastNameRequired");
      }
      if (!this.$v.form.lastName.maxLength) {
        return this.$t("utils.lastNameMaxLength");
      }
      return "";
    },
    firstNameErrors() {
      if (!this.$v.form.firstName.$dirty) {
        return "";
      }
      if (!this.$v.form.firstName.required) {
        return this.$t("utils.firstNameRequired");
      }
      if (!this.$v.form.firstName.maxLength) {
        return this.$t("utils.firstNameMaxLength");
      }
      return "";
    },
  },
  validations: {
    form: {
      email: {
        required,
        maxLength: maxLength(255),
        email,
      },
      phoneNumber: {
        required,
        phoneNumber: (value) => {
          return /^0+[0-9]{9}$/.test(value);
        },
      },
      lastName: {
        required,
        maxLength: maxLength(255),
      },
      firstName: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  watch: {
    dialogState(val) {
      if (val && this.signatory) this.initForm();
    },
  },
  methods: {
    reset() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.form = {
        email: "",
        phoneNumber: "",
        lastName: "",
        firstName: "",
      };
      this.$v.form.$reset();
    },
    initForm() {
      this.form.email = this.signatory.email;
      this.form.phoneNumber = this.signatory.phoneNumber;
      this.form.lastName = this.signatory.lastName;
      this.form.firstName = this.signatory.firstName;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmiting = true;
      try {
        if (this.signatory?.id) {
          await this.editSignatory();
        } else {
          await this.createSignatory();
        }
        this.resetForm();
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
    },
    async createSignatory() {
      let res = await DashboardAPI.postUserSignatory({
        email: this.form.email,
        phone_number: this.form.phoneNumber.replace("0", "+33"),
        last_name: this.form.lastName,
        first_name: this.form.firstName,
      });
      this.$emit("signatoryAdded", new Signatory({ ...res.data }));
      this.$store.dispatch("snackbar/active", {
        message: this.$t("utils.snackbar.createSuccess"),
        type: "SUCCESS",
      });
    },
    async editSignatory() {
      let res = await DashboardAPI.patchUserSignatory({
        signatoryId: this.signatory.id,
        payload: {
          email: this.form.email,
          phone_number: this.form.phoneNumber.replace("0", "+33"),
          last_name: this.form.lastName,
          first_name: this.form.firstName,
        },
      });
      this.$emit("signatoryEdited", new Signatory({ ...res.data }));
      this.$store.dispatch("snackbar/active", {
        message: this.$t("utils.snackbar.editSuccess"),
        type: "SUCCESS",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .input_group {
    display: flex;
    & > * {
      flex: 1;
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
    .label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
      color: #707080;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    margin-left: 16px;
  }
}
</style>
