import recordAPI from "../Services/record.api";
import impersonaApi from "../Services/impersona.api";
import folderAPI from "../Services/folder.api";
import campaignAPI from "../Services/campaign.api";

import Record from "@/core/Models/Record";
import Question from "@/core/Models/Question";

import WalletFolders from "@/core/Models/WalletFolder";
import Offer from "@/core/Models/Offer";
import offerApi from "@/modules/Folder/Services/offer.api";

export default {
  namespaced: true,
  state: {
    recordsNotCompleted: [],
    activeQuestion: null,
    recordList: [],
    activeCampaignAccess: null,
    walletFolders: [],
    activeWalletFolder: null,
    folderOffers: [],
    folderRecords: [],
    isFetchingFolderRecords: true,
    isFetchingFolderOffers: true,
    stepId: null,
    modals: {
      isFolderDialogOpen: false,
    },
  },
  mutations: {
    // record not completed fetch
    setRecordsNotCompleted(state, payload) {
      state.recordsNotCompleted = payload;
    },
    //#endregion
    //#region Question
    setActiveQuestion(state, payload) {
      state.activeQuestion = payload ? new Question(payload) : null;
    },
    setIsFolderDialogOpen(state, payload) {
      state.modals.isFolderDialogOpen = payload;
    },
    //#endregion

    // #region folders
    setWalletFolders(state, payload) {
      state.walletFolders = payload
        ? payload.map((walletFolder) => new WalletFolders(walletFolder))
        : [];
    },
    setActiveWalletFolder(state, payload) {
      state.activeWalletFolder = payload ? new WalletFolders(payload) : null;
    },

    setFolderOffers(state, payload) {
      state.folderOffers = payload
        ? payload.map((offer) => new Offer(offer))
        : [];
    },
    setFolderOffersPatched(state, { status, offerId }) {
      let offerToPatch = state.folderOffers.find(
        (offer) => offer.id === offerId
      );
      offerToPatch.clients.status = status;
      state.folderOffers.splice(
        state.folderOffers.findIndex((offer) => offer.id === offerId),
        1,
        new Offer(offerToPatch)
      );
    },
    setFolderRecords(state, payload) {
      state.folderRecords = payload
        ? payload.map((course) => new Record(course))
        : [];
    },
    setIsFetchingFolderRecords(state, payload) {
      state.isFetchingFolderRecords = payload;
    },
    setIsFetchingFolderOffers(state, payload) {
      state.isFetchingFolderOffers = payload;
    },
    // region capaign
    setActiveCampaignAccess(state, payload) {
      state.activeCampaignAccess = payload;
    },
  },
  actions: {
    async postImpersona(__, payload) {
      return await impersonaApi.postImpersona(payload);
    },
    //#region Record Actions
    async fetchRecordList({ commit, state }, recordStatus) {
      commit("setRecordList", []);
      let page = 1;
      do {
        const res = await recordAPI.getRecords(recordStatus, page);
        const recordList = res.data.results;
        page = res.data.next;
        commit("setRecordList", [...state.recordList, ...recordList]);
      } while (page);
      return state.recordList;
    },
    // get record not completed
    async fetchRecordsNotCompleted({ commit, state }) {
      let page = 1;
      commit("setRecordsNotCompleted", []);
      do {
        const res = await recordAPI.getRecordNotCompleted(page);
        const recordsNotCompletedPage = res.data.results;
        commit("setRecordsNotCompleted", [
          ...state.recordsNotCompleted,
          ...recordsNotCompletedPage,
        ]);
        page = res.data.next;
      } while (page);
      return state.recordsNotCompleted;
    },
    async getWalletFolder({ commit }, { organizationId, page }) {
      commit("setWalletFolders", []);
      // wallet client owner of the folder
      const responseClientOwner = await folderAPI.getWalletUserFolders({
        organizationId,
        page,
      });
      let next = responseClientOwner.next;
      let results = [...responseClientOwner.data.results];
      while (next != null) {
        let newResponse = await folderAPI.getWalletUserFolders({
          organizationId,
          page: next,
        });
        next = newResponse.next;
        results = [...results, ...newResponse.next];
      }

      // wallet client extra folder

      const responseClientExtraOwner = await folderAPI.getWalletUserFolders({
        organizationId,
        page,
        isExtraClient: true,
      });
      next = responseClientExtraOwner.next;
      results = [...results, ...responseClientExtraOwner.data.results];
      while (next != null) {
        let newResponse = await folderAPI.getWalletUserFolders({
          organizationId,
          page: next,
          isExtraClient: true,
        });
        next = newResponse.next;
        results = [...results, ...newResponse.next];
      }
      commit("setWalletFolders", results);
    },
    async getWalletOffer({ commit }, { organizationId, folderId, page }) {
      commit("setIsFetchingFolderOffers", true);
      commit("setFolderOffers", []);
      const response = await folderAPI.getOfferByFolderId({
        organizationId,
        folderId,
        page,
      });
      let next = response.next;
      let results = [...response.data.results];
      while (next != null) {
        let newResponse = await folderAPI.getOfferByFolderId({
          organizationId,
          folderId,
          page: next,
        });
        next = newResponse.next;
        results = [...newResponse.data.results];
      }

      commit("setFolderOffers", results);
      commit("setIsFetchingFolderOffers", false);
    },
    async patchOfferStatus(
      { commit },
      { organizationId, folderId, offerId, offerClientId, payload, status }
    ) {
      await offerApi.patchOfferStatus({
        organizationId,
        folderId,
        offerId,
        offerClientId,
        payload,
      });
      commit("setFolderOffersPatched", { offerId, status });
    },
    async getWalletRecords({ commit }, { folderId, page }) {
      commit("setIsFetchingFolderRecords", true);
      commit("setFolderRecords", []);
      const response = await folderAPI.getRecordByFolderId({
        folderId,
        page,
      });
      let next = response.next;
      let results = [...response.data.results];
      while (next != null) {
        let newResponse = await folderAPI.getRecordByFolderId({
          folderId,
          page: next,
        });
        next = newResponse.next;
        results = [...newResponse.next];
      }
      commit("setFolderRecords", results);
      commit("setIsFetchingFolderRecords", false);
    },
    // region campaign
    async getAccessCampaign({ commit }, { campaignSlug, recordId, sc }) {
      const response = await campaignAPI.getCampaignAccess(
        campaignSlug,
        recordId,
        sc
      );
      commit("setActiveCampaignAccess", response.data);
      return response;
    },
  },
  getters: {
    getListRecordNotCompleted: (state) => state.recordNotCompleted,
    isFetchingRecords: (state) => state.isRecordNotCompletedFetching,
  },
};
