<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('folder.workflow.deletStatusConfirmation')"
    @click:outside="close"
    @keydown.esc="close"
    @close="close"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="$t('folder.workflow.deletStatusConfirmation')" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="close"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WorkflowDeleteStatusDialog",
  computed: {
    ...mapState({
      activeStatus: (state) => state.workflow.activeStatus,
      dialogState: (state) => state.workflow.modals.isDeleteStatusDialogOpen,
    }),
  },
  methods: {
    close() {
      this.$store.commit("workflow/setModalDeleteStatusDialog", false);
      this.$store.commit("workflow/setActiveStatus", null);
    },
    async submit() {
      try {
        await this.$store.dispatch("workflow/deleteWorkflowStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.success.statusDeletedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.status.snackbar.error.errorDeletingStatus"
          ),
          type: "ERROR",
        });
      }
      this.close();
    },
  },
};
</script>
