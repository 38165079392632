<template>
  <BaseModal
    v-if="record"
    :value="dialogState"
    :modal-title="
      $t('folder.invalidateMultipleForm.title', {
        title: $store.state.folder.activeFolder.name,
      })
    "
    @close="reset"
  >
    <template #modal>
      <form @submit.prevent>
        <BaseParagraph
          type="primary"
          v-text="$t('folder.invalidateMultipleForm.questions')"
        />
        <div v-if="questions" class="cosmo-fields-container">
          <div
            v-for="(group, i) in categorisedQuestions"
            :key="`g-${group.name + 1}${i}`"
            class="my-2"
          >
            <div v-if="group.questions.length" class="question_category">
              {{ group.name }}
            </div>
            <div class="question_list">
              <div
                v-for="question in group.questions"
                :key="`gq-${question.id + 1}${i}`"
                class="question_item"
              >
                <div class="name">
                  {{ question.name }}
                </div>

                <div class="ml-2">
                  <BaseButtonIcon
                    icon="$mdiClose"
                    color="primary"
                    @click="unSelectQuestion(question.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="record.client" class="title-container">
          <span
            class="text sub-text"
            v-text="`${$tc('utils.recipient', 3)} : `"
          />
          <span
            v-if="record && record.client.email"
            class="text"
            v-text="record.client.email"
          />
        </div>
        <BaseMarkdownEditor
          v-model="formData.content"
          :placeholder="'Corps du mail'"
          :with-link="false"
          @change="$v.formData.content.$touch()"
          @blur="$v.formData.content.$touch()"
        />
      </form>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :disabled="$v.formData.$invalid || !record.client"
        type="primary"
        :loading="isSubmiting"
        @click="submit()"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "FolderSendInvalidateAnswersDialog",

  mixins: [validationMixin],
  props: {
    dialogState: {
      type: Boolean,
    },
    record: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      formData: {
        content: "",
      },
      checkedQuestions: [],
    };
  },
  validations: {
    formData: {
      content: {
        required,
      },
    },
  },

  computed: {
    contentErrors() {
      if (!this.$v.formData.content.$dirty) {
        return "";
      }
      if (!this.$v.formData.content.required) {
        return this.$t(
          "folder.invalidateMultipleForm.validation.contentRequired"
        );
      }
      return "";
    },
    categorisedQuestions() {
      return [
        {
          name: "",
          questions: this.checkedQuestions.filter(
            (question) => question.datatype === ""
          ),
        },
        {
          name: "Données personnelles",
          questions: this.checkedQuestions.filter(
            (question) => !!question.datatype
          ),
        },
      ];
    },
  },
  watch: {
    questions: {
      handler() {
        if (!this.questions.length) return;
        this.checkedQuestions = this.questions.filter(
          (question) => question.typeQuestion !== "MESSAGE"
        );
      },
      deep: true,
    },
  },
  methods: {
    reset() {
      this.$v.formData.$reset();
      this.isSubmiting = false;
      this.formData.content = "";
      this.$emit("close");
    },
    unSelectQuestion(idQuestion) {
      this.checkedQuestions = this.checkedQuestions.filter(
        (question) => question.id !== idQuestion
      );
      if (!this.checkedQuestions.length) return this.reset();
    },
    async submit() {
      this.isSubmiting = true;
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        try {
          await this.$store.dispatch("folder/invalidateRecordAnswers", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            recordId: this.record.id,
            questions: this.checkedQuestions,
            payload: {
              answers: [...this.questions.map((q) => q.answer.id)],
              message: this.formData.content,
            },
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.invalidateMultipleForm.success"),
            type: "SUCCESS",
          });
        } catch (error) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.invalidateMultipleForm.error"),
            type: "ERROR",
          });
        }
        this.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  margin-bottom: 16px;
}
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
  &.text_danger {
    color: #ff5267;
  }
}
.sub-text {
  font-weight: 600;
}
.question_category {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #707080;
}
.cosmo-fields-container {
  border: 2px solid #cfcfdc;
  border-radius: 5px;
  padding: 8px;
  margin: 24px 0;
}
.title {
  margin-bottom: 24px;
}
.question_list {
  display: flex;
  flex-wrap: wrap;

  .question_item {
    border: 1px solid #2536cc;
    margin: 8px;
    height: 33px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .name {
      margin: 0 5px;
      font-size: 16px;
      line-height: 18px;
      color: #2536cc;
      max-height: 36px;
      text-align: center;
      overflow: hidden;
    }
  }
}
</style>
