<template>
  <div id="pdf" ref="pdf-to-print" class="folder-full-data-view">
    <div v-if="isLoadingData" class="d-flex flex-column loader-content my">
      <v-skeleton-loader
        v-if="isLoadingData"
        class="loader"
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
      <v-skeleton-loader
        v-if="isLoadingData"
        class="loader"
        type="table-heading, image, table-tfoot"
      />
    </div>
    <div v-else class="data-container">
      <FullDataCardHeader
        :client="client"
        :folder="folder"
        @download-data="printPdf"
        @download-zip="downloadZipFiles"
      />
      <FullDataCardContent
        v-if="filteredStatus.length"
        class="my-3"
        :cart-title="$t('folder.clientFullData.folderInfo')"
        :data="filteredStatus"
        :folder="folder"
        folder-info
      />
      <FullDataCardContent
        v-if="records.length"
        :cart-title="$t('folder.clientFullData.collectedInfo')"
        :data="records"
        :folder="folder"
        client-collect
      />
      <FullDataCardContent
        v-if="offers.length"
        class="my-3"
        :cart-title="$t('folder.clientFullData.offerInfo')"
        :folder="folder"
        :data="offers"
        offer-info
      />
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FullDataCardHeader from "../Components/FullDataCardHeader.vue";
import FullDataCardContent from "../Components/FullDataCardContent.vue";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";

import { getClientFullData } from "../../Folder/Services/folder.service";
import { getFolderFiles } from "@/modules/Folder/Services/folder.service";

export default {
  name: "ClientFullDataView",
  components: { FullDataCardHeader, FullDataCardContent },
  props: {
    organizationID: {
      type: [Number, String],
      required: true,
    },
    folderID: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isLoadingData: true,
      folderFiles: [],
      statuses: [],
      offers: [],
      records: [],
      client: {},
    };
  },
  computed: {
    filteredStatus() {
      if (!this.statuses?.length) return [];
      this.statuses.forEach((status) => {
        status.questions = status.questions.sort((a, b) => {
          if (!a.category) return -1;
          if (!b.category) return 1;
          return a.category.id - b.category.id;
        });
      });
      return this.statuses.filter((s) => s.questions.length > 0);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isLoadingData = true;
      try {
        const res = await getClientFullData(this.organizationID, this.folderID);
        this.folderFiles = (
          await getFolderFiles(this.organizationID, this.folderID)
        ).data;

        const { statuses, records, offers, client, id, name, dateCreated } =
          res && res.data;
        this.folder = { id, name, dateCreated };
        this.client = client;
        this.statuses = statuses;
        this.records = records;
        this.offers = offers;

        this.isLoadingData = false;
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
    },
    async downloadZipFiles() {
      var zip = new JSZip();
      for (const file of this.folderFiles.filter((f) => f.url)) {
        const binaryFile = await JSZipUtils.getBinaryContent(file.url);
        zip.file(file.path, binaryFile, { binary: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "folderData.zip");
      });
    },
    async printPdf() {
      this.$nextTick(async () => {
        this.$refs["pdf-to-print"].style.backgroundColor = "#FFF";
        const source = document.querySelector("#pdf");
        const top_left_margin = 15;
        const PDF_Width = source.clientWidth + top_left_margin * 2;
        const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
        const canvas_image_width = source.clientWidth;
        const canvas_image_height = source.clientHeight;
        const totalPDFPages = Math.ceil(source.clientHeight / PDF_Height) - 1;
        const canvas = await html2canvas(source, { allowTaint: true });
        canvas.getContext("2d");
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );

        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage();
          pdf.addImage(
            imgData,
            "JPG",
            top_left_margin,
            -(PDF_Height * +i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }
        pdf.save("fullData.pdf");
        this.$refs["pdf-to-print"].style.backgroundColor = "#f4f7ff";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-full-data-view {
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  background-color: #f4f7ff;
  .data-container {
    width: 90%;
    margin-top: 28px;
    .logo-container {
      width: 75px;
      height: 50px;
    }
  }
}
.loader-content {
  width: 90vw;
  height: 100vh;
}
.loader {
  margin-top: 28px;
}
</style>
