<template>
  <v-flex v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-flex>
</template>

<script>
export default {
  name: "BaseLayout",
};
</script>
