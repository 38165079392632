var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bo_title mb-6",domProps:{"textContent":_vm._s(_vm.$t('client.list'))}}),_c('Board',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){return _vm.$store.commit('client/setDisplayClientDialog', true)}}},[_vm._v(" "+_vm._s(_vm.$t("client.create"))+" ")]),_c('div',[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"outlined":"","label":_vm.$t('client.search'),"prepend-inner-icon":"$mdiMagnify","dense":"","hide-details":""},on:{"input":_vm.onSearch},model:{value:(_vm.searchClientField),callback:function ($$v) {_vm.searchClientField=$$v},expression:"searchClientField"}})],1)],1)]},proxy:true},{key:"main",fn:function(){return [_c('div',[_c('BaseDataTable',{attrs:{"items":_vm.clientsFiltered,"headers":_vm.CLIENTS_HEADER,"loading":_vm.isFetchingClients,"loading-text":_vm.$t('utils.loadingData'),"sort-by":"dateCreated","sort-desc":"","must-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"row--link",on:{"click":function($event){return _vm.$router.push({
                  name: 'clientDetail',
                  params: {
                    organizationId: _vm.$route.params.organizationId,
                    clientId: item.id,
                  },
                })}}},[_c('td',[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(
                    item.fullName ? item.fullName : _vm.$t('answer.noContent')
                  )}})]),_c('td',[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(item.email ? item.email : _vm.$t('answer.noContent'))}})]),_c('td',[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(
                    item.societyName
                      ? item.societyName
                      : _vm.$t('answer.noContent')
                  )}})]),_c('td',{staticClass:"text-end"},[_c('BaseChip',{attrs:{"type":_vm.statusType(item),"color":_vm.statusType(item)}},[_c('span',{staticClass:"text_sm",domProps:{"textContent":_vm._s(
                      ((item.recordsAgreeCount) + "/" + (item.recordsTotalCount))
                    )}})])],1)])]}}])}),(_vm.clientsCount > 10)?_c('div',{staticClass:"pagination"},[_c('v-pagination',{attrs:{"total-visible":_vm.filters.pageSize,"length":_vm.clientPages},on:{"input":_vm.changeFilters},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1):_vm._e()],1)]},proxy:true}])}),(_vm.clients)?_c('BaseDescription',{staticClass:"mt-6",domProps:{"textContent":_vm._s((_vm.clientsCount + " contact" + (_vm.clientsCount > 1 ? 's' : '')))}}):_vm._e(),_c('ClientCreateDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }