import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getOrganization(organizationId) {
    return cosmoAPI.get(`/wallet/organization/${organizationId}/`);
  },
  getOrganizations(page = 1) {
    return cosmoAPI.get("/wallet/organization/", {
      params: {
        page,
      },
    });
  },
};
