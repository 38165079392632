<template>
  <BaseModal
    :value="dialogState"
    :modal-title="`Supprimer la catégorie ${
      statusCategory && statusCategory.name
    } ?`"
    @close="close"
  >
    <template #modal>
      <div class="text-center">
        <div
          class="alert_danger"
          v-text="
            `Cette action ne pourra pas être
          annulée. Tous les champs assigné à cette catégorie seront désormais
          sans catégorie .`
          "
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        class="mx-2 ml-auto"
        type="secondary"
        text
        color="#707080"
        :loading="isSubmiting"
        @click="submit"
      >
        {{ $t("utils.delete") }}
      </BaseButton>
      <BaseButton color="primary" type="primary" @click="close">
        {{ $t("utils.cancel") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WorkflowRemoveStatusCategory",
  data() {
    return {
      isSubmiting: false,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.workflow.modals.isRemoveStatusCategoryOpen,
      statusCategory: (state) => state.workflow.activeStatusCategory,
    }),
  },

  methods: {
    close() {
      this.$store.commit(
        "workflow/SET_REMOVE_STATUS_CATEGORY_MODAL_STATUS",
        false
      );
      this.$store.commit("workflow/SET_ACTIVE_STATUS_CATEGORY", null);
    },
    async submit() {
      this.isSubmiting = true;
      try {
        await this.$store.dispatch("workflow/deleteStatusCategory", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.statusCategory.statusId,
          categoryId: this.statusCategory.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: "Categorie supprimer",
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.alert_danger {
  padding: 0 8px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ff5267;
}
</style>
