import { render, staticRenderFns } from "./CosmoformForm.vue?vue&type=template&id=6d006555&scoped=true"
import script from "./CosmoformForm.vue?vue&type=script&lang=js"
export * from "./CosmoformForm.vue?vue&type=script&lang=js"
import style0 from "./CosmoformForm.vue?vue&type=style&index=0&id=6d006555&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d006555",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VDatePicker,VFileInput,VMenu,VRadio,VRadioGroup,VSpacer,VTextField,VTextarea,VTimePicker})
