<template>
    <v-icon>
      {{ svgPath }}
    </v-icon>
  </template>

  <script>
  import { mdiFileDocument  } from "@mdi/js";
  export default {
    name: "MdiFileDocument",
    data() {
      return {
        svgPath: mdiFileDocument ,
      };
    },
  };
  </script>
