<template>
  <span class="text-paragraph" :class="classObject" v-on="$listeners">
    <slot />
  </span>
</template>

<script>
export default {
  name: "BaseParagraph",
  props: {
    bold: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: () => "primary",
      validator: function (value) {
        return ["primary", "secondary", "disabled"].indexOf(value) !== -1;
      },
    },
    uppercase: {
      type: Boolean,
      default: () => false,
    },
    italic: {
      type: Boolean,
      default: () => false,
    },
    subtitle: {
      type: Boolean,
      default: () => false,
    },
    truncate: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    classObject() {
      return {
        "text-uppercase": this.uppercase,
        [`${this.color ? this.color + "--text" : "text--" + this.text}`]: true,
        "font-italic": this.italic,
        "text-paragraph--bold": this.bold,
        "text-truncate d-block": this.truncate,
        "text-paragraph--subtitle": this.subtitle,
        "text-paragraph--title": this.title,
      };
    },
  },
};
</script>
<style scoped>
.text-paragraph {
  font-size: 0.875rem !important;
  font-weight: 400;
}
.text-paragraph--bold {
  font-weight: 700;
}
.text-paragraph--subtitle {
  font-size: 1rem !important;
}
.text-paragraph--title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #707080;
}
</style>
