import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getUserInvitations() {
    return cosmoAPI.get("/invitation/");
  },
  acceptInvitation(invitationId) {
    return cosmoAPI.post(`/invitation/${invitationId}/accept/`);
  },
  declineInvitation(invitationId) {
    return cosmoAPI.post(`/invitation/${invitationId}/decline/`);
  },
  getUserSignatories() {
    return cosmoAPI.get("/me/signatory/");
  },
  postUserSignatory(payload) {
    return cosmoAPI.post("/me/signatory/", payload);
  },
  patchUserSignatory({ signatoryId, payload }) {
    return cosmoAPI.patch(`/me/signatory/${signatoryId}/`, payload);
  },
  deleteUserSignatory(signatoryId) {
    return cosmoAPI.delete(`/me/signatory/${signatoryId}/`);
  },
  getOrganizationSignatories({ organizationId }) {
    return cosmoAPI.get(`/organization/${organizationId}/signatory/`);
  },
  postOrganizationSignatory({ organizationId, payload }) {
    return cosmoAPI.post(`/organization/${organizationId}/signatory/`, payload);
  },
  patchOrganizationSignatory({ organizationId, signatoryId, payload }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/signatory/${signatoryId}/`,
      payload
    );
  },
  deleteOrganizationSignatory({ organizationId, signatoryId }) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/signatory/${signatoryId}/`
    );
  },
};
