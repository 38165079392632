export function removeDuplicates(items, key) {
  const uniqueKeys = new Set();
  return items.reduce((accumulator, current) => {
    const value = current[key];
    if (!uniqueKeys.has(value)) {
      uniqueKeys.add(value);
      accumulator.push(current);
    }
    return accumulator;
  }, []);
}
