var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(/^FILE|SIGNATURE$/.test(_vm.question.typeQuestion) && _vm.content)?_c('a',{attrs:{"href":_vm.imageContent,"target":"_blank"}},[(_vm.isImage)?_c('BaseFrontOfficeImage',{class:{
        full_image: _vm.fullImage,
        thumb: !_vm.fullImage,
        'mid-size': _vm.midSizeImage,
      },attrs:{"src":_vm.imageContent},on:{"error":function($event){_vm.isImage = false}}}):_c('BaseFrontOfficeParagraph',{attrs:{"bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('answer.seeFolder', { name: _vm.name }))}})],1):(/^MULTIPLE_FILE$/.test(_vm.question.typeQuestion) && _vm.content)?_c('div',{staticClass:"offer_files"},_vm._l((_vm.content),function(file,i){return _c('div',{key:i,staticClass:"offer_file_item"},[_c('div',{staticClass:"file",on:{"click":function($event){$event.stopPropagation();return _vm.openFile(file)}}},[(file.url && file.url.includes('.pdf'))?_c('BasePdfViewer',{attrs:{"pdf":file.url}}):_c('BaseFrontOfficeImage',{attrs:{"src":file.url}})],1),_c('div',{staticClass:"file_name",domProps:{"textContent":_vm._s(file.name)}})])}),0):(
      /^DATAGRID|ROW_DATAGRID$/.test(_vm.question.typeQuestion) && _vm.content
    )?_c('QuestionDatagridPreviwer',{attrs:{"question":_vm.question}}):(/DATE/.test(_vm.question.typeQuestion) && _vm.content)?_c('BaseFrontOfficeParagraph',{attrs:{"truncate":"","bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.content), 'date'))}}):(/DATETIME/.test(_vm.question.typeQuestion) && _vm.content)?_c('BaseFrontOfficeParagraph',{attrs:{"truncate":"","bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.content), 'full'))}}):(
      /TEXT|TEXTEARA|SELECT|RADIOBUTTON|CHECKBOX/.test(
        _vm.question.typeQuestion
      ) && _vm.content
    )?_c('BaseFrontOfficeParagraph',{attrs:{"color":"primary","bold":""},domProps:{"textContent":_vm._s(_vm.content)}}):(
      /NUMBER/.test(_vm.question.typeQuestion) && !isNaN(parseInt(_vm.content, 10))
    )?_c('BaseFrontOfficeParagraph',{attrs:{"color":"primary","bold":""},domProps:{"textContent":_vm._s(_vm.content)}}):_c('BaseFrontOfficeParagraph',{attrs:{"truncate":"","italic":""},domProps:{"textContent":_vm._s(_vm.$t('answer.noContent'))}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }