<template>
  <div>
    <BaseTitle class="mb-6" v-text="$tc('campaign.campaign', 2)" />

    <CampaignDetailHeader
      :is-fetching-active-course="isFetchingActiveCourse"
      :is-fetching-active-campaign="isFetchingActiveCampaign"
    />

    <CampaignDetailRecords
      v-if="activeCampaign && !/^DRAFT$/.test(activeCampaign.status)"
      :is-fetching-active-course="isFetchingActiveCourse"
      :is-fetching-active-campaign="isFetchingActiveCampaign"
    />

    <CampaignCreateAndEditDialog />
    <CampaignDeleteDialog />
    <CampaignInviteDialog v-if="dialogState" />
  </div>
</template>

<script>
import CampaignDetailHeader from "../Components/CampaignDetailHeader";
import CampaignDetailRecords from "../Components/CampaignDetailRecords";
import CampaignCreateAndEditDialog from "../Components/CampaignCreateAndEditDialog";
import CampaignDeleteDialog from "../Components/CampaignDeleteDialog";
import CampaignInviteDialog from "../Components/CampaignInviteDialog";

import { mapState } from "vuex";

export default {
  name: "CampaignDetail",
  components: {
    CampaignDetailHeader,
    CampaignDetailRecords,
    CampaignCreateAndEditDialog,
    CampaignDeleteDialog,
    CampaignInviteDialog,
  },
  data() {
    return {
      isFetchingActiveCourse: false,
      isFetchingActiveCampaign: false,
    };
  },
  computed: {
    ...mapState({
      course: (state) => state.course.course,
      activeCampaign: (state) => state.course.activeCampaign,
      dialogState: (state) => state.course.modals.isCampaignInviteDialogOpen,
    }),
  },
  watch: {
    activeCampaign: {
      handler(newVal, oldVal) {
        if ((!oldVal && newVal) || (newVal && newVal.name !== oldVal.name)) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.$store.dispatch("course/resetCourse");
      this.$store.commit("course/setActiveCampaign", null);
    },
    initialize() {
      this.isFetchingActiveCourse = true;
      this.$store
        .dispatch("course/fetchCourse", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
        })
        .then(() => {
          this.isFetchingActiveCourse = false;
          this.isFetchingActiveCampaign = true;
          this.$store
            .dispatch("course/fetchActiveCampaign", {
              organizationId: this.$route.params.organizationId,
              courseId: this.$route.params.courseId,
              campaignId: this.$route.params.campaignId,
            })
            .then(() => {
              this.isFetchingActiveCampaign = false;
            });
        });
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[1].label = `${this.course.name} `;
      this.$route.meta.breadcrumbs[2].label = `${this.activeCampaign.name} `;
    },
  },
};
</script>
