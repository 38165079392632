<template>
  <div>
    <BaseSnackbar
      :class="statusClass"
      right
      :timeout="snackbar.timeout"
      :value="snackbar.active"
      @input="onClick"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div>
            <BaseFrontOfficeIcon
              v-if="snackbar.type === 'SUCCESS'"
              icon="$mdiCheck"
              color="walletAlertSuccess"
            />
            <BaseFrontOfficeIcon
              v-else-if="snackbar.type === 'WARNING'"
              icon="$mdiHelpCircle"
              color="walletAlertWarning"
            />
            <BaseFrontOfficeIcon
              v-else-if="snackbar.type === 'INFO'"
              icon="$mdiInformation"
              color="walletAlertInfo"
            />
            <BaseFrontOfficeIcon
              v-else
              icon="$mdiClose"
              color="walletAlertError"
            />
          </div>
          <BaseFrontOfficeParagraph class="ml-4" v-text="snackbar.message" />
        </div>
        <BaseFrontOfficeButtonIcon
          v-if="snackbar.needClose"
          icon="$mdiClose"
          type="secondary"
          color="grey"
          @click="onClick"
        />
      </div>
    </BaseSnackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheFrontOfficeSnackbar",
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar,
    }),
    statusClass() {
      switch (this.snackbar.type) {
        case "SUCCESS":
          return "snackbar-success";
        case "WARNING":
          return "snackbar-warning";
        case "INFO":
          return "snackbar-info";
        case "ERROR":
        default:
          return "snackbar-error";
      }
    },
  },
  methods: {
    onClick() {
      this.$store.dispatch("snackbar/reset");
    },
  },
};
</script>

<style scoped>
::v-deep .v-snack__wrapper {
  margin: 20px;
}
::v-deep .snackbar-success .v-snack__wrapper {
  border-radius: 15px;
  border: var(--v-walletAlertSuccess-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
::v-deep .snackbar-warning .v-snack__wrapper {
  border-radius: 15px;
  border: var(--v-walletAlertWarning-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
::v-deep .snackbar-info .v-snack__wrapper {
  border-radius: 15px;
  border: var(--v-walletAlertInfo-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
::v-deep .snackbar-error .v-snack__wrapper {
  border-radius: 15px;
  border: var(--v-walletAlertError-base) solid 2px !important;
  background-color: var(--v-walletGrey-lighten5);
}
</style>
