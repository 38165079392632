<template>
  <div>
    <div v-if="showTitle">
      <div class="mb-6">
        <BaseTitle v-text="$t('navigation.myAccount')" />
      </div>
      <router-view />
    </div>
    <div v-else>
      <WalletAuth />
    </div>
  </div>
</template>

<script>
import WalletAuth from "@/modules/Wallet/Pages/Auth/WalletAuth.vue";

export default {
  name: "Auth",
  components: {
    WalletAuth,
  },
  computed: {
    showTitle() {
      const path = this.$route.fullPath;
      return (
        path.indexOf("/auth/login") == -1 &&
        path.indexOf("/auth/signup") == -1 &&
        path.indexOf("/auth/password/reset") == -1 &&
        path.indexOf("/auth/password/reset/done") == -1 &&
        path.indexOf("/auth/password/reset/confirm") == -1 &&
        path.indexOf("/auth/password/reset/complete") == -1
      );
    },
  },
};
</script>
