<template>
  <BaseModal
    :value="dialogState"
    is-unique
    :modal-title="$tc('folder.workflow.createWorkflow', isCreateForm ? 1 : 2)"
    @close="reset"
  >
    <template #modal>
      <v-form ref="form" @submit.prevent="submit">
        <BaseTextField
          v-model="form.name"
          autofocus
          :placeholder="$tc('folder.workflow.newWorkflowLabel')"
          :error-messages="nameErrors"
          :has-error="!!nameErrors"
          @change="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
      </v-form>
    </template>

    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click="submit">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "WorkflowCreateAndEditDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) =>
        state.workflow.modals.isCreateAndEditWorkflowDialogOpen,
      activeWorkflow: (state) => state.workflow.activeWorkflow,
    }),
    isCreateForm() {
      return this.$store.state.workflow.activeWorkflow == null;
    },
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return "Vous devez saisir un nom de workflow";
      }
      if (!this.$v.form.name.maxLength) {
        return "le nom du workflow ne doit pas dépasser 255 caractères";
      }
      return "";
    },
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  watch: {
    dialogState(newVal) {
      if (newVal && this.activeWorkflow) {
        this.form.name = this.activeWorkflow.name;
      }
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.$v.$reset();
      this.$store.commit("workflow/setModalCreateAndEditWorkflowDialog", false);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isCreateForm ? await this.create() : await this.edit();
    },
    async create() {
      try {
        const payload = {
          name: this.form.name,
        };
        const res = await this.$store.dispatch("workflow/createWorkflow", {
          organizationId: this.$route.params.organizationId,
          payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.snackbar.success.workflowCreatedSuccessfully"
          ),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "workflowDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            workflowId: `${res.id}`,
          },
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("workflow.snackbar.success.errorCreatingWorkflow"),
          type: "ERROR",
        });
      }
      this.reset();
    },
    async edit() {
      try {
        const payload = {
          name: this.form.name,
        };
        await this.$store.dispatch("workflow/updateWorkflow", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          payload,
        });

        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.snackbar.success.workflowModifiedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("workflow.snackbar.success.errorModifyingWorkflow"),
          type: "ERROR",
        });
      }
      this.reset();
    },
  },
};
</script>
