<template>
  <v-icon v-bind="$attrs" v-on="$listeners">
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiCloseBox } from "@mdi/js";
export default {
  name: "MdiCheck",
  data() {
    return {
      svgPath: mdiCloseBox,
    };
  },
};
</script>
