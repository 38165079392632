<template>
  <BaseModal
    :max-width="800"
    :value="dialogState"
    :modal-title="$t('utils.cancelOffer.title')"
    @click:outside="reset()"
    @close="reset()"
  >
    <template #modal>
      <div class="modal_content">
        <template>
          <div class="text-warning">
            {{ $t("utils.cancelOffer.warningCancelConsulOffer") }}
          </div>
          <div class="text-title">
            {{ $t("utils.cancelOffer.cancelReason") }}
          </div>
          <BaseTextField v-model="reason" :label="$t('utils.description')" />
        </template>
      </div>
    </template>
    <template #actions>
      <BaseButton
        :disabled="isSubmiting"
        class="mx-4 ml-auto"
        color="grey"
        type="secondary"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :loading="isSubmiting"
        color="secondary"
        type="secondary"
        @click="submit"
      >
        {{ $t("utils.confirm") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import offerApi from "../../Services/offer.api";

export default {
  name: "FolderOfferConsultCancelDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
    offerId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      reason: "",
      isSubmiting: false,
    };
  },
  methods: {
    reset() {
      this.$emit("close");
    },
    async submit() {
      try {
        this.isSubmiting = true;
        await offerApi.postOrganizationFolderOfferCancel(
          this.organizationId,
          this.folderId,
          this.offerId,
          { reason: this.reason }
        );
        await this.$store.dispatch("folder/getOrganizationOffersRecordFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.offerStatus.offerConsultCanceled"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-warning {
  color: var(--Principale-Gris-Noir, #353542);
  text-align: center;
  /* Texte/Paragraphe */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 24px 16px;
}
.text-title {
  margin: 24px 0;
  color: var(--Principale-Gris-Noir, #353542);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
