<template>
  <div class="extracted__data">
    <div
      v-for="(siren, index) in extractedValues"
      :key="`${siren.key}-${index}`"
      class="section"
    >
      <div class="section__title">{{ siren.key }}</div>
      <div class="extracted__items">
        <div v-for="(item, i) in siren.value" :key="i" class="extracted__item">
          <div class="extracted__label">{{ item.key }}</div>
          <div v-if="item.value" class="extracted__value">{{ item.value }}</div>
          <div v-else class="no__content">{{ $t("utils.noContent") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkflowExtractedDataFields",
  props: {
    extractedData: {
      type: Object,
      default: () => {},
    },
    question: {
      type: Object,
      default: () => {},
    },
    personalData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    extractedValues() {
      if (this.personalData) {
        return this.personalData.datatype.name === "SIREN"
          ? this.sirenCategories
          : this.siretCategories;
      }
      return this.question.name === "SIREN"
        ? this.sirenCategories
        : this.siretCategories;
    },
    siretCategories() {
      const establishment = [
        {
          key: this.$t("siret.key.dateCreatedEstablishment"),
          value: this.extractedData.establishment.dateCreatedEstablishment,
        },
        {
          key: this.$t("siret.key.dateLastProcessingEstablishment"),
          value:
            this.extractedData.establishment.dateLastProcessingEstablishment,
        },
        {
          key: this.$t("siret.key.hasHeadquarters"),
          value: this.extractedData.establishment.hasHeadquarters,
        },
        {
          key: this.$t("siret.key.nic"),
          value: this.extractedData.establishment.nic,
        },
        {
          key: this.$t("siret.key.siren"),
          value: this.extractedData.establishment.siren,
        },
        {
          key: this.$t("siret.key.siret"),
          value: this.extractedData.establishment.siret,
        },
      ];
      const establishmentAddress = [
        {
          key: this.$t("siret.key.communeLabel"),
          value: this.extractedData.establishmentAddress.communeLabel,
        },
        {
          key: this.$t("siret.key.communeLabelForeign"),
          value: this.extractedData.establishmentAddress.communeLabelForeign,
        },
        {
          key: this.$t("siret.key.coordinatesAbs"),
          value: this.extractedData.establishmentAddress.coordinatesAbs,
        },
        {
          key: this.$t("siret.key.coordinatesOrd"),
          value: this.extractedData.establishmentAddress.coordinatesOrd,
        },
        {
          key: this.$t("siret.key.countryLabelForeign"),
          value: this.extractedData.establishmentAddress.countryLabelForeign,
        },
        {
          key: this.$t("siret.key.siret"),
          value: this.extractedData.establishmentAddress.siret,
        },
      ];

      const establishmentPeriod = [
        {
          key: this.$t("siret.key.administrativeStatusEstablishment"),
          value:
            this.extractedData.establishmentPeriod
              .administrativeStatusEstablishment,
        },
        {
          key: this.$t("siret.key.brand1"),
          value: this.extractedData.establishmentPeriod.brand1,
        },
        {
          key: this.$t("siret.key.mainActivityEstablishment"),
          value:
            this.extractedData.establishmentPeriod.mainActivityEstablishment,
        },
      ];
      const legalUnit = [
        {
          key: this.$t("siret.key.acronym"),
          value: this.extractedData.legalUnit.acronym,
        },
        {
          key: this.$t("siret.key.administrativeStatusLegalUnit"),
          value: this.extractedData.legalUnit.administrativeStatusLegalUnit,
        },
        {
          key: this.$t("siret.key.associationIdentification"),
          value: this.extractedData.legalUnit.associationIdentification,
        },
        {
          key: this.$t("siret.key.companyMission"),
          value: this.extractedData.legalUnit.companyMission,
        },
        {
          key: this.$t("siret.key.dateCreatedLegalUnit"),
          value: this.extractedData.legalUnit.dateCreatedLegalUnit,
        },
        {
          key: this.$t("siret.key.denomination"),
          value: this.extractedData.legalUnit.denomination,
        },
        {
          key: this.$t("siret.key.effectivePeriod"),
          value: this.extractedData.legalUnit.effectivePeriod,
        },
        {
          key: this.$t("siret.key.firstName1"),
          value: this.extractedData.legalUnit.firstName1,
        },
        {
          key: this.$t("siret.key.firstName2"),
          value: this.extractedData.legalUnit.firstName2,
        },
        {
          key: this.$t("siret.key.firstName3"),
          value: this.extractedData.legalUnit.firstName3,
        },
        {
          key: this.$t("siret.key.firstName4"),
          value: this.extractedData.legalUnit.firstName4,
        },
        {
          key: this.$t("siret.key.gender"),
          value: this.extractedData.legalUnit.gender,
        },
        {
          key: this.$t("siret.key.headquartersNic"),
          value: this.extractedData.legalUnit.headquartersNic,
        },
        {
          key: this.$t("siret.key.legalCategory"),
          value: this.extractedData.legalUnit.legalCategory,
        },
        {
          key: this.$t("siret.key.mainActivityLegalUnit"),
          value: this.extractedData.legalUnit.mainActivityLegalUnit,
        },
        {
          key: this.$t("siret.key.name"),
          value: this.extractedData.legalUnit.name,
        },
        {
          key: this.$t("siret.key.socialSolidarityEconomy"),
          value: this.extractedData.legalUnit.socialSolidarityEconomy,
        },
      ];

      return [
        {
          key: this.$t("siret.label.establishment"),
          value: { ...establishment },
        },
        { key: this.$t("siret.label.legalUnit"), value: { ...legalUnit } },
        {
          key: this.$t("siret.label.establishmentAddress"),
          value: { ...establishmentAddress },
        },
        {
          key: this.$t("siret.label.establishmentPeriod"),
          value: { ...establishmentPeriod },
        },
      ];
    },
    sirenCategories() {
      const legalUnitPeriod = [
        {
          key: this.$t("siret.key.administrativeStatusLegalUnit"),
          value:
            this.extractedData.legalUnitPeriod.administrativeStatusLegalUnit,
        },
        {
          key: this.$t("siret.key.companyMission"),
          value: this.extractedData.legalUnitPeriod.companyMission,
        },
        {
          key: this.$t("siret.key.denomination"),
          value: this.extractedData.legalUnitPeriod.denomination,
        },
        {
          key: this.$t("siret.key.denominationUsual1"),
          value: this.extractedData.legalUnitPeriod.denominationUsual1,
        },
        {
          key: this.$t("siret.key.employerType"),
          value: this.extractedData.legalUnitPeriod.employerType,
        },
        {
          key: this.$t("siret.key.headquartersNic"),
          value: this.extractedData.legalUnitPeriod.headquartersNic,
        },
        {
          key: this.$t("siret.key.legalCategory"),
          value: this.extractedData.legalUnitPeriod.legalCategory,
        },
        {
          key: this.$t("siret.key.mainActivityLegalUnit"),
          value: this.extractedData.legalUnitPeriod.mainActivityLegalUnit,
        },
        {
          key: this.$t("siret.key.name"),
          value: this.extractedData.legalUnitPeriod.name,
        },
        {
          key: this.$t("siret.key.socialSolidarityEconomy"),
          value: this.extractedData.legalUnitPeriod.socialSolidarityEconomy,
        },
        {
          key: this.$t("siret.key.socialSolidarityEconomyChanged"),
          value:
            this.extractedData.legalUnitPeriod.socialSolidarityEconomyChanged,
        },
      ];

      const legalUnit = [
        {
          key: this.$t("siret.key.acronym"),
          value: this.extractedData.legalUnit.acronym,
        },
        {
          key: this.$t("siret.key.associationIdentification"),
          value: this.extractedData.legalUnit.associationIdentification,
        },
        {
          key: this.$t("siret.key.companyCategory"),
          value: this.extractedData.legalUnit.companyCategory,
        },
        {
          key: this.$t("siret.key.companyCategoryYear"),
          value: this.extractedData.legalUnit.companyCategoryYear,
        },
        {
          key: this.$t("siret.key.dateCreatedLegalUnit"),
          value: this.extractedData.legalUnit.dateCreatedLegalUnit,
        },
        {
          key: this.$t("siret.key.dateLastProcessingLegalUnit"),
          value: this.extractedData.legalUnit.dateLastProcessingLegalUnit,
        },
        {
          key: this.$t("siret.key.effectivePeriod"),
          value: this.extractedData.legalUnit.effectivePeriod,
        },
        {
          key: this.$t("siret.key.effectiveYear"),
          value: this.extractedData.legalUnit.effectiveYear,
        },
        {
          key: this.$t("siret.key.firstName1"),
          value: this.extractedData.legalUnit.firstName1,
        },
        {
          key: this.$t("siret.key.firstName2"),
          value: this.extractedData.legalUnit.firstName2,
        },
        {
          key: this.$t("siret.key.firstName3"),
          value: this.extractedData.legalUnit.firstName3,
        },
        {
          key: this.$t("siret.key.firstName4"),
          value: this.extractedData.legalUnit.firstName4,
        },
        {
          key: this.$t("siret.key.gender"),
          value: this.extractedData.legalUnit.gender,
        },
        {
          key: this.$t("siret.key.periodCount"),
          value: this.extractedData.legalUnit.periodCount,
        },
        {
          key: this.$t("siret.key.siren"),
          value: this.extractedData.legalUnit.siren,
        },
      ];

      return [
        { key: this.$t("siret.label.legalUnit"), value: { ...legalUnit } },
        {
          key: this.$t("siret.label.legalUnitPeriod"),
          value: { ...legalUnitPeriod },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.extracted__data {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  gap: 14px;
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #eeeef7;
  }

  &::-webkit-scrollbar-thumb {
    background: #b6bdff;
    border-radius: 3px;
  }
  .section {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .section__title {
      color: var(--Principale-Gris-Noir, #353542);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .extracted__items {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      .extracted__item {
        width: calc(90% / 4);
        display: flex;
        flex-direction: column;
        gap: 8px;
        .extracted__label {
          color: #707080;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 100% */
          letter-spacing: 0.018px;
        }
        .extracted__value {
          color: #505050;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px; /* 164.286% */
        }
        .no__content {
          color: #cfcfdc;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px; /* 164.286% */
        }
      }
    }
  }
}
</style>
