import { ANSWER_VALIDATION_LEVEL } from "../Utils/types.utils";
import WizideeData from "./WizideeData";
export default class Answer {
  constructor({
    id = Number.NaN,
    content = null,
    choices = [],
    personalData = null,
    personalDataOrganizationId = Number.NaN,
    question = null,
    setContent = null,
    extractedData = null,
    freeOptionContent = "",
    validationLevel = ANSWER_VALIDATION_LEVEL.PENDING,
  } = {}) {
    this.id = Number.parseInt(id);
    this.content = content;
    this.choices = choices;
    this.personalData = personalData;
    this.personalDataOrganizationId = Number.parseInt(
      personalDataOrganizationId
    );
    this.question = question;
    this.setContent = setContent;
    if (extractedData && JSON.stringify(extractedData) !== "{}") {
      if (extractedData instanceof WizideeData) {
        this.extractedData = extractedData;
      } else {
        this.extractedData = new WizideeData(extractedData);
      }
    }
    this.freeOptionContent = freeOptionContent;
    this.validationLevel = validationLevel;
  }
}
