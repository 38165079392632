<template>
  <div class="preview_container">
    <div class="left_section">
      <template v-if="loading">
        <div class="question_label">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
        <div class="question_answer">
          <div class="no_content" />
        </div>
      </template>
      <template v-else>
        <div class="check_btn" @click.stop>
          <v-checkbox v-model="isCheck" color="primary" hide-details />
        </div>
        <div class="question_label" v-text="personalData.datatype.name" />
        <div class="question_answer">
          <template
            v-if="
              Array.isArray(personalData.content) &&
              /DATAGRID|ROW_DATAGRID/.test(personalData.datatype.typeQuestion)
            "
          >
            <a
              class="link_content"
              target="_blank"
              v-text="$t('utils.goTable')"
            />
          </template>
          <template
            v-else-if="
              answerContent &&
              !/DATAGRID|ROW_DATAGRID/.test(personalData.datatype.typeQuestion)
            "
          >
            <div
              v-if="personalData.datatype.typeQuestion === 'MULTIPLE_FILE'"
              class="file-content"
            >
              <a
                v-for="(link, i) in answerContent"
                :key="`${i}`"
                class="link_content"
                @click.stop="open(link.url)"
                v-text="`Voir fichier (${i})`"
              />
            </div>
            <div
              v-else-if="
                personalData.datatype.typeQuestion !== 'MULTIPLE_FILE' &&
                /FILE|SIGNATURE/.test(personalData.datatype.typeQuestion)
              "
            >
              <div
                v-if="personalData.extractedData"
                class="extracted__data"
                @click.stop="dialogState = true"
              >
                {{ $t("folder.records.seeFile") }}
              </div>
              <a
                v-else
                class="link_content"
                :href="answerContent"
                target="_blank"
                @click.stop
                v-text="$t('folder.records.seeFile')"
              />
              <client-wezidee-dialog
                :dialog-state="dialogState"
                :personal-data="personalData"
                @close="dialogState = false"
              />
            </div>

            <div
              v-else
              class="text_content"
              :class="{ clickable: personalData.extractedData }"
              @click.stop="
                () => {
                  if (personalData.extractedData) return (dialogState = true);
                }
              "
            >
              {{ answerContent }}
              <client-wezidee-dialog
                :dialog-state="dialogState"
                :personal-data="personalData"
                @close="dialogState = false"
              />
            </div>
          </template>
          <div v-else class="no_content" />
        </div>
      </template>
    </div>
    <div class="right_section">
      <button class="edit_btn">
        <BaseIcon class="icon" icon="$mdiPencilOutline" />
        <span v-text="$t('utils.edit')" />
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import ClientWezideeDialog from "../../Folder/Components/ClientWezideeDialog.vue";

export default {
  name: "ClientPersonalDataTable",
  components: { ClientWezideeDialog },
  props: {
    personalData: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    extractedData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeTab: 1,
      isCheck: false,
      dialogState: false,
      QuestionType: {
        TEXT: "TEXT",
        TEXTAREA: "TEXTAREA",
        DATE: "DATE",
        DATETIME: "DATETIME",
        NUMBER: "NUMBER",
        SELECT: "SELECT",
        RADIOBUTTON: "RADIOBUTTON",
        CHECKBOX: "CHECKBOX",
        FILE: "FILE",
        MULTIPLE_FILE: "MULTIPLE_FILE",
        SIGNATURE: "SIGNATURE",
        DATAGRID: "DATAGRID",
        ROW_DATAGRID: "ROW_DATAGRID",
        MESSAGE: "MESSAGE",
      },
    };
  },

  computed: {
    ...mapState({
      activeClient: (state) => state.client.activeClient,
    }),
    answerContent() {
      if (!this.personalData.content) return null;
      switch (this.personalData.datatype.typeQuestion) {
        case this.QuestionType.DATE:
          return moment(this.personalData.content).format("DD/MM/YYYY");
        case this.QuestionType.FILE:
        case this.QuestionType.SIGNATURE:
          return this.personalData.content
            ? this.personalData.content[0].url
            : null;
        case this.QuestionType.MULTIPLE_FILE: {
          return this.personalData.content ? this.personalData.content : null;
        }
        case this.QuestionType.DATETIME:
          return moment(this.personalData.content).format("DD/MM/YYYY HH:mm");
        case this.QuestionType.DATAGRID:
        case this.QuestionType.ROW_DATAGRID:
          return Array.isArray(this.personalData.content) ? "Tableau" : null;
        default:
          return this.personalData.content?.toString();
      }
    },
  },
  methods: {
    getUniversesName(item) {
      return item.datatype?.universes[0]?.name;
    },
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.preview_container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  min-height: 48px;
  background: #fbfbfb;
  border-left: 8px solid #b6bdff;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  transition: all 0.25s ease-in;
  cursor: pointer;

  .left_section {
    display: flex;
    align-items: center;
    width: 100%;
    transition: all 0.25s ease-in;
  }
  .right_section {
    display: flex;
    align-items: center;
    flex: none;
    overflow: hidden;
    width: 0;
    transition: all 0.25s ease-in;
  }

  &.loading {
    border-left: 8px solid #e0e0e0;
  }

  .check_btn {
    width: 14px;
    height: 14px;
    position: absolute;
    z-index: 5;
    top: 17px;
    left: -19px;
    opacity: 0;
    transform: scale(0);
    transition: all ease-in 0.25s;
    &::v-deep {
      .v-input--checkbox {
        margin: 0;
        padding: 0;
      }
      .v-input--selection-controls__ripple {
        display: none;
      }
      .v-input--selection-controls__input {
        width: 14px;
        height: 14px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .question_label {
    width: 50%;
    flex: none;
    padding-right: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #353542;
    max-height: 32px;
    overflow: hidden;
    .skeleton {
      height: 16px;
      width: 100px;
      ::v-deep {
        .v-skeleton-loader__bone {
          height: 12px;
        }
      }
    }
  }
  .question_answer {
    width: 50%;
    flex: none;
    padding: 0 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #2536cc;
    display: flex;
    justify-content: flex-end;
    .text_content {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .link_content {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .no_content {
      margin-right: 48px;
      width: 16px;
      height: 2px;
      background: #cfcfdc;
    }
  }

  .edit_btn {
    display: flex;
    padding: 4px 8px;
    height: 24px;
    background: #eeeef7;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #707080;
    transform: scale(0);
    transition: all ease-in 0.25s;
    & > .icon {
      &::v-deep {
        .v-icon {
          width: 14px;
          height: 14px;
          margin-right: 2px;
          color: #707080;
        }
      }
    }
  }

  &.selectable:hover {
    border-left: 24px solid #b6bdff;
    background: #f4f7ff;
    .check_btn {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.disable {
    pointer-events: none;
    background-color: #ededed;
  }
  &.editable:hover {
    .left_section {
      width: calc(100% - 80px);
    }
    .right_section {
      width: 80px;
    }
    .edit_btn {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.checked {
    border-left: 24px solid #b6bdff;
    .check_btn {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.expanded {
    border-left: 24px solid #b6bdff;
    .check_btn {
      opacity: 1;
      transform: scale(1);
    }
  }
}
.record_question_container {
  position: relative;
  padding: 8px 16px 8px 24px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  height: 42px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #cfcfdc;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff;
  }
  .label {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
.clickable {
  text-decoration: underline;
}
</style>
