<template>
  <div>
    <template v-if="fetchingRecords">
      <FolderRecordItemSkeleton v-for="i in 3" :key="i" />
    </template>
    <template v-if="!fetchingRecords && offersRecordsCosmoforms.length">
      <div v-for="(record, i) in offersRecordsCosmoforms" :key="`r-${i}`">
        <FolderRecordItem
          v-if="record.type == RECORD_TYPE.RECORD"
          :organization-id="organizationId"
          :folder-id="folderId"
          :record="record"
        />
        <FolderCosmoformItem
          v-else-if="record.type == RECORD_TYPE.COSMOFORM"
          :cosmoform-detail="record"
        />
        <FolderEmailItem
          v-else-if="record.type == RECORD_TYPE.EMAIL"
          :email="record"
          :organization-id="organizationId"
          :folder-id="folderId"
        />
        <folder-control-item
          v-else-if="record.type == RECORD_TYPE.CONTROL"
          :control-group="record"
        />
        <FolderOfferItem
          v-else
          :offer="record"
          :organization-id="organizationId"
          :folder-id="folderId"
        />
      </div>
    </template>
    <!-- MODELS -->
    <RelaunchInvitationRecordDialog />
    <RelaunchCosmoformDialog />
    <FolderQuestionValidationDialog />
  </div>
</template>

<script>
import { mapState } from "vuex";

import FolderRecordItemSkeleton from "@/modules/Folder/Components/FolderRecordItemSkeleton.vue";
import FolderRecordItem from "@/modules/Folder/Components/FolderRecordItem/FolderRecordItem.vue";
import FolderCosmoformItem from "@/modules/Folder/Components/FolderCosmoformItem/FolderCosmoformItem.vue";
import FolderOfferItem from "@/modules/Folder/Components/FolderOfferItem/FolderOfferItem.vue";
import RelaunchInvitationRecordDialog from "@/modules/Folder/Components/RelaunchInvitationRecordDialog.vue";
import RelaunchCosmoformDialog from "@/modules/Folder/Components/RelaunchCosmoformDialog.vue";
import FolderQuestionValidationDialog from "@/modules/Folder/Components/FolderQuestionValidationDialog.vue";
import FolderEmailItem from "./FolderEmailItem/FolderEmailItem.vue";
import FolderControlItem from "./FolderControlItem/FolderControlItem.vue";

const RECORD_TYPE = {
  RECORD: "RECORD",
  COSMOFORM: "COSMOFORM",
  OFFER: "OFFER",
  EMAIL: "EMAIL",
  CONTROL: "CONTROL",
};

export default {
  name: "FolderRecords",
  components: {
    FolderRecordItemSkeleton,
    FolderRecordItem,
    FolderCosmoformItem,
    FolderOfferItem,
    RelaunchInvitationRecordDialog,
    RelaunchCosmoformDialog,
    FolderQuestionValidationDialog,
    FolderEmailItem,
    FolderControlItem,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      RECORD_TYPE: RECORD_TYPE,
      fetchingRecords: true,
    };
  },
  computed: {
    ...mapState({
      offerList: (state) => state.offer.offerList,
      activeFolder: (state) => state.folder.activeFolder,
      cosmoformDetails: (state) => state.folder.cosmoformDetails,
      offersRecordsCosmoforms: (state) => state.folder.offersRecordsCosmoforms,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchingRecords = true;
      const payload = {
        organizationId: this.organizationId,
        folderId: this.folderId,
        page: 1,
      };
      await this.$store.dispatch(
        "folder/getOrganizationOffersRecordFolder",
        payload
      );
      this.fetchingRecords = false;
    },
  },
};
</script>
