<template>
  <BaseModal
    v-if="activeCosmoform"
    :value="dialogState"
    :modal-title="'test'"
    :need-action="true"
    @close="reset()"
  >
    <template #modal>
      <form>
        <BaseRow v-if="activeCosmoform.answer">
          <BaseCol>
            <QuestionDatagridPreviwer
              v-if="
                /^DATAGRID|ROW_DATAGRID$/.test(question.typeQuestion) &&
                question.answer.content
              "
              :question="question"
              class="mb-1"
            />
            <RecordQuestionValidation v-else :question="question" />
          </BaseCol>
        </BaseRow>
      </form>
    </template>

    <template #actions>
      <BaseButton class="mx-2 ml-auto" type="primary" @click="reset">
        {{ $t("utils.back") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import RecordQuestionValidation from "../Components/RecordQuestionValidation";
import QuestionDatagridPreviwer from "@/core/Components/QuestionDatagridPreviwer";

import { mapState } from "vuex";

export default {
  name: "FolderQuestionValidationDialog",
  components: {
    RecordQuestionValidation,
    QuestionDatagridPreviwer,
  },

  computed: {
    ...mapState({
      activeCosmoform: (state) => state.folder.activeCosmoform,
      dialogState: (state) => state.folder.modals.isCosmoformDialogDisplayOpen,
    }),
    question() {
      return { ...this.activeCosmoform, ...this.activeCosmoform.question };
    },
  },

  methods: {
    reset() {
      this.$store.commit("folder/setIsCosmoformDialogDisplayOpen", false);
    },
  },
};
</script>
