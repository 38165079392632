<template>
  <div class="d-flex flex-column">
    <base-pdf-signature-positioner
      :files="[...newOffer.files]"
      :signatories="signatories"
      @all-file-validated="isDisabled = false"
    />
    <div class="action-container">
      <BaseButton class="ml-auto" type="primary" outlined @click="goBackStep">
        <Base-icon small icon="$mdiArrowLeftThick" />
        <span class="ml-2" v-text="$t('utils.back')" />
      </BaseButton>
      <BaseButton type="primary" :disabled="isDisabled" @click="moveNextStep">
        <span class="mr-2" v-text="$t('utils.next')" />
        <Base-icon small icon="$mdiArrowRightThick" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BasePdfSignaturePositioner from "../../../../core/Components/BasePdfSignaturePositioner/BasePdfSignaturePositioner.vue";
export default {
  name: "FolderOfferStep3",
  components: { BasePdfSignaturePositioner },
  data() {
    return {
      isDisabled: true,
    };
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      newOffer: (state) => state.offer.newOffer,
    }),
    signatories() {
      if (!this.newOffer?.signatories && !this.newOffer?.signatories?.length)
        return;
      return [...this.newOffer.signatories].filter(
        (signatory) => signatory?.action == "signer"
      );
    },
  },

  methods: {
    moveNextStep() {
      this.$store.commit("offer/setOfferStep", 4);
    },
    goBackStep() {
      this.$store.commit("offer/setOfferStep", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 12px;
}
</style>
