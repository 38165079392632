var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.walletFolders.length)?_c('div',{staticClass:"folder_records_container"},_vm._l((_vm.walletFolders),function(folder,index){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:(index + "-" + (folder.id)),staticClass:"record_item",on:{"click":function($event){return _vm.redirectToFolder(folder)}}},[_c('div',{staticClass:"record_item_header"},[_c('div',{staticClass:"header_title"},[_vm._v(" "+_vm._s(folder.name)+" ")])]),_c('div',{staticClass:"record_item_content"},[_c('div',{domProps:{"textContent":_vm._s(
            ((_vm.$t('folder.createDateAt')) + " " + (_vm.$d(
              folder.dateCreated,
              'veryshort'
            )))
          )}}),_c('div',{domProps:{"textContent":_vm._s(
            _vm.$t('folder.lastEdition', {
              date: _vm.$d(folder.dateUpdate, 'veryshort'),
            })
          )}})])])}),0):_c('div',{staticClass:"d-flex justify-space-between mobile-skeleton"},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"skeleton-charger mx-1 mb-2"},[_c('v-skeleton-loader',{attrs:{"min-width":"350","type":"list-item-avatar, divider,  card-heading, image"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }