import { cosmoAPI, cosmoWEB } from "@/core/Api/cosmoAxiosInstance";

export default {
  getOrganizations(page = 1) {
    return cosmoAPI.get("/organization/", {
      params: {
        page,
      },
    });
  },
  getSSOReady() {
    return cosmoWEB.get("/oidc/aad-ready/");
  },
  getWalletOrganization(organizationId) {
    return cosmoAPI.get(`/wallet/organization/${organizationId}/`);
  },
  getOrganizationDocumentsControl(organizationId) {
    return cosmoAPI.get(`/organization/${organizationId}/validation-schema/`);
  },
  getOrganizationDocumentTemplates(organizationId) {
    return cosmoAPI.get(`/organization/${organizationId}/docx-template/`);
  },
  postOrganizationDocumentControl(organizationId, folderId, payload) {
    return cosmoAPI.post(
      `organization/${organizationId}/folder/${folderId}/control/`,
      payload
    );
  },
  postOrganizationDocumentControlRun(
    organizationId,
    folderId,
    documentControlId
  ) {
    return cosmoAPI.post(
      `organization/${organizationId}/folder/${folderId}/control/${documentControlId}/run/`
    );
  },
  postOrganizationDocumentGenerate({ organizationId, folderId, payload }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/generate-document/`,
      payload
    );
  },
  postOrganization({ name, avatar }) {
    let payload = new FormData();
    payload.append("name", name);
    if (avatar) {
      payload.append("avatar", avatar);
    }
    return cosmoAPI.post("/organization/", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  postOrganizationToGED({ organizationId, folderId }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/files/to_ged/`
    );
  },
  getOrganization(organizationId) {
    return cosmoAPI.get(`/organization/${organizationId}/`);
  },
  patchOrganization(organizationId, data) {
    let payload = new FormData();
    if (data.avatar || data.logo) {
      data.avatar
        ? payload.append("avatar", data.avatar)
        : payload.append("logo", data.logo);
      return cosmoAPI.patch(`/organization/${organizationId}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    return cosmoAPI.patch(`/organization/${organizationId}/`, data);
  },
  getOrganizationCollaborators(organizationId, page) {
    return cosmoAPI.get(`/organization/${organizationId}/collaborator/`, {
      params: {
        page,
      },
    });
  },
  deleteOrganizationCollaborator({ organizationId, collaboratorId }) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/collaborator/${collaboratorId}/`
    );
  },
};
