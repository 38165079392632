import Collaborator from "./Collaborator";
import PersonalData from "./PersonalData";

export default class Organization {
  constructor({
    id = Number.NaN,
    name = "",
    avatar = null,
    logo = null,
    description = "",
    contact = "",
    collaborators = [],
    enoxa = null,
    personalDatas = [],
  } = {}) {
    this.id = id;
    this.name = name;
    this.avatar = avatar;
    this.logo = logo;
    this.description = description;
    this.contact = contact;
    this.collaborators = collaborators
      ? collaborators.map((collaborator) => new Collaborator(collaborator))
      : [];
    this.enoxa = enoxa;
    this.personalDatas = personalDatas?.length
      ? personalDatas.map((p) => new PersonalData({ ...p }))
      : [];
  }
}
