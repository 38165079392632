<template>
  <div>
    <Board>
      <template slot="header">
        <div class="d-flex justify-space-between align-center">
          <BaseTitle class="d-block" v-text="$tc('organization.course', 2)" />
          <BaseButton
            type="primary"
            icon="$mdiArrowRightThick"
            :to="{
              name: 'courseList',
              params: { organizationId: activeOrganization.id },
            }"
          >
            {{ $t("editor.seeOthers") }}
          </BaseButton>
        </div>
      </template>
      <template slot="main">
        <BaseDataTable
          :headers="COURSES_LIST_HEADER"
          :items="courses"
          :loading="isFetchingOrganizationCourses"
          :loading-text="$t('utils.loadingData')"
          class="elevation-1 mb-6"
        >
          <template #item="{ item }">
            <tr class="row--link">
              <td>
                <router-link
                  class="d-flex align-center"
                  :to="{
                    name: 'courseDetail',
                    params: {
                      organizationId: activeOrganization.id,
                      courseId: item.id,
                    },
                  }"
                >
                  <BaseParagraph class="d-block" v-text="item.name" />
                </router-link>
              </td>
              <td>
                <router-link
                  class="text-center px-0"
                  :to="{
                    name: 'courseDetail',
                    params: {
                      organizationId: activeOrganization.id,
                      courseId: item.id,
                    },
                  }"
                >
                  <BaseIcon
                    v-if="item.anonymous"
                    icon="$mdiCheck"
                    color="primary"
                  />
                  <BaseIcon v-else icon="$mdiClose" color="secondary" />
                </router-link>
              </td>
              <td>
                <router-link
                  class="text-center px-0"
                  :to="{
                    name: 'courseDetail',
                    params: {
                      organizationId: activeOrganization.id,
                      courseId: item.id,
                    },
                  }"
                >
                  <BaseParagraph v-text="item.campaignsCount" />
                </router-link>
              </td>
              <td>
                <router-link
                  class="text-center px-0"
                  :to="{
                    name: 'courseDetail',
                    params: {
                      organizationId: activeOrganization.id,
                      courseId: item.id,
                    },
                  }"
                >
                  <BaseParagraph v-text="item.activeRecordsCount" />
                </router-link>
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "../Board";

import { getOrganizationCourses } from "../../Services/organizationDashboard.service";

import { mapState } from "vuex";

export default {
  name: "OrganizationDashboardCourses",
  components: {
    Board,
  },
  data() {
    return {
      COURSES_LIST_HEADER: [
        {
          text: this.$tc("utils.name", 1),
          value: "name",
        },
        {
          text: this.$tc("editor.anonymous"),
          value: "anonymous",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("organization.campaign", 2),
          value: "fullname",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("organization.record", 2),
          value: "function",
          align: "center",
          sortable: false,
        },
      ],
      isFetchingOrganizationCourses: false,
      courses: [],
    };
  },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
  },
  watch: {
    "activeOrganization.id"() {
      this.reset();
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    reset() {
      this.isFetchingOrganizationCourses = false;
      this.courses = [];
    },
    initialize() {
      this.isFetchingOrganizationCourses = true;
      this.fetchOrganizationCourses(1);
    },
    async fetchOrganizationCourses(page) {
      let res = await getOrganizationCourses(this.activeOrganization.id, page);
      if(!res) return;
      this.courses.push(...res?.data?.results);
      if (res.data.next) {
        this.fetchOrganizationCourses(res.data.next);
      }
      this.isFetchingOrganizationCourses = false;
    },
  },
};
</script>

<style lang="scss">
td a{
  // Removed underlining in table links
  text-decoration: none;
}
</style>
