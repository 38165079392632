import authenticationAPI from "./authentication.api";

export function getUser() {
  return authenticationAPI.getUser();
}

export function patchUserAvatar(image) {
  let payload = new FormData();
  payload.append("avatar", image);
  return authenticationAPI.patchUserAvatar(payload);
}

export function setUserPassword(oldPassword, newPassword1, newPassword2) {
  return authenticationAPI.patchUserPassword({
    password_old: oldPassword,
    password_1: newPassword1,
    password_2: newPassword2,
  });
}

export function deleteUserAccount(password) {
  return authenticationAPI.deleteUser({
    password: password,
    confirm: 1,
  });
}
