<template>
  <div>
    <BaseTableEditor
      v-model="answerContent"
      :headers="tableHeaders"
      variant="secondary"
      :horizontal="/^ROW_DATAGRID$/.test(question.typeQuestion)"
      readonly
    />
  </div>
</template>

<script>
export default {
  name: "QuestionDatagridPreviwer",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableHeaders() {
      if (!/^DATAGRID|ROW_DATAGRID$/.test(this.question.typeQuestion))
        return [];
      return this.question.choices.map((choice) => ({
        label: choice.name,
        field: choice.name,
      }));
    },
    answerContent() {
      if (!this.question.answer?.content) return [];
      const rows = [];
      this.question.answer.content.rows.forEach((row) => {
        const tmp = {};
        this.question.answer.content.headers.forEach((key, i) => {
          tmp[key] = row[i];
        });
        rows.push(tmp);
      });
      return rows;
    },
  },
};
</script>
