import OrganizationDashboard from "../Pages/OrganizationDashboard";

export default [
  {
    path: "dashboard/",
    name: "organizationDashboard",
    component: OrganizationDashboard,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "organizationDashboard",
          disabled: true,
          label: "utils.dashboard",
        },
      ],
    },
  },
];
