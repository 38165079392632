<template>
  <div class="d-flex align-center">
    <div class="d-flex flex-column" style="width: 100%">
      <slot name="left_items" />
    </div>
    <div class="ml-auto">
      <slot name="right_items" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientSpaceItems",
};
</script>
