import { render, staticRenderFns } from "./BaseListItemGroup.vue?vue&type=template&id=7bf721f0"
import script from "./BaseListItemGroup.vue?vue&type=script&lang=js"
export * from "./BaseListItemGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VListItemGroup})
