import Organization from "./Organization";
import Step from "./Step";

export default class Course {
  constructor({
    id = Number.NaN,
    name = "",
    description = "",
    image = "",
    anonymous = false,
    organization = new Organization(),
    testUrl = "",
    hasCampaignStarted = "",
    archived = false,
    steps = [],
    campaignsCount = Number.NaN,
    activeRecordsCount = Number.NaN,
    organizationRequired = false,
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.description = description;
    this.image = image;
    this.anonymous = anonymous;
    this.organization = organization ? new Organization(organization) : null;
    this.testUrl = testUrl;
    this.hasCampaignStarted = hasCampaignStarted;
    this.archived = archived;
    this.steps = steps ? steps.map((step) => new Step(step)) : [];
    this.campaignsCount = Number.parseInt(campaignsCount);
    this.activeRecordsCount = Number.parseInt(activeRecordsCount);
    this.organizationRequired = organizationRequired;
  }
  get isValid() {
    return (
      this.steps.length > 0 &&
      this.steps.every((step) => step.questions.length) > 0
    );
  }
}
