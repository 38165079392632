<template>
  <div v-bind="$attrs" class="step-4" v-on="$listeners">
    <div class="item-container">
      <span class="sub-title" v-text="`Programmation de l'envoi`" />
      <div class="d-flex align-center">
        <span class="input-text mr-3" v-text="'Date'" />
        <base-date-pickers @update-date="(value) => (sendingDate = value)" />
        <span class="input-text mx-3" v-text="'à'" />
        <base-time-pickers @update-time="(value) => (sendingTime = value)" />
      </div>
    </div>
    <div v-if="hasSignatories" class="datatype_field">
      <div class="d-flex flex-column sub-title">
        <span v-text="$t('folder.offer.datatypeTitle')" />
        <span class="text-line" v-text="$t('folder.offer.datatypeSubTitle')" />
      </div>
      <BaseTextField
        v-if="defaultSignedFileDatatype"
        :value="defaultSignedFileDatatype.name"
        dense
        readonly
        class="selector"
      />
      <BaseSelect
        v-else
        v-model="signedFileDatatype"
        class="selector"
        solo
        dense
        hide-selected
        clearable
        :items="datatypes"
        item-text="name"
        item-value="id"
        :loading="fetchingDatatypes"
        :label="$t('folder.offer.associateWithField')"
      />
    </div>
    <!-- remove for scheduler -->
    <div class="item-container">
      <div class="d-flex flex-column sub-title">
        <span v-text="`${$t('folder.offer.relaunch_automatic')}`" />
        <span class="text-line" v-text="$t('folder.offer.label_reminder')" />
      </div>
      <div class="config-container">
        <v-switch
          v-model="reminder"
          class="checkbox"
          inset
          hide-details
          :label="$t('folder.offer.reminder_activate')"
        />
      </div>
    </div>

    <div class="action-container">
      <BaseButton
        class="ml-auto"
        type="primary"
        :disabled="isLoading"
        outlined
        @click="goBackStep"
      >
        <Base-icon small icon="$mdiArrowLeftThick" />
        <span class="ml-2" v-text="$t('utils.back')" />
      </BaseButton>
      <BaseButton type="primary" :loading="isLoading" @click="validate">
        <span class="mr-2" v-text="$t('utils.send')" />
        <Base-icon small icon="$mdiArrowRightThick" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import DatatypeAPI from "@/core/Services/datatype.api";
import Datatype from "@/core/Models/Datatype";
import { mapState } from "vuex";
export default {
  name: "FolderOfferStep4",
  mixins: [validationMixin],
  props: {
    defaultSignedFileDatatype: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      sendingDate: null,
      sendingTime: null,
      configTypes: [
        { title: "Une fois par jour", value: "1" },
        { title: "Tous les 2 jours", value: "2" },
        { title: "Une fois par semaine", value: "7" },
        { title: "Toutes les 2 semaines", value: "14" },
      ],
      scheduler: null,
      reminder: true,
      signedFileDatatype: null,
      fetchingDatatypes: false,
      datatypes: [],
    };
  },
  validations() {
    let validations = {};
    validations.sendingDate = {
      minValue: (value) => new Date(value) > new Date(),
    };
    return validations;
  },
  computed: {
    ...mapState({
      step: (state) => state.offer.offerStep,
      newOffer: (state) => state.offer.newOffer,
    }),
    hasSignatories() {
      if (this.newOffer.type != "SIGNATURE" && this.newOffer.type != "MIXTE")
        return false;
      return this.newOffer.signatories?.some(
        (item) => item.action === "signer"
      );
    },
  },
  watch: {
    defaultSignedFileDatatype: {
      handler(value) {
        this.signedFileDatatype = value ? value.id : undefined;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchDatatypes();
  },
  methods: {
    async fetchDatatypes() {
      this.fetchingDatatypes = true;
      this.datatypes = [];
      try {
        let page = 1;
        while (page) {
          const res = await DatatypeAPI.getDatatypes({
            page,
            typeQuestion: "MULTIPLE_FILE",
          });
          this.datatypes = [
            ...this.datatypes,
            ...res.data.results.map((d) => new Datatype(d)),
          ];
          page = res.data.next;
        }
      } catch (error) {
        this.datatypes = [];
      }
      this.fetchingDatatypes = false;
    },
    goBackStep() {
      this.$store.commit("offer/setOfferStep", +this.step - 1);
    },
    validate() {
      let payload = {};
      if (!this.$v.sendingDate.$invalid || this.sendingTime) {
        payload.publishDate = new Date(
          `${this.sendingDate} ${this.sendingTime}`
        ).toISOString();
      }
      this.scheduler && (payload.scheduler = this.scheduler);
      this.$store.commit("offer/setNewOffer", {
        ...this.newOffer,
        auto_reminder: this.reminder,
        ...payload,
        signedFileDatatype: this.signedFileDatatype,
      });
      this.$emit("create-offer");
    },
  },
};
</script>

<style lang="scss" scoped>
.step-4 {
  display: flex;
  flex-direction: column;
}
.item-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
  margin-bottom: 24px;
}
.config {
  flex: 1;
}
.sub-title {
  color: var(--principale-gris-noir, #353542);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.config-container {
  display: flex;
  align-items: center;
  height: 76px;
  gap: 24px;
  width: 100%;
}
.text-line {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}
.input-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707080;
}
.action-container {
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 12px;
}
.checkbox {
  margin: 0 0 16px 0 !important;
  .checkbox_label {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 8px;
    }
  }
}
.datatype_field {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
  .selector {
    width: 300px;
  }
}
</style>
