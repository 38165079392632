<template>
  <BaseDialog max-width="650" :value="dialogState" @click:outside="reset()">
    <template #modal>
      <div v-if="selectedCosmoform" class="modal_container">
        <div class="modal_header">
          <div
            class="modal_title"
            v-text="
              `${selectedCosmoform.question.label} ${
                selectedCosmoform.question.required ? '*' : ''
              }`
            "
          />
          <BaseFrontOfficeButtonIcon
            class="close_icon"
            icon="$mdiClose"
            @click="reset()"
          />
        </div>
        <div class="modal_content">
          <div
            v-if="helpText.length || selectedCosmoform.question.helpFile"
            class="help_section"
          >
            <div class="help_section_title" v-text="$t('utils.help')" />
            <div v-if="helpText.length" class="help_text">
              <div
                v-for="(text, index) in helpText"
                :key="text + index"
                class="help_texp"
                v-text="text"
              />
            </div>
            <a
              v-if="selectedCosmoform.question.helpFile"
              :href="selectedCosmoform.question.helpFile"
              target="_blank"
              class="help_file"
            >
              {{ fileName }}
            </a>
          </div>
          <CosmoformForm
            v-model="form.value"
            :loading="isLoading"
            :disabled="selectedCosmoform.question.required && !form.value"
            :question="selectedCosmoform.question"
            @is-saving="save()"
            @submit="submit()"
          />
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import CosmoformForm from "./CosmoformForm.vue";
import { mapState } from "vuex";
import Cosmoform from "@/core/Models/Cosmoform";

export default {
  name: "FillCosmoformDialog",
  components: {
    CosmoformForm,
  },
  data() {
    return {
      form: {
        value: null,
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.cosmoform.modals.fillCosmoformState,
      selectedCosmoform: (state) => state.cosmoform.selectedCosmoform,
      cosmoforms: (state) => state.cosmoform.cosmoforms,
    }),
    helpText() {
      if (!this.selectedCosmoform.question.helpText) return [];
      return this.selectedCosmoform.question.helpText.split("\n");
    },
    fileName() {
      if (!this.selectedCosmoform.question.helpFile) return "";
      if (this.selectedCosmoform.question.helpFile.includes("?")) {
        return this.selectedCosmoform.question.helpFile.split("?")[0];
      }
      return this.selectedCosmoform.question.helpFile.split("/")[
        this.selectedCosmoform.question.helpFile.split("/").length - 1
      ];
    },
  },
  watch: {
    dialogState(val) {
      if (val && this.selectedCosmoform && this.selectedCosmoform.answer) {
        this.form.value = this.selectedCosmoform.answer;
      }
    },
  },

  methods: {
    reset() {
      this.form = {
        value: null,
      };
      this.$store.commit("cosmoform/SET_Fill_COSMOFORM_DIALOG_STATE", false);
      this.$store.commit("cosmoform/SET_SELECTED_COSMOFORM", null);
    },
    save() {
      this.isLoading = true;
      const tmpCosmoform = new Cosmoform({
        question: {
          ...this.selectedCosmoform.question,
          answer: this.form.value ? this.form.value : null,
        },

        answer: this.form.value ? this.form.value : null,
      });
      this.$store.commit(
        "cosmoform/SET_COSMOFORMS",
        this.cosmoforms.map((cosmoform) => {
          if (cosmoform.question.id === tmpCosmoform.question.id) {
            return tmpCosmoform;
          } else {
            return cosmoform;
          }
        })
      );
      this.isLoading = false;
      this.reset();
    },
    submit() {
      if (!/^RADIOBUTTON$/.test(this.selectedCosmoform.question.typeQuestion)) {
        this.save();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_container {
  padding: 24px;
  background: #fff;
  .modal_header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .modal_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #353542;
    }
    .close_icon {
      margin-left: auto;
    }
  }
  .modal_content {
    .help_section {
      padding: 16px;
      border: 0.5px solid #2536cc;
      border-radius: 5px;
      margin-bottom: 24px;
      .help_section_title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #353542;
        margin-bottom: 12px;
      }
      .help_text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #353542;
        margin-bottom: 4px;
      }
      .help_file {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #2536cc;
      }
    }
  }
  .modal_footer {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    & > * {
      margin-left: 16px;
    }
  }
}
</style>
