var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w_container"},[_c('div',{staticClass:"left_section"},[_c('div',{staticClass:"multiple__file__container"},[(_vm.question.answer.content.length > 1)?_c('base-icon',{staticClass:"carouse__left__icon",class:{ disabled: _vm.activeCarousel <= 1 },attrs:{"color":"primary","icon":"$mdiChevronLeft"},on:{"click":function($event){$event.stopPropagation();return _vm.changeActiveImg('PREVIOUS')}}}):_vm._e(),(_vm.question.answer.content.length > 1)?_c('base-icon',{staticClass:"carouse__right__icon",class:{
          disabled: _vm.activeCarousel >= _vm.question.answer.content.length,
        },attrs:{"color":"primary","icon":"$mdiChevronRight"},on:{"click":function($event){$event.stopPropagation();return _vm.changeActiveImg('NEXT')}}}):_vm._e(),_c('a',{staticClass:"img_container"},[_c('div',{staticClass:"menu__item",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-menu',{attrs:{"offset-y":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();}}},'div',attrs,false),on),[_c('div',{staticClass:"menu__activator"},[_c('BaseIcon',{attrs:{"icon":"$mdiDotsHorizontal","color":"dark"}})],1)])]}}])},[_c('v-list',_vm._l(([
                  {
                    key: 'download',
                    title: 'Télécharger le fichier',
                    icon: '$mdiDownload',
                    disabled: false,
                  },
                  {
                    key: 'delete',
                    title: 'Supprimer le fichier',
                    icon: '$mdiDelete',
                    disabled: true,
                  },
                  {
                    key: 'sendGed',
                    title: _vm.$t('utils.sendToGed'),
                    icon: '$mdiFileCog',
                    disabled: true,
                  } ]),function(template,index){return _c('v-list-item',{key:index,staticClass:"item-list"},[_c('div',{staticClass:"d-flex align-items-center",class:{ disabled: template.disabled },on:{"click":function($event){$event.stopPropagation();!template.disabled && _vm.activeAction(template.key)}}},[_c('BaseIcon',{attrs:{"icon":template.icon,"color":"black","size":18}}),_c('div',{staticClass:"label__menu",domProps:{"textContent":_vm._s(template.title)}})],1)])}),1)],1)],1),(_vm.isFilePdf)?_c('BasePdfViewer',{attrs:{"pdf":_vm.imgSrc},on:{"click":function($event){$event.stopPropagation();return _vm.open(_vm.imgSrc)}}}):_c('img',{attrs:{"src":_vm.imgSrc},on:{"click":function($event){$event.stopPropagation();return _vm.open(_vm.imgSrc)}}})],1)],1),(_vm.question.answer.content.length > 1)?_c('div',{staticClass:"slider__indication"},_vm._l((_vm.question.answer.content.length),function(x){return _c('div',{key:x,staticClass:"indicator",class:{ active: x == _vm.activeCarousel }})}),0):_vm._e()]),_c('div',{staticClass:"rigth_section"},[_c('div',{staticClass:"item"},_vm._l((_vm.wizideeDataDataList),function(data,index){return _c('div',{key:index,staticClass:"content_field"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(data.key))]),(data.isImage)?_c('div',{staticClass:"response url",on:{"click":function($event){return _vm.goToFile(data.value)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.seeFile"))+" ")]):_c('div',{staticClass:"response"},[_vm._v(_vm._s(data.value))])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }