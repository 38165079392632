import PersonalData from "./PersonalData";
import CosmoUser from "./CosmoUser";

export default class Client extends CosmoUser {
  constructor({
    id = Number.NaN,
    email = "",
    firstname = "",
    lastname = "",
    personalData = [],
    societyName = null,
    contractStartDate = "",
    identityPhoto = null,
    matricule = "",
    phone = "",
    recordsAgreeCount = Number.NaN,
    recordsTotalCount = Number.NaN,
    inOrganizationSignatories = false,
    reasonDecline = "",
    status = "",
    certificate = "",
    lastAction = null,
  } = {}) {
    super({ id, email, firstname, lastname });
    this.personalData = personalData
      ? personalData.map((data) => new PersonalData(data))
      : [];
    this.societyName = societyName;
    this.contractStartDate = contractStartDate ? contractStartDate : "";
    this.identityPhoto = identityPhoto ? identityPhoto : "";
    this.matricule = matricule ? matricule : "";
    this.phone = phone ? phone : "";
    this.recordsAgreeCount = Number.parseInt(recordsAgreeCount);
    this.recordsTotalCount = Number.parseInt(recordsTotalCount);
    this.reasonDecline = reasonDecline;
    this.status = status;
    this.certificate = certificate;
    this.lastAction = lastAction;
    this.inOrganizationSignatories = inOrganizationSignatories;
  }

  get firstChar() {
    if (this.fullName) return this.fullName[0];
    return this.email[0];
  }

  get identityPhotoUrl() {
    return this.identityPhoto.length ? this.identityPhoto[0].url : "";
  }
}
