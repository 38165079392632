import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getQuestionTemplates({ organizationId, page, slug, pageSize }) {
    let params = [];
    if (page) params.push(`page=${page}`);
    if (pageSize) params.push(`page_size=${pageSize}`);
    if (slug) params.push(`slug=${slug}`);
    return cosmoAPI.get(
      `/organization/${organizationId}/template/question/?${params.join("&")}`
    );
  },
  postQuestionTemplate({ organizationId, payload }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/template/question/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  patchQuestionTemplate({ organizationId, questionTemplateId, payload }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/template/question/${questionTemplateId}/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postPropagateQuestionTemplate({ organizationId, questionTemplateId }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/template/question/${questionTemplateId}/propagate/`
    );
  },
  getQuestionTemplateOption({ organizationId, questionTemplateId, page }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/template/question/${questionTemplateId}/option/`,
      {
        params: {
          page,
        },
      }
    );
  },
  postQuestionTemplateOption({ organizationId, questionTemplateId, payload }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/template/question/${questionTemplateId}/option/`,
      payload
    );
  },
  patchQuestionTemplateOption({
    organizationId,
    questionTemplateId,
    optionId,
    payload,
  }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/template/question/${questionTemplateId}/option/${optionId}/`,
      payload
    );
  },
  deleteQuestionTemplateOption({
    organizationId,
    questionTemplateId,
    optionId,
  }) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/template/question/${questionTemplateId}/option/${optionId}/`
    );
  },
};
