import OrganizationAPI from "../Services/organization.api";

import Organization from "@/core/Models/Organization";
import Collaborator from "@/core/Models/Collaborator";
import { getOrganizationStatic } from "../Services/organizationDashboard.service";
import organizationApi from "../Services/organization.api";

export default {
  namespaced: true,
  state: {
    organizations: [],
    organizationsPromise: null,
    activeOrganization: null,
    isLoadingActiveOrganizationCollaborators: null,
    statisticWorkflows: [],
    activeCollaborator: null,
    organizationDocxTemplates: [],
    isRemoveCollaboratorModalOpen: false,
    documentControlLists: [],
    isOrganizationInformationModalOpen: {
      status: false,
    },
    questionCategories: [],
    modals: {
      isGenerateDocxModalOpen: false,
    },
  },
  mutations: {
    setOrganizations(state, payload) {
      state.organizations = payload
        ? payload.map((organization) => new Organization(organization))
        : [];
    },
    SET_DOCUMENTS_CONTROL_LIST: (state, payload) => {
      state.documentControlLists = [...payload];
    },
    SET_STATISTICS_WORKFLOWS: (state, payload) => {
      state.statisticWorkflows = [...payload];
    },
    SET_ACCOUNT_ORGANIZATIONS_PROMISE(state, payload) {
      state.accountOrganizationsPromise = payload;
    },
    setOrganizationDocxTemplates(state, payload) {
      state.organizationDocxTemplates = [...payload];
    },
    setGenerateDocxModalOpen(state, payload) {
      state.modals.isGenerateDocxModalOpen = payload;
    },

    setOrganizationsPromise(state, payload) {
      state.organizationsPromise = payload;
    },
    setActiveOrganization(state, payload) {
      state.activeOrganization = payload ? new Organization(payload) : null;
    },
    setIsLoadingActiveOrganizationCollaborators(state, payload) {
      state.isLoadingActiveOrganizationCollaborators = payload;
    },
    setIsOrganizationEditInformationModalOpen(state, payload) {
      state.isOrganizationInformationModalOpen = { ...payload };
    },
    SET_ACTIVE_COLLABORATOR(state, payload) {
      state.activeCollaborator = payload;
    },
    SET_REMOVE_COLLABORATOR_DIALOG_STATUS(state, payload) {
      state.isRemoveCollaboratorModalOpen = payload;
    },
    SET_QUESTION_CATEGORIES: (state, payload) => {
      state.questionCategories = payload;
    },
  },
  actions: {
    //statistic
    getOrganizationStatistics: async ({ commit }, organizationID) => {
      let page = 1;
      const response = await getOrganizationStatic(organizationID, page);
      commit("SET_STATISTICS_WORKFLOWS", response.data.data);
    },
    getSSOLists: async () => {
      const response = await OrganizationAPI.getSSOReady();
      return response.data.results;
    },
    async getOrganizationDocumentControl({ commit }, organizationID) {
      const response = await OrganizationAPI.getOrganizationDocumentsControl(
        organizationID
      );
      commit("SET_DOCUMENTS_CONTROL_LIST", response.data.results);
    },
    async postOrganizationDocumentControl(
      _,
      { organizationId, folderId, wizideeControlId }
    ) {
      const response = await OrganizationAPI.postOrganizationDocumentControl(
        organizationId,
        folderId,
        { validation_schema: wizideeControlId }
      );
      return response.data;
    },
    async postOrganizationDocumentControlRun(
      _,
      { organizationId, folderId, documentControlId }
    ) {
      await OrganizationAPI.postOrganizationDocumentControlRun(
        organizationId,
        folderId,
        documentControlId
      );
    },

    async fetchOrganizations({ state, commit }) {
      commit("setOrganizations", []);
      async function fetchOrganizationsPage(page) {
        const res = await OrganizationAPI.getOrganizations(page);
        const organizations = res.data.results;
        if (page == 1) {
          commit("setOrganizations", organizations);
        } else {
          commit("setOrganizations", [
            ...state.organizations,
            ...organizations,
          ]);
          commit("setOrganizationsPromise", null);
        }
        if (res.data.next) {
          fetchOrganizationsPage(res.data.next);
        }
        return organizations;
      }
      if (state.organizations.length) {
        return state.organizations;
      }
      if (state.organizationsPromise) {
        return state.organizationsPromise;
      }
      const promise = fetchOrganizationsPage(1);
      commit("setOrganizationsPromise", promise);
      const result = await promise;
      commit("setOrganizationsPromise", null);
      return result;
    },
    async getOrganizationDocxTemplate({ commit }, { organizationId }) {
      const response = await organizationApi.getOrganizationDocumentTemplates(
        organizationId
      );
      commit("setOrganizationDocxTemplates", response.data.results);
    },
    async generateDocx(_, { organizationId, folderId, payload }) {
      return await organizationApi.postOrganizationDocumentGenerate({
        organizationId,
        folderId,
        payload,
      });
    },
    async sentToGED(_, { organizationId, folderId }) {
      return await organizationApi.postOrganizationToGED({
        organizationId,
        folderId,
      });
    },
    async setActiveOrganizationById({ state, commit }, organizationId) {
      if (state.organizationsPromise) {
        await state.organizationsPromise;
      }
      const organization = state.organizations.find(
        (o) => o.id === Number.parseInt(organizationId)
      );
      commit("setActiveOrganization", organization);
    },
    async patchOrganization({ state, commit }, { organizationId, payload }) {
      const res = await OrganizationAPI.patchOrganization(
        organizationId,
        payload
      );
      const organization = res.data;
      const organizations = [...state.organizations];
      organizations.slice(
        organizations.find((o) => o.id === organization.id),
        1,
        organization
      );
      commit("setOrganizations", organizations);
      commit("setActiveOrganization", organization);
      return organization;
    },
    async fetchOrganizationCollaborators({ state, commit }, organizationId) {
      async function fetchOrganizationCollaboratorsPage(page) {
        const res = await OrganizationAPI.getOrganizationCollaborators(
          organizationId,
          page
        );
        if (state.activeOrganization?.id != organizationId || !res) {
          return;
        }
        const collaborators = res.data.results.length
          ? res.data?.results?.map((collaborator) => {
              return new Collaborator({
                ...collaborator,
                status: collaborator?.function,
              });
            })
          : [];
        commit(
          "setActiveOrganization",
          new Organization({
            ...state.activeOrganization,
            collaborators: [
              ...state.activeOrganization.collaborators,
              ...collaborators,
            ],
          })
        );
        if (res.data.next) {
          await fetchOrganizationCollaboratorsPage(res.data.next);
        }
        return state.activeOrganization.collaborators;
      }
      if (state.activeOrganization.collaborators.length) {
        return state.activeOrganization.collaborators;
      }
      commit("setIsLoadingActiveOrganizationCollaborators", true);
      const result = await fetchOrganizationCollaboratorsPage(1);
      commit("setIsLoadingActiveOrganizationCollaborators", false);
      return result;
    },
    async fetchWalletOrganization({ commit }, organizationId) {
      const res = await OrganizationAPI.getWalletOrganization(organizationId);
      const organization = res.data;
      commit("setActiveOrganization", organization);
      return organization;
    },
    async removeCollaborator({ state, commit }, { collaboratorId }) {
      const res = await OrganizationAPI.deleteOrganizationCollaborator({
        organizationId: state.activeOrganization.id,
        collaboratorId,
      });
      if (res?.status === 204) {
        commit(
          "setActiveOrganization",
          new Organization({
            ...state.activeOrganization,
            collaborators: state.activeOrganization.collaborators.filter(
              (c) => c.id !== collaboratorId
            ),
          })
        );
      } else {
        throw new Error("Error Has Happen");
      }
    },
  },
  getters: {
    listOrganizationStatistics: (state) => state.statisticWorkflows,
  },
};
