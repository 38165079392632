<template>
  <v-icon v-bind="$attrs" v-on="$listeners">
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
};
</script>
