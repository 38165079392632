import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

export default function registration() {
  function registrateFolder(baseComponentsContext) {
    baseComponentsContext.keys().forEach((fileName) => {
      // Get component config
      const componentBaseConfig = baseComponentsContext(fileName);

      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          fileName
            .split("/")
            .pop()
            .replace(/\.\w+$/, "")
        )
      );

      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentBaseConfig.default || componentBaseConfig
      );
    });
  }

  const coreBaseComponents = require.context(
    // The relative path of the components folder
    "../../core/Components/UI",
    // Whether or not to look in subfoldersthis.$route
    true,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
  );
  const walletBaseComponents = require.context(
    // The relative path of the components folder
    "../../modules/Wallet/Components/UI",
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /BaseFrontOffice[A-Z]\w+\.(vue|js)$/
  );
  registrateFolder(coreBaseComponents);
  registrateFolder(walletBaseComponents);
}
