import Question from "./Question";
import TargetStatus from "./TargetStatus";
import CollaboratorAssignation from "./CollaboratorAssignation";
import Action from "./Action";
import { QuestionCategory } from "./QuestionCategory";

export default class Status {
  constructor({
    id = Number.NaN,
    name = "",
    questions = [],
    targetStatuses = [],
    actions = [],
    collaboratorsAssigned = [],
    dateCreated = new Date(),
    dateUpdated = new Date(),
    categories = [],
    order = Number.NaN,
  } = {}) {
    this.id = Number.parseInt(id);
    this.order = order;
    this.name = name;
    this.questions = questions
      ? questions.map((question) => new Question(question))
      : [];
    this.targetStatuses = targetStatuses
      ? targetStatuses.map((targetStatus) => new TargetStatus(targetStatus))
      : [];
    this.actions = actions ? actions.map((action) => new Action(action)) : [];
    this.collaboratorsAssigned = collaboratorsAssigned
      ? collaboratorsAssigned.map(
          (collaboratorsAssigned) =>
            new CollaboratorAssignation(collaboratorsAssigned)
        )
      : [];
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
    this.categories = categories
      ? categories.map((sc) => new QuestionCategory(sc))
      : [];
  }
}
