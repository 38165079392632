export const SIGNATORY_TYPE = {
  ORGANIZATION_SIGNATORY: "ORGANIZATION_SIGNATORY",
  PERSONAL_SIGNATORY: "PERSONAL_SIGNATORY",
  FOLDER_SIGNATORY: "FOLDER_SIGNATORY",
};

export class Signatory {
  constructor({
    id = Number.NaN,
    email = "",
    firstName = "",
    lastName = "",
    phoneNumber = "",
    type = SIGNATORY_TYPE.PERSONAL_SIGNATORY,
    action = "signer",
  } = {}) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber ? phoneNumber.replace("+33", 0) : "";
    this.type = type;
    this.action = action;
  }

  get uniqId() {
    return `${this.id}${this.email}${this.type}`;
  }

  get icon() {
    switch (this.type) {
      case SIGNATORY_TYPE.ORGANIZATION_SIGNATORY:
        return "$mdiDomain";
      case SIGNATORY_TYPE.PERSONAL_SIGNATORY:
        return "$mdiHome";
      case SIGNATORY_TYPE.FOLDER_SIGNATORY:
        return "$mdiAccount";
      default:
        return "";
    }
  }
}
