<template>
  <div>
    <BaseTitle class="mb-6" v-text="$t('workflow.management')" />
    <div v-if="activeWorkflow">
      <!-- Workflow Header -->
      <Board class="mb-6">
        <template #header class="mb-6">
          <BaseTitle v-text="activeWorkflow.name" />
        </template>
        <template #main>
          <BaseDivider class="my-5" :grey="true" />
          <div class="d-flex justify-space-between align-center">
            <!-- Edit workflow button -->
            <BaseButton
              icon="$mdiPencilOutline"
              @click="
                $store.commit(
                  'workflow/setModalCreateAndEditWorkflowDialog',
                  true
                )
              "
            >
              <span v-text="$t('workflow.modifyWorkflow')" />
            </BaseButton>
          </div>
        </template>
      </Board>
      <Board class="mb-6">
        <template #header class="mb-6">
          <div class="d-flex justify-space-between align-center">
            <BaseTitle v-text="'Statuts'" />
            <!-- create workflow status button -->
            <BaseButton
              icon="$mdiPlus"
              @click="
                $store.commit(
                  'workflow/setModalCreateAndEditStatusDialog',
                  true
                )
              "
            >
              Créer un statut
            </BaseButton>
          </div>
        </template>
        <template #main>
          <WorkflowDetailStatus
            v-for="(status, index) in activeWorkflow.statuses"
            :key="index"
            :index-status="index + 1"
            :status="status"
            :is-last-status="activeWorkflow.statuses.length - 1 === +index"
       
          />
          <BaseAlert
            v-if="activeWorkflow.statuses.length === 0"
            color="secondary lighten-5 text-center"
          >
            <BaseParagraph bold v-text="$t('workflow.status.noStatus')" />
          </BaseAlert>
        </template>
      </Board>
    </div>
    <WorkflowCreateAndEditDialog />
    <WorkflowCreateAndEditStatusDialog />
    <WorkflowDeleteStatusDialog />
    <WorkflowStatusQuestionCreateAndEditDialog />
    <WorkflowStatusQuestionDeleteDialog />
    <WorkflowStatusTargetStatusCreateAndEditDialog />
    <WorkflowStatusTargetStatusDeleteDialog />
    <WorkFlowCollaboratorsAddAndDelDialog />
    <WorkflowRemoveStatusCategory />
    <WorkflowEditStatusCategory />
  </div>
</template>

<script>
import Board from "@/core/Components/Board.vue";
import WorkflowDetailStatus from "../Components/WorkflowDetailStatus";
import WorkflowCreateAndEditDialog from "../Components/WorkflowCreateAndEditDialog";
import WorkflowCreateAndEditStatusDialog from "../Components/WorkflowCreateAndEditStatusDialog";
import WorkflowDeleteStatusDialog from "../Components/WorkflowDeleteStatusDialog";
import WorkflowStatusQuestionCreateAndEditDialog from "../Components/WorkflowStatusQuestionCreateAndEditDialog";
import WorkflowStatusQuestionDeleteDialog from "../Components/WorkflowStatusQuestionDeleteDialog";
import WorkflowStatusTargetStatusCreateAndEditDialog from "../Components/WorkflowStatusTargetStatusCreateAndEditDialog";
import WorkflowStatusTargetStatusDeleteDialog from "../Components/WorkflowStatusTargetStatusDeleteDialog";
import WorkFlowCollaboratorsAddAndDelDialog from "../Components/WorkFlowCollaboratorsAddAndDelDialog";
import WorkflowRemoveStatusCategory from "../Components/WorkflowRemoveStatusCategory";
import WorkflowEditStatusCategory from "../Components/WorkflowEditStatusCategory";

import { mapState } from "vuex";

export default {
  name: "WorkflowDetail",
  components: {
    Board,
    WorkflowDetailStatus,
    WorkflowCreateAndEditDialog,
    WorkflowCreateAndEditStatusDialog,
    WorkflowDeleteStatusDialog,
    WorkflowStatusQuestionCreateAndEditDialog,
    WorkflowStatusQuestionDeleteDialog,
    WorkflowStatusTargetStatusCreateAndEditDialog,
    WorkflowStatusTargetStatusDeleteDialog,
    WorkFlowCollaboratorsAddAndDelDialog,
    WorkflowRemoveStatusCategory,
    WorkflowEditStatusCategory,
  },
  props: {
    workflowId: {
      type: String,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
    }),
  },
  watch: {
    workflowId() {
      this.initialize();
    },
    activeWorkflow: {
      handler(newVal, oldVal) {
        if ((!oldVal && newVal) || (newVal && newVal.name !== oldVal.name)) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        if (!this.workflowId) return;
        await this.$store.dispatch("workflow/fetchActiveWorkflow", {
          organizationId: this.organizationId,
          workflowId: this.workflowId,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[3].label = `${this.activeWorkflow.name} `;
    },
  },
};
</script>
