import { EMAIL_STATUS } from "@/core/Utils/types.utils";

export class TransactionalEmail {
  constructor({
    id = Number.NaN,
    status = EMAIL_STATUS.IN_PROGRESS,
    dateCreated = new Date(),
    dateUpdated = new Date(),
  } = {}) {
    this.id = id ? Number.parseInt(id) : null;
    this.status = status;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
  }
}
