var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOpen)?_c('BaseDialog',{attrs:{"width":"unset","value":_vm.isOpen},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.reset()},"click:outside":function($event){return _vm.reset()}},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('BaseCosmoCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"my-2",domProps:{"textContent":_vm._s(_vm.$t('folder.logs.history'))}})]},proxy:true},{key:"actions",fn:function(){return [_c('BaseSpacer'),_c('BaseButton',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")])]},proxy:true}],null,false,639027890)},[[_c('BaseDataTable',{staticClass:"elevation-1 ma-6",attrs:{"headers":_vm.headers,"items":_vm.allRecordsLogs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('BaseParagraph',{staticClass:"ml-auto mr-auto d-block mb-1",attrs:{"text":"secondary"},domProps:{"textContent":_vm._s(
                      ((_vm.$d(new Date(item.dateCreated), 'veryshort')) + " " + (_vm.$d(
                        new Date(item.dateCreated),
                        'time'
                      )))
                    )}})],1)]),_c('td',[_c('div',{staticClass:"d-flex flex-column align-start py-5"},[_c('BaseParagraph',{staticClass:"d-block mb-1 mx-auto",attrs:{"text":"secondary"},domProps:{"innerHTML":_vm._s(item.actionDisplay)}})],1)])])]}}],null,false,3776290151)})]],2)]},proxy:true}],null,false,515850771)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }