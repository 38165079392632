export default {
  namespaced: true,
  state: {
    initializationLoader: false,
    hasLoggedNormaly: false,
  },
  mutations: {
    SET_INITIALIZATION_LOADER(state, boolean) {
      state.initializationLoader = boolean;
    },
    SET_HAS_LOGGED_NORMALY(state, payload) {
      state.hasLoggedNormaly = payload;
    },
  },
  actions: {
    resetApp({ commit, dispatch }) {
      commit("SET_INITIALIZATION_LOADER", true);
      commit("organization/setOrganizations", null, {
        root: true,
      });
      dispatch("account/resetUser", null, {
        root: true,
      });
      commit("SET_INITIALIZATION_LOADER", false);
    },
  },
  getters: {
    isAppReady: (state) => !state.initializationLoader,
  },
};
