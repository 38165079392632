import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getCosmoforms(token) {
    return cosmoAPI.get(`/cosmoform/${token}/question/`);
  },
  getCosmoformCategories(token) {
    return cosmoAPI.get(`/cosmoform/${token}/category/`);
  },
  getCosmoformOrganization(token) {
    return cosmoAPI.get(`/cosmoform/${token}/`);
  },
  getCosmoformDetail(organizationId, folderId) {
    return cosmoAPI.get(
      `/wallet/organization/${organizationId}/folder/${folderId}/cosmoform/`
    );
  },
  postAnsweredCosmoform({ token, questionId, payload }) {
    return cosmoAPI.post(
      `/cosmoform/${token}/question/${questionId}/answer/`,
      payload
    );
  },
  editAnswseredCosmoform({ token, questionId, answerId, payload }) {
    return cosmoAPI.patch(
      `/cosmoform/${token}/question/${questionId}/answer/${answerId}/`,
      payload
    );
  },
  postExpiredCosmoformToken({ token }) {
    return cosmoAPI.post(`/cosmoform/${token}/expired/`);
  },
};
