<template>
  <div>
    <TheFrontOfficeBaseApp>
      <v-main>
        <div :class="{ 'cosmo-main': !displayCourseInfo }">
          <div :class="{ 'cosmo-main-container': !displayCourseInfo }">
            <router-view />
          </div>
        </div>
      </v-main>
      <TheFrontOfficeSnackbar />
    </TheFrontOfficeBaseApp>
  </div>
</template>

<script>
import TheFrontOfficeBaseApp from "@/modules/Wallet/Components/TheFrontOfficeBaseApp.vue";
import TheFrontOfficeSnackbar from "@/modules/Wallet/Components/TheFrontOfficeSnackbar";

export default {
  name: "LoginSignupLayout",
  components: {
    TheFrontOfficeBaseApp,
    TheFrontOfficeSnackbar,
  },
  computed: {
    displayCourseInfo() {
      return !!this.$route.params.accessCampaign;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {},
  },
};
</script>

<style>
:root {
  --cosmo-font-nunito: "Nunito-SemiBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cosmo-gradient-primary: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 51.56%,
      rgba(252, 192, 15, 0.5) 100%
    ),
    #ff7074;
  --cosmo-gradient-secondary: linear-gradient(
      147.45deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    ),
    #ff5b60;
  --cosmo-gradient-tertiary: linear-gradient(
      102.88deg,
      #2f479d 40.71%,
      #965b89 73.04%,
      #ff7074 90.62%,
      rgba(255, 112, 116, 0.2) 101.58%
    ),
    #fcc00f;
}
</style>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
  background-color: white;
}
::v-deep .theme--light.v-application {
  background: none;
}
.cosmo-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px 0px;
}
.cosmo-main-container {
  width: 1000px;
}

@media all and (max-width: 600px) {
  .cosmo-main {
    height: auto;
  }
}
@media all and (max-width: 960px) {
  .cosmo-main-container {
    width: 95%;
  }
}

.theme--light.v-sheet {
  border-color: auto !important;
}
.v-application .rounded-5 {
  border-radius: auto !important;
}
.v-application .pa-6 {
  padding: auto !important;
}
.v-application .mb-6 {
  margin-bottom: auto !important;
}
</style>
