import { cosmoAPI, cosmoWEB } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region User - API
  postUserRegister(payload) {
    return cosmoAPI.post("/signup/", payload);
  },
  getUser() {
    return cosmoAPI.get("/me/");
  },
  patchUserAvatar(payload) {
    return cosmoAPI.patch("/user/", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  patchUserPassword(payload) {
    return cosmoAPI.post("/user/password/", payload);
  },
  deleteUser(payload) {
    return cosmoAPI.delete("/user/", { data: payload });
  },
  passwordReset(payload) {
    return cosmoWEB.post("/api/password_reset/", payload);
  },
  passwordResetValidateToken(payload) {
    return cosmoWEB.post("/api/password_reset/validate_token/", payload);
  },
  passwordResetConfirm(payload) {
    return cosmoWEB.post("/api/password_reset/confirm/", payload);
  },
  //#endregion
};
