import Cosmoform from "@/core/Models/Cosmoform";
import {
  getCosmoforms,
  submitCosmoformAnswer,
  getCosmoformOrganization,
  getCosmoformDetail,
} from "../Services/cosmoform.service";

export default {
  namespaced: true,
  state: {
    cosmoforms: [],
    selectedCosmoform: null,
    cosmoFormOrganization: null,
    cosmoFormDetail: [],
    modals: {
      fillCosmoformState: false,
    },
  },
  mutations: {
    SET_Fill_COSMOFORM_DIALOG_STATE(state, payload) {
      state.modals.fillCosmoformState = payload;
    },
    SET_COSMOFORMS(state, payload) {
      state.cosmoforms = payload;
    },
    SET_SELECTED_COSMOFORM(state, payload) {
      state.selectedCosmoform = payload;
    },
    SET_COSMOFORM_ORGANIZATION(state, payload) {
      state.cosmoFormOrganization = payload;
    },
    SET_COSMOFORM_FOLDER_DETAIL(state, payload) {
      state.cosmoFormDetail = payload;
    },
  },
  actions: {
    setFillCosmoformDialogState({ commit }, payload) {
      commit("SET_Fill_COSMOFORM_DIALOG_STATE", payload);
    },
    async fetchCosmoFormOrganization({ commit }, payload) {
      const response = await getCosmoformOrganization(payload);
      commit("SET_COSMOFORM_ORGANIZATION", response.data);
    },
    async fetchCosmoFormFolderDetail({ commit }, { organizationId, folderId }) {
      commit("SET_COSMOFORM_FOLDER_DETAIL", []);
      const response = await getCosmoformDetail(organizationId, folderId);
      commit("SET_COSMOFORM_FOLDER_DETAIL", response);
    },
    async fetchCosmoforms({ state, commit }, { token }) {
      let res = await getCosmoforms(token);
      if (res.data) {
        commit(
          "SET_COSMOFORMS",
          res.data.results.map(function (cosmoform) {
            return new Cosmoform({ ...cosmoform });
          })
        );
      }
      return state.cosmoforms;
    },
    async submitCosmoformsAnswer({ state }, { token }) {
      localStorage.folderId = "";
      let folderId = await submitCosmoformAnswer({
        token,
        cosmoforms: state.cosmoforms,
      });
      localStorage.folderId = folderId;
      return folderId;
    },
  },
};
