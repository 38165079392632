<template>
  <v-menu v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>
    <slot name="content" />
  </v-menu>
</template>

<script>
export default {
  name: "BaseMenu",
};
</script>
