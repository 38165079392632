<template>
  <div class="stepper_container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseStepper",
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    consultedStep: {
      set: function (value) {
        this.$emit("updateValue", value);
      },
      get: function () {
        return this.value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper_container {
  width: 100%;
}
</style>
