import { render, staticRenderFns } from "./PersonalDataPreviewer.vue?vue&type=template&id=6516b6e2&scoped=true"
import script from "./PersonalDataPreviewer.vue?vue&type=script&lang=js"
export * from "./PersonalDataPreviewer.vue?vue&type=script&lang=js"
import style0 from "./PersonalDataPreviewer.vue?vue&type=style&index=0&id=6516b6e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6516b6e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCheckbox,VSkeletonLoader})
