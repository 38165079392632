<template>
  <v-chip
    :color="chipProps.color"
    :text-color="chipProps.textColor"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
</template>

<script>
export default {
  name: "BaseChip",
  props: {
    type: {
      type: String,
      default: () => "primary",
    },
  },
  computed: {
    chipProps() {
      switch (this.type) {
        case "primary": // Done
          return {
            color: "primary lighten-5",
            textColor: "primary",
          };
        case "secondary": // On-going
          return {
            color: "accent lighten-5",
            textColor: "accent",
          };
        case "tertiary": // New
          return {
            color: "success lighten-5",
            textColor: "success",
          };
        case "error": // Error
          return {
            color: "secondary lighten-5",
            textColor: "secondary",
          };
        case "accent":
          return {
            color: "accent lighten-5",
            textColor: "accent",
          };
        case "done":
          return {
            color: "done lighten-5",
            textColor: "done",
          };
        default:
          break;
      }
      return {};
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-size--small {
    padding: 4px 8px;
    height: 18px;
  }
  .v-chip__content {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 500;
  }
}
</style>
