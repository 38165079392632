import Organization from "@/core/Models/Organization";
import organizationApi from "../Services/organization.api";

export default {
  namespaced: true,
  state: {
    organizations: [],
  },
  mutations: {
    SET_ORGANISATIONS(state, payload) {
      state.organizations = payload
        ? payload.map((organization) => new Organization(organization))
        : [];
    },
  },
  actions: {
    async fetchOrganizations({ state, commit }) {
      let page = 1;
      commit("SET_ORGANISATIONS", []);
      let results = [];
      while (page) {
        const res = await organizationApi.getOrganizations(page);
        results = [...results, ...res.data.results];
        page = res.data.next;
      }
      commit("SET_ORGANISATIONS", [...results]);
      return state.organizations;
    },
  },
};
