<template>
  <BaseFrontOfficeWalletWrapper
    :mobile-title-image="$findStatic('cosmoWalletAuthImage')"
    :mobile-title-message="$t('wallet.welcomeMessage')"
  >
    <template #title>
      <BaseFrontOfficeTitle v-text="$t('wallet.reserved.title')" />
    </template>
    <BaseFrontOfficeBoard>
      <div class="d-flex flex-column-reverse flex-sm-row">
        <div>
          <BaseFrontOfficeTitle v-text="$t('wallet.reserved.oups')" />
          <BaseFrontOfficeParagraph
            class="d-block"
            v-text="$t('wallet.reserved.message')"
          />
        </div>
        <div class="d-flex justify-center ml-sm-4 mb-4 mb-sm-0">
          <BaseFrontOfficeImage
            :src="$findStatic('reservedCourse')"
            max-width="100"
            width="100"
            contain
          />
        </div>
      </div>
      <div
        class="d-flex justify-center justify-md-end flex-column flex-md-row mt-6"
      >
        <BaseFrontOfficeButton
          type="primary"
          class="align-self-center"
          :to="{ name: isLogged ? 'WalletHome' : 'WalletLogin' }"
        >
          {{
            isLogged ? $t("wallet.reserved.goBack") : $t("wallet.signIn.submit")
          }}
        </BaseFrontOfficeButton>
      </div>
    </BaseFrontOfficeBoard>
  </BaseFrontOfficeWalletWrapper>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WalletReserved",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    isLogged() {
      if (this.user) return true;
      return false;
    },
  },
};
</script>
