<template>
  <div>
    <div class="bo_title mb-6" v-text="'Gestion des workflows'" />
    <Board class="mb-6">
      <template #header class="mb-6">
        <div class="d-flex justify-space-between align-center">
          <!-- Create workflow button -->
          <BaseButton
            icon="$mdiPlus"
            @click="
              $store.commit(
                'workflow/setModalCreateAndEditWorkflowDialog',
                true
              )
            "
          >
            <span v-text="$t('workflow.createNewWorkflow')" />
          </BaseButton>
          <div>
            <v-text-field
              v-model="searchValue"
              outlined
              :label="$t('folder.search')"
              prepend-inner-icon="$mdiMagnify"
              dense
              hide-details
            />
          </div>
        </div>
      </template>
      <template #main>
        <div>
          <BaseRow v-if="workflows.length > 0">
            <BaseCol
              v-for="(workflow, index) in filteredWorkflows"
              :key="index"
              cols="4"
            >
              <BaseSheet v-ripple class="workflow-sheet elevation-3 pa-6">
                <router-link
                  :to="{
                    name: 'workflowDetail',
                    params: {
                      workflowId: `${workflow.id}`,
                    },
                  }"
                >
                  <div
                    class="text"
                    text="primary"
                    truncate
                    v-text="workflow.name"
                  />
                  <BaseDivider grey class="my-4" />
                  <div class="text status_info">
                    <div
                      class="mb-1"
                      type="primary"
                      truncate
                      v-text="$tc('utils.status', 3)"
                    />
                    <div
                      type="secondary"
                      truncate
                      v-text="workflow.statuses.length"
                    />
                  </div>
                  <BaseDivider grey class="my-4" />
                  <div class="d-flex justify-end">
                    <BaseButtonIcon
                      color="secondary"
                      x-small
                      icon="$mdiTrashCanOutline"
                      @mousedown.stop
                      @click.prevent="
                        () => {
                          $store.commit(
                            'workflow/SET_ACTIVE_WORKFLOW',
                            workflow
                          );
                          $store.commit(
                            'workflow/setModalDeleteWorkflowDialog',
                            true
                          );
                        }
                      "
                    />
                  </div>
                </router-link>
              </BaseSheet>
            </BaseCol>
          </BaseRow>
          <div v-else-if="isFetchingWorkflow">
            <BaseRow>
              <BaseCol v-for="index in [1, 2, 3, 4, 5]" :key="index" cols="4">
                <BaseSheet class="elevation-3 pa-4">
                  <BaseSkeletonLoader type="article, actions" />
                </BaseSheet>
              </BaseCol>
            </BaseRow>
          </div>
          <div v-else class="d-flex justify-center">
            <BaseParagraph
              type="primary"
              v-text="$t('workflow.noWorkflowAvailable')"
            />
          </div>
        </div>
      </template>
    </Board>

    <WorkflowCreateAndEditDialog />
    <WorkflowDeleteDialog
      @close="
        () => {
          $store.commit('workflow/SET_ACTIVE_WORKFLOW', null);
        }
      "
    />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import WorkflowCreateAndEditDialog from "./WorkflowCreateAndEditDialog";
import WorkflowDeleteDialog from "./WorkflowDeleteDialog";

import { mapState } from "vuex";

export default {
  name: "WorkflowList",
  components: {
    Board,
    WorkflowCreateAndEditDialog,
    WorkflowDeleteDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isFetchingWorkflow: true,
      searchValue: "",
    };
  },
  computed: {
    ...mapState({
      workflows: (state) => state.workflow.workflows,
    }),
    filteredWorkflows() {
      if (!this.searchValue) return this.workflows;
      return this.workflows.filter((w) =>
        w.name.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    },
  },
  watch: {
    organizationId() {
      this.reset();
      this.initialize();
    },
  },
  created() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", null);
    },
    async initialize() {
      this.isFetchingWorkflow = true;
      await this.$store.dispatch(
        "workflow/fetchWorkflows",
        this.$route.params.organizationId
      );
      this.isFetchingWorkflow = false;
    },
  },
};
</script>

<style scoped>
.workflow-sheet {
  cursor: pointer;
}
::v-deep .workflow-sheet a {
  text-decoration: none;
}
.status_info {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
}
</style>
