<template>
  <div class="w_container">
    <div class="left_section">
      <a class="img_container">
        <div class="menu__item" @click.stop="">
          <v-menu offset-y @click.stop="">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" @click.stop="">
                <div class="menu__activator">
                  <BaseIcon icon="$mdiDotsHorizontal" color="dark" />
                </div>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(template, index) in [
                  {
                    key: 'download',
                    title: 'Télécharger le fichier',
                    icon: '$mdiDownload',
                    disabled: false,
                  },
                  {
                    key: 'delete',
                    title: 'Supprimer le fichier',
                    icon: '$mdiDelete',
                    disabled: true,
                  },
                  {
                    key: 'sendGed',
                    title: $t('utils.sendToGed'),
                    icon: '$mdiFileCog',
                    disabled: !hasEnoxa,
                  },
                ]"
                :key="index"
                class="item-list"
              >
                <div
                  class="d-flex align-items-center"
                  :class="{ disabled: template.disabled }"
                  @click.stop="!template.disabled && activeAction(template.key)"
                >
                  <BaseIcon :icon="template.icon" color="black" :size="18" />
                  <div class="label__menu" v-text="template.title" />
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <BasePdfViewer v-if="isFilePdf" :pdf="imgSrc" />
        <img v-else :src="imgSrc" @click.stop="open(imgSrc)" />
      </a>
    </div>
    <div class="rigth_section">
      <div class="item">
        <div
          v-for="(data, index) in wizideeDataDataList"
          :key="index"
          class="content_field"
        >
          <div class="label">{{ data.key }}</div>
          <div
            v-if="data.isImage"
            class="response url"
            @click="goToFile(data.value)"
          >
            {{ $t("utils.seeFile") }}
          </div>
          <div v-else class="response">{{ data.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePdfViewer from "@/core/Components/UI/PdfViewer/BasePdfViewer.vue";
import { dataURLtoFile } from "@/core/Utils/file.utils";
import { validationMixin } from "vuelidate";
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
  name: "WezideeClientData",
  components: {
    BasePdfViewer,
  },
  mixins: [validationMixin],
  props: {
    content: {
      type: Array,
      required: true,
    },
    extractedData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imgSrc: null,
      isSendingToGed: false,
      enoxaErrorDialogState: false,
      errorCode: "",
      dialogState: false,
    };
  },
  computed: {
    ...mapState({
      activeStatus: (state) => state.workflow.activeStatus,
    }),
    isDisabled() {
      return false;
    },
    hasEnoxa() {
      return this.activeOrganization?.enoxa?.serverName;
    },
    wizideeDataDataList() {
      return [
        {
          key: this.$t("utils.authority"),
          value: this.extractedData.authority,
        },
        {
          key: this.$t("utils.id"),
          value: this.extractedData.permitId,
        },
        {
          key: this.$t("utils.permitList"),
          value: this.extractedData.permitList,
        },
        {
          key: this.$t("utils.useName"),
          value: this.extractedData.useName,
        },
        {
          key: this.$tc("utils.name", 1),
          value: this.extractedData.name,
        },
        {
          key: this.$t("utils.firstName"),
          value: this.extractedData.firstName,
        },
        {
          key: this.$t("utils.birthDate"),
          value: this.extractedData.birthDate,
        },
        {
          key: this.$t("utils.birthPlace"),
          value: this.extractedData.birthPlace,
        },
        {
          key: this.$t("utils.height"),
          value: this.extractedData.height,
        },
        {
          key: this.$t("utils.addressFullText"),
          value: this.extractedData.addressFullText,
        },
        {
          key: this.$t("utils.validityDate"),
          value: this.extractedData.validityDate,
        },
        {
          key: this.$tc("utils.releaseDate", 1),
          value: this.extractedData.releaseDate,
        },
        {
          key: this.$tc("utils.iban"),
          value: this.extractedData.iban,
        },
        {
          key: this.$tc("utils.bic"),
          value: this.extractedData.bic,
        },
        {
          key: this.$tc("utils.holder"),
          value: this.extractedData.holder,
        },
        {
          key: this.$tc("utils.socialNumber"),
          value: this.extractedData.socialNumber,
        },
        {
          key: this.$t("utils.signature"),
          value: this.extractedData.signature,
          isImage: true,
        },
        {
          key: this.$t("utils.photo"),
          value: this.extractedData.photo,
          isImage: true,
        },
      ].filter((wd) => wd.value);
    },
    isFilePdf() {
      return this.imgSrc?.split(",")[0].split(";")[0].includes("pdf");
    },
  },

  mounted() {
    this.initialize();
  },
  methods: {
    async goToFile(value) {
      const file = await dataURLtoFile(value, "file");
      open(URL.createObjectURL(file));
    },
    async activeAction(action) {
      switch (action) {
        case "download": {
          return saveAs(this.content[0].url, this.content[0].name);
        }
        case "delete": {
          return;
        }
        case "sendGed": {
          return;
        }
      }
    },
    initialize() {
      this.imgSrc = this.content[0].url;
    },
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.w_container {
  --img-width: 294px;
  --img-height: 200px;
  position: relative;
  width: calc(100% - 10px);
  margin: 10px 5px;
  display: flex;
  .left_section {
    flex: none;
    width: var(--img-width);
    .img_container {
      display: block;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: var(--img-height);
      border: 3px solid #b6bdff;
      border-radius: 10px;
      overflow: hidden;
      .menu__item {
        position: absolute;
        z-index: 12;
        right: 12px;
        top: 12px;
        opacity: 0.2;
        &:hover {
          opacity: 1;
        }
        .menu__activator {
          background: white;
          border-radius: 5px;
          width: 31px;
          height: 31px;
          display: flex;
          align-content: center;
          justify-content: center;
        }
      }
      & > img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .file_action {
      display: flex;
      margin-top: 10px;
      justify-content: flex-end;
      position: relative;
      .input {
        display: none;
      }
    }
  }
  .rigth_section {
    display: flex;
    width: 100%;
    margin-left: 64px;
    justify-content: space-between;
    .item {
      flex: 1;
      padding: 14px;
      display: flex;
      gap: 16px;
      max-height: 250px;
      overflow-y: auto;
      flex-wrap: wrap;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #eeeef7;
      }

      &::-webkit-scrollbar-thumb {
        background: #b6bdff;
        border-radius: 3px;
      }
      .content_field {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 46%;
        .label {
          color: #707080;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 100% */
          letter-spacing: 0.018px;
        }
        .response {
          color: #353542;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &.url {
            color: #2536cc;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.label__menu {
  color: var(--Principale-Gris-Texte, #707080);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
}
.item-list {
  &:hover {
    background: #f5f8ff;
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.2;
}
</style>
