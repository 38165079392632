<template>
  <div>
    <div class="record_detail_container">
      <template v-if="isLoadingActiveRecord">
        <BaseFrontOfficeBoard>
          <BaseSkeletonLoader type="list-item-avatar-two-line" />
        </BaseFrontOfficeBoard>
        <BaseFrontOfficeBoard>
          <BaseSkeletonLoader type="list-item-avatar" />
        </BaseFrontOfficeBoard>
        <BaseFrontOfficeBoard>
          <BaseLayout row wrap class="mx-10 my-3">
            <BaseFlex v-for="index in 7" :key="index" md6>
              <BaseSkeletonLoader
                type="image"
                class="mx-2 my-3 d-block"
                height="68px"
              />
            </BaseFlex>
          </BaseLayout>
        </BaseFrontOfficeBoard>
      </template>
      <template v-else>
        <div class="record_detail_header">
          <div class="left_section">
            <div class="back_btn">
              <v-btn
                icon
                @click="
                  redirectTo({
                    name: 'WalletOrganizationRecords',
                    params: {
                      organizationId:
                        activeRecord.campaign.course.organization.id,
                    },
                  })
                "
              >
                <BaseFrontOfficeIcon
                  icon="$mdiChevronLeftCircleOutline"
                  color="#444393"
                />
              </v-btn>
            </div>
            <div class="logo_container">
              <img class="logo" :src="headerLogo" />
            </div>
          </div>
          <div class="header_title">
            {{ activeRecord.campaign.course.name }}
          </div>
        </div>

        <div class="record_detail">
          <div class="record_description">
            <BaseMarkdownViewer
              :content="activeRecord.campaign.course.description"
            />
          </div>
          <div class="record_share_section">
            <BaseFrontOfficeSwitch
              v-model="form.recordStatus"
              class="ma-4"
              @change="dialogState.toggleAgreement = true"
            />
            <div>
              <BaseFrontOfficeParagraph
                v-text="$t('wallet.record.shareData')"
              />
              <BaseFrontOfficeDescription
                v-if="activeRecord.agreements[0].active"
                v-text="$t('wallet.record.shareDataActive')"
              />
              <BaseFrontOfficeDescription
                v-else
                v-text="$t('wallet.record.shareDataUnactive')"
              />
            </div>
          </div>
        </div>

        <div class="record_section">
          <div
            v-for="step in activeRecord.campaign.course.steps"
            :key="step.id"
            class="record_section_item"
          >
            <div class="record_step">
              <BaseFrontOfficeSubtitle v-text="step.name" />
              <BaseFrontOfficeDivider />
            </div>
            <div class="record_questions">
              <template v-for="question in step.questions">
                <div
                  v-if="!/^MESSAGE$/.test(question.typeQuestion)"
                  :key="`${question.id}`"
                  class="record_question_item"
                >
                  <WalletLabelData
                    v-if="question.answer"
                    :type-question="question.typeQuestion"
                    :content="question.answer.content"
                    :name="question.name"
                    :folder-closed="folderClosed"
                    :is-error="/^UPDATE_REQUESTED$/.test(question.validation)"
                    @show="openEditQuestionDialog(question, step)"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- MODAL -->
    <WalletSafeRecordToggleAgreementDialog
      :dialog-state="dialogState.toggleAgreement"
      :record="activeRecord"
      @update-agreement="updateAgreement($event)"
    />
    <WalletSafeRecordEditAnswerDialog
      :dialog-state="dialogState.editQuestion"
      :question="activeQuestion"
      :active-record="activeRecord"
      @updateQuestion="updateQuestion($event)"
      @close="dialogState.editQuestion = false"
    />
  </div>
</template>

<script>
import WalletLabelData from "../Components/WalletLabelData.vue";
import WalletSafeRecordToggleAgreementDialog from "../Components/WalletSafeRecordToggleAgreementDialog.vue";
import WalletSafeRecordEditAnswerDialog from "../Components/WalletSafeRecordEditAnswerDialog.vue";

import {
  fecthRecord,
  fecthRecordQuestion,
} from "@/modules/Wallet/Services/record.service";

export default {
  name: "WalletSafeRecordDetail",
  components: {
    WalletLabelData,
    WalletSafeRecordToggleAgreementDialog,
    WalletSafeRecordEditAnswerDialog,
  },
  props: {
    recordId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      form: {
        recordStatus: false,
      },
      activeRecord: null,
      activeQuestion: null,
      isLoadingActiveRecord: true,
      dialogState: {
        toggleAgreement: false,
        editQuestion: false,
      },
    };
  },
  computed: {
    headerLogo() {
      if (!this.activeRecord) return;
      return this.activeRecord.campaign.course.organization.logo
        ? this.activeRecord.campaign.course.organization.logo
        : this.$findStatic("cosmoWalletCloudData");
    },
    folderClosed() {
      if (this.activeRecord?.folderState == "CLOSED") return true;
      return false;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isLoadingActiveRecord = true;
      try {
        this.activeRecord = await fecthRecord(this.recordId);
        const questions = await fecthRecordQuestion(this.recordId);
        this.activeRecord.campaign.course.steps.forEach((step) => {
          step.questions = questions.filter((q) => q.step == step.id);
        });
        this.form.recordStatus = this.activeRecord.agreements[0].active;
      } catch (error) {
        if (error.message.includes("code 403")) {
          return this.$router.push({ name: "WalletReserved" });
        }
      }
      this.isLoadingActiveRecord = false;
    },
    openEditQuestionDialog(question) {
      this.activeQuestion = question;
      this.dialogState.editQuestion = true;
    },
    redirectTo(path) {
      this.$router.push(path);
    },
    updateAgreement(value) {
      this.dialogState.toggleAgreement = false;
      this.activeRecord.agreements[0].active = value;
      this.form.recordStatus = value;
    },
    updateQuestion(question) {
      const step = this.activeRecord.campaign.course.steps.find(
        (s) => s.id == question.step
      );
      step.questions.forEach((sq) => {
        if (sq.id == question.id) {
          sq.answer = question.answer;
          sq.validation = question.validation;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.record_detail_container {
  padding: 24px;
  margin: auto;
  max-width: 1400px;
  .record_detail_header {
    padding: 24px;
    min-height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
      0px 10px 10px rgba(46, 45, 193, 0.06);
    border-radius: 20px;
    position: relative;
    margin-bottom: 24px;
    .left_section {
      position: absolute;
      left: 24px;
      top: 24px;
      display: flex;
      align-items: center;
      & > * {
        margin-right: 16px;
      }
      .logo_container {
        height: 60px;
        flex: none;
        .logo {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .header_title {
      text-align: center;
      width: 40%;
      color: var(--v-walletPrimary-base);
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      font-family: var(--cosmo-font-nunito);
    }
  }
  .record_detail {
    padding: 24px;
    display: flex;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    position: relative;
    min-height: 114px;
    margin-bottom: 24px;
    .record_description {
      margin-right: 16px;
    }
    .record_share_section {
      flex: none;
      display: flex;
      margin-left: auto;
      align-items: center;
    }
  }
  .record_section {
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    background: #ffffff;
    margin-bottom: 24px;
    .record_section_item {
      margin-bottom: 24px;
      &:last-child {
        margin: 0;
      }
      .record_step {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        & > * {
          margin-right: 16px;
        }
      }
      .record_questions {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        @media (min-width: 600px) {
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          grid-template-columns: repeat(2, calc(50% - 8px));
        }
        @media (min-width: 1130px) {
          grid-column-gap: calc(4% / 2);
          grid-row-gap: 16px;
          grid-template-columns: repeat(3, 32%);
        }
      }
    }
  }
}
</style>
