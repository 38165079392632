import Client from "./Client";
import { TransactionalEmail } from "./TransactionalEmail";
export class OfferReminder {
  constructor({
    id = Number.NaN,
    collaborator = null,
    dateUpdated = null,
    dateCreated = null,
    transactionalEmail = null,
  } = {}) {
    this.id = Number.parseInt(id);
    this.collaborator = collaborator ? new Client(collaborator) : null;
    this.dateCreated = dateCreated ? new Date(dateCreated) : null;
    this.dateUpdated = dateUpdated ? new Date(dateUpdated) : null;
    this.transactionalEmail = transactionalEmail
      ? new TransactionalEmail(transactionalEmail)
      : null;
  }
}
