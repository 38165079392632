<template>
  <BaseDialog
    max-width="700"
    :value="dialogState"
    :persistent="isSubmiting"
    @click:outside="close()"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('utils.distributionOfChanges')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="close()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div class="modal_text" v-text="$t('utils.propagateMessage')" />
          <div class="modal_text" v-text="$t('utils.wantToProceed')" />
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="close()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import QuestionTemplateAPI from "@/core/Services/questionTemplate.api";
import { QuestionTemplate } from "@/core/Models/QuestionTemplate";

export default {
  name: "QuestionTemplatePropagateDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    questionTemplate: {
      type: QuestionTemplate,
      default: () => null,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
    };
  },
  methods: {
    close() {
      if (this.isSubmiting) return;
      this.$emit("close");
    },
    async submit() {
      this.isSubmiting = true;
      try {
        await QuestionTemplateAPI.postPropagateQuestionTemplate({
          organizationId: this.organizationId,
          questionTemplateId: this.questionTemplate.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding: 24px;
  .modal_text {
    color: #353542;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
    &:last-child {
      margin: 0;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    margin-left: 16px;
  }
}
</style>
