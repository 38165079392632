<template>
  <div>
    <draggable v-bind="$attrs" v-on="$listeners">
      <template #header>
        <slot slot="header" />
      </template>
      <slot />
      <template #footer>
        <slot slot="footer" />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "BaseDraggable",
  components: {
    draggable,
  },
};
</script>
