<template>
  <v-card :light="true" v-bind="$attrs" v-on="$listeners">
    <v-card-title v-if="$slots.title">
      <slot name="title" />
    </v-card-title>
    <v-divider v-if="$slots.title" />
    <v-card-text>
      <slot />
    </v-card-text>
    <v-divider v-if="$slots.actions" />
    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BaseCosmoCard",
};
</script>
