<template>
  <div v-if="status" class="status_container">
    <BaseExpensionPanels>
      <BaseExpensionPanel
        v-model="isExpanded"
        class="disagreed-panel"
        expand
        eager
        background-color="secondary"
        @click="onExpand"
      >
        <template slot="header">
          <!-- HEADER -->
          <div class="status_header">
            <div class="header_left">
              <div class="number-status" v-text="indexStatus" />
              <div class="status_name" v-text="status.name" />
              <div v-if="needActionArrow" class="actions d-flex align-center">
                <base-button
                  width="29"
                  class="btn-action mr-2"
                  color="primary"
                  :disabled="upArrowDisabled"
                  @click.stop="moveStatus('move_up')"
                >
                  <v-icon>
                    {{ "$mdiArrowUp" }}
                  </v-icon>
                </base-button>
                <base-button
                  width="29"
                  class="btn-action"
                  color="primary"
                  :disabled="downArrowDisabled"
                  @click.stop="moveStatus('move_down')"
                >
                  <v-icon>
                    {{ "$mdiArrowDown" }}
                  </v-icon>
                </base-button>
              </div>
            </div>
            <div class="header_right">
              <!-- edit workflow status button -->
              <BaseButton
                class="mr-2"
                icon="$mdiPencilOutline"
                @click.stop="
                  () => {
                    $store.commit('workflow/setActiveStatus', status);
                    $store.commit(
                      'workflow/setModalCreateAndEditStatusDialog',
                      true
                    );
                  }
                "
              >
                {{ $t("utils.edit") }}
              </BaseButton>
              <BaseButton
                v-if="activeWorkflow.primaryStatus != status.id"
                icon="$mdiTrashCanOutline"
                color="secondary"
                @click.stop="
                  () => {
                    $store.commit('workflow/setActiveStatus', status);
                    $store.commit('workflow/setModalDeleteStatusDialog', true);
                  }
                "
              >
                {{ $t("utils.delete") }}
              </BaseButton>
            </div>
          </div>
        </template>
        <template slot="content">
          <!-- BODY -->
          <div class="status_body">
            <v-tabs v-model="tab" color="primary" slider-color="primary">
              <v-tab class="d-flex align-center">
                <BaseIcon
                  icon="$mdiCheck"
                  small
                  class="mr-2 text-description-normal"
                />
                <BaseDescription
                  class="text-description"
                  v-text="$t('utils.customFields')"
                />
              </v-tab>
              <v-tab>
                <BaseIcon
                  icon="$mdiCrosshairsGps"
                  small
                  class="mr-2 text-description-normal"
                />
                <BaseDescription
                  class="text-description"
                  v-text="$t('utils.targetStatus')"
                />
              </v-tab>
              <v-tab>
                <BaseIcon
                  icon="$mdiCogs"
                  small
                  class="mr-2 text-description-normal"
                />
                <BaseDescription
                  class="text-description"
                  v-text="$t('utils.choicesOfAction')"
                />
              </v-tab>
              <v-tab>
                <BaseIcon
                  icon="$mdiAccount"
                  small
                  class="mr-2 text-description-normal"
                />
                <BaseDescription
                  class="text-description"
                  v-text="$t('utils.collaborators')"
                />
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <!-- CHAMP personnalisés -->
              <v-tab-item class="py-4 px-1">
                <div class="status_fields">
                  <WorkflowStatusCategory
                    v-for="(statusCategory, i) in groupedQuestionsByCategory"
                    :key="i"
                    :status-category="statusCategory"
                    :is-first="statusCategory.isFirst"
                    :is-last="statusCategory.isLast"
                    @addQuestion="addQuestion"
                    @editingQuestion="editQuestion"
                    @removeQuestion="removeQuestion"
                  />
                  <div class="status_fields_actions">
                    <BaseButton
                      icon="$mdiPlus"
                      class="mr-4"
                      @click="addQuestion({ statusCategory: null })"
                    >
                      {{ $t("workflow.status.fields.add") }}
                    </BaseButton>
                  </div>
                </div>
              </v-tab-item>
              <!-- Statut cible -->
              <v-tab-item class="py-4 px-1">
                <WorkflowDetailStatusTargetStatuses :status="status" />
              </v-tab-item>
              <!-- Choix des actions -->
              <v-tab-item class="py-4 px-1">
                <WorkflowStatusActions :status="status" />
              </v-tab-item>
              <!-- Collaborateurs -->
              <v-tab-item class="py-4 px-1">
                <div class="v-flex">
                  <div>
                    <BaseButton
                      icon="$mdiEdit"
                      medium
                      class="mb-4"
                      @click="
                        () => {
                          $store.commit('workflow/setActiveStatus', status);
                          $store.commit(
                            'workflow/setCollaboratorsManagementStatusDialogOpen',
                            true
                          );
                        }
                      "
                    >
                      {{ $t("utils.edit") }}
                    </BaseButton>
                  </div>
                  <div v-if="isFetchingCollaboratorsAssigned">
                    <BaseDescription
                      v-text="$t('workflow.status.collaborator.loading')"
                    />
                  </div>
                  <div
                    v-else-if="
                      !status.collaboratorsAssigned ||
                      status.collaboratorsAssigned.length === 0
                    "
                  >
                    <BaseAlert color="primary lighten-5">
                      <BaseDescription
                        bold
                        v-text="
                          $t('workflow.status.collaborator.everyAffectable')
                        "
                      />
                    </BaseAlert>
                  </div>
                  <div v-else>
                    <v-list class="d-flex">
                      <v-list-item
                        v-for="(
                          assignation, index
                        ) in status.collaboratorsAssigned"
                        :key="index"
                        class="d-inline-flex flex-row"
                      >
                        <v-list-item-avatar>
                          <v-img
                            :alt="`${assignation.collaborator.name} avatar`"
                            :src="assignation.collaborator.avatar"
                          />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="getCollaborator(assignation)"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </template>
      </BaseExpensionPanel>
    </BaseExpensionPanels>
  </div>
</template>

<script>
import WorkflowDetailStatusTargetStatuses from "./WorkflowDetailStatusTargetStatuses";
import WorkflowStatusActions from "./WorkflowStatusActions";
import WorkflowStatusCategory from "./WorkflowStatusCategory.vue";

import { mapState } from "vuex";

export default {
  name: "WorkflowDetailStatus",
  components: {
    WorkflowDetailStatusTargetStatuses,
    WorkflowStatusActions,
    WorkflowStatusCategory,
  },
  props: {
    status: {
      type: Object,
      default: null,
    },
    isLastStatus: {
      type: Boolean,
    },
    indexStatus: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isLoadingQuestions: false,
      tab: 0,
      isFetchingCollaboratorsAssigned: false,
      isExpanded: false,
    };
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
    }),
    upArrowDisabled() {
      return this.status.order <= 1;
    },
    downArrowDisabled() {
      return this.status.order === this.activeWorkflow?.statuses?.length;
    },
    needActionArrow() {
      return this.activeWorkflow?.statuses?.length > 1;
    },
    groupedQuestionsByCategory() {
      if (!this.status) return [];
      let groupedQuestions = this.status.categories
        .map((sc) => ({
          ...sc,
          statusId: this.status.id,
          isFirst:
            sc.order ===
            Math.min(...this.status.categories.map((tmpSC) => tmpSC.order)),
          isLast:
            sc.order ===
            Math.max(...this.status.categories.map((tmpSC) => tmpSC.order)),
          questions: this.status.questions
            .filter((q) => q.category?.id === sc.id)
            .sort((a, b) => a.order - b.order),
        }))
        .filter((sc) => sc.questions.length)
        .sort((a, b) => a.order - b.order);
      const withoutCategoryGroup = {
        id: null,
        name: this.$t("category.uncategorized"),
        statusId: this.status.id,
        questions: this.status.questions.filter((q) => !q.category),
      };
      if (withoutCategoryGroup.questions.length) {
        groupedQuestions.unshift(withoutCategoryGroup);
      }
      return groupedQuestions;
    },
  },
  watch: {
    status(newVal, oldVal) {
      if (newVal?.name != oldVal?.name && !newVal.questions?.length)
        this.initialize();
    },
  },
  methods: {
    onExpand() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.initialize();
      }
    },
    async initialize() {
      this.isLoadingQuestions = true;
      await this.$store.dispatch("workflow/fetchStatusQuestions", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.$route.params.workflowId,
        statusId: this.status.id,
      });
      this.isLoadingQuestions = false;
      this.$store.dispatch("workflow/fetchOrganizationCollaborators", {
        organizationId: this.$route.params.organizationId,
      });
      this.isFetchingCollaboratorsAssigned = true;
      await this.$store.dispatch("workflow/fetchCollaboratorAssigned", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.$route.params.workflowId,
        statusId: this.status.id,
      });
      this.isFetchingCollaboratorsAssigned = false;
    },
    getCollaborator(item) {
      return item.collaborator?.user?.email;
    },
    async moveStatus(action) {
      const payload = {
        organizationId: this.$route.params.organizationId,
        workflowId: this.activeWorkflow.id,
        statusId: this.status.id,
        action,
        status: this.status,
      };
      await this.$store.dispatch("workflow/moveWorkflowStatus", payload);
    },
    getDisplayTextForTypeQuestion(type) {
      switch (type) {
        case "TEXT":
          return this.$t("question.types.text");
        case "TEXTAREA":
          return this.$t("question.types.textarea");
        case "NUMBER":
          return this.$t("question.types.number");
        case "DATE":
          return this.$t("question.types.date");
        case "DATETIME":
          return this.$t("question.types.datetime");
        case "RADIOBUTTON":
          return this.$t("question.types.radio");
        case "CHECKBOX":
          return this.$t("question.types.checkbox");
        case "SELECT":
          return this.$t("question.types.select");
        case "FILE":
          return this.$t("question.types.file");
        case "SIGNATURE":
          return this.$t("question.types.signature");
        case "DATAGRID":
          return this.$t("question.types.datagrid");
        case "ROW_DATAGRID":
          return this.$t("question.types.rowDatagrid");
        case "MESSAGE":
          return this.$t("question.types.message");
      }
    },
    setPrimaryStatus(statusId) {
      const payload = {
        primary_status: statusId,
      };
      if (this.activeWorkflow.primaryStatus === statusId) {
        payload.primary_status = null;
      }
      this.$store
        .dispatch("workflow/updateWorkflow", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.activeWorkflow.id,
          payload,
        })
        .then(() => {
          if (payload.primary_status !== null) {
            this.$store.dispatch("snackbar/active", {
              message: this.$t(
                "workflow.status.snackbar.success.statusStartDefinedSuccessfully"
              ),
              type: "SUCCESS",
            });
          } else {
            this.$store.dispatch("snackbar/active", {
              message: this.$t(
                "workflow.status.snackbar.success.statusStartDeletedSuccessfully"
              ),
              type: "SUCCESS",
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("workflow.status.snackbar.success.errorDeleting"),
            type: "ERROR",
          });
        });
    },
    addQuestion({ statusCategory }) {
      this.$store.commit("workflow/setActiveStatus", this.status);
      this.$store.commit("workflow/SET_ACTIVE_STATUS_CATEGORY", statusCategory);
      this.$store.commit("workflow/setCreateAndEditStatusQuestionDialog", true);
    },
    editQuestion({ question, statusCategory }) {
      this.$store.commit("workflow/setActiveStatus", this.status);
      this.$store.commit("workflow/setActiveQuestion", question);
      this.$store.commit("workflow/SET_ACTIVE_STATUS_CATEGORY", statusCategory);
      this.$store.commit("workflow/setCreateAndEditStatusQuestionDialog", true);
    },
    removeQuestion({ question }) {
      this.$store.commit("workflow/setActiveStatus", this.status);
      this.$store.commit("workflow/setActiveQuestion", question);
      this.$store.commit("workflow/setDeleteStatusQuestionDialog", true);
    },
    setToggleStatusActionDialog({ event, action, status }) {
      this.$store.commit("workflow/setActiveStatus", status);
      this.$store.commit("workflow/setActiveAction", action);
      this.$store.commit("workflow/setActiveActionExists", !event);
      this.$store.commit("workflow/setToggleStatusActionDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .actions .v-btn {
  padding: 0px !important;
}
::v-deep .v-expansion-panel-header {
  padding: 24px;
}
.status_container {
  background: #fff;
  margin-bottom: 24px;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.status_header {
  display: flex;
  align-items: center;
  .header_left {
    display: flex;
    align-items: center;
    .status_name {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #353542;
      margin-right: 24px;
    }
  }
  .header_right {
    margin-left: auto;
  }
}
.status_body {
  .status_fields {
    .status_fields_actions {
      margin: 16px 0;
      display: flex;
      justify-content: center;
    }
  }
}
.number-status {
  padding: 10px;
  gap: 10px;

  width: 30px;
  height: 30px;

  /* Principale/Foncé */

  background: #2536cc;
  border-radius: 31px;
  margin-right: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action {
  padding: 7px;
  gap: 10px;
  height: 30px !important;
}
::v-deep .v-btn {
  min-width: 29px !important;
}
</style>
