<template>
  <div class="d-flex flex-column">
    <div class="d-flex mb-4">
      <BaseImage
        :src="$findStatic('cosmoAvatar')"
        max-width="35"
        max-height="35"
        class="mr-6 ml-0"
      />

      <BaseFrontOfficeChatMessage>
        <BaseFrontOfficeParagraph
          v-text="$t('wallet.assistant.noRecordsLeft')"
        />
      </BaseFrontOfficeChatMessage>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletCourseAllCompleted",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
};
</script>
