<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiCrosshairsGps } from "@mdi/js";
export default {
  name: "MdiCrosshairsGps",
  data() {
    return {
      svgPath: mdiCrosshairsGps,
    };
  },
};
</script>
