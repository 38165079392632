<template>
  <v-badge v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-badge>
</template>

<script>
export default {
  name: "BaseBadge",
};
</script>
