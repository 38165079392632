<template>
  <v-checkbox
    v-model="localValue"
    class="mr-2"
    :name="name"
    :label="label"
    :tabindex="tabIndex"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #label>
      <slot name="label" />
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "BaseFrontOfficeCheckbox",
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    tabIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localValue: false,
    };
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue(newVal) {
      this.$emit("updateValue", !!newVal);
    },
  },
};
</script>
