import FillCosmoform from "../Pages/FillCosmoform.vue";
import ThanksPage from "../Pages/ThanksPage.vue";

export default [
  {
    path: "/cosmoform/thanks",
    name: "ThanksPage",
    component: ThanksPage,
  },
  {
    path: "/cosmoform/:token",
    name: "FillCosmoform",
    component: FillCosmoform,
    props: true,
  },
];
