<template>
  <div>
    <BaseButton
      :to="{ name: 'account' }"
      type="secondary"
      icon="$mdiChevronLeft"
      class="mb-6"
    >
      {{ $t("utils.back") }}
    </BaseButton>
    <Board class="mb-6">
      <template #main>
        <div>
          <div class="signatory_title">
            <span class="label" v-text="$t('utils.personalDirectory')" />
            <BaseIcon class="icon" icon="$mdiHome" />
          </div>
          <BaseButton
            class="mb-2"
            type="primary"
            icon="$mdiPlus"
            @click="openSignatoryCreateAndEditDialog()"
          >
            {{ $tc("utils.add", 4) }}
          </BaseButton>
        </div>
        <BaseDataTable
          :headers="SIGNATORIES_HEADER"
          :items="signatories"
          :loading="fetchingSignatories"
          :loading-text="$t('utils.loadingData')"
        >
          <template #item="{ item }">
            <tr>
              <td>
                <BaseParagraph v-text="item.lastName" />
              </td>
              <td>
                <BaseParagraph v-text="item.firstName" />
              </td>
              <td class="text-center">
                <BaseParagraph v-text="item.email" />
              </td>
              <td class="text-center">
                <BaseParagraph v-text="item.phoneNumber" />
              </td>
              <td>
                <div class="d-flex justify-end">
                  <span class="mx-4" />
                  <BaseButtonIcon
                    x-small
                    color="primary"
                    icon="$mdiPencilOutline"
                    class="mr-2"
                    @click="openSignatoryCreateAndEditDialog(item)"
                  />
                  <BaseButtonIcon
                    x-small
                    color="secondary"
                    icon="$mdiTrashCanOutline"
                    @click="openSignatoryRemoveDialog(item)"
                  />
                </div>
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </template>
    </Board>

    <!-- MODAL -->
    <SignatoryCreateAndEditDialog
      :dialog-state="createAndEditSignatoryDialogState"
      :signatory="selectedSignatory"
      @close="createAndEditSignatoryDialogState = false"
      @signatoryAdded="onSignatoryAdded"
      @signatoryEdited="onSignatoryEdited"
    />
    <SignatoryRemoveDialog
      :dialog-state="removeSignatoryDialogState"
      :signatory="selectedSignatory"
      @close="removeSignatoryDialogState = false"
      @signatoryRemoved="onSignatoryRemoved"
    />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import SignatoryCreateAndEditDialog from "@/modules/Authentication/Components/SignatoryCreateAndEditDialog";
import SignatoryRemoveDialog from "@/modules/Authentication/Components/SignatoryRemoveDialog";

import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { Signatory } from "@/core/Models/Signatory";

export default {
  name: "SignatoryList",
  components: {
    Board,
    SignatoryCreateAndEditDialog,
    SignatoryRemoveDialog,
  },
  data() {
    return {
      SIGNATORIES_HEADER: [
        {
          text: this.$tc("utils.lastName", 1),
          value: "lastName",
        },
        {
          text: this.$t("utils.firstName"),
          value: "firstName",
        },
        {
          text: this.$tc("utils.email", 1),
          value: "email",
          align: "center",
        },
        {
          text: this.$tc("utils.phoneNumber", 1),
          value: "phoneNumber",
          align: "center",
        },
        {
          text: this.$tc("utils.action", 3),
          value: "action",
          align: "end",
          sortable: false,
        },
      ],
      signatories: [],
      selectedSignatory: null,
      fetchingSignatories: true,
      createAndEditSignatoryDialogState: false,
      removeSignatoryDialogState: false,
    };
  },
  created() {
    this.fetchSignatories();
  },
  methods: {
    async fetchSignatories() {
      try {
        this.signatories = (
          await DashboardAPI.getUserSignatories()
        ).data.results.map((s) => new Signatory(s));
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.fetchingSignatories = false;
    },
    onSignatoryAdded(signatory) {
      this.createAndEditSignatoryDialogState = false;
      this.signatories.push(signatory);
    },
    onSignatoryEdited(signatory) {
      this.createAndEditSignatoryDialogState = false;
      this.signatories = this.signatories.map((s) =>
        s.id == signatory.id ? signatory : s
      );
    },
    openSignatoryCreateAndEditDialog(selectedSignatory = null) {
      this.selectedSignatory = selectedSignatory;
      this.createAndEditSignatoryDialogState = true;
    },
    openSignatoryRemoveDialog(selectedSignatory = null) {
      this.selectedSignatory = selectedSignatory;
      this.removeSignatoryDialogState = true;
    },
    onSignatoryRemoved(signatory) {
      this.removeSignatoryDialogState = false;
      this.signatories = this.signatories.filter((s) => s.id != signatory.id);
    },
  },
};
</script>

<style scoped lang="scss">
.signatory_title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .label {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #353542 !important;
  }
  .icon {
    margin-left: 8px;
  }
}
</style>
