<template>
  <div>
    <div class="mb-5">
      <BaseFrontOfficeDescription uppercase class="mt-3">
        <i18n path="folder.offerSentBy">
          <template #sent>
            <span class="text_primary" v-text="$t('utils.sent')" />
          </template>
          <template #sendingDate>
            <span v-text="$d(offer.dateCreated, 'detailed')" />
          </template>
          <template #clientName>
            <span v-text="creatorName" />
          </template>
        </i18n>
      </BaseFrontOfficeDescription>
      <BaseFrontOfficeDescription
        v-if="offer.description !== ''"
        uppercase
        class="mt-3"
      >
        <span class="text_primary" v-text="$t('folder.offerDescription')" />
        <BaseMarkdownViewer :content="offer.description" is-text-caption />
      </BaseFrontOfficeDescription>
    </div>
    <!-- OFFER FILES -->
    <div class="offer_files_container">
      <template v-if="isSigned">
        <div class="offer_files_title">
          {{ $t("folder.signedDocuments") }}
        </div>
        <div class="offer_files">
          <div
            v-for="file in offer.files"
            :key="file.id"
            class="offer_file_item"
            @click="openFile(file.signedFile)"
          >
            <div class="file">
              <BasePdfViewer :pdf="file.signedFile" :page="1" />
            </div>
            <div class="file_name" v-text="file.name" />
          </div>
        </div>
        <div class="offer_files_title mt-4">
          {{ $t("folder.proofOfSignature") }}
        </div>
        <div class="offer_files">
          <div
            class="offer_file_item"
            @click="openFile(offer.clients.certificate)"
          >
            <div class="file">
              <BasePdfViewer :pdf="offer.clients.certificate" :page="1" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="offer_files">
          <div
            v-for="file in offer.files"
            :key="file.id"
            class="offer_file_item"
            @click="openFile(file.offerFile)"
          >
            <div
              class="file"
              :class="{
                'icon-file': ['.docx', '.doc', '.pptx'].some((v) =>
                  file.offerFile.includes(v)
                ),
              }"
            >
              <BasePdfViewer
                v-if="file.offerFile.includes('.pdf')"
                :pdf="file.offerFile"
                :page="1"
              />

              <base-icon
                v-else-if="
                  ['.docx', '.doc', '.pptx'].some((v) =>
                    file.offerFile.includes(v)
                  )
                "
                icon="$mdiFileDocument"
                color="primary"
              />

              <img v-else :src="file.offerFile" />
            </div>
            <div class="file_name" v-text="file.name" />
          </div>
        </div>
      </template>
    </div>
    <!-- OFFER ACTIONS -->
    <div
      v-if="
        (offer.offerType === 'VALIDATION' ||
          offer.offerType === 'SIGNATURE' ||
          offer.clients.action !== 'CONSULT') &&
        offer.clients.status === 'PENDING'
      "
    >
      <div v-if="refused">
        <div>
          <BaseFrontOfficeSubtitle
            error
            class="mt-2"
            v-text="'Motif du refus'"
          />
          <v-textarea
            v-model="declineReason"
            outlined
            class="mt-2"
            color="#9C0000"
            auto-grow
            name="name"
            autofocus
            :placeholder="
              $t('folder.records.createAndEditModal.messagePlaceHolder')
            "
          />
          <div class="d-flex justify-center">
            <BaseFrontOfficeParagraph
              v-if="declineReason !== ''"
              color="primary"
              v-text="$t('utils.alertRefuseMessage')"
            />
          </div>
        </div>
        <div class="d-flex justify-center mt-2">
          <BaseFrontOfficeButton
            class="mr-3"
            @click="refused = !refused"
            v-text="$t('utils.cancel')"
          />

          <BaseFrontOfficeButton
            :disabled="declineReason === ''"
            @click="refuseOffer"
            v-text="$t('utils.refuse')"
          />
        </div>
      </div>
      <div v-else class="footer_actions">
        <BaseFrontOfficeButton
          type="secondary"
          @click="refused = !refused"
          v-text="$t('utils.refuse')"
        />
        <BaseFrontOfficeButton
          :icon="'$mdiPencilOutline'"
          @click="
            offer.offerType === 'SIGNATURE' ? signOffer() : validateOffer()
          "
          v-text="
            $t(
              offer.offerType === 'VALIDATION' ||
                offer.clients.action === 'VALIDATION'
                ? 'utils.validate'
                : 'utils.sign'
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletOfferItem",
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      declineReason: "",
      refused: false,
      fileHovered: null,
    };
  },

  computed: {
    creatorName() {
      if (!this.offer) return "";
      if (
        this.offer.creator?.lastName === "" &&
        this.offer.creator?.firstName === ""
      )
        return "Anonyme";
      return `${this.offer.creator?.lastName} ${this.offer.creator?.firstName}`;
    },
    isManyFiles() {
      return this.offer.files.length > 3;
    },
    isSigned() {
      return (
        this.offer.offerType == "SIGNATURE" &&
        this.offer?.files.every((file) => file.signedFile)
      );
    },
  },
  created() {
    this.setOfferConsulted();
  },
  methods: {
    openFile(link) {
      open(link);
    },
    itemHovered(file) {
      this.fileHovered = file;
    },
    itemUnhovered() {
      this.fileHovered = null;
    },
    async refuseOffer() {
      let payload = new FormData();
      const status = "DECLINE";
      payload.append("status", status);
      payload.append("reason_decline", this.declineReason);
      try {
        await this.$store.dispatch("walletCourse/patchOfferStatus", {
          payload,
          status,
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderID,
          offerId: this.offer.id,
          offerClientId: this.offer.clients.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.offerStatus.signatureRefused"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async setOfferConsulted() {
      if (
        (this.offer.offerType == "CONSULT" &&
          this.offer.clients.status == "PENDING") ||
        (this.offer.offerType == "MIXTE" &&
          this.offer.clients.action == "CONSULT" &&
          this.offer.clients.status == "PENDING")
      ) {
        let payload = new FormData();
        payload.append("status", "CONSULTED");
        const status = "CONSULTED";
        try {
          await this.$store.dispatch("walletCourse/patchOfferStatus", {
            organizationId: this.$route.params.organizationId,
            status,
            folderId: this.$route.params.folderID,
            offerId: this.offer.id,
            payload,
            offerClientId: this.offer.clients.id,
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$tc("folder.offerStatus.offerConsulted", 0),
            type: "SUCCESS",
          });
        } catch (error) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        }
      }
    },
    signOffer() {
      if (!this.offer.clients?.yousignLink) {
        return this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.yousignError"),
          type: "ERROR",
        });
      }
      open(this.offer.clients?.yousignLink);
    },
    async validateOffer() {
      let payload = new FormData();
      const status = "ACCEPT";
      payload.append("status", "ACCEPT");
      try {
        await this.$store.dispatch("walletCourse/patchOfferStatus", {
          status,
          payload,
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderID,
          offerId: this.offer.id,
          offerClientId: this.offer.clients.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$tc("folder.offerStatus.offerValidated", 0),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offer_files_container {
  padding: 16px;
  width: 100%;
  background: #fff;
  .offer_files_title {
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 0.8em;
  }
  .offer_files {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    grid-template-rows: auto;
    @media (min-width: 600px) {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: repeat(2, calc(50% - 8px));
    }
    @media (min-width: 1066px) {
      grid-column-gap: calc(4% / 2);
      grid-row-gap: 16px;
      grid-template-columns: repeat(3, 32%);
    }
    .offer_file_item {
      width: 100%;
      overflow: hidden;
      cursor: pointer;
      .file {
        border: 1px solid #e5e5e5;
        height: 95%;
        min-height: 200px;
        overflow: hidden;
        width: 100%;

        & > * {
          height: 100%;
          width: 100%;
          object-fit: cover;
          margin: 0;
        }
      }
      .file_name {
        padding: 0 8px;
        width: 100%;
        font-size: 10px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
        background: #fff;
        text-align: center;
      }
    }
  }
}
.footer_actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & > * {
    margin: 5px;
  }
}
.text_primary {
  color: var(--v-primary-base);
}
</style>
