<template>
  <div>
    <v-menu offset-y left>
      <template #activator="{ on, attrs }">
        <BaseButton
          :color="validationColor"
          :loading="isSubmitting"
          append-icon="$mdiChevronDown"
          v-bind="attrs"
          v-on="on"
        >
          {{ validationLabel }}
        </BaseButton>
      </template>
      <div class="menu_list">
        <v-btn
          x-large
          class="menu_list_item"
          block
          text
          @click="updateAnswerValidation(ANSWER_VALIDATION_LEVEL.PENDING)"
        >
          <span class="label" v-text="$t('utils.notVerified')" />
        </v-btn>
        <v-btn
          x-large
          class="menu_list_item"
          block
          text
          @click="updateAnswerValidation(ANSWER_VALIDATION_LEVEL.VALIDATED)"
        >
          <span class="label" v-text="$t('utils.validated')" />
        </v-btn>
        <v-btn
          x-large
          class="menu_list_item"
          block
          text
          @click="updateAnswerValidation(ANSWER_VALIDATION_LEVEL.INVALIDATED)"
        >
          <span class="label" v-text="$t('utils.error')" />
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { ANSWER_VALIDATION_LEVEL } from "@/core/Utils/types.utils";

export default {
  name: "WorkflowAnswerValidation",
  props: {
    question: {
      type: Object,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ANSWER_VALIDATION_LEVEL: ANSWER_VALIDATION_LEVEL,
      isSubmitting: false,
    };
  },
  computed: {
    validationLabel() {
      switch (this.question.answer.validationLevel) {
        case ANSWER_VALIDATION_LEVEL.PENDING:
          return this.$t("utils.notVerified");
        case ANSWER_VALIDATION_LEVEL.VALIDATED:
          return this.$t("utils.validated");
        case ANSWER_VALIDATION_LEVEL.INVALIDATED:
          return this.$t("utils.error");
      }
      return "";
    },
    validationColor() {
      switch (this.question.answer.validationLevel) {
        case ANSWER_VALIDATION_LEVEL.PENDING:
          return "primary";
        case ANSWER_VALIDATION_LEVEL.VALIDATED:
          return "success";
        case ANSWER_VALIDATION_LEVEL.INVALIDATED:
          return "secondary";
      }
      return "primary";
    },
  },
  methods: {
    async updateAnswerValidation(validationLevel) {
      this.isSubmitting = true;
      this.$emit("updateSubmiting", true);
      await this.$store.dispatch("workflow/patchQuestionAnswer", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        statusId: this.statusId,
        questionId: this.question.id,
        payload: {
          validation_level: validationLevel,
        },
        answerId: this.question.answer.id,
      });
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", [
        {
          ...this.question,
          answer: {
            ...this.question.answer,
            validationLevel,
          },
        },
      ]);
      this.$emit("updateSubmiting", false);
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: flex-start;
    &::v-deep {
      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #707080;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      &::v-deep {
        .label {
          color: #2536cc;
        }
      }
    }
  }
}
</style>
