<template>
  <div>
    <v-overlay :value="isLoading" style="z-index: 100">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "TheBaseFrontOfficeOverlay",
  props: {
    isLoading: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped></style>
