let plugins = [];

// Import Stores
import Client from "./client";

let stores = {
  client: Client,
};

export { plugins, stores };
