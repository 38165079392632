<template>
  <div>
    <div class="d-flex align-center justify-space-between mt-4">
      <BaseTitle class="mb-1" v-text="step.name" />
      <div v-if="isEditingAllowed" class="d-flex align-center flex-shrink-0">
        <BaseButtonIcon
          :disabled="isLastStep"
          color="gray"
          outlined
          tile
          class="rounded ml-2"
          icon="$mdiArrowDownThick"
          @click="editStepOrder(false)"
        />
        <BaseButtonIcon
          :disabled="isFirstStep"
          color="gray"
          outlined
          tile
          class="rounded ml-2"
          icon="$mdiArrowUpThick"
          @click="editStepOrder(true)"
        />
        <BaseButton type="tertiary" class="ml-2" @click="$emit('edit')">
          {{ $t("utils.edit") }}
        </BaseButton>
        <BaseButton color="secondary" class="ml-2" @click="$emit('delete')">
          {{ $t("course.deleteStep") }}
        </BaseButton>
      </div>
    </div>

    <BaseDivider class="my-4" grey />
  </div>
</template>

<script>
export default {
  name: "CourseDetailStepHeader",
  props: {
    isEditingAllowed: {
      type: Boolean,
    },
    isFirstStep: {
      type: Boolean,
    },
    isLastStep: {
      type: Boolean,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Function to change order between two steps, similare to edit
     * question order.
     * No time has been taken to refactor or optimize this function
     * because a new version of an endpoint is coming to facilite step order change
     */
    async editStepOrder(goUp) {
      const course = await this.$store
        .dispatch("course/moveStep", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.step.id,
          goUp,
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.course = course;
      // Display TheSnackbar
      this.$store.dispatch("snackbar/active", {
        message: this.$t("course.editStepOrderConfirmation"),
        type: "SUCCESS",
      });
    },
  },
};
</script>
