import { render, staticRenderFns } from "./BaseFrontOfficeCheckbox.vue?vue&type=template&id=6e1dce8a"
import script from "./BaseFrontOfficeCheckbox.vue?vue&type=script&lang=js"
export * from "./BaseFrontOfficeCheckbox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
