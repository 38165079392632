<template>
  <div>
    <BaseModal
      :max-width="800"
      :value="dialogState"
      :modal-title="$t('utils.cancelOffer.title')"
      @click:outside="reset()"
      @close="reset()"
    >
      <template #modal>
        <div class="modal_content">
          <template v-if="procedure && procedure.sequentialSignatures">
            <div
              class="text-center"
              v-text="$t('utils.cancelOffer.simpleTextSequential')"
            />
            <ul>
              <li class="text_danger text-center">
                {{ $t("utils.cancelOffer.warning1Sequential") }}
              </li>
              <li class="text_danger text-center">
                {{ $t("utils.cancelOffer.warning2Sequential") }}
              </li>
            </ul>
          </template>
          <template v-else>
            <div
              class="text-center"
              v-text="$t('utils.cancelOffer.simpleText')"
            />
            <ul>
              <li class="text_danger text-center">
                {{ $t("utils.cancelOffer.warning1") }}
              </li>
              <li class="text_danger text-center">
                {{ $t("utils.cancelOffer.warning2") }}
              </li>
            </ul>
          </template>
        </div>
      </template>
      <template #actions class="d-flex justify-end">
        <BaseButton
          :disabled="isSubmiting"
          class="mx-4 ml-auto"
          color="grey"
          type="secondary"
          @click="reset()"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton
          :loading="isSubmiting"
          color="secondary"
          type="secondary"
          @click="submit()"
        >
          Confirmer
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: "FolderOfferCancelDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    procedure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      cancelDialog: false,
    };
  },
  methods: {
    reset() {
      this.$emit("close");
    },
    async submit() {
      this.isSubmiting = true;
      const data = {
        status: "CANCELED",
      };
      try {
        await this.$store.dispatch("offer/cancelSignatureProcedure", {
          organizationId: this.$route.params.organizationId,
          procedureId: this.procedure.id,
          data,
        });
        await this.$store.dispatch("folder/getOrganizationOffersRecordFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
        }),
          await this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.cancelOffer.success"),
            type: "SUCCESS",
          });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }

      this.reset();
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding: 24px 10px;

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  & > * {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .text_danger {
    color: #ff5267;
  }
}
.modal_footer {
  justify-content: flex-end;
}
</style>
