<template>
  <div>
    <BaseDialog
      max-width="800"
      :persistent="isSubmiting"
      :value="dialogState"
      @click:outside="reset()"
    >
      <template #modal>
        <BaseCosmoCard>
          <template #title>
            <div class="modal_header">
              <BaseTitle v-text="$t('utils.relaunch')" />
              <BaseButtonIcon
                class="close_icon"
                icon="$mdiClose"
                @click="reset()"
              />
            </div>
          </template>
          <div v-if="offerClient" class="modal_content">
            <div class="item_section mb-4">
              <div class="label" v-text="$tc('utils.recipient', 1)" />
              <div class="desc" v-text="offerClient.email" />
            </div>
            <div class="item_section mb-4">
              <div
                class="label"
                v-text="$tc('utils.document', offer.files.length)"
              />
              <div class="files">
                <div
                  v-for="(file, i) in offer.files"
                  :key="`f-${i}`"
                  class="file_name"
                  v-text="file.name"
                />
              </div>
              <div class="item_footer" v-text="lastRemindDate" />
            </div>
            <div class="item_section mb-4">
              <div class="label" v-text="$t('cosmoform.dialog.emailContent')" />
              <BaseMarkdownEditor
                v-model="message"
                :with-link="false"
                :error-messages="messageErrors"
              />
            </div>
          </div>
          <div class="modal_footer">
            <BaseButton
              :disabled="isSubmiting"
              color="primary"
              type="primary"
              @click="remindLogsDialogState = true"
            >
              {{ $t("utils.showLogs") }}
            </BaseButton>
            <BaseButton
              :disabled="isSubmiting"
              color="grey"
              type="secondary"
              @click="reset()"
            >
              {{ $t("utils.cancel") }}
            </BaseButton>
            <BaseButton
              :loading="isSubmiting"
              color="primary"
              type="primary"
              @click="submit()"
            >
              {{ $t("utils.relaunch") }}
            </BaseButton>
          </div>
        </BaseCosmoCard>
      </template>
    </BaseDialog>

    <FolderOfferRemindLogsDialog
      :offer-client="offerClient"
      :dialog-state="remindLogsDialogState"
      @close="remindLogsDialogState = false"
    />
  </div>
</template>

<script>
import OfferAPI from "@/modules/Folder/Services/offer.api";
import FolderOfferRemindLogsDialog from "./FolderOfferRemindLogsDialog";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { OfferReminder } from "@/core/Models/OfferReminder";

export default {
  name: "FolderOfferRemindDialog",
  components: {
    FolderOfferRemindLogsDialog,
  },
  mixins: [validationMixin],
  props: {
    dialogState: {
      type: Boolean,
    },
    offer: {
      type: Object,
      required: true,
    },
    offerClient: {
      type: Object,
      default: () => null,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      remindLogsDialogState: false,
      message: "",
    };
  },
  validations() {
    let validations = {};
    validations = {
      message: {
        required,
      },
    };
    return validations;
  },
  computed: {
    lastRemindDate() {
      if (!this.offerClient?.reminders) return;
      return this.$t("utils.lastSentDate", {
        date: this.$d(this.offerClient?.reminders[0]?.dateCreated, "full"),
      });
    },
    messageErrors() {
      if (!this.$v.message.$dirty) {
        return [];
      }
      if (!this.$v.message.required) {
        return [this.$t("utils.emailRequired")];
      }
      return [];
    },
  },
  methods: {
    reset() {
      this.message = "";
      this.$emit("close");
    },
    async submit() {
      this.$v.message.$touch();
      if (this.$v.message.$invalid) return;
      this.isSubmiting = true;
      try {
        let newRemind = (
          await OfferAPI.postOrganizationFolderOfferRemind({
            organizationId: this.organizationId,
            folderId: this.folderId,
            offerId: this.offer.id,
            payload: {
              client: this.offerClient.id,
              message: this.message,
            },
          })
        ).data;
        this.$store.commit("offer/SET_OFFER_CLIENT", {
          offerClient: {
            ...this.offerClient,
            reminders: [
              new OfferReminder({ ...newRemind }),
              ...this.offerClient.reminders,
            ],
          },
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.OfferRelaunched"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.reset();
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .item_section {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    .label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 1em;
      color: #353542;
    }
    ::v-deep {
      .v-input__control .v-input__slot {
        & > fieldset {
          border: 1px solid #b6bdff;
        }
      }
    }
    .desc {
      padding: 16px;
      background: #f9f9f9;
      border-radius: 5px;
      line-height: 17px;
      color: #707080;
    }
    .files {
      padding: 16px;
      background: #f9f9f9;
      border-radius: 5px;
      line-height: 20px;
      color: #353542;
      text-decoration: underline;
    }
    .item_footer {
      margin-top: 10px;
      padding: 5px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: #707080;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    &:first-child {
      margin: 0;
      margin-right: auto;
    }
    margin-left: 16px;
  }
}
</style>
