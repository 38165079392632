import { render, staticRenderFns } from "./BaseFrontOfficeFieldImage.vue?vue&type=template&id=9b24fb2e&scoped=true"
import script from "./BaseFrontOfficeFieldImage.vue?vue&type=script&lang=js"
export * from "./BaseFrontOfficeFieldImage.vue?vue&type=script&lang=js"
import style0 from "./BaseFrontOfficeFieldImage.vue?vue&type=style&index=0&id=9b24fb2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b24fb2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
installComponents(component, {VFileInput})
