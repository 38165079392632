import Campaign from "./Campaign";
import Course from "./Course";
import Step from "./Step";
import Agreement from "./Agreement";
import Client from "./Client";
import User from "./User";
import Remind from "./Remind";
import Answer from "./Answer";

export default class Record {
  constructor({
    id = Number.NaN,
    campaign = null,
    course = null,
    steps = new Array(Step),
    reminders = new Array(Remind),
    answer = null,
    client = null,
    user = null,
    agreements = new Array(Agreement),
    agreement = false,
    status = "",
    dateCreated = new Date(),
    consentDate = new Date(),
    validationStatus = "",
    email = "",
    folderState = "OPEN",
    nextReminderDate = null,
  } = {}) {
    this.id = Number.parseInt(id);
    this.campaign = campaign ? new Campaign(campaign) : null;
    this.answer = answer ? new Answer(answer) : null;
    this.course = course ? new Course(course) : null;
    this.steps = steps ? steps.map((step) => new Step(step)) : [];
    this.client = client ? new Client(client) : null;
    this.nextReminderDate = nextReminderDate;
    if (user) {
      this.user =
        typeof user == "number" ? Number.parseInt(user) : new User(user);
    } else {
      this.user = null;
    }
    this.agreements = agreements
      ? agreements.map((agreement) => new Agreement(agreement))
      : [];
    this.agreement = agreement;
    this.status = status;
    this.dateCreated = new Date(dateCreated);
    this.consentDate = new Date(consentDate);
    this.validationStatus = validationStatus;
    this.email = email;
    this.reminders = reminders.length
      ? reminders.map((remind) => new Remind(remind))
      : [];
    this.folderState = folderState;
  }
}
