<template>
  <WalletManagerBaseModal
    :value="isOpen"
    :modal-title="title"
    @close="$emit('close')"
  >
    <template #modal>
      <div :class="!isError ? 'valid' : 'error-text'" v-text="description" />
    </template>
    <template #actions>
      <div v-if="!isConsultation" class="btn-container">
        <v-btn class="btn-cancel" rounded @click="$emit('close')"
          >Annuler</v-btn
        >
        <v-btn class="btn-comment" rounded @click="$emit('update')"
          >Confirmer</v-btn
        >
      </div>
      <div v-else class="d-flex justify-center">
        <v-btn class="btn-comment" rounded @click="$emit('update')">Ok</v-btn>
      </div>
    </template>
  </WalletManagerBaseModal>
</template>
<script>
import WalletManagerBaseModal from "./WalletManagerBaseModal.vue";

export default {
  name: "WalletManagerValidationModal",
  components: {
    WalletManagerBaseModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    isConsultation: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.btn-container {
  display: flex;
  gap: 8px;
}
.btn-comment {
  background-color: #ffd056 !important;
  color: #4f4f4f;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
.btn-cancel {
  background-color: #fefefe !important;
  color: #4f4f4f;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  border: 1px solide #4f4f4f;
}

.valid {
  color: #18a300;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.error-text {
  color: #fe7878;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
