var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 my-4",attrs:{"locale":'fr',"headers":_vm.listHeaders,"items":_vm.sortedRecords,"loading":_vm.isLoadingRecords,"item-key":"id","show-expand":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{staticClass:"row-click"},[_c('td',{on:{"click":function($event){return expand(!isExpanded)}}},[_c('div',{staticClass:"d-flex flex-column ma-2 justify-center"},[(item.client)?_c('BaseParagraph',{staticClass:"mb-2",domProps:{"textContent":_vm._s(item.client.email)}}):_vm._e(),(item.client)?_c('BaseParagraph',{attrs:{"type":"secondary"},domProps:{"textContent":_vm._s(((item.client.firstName) + " " + (item.client.lastName)))}}):_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.email)}})],1)]),_c('td',{on:{"click":function($event){return expand(!isExpanded)}}},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(_vm.$d(new Date(item.dateCreated), 'detailed'))}})],1),(!item.answer)?_c('td',{on:{"click":function($event){return expand(!isExpanded)}}},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(
            item.nextReminderDate
              ? _vm.$d(new Date(item.nextReminderDate), 'detailed')
              : ''
          )}})],1):_vm._e(),_c('td',{on:{"click":function($event){return expand(!isExpanded)}}},[_c('BaseButtonIcon',{class:{
            'openRecord-enter-active': !isExpanded,
            'openRecord-leave-active': isExpanded,
          },attrs:{"icon":"$mdiChevronDown"}})],1)])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"my-4"},[(/^AGREED$/.test(_vm.recordStatus))?_c('Record',{attrs:{"record-id":item.id,"record":item}}):(/^DISAGREED$/.test(_vm.recordStatus))?_c('BaseRow',{staticClass:"d-flex justify-center align-center",attrs:{"record-id":item.id}},[_c('BaseCol',{staticClass:"d-flex",attrs:{"cols":"7"}},[_c('BaseParagraph',{staticClass:"ml-auto mb-2 d-block",attrs:{"text":"primary"},domProps:{"textContent":_vm._s(_vm.$t('client.notAgreedLabel'))}})],1),_c('BaseCol',{staticClass:"d-flex",attrs:{"cols":"5"}},[_c('BaseButton',{staticClass:"d-flex align-center ml-auto mr-11",attrs:{"type":"warning","color":"red"},on:{"click":function($event){return _vm.openRelaunchModal({ item: item, isDisagree: true })}}},[_c('BaseIcon',{staticClass:"mr-2",attrs:{"icon":"$mdiEmailOutline","color":"red"}}),_c('BaseParagraph',{attrs:{"bold":"","text":"secondary","color":"red"},domProps:{"textContent":_vm._s(_vm.$t('folder.relaunch'))}})],1)],1)],1):_c('BaseRow',{staticClass:"d-flex justify-center align-center"},[_c('BaseCol',{staticClass:"d-flex",attrs:{"cols":"7"}},[_c('BaseParagraph',{staticClass:"ml-auto mb-2 d-block",attrs:{"text":"primary"},domProps:{"textContent":_vm._s(_vm.$t('client.notFinishedLabel'))}})],1),_c('BaseCol',{staticClass:"d-flex",attrs:{"cols":"5"}},[_c('BaseButton',{staticClass:"d-flex align-center ml-auto mr-11",attrs:{"type":"primary","color":"purple"},on:{"click":function($event){return _vm.openRelaunchModal({ item: item, isDisagree: false })}}},[_c('BaseIcon',{staticClass:"mr-2",attrs:{"icon":"$mdiEmailOutline","color":"purple"}}),_c('BaseParagraph',{attrs:{"bold":"","text":"secondary","color":"purple"},domProps:{"textContent":_vm._s(_vm.$t('folder.relaunch'))}})],1)],1)],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }