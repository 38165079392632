<template>
  <BaseModal
    :value="isOpen"
    :max-width="700"
    is-complex
    :modal-title="$t('folder.askInformation')"
    @click:outside="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="d-flex flex-column justify-content-center">
        <span class="text danger"
          >Votre dossier ne contient aucun contact assigné. L’envoi de cette
          demande d’informations affectera “{{ confirmEmail }}” automatiquement
          en tant que propriétaire de ce dossier.</span
        >
        <span class="text"
          >Êtes-vous sûr de vouloir affecter le contact “{{ confirmEmail }}” à
          ce dossier? Cette action est irréversible.</span
        >
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.back") }}
      </BaseButton>
      <BaseButton color="primary" type="primary" @click="$emit('on-validate')">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "FolderConfirmAssignDialog",
  props: {
    isOpen: {
      type: Boolean,
    },
    confirmEmail: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 171.52%;
  text-align: center;
}
.danger {
  color: #ff5267;
}
</style>
