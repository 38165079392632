import {
  fetchOrganizationWorkflows,
  fetchOrganizationWorkflow,
  postOrganizationWorkflow,
  patchOrganizationWorkflow,
  deleteOrganizationWorkflow,
  postOrganizationWorkflowStatus,
  patchOrganizationWorkflowStatus,
  deleteOrganizationWorkflowStatus,
  postOrganizationWorkflowStatusQuestion,
  postOrganisationWorkflowStatusDatatypeQuestion,
  updateOrganizationWorkflowStatusQuestion,
  fetchOrganizationWorkflowStatusQuestionOptions,
  deleteOrganizationWorkflowStatusQuestion,
  postOrganizationFolderStatusQuestionAnswer,
  patchOrganizationFolderStatusQuestionAnswer,
  deleteOrganizationFolderStatusQuestionAnswer,
  createOrganizationWorkflowStatusTargetStatus,
  fetchOrganizationWorkflowStatusTargetStatuses,
  updateOrganizationWorkflowStatusTargetStatus,
  deleteOrganizationWorkflowStatusTargetStatus,
  fetchOrganizationWorkflowStatusActions,
  postOrganizationWorkflowStatusAction,
  deleteOrganizationWorkflowStatusAction,
  getOrganizationCollaborators,
  addCollaboratorStatus,
  getCollaboratorAssignedToStatus,
  delCollaboratorStatus,
  postOrganisationWorkflowStatusMove,
} from "../Services/workflow.service";

import {
  fetchOrganizationFolderStatusQuestions,
  fetchOrganizationFolderStatusActions,
} from "@/modules/Folder/Services/folder.service";

import WorkflowAPI from "../Services/workflow.api";

import Workflow from "@/core/Models/Workflow";
import Status from "@/core/Models/Status";
import TargetStatus from "@/core/Models/TargetStatus";
import Question from "@/core/Models/Question";
import { QuestionCategory } from "@/core/Models/QuestionCategory";
import Action from "@/core/Models/Action";

export default {
  namespaced: true,
  state: {
    workflows: [],
    allWorkFlows: [],
    collaborators: [],
    activeWorkflow: null,
    activeStatus: null,
    activeQuestion: null,
    activeTargetStatus: null,
    activeStatusCategory: null,
    modals: {
      isCreateAndEditWorkflowDialogOpen: false,
      isDeleteWorkflowDialogOpen: false,
      isCreateAndEditStatusDialogOpen: false,
      isDeleteStatusDialogOpen: false,
      isCreateAndEditStatusQuestionDialogOpen: false,
      isDeleteStatusQuestionDialogOpen: false,
      isCollaboratorsActionDialogOpen: false,
      isCollaboratorsManagementStatusDialogOpen: false,
      isCreateAndEditStatusTargetStatusDialogOpen: false,
      isDeleteStatusTargetStatusDialogOpen: false,
      isRemoveStatusCategoryOpen: false,
      isEditQuestionCategoryOpen: false,
    },
  },
  mutations: {
    //#region Collaborators
    setGlobalCollaborators(state, payload) {
      state.collaborators = payload;
    },
    setAssignedCollaboratorsStatus(state, { statusId, payload }) {
      if (!state.activeWorkflow) return;
      let status =
        state.activeWorkflow.statuses.length &&
        state.activeWorkflow.statuses.find((s) => s.id == statusId);
      status.collaboratorsAssigned = payload;
      state.activeWorkflow.statuses.splice(
        state.activeWorkflow.statuses.findIndex((s) => s.id == statusId),
        1,
        status
      );
    },
    setAllWorkFlows(state, payload) {
      state.allWorkFlows = payload
        ? payload.map((workflow) => new Workflow(workflow))
        : [];
    },
    //#endregion
    //#region Workflow
    setWorkflows(state, payload) {
      state.workflows = payload
        ? payload.map((workflow) => new Workflow(workflow))
        : [];
    },
    SET_ACTIVE_WORKFLOW(state, payload) {
      state.activeWorkflow = payload ? new Workflow(payload) : null;
    },
    setWorkflow(state, payload) {
      state.activeWorkflow = payload ? new Workflow(payload) : null;
      state.workflows.splice(
        state.workflows.findIndex((wf) => wf.id == payload.id),
        1,
        state.activeWorkflow
      );
    },
    //#endregion
    //#region Status
    setActiveStatus(state, payload) {
      state.activeStatus = payload ? new Status(payload) : null;
    },
    setStatus(state, { statusId, payload }) {
      payload = payload ? new Status(payload) : null;
      if (state.activeWorkflow.statuses.length == 0) {
        state.activeWorkflow.primaryStatus = statusId;
      }
      if (
        state.activeWorkflow.statuses.find((status) => status.id == statusId)
      ) {
        if (payload) {
          state.activeWorkflow.statuses.splice(
            state.activeWorkflow.statuses.findIndex(
              (status) => status.id == statusId
            ),
            1,
            payload
          );
        } else {
          state.activeWorkflow.statuses.splice(
            state.activeWorkflow.statuses.findIndex(
              (status) => status.id == statusId
            ),
            1
          );
        }
      } else {
        state.activeWorkflow.statuses.push(payload);
      }
    },
    setStatusTargetStatuses(state, { statusId, payload }) {
      let status = state.activeWorkflow.statuses.find((s) => s.id == statusId);
      status.targetStatuses = payload
        ? payload.map((targetStatus) => new TargetStatus(targetStatus))
        : [];
      state.activeWorkflow.statuses.splice(
        state.activeWorkflow.statuses.findIndex((s) => s.id == statusId),
        1,
        status
      );
    },
    setActiveTargetStatus(state, payload) {
      state.activeTargetStatus = payload ? new TargetStatus(payload) : null;
    },
    //#endregion
    //#region Question
    setActiveQuestion(state, payload) {
      state.activeQuestion = payload ? new Question(payload) : null;
    },
    setQuestions(state, { statusId, payload }) {
      let status = state.activeWorkflow.statuses.find((s) => s.id == statusId);
      payload.sort((a, b) => a.order - b.order);
      status.questions = payload
        ? payload.map((question) => new Question(question))
        : [];
      state.activeWorkflow.statuses.splice(
        state.activeWorkflow.statuses.findIndex((s) => s.id == statusId),
        1,
        new Status(status)
      );
    },
    SET_STATUS_QUESTIONS(state, { statusId, payload }) {
      if (!state.activeWorkflow) return;
      let status = state.activeWorkflow.statuses?.find((s) => s.id == statusId);
      payload.sort((a, b) => a.order - b.order);
      status.questions =
        payload?.length > 0
          ? payload.map((question) => new Question(question))
          : [];
      state.activeWorkflow.statuses?.splice(
        state.activeWorkflow.statuses?.findIndex((s) => s.id == statusId),
        1,
        new Status(status)
      );
    },
    SET_STATUS_CATEGORIES(state, { statusId, payload }) {
      if (!state.activeWorkflow) return;
      let status = state.activeWorkflow.statuses?.find((s) => s.id == statusId);
      status.categories = payload
        ? payload.map((category) => new QuestionCategory(category))
        : [];
      state.activeWorkflow.statuses?.splice(
        state.activeWorkflow.statuses?.findIndex((s) => s.id == statusId),
        1,
        new Status(status)
      );
    },
    SET_STATUS_ACTION(state, { statusId, payload }) {
      if (!state.activeWorkflow?.statuses?.length > 0) return;
      payload = payload ? payload.map((action) => new Action(action)) : [];
      let status = state.activeWorkflow.statuses.find((s) => s.id == statusId);
      status.actions = payload;
      state.activeWorkflow.statuses.splice(
        state.activeWorkflow.statuses.findIndex((s) => s.id == statusId),
        1,
        status
      );
    },
    //#endregion
    //#region Modals
    setModalCreateAndEditWorkflowDialog(state, payload) {
      state.modals.isCreateAndEditWorkflowDialogOpen = payload;
    },
    setModalDeleteWorkflowDialog(state, payload) {
      state.modals.isDeleteWorkflowDialogOpen = payload;
    },
    setModalCreateAndEditStatusDialog(state, payload) {
      state.modals.isCreateAndEditStatusDialogOpen = payload;
    },
    setModalDeleteStatusDialog(state, payload) {
      state.modals.isDeleteStatusDialogOpen = payload;
    },
    setCreateAndEditStatusQuestionDialog(state, payload) {
      state.modals.isCreateAndEditStatusQuestionDialogOpen = payload;
    },
    setDeleteStatusQuestionDialog(state, payload) {
      state.modals.isDeleteStatusQuestionDialogOpen = payload;
    },
    setStatusTargetStatusCreateAndEditDialog(state, payload) {
      state.modals.isCreateAndEditStatusTargetStatusDialogOpen = payload;
    },
    setDeleteStatusTargetStatusDialog(state, payload) {
      state.modals.isDeleteStatusTargetStatusDialogOpen = payload;
    },
    setAddCollaboratorsWorkflowActionDialog(state, payload) {
      state.modals.isCollaboratorsActionDialogOpen = payload;
    },

    setCollaboratorsManagementStatusDialogOpen(state, payload) {
      state.modals.isCollaboratorsManagementStatusDialogOpen = payload;
    },
    SET_REMOVE_STATUS_CATEGORY_MODAL_STATUS(state, payload) {
      state.modals.isRemoveStatusCategoryOpen = payload;
    },
    SET_ACTIVE_STATUS_CATEGORY(state, payload) {
      state.activeStatusCategory = payload;
    },
    SET_EDIT_QUESTION_CATEGORY_MODAL_STATUS(state, payload) {
      state.modals.isEditQuestionCategoryOpen = payload;
    },
  },
  actions: {
    // get all workflow
    async getAllOrganizationWorkFlow({ commit }, organizationId) {
      const res = await fetchOrganizationWorkflows(organizationId);
      const workflows = res.data.results;
      commit(
        "setAllWorkFlows",
        workflows.sort((a, b) => (a.id > b.id ? 1 : -1))
      );
      return workflows;
    },
    async moveWorkflowStatus({ commit, state }, payload) {
      const { action, status } = payload;
      await postOrganisationWorkflowStatusMove(payload);
      const statutes = [...state.activeWorkflow.statuses];
      // just a simple permutation
      switch (action) {
        case "move_up": {
          const indexStatus = statutes.findIndex(
            (stat) => stat.order === status.order
          );
          statutes[indexStatus] = {
            ...statutes[indexStatus - 1],
            order: statutes[indexStatus - 1].order + 1,
          };
          statutes[indexStatus - 1] = { ...status, order: status.order - 1 };
          break;
        }
        default: {
          const indexStatus = statutes.findIndex(
            (stat) => stat.order === status.order
          );
          statutes[indexStatus] = {
            ...statutes[indexStatus + 1],
            order: statutes[indexStatus + 1].order - 1,
          };
          statutes[indexStatus + 1] = { ...status, order: status.order + 1 };
        }
      }
      return commit("SET_ACTIVE_WORKFLOW", {
        ...state.activeWorkflow,
        statuses: [...statutes],
      });
    },
    //#region Workflow CRUD
    async fetchWorkflows({ commit }, organizationId) {
      let page = 1;
      let workflows = [];
      while (page) {
        const res = await fetchOrganizationWorkflows(organizationId, page);
        if (!res) return;
        workflows = [...workflows, ...res.data.results];
        page = res.data.next;
      }
      commit("setWorkflows", workflows);
      return workflows;
    },
    async fetchActiveWorkflow({ commit }, { organizationId, workflowId }) {
      let workflow = (
        await fetchOrganizationWorkflow(organizationId, workflowId)
      ).data;
      commit("SET_ACTIVE_WORKFLOW", workflow);
      return workflow;
    },
    async createWorkflow({ state, commit }, { organizationId, payload }) {
      const res = await postOrganizationWorkflow(organizationId, payload);
      const workflowCreated = res.data;
      commit("setWorkflows", [...state.workflows, workflowCreated]);
      return workflowCreated;
    },
    async updateWorkflow(
      { state, commit },
      { organizationId, workflowId, payload }
    ) {
      const res = await patchOrganizationWorkflow(
        organizationId,
        workflowId,
        payload
      );
      const workflow = res.data;
      commit("setWorkflow", {
        ...state.activeWorkflow,
        name: workflow.name,
        primaryStatus: workflow.primaryStatus,
      });
      return workflow;
    },
    async deleteWorkflow({ state, commit }, { organizationId, workflowId }) {
      await deleteOrganizationWorkflow(organizationId, workflowId);
      commit(
        "setWorkflows",
        state.workflows.filter((wf) => wf.id !== workflowId)
      );
    },
    //#endregion
    //#region Status CRUD
    async createWorkflowStatus(
      { commit },
      { organizationId, workflowId, payload }
    ) {
      const res = await postOrganizationWorkflowStatus(
        organizationId,
        workflowId,
        payload
      );
      const statusCreated = res.data;
      commit("setStatus", {
        workflowId: workflowId,
        statusId: statusCreated.id,
        payload: statusCreated,
      });
      return statusCreated;
    },
    async updateWorkflowStatus(
      { commit },
      { organizationId, workflowId, statusId, payload }
    ) {
      const res = await patchOrganizationWorkflowStatus(
        organizationId,
        workflowId,
        statusId,
        payload
      );
      const statusUpdated = res.data;
      commit("setStatus", {
        workflowId: workflowId,
        statusId: statusId,
        payload: statusUpdated,
      });
      return statusUpdated;
    },
    async deleteWorkflowStatus(
      { commit },
      { organizationId, workflowId, statusId }
    ) {
      const res = await deleteOrganizationWorkflowStatus(
        organizationId,
        workflowId,
        statusId
      );
      commit("setStatus", {
        workflowId: workflowId,
        statusId: statusId,
        payload: null,
      });
      return res.data;
    },
    //#endregion
    //#region Question CRUD
    async fetchStatusQuestions(
      { commit, dispatch },
      { organizationId, workflowId, statusId }
    ) {
      await dispatch("fetchStatusCategories", {
        organizationId,
        workflowId,
        statusId,
      });
      // GET Status Question
      let qpage = 1;
      let questions = [];
      while (qpage) {
        let res = await WorkflowAPI.fetchorganizationWorkflowStatusQuestions(
          organizationId,
          workflowId,
          statusId,
          qpage
        );
        qpage = res.data.next;
        questions = [...questions, ...res.data.results];
      }
      commit("SET_STATUS_QUESTIONS", { statusId, payload: questions });
    },
    async fetchStatusCategories(
      { commit },
      { organizationId, workflowId, statusId }
    ) {
      let page = 1;
      let statusCategories = [];
      while (page) {
        let res = await WorkflowAPI.getWorkflowStatusCategories({
          organizationId,
          workflowId,
          statusId,
          page: page,
        });
        page = res.data.next;
        statusCategories = [...statusCategories, ...res.data.results];
      }
      commit("SET_STATUS_CATEGORIES", {
        statusId,
        payload: statusCategories,
      });
      return statusCategories;
    },
    async moveStatusCategory(
      { dispatch },
      { organizationId, workflowId, statusId, categoryId, order }
    ) {
      await WorkflowAPI.postWorkflowStatusCategoryOrder({
        organizationId,
        workflowId,
        statusId,
        categoryId,
        payload: { order },
      });

      await dispatch("fetchStatusCategories", {
        organizationId,
        workflowId,
        statusId,
      });
    },
    async deleteStatusCategory(
      { dispatch },
      { organizationId, workflowId, statusId, categoryId }
    ) {
      await WorkflowAPI.deleteWorkflowStatusCategory({
        organizationId: organizationId,
        workflowId: workflowId,
        statusId: statusId,
        categoryId: categoryId,
      });
      dispatch("fetchStatusQuestions", {
        organizationId,
        workflowId,
        statusId,
      });
    },
    async fetchFolderQuestions(
      { commit, dispatch },
      { organizationId, workflowId, folderId, statusId }
    ) {
      await dispatch("fetchStatusCategories", {
        organizationId,
        workflowId,
        statusId,
      });
      // GET Folder Status Question
      let qpage = 1;
      let questions = [];
      while (qpage) {
        let res = await fetchOrganizationFolderStatusQuestions(
          organizationId,
          folderId,
          statusId,
          qpage
        );
        qpage = res.data.next;
        questions = [...questions, ...res.data.results];
      }
      commit("SET_STATUS_QUESTIONS", { statusId, payload: questions });
    },
    async createWorkflowStatusQuestion(
      _,
      { organizationId, workflowId, statusId, payload }
    ) {
      const question = await postOrganizationWorkflowStatusQuestion(
        organizationId,
        workflowId,
        statusId,
        payload
      );
      return question;
    },
    async createWorkflowStatusDatatypeQuestion(
      _,
      { organizationId, workflowId, statusId, payload }
    ) {
      const question = await postOrganisationWorkflowStatusDatatypeQuestion({
        organizationId,
        workflowId,
        statusId,
        payload,
      });
      return question;
    },
    async editWorkflowStatusQuestion(
      { state, commit },
      {
        organizationId,
        workflowId,
        statusId,
        questionId,
        payload,
        deletedOptionsIds,
        updateState = true,
      }
    ) {
      const question = await updateOrganizationWorkflowStatusQuestion(
        organizationId,
        workflowId,
        statusId,
        questionId,
        payload,
        deletedOptionsIds
      );
      if (updateState) {
        const status = state.activeWorkflow.statuses.find(
          (s) => s.id == statusId
        );
        commit("SET_STATUS_QUESTIONS", {
          statusId,
          payload: [
            ...status.questions.filter((q) => q.id != question.id),
            question,
          ],
        });
      }
      return question;
    },
    async deleteWorkflowStatusQuestion(
      { state, commit },
      { organizationId, workflowId, statusId, questionId }
    ) {
      await deleteOrganizationWorkflowStatusQuestion(
        organizationId,
        workflowId,
        statusId,
        questionId
      );
      const status = state.activeWorkflow.statuses.find(
        (s) => s.id == statusId
      );
      commit("setQuestions", {
        statusId,
        payload: status.questions.filter((q) => q.id != questionId),
      });
    },
    //#endregion
    //#region Answer CRUD
    async postQuestionAnswer(
      { state, commit },
      { organizationId, folderId, statusId, questionId, payload }
    ) {
      const res = await postOrganizationFolderStatusQuestionAnswer({
        organizationId,
        folderId,
        statusId,
        questionId,
        payload,
      });
      const answer = res.data;
      const status = state.activeWorkflow.statuses.find(
        (s) => s.id == statusId
      );
      let question = Object.assign(
        {},
        status.questions.find((q) => q.id == questionId)
      );
      const questionPayload = {
        ...question,
        answer,
      };
      commit("setQuestions", {
        statusId,
        payload: [
          ...status.questions.filter((q) => q.id != questionId),
          questionPayload,
        ],
      });
      return answer;
    },
    async patchQuestionAnswer(
      { state, commit },
      { organizationId, folderId, statusId, questionId, answerId, payload }
    ) {
      const res = await patchOrganizationFolderStatusQuestionAnswer({
        organizationId,
        folderId,
        statusId,
        questionId,
        answerId,
        payload,
      });
      const answer = res.data;
      const status = state.activeWorkflow.statuses.find(
        (s) => s.id == statusId
      );
      let question = Object.assign(
        {},
        status.questions.find((q) => q.id == questionId)
      );
      question.answer = answer;
      commit("setQuestions", {
        statusId,
        payload: [
          ...status.questions.filter((q) => q.id != questionId),
          question,
        ],
      });
      return res;
    },
    async deleteQuestionAnswer(
      { commit, state },
      { organizationId, folderId, statusId, questionId, answerId }
    ) {
      await deleteOrganizationFolderStatusQuestionAnswer(
        organizationId,
        folderId,
        statusId,
        questionId,
        answerId
      );
      const status = state.activeWorkflow.statuses.find(
        (s) => s.id == statusId
      );
      let question = Object.assign(
        {},
        status.questions.find((q) => q.id == questionId)
      );
      question.answer = null;
      commit("setQuestions", {
        statusId,
        payload: [
          ...status.questions.filter((q) => q.id != questionId),
          question,
        ],
      });
    },
    //#endregion
    //#region Option CRUD
    async findQuestionOptions(
      _,
      { organizationId, workflowId, statusId, questionId }
    ) {
      return await fetchOrganizationWorkflowStatusQuestionOptions(
        organizationId,
        workflowId,
        statusId,
        questionId
      );
    },
    //#endregion
    //#region TargetStatus CRUD
    async postTargetStatus(
      { commit, state },
      { organizationId, workflowId, statusId, payload }
    ) {
      const res = await createOrganizationWorkflowStatusTargetStatus(
        organizationId,
        workflowId,
        statusId,
        payload
      );
      const newTargetStatus = res.data;
      let status = {
        ...state.activeWorkflow.statuses.find((s) => s.id == statusId),
      };
      commit("setStatus", {
        statusId,
        payload: {
          ...status,
          targetStatuses: status.targetStatuses.concat(newTargetStatus),
        },
      });
      return res;
    },
    async fetchTargetStatuses(
      { commit, state },
      { organizationId, workflowId, statusId }
    ) {
      const res = await fetchOrganizationWorkflowStatusTargetStatuses(
        organizationId,
        workflowId,
        statusId
      );
      let targetStatuses = res.data.results;
      let status = state.activeWorkflow.statuses.find((s) => s.id == statusId);
      if (status.targetStatuses && status.targetStatuses.length) {
        targetStatuses = [...status.targetStatuses, ...targetStatuses];
      }

      commit("setStatusTargetStatuses", {
        statusId,
        payload: targetStatuses,
      });
      return targetStatuses;
    },
    resetTargetStatuses({ commit }, statusId) {
      commit("setStatusTargetStatuses", {
        statusId,
        payload: [],
      });
    },
    async updateTargetStatus(
      { commit, state },
      { organizationId, workflowId, statusId, targetStatusId, payload }
    ) {
      const res = await updateOrganizationWorkflowStatusTargetStatus(
        organizationId,
        workflowId,
        statusId,
        targetStatusId,
        payload
      );
      const targetStatus = res.data;
      let status = {
        ...state.activeWorkflow.statuses.find((s) => s.id == statusId),
      };
      let tempTargetStatuses = [...status.targetStatuses];
      tempTargetStatuses.splice(
        status.targetStatuses.findIndex((ts) => ts.id == targetStatus.id),
        1,
        targetStatus
      );
      commit("setStatusTargetStatuses", {
        statusId,
        payload: tempTargetStatuses,
      });
      return targetStatus;
    },
    async deleteTargetStatus(
      { commit, state },
      { organizationId, workflowId, statusId, targetStatusId }
    ) {
      await deleteOrganizationWorkflowStatusTargetStatus(
        organizationId,
        workflowId,
        statusId,
        targetStatusId
      );
      let status = {
        ...state.activeWorkflow.statuses.find((s) => s.id == statusId),
      };
      commit("setStatus", {
        statusId,
        payload: {
          ...status,
          targetStatuses: status.targetStatuses.filter(
            (s) => s.id != targetStatusId
          ),
        },
      });
    },
    //#endregion
    //#region Actions CRUD
    async fetchStatusActions(
      { commit },
      { organizationId, workflowId, statusId }
    ) {
      let page = 1;
      let actions = [];
      while (page) {
        const res = await fetchOrganizationWorkflowStatusActions({
          organizationId,
          workflowId,
          statusId,
          page,
        });
        actions = [...actions, ...res.data.results];
        page = res.data.next;
      }
      commit("SET_STATUS_ACTION", {
        statusId,
        payload: [...actions],
      });
      return actions;
    },
    async fetchFolderStatusActions(
      { commit },
      { organizationId, folderId, statusId }
    ) {
      let page = 1;
      let actions = [];
      while (page) {
        const res = await fetchOrganizationFolderStatusActions({
          organizationId,
          folderId,
          statusId,
          page,
        });
        actions = [...actions, ...res.data.results];
        page = res.data.next;
      }
      commit("SET_STATUS_ACTION", {
        statusId,
        payload: actions,
      });
      return actions;
    },
    async toggleStatusAction(
      { commit, state },
      { organizationId, workflowId, statusId, actionId, enable }
    ) {
      if (enable) {
        await postOrganizationWorkflowStatusAction({
          organizationId,
          workflowId,
          statusId,
          actionId,
        });
      } else {
        await deleteOrganizationWorkflowStatusAction({
          organizationId,
          workflowId,
          statusId,
          actionId,
        });
      }
      const status = state.activeWorkflow.statuses.find(
        (s) => s.id == statusId
      );
      commit("SET_STATUS_ACTION", {
        statusId,
        payload: status.actions.map((a) => {
          if (a.id != actionId) return a;
          return { ...a, enable };
        }),
      });
    },
    //#endregion
    //#region Collaborator CRUD
    //read
    async fetchOrganizationCollaborators({ commit }, { organizationId }) {
      const response = await getOrganizationCollaborators({ organizationId });
      let next = response.data.next;
      let allCollaboratorLists = [...response.data.results];
      while (next !== null) {
        const responsePagination = await getOrganizationCollaborators({
          organizationId,
          page: next,
        });
        allCollaboratorLists = [
          ...allCollaboratorLists,
          ...responsePagination.data.results,
        ];
        next = responsePagination.data.next;
      }
      commit("setGlobalCollaborators", [...allCollaboratorLists]);
      return [...allCollaboratorLists];
    },
    //#endregion
    //#region Status Collaborators CRUD
    //create
    async addCollaboratorToStatus(
      { commit },
      { collaboratorId, organizationId, statusId, workflowId }
    ) {
      await addCollaboratorStatus(
        collaboratorId,
        organizationId,
        statusId,
        workflowId
      );
      const res = await getCollaboratorAssignedToStatus(
        organizationId,
        workflowId,
        statusId
      );
      const assignedCollaborators = res.data.results;
      commit("setAssignedCollaboratorsStatus", {
        statusId,
        payload: assignedCollaborators,
      });
      return assignedCollaborators;
    },
    //read
    async fetchCollaboratorAssigned(
      { commit },
      { organizationId, statusId, workflowId }
    ) {
      const res = await getCollaboratorAssignedToStatus(
        organizationId,
        workflowId,
        statusId
      );
      const collaboratorsAssigned = res.data.results;
      commit("setAssignedCollaboratorsStatus", {
        statusId,
        payload: collaboratorsAssigned,
      });
      return collaboratorsAssigned;
    },
    async fetchTargetStatusAssignedCollaborator(
      _,
      { organizationId, statusId, workflowId }
    ) {
      return await getCollaboratorAssignedToStatus(
        organizationId,
        workflowId,
        statusId
      );
    },
    //delete
    async delCollaboratorToStatus(
      { commit },
      { organizationId, workflowId, statusId, assignStatusId }
    ) {
      await delCollaboratorStatus(
        organizationId,
        workflowId,
        statusId,
        assignStatusId
      );
      const res = await getCollaboratorAssignedToStatus(
        organizationId,
        workflowId,
        statusId
      );
      const assignedCollaborators = res.data.results;
      commit("setAssignedCollaboratorsStatus", {
        statusId,
        payload: assignedCollaborators,
      });
      return assignedCollaborators;
    },
    //#endregion
    // get yousign position

    //#region YouSign CRUD
  },
  getters: {
    getAllWorkFlow: (state) => state.allWorkFlows,
    isWorkflowActiveSet: (state) => state.activeWorkflow !== null,
    orderedStatusQuestions: (state) => (statusId) => {
      const questions = state.activeWorkflow.statuses.find(
        (s) => s.id == statusId
      ).questions;
      if (!questions?.length > 0) {
        return [];
      }
      let orderedQuestions = [...questions].sort((a, b) => a.order - b.order);
      orderedQuestions = orderedQuestions.map((question, index) => {
        return {
          ...question,
          isFirst: index === 0,
          isLast: index === orderedQuestions.length - 1,
        };
      });
      return orderedQuestions;
    },
    collaborators: (state) => {
      return state.collaborators;
    },
    activeTargetStatus: (state) => {
      return state.activeTargetStatus;
    },
  },
};
