var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"value":_vm.dialogState,"modal-title":_vm.$t('folder.workflow.assignCollaborator')},on:{"click:outside":_vm.reset,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.reset.apply(null, arguments)},"close":_vm.reset},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('v-list',_vm._l((_vm.collaboratorsList),function(assignation,index){return _c('v-list-item',{key:index,staticClass:"list-item"},[_c('v-checkbox',{attrs:{"input-value":assignation.isAssigned,"label":assignation.user.email},on:{"&change":function($event){return _vm.toggleAddDelCollaborator(assignation)}}})],1)}),1)]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080"},on:{"click":function () {
          _vm.$store.commit('workflow/setActiveStatus', null);
          _vm.$store.commit(
            'workflow/setCollaboratorsManagementStatusDialogOpen',
            false
          );
        }}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"type":"primary"},on:{"click":function () {
          _vm.$store.commit('workflow/setActiveStatus', null);
          _vm.$store.commit(
            'workflow/setCollaboratorsManagementStatusDialogOpen',
            false
          );
        }}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }