var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{attrs:{"max-width":"600","value":_vm.dialogState},on:{"click:outside":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('BaseCosmoCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"modal_header"},[_c('BaseTitle',{domProps:{"textContent":_vm._s(_vm.$t('folder.logs.history'))}}),_c('BaseButtonIcon',{staticClass:"close_icon",attrs:{"icon":"$mdiClose"},on:{"click":function($event){return _vm.$emit('close')}}})],1)]},proxy:true}])},[(_vm.offerClient)?_c('div',{staticClass:"modal_content"},[_c('BaseDataTable',{staticClass:"mb-4 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.offerClient.reminders,"items-per-page":15},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('div',{staticClass:"log_item"},[_c('div',{staticClass:"log_label",domProps:{"textContent":_vm._s(
                      ((_vm.$d(new Date(item.dateCreated), 'veryshort')) + " " + (_vm.$d(
                        new Date(item.dateCreated),
                        'time'
                      )))
                    )}}),_c('div',{staticClass:"log_label"},[_c('span',{domProps:{"textContent":_vm._s(((_vm.$t('utils.sentTo')) + " : "))}}),_c('span',{staticClass:"strong",domProps:{"textContent":_vm._s(item.collaborator.email)}}),_vm._v(" ("),_c('span',{staticClass:"ml-1",domProps:{"textContent":_vm._s(item.collaborator.firstname)}}),_c('span',{staticClass:"ml-1",domProps:{"textContent":_vm._s(item.collaborator.lastname)}}),_vm._v(") ")])])])])]}}],null,false,2236646959)})],1):_vm._e(),_c('div',{staticClass:"modal_footer"},[_c('BaseButton',{attrs:{"olor":"primary","type":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("utils.back"))+" ")])],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }