<template>
  <div>
    <BaseModal
      :value="dialogState"
      :modal-title="modalTitle"
      is-complex
      @close="reset"
    >
      <template #modal>
        <div v-if="isSirenSiret" class="extracted">
          <workflow-extracted-data-fields
            :extracted-data="personalData.extractedData"
            :personal-data="personalData"
          />
        </div>

        <WezideeClientData
          v-else
          :extracted-data="personalData.extractedData"
          :content="personalData.content"
        />
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click.prevent="reset"
        >
          {{ $t("navigation.close") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import WezideeClientData from "./WezideeClientData.vue";
import WorkflowExtractedDataFields from "./WorkflowExtractedDataFields.vue";
export default {
  name: "ClientWezideeDialog",
  components: {
    WezideeClientData,
    WorkflowExtractedDataFields,
  },
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
    dialogState: {
      type: Boolean,
    },
  },

  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    isSirenSiret() {
      return ["SIREN", "Siret"].includes(this.personalData.datatype.name);
    },
    modalTitle() {
      if (!this.personalData.datatype) return "";
      return this.personalData.datatype.name;
    },
  },
  methods: {
    reset() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.extracted {
  padding: 0 14px;
}
</style>
