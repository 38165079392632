<template>
  <div>
    <BaseFrontOfficeWalletWrapper
      small
      :mobile-title-image="!isDigilink ? $findStatic('walletAuthLogin') : ''"
    >
      <template #title>
        <BaseFrontOfficeTitle v-text="title" />
      </template>

      <BaseFrontOfficeBoard>
        <div class="d-block">
          <div class="d-flex align-center">
            <div>
              <BaseFrontOfficeSubtitle v-text="errorMessage" />
              <div
                v-for="(content, index) in messages"
                :key="index"
                class="message"
                v-text="content"
              />
            </div>

            <BaseFrontOfficeImage
              :src="
                $findStatic(
                  isDigilink ? 'logoSrConseil' : 'cosmoWalletPageNotFound'
                )
              "
              contain
            />
          </div>

          <div
            v-if="!isDigilink"
            class="d-flex flex-column flex-sm-row justify-center mt-5"
          >
            <BaseFrontOfficeButton
              class="mr-0 mr-sm-3 mb-2 mb-sm-0"
              type="secondary"
              @click.prevent="goToMyCoSite()"
              v-text="$t('account.myCoKnowMore')"
            />
            <BaseFrontOfficeButton
              :to="{ name: 'WalletHome' }"
              v-text="$t('pageNotFound.backToHome')"
            />
          </div>
        </div>
      </BaseFrontOfficeBoard>
    </BaseFrontOfficeWalletWrapper>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
  computed: {
    isDigilink() {
      return (
        process.env.VUE_APP_COSMO_URL_API == "https://digilink.anderlaine.com"
      );
    },
    errorMessage() {
      if (this.$route.query.link_expired)
        return this.$t("pageNotFound.linkExpired");
      return `${this.$t("pageNotFound.OopsError")}`;
    },
    title() {
      if (this.$route.query.link_expired) return this.$t("utils.expiredLink");
      return "404";
    },
    messages() {
      if (this.$route.query.link_expired)
        return [this.$t("pageNotFound.linkExpiredMessage")];
      return [this.$t("pageNotFound.404ErrorMessage")];
    },
  },
  methods: {
    goToMyCoSite() {
      open("https://www.myco.coop/");
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  color: var(--v-walletGrey-base);
  font-size: 16px !important;
  line-height: 22px;
  text-transform: initial;
  font-weight: 400;
  font-family: var(--cosmo-font-nunito);
  margin-bottom: 4px;
}
</style>
