import { render, staticRenderFns } from "./WorkflowWezideeQuestionField.vue?vue&type=template&id=20d69630&scoped=true"
import script from "./WorkflowWezideeQuestionField.vue?vue&type=script&lang=js"
export * from "./WorkflowWezideeQuestionField.vue?vue&type=script&lang=js"
import style0 from "./WorkflowWezideeQuestionField.vue?vue&type=style&index=0&id=20d69630&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d69630",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VList,VListItem,VMenu})
