<template>
  <div>
    <transition :name="isMobile ? 'display' : ''">
      <div
        v-if="displayMenu && !showMenuBurger"
        id="menu_container"
        class="px-4 pt-3"
      >
        <v-btn
          class="close_btn"
          icon
          @click="isMobile ? hideMenu() : onClickCloseMenuDesktop()"
        >
          <v-icon large color="green darken-2"> $mdiClose </v-icon>
        </v-btn>
        <div class="menu-item d-flex flex-column align-start mt-9">
          <template v-if="isAuthenticated">
            <v-list nav dense class="nav_list">
              <v-list-item
                link
                class="mb-4"
                :to="{ name: 'WalletHome' }"
                @click="isMobile && hideMenu()"
              >
                <WalletNavigationLink
                  :link-name="$t('wallet.mySpace')"
                  :image-name="
                    $findStatic(
                      isMobile
                        ? 'cosmoWalletAssistantDark'
                        : 'cosmoWalletAssistant'
                    )
                  "
                  image-required
                  :class-name="
                    $route.name === 'WalletHome'
                      ? 'active_link'
                      : isMobile
                      ? 'default_link'
                      : ''
                  "
                />
              </v-list-item>
              <v-list-item
                v-for="organization in organizations"
                :key="organization.id"
                class="mb-4 ml-8"
                :to="{
                  name: 'WalletOrganizationRecords',
                  params: {
                    organizationId: organization.id,
                  },
                }"
                @click="isMobile && hideMenu()"
                @click.prevent="
                  () => {
                    $store.commit(
                      'organization/setActiveOrganization',
                      organization
                    );
                  }
                "
              >
                <WalletNavigationLink
                  :link-name="organization.name"
                  :image-name="
                    $findStatic(
                      $route.name === 'WalletOrganizationRecords' &&
                        $route.params.organizationId === +organization.id
                        ? 'cosmoWalletActiveAssistant'
                        : 'cosmoWalletAssistant'
                    )
                  "
                  :class-name="
                    $route.name === 'WalletOrganizationRecords' &&
                    $route.params.organizationId === +organization.id
                      ? 'active_link'
                      : isMobile
                      ? 'default_link'
                      : ''
                  "
                />
              </v-list-item>
            </v-list>
            <v-list v-if="isMobile" nav dense class="nav_list">
              <v-list-item
                :to="{
                  name: 'WalletAccount',
                }"
                class="mb-4"
                @click="isMobile && hideMenu()"
              >
                <WalletNavigationLink
                  :link-name="$t('navigation.myAccount')"
                  :image-name="
                    $findStatic(
                      $route.name === 'WalletAccount'
                        ? 'cosmoWalletActiveProfile'
                        : 'cosmoWalletProfileDark'
                    )
                  "
                  image-required
                  :class-name="
                    $route.name === 'WalletAccount'
                      ? 'active_link'
                      : isMobile
                      ? 'default_link'
                      : ''
                  "
                />
              </v-list-item>
            </v-list>
          </template>
        </div>
      </div>
    </transition>
    <div v-if="displayMenu" class="backdrop" @click="isMobile && hideMenu()" />
    <div v-if="showMenuBurger && !isMobile" class="menu-burger-button">
      <v-btn icon @click="showMenu()">
        <v-icon x-large class="menu-burger-icon"> $mdiMenu </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WalletNavigationLink from "./WalletNavigationLink.vue";

export default {
  name: "TheFrontOfficeNavigationBar",
  components: {
    WalletNavigationLink,
  },
  data() {
    return {
      isMobile: true,
      displayMenu: false,
      showMenuBurger: true,
    };
  },
  computed: {
    ...mapState({
      organizations: (state) => state.walletOrganization.organizations,
      isAuthenticated: (state) => state.account.isAuthenticated,
    }),
  },

  watch: {
    isMobile(value) {
      this.displayMenu = !value;
    },
  },
  mounted() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkWindowWidth);
  },
  methods: {
    showMenu() {
      this.displayMenu = true;
      this.showMenuBurger = false;
    },
    hideMenu() {
      this.displayMenu = false;
    },
    onClickCloseMenuDesktop() {
      this.hideMenu();
      this.showMenuBurger = true;
    },
    checkWindowWidth() {
      this.isMobile = !(window.innerWidth > 600);
    },
  },
};
</script>

<style lang="scss" scoped>
.display-enter-active {
  transform-origin: top right;
  animation: display 0.3s ease-in-out;
}
.display-leave-active {
  transform-origin: top right;
  animation: display 0.3s ease-in-out reverse;
}
@keyframes display {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

#menu_container {
  width: 256px;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(
      175.16deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    ),
    #ff5b60;
  @media (max-width: 600px) {
    position: fixed;
    top: 0;
    width: auto;
    min-width: min(300px, calc(100vw - 64px));
    max-width: max(300px, calc(100vw - 64px));
    z-index: 1001;
    height: auto;
    right: 0;
    left: auto;
    background: #fff;
    border-radius: 5px;
    margin: 16px;
    max-height: calc(100vh - 32px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #2f479d;
      border-radius: 2px;
    }
  }
}

@media all and (min-width: 600px) {
  .menu-item.mt-9 {
    margin-top: 1.5rem !important;
  }
  .nav_list.mt-16 {
    margin-top: 0px !important;
  }
}

.close_btn {
  display: block;
  position: sticky;
  top: 0px;
  margin-right: -10px;
  margin-left: auto;
  &::v-deep .v-icon {
    color: #ffffff !important;
  }
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-top: 0px;
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 16px;
    &::v-deep .v-icon {
      color: #3c3b8e !important;
    }
  }
}

.menu-burger-button {
  margin-top: 25px;
  margin-left: 30px;
}

::v-deep .menu-burger-icon {
  .v-icon__component {
    .v-icon__svg {
      width: 100% !important;
      height: 100% !important;
      color: #3c3b8e !important;
    }
  }
}

.logo {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}

.nav_list {
  background: transparent;
  @media (max-width: 600px) {
    color: #2f479d;
    margin-top: 0px !important;
  }
}
@media (max-width: 600px) {
  .backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1000;
  }
}

.cosmo-app-bar-link {
  color: white;
  text-decoration: none;
}

/* animation slide hover */
.left-slider-on-hover {
  transition: all 0.2s ease-in-out;
  position: relative;
}
.left-slider-on-hover::before,
.hover::after {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 0px;
  height: 3px;
  transition-duration: 0.75s;
  opacity: 0;
  left: 0;
  background-color: #ff5621;
  border-radius: 2px;
}
.left-slider-on-hover:hover {
  cursor: pointer;
}
.left-slider-on-hover:hover::after,
.left-slider-on-hover:hover::before {
  width: 100%;
  opacity: 1;
}
::v-deep .v-list .v-list-item {
  color: rgba(0, 0, 0, 0) !important;
}
</style>
