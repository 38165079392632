var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mb-6"},[_c('div',{staticClass:"bo_title mb-6",domProps:{"textContent":_vm._s(_vm.$tc('organization.course', 10))}}),_c('Board',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){_vm.isCreateCourseDialogOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t("course.create"))+" ")]),_c('div',[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"outlined":"","label":_vm.$t('course.search'),"prepend-inner-icon":"$mdiMagnify","dense":"","hide-details":"","must-sort":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"main",fn:function(){return [_c('v-tabs',{attrs:{"light":""},on:{"change":_vm.changeTab},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"background-color":"primary"}},[_c('BaseIcon',{staticClass:"pr-2",attrs:{"icon":"$mdiCheck","small":""}}),_vm._v(" "+_vm._s(_vm.$t("course.active"))+" "),_c('span',{staticClass:"lowercase pl-2"},[_vm._v("("+_vm._s(_vm.activeCoursesAmount)+")")])],1),_c('v-tab',[_c('BaseIcon',{staticClass:"pr-2",attrs:{"icon":"$mdiArchive","small":""}}),_vm._v(" "+_vm._s(_vm.$t("course.archives"))+" "),_c('span',{staticClass:"lowercase pl-2"},[_vm._v(" ("+_vm._s(_vm.archivedCoursesAmount)+") ")])],1)],1),_c('BaseDataTable',{staticClass:"elevation-1 mb-6",attrs:{"items":_vm.displayedCourses,"headers":_vm.COURSES_LIST_HEADER,"loading":(_vm.displayActiveCourses && _vm.activeCoursesLoading) ||
          (!_vm.displayActiveCourses && _vm.isArchivedCoursesLoading),"loading-text":_vm.$t('utils.loadingData'),"sort-by":"dateCreated","sort-desc":"","must-sort":"","no-data-text":_vm.$tc('organization.course', 0)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{staticClass:"row--link",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'courseDetail',
                params: {
                  organizationId: _vm.organizationId,
                  courseId: item.id,
                },
              })}}},[_c('td',[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(item.name)}})]),_c('td',{staticClass:"text-center"},[(item.anonymous)?_c('BaseIcon',{attrs:{"icon":"$mdiCheckboxMarked","color":"primary"}}):_c('BaseIcon',{attrs:{"icon":"$mdiCloseBox","color":"grey lighten-2"}})],1),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(item.campaignsCount)}})]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(item.activeRecordsCount)}})]),(item.campaignsCount)?_c('td',{staticClass:"text-center"},[_c('BaseButtonIcon',{attrs:{"icon":"$mdiArchive","color":"primary","x-small":""},on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.$store.commit('course/SET_COURSE', item);
                    _vm.isCourseToggleArchivedDialogOpen = true;
                  }).apply(null, arguments)}}})],1):_c('td',{staticClass:"text-center"},[_c('BaseButtonIcon',{attrs:{"icon":"$mdiTrashCanOutline","color":"secondary","x-small":""},on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.$store.commit('course/SET_COURSE', item);
                    _vm.isDeleteCourseDialogOpen = true;
                  }).apply(null, arguments)}}})],1)])]}}])})]},proxy:true}])}),_c('CourseDeleteDialog',{attrs:{"is-open":_vm.isDeleteCourseDialogOpen},on:{"deleted":_vm.onDelete,"close":function($event){_vm.isDeleteCourseDialogOpen = false}}}),_c('CourseCreateAndEditDialog',{attrs:{"is-open":_vm.isCreateCourseDialogOpen},on:{"close":function($event){_vm.isCreateCourseDialogOpen = false}}}),_c('CourseToggleArchiveDialog',{attrs:{"is-open":_vm.isCourseToggleArchivedDialogOpen},on:{"close":function () {
        _vm.$store.commit('course/SET_COURSE', null);
        _vm.isCourseToggleArchivedDialogOpen = false;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }