<template>
  <div class="email_item_container">
    <!-- HEADER -->
    <FolderEmailItemHeader
      :email="email"
      :email-client="emailClient"
      :expanded="expanded"
      @expandChange="changeExpandStatus()"
    />

    <!-- BODY -->
    <template v-if="expanded">
      <div class="item_body">
        <div class="separator" />
        <div class="item_content">
          <div class="email_details">
            <!-- CONTACT -->

            <div class="email_detail_item">
              <div class="title" v-text="$tc('utils.contact', 1)" />
              <div class="content">
                <BaseDataTable
                  :headers="EMAIL_CLIENT_HEADER"
                  :items="emailClient"
                  hide-default-footer
                  class="contact_list"
                >
                  <template #item="{ item }">
                    <tr class="contact_item">
                      <td>
                        <div class="d-flex align-center">
                          <BaseParagraph v-text="item.email" />
                        </div>
                      </td>

                      <td>
                        <div
                          class="chips"
                          :class="item.statusClass"
                          v-text="item.statusLabel"
                        />
                      </td>
                      <td class="text-center">
                        <BaseParagraph
                          v-text="
                            item.reminders.length - 1 > 0
                              ? item.reminders.length - 1
                              : 0
                          "
                        />
                      </td>
                      <td>
                        <div v-if="item.reminders.length">
                          <button
                            class="relaunch_btn"
                            :class="{ relaunched: item.reminders.length > 1 }"
                            @click="openRemindDialog(item)"
                          >
                            <BaseIcon class="icon" icon="$mdiEmailOutline" />
                            <span v-text="$t('utils.relaunch')" />
                          </button>
                        </div>
                        <div v-else v-text="' - '" />
                      </td>
                    </tr>
                  </template>
                </BaseDataTable>
              </div>
            </div>

            <!-- Message section -->
            <div v-if="email.content" class="email_detail_item">
              <div class="title" v-text="'Message'" />
              <div class="content">
                <div class="desc_content">
                  <BaseMarkdownViewer
                    :content="email.content"
                    is-text-caption
                  />
                </div>
              </div>
            </div>
            <!-- Message section end -->
            <!-- Document -->
            <div v-if="email.files.length" class="email_detail_item">
              <div class="title" v-text="'Fichiers'" />
              <div class="content">
                <div class="documents_card">
                  <div class="email_files">
                    <template>
                      <div
                        v-for="(file, i) in email.files"
                        :key="`f-${i}`"
                        class="offer_file_item"
                        @click.stop.prevent="open(file)"
                      >
                        <div class="d-flex">
                          <div class="label" v-text="'Nom du fichier:'" />
                          <div class="file_name" v-text="file.name" />
                        </div>
                        <div class="file">
                          <BasePdfViewer
                            v-if="file.url.includes('.pdf')"
                            :pdf="file.url"
                            :page="1"
                          />
                          <base-icon
                            v-else-if="
                              ['.docx', '.doc', '.pptx'].some((v) =>
                                file.url.includes(v)
                              )
                            "
                            icon="$mdiFileDocument"
                            color="primary"
                          />
                          <BaseImage v-else :src="file.url" />
                        </div>
                        <v-btn
                          class="down_btn"
                          icon
                          @click.stop.prevent="downloadFile(file.url, file.name)"
                        >
                          <BaseIcon icon="$mdiDownload" />
                        </v-btn>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- Document -->
          </div>
        </div>
      </div>
    </template>

    <FolderRelaunchEmailDialog
      :email="email"
      :email-client="selectEmail"
      :dialog-state="remindDialogState"
      :organization-id="organizationId"
      :folder-id="folderId"
      @close="remindDialogState = false"
    />
  </div>
</template>

<script>
import FolderEmailItemHeader from "./FolderEmailItemHeader";
import FolderRelaunchEmailDialog from "../FolderEmailItem/FolderRelaunchEmailDialog.vue";

import * as Sentry from "@sentry/vue";

import { saveAs } from "file-saver";
import { EMAIL_STATUS } from "@/core/Utils/types.utils";
import { mapGetters } from "vuex";

export default {
  name: "FolderEmailItem",
  components: {
    FolderEmailItemHeader,
    FolderRelaunchEmailDialog,
  },
  props: {
    email: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      remindDialogState: false,
      selectEmail: null,
      expanded: false,
      fileLoading: false,
      EMAIL_CLIENT_HEADER: [
        {
          text: "E-mail Contact",
          value: "email",
        },
        {
          text: "Status du mail",
          value: "status",
        },
        {
          text: this.$t("utils.remindNumber"),
          value: "remindNumber",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.reminder", 2),
          value: "reminder",
          sortable: false,
        },
      ],
      signatories: [],
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    emailClient() {
      return this.email?.to?.split(";").map((item) => ({
        ...this.email,
        email: item,
        statusLabel: this.lastEmailStatus(item),
        statusClass: this.lastEmailStatusClass(item),
      }));
    },
  },
  methods: {
    async changeExpandStatus() {
      this.expanded = !this.expanded;
    },
    open(link) {
      open(`${link}`);
    },
    downloadFile(fileUrl, name = "download") {
      try {
        saveAs(fileUrl, name);
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setExtra("fileUrl", fileUrl);
          const email = { ...this.email };
          scope.setExtra("email", JSON.stringify(email));
          Sentry.captureException(error);
        });
      }
    },
    openRemindDialog(email) {
      this.selectEmail = email;
      this.remindDialogState = true;
    },
    getDate(date) {
      const dateRes = new Date(date).toLocaleDateString("fr", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const time = new Date(date).toLocaleTimeString("fr");
      return `${dateRes} ${time}`;
    },
    lastEmailStatus(item) {
      let emailStatus = {};
      this.email.reminders.forEach((remind) => {
        if (remind.transactionalEmail.email === item)
          emailStatus.status = remind.transactionalEmail.status;
      });

      switch (emailStatus.status) {
        case EMAIL_STATUS.PENDING:
          emailStatus.label = this.$t("utils.emailAction.pending");
          break;
        case EMAIL_STATUS.IN_PROGRESS:
          emailStatus.label = this.$t("utils.emailAction.inProgess");
          break;
        case EMAIL_STATUS.SUCCESS:
          emailStatus.label = this.$t("utils.emailAction.emailSent");
          break;
        case EMAIL_STATUS.ERROR:
          emailStatus.label = this.$t("utils.emailAction.error");
          break;
        case EMAIL_STATUS.SENT:
          emailStatus.label = this.$t("utils.emailAction.receivedEmail");
          break;
        case EMAIL_STATUS.CLICKED:
          emailStatus.label = this.$t("utils.emailAction.clicked");
          break;
        case EMAIL_STATUS.SPAM:
          emailStatus.label = this.$t("utils.emailAction.spam");
          break;
        case EMAIL_STATUS.OPEN:
          emailStatus.label = this.$t("utils.emailAction.open");
          break;
        case EMAIL_STATUS.HARD_BOUNCE:
          emailStatus.label = this.$t("utils.hardBounce");
          break;
        case EMAIL_STATUS.SOFT_BOUNCE:
          emailStatus.label = this.$t("utils.softBounce");
          break;
      }
      return emailStatus.label;
    },
    lastEmailStatusClass(item) {
      let emailStatus = {};
      this.email.reminders.forEach((remind) => {
        if (remind.transactionalEmail.email === item)
          emailStatus.status = remind.transactionalEmail.status;
      });

      switch (emailStatus.status) {
        case EMAIL_STATUS.SUCCESS:
        case EMAIL_STATUS.PENDING:
          emailStatus.className = "chips_simple";
          break;
        case EMAIL_STATUS.SPAM:
        case EMAIL_STATUS.ERROR:
          emailStatus.className = "chips_danger";
          break;
        case EMAIL_STATUS.OPEN:
          emailStatus.className = "chips_primary";
          break;
        default:
          emailStatus.className = "chips_simple";
          break;
      }
      return emailStatus.className;
    },
  },
};
</script>

<style lang="scss" scoped>
.email_item_container {
  background: #ffffff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;

  .item_body {
    border-left: 32px solid #bda0ed;
    position: relative;
    padding-bottom: 34px;

    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .item_content {
      padding: 24px;

      .email_details {
        padding: 16px 24px;
        .email_detail_item {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #353542;
          }
          .content {
            padding: 16px;
          }
          .contact_item {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #353542;
            .index {
              padding: 4px 8px;
              width: 22px;
              height: 23px;
              background: rgba(155, 189, 255, 0.22);
              border-radius: 5px;
              margin-right: 4px;
              &.chips_simple {
                background: none;
              }
            }
            & > * {
              margin-right: 35px;
            }
            &:last-child {
              margin: 0;
            }
            .chips {
              background: #eeeef7;
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #707080;
              padding: 4px 8px;
              border-radius: 24px;
              &.chips_success {
                background: #f0ffd6;
                color: #18a300;
              }
              &.chips_danger {
                background: #fef9fa;
                color: #ff5267;
              }
              &.chips_canceled {
                background: #eeeef7;
                color: #707080;
              }
              &.chips_active {
                background: #e9f1ff;
              }
              &.chips_primary {
                color: #2536cc;
                background: #f4f7ff;
              }
            }
          }
          .desc_content {
            background: #ffffff;
            padding: 24px;
            border-radius: 5px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #353542;
            border: 0.5px solid #cfcfdc;

            .email {
              font-weight: 700;
              text-transform: uppercase;
            }
          }
          .documents_card {
            background: #fff;
            border-radius: 5px;
            padding: 12px;
            border: 0.5px solid #cfcfdc;
            .email_files {
              padding: 12px;
              display: grid;
              width: 100%;
              grid-template-columns: 1fr;
              grid-gap: 16px;
              grid-template-rows: auto;
              max-height: 248px;
              overflow: auto;
              @media (min-width: 1066px) {
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                grid-template-columns: repeat(2, calc(50% - 8px));
              }
              @media (min-width: 1400px) {
                grid-column-gap: calc(4% / 2);
                grid-row-gap: 16px;
                grid-template-columns: repeat(3, 32%);
              }
              @media (min-width: 1600px) {
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                grid-template-columns: repeat(4, calc(25% - 16px));
              }
              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 3px;
                border: 1px solid #eeeef7;
              }

              &::-webkit-scrollbar-thumb {
                background: #b6bdff;
                border-radius: 3px;
              }
              .offer_file_item {
                width: 100%;
                cursor: pointer;
                position: relative;
                .label {
                  text-transform: uppercase;
                  font-size: 10px;
                  line-height: 24px;
                  margin-right: 1px;
                  font-weight: 400;
                }
                .file_name {
                  padding: 0 8px;
                  font-weight: 600;
                  font-size: 10px;
                  line-height: 24px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #333;
                  text-transform: uppercase;
                }
                .file {
                  border: 1px solid #e5e5e5;
                  height: auto;
                  overflow: hidden;
                  width: 100%;
                  height: 200px;
                  & > * {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    margin: 0;
                  }
                }
                .down_btn {
                  background: #f4f7ff;
                  position: absolute;
                  right: 12px;
                  bottom: 12px;
                  box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
                    0px 10px 10px rgba(46, 45, 193, 0.06);
                  &::v-deep {
                    .v-icon {
                      width: 20px;
                      height: 20px;
                      color: #2536cc !important;
                    }
                  }
                }
              }
            }
          }
        }
        .contact_list {
          margin-bottom: 16px;
          background: #fafafc;
          border-radius: 5px;
          border: 0.5px solid #cfcfdc;
          .email_status {
            text-align: center;
            .email_status_icon {
              cursor: pointer;
              padding: 0 4px;
              color: #cfcfdc;
              &::v-deep {
                .v-icon {
                  color: #cfcfdc;
                }
              }
              &.danger {
                &::v-deep {
                  .v-icon {
                    color: #ff5267;
                  }
                }
              }
            }
          }
          .chips {
            background: #eeeef7;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #707080;
            padding: 4px 8px;
            border-radius: 24px;
            display: inline-block;
            &.chips_success {
              background: #f0ffd6;
              color: #18a300;
            }
            &.chips_danger {
              background: #fef9fa;
              color: #ff5267;
            }
          }
          .relaunch_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            background: #f4f7ff;
            border-radius: 5px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #2536cc;
            & > .icon {
              &::v-deep {
                .v-icon {
                  width: 16px;
                  height: 16px;
                  color: #2536cc;
                }
              }
            }
            & > * {
              margin: 0 4px;
            }
          }
        }
      }
    }
  }
}
</style>
