<template>
  <div class="stepper_items_container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseStepperItems",
  computed: {
    consultedStep: {
      set: function (value) {
        if (this.$parent) {
          this.$parent.consultedStep = value;
        }
      },
      get: function () {
        return this.$parent ? this.$parent.consultedStep : 0;
      },
    },
    loading() {
      return this.$parent?.loading;
    },
  },
};
</script>
<style scoped>
.stepper_items_container {
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border: 0.5px solid #ecebf1;
  border-radius: 5px;
}
</style>
