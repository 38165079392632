<template>
  <div
    class="editor_constainer"
    @click.stop="showDialog = false"
    @focusin="isFocused = true"
    @focusout="isFocused = false"
  >
    <div v-if="label" class="label" @click.stop="" v-text="label" />
    <vue-editor
      id="editor_content"
      ref="editor-content"
      v-model="valueModel"
      :editor-options="editorSettings"
      :placeholder="placeholder"
      :class="{
        focused: isFocused,
        error: errorMessages && errorMessages.length,
      }"
    />
    <div
      v-if="errorMessages && errorMessages.length"
      class="alert_danger"
      v-text="errorMessages[0]"
    />
  </div>
</template>

<script>
export default {
  name: "BaseMarkdownEditor",
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: null,
    },
    withLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialog: false,
      isFocused: false,
    };
  },

  computed: {
    editorSettings() {
      if (this.withLink) {
        return {
          modules: {
            toolbar: {
              container: [
                [{ size: ["small", false, "large"] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ color: [] }, { background: [] }],
              ],
            },
          },
        };
      }
      return {
        modules: {
          toolbar: {
            container: [
              [{ size: ["small", false, "large"] }],
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
      };
    },
    valueModel: {
      set: function (value) {
        // prettier-ignore
        let newVal = value.replaceAll("class=\"ql-size-large\"","style='font-size:28px'");
        // prettier-ignore
        newVal = newVal.replaceAll("class=\"ql-size-small\"","style='font-size:10px'");
        this.$emit("updateValue", newVal);
      },
      get: function () {
        return this.value?.replace(/(?:\r\n|\r|\n)/g, "<br>");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .focused {
  outline: 1px solid #2536cc;
  box-shadow: 0px 0px 12px 4px #ebf2ff;
  border-radius: 5px;
  overflow: hidden;

  .ql-toolbar {
    border-bottom: 1px solid #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff;
  }
}

::v-deep .error {
  border: 1px solid #ff5267;
  background-color: #fff !important;
  box-shadow: 0px 0px 8px 2px #ffc6c6;
  .ql-container.ql-snow {
    border: 1px solid #ff5267;
  }
  .ql-editor.ql-blank {
    &:before {
      color: #ff5267 !important;
    }
  }
}
.editor_constainer {
  border-radius: 5px;
  .label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #707080;
    margin-bottom: 4px;
  }
  .alert_danger {
    font-size: 12px;
    line-height: 14px;
    color: red;
    margin: 4px 0;
  }
}
.emoji {
  position: relative;
  .emoji-picker {
    position: absolute;
    top: 40px;
    left: -100px;
    width: 250px;
    height: 250px;
    z-index: 100;
  }
}
</style>
