<template>
  <div class="table_editor_container">
    <div v-if="label" class="label">{{ label }}</div>
    <table
      class="table_container"
      :class="[
        variant,
        {
          error: error,
          readonly: $props.readonly,
          focus: focused,
          horizontal: horizontal,
        },
        horizontal ? 'horizontal' : 'vertical',
      ]"
    >
      <template v-if="!horizontal">
        <thead>
          <tr class="header">
            <th v-for="(header, i) in headers" :key="`h${i}`">
              <div class="header_item">
                <span>{{ header.label }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, ri) in rows" :key="`r${ri}`">
            <td v-for="(header, ci) in headers" :key="`fi${ci}`">
              <div class="col_item">
                <input
                  v-if="!readonly"
                  v-model="row[header.field]"
                  type="text"
                  :class="variant"
                  @input="modelValueChange()"
                  @focus="focused = true"
                  @blur="focused = false"
                  @keyup.enter="addRow({ ri, ci })"
                />
                <div v-else class="col_item_value" v-text="row[header.field]" />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else>
        <tr v-for="(header, i) in headers" :key="`h${i}`" class="header">
          <th>
            <div class="header_item">
              <span>{{ header.label }}</span>
            </div>
          </th>
          <td v-for="(row, ri) in rows" :key="`r${ri}`">
            <div class="col_item">
              <input
                v-if="!readonly"
                v-model="row[header.field]"
                type="text"
                :class="variant"
                @input="modelValueChange()"
                @focus="focused = true"
                @blur="focused = false"
              />
              <div v-else class="col_item_value" v-text="row[header.field]" />
            </div>
          </td>
        </tr>
      </template>
    </table>
    <div v-if="error && errorMessage" class="error_message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTableEditor",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    modelValue: {
      type: [Array],
      default: null,
    },
    headers: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    horizontal: {
      type: Boolean,
    },
  },
  data() {
    return {
      rows: [],
      focused: false,
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.updateRows();
      },
      // deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.updateRows();
  },
  methods: {
    updateRows() {
      this.rows = this.modelValue ? this.modelValue : [];
      if (!this.rows.length) this.addRow();
    },
    addRow(coords) {
      if (
        coords &&
        (coords.ri !== this.rows?.length - 1 ||
          coords.ci !== this.headers.length - 1)
      )
        return;
      const tmp = {};
      this.headers.forEach((header) => (tmp[header.field] = ""));
      this.rows.push(tmp);
      this.modelValueChange();
    },
    modelValueChange() {
      this.$emit("changeValue", this.rows);
    },
  },
};
</script>

<style scoped lang="scss">
.table_editor_container {
  .label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4f4f4f;
    margin-bottom: 8px;
  }
  .table_container {
    min-width: 100%;
    outline: 3px solid transparent;
    background: #ffffff !important;
    border: 1px solid #cacaca;
    transition: all 0.25s ease-in-out;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 20px;

    /* Apply a border to the right of all but the last column */
    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid black;
    }

    /* Apply a border to the bottom of all but the last row */
    & > thead > tr > th,
    & > tbody > tr:not(:last-child) > th,
    & > tbody > tr:not(:last-child) > td,
    & > tfoot > tr:not(:last-child) > th,
    & > tfoot > tr:not(:last-child) > td,
    & > tr:not(:last-child) > td,
    & > tr:not(:last-child) > th {
      border-bottom: 1px solid black;
    }

    &.vertical {
      thead {
        tr:first-child {
          th:first-child {
            border-top-left-radius: 20px;
            * {
              border-top-left-radius: 20px;
            }
          }
          th:last-child {
            border-top-right-radius: 20px;
            * {
              border-top-right-radius: 20px;
            }
          }
        }
      }
      tbody {
        tr:last-child {
          td:first-child {
            border-bottom-left-radius: 20px;
            * {
              border-bottom-left-radius: 20px;
            }
          }
          td:last-child {
            border-bottom-right-radius: 20px;
            * {
              border-bottom-right-radius: 20px;
            }
          }
        }
      }
    }

    &.horizontal {
      tr:first-child {
        th:first-child {
          border-top-left-radius: 20px;
          * {
            border-top-left-radius: 20px;
          }
        }
        td:last-child {
          border-top-right-radius: 20px;
          * {
            border-top-right-radius: 20px;
          }
        }
      }
      tr:last-child {
        th:first-child {
          border-bottom-left-radius: 20px;
          * {
            border-bottom-left-radius: 20px;
          }
        }
        td:last-child {
          border-bottom-right-radius: 20px;
          * {
            border-bottom-right-radius: 20px;
          }
        }
      }
    }

    &.primary {
      color: #a287dd;
      border-color: #cacaca !important;
      tr,
      th,
      td {
        border-color: #cacaca !important;
      }
      &.focus {
        outline: 3px solid #e5daff;
      }
    }
    &.secondary {
      color: #a287dd;
      border-color: #83d1f8 !important;
      tr,
      th,
      td {
        border-color: #83d1f8 !important;
      }
    }
    &.error {
      background: #fff;
      color: #fe7878;
      border: 1px solid #fe7878;
      transition: all 0.25s ease-in-out;
      &:focus {
        outline: 3px solid rgba(#fe7878, 0.2);
      }
    }
    .header_item {
      padding: 16px;
      color: #4f4f4f;
      text-align: center;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      line-height: 18px;
      padding: 16px;
      min-width: 125px;
      height: auto;
    }
    .col_item {
      min-width: 125px;
      input {
        width: 100%;
        border: none;
        height: 100%;
        padding: 16px;
        color: #4f4f4f;
        text-align: center;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: 16px;
        background: #fff !important;
        &.primary {
          &:focus {
            outline: 3px solid #e5daff !important;
          }
        }
        &.secondary {
          &:focus {
            outline: 3px solid #d3eefb !important;
          }
        }
      }
      .col_item_value {
        padding: 16px;
        color: #4f4f4f;
        text-align: center;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: 16px;
        min-width: 125px;
        height: 100%;
      }
    }
  }
  .error_message {
    margin-top: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fe7878;
  }
}
</style>
