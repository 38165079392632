<template>
  <div class="impersona">
    <v-progress-circular indeterminate size="90" color="#2536cc" />
  </div>
</template>

<script>
import { setBearerToken } from "@/core/Api/cosmoAxiosInstance";
export default {
  name: "WalletImpersona",
  created() {
    this.initilialize();
  },
  methods: {
    async initilialize() {
      try {
        const payload = {
          user: this.$route.query.user,
          client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        };

        const oauthAdmin = localStorage.getItem("oauthAdmin")
          ? JSON.parse(localStorage.getItem("oauthAdmin"))
          : null;
        if (oauthAdmin?.accessToken) {
          setBearerToken(oauthAdmin.accessToken);
        } else {
          localStorage.setItem("oauthAdmin", localStorage.getItem("oauth"));
        }
        const response = await this.$store.dispatch(
          "walletCourse/postImpersona",
          payload
        );
        const newToken = JSON.stringify(response.data.token);
        if (!oauthAdmin) {
          localStorage.setItem("oauthAdmin", localStorage.getItem("oauth"));
        }
        localStorage.setItem("oauth", newToken);
        setBearerToken(response.data.token.accessToken);
        this.$store.dispatch("snackbar/active", {
          message: `Vous êtes maintenant connecté en tant que ${response.data.user.email}`,
          type: "SUCCESS",
          timeOut: 3000,
        });
      } catch (error) {
        let msg = this.$t("utils.errorHasHappen");
        if (error?.response?.data?.code == "only-for-superuser") {
          msg = "Vous n'êtes pas autorisé à cette fonctionnalité";
        }
        this.$store.dispatch("snackbar/active", {
          message: msg,
          type: "ERROR",
        });
      }
      this.$router.push({ name: "WalletAccount" });
    },
  },
};
</script>

<style lang="scss" scoped>
.impersona {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
