let plugins = [];

// Import Stores
import CoreStore from "./core";
import OrganizationStore from "./organization";
import DatatypeStore from "./datatype";
import SnackbarStore from "./snackbar";

let stores = {
  core: CoreStore,
  organization: OrganizationStore,
  datatype: DatatypeStore,
  snackbar: SnackbarStore,
};

export { plugins, stores };
