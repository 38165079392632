import Organization from "./Organization";
import User from "./User";

export default class Collaborator {
  constructor({
    id = Number.NaN,
    status = null,
    organization = null,
    user = null,
  } = {}) {
    this.id = Number.parseInt(id);
    this.status = status;
    this.organization = organization
      ? new Organization({ id: organization })
      : new Organization();
    this.user = user ? new User(user) : null;
  }
}
