<template>
  <div class="ws_container">
    <template v-if="!loading">
      <div class="d-flex justify-center mt-3 mb-6">
        <BaseImage
          v-if="record.campaign.course.image"
          :src="record.campaign.course.image"
          contain
          max-height="200px"
          max-width="80%"
        />
        <BaseImage
          v-else
          :src="$findStatic('defaultAvatar')"
          max-height="85px"
          max-width="85px"
        />
      </div>
      <BaseFrontOfficeDivider class="my-6" type="secondary" />
      <div class="step_container">
        <div
          v-for="(step, i) in recordSteps"
          :key="`ws-${i}`"
          class="step_item"
          :class="{
            complet_spet: step.isComplet,
            active_step: step.isActive,
          }"
        >
          <div class="section_left">
            <div class="step_value" />
            <div class="step_separator" />
          </div>
          <div class="step_label" v-text="step.name" />
        </div>
      </div>
    </template>
    <BaseSkeletonLoader v-else type="card, list-item@6" />
  </div>
</template>

<script>
export default {
  name: "WalletSidebar",
  props: {
    record: {
      type: Object,
      default: null,
    },
    activeStepId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    loading() {
      return !this.record ? true : false;
    },
    recordSteps() {
      if (!this.record) return [];
      return this.record.steps.map((step) => {
        if (step.questions.every((q) => q.answer)) {
          step.isComplet = true;
        } else if (step.id == this.activeStepId) {
          step.isActive = true;
        }
        return step;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ws_container {
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  font-family: "Nunito";
  font-weight: 500;
}
.step_container {
  padding: 0 24px;
  height: calc(100% - 260px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #eeeef7;
  }

  &::-webkit-scrollbar-thumb {
    background: #3c3b8e;
    border-radius: 3px;
  }

  .step_item {
    display: flex;
    align-items: flex-start;
    .section_left {
      .step_value {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #fff;
        border: 4px solid #3c3b8e;
        transition: all 0.25s ease-in-out;
      }
      .step_separator {
        width: 4px;
        height: 40px;
        background: #3c3b8e;
        margin: auto;
      }
    }
    .step_label {
      font-family: "Nunito";
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #a3a3ad;
      padding: 0 12px;
      transition: all 0.25s ease-in-out;
    }
    &:last-child {
      .step_separator {
        display: none;
      }
    }
    &.active_step {
      .step_value {
        background: #3c3b8e;
      }
      .step_label {
        color: #3c3b8e;
      }
    }
    &.complet_spet {
      .step_value {
        background: #3c3b8e;
      }
      .step_label {
        color: #3c3b8e;
      }
    }
  }
}
</style>
