<template>
  <BaseModal
    :value="dialogState"
    modal-title="Modifier la catégorie"
    @close="close"
  >
    <template #modal>
      <form class="modal_content" @submit="submit()">
        <base-text-field
          v-model="form.name"
          autofocus
          :label="`${$tc('utils.name', 1)} *`"
          :error-messages="nameErrors"
          :has-error="!!nameErrors"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
      </form>
    </template>

    <template #actions>
      <BaseButton
        class="mx-2 ml-auto"
        color="primary"
        type="primary"
        @click="close"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        type="secondary"
        text
        color="#707080"
        :loading="isSubmiting"
        @click="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "WorkflowEditStatusCategory",
  mixins: [validationMixin],
  data() {
    return {
      isSubmiting: false,
      form: {
        name: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(35),
      },
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.workflow.modals.isEditQuestionCategoryOpen,
      activeCategory: (state) => state.workflow.activeStatusCategory,
    }),
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("category.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("category.nameTooLong");
      }
      return "";
    },
  },
  watch: {
    async dialogState(val) {
      if (!val) {
        this.reset();
      } else {
        await this.initialize();
      }
    },
  },
  methods: {
    initialize() {
      if (this.activeCategory) {
        this.form.name = this.activeCategory.name;
      }
    },
    reset() {
      this.form.name = "";
      this.close();
    },
    close() {
      this.$store.commit(
        "workflow/SET_EDIT_QUESTION_CATEGORY_MODAL_STATUS",
        false
      );
      this.$store.commit("workflow/SET_ACTIVE_STATUS_CATEGORY", null);
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmiting = true;
      try {
        await WorkflowAPI.patchWorkflowStatusCategory({
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeCategory.statusId,
          categoryId: this.activeCategory.id,
          payload: {
            name: this.form.name,
          },
        });
        await this.$store.dispatch("workflow/fetchStatusCategories", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeCategory.statusId,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
    },
  },
};
</script>
