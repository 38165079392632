<template>
  <div class="records-container d-flex flex-column justify-center">
    <div v-if="isFetchingFolderRecords">
      <v-skeleton-loader
        v-for="number in 2"
        :key="number"
        type="list-item-avatar, article"
        class="my-3"
      />
    </div>
    <div v-else>
      <WalletAssistantRecord
        v-for="(record, index) in folderRecords"
        :key="`${record.id}-${index}`"
        :record="record"
        :organization-avatar="activeOrganization.avatar"
        class="mb-6"
      />
      <WalletAnswerCosmoForm :cosmo-form-detail="cosmoFormDetail" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WalletAnswerCosmoForm from "./WalletAnswerCosmoForm.vue";
import WalletAssistantRecord from "./WalletAssistantRecord.vue";
export default {
  name: "WalletFolderRecords",
  components: { WalletAssistantRecord, WalletAnswerCosmoForm },
  computed: {
    ...mapState({
      folderRecords: (state) => state.walletCourse.folderRecords,
      isFetchingFolderRecords: (state) =>
        state.walletCourse.isFetchingFolderRecords,
      cosmoFormDetail: (state) => state.cosmoform.cosmoFormDetail,
      activeWalletFolder: (state) => state.walletCourse.activeWalletFolder,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await Promise.all([
          this.$store.dispatch("walletCourse/getWalletRecords", {
            folderId: this.$route.params.folderID,
          }),
          this.$store.dispatch("cosmoform/fetchCosmoFormFolderDetail", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderID,
          }),
        ]);
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>
<style scoped>
.records-container {
  background-color: var(--v-walletPrimary-lighten5);
  padding: 25px 15px 0px 15px;
  border-radius: 5px;
}
</style>
