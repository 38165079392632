<template>
  <div>
    <BaseButton
      :to="{ name: 'account' }"
      type="secondary"
      icon="$mdiChevronLeft"
      class="mb-6"
    >
      {{ $t("utils.back") }}
    </BaseButton>
    <Board>
      <template #main>
        <BaseTitle class="mb-4" v-text="$t('client.account.deleteTitle')" />
        <BaseDivider grey />
        <BaseParagraph
          class="d-inline-block my-6"
          type="primary"
          v-text="$t('client.account.deleteLabel')"
        />
        <v-form ref="deleteForm">
          <v-text-field
            v-model="$v.password.$model"
            autofocus
            :label="$t('account.deleteForm.passwordLabel')"
            :error-messages="passwordErrors"
            type="password"
            @change="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <v-checkbox
            v-model="$v.valid.$model"
            :label="$t('client.account.deleteForm.deleteMyAccount')"
            @change="$v.valid.$touch()"
            @blur="$v.valid.$touch()"
          />
        </v-form>
        <div class="d-flex justify-end">
          <BaseButton
            type="secondary"
            color="secondary"
            :disabled="$v.$invalid"
            @click="submit"
          >
            {{ $t("client.account.deleteValidation") }}
          </BaseButton>
        </div>
      </template>
    </Board>
    <BaseDialog
      :value="dialogState"
      max-width="360"
      persistant
      @click:outside="toggleDialog(false)"
    >
      <template #modal>
        <BaseCosmoCard>
          <template #title>
            <BaseTitle
              class="mb-4"
              v-text="$t('client.account.deleteValidation')"
            />
          </template>
          <template>
            <BaseParagraph
              class="d-inline-block mt-3 mb-2"
              type="primary"
              v-text="$t('client.account.deleteModalValidation')"
            />
          </template>
          <template #actions class="d-flex justify-end">
            <v-spacer />
            <BaseButton type="secondary" @click="toggleDialog(false)">
              {{ $t("utils.cancel") }}
            </BaseButton>
            <BaseButton
              type="secondary"
              color="secondary"
              @click="deleteAccount"
            >
              Supprimer
            </BaseButton>
          </template>
        </BaseCosmoCard>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";

import Board from "@/core/Components/Board";

export default {
  name: "AccountDelete",
  components: {
    Board,
  },
  mixins: [validationMixin],
  data() {
    return {
      password: null,
      valid: false,
      validationState: true,
      dialogState: false,
    };
  },
  validations: {
    password: {
      required,
    },
    valid: {
      required,
      sameAs: sameAs("validationState"),
    },
  },
  computed: {
    passwordErrors() {
      if (!this.$v.password.$dirty) {
        return [];
      }
      let errors = [];
      if (!this.$v.password.required) {
        errors.push(this.$t("client.account.deleteForm.passwordRequired"));
      }
      return errors;
    },
  },
  methods: {
    toggleDialog(state) {
      this.dialogState = state;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.toggleDialog(true);
      }
    },
    resetForm() {
      this.$v.password.$reset();
      this.$v.valid.$reset();
    },
    async deleteAccount() {
      try {
        await this.$store.dispatch("account/deleteUser", this.password);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.deleteForm.accountDeletetion"),
          type: "SUCCESS",
        });
        this.$router.push({ name: "account" });
        this.$refs.deleteForm.reset();
        this.resetForm();
        this.toggleDialog(false);
      } catch (error) {
        if (
          error.response.data.password &&
          error.response.data.password[0] === "Mot de passe invalide"
        ) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("client.account.wrongPassword"),
            type: "ERROR",
          });
          this.$refs.deleteForm.reset();
          this.resetForm();
          this.toggleDialog(false);
        }
      }
    },
  },
};
</script>
