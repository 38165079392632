<template>
  <div>
    <div v-if="walletFolders.length" class="folder_records_container">
      <div
        v-for="(folder, index) in walletFolders"
        :key="`${index}-${folder.id}`"
        v-ripple
        class="record_item"
        @click="redirectToFolder(folder)"
      >
        <div class="record_item_header">
          <div class="header_title">
            {{ folder.name }}
          </div>
        </div>
        <div class="record_item_content">
          <div
            v-text="
              `${$t('folder.createDateAt')} ${$d(
                folder.dateCreated,
                'veryshort'
              )}`
            "
          />
          <div
            v-text="
              $t('folder.lastEdition', {
                date: $d(folder.dateUpdate, 'veryshort'),
              })
            "
          />
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-space-between mobile-skeleton">
      <div v-for="i in 3" :key="i" class="skeleton-charger mx-1 mb-2">
        <v-skeleton-loader
          min-width="350"
          type="list-item-avatar, divider,  card-heading, image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      walletFolders: (state) => state.walletCourse.walletFolders,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
  },
  watch: {
    $route: {
      handler() {
        if (!this.$route.params.folderID) return;
        return this.openFolderModal();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    redirectToFolder(folder) {
      this.$router.push({
        name: "WalletOrganizationRecordsFolder",
        params: { folderID: folder.id },
      });
    },
    openFolderModal() {
      const actualFolder = this.walletFolders?.find(
        (folder) => folder.id === +this.$route.params.folderID
      );
      this.$store.commit("walletCourse/setIsFolderDialogOpen", true);
      this.$store.commit("walletCourse/setActiveWalletFolder", actualFolder);
    },
  },
};
</script>

<style lang="scss" scoped>
.folder_records_container {
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, calc(50%));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, calc(100% / 3));
  }
  .record_item {
    margin: 16px;
    width: calc(100% - 32px);
    border-radius: 10px;
    border: 1px solid #ebebeb;
    overflow: hidden;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
      box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .record_item_header {
      display: flex;
      align-items: center;
      padding: 24px;
      background: linear-gradient(
        147.45deg,
        #2f479d 19.48%,
        rgba(47, 71, 157, 0) 144.95%
      );
      .header_title {
        color: #fff;
        font-family: "Nunito";
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .record_item_content {
      padding: 24px;
      font-family: "Nunito";
      & > * {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        color: #707080;
      }
    }
  }
}
.skeleton-charger {
  border: 1px solid #ebebeb;
  filter: drop-shadow(0px 2px 4px rgba(46, 45, 193, 0.1))
    drop-shadow(0px 6px 10px rgba(46, 45, 193, 0.06));
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}
@media all and (max-width: 600px) {
  .mobile-skeleton {
    flex-direction: column;
  }
}
</style>
