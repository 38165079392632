var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{staticClass:"pa-6",attrs:{"is-rounded":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"logo-container"},[_c('BaseImage',{attrs:{"src":_vm.$findStatic('cosmoLogo')}})],1),_c('div',{staticClass:"d-flex flex-column ml-auto",attrs:{"id":"button-container"}},[_c('BaseButton',{staticClass:"mb-2",attrs:{"type":"secondary","icon":"$mdiDownload"},on:{"click":function($event){return _vm.$emit('download-data')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("utils.downloadData")))])]),_c('BaseButton',{attrs:{"type":"secondary","icon":"$mdiDownload"},on:{"click":function($event){return _vm.$emit('download-zip')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("utils.downloadFiles")))])])],1)]),(_vm.client && _vm.folder)?_c('div',{staticClass:"d-flex flex-column"},[_c('BaseTitle',{staticClass:"my-2",domProps:{"textContent":_vm._s('Récapitulatif')}}),_c('BaseParagraph',{domProps:{"textContent":_vm._s(
        _vm.$t('folder.clientFullData.FolderName', {
          folderName: _vm.folder.name,
        })
      )}}),_c('BaseParagraph',{domProps:{"textContent":_vm._s(
        _vm.$t('folder.clientFullData.contact', {
          contactName: _vm.getFullName(_vm.client),
          contactEmail: _vm.client.email,
        })
      )}}),_c('BaseParagraph',{domProps:{"textContent":_vm._s(
        _vm.$t('folder.clientFullData.createdAt', {
          date: _vm.$d(new Date(_vm.folder.dateCreated), 'detailed'),
        })
      )}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }