<template>
  <v-list-item-content v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-list-item-content>
</template>

<script>
export default {
  name: "BaseListItemContent",
};
</script>
