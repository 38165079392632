<template>
  <span class="text-paragraph" :class="classObject">
    <slot />
  </span>
</template>

<script>
export default {
  name: "BaseFrontOfficeParagraph",
  props: {
    bold: {
      type: Boolean,
    },
    semiBold: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: () => "walletPrimary",
      validator: function (value) {
        return (
          ["walletPrimary", "walletAccent", "disabled"].indexOf(value) !== -1
        );
      },
    },
    uppercase: {
      type: Boolean,
      default: () => false,
    },
    italic: {
      type: Boolean,
      default: () => false,
    },
    truncate: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    classObject() {
      return {
        "text-uppercase": this.uppercase,
        [`${this.color ? this.color + "--text" : "text--" + this.text}`]: true,
        "font-italic": this.italic,
        "text-paragraph--bold": this.bold,
        "text-truncate d-block": this.truncate,
        "text-paragraph--semi--bold": this.semiBold,
      };
    },
  },
};
</script>

<style scoped>
.text-paragraph {
  color: var(--v-walletGrey-base);
  font-size: 16px !important;
  line-height: 22px;
  text-transform: initial;
  font-weight: 400;
  font-family: var(--cosmo-font-nunito);
}
span::first-letter {
  text-transform: uppercase;
}
.text-paragraph--bold {
  font-weight: 700;
}
.text-paragraph--semi--bold {
  font-weight: 600;
  font-family: var(--cosmo-font-nunito-secondary) !important;
  font-style: normal;
}
</style>
