<template>
  <div class="file-drag-container" @click.stop="$refs['file-drag'].click()">
    <div
      v-if="offerFiles && offerFiles.length && nature === 'secondary'"
      class="files-items"
    >
      <folder-offer-file
        v-for="(file, index) in offerFiles"
        :key="index"
        :file="file"
        :index-file="index"
        :is-disabled="isDisabled"
        :is-wallet="isWallet"
        :non-editable="isCourse"
        :is-cosmo-form="isCosmoForm"
        :need-iteration="needIteration"
        :question="question"
        :initial-files="initialFiles"
        :is-not-offer="isNotOffer"
        nature="secondary"
        v-on="$listeners"
      />
    </div>
    <div v-if="!isDisabled" class="file-drag">
      <div
        class="file-drag-repo"
        :class="{
          dragged: isDragged,
          'error-file': hasError,
          'wallet-form': isWallet,
        }"
        @mouseenter="isDragged = true"
        @mouseleave="isDragged = false"
        @drop.prevent="onDrop"
        @dragover.prevent="isDragged = true"
        @dragenter.prevent="isDragged = true"
        @dragleave.prevent="isDragged = false"
      >
        <div class="img-container">
          <BaseImage
            :src="$findStatic(isWallet ? 'groupViewWallet' : 'groupView')"
          />
        </div>

        <input
          v-show="false"
          ref="file-drag"
          type="file"
          :value="inputValue"
          multiple
          :accept="acceptedFile"
          @change="insert"
        />

        <div v-if="isWallet" class="title">
          <span class="label-wallet" v-text="$tc('dragDocuments', 0)" />
          <span
            class="label-input"
            v-text="` ${$t('or')} ${$t('clickHereToBrowse')}`"
          />
        </div>

        <div v-else class="d-flex flex-column">
          <span class="label" v-text="$tc('dragDocuments', 2)" />
          <base-button :color="hasError ? 'error' : 'primary'">
            {{ $t("browseFiles") }}
          </base-button>
        </div>
      </div>
      <div
        v-if="offerFiles && offerFiles.length && nature === 'primary'"
        class="files-container"
      >
        <folder-offer-file
          v-for="(file, index) in offerFiles"
          :key="index"
          :file="file"
          :index-file="index"
          :is-disabled="isDisabled"
          :is-wallet="isWallet"
          :is-cosmo-form="isCosmoForm"
          :need-iteration="needIteration"
          :question="question"
          :initial-files="initialFiles"
          :is-not-offer="isNotOffer"
          v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FolderOfferFile from "./FolderOfferFile.vue";
export default {
  name: "FolderOfferFileDrag",
  components: { FolderOfferFile },
  props: {
    offerFiles: {
      type: Array,
      default: () => [],
    },
    question: {
      type: Object,
      default: () => {},
    },
    nature: {
      type: String,
      default: "primary",
    },
    needIteration: {
      type: Boolean,
      default: true,
    },
    isNotOffer: {
      type: Boolean,
    },
    hasError: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
    },
    isWallet: {
      type: Boolean,
    },
    isCosmoForm: {
      type: Boolean,
    },
    isCourse: {
      type: Boolean,
    },
    initialFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDragged: false,
      inputValue: null,
    };
  },
  computed: {
    acceptedFile() {
      if (this.isSignature) {
        return ".xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf";
      }
      return "";
    },
  },
  methods: {
    onDrop(event) {
      this.isDragged = false;
      this.$emit("file-droped", {
        value: [...event.dataTransfer.files],
      });
    },
    insert(event) {
      this.$emit("file-droped", { value: event.target.files, input: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-drag-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .file-drag {
    height: 100%;
    width: 100%;
    min-width: 285px;
    display: flex;
    gap: 24px;
    .file-drag-repo {
      min-height: 152px;
      border-radius: 5px;
      padding: 16px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px dashed var(--principale-moyenne, #9bbdff);
      background: rgba(247, 250, 255, 0.3);
      .img-container {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e9f1ff;
        border: 14px solid #f4f7ff;
      }
      &.error-file {
        border: 1px dashed #ff5267;
        box-shadow: 1px -1px 5px 5px #ffc6c6 !important;
      }
    }
    .dragged {
      background: rgba(155, 189, 255, 0.18);
      cursor: pointer;
    }

    .files-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 16px;
      width: calc(50% - 12px);
    }
  }
}
.image-src {
  width: 80% !important;
}
.label {
  color: #353542;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0;
}
.files-items {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
}
.wallet-form {
  min-width: 400px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px dashed #3c3b8e !important;
  margin-bottom: 24px;
}
@media (max-width: 500px) {
  .wallet-form {
    min-width: 150px;
  }
}
.label-input {
  color: #3c3b8e;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title {
  max-width: 304px;
  text-align: center;
}
.label-wallet {
  color: var(--principale-gris-noir, #353542);
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
