<template>
  <v-dialog
    :max-width="isComplex ? maxWidth : 800"
    persistant
    v-bind="$attrs"
    v-on="$listeners"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <div class="d-flex flex-column modal-container">
        <div class="modal-header">
          <BaseTitle v-text="modalTitle" />
          <BaseIcon
            icon="$mdiClose"
            color="#CFCFDC"
            class="icon-modal"
            :disabled="disabled"
            @click.prevent="$emit('close')"
          />
        </div>

        <div class="modal-content">
          <slot name="modal" />
        </div>
        <div
          v-if="needAction"
          class="modal-actions"
          :class="{ unique: isUnique }"
        >
          <slot name="actions" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    isOpen: {
      type: Boolean,
    },
    isUnique: {
      type: Boolean,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    isComplex: {
      type: Boolean,
    },
    maxWidth: {
      type: Number,
      default: 850,
    },
    needAction: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.modal-container {
  padding: 24px;
}
.modal-content {
  margin-top: 24px;
  margin-bottom: 20px;
}
.modal-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  height: 45px;
  border-bottom: 1px solid #eeeef7;
}
.modal-actions {
  display: flex;
  align-content: center;
  min-height: 45px;
  margin-bottom: -10px;
}
.unique {
  margin-top: -24px;
}
</style>
