import Answer from "./Answer";
import Option from "./Option";
import { QuestionCategory } from "./QuestionCategory";
export default class Question {
  constructor({
    id = Number.NaN,
    name = "",
    slug = "",
    label = "",
    answer = null,
    suggestion = null,
    choices = [],
    datatype = null,
    helpText = "",
    helpFile = null,
    image = "",
    logicjumps = [],
    order = Number.NaN,
    required = false,
    readOnly = false,
    typeQuestion = "",
    group = null,
    groupName = null,
    step = Number.NaN,
    visibleClient = false,
    category = null,
    validation = "PENDING",
    hasFreeOption = false,
    answersCount = 0,
    conditions = "",
    errorMessage = "",
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.slug = slug;
    this.label = label;
    this.answer = answer ? new Answer(answer) : null;
    this.suggestion = suggestion ? suggestion : null;
    this.choices = choices ? choices.map((choice) => new Option(choice)) : [];
    this.datatype = datatype;
    this.datatypeId = Number.isSafeInteger(datatype)
      ? Number.parseInt(datatype)
      : datatype?.id;
    this.helpText = helpText;
    this.helpFile = helpFile;
    this.image = image;
    this.logicjumps = logicjumps;
    this.order = Number.parseInt(order);
    this.required = required;
    this.readOnly = readOnly;
    this.typeQuestion = typeQuestion;
    this.group = group;
    this.groupName = groupName;
    this.step = step ? Number.parseInt(step) : null;
    this.visibleClient = visibleClient;
    this.category =
      category && category.id ? new QuestionCategory(category) : null;
    this.validation = validation;
    this.hasFreeOption = hasFreeOption;
    this.answersCount = answersCount;
    this.conditions = conditions;
    this.errorMessage = errorMessage;
  }
}
