import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Record > Question - API
  getDatatypes({ page, typeQuestion }) {
    let params = [];
    if (page) params.push(`page=${page}`);
    if (typeQuestion) params.push(`type_question=${typeQuestion}`);
    return cosmoAPI.get(`/datatype/?${params.join("&")}`);
  },
};
