<template>
  <section>
    <BaseCard :outlined="true" class="pa-4">
      <CourseDetailStepHeader
        :is-editing-allowed="isEditingAllowed"
        :is-first-step="isFirstStep"
        :is-last-step="isLastStep"
        :step="step"
        v-on="$listeners"
      />

      <BaseButton
        v-if="isEditingAllowed"
        class="mb-4"
        type="primary"
        :disabled="questionsToGroup.length < 2"
        :small="true"
        :icon="'$mdiGroup'"
        @click="groupQuestions"
      >
        {{ $t("course.forms.step.group") }}
      </BaseButton>
      <BaseContainer
        v-if="orderedStepQuestions"
        fluid
        class="elevation-2 mb-2 px-1"
      >
        <div class="px-4">
          <CourseDetailStepTableHeader
            :is-editing-allowed="isEditingAllowed"
            class="pl-4"
          />
          <BaseDivider v-if="!orderedStepQuestions.length > 0" grey />
        </div>

        <div v-if="orderedStepQuestions.length > 0">
          <!-- drag drop -->
          <draggable
            v-model="orderedStepQuestions"
            :move="setDragItem"
            class="drag__drop__system"
            :options="{ disabled: questionsToGroup.length }"
            @end="changeOrder"
          >
            <div
              v-for="(elem, index) in orderedStepQuestions"
              :key="`${index}-${elem.id}`"
              class="px-4"
            >
              <!-- Check if the elem is a question (Only questions have an order property) -->
              <BaseSheet
                v-if="elem.order != null"
                elevation="2"
                style="height: 60px"
                @click.stop="$emit('edit-question', elem)"
              >
                <CourseDetailStepQuestionRow
                  class="pl-4 mb-1 mt-1"
                  :is-editing-allowed="isEditingAllowed"
                  :step-id="step.id"
                  :questions-to-group="questionsToGroup"
                  :question="elem"
                  :ordered-step-questions="orderedStepQuestions"
                  :is-logic-jumb-disabled="
                    orderedStepQuestions.length - 1 === +index && isLastStep
                  "
                  v-on="$listeners"
                  @toggle-group-question="toggleQuestionToGroup"
                />
              </BaseSheet>
              <!-- Check if the elem is a group -->
              <div v-else class="my-2">
                <BaseExpensionPanels :value="elem.isOpen">
                  <BaseExpensionPanel inset>
                    <template slot="header">
                      <div
                        class="d-flex justify-space-between align-center pr-4"
                      >
                        <div>
                          <BaseParagraph type="primary" v-text="elem.label" />
                        </div>
                        <div v-if="isEditingAllowed">
                          <BaseButton
                            color="primary"
                            :small="true"
                            :icon="'$mdiEdit'"
                            class="mr-2"
                            @click.stop="$emit('edit-question-group', elem)"
                          >
                            {{ $t("course.forms.groupEdit.title") }}
                          </BaseButton>
                          <BaseButton
                            color="secondary"
                            :small="true"
                            :icon="'$mdiTrashCanOutline'"
                            @click.stop="$emit('delete-group', elem)"
                          >
                            {{ $t("course.forms.groupDelete.title") }}
                          </BaseButton>
                        </div>
                      </div>
                    </template>
                    <template slot="content">
                      <draggable
                        v-model="elem.questions"
                        :move="setDragItem"
                        class="drag__drop__system"
                        :options="{ disabled: questionsToGroup.length }"
                        @end="changeGroupOrder"
                      >
                        <div v-for="(question, i) in elem.questions" :key="i">
                          <BaseDivider grey />
                          <CourseDetailStepQuestionRow
                            class="pl-4"
                            :is-group-last-question="
                              i === elem.questions.length - 1
                            "
                            :is-editing-allowed="isEditingAllowed"
                            :step-id="step.id"
                            :questions-to-group="questionsToGroup"
                            :question="question"
                            :is-group="true"
                            v-on="$listeners"
                            @toggle-group-question="toggleQuestionToGroup"
                            @ungroup-question="ungroupQuestion"
                          />
                        </div>
                      </draggable>
                    </template>
                  </BaseExpensionPanel>
                </BaseExpensionPanels>
              </div>
            </div>
          </draggable>
        </div>
        <div v-else class="text-center d-block my-4">
          <BaseParagraph type="primary" v-text="$tc('course.question', 0)" />
        </div>
      </BaseContainer>

      <CourseDetailStepActions v-if="isEditingAllowed" v-on="$listeners" />
    </BaseCard>
  </section>
</template>

<script>
import CourseDetailStepTableHeader from "../Components/CourseDetailStepTableHeader";
import CourseDetailStepQuestionRow from "../Components/CourseDetailStepQuestionRow";
import CourseDetailStepActions from "../Components/CourseDetailStepActions";
import CourseDetailStepHeader from "../Components/CourseDetailStepHeader";
import draggable from "vuedraggable";
import { getQuestionGroups } from "../Services/course.service";
import { mapState } from "vuex";

export default {
  name: "CourseDetailStep",
  components: {
    CourseDetailStepTableHeader,
    CourseDetailStepQuestionRow,
    CourseDetailStepActions,
    CourseDetailStepHeader,
    draggable,
  },
  props: {
    courseIsAnonymous: {
      type: Boolean,
    },
    isEditingAllowed: {
      type: Boolean,
    },
    isFirstStep: {
      type: Boolean,
    },
    isLastStep: {
      type: Boolean,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      questionsToGroup: [],
      questionsGroupCheckboxValues: [],
      questionsGroupLabel: "",
      isFetchingGroups: false,
      groups: [],
      orderedStepQuestions: null,
      draggedElement: null,
      targetElement: null,
    };
  },
  computed: {
    ...mapState({
      campaigns: (state) => state.course.campaigns,
      questionsAvailableToGroup: (state) =>
        state.course.questionsAvailableToGroup,
    }),
  },
  watch: {
    step: {
      handler() {
        this.fetchStepGroups();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setOrderedQuestionGroup() {
      if (this.isFetchingGroups) {
        return null;
      }
      let result = [];
      const questionToUse = this.step.questions;
      result.push(...questionToUse?.filter((q) => q.group == null));
      result.push(...this.groups.filter((g) => g.questions.length > 0));
      if (result.length > 1) {
        result.sort((a, b) => {
          const orderA = a.order != null ? a.order : a.questions[0].order;
          const orderB = b.order != null ? b.order : b.questions[0].order;
          return orderA - orderB;
        });
      }

      result = result.map((r, index) => {
        let res = r;
        if (r.order == null) {
          res = {
            ...r,
            isOpen: 0,
            questions: r.questions?.map((q, index) => {
              return {
                ...q,
                isFirst: index === 0 ? true : false,
                isLast: index === r.questions.length - 1 ? true : false,
              };
            }),
          };
        }
        return {
          ...res,
          isFirst: index === 0 ? true : false,
          isLast: index === result.length - 1 ? true : false,
        };
      });
      return result;
    },
    async fetchStepGroups() {
      this.isFetchingGroups = true;
      const res = await getQuestionGroups(
        this.$route.params.organizationId,
        this.$route.params.courseId,
        this.step.id
      );
      this.groups = res.data.results.filter((g) => g.questions.length > 0);
      this.isFetchingGroups = false;
      this.orderedStepQuestions = this.setOrderedQuestionGroup();
    },
    resetValue() {
      this.targetElement = null;
      this.draggedElement = null;
    },
    setDragItem(event) {
      this.draggedElement = { ...event.draggedContext.element };
      this.targetElement = { ...event.relatedContext.element };
    },

    // function to drag and drop inside question Group
    async changeGroupOrder() {
      if (!this.draggedElement || !this.targetElement) return;
      const payload = {
        organizationId: this.$route.params.organizationId,
        courseId: this.$route.params.courseId,
        stepId: this.step.id,
        questionId: this.draggedElement.id,
        newPosition: this.targetElement.order, // switching order to the targetElement
      };
      return this.moveQuestionToHisNewPosition(payload);
    },

    // function to move question order one time

    async moveQuestionToHisNewPosition(payload) {
      try {
        await this.$store.dispatch("course/moveQuestionOrder", payload);
        this.resetValue();
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.forms.question.hasBeenMovedSuccessFully"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.errorChangingQuestionPosition"),
          type: "ERROR",
        });
      }
    },

    // Drag and drop system
    async changeOrder() {
      let payload = {};

      if (!this.draggedElement || !this.targetElement) return;

      // Simple question dragged
      if (this.draggedElement.order != null) {
        // if target element is also simple question
        if (this.targetElement.order != null) {
          payload = {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            stepId: this.step.id,
            questionId: this.draggedElement.id,
            newPosition: this.targetElement.order, // just the position in the index
          };
          return await this.moveQuestionToHisNewPosition(payload);
        }

        // if target element is a group question
        else {
          const isSimpleQuestionDraggedDown =
            this.draggedElement.order < this.targetElement.order;
          let groupQuestion = [
            ...this.orderedStepQuestions?.find(
              (question) =>
                question.id === this.targetElement.questions[0].group.id
            )?.questions,
          ];
          // find the group of questions and order it
          groupQuestion = groupQuestion.sort((a, b) => b.order - a.order);

          payload = {
            organizationId: this.$route.params.organizationId,
            courseId: this.$route.params.courseId,
            stepId: this.step.id,
            questionId: this.draggedElement.id,
            newPosition: isSimpleQuestionDraggedDown
              ? groupQuestion[groupQuestion.length - 1].order
              : groupQuestion[0].order, // if simple question dragged down, taking position of the last element of group
          };
          return this.moveQuestionToHisNewPosition(payload);
        }
      }
      // dragged element is a group
      let draggedGroupQuestion = [
        ...this.orderedStepQuestions.find(
          (question) =>
            question.id === this.draggedElement.questions[0].group.id
        )?.questions,
      ];

      // if target element is simple question
      if (this.targetElement.order != null) {
        const isGroupDraggedDown =
          draggedGroupQuestion[0].order > this.targetElement.order;
        // if group Question is draggeddown, we use descending sort to set new position in the pile
        draggedGroupQuestion = isGroupDraggedDown
          ? draggedGroupQuestion.sort((a, b) => b.order - a.order)
          : draggedGroupQuestion.sort((a, b) => a.order - b.order);
        return this.moveManyQuestionToHisNewPosition({
          groupLength: draggedGroupQuestion.length,
          number: 0,
          draggedGroupQuestion,
          targetPosition: this.targetElement.order,
        });
      }
      // if target element is group question
      let targetGroupQuestion = [
        ...this.orderedStepQuestions.find(
          (question) => question.id === this.targetElement.questions[0].group.id
        )?.questions,
      ];
      targetGroupQuestion = targetGroupQuestion.sort((a, b) =>
        a.order < b.order ? -1 : 1
      );
      const isGroupDraggedDown =
        draggedGroupQuestion[0].order < targetGroupQuestion[0].order;
      draggedGroupQuestion = isGroupDraggedDown
        ? draggedGroupQuestion.sort((a, b) => b.order - a.order)
        : draggedGroupQuestion.sort((a, b) => a.order - b.order);

      return this.moveManyQuestionToHisNewPosition({
        groupLength: draggedGroupQuestion.length,
        number: 0,
        draggedGroupQuestion,
        targetPosition: isGroupDraggedDown
          ? targetGroupQuestion[targetGroupQuestion.length - 1].order
          : targetGroupQuestion[targetGroupQuestion.length - 1].order - 1,
      });
    },

    // function to move multiple question one at any time
    moveManyQuestionToHisNewPosition({
      groupLength,
      number,
      draggedGroupQuestion,
      targetPosition,
    }) {
      if (number >= groupLength) {
        // end of loop request
        this.resetValue();
        return this.$store.dispatch("snackbar/active", {
          message: this.$t("course.forms.question.hasBeenMovedSuccessFully"),
          type: "SUCCESS",
        });
      }
      const payload = {
        organizationId: this.$route.params.organizationId,
        courseId: this.$route.params.courseId,
        stepId: this.step.id,
        questionId: draggedGroupQuestion[number].id, // taking the question id
        newPosition: targetPosition, // setting the question to the same target position so they can be shuffled
        isGroup: draggedGroupQuestion.length - 1 === number, // updating the questions step on the last request
      };
      try {
        // send first moveQuestion request and wait for the response to send another one
        this.$store.dispatch("course/moveQuestionOrder", payload).then(() =>
          this.moveManyQuestionToHisNewPosition({
            groupLength,
            number: number + 1,
            draggedGroupQuestion,
            targetPosition,
          })
        );
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.errorChangingQuestionPosition"),
          type: "ERROR",
        });
      }
    },
    getDisplayTextForTypeQuestion(type) {
      switch (type) {
        case "TEXT":
          return this.$t("question.types.text");
        case "TEXTAREA":
          return this.$t("question.types.textarea");
        case "NUMBER":
          return this.$t("question.types.number");
        case "DATE":
          return this.$t("question.types.date");
        case "DATETIME":
          return this.$t("question.types.datetime");
        case "RADIOBUTTON":
          return this.$t("question.types.radio");
        case "CHECKBOX":
          return this.$t("question.types.checkbox");
        case "SELECT":
          return this.$t("question.types.select");
        case "FILE":
          return this.$t("question.types.file");
        case "SIGNATURE":
          return this.$t("question.types.signature");
        case "DATAGRID":
          return this.$t("question.types.datagrid");
        case "ROW_DATAGRID":
          return this.$t("question.types.rowDatagrid");
        case "MESSAGE":
          return this.$t("question.types.message");
      }
    },
    /** Function to change order between two steps, similare to edit
     * question order.
     * No time has been taken to refactor or optimize this function
     * because a new version of an endpoint is coming to facilite step order change
     */
    editStepOrder(goUp) {
      // this.isEditingStepOrder = true;
      this.$store
        .dispatch("course/moveStep", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.step.id,
          goUp,
        })
        .then((course) => {
          this.course = course;
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.editStepOrderConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },

    setAvailableQuestionToGroup(status) {
      if (!this.questionsToGroup.length)
        return this.$store.commit("course/setAvailableQuestionToGroup", []);
      if (this.questionsToGroup.length >= 2) {
        let availableIndex = [];
        for (
          let i = this.questionsToGroup[0].order - 1;
          i <=
          this.questionsToGroup[this.questionsToGroup.length - 1].order + 1;
          i++
        ) {
          availableIndex.push(i);
        }

        const indexToInsert = status
          ? [...this.questionsAvailableToGroup, ...availableIndex]
          : [...availableIndex];

        return this.setNewQuestionGroup([...new Set(indexToInsert)]);
      }
      let availableIndex = [
        this.questionsToGroup[0].order - 1,
        this.questionsToGroup[0].order,
        this.questionsToGroup[0].order + 1,
      ];
      return this.setNewQuestionGroup([...new Set(availableIndex)]);
    },
    setNewQuestionGroup(data) {
      this.$store.commit("course/setAvailableQuestionToGroup", []);
      return this.$store.commit("course/setAvailableQuestionToGroup", [
        ...data,
      ]);
    },
    toggleQuestionToGroup(event, question) {
      if (event) {
        this.questionsToGroup.push(question);
        this.questionsToGroup.sort((a, b) => a.order - b.order);
        this.setAvailableQuestionToGroup(true);
      } else {
        this.questionsToGroup = this.questionsToGroup.filter(
          (q) => q.id != question.id
        );
        this.setAvailableQuestionToGroup(false);
      }
    },
    groupQuestions() {
      this.$emit("create-question-group", this.questionsToGroup);
      this.questionsToGroup = [];
    },
    ungroupQuestion(question) {
      this.$store
        .dispatch("course/patchQuestion", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.step.id,
          questionId: question.id,
          question: {
            group: null,
          },
        })
        .then(() => {
          this.fetchStepGroups();
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  margin: 0;
  padding: 0;
}

tr > td {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.drag__drop__system {
  cursor: pointer !important;
}
</style>
