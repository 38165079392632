<template>
  <base-modal
    v-if="activeFolder && dialogState"
    :value="dialogState"
    :max-width="1200"
    :modal-title="offerName"
    is-complex
    :need-action="false"
    @close="reset()"
  >
    <template #modal>
      <div class="offer-creation-container">
        <div class="stepper-container">
          <v-stepper v-model="step" class="stepper" elevation="0">
            <v-stepper-header>
              <v-stepper-step step="1" :complete="step > 1" :color="stepColor">
                <span v-if="step === 1" class="label"> Nature de l'offre </span>
              </v-stepper-step>

              <v-divider :color="step > 1 ? stepColor : '#F9F9F9'" />

              <v-stepper-step step="2" :complete="step > 2" :color="stepColor">
                <span v-if="step === 2" class="label"> Destinataires </span>
              </v-stepper-step>

              <v-divider :color="step > 2 ? stepColor : '#F9F9F9'" />
              <v-stepper-step step="3" :complete="step > 3" :color="stepColor">
                <span
                  v-if="step === 3"
                  class="label"
                  v-text="
                    canAccessStep3
                      ? 'Emplacement de signature'
                      : 'Options supplementaires'
                  "
                />
              </v-stepper-step>

              <v-divider
                v-if="canAccessStep3"
                :color="step > 3 ? stepColor : '#F9F9F9'"
              />
              <v-stepper-step
                v-if="canAccessStep3"
                step="4"
                :complete="step > 4"
                :color="stepColor"
              >
                <span v-if="step === 4" class="label">
                  Options supplémentaires
                </span>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <folder-offer-step-1 />
              </v-stepper-content>

              <v-stepper-content step="2">
                <folder-offer-step-2 v-if="step >= 2" />
              </v-stepper-content>

              <v-stepper-content step="3">
                <folder-offer-step-3 v-if="canAccessStep3" />
                <folder-offer-step-4
                  v-else
                  :is-loading="isCreatingOffer"
                  @create-offer="createOffer"
                />
              </v-stepper-content>
              <v-stepper-content step="4">
                <folder-offer-step-4
                  v-if="newOffer && newOffer.type"
                  :is-loading="isCreatingOffer"
                  :default-signed-file-datatype="
                    newOffer.defaultSignedFileDatatype
                  "
                  @create-offer="createOffer"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { OFFER_TYPES } from "@/core/Utils/types.utils";
import { mapState } from "vuex";
import FolderOfferStep1 from "./FolderOffer/FolderOfferStep1.vue";
import FolderOfferStep2 from "./FolderOffer/FolderOfferStep2.vue";
import FolderOfferStep3 from "./FolderOffer/FolderOfferStep3.vue";
import FolderOfferStep4 from "./FolderOffer/FolderOfferStep4.vue";
export default {
  name: "FolderOfferCreateAndEditDialog",
  components: {
    FolderOfferStep1,
    FolderOfferStep3,
    FolderOfferStep2,
    FolderOfferStep4,
  },
  data() {
    return {
      stepColor: "#9BBDFF",
      isCreatingOffer: false,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) =>
        state.offer.modals.isFolderOfferCreateAndEditDialogIsOpen,
      activeFolder: (state) => state.folder.activeFolder,
      activeOffer: (state) => state.offer.activeOffer,
      newOffer: (state) => state.offer.newOffer,
      offerTemplates: (state) => state.offer.offerTemplates,
      step: (state) => state.offer.offerStep,
    }),
    offerName() {
      if (this.step < 2) {
        return this.$tc("folder.records.createAndEditModal.title", 1);
      }
      return this.newOffer.name;
    },
    canAccessStep3() {
      const isSigned =
        this.newOffer?.signatories &&
        this.newOffer.signatories.some((item) => item.action === "signer");
      return (
        this.step > 1 &&
        isSigned &&
        (this.newOffer.type === "SIGNATURE" || this.newOffer.type === "MIXTE")
      );
    },
  },

  watch() {},
  methods: {
    reset() {
      this.$store.commit("offer/setFolderOfferCreateAndEditDialog", false);
      this.$store.commit("offer/setNewOffer", {
        files: [],
        fileSignaturePosition: [],
      });
      this.$store.commit("offer/setOfferStep", 1);
      this.$store.commit("offer/SET_ACTIVE_OFFER_TEMPLATE_ID", null);
    },
    async initialize() {
      await this.loadOffer();
    },
    async loadOffer() {
      await this.$store.dispatch("offer/getOfferTemplates", {
        organizationId: this.$route.params.organizationId,
      });
    },
    async createOffer() {
      try {
        this.isCreatingOffer = true;
        const {
          name,
          type,
          description,
          files,
          client,
          scheduler,
          publishDate,
          signedFileDatatype,
        } = this.newOffer;

        let payload = {
          title: name,
          description: description
            ? description.replaceAll("\n", "</p><p>")
            : "",
          offerType: type,
          auto_reminder: this.newOffer.auto_reminder,
          clients: client.length ? [...client] : [client || ""],
          signatures_sequential: !!this.newOffer.signatures_sequential,
          scheduler,
          publishDate,
          signedFileDatatype,
        };

        // creation offre
        const newOffer = await this.postPatchOffer({
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
          payload,
        });
        if (newOffer) {
          await this.submitOfferFilesAndOffer(newOffer, files);

          await this.$store.dispatch("offer/submitOffer", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            offerId: newOffer?.id,
          });
          await this.$store.dispatch(
            "folder/getOrganizationOffersRecordFolder",
            {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
            }
          );
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.records.createAndEditModal.success"),
            type: "SUCCESS",
          });
        }
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.records.createAndEditModal.error"),
          type: "ERROR",
        });
      }
      this.isCreatingOffer = false;
      this.reset();
    },
    async postPatchOffer(payload) {
      if (!this.isEdited) {
        return await this.$store.dispatch("offer/postOffer", payload);
      }
      return await this.$store.dispatch("offer/patchOffer", {
        ...payload,
        offerId: this.activeOffer.id,
      });
    },
    async submitOfferFilesAndOffer(offer, files) {
      let filesPromises = [];
      files.map((file) => {
        const filePayload = file.size
          ? {
              name: file.name,
              offer_file: file,
            }
          : { name: file.name };
        filesPromises.push(
          this.$store.dispatch(
            `offer/${file.size ? "postOfferFile" : "patchOfferFile"}`,
            {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
              offerId: offer.id,
              payload: filePayload,
              fileId: file?.id || null,
            }
          )
        );
      });
      await Promise.all(filesPromises);

      // process yousign signature
      // if error occured, we dont submit to the client

      if (
        this.newOffer.type === OFFER_TYPES.SIGNATURE ||
        (this.newOffer.type === OFFER_TYPES.MIXTE && offer.procedure)
      ) {
        const signatories = this.newOffer.signatories
          .map((signatory, index) => {
            if (signatory.action === "signer")
              return {
                first_name: signatory.firstName,
                last_name: signatory.lastName,
                email: signatory.email,
                phone_number: "+33" + signatory.phoneNumber.substr(1),
                order: index,
              };
          })
          .filter((signatory) => signatory);
        return await this.yousignProcess(
          offer.id,
          offer.procedure.id,
          files,
          signatories
        );
      }
    },
    async yousignProcess(offerId, procedureId, files, signatories) {
      return await this.$store.dispatch("offer/createOfferProcedureToSign", {
        procedureId: procedureId,
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        offerId,
        files: files,
        signatures_sequential: this.newOffer.signatures_sequential,
        fileSignaturePosition: this.newOffer.fileSignaturePosition,
        signatories: signatories,
        message: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-creation-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  .stepper-container {
    height: 100%;
    .stepper {
      width: 100%;
    }
    &::v-deep .theme--light.v-stepper {
      background: none;
    }
    &::v-deep .v-stepper__header {
      height: 64px;
    }
    &::v-deep .v-stepper__step {
      height: 64px;
      padding: 24px 0px;
    }
    &::v-deep .v-stepper__header {
      box-shadow: none;
    }
    &::v-deep .v-stepper__content {
      padding: 17px 0 0;
    }

    &::v-deep .v-stepper__step--inactive {
      .v-stepper__step__step {
        border-radius: 23px;
        color: black;
        border: solid 5px rgba(235, 235, 235, 0.3) !important;
        background: white !important;
      }
    }
    &::v-deep .v-stepper__step__step {
      width: 32px;
      height: 32px;
      border-radius: 23px;
      border: solid 5px #eaf1ff !important;
      .v-icon__svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}
.label {
  color: #9bbdff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: none !important;
  background: white;
  padding-right: 3px;
}
.divider {
  background-color: black !important;
  height: 5px;
  width: auto;
}
::v-deep .v-text-field--outlined fieldset {
  height: 48px;
}
.btn-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.btn-next {
  display: flex;
  align-items: center;
}
::v-deep .v-stepper__wrapper {
  overflow: visible;
}
</style>
