export default class CosmoUser {
  constructor({
    id = Number.NaN,
    email = "",
    firstname = "",
    lastname = "",
    isTemporary,
  } = {}) {
    this.id = Number.parseInt(id);
    this.email = email;
    this.firstname = firstname ? firstname : "";
    this.lastname = lastname ? lastname : "";
    this.isTemporary = isTemporary;
  }

  get fullName() {
    return ` ${this.lastname} ${this.firstname}`.trim();
  }

  set lastName(val) {
    this.lastname = val;
  }

  get lastName() {
    return this.lastname ? this.lastname : "";
  }

  set firstName(val) {
    this.firstname = val;
  }

  get firstName() {
    return this.firstname ? this.firstname : "";
  }
}
