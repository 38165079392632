<template>
  <v-textarea
    v-bind="$attrs"
    :class="{ 'error-input': hasError, focused: isFocused }"
    outlined
    @focusin="isFocused = true"
    @focusout="isFocused = false"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: "BaseTextArea",
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.focused {
  ::v-deep .v-input__slot {
    color: #2536cc !important;
    box-shadow: 0px 0px 12px 4px #ebf2ff !important;
  }
}
.error-input {
  ::v-deep .v-input__slot {
    color: #ff5267 !important;
    box-shadow: 0px 0px 8px 2px #ffc6c6 !important;
  }
}
</style>
