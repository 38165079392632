<template>
  <BaseModal
    v-model="dialogState"
    :modal-title="$t('folder.askInformation')"
    @close="reset"
  >
    <template #modal>
      <div class="modal_content">
        <BaseSelect
          v-if="campaigns"
          v-model="form.campaign"
          :label="$t('client.inviteModal.campaignName')"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="campaigns"
          item-text="name"
          item-value="id"
          :loading="isFetchingCampaigns"
          :error-messages="campaignError"
          solo
          need-selection
          @change="onSelectCampaign()"
          @blur="$v.form.campaign.$touch()"
        >
          <template #selection="{ item }">
            <div
              class="d-flex align-items-center justify-space-between select-content"
            >
              <base-paragraph v-text="item.name" />
              <base-icon
                :icon="
                  item.course.anonymous ? '$mdiAccountOff' : '$$mdiAccount'
                "
              />
            </div>
          </template>
        </BaseSelect>
        <BaseMarkdownEditor
          v-model="form.message"
          name="input-7-1"
          :label="$t('folder.askInformationForm.contentLabel')"
          :hint="$t('folder.askInformationForm.contentHint')"
          :with-link="false"
        />
        <v-switch
          v-model="form.createFolder"
          :label="$t('client.inviteModal.createFolderField')"
          inset
          class="checkbox"
          hide-details
        >
          <template #label>
            <div class="checkbox_label">
              <span v-text="$t('client.inviteModal.createFolderField')" />
              <BaseIcon medium icon="$mdiInformation" color="#CFCFDC" />
            </div>
          </template>
        </v-switch>
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        type="primary"
        color="primary"
        :loading="isSubmiting"
        :disabled="$v.form.$invalid"
        @click="submit()"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { readAllCampaign } from "@/modules/Folder/Services/folder.service";
import { mapState } from "vuex";

export default {
  name: "ClientAskInformationDialog",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        campaign: "",
        message: "",
        createFolder: false,
      },
      isFetchingCampaigns: true,
      campaigns: [],
      isSubmiting: false,
    };
  },
  validations: {
    form: {
      campaign: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.client.modals.isAskInformationDialogOpen,
    }),
    campaignError() {
      if (!this.$v.form.campaign.$dirty) {
        return "";
      }
      if (!this.$v.form.campaign.required) {
        return this.$t("client.inviteModal.campaignRequired");
      }
      return "";
    },
  },
  watch: {
    dialogState(newVal) {
      if (newVal && !this.campaigns.length) {
        this.initialize();
      }
    },
  },
  methods: {
    async initialize() {
      this.isFetchingCampaigns = true;
      const res = await readAllCampaign(this.$route.params.organizationId);
      this.campaigns = res.data.results;
      this.isFetchingCampaigns = false;
    },
    reset() {
      this.form = {
        campaign: "",
        message: "",
        createFolder: false,
      };
      this.$store.commit("client/setAskInformationDialog", false);
      this.isSubmiting = false;
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.$invalid) return;
      this.isSubmiting = true;
      const payload = {
        campaign: this.form.campaign,
        email_content: this.form.message,
        create_folder: this.form.createFolder,
      };
      try {
        await this.$store.dispatch("client/postOrganizationClientInvite", {
          organizationId: this.$route.params.organizationId,
          clientId: this.$route.params.clientId,
          payload,
        });
        await this.$store.dispatch("client/fetchOrganizationClientRecords", {
          organizationId: this.$route.params.organizationId,
          clientId: this.$route.params.clientId,
        });
      } catch (error) {
        // Display TheSnackbar
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      // Display TheSnackbar
      this.$store.dispatch("snackbar/active", {
        message: this.$t("client.inviteModal.success"),
        type: "SUCCESS",
      });
      this.reset();
    },
    onSelectCampaign() {
      this.$v.form.campaign.$touch();
      const selectedCampaign = this.campaigns.find(
        (el) => el.id == this.form.campaign
      );
      this.form.message = selectedCampaign?.defaultEmailContent;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal_content {
  padding: 0px 16px;
}
.select-content {
  width: 100%;
}
.checkbox {
  margin-top: 20px !important;
  .checkbox_label {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 8px;
    }
  }
}
</style>
