<template>
  <div class="d-flex justify-center">
    <div class="wallet-thanks-board">
      <BaseFrontOfficeBoard class="mt-16 mt-sm-0">
        <BaseFrontOfficeTitle v-text="$t('wallet.thanks.title')" />

        <BaseDivider class="mt-3 mb-3" grey />
        <BaseFrontOfficeImage
          :src="
            isDigilink && organization
              ? organization.logo
              : $findStatic('cosmoWalletAgreementImage')
          "
          class="logo"
          contain
        />
        <div class="text-center">
          <i18n :path="message">
            <template #organizationName>
              <b v-text="organizationName" />
            </template>
          </i18n>
        </div>
        <div class="d-flex justify-center mt-5">
          <router-link
            v-if="activeRecord && !activeRecord.campaign.course.anonymous"
            :to="{ name: 'WalletSafe' }"
          >
            <BaseFrontOfficeButton
              type="secondary"
              class="mr-3"
              min-height="45"
              v-text="$t('wallet.assistant.safe')"
            />
          </router-link>
          <BaseFrontOfficeButton
            v-if="!isDigilink"
            min-height="45"
            @click.prevent="goToMyCoSite()"
            v-text="$t('account.myCoKnowMore')"
          />
        </div>
      </BaseFrontOfficeBoard>
    </div>
  </div>
</template>

<script>
import RecordAPI from "../Services/record.api";

import Record from "@/core/Models/Record";
import Oauth from "../../../core/Models/Oauth";
export default {
  name: "WalletThanks",
  props: {
    recordId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      activeRecord: null,
    };
  },
  computed: {
    isDigilink() {
      return (
        process.env.VUE_APP_COSMO_URL_API == "https://digilink.anderlaine.com"
      );
    },
    organizationName() {
      return this.organization?.name;
    },
    organization() {
      return this.activeRecord?.campaign.course.organization;
    },
    message() {
      if (
        this.activeRecord?.agreements[0]?.active &&
        !this.activeRecord?.campaign?.course?.anonymous
      ) {
        return "wallet.thanks.messageAccept";
      }
      if (this.activeRecord?.campaign?.course?.anonymous) {
        return this.isDigilink ? "walletThanksSrConseil" : "walletThanks";
      }

      return "wallet.thanks.messageRefuse";
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const payload = { recordId: this.recordId };
      if (this.$route.query.sc) {
        payload["settings"] = { params: { sc: this.$route.query.sc } };
      }
      const tmpRecord = (await RecordAPI.getRecord(payload)).data;

      this.activeRecord = new Record({ ...tmpRecord });

      if (Oauth.localStorage)
        await this.$store.dispatch("walletOrganization/fetchOrganizations");
    },
    goToMyCoSite() {
      open("https://www.devngo.fr/");
    },
  },
};
</script>

<style scoped>
.wallet-thanks-board {
  min-width: 30%;
  width: 500px;
}
.logo {
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 24px;
}
@media (max-width: 960px) {
  .wallet-thanks-board {
    width: 95%;
  }
}
</style>
