<template>
  <v-row>
    <v-col cols="2">
      <div v-if="workflow" class="text" v-text="workflow.name" />
    </v-col>
    <v-col cols="8">
      <BaseProgressLinear
        :value="progressBarValue"
        class="mt-1"
        striped
        height="6"
      />
    </v-col>
    <v-col cols="2">
      <span
        class="text"
        v-text="
          `${workflow.foldersCount} ${$tc(
            'organization.folder',
            folderLength
          )} `
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "StatisticsPanel",
  props: {
    workflow: {
      type: Object,
      default: () => {},
    },
    foldersTotal: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters({ folders: "folder/getAllOrganizationFolders" }),

    progressBarValue() {
      if (!this.folders.length) return 0;
      // calcul progressBar by folders total number
      return +(this.workflow.foldersCount * 100) / this.foldersTotal;
    },
    folderLength() {
      if (this.workflow.foldersCount <= 1) return 1;
      return 2;
    },
  },
};
</script>
