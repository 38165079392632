<template>
  <div>
    <BaseTitle class="d-block mb-6" v-text="$t('organization.avatar.title')" />
    <div class="d-flex align-center">
      <BaseImage
        v-if="activeOrganization.avatar"
        :src="activeOrganization.avatar"
        class="mr-4"
        max-width="77"
      />
      <BaseImage
        v-else
        :src="$findStatic('cosmoAvatar')"
        max-width="77"
        class="mr-6 ml-0"
      />
      <div>
        <BaseParagraph
          class="d-inline-block mb-6"
          v-text="$t('organization.avatar.editionText')"
        />
        <div class="d-flex justify-end">
          <BaseButton class="mr-4" type="primary" @click="toggleEdit(true)">
            {{ $t("organization.avatar.edit") }}
          </BaseButton>

          <BaseModalFileUpload
            :title="$t('organization.avatar.edit')"
            :is-open="modalToggleEdit"
            :file="avatar"
            :is-disabled="!avatar"
            :validate-btn-text="$t('utils.edit')"
            :place-holder="$t('organization.avatar.add')"
            @change="changeAvatar"
            @validate="submitEdit"
            @close="toggleEdit(false)"
          />
          <BaseButton
            :disabled="!activeOrganization.avatar"
            type="secondary"
            @click="toggleDelete(true)"
          >
            {{ $t("organization.avatar.reinitialize") }}
          </BaseButton>
          <BaseModal
            :value="modalToggleDelete"
            :modal-title="$t('organization.avatar.reinitializeTitle')"
            @close="toggleDelete(false)"
          >
            <template #modal>
              <div class="text-center">
                <BaseParagraph
                  v-text="$t('organization.avatar.reinitializeLabel')"
                />
              </div>
            </template>
            <template #actions>
              <BaseButton
                text
                class="mx-2 ml-auto"
                type="secondary"
                color="#707080"
                @click="toggleDelete(false)"
              >
                {{ $t("utils.cancel") }}
              </BaseButton>

              <BaseButton
                color="primary"
                type="primary"
                submit
                @click="submitDelete()"
              >
                {{ $t("organization.avatar.reinitialize") }}
              </BaseButton>
            </template>
          </BaseModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AvatarEdition",
  data() {
    return {
      modalToggleEdit: false,
      modalToggleDelete: false,
      avatar: null,
      resetImageField: false,
    };
  },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
  },
  mounted() {
    this.initializeAvatar();
  },
  methods: {
    async initializeAvatar() {
      if (!this.activeOrganization.avatar) return;
      this.avatar = await this.getAvatar(this.activeOrganization.avatar);
    },
    changeAvatar(avatar) {
      this.avatar = avatar;
    },
    async submitEdit() {
      const organizationId = this.activeOrganization.id;
      const payload = { avatar: this.avatar };
      this.toggleEdit(false);
      try {
        await this.$store.dispatch("organization/patchOrganization", {
          organizationId,
          payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.avatar.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.avatar.snackbar.editError"),
          type: "ERROR",
        });
      }
    },
    async getAvatar(value) {
      const res = await fetch(value);
      const blob = await res.blob();
      const extension = blob.type.split("/");
      return new File([blob], `avatar.${extension[1]}`, {
        type: blob.type,
      });
    },
    async submitDelete() {
      const organizationId = this.activeOrganization.id;
      const payload = { avatar: null };
      this.toggleDelete(false);
      try {
        await this.$store.dispatch("organization/patchOrganization", {
          organizationId,
          payload,
        });
        this.avatar = null;
        this.resetImageField = !this.resetImageField;
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.avatar.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("organization.avatar.snackbar.editError"),
          type: "ERROR",
        });
      }
    },
    toggleEdit(status) {
      this.modalToggleEdit = status;
    },
    toggleDelete(status) {
      this.modalToggleDelete = status;
    },
  },
};
</script>
