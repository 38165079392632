<template>
  <div
    class="text-h6 text--primary"
    :class="{
      'text-uppercase': uppercase,
    }"
  />
</template>

<script>
export default {
  name: "BaseSubtitle",
  props: {
    uppercase: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>
