<template>
  <div>
    <v-card light v-bind="$attrs" v-on="$listeners">
      <v-card-title v-if="$slots.title">
        <slot name="title" />
      </v-card-title>
      <v-divider v-if="$slots.title" class="mx-6" />
      <v-card-text :class="{ 'pa-0': noPadding }">
        <slot />
      </v-card-text>
      <v-divider v-if="$slots.actions" class="mx-6" />
      <v-card-actions v-if="$slots.actions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeCard",
  props: {
    noPadding: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
::v-deep .v-card {
  background-color: var(--v-walletPrimary-lighten5);
}
</style>
