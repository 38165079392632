import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#folder wallet
  getWalletUserFolders({ organizationId, page = 1, isExtraClient = false }) {
    if (isExtraClient)
      return cosmoAPI.get(
        `/wallet/organization/${organizationId}/folder/?extra_client=1&?page=${page}`
      );
    return cosmoAPI.get(
      `/wallet/organization/${organizationId}/folder/?page=${page}`
    );
  },
  getRecordByFolderId({ folderId, page = 1 }) {
    return cosmoAPI.get(`/wallet/record/?folder_id=${folderId}&page=${page}`);
  },
  getOfferByFolderId({ organizationId, folderId, page = 1 }) {
    return cosmoAPI.get(
      `/wallet/organization/${organizationId}/folder/${folderId}/offer/?page=${page}`
    );
  },
  //#endregion
};
