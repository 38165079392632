<template>
  <div v-on="$listeners">
    <div class="sub-title" v-text="`Nature de l'offre`" />
    <div class="input-fields">
      <div class="input_label" v-text="$t('utils.offerDescriptionLabel')" />
      <folder-offer-selecte-pad
        :select-items="offerTypes"
        :has-error="typeError"
        :default-value="offerDefaultValue"
        @selected="
          (value) => {
            form.type = value;
            reset();
          }
        "
      />
      <div class="offer-nature-container">
        <BaseSelect
          v-model="templateSelected"
          name="name"
          :items="filteredOfferTemplates"
          item-text="title"
          item-value="id"
          solo
          dense
          hide-selected
          :label="$t('folder.selectOffer')"
          class="offer-item"
          @change="setTemplateOffer"
        />
        <v-skeleton-loader
          v-if="isLoading && !form.name"
          type="text"
          class="offer-item"
        />
        <BaseTextField
          v-else
          v-model="form.name"
          autofocus
          dense
          name="name"
          :label="`${$t('folder.offerName')} *`"
          :error-messages="nameErrors"
          :has-error="!!nameErrors"
          class="offer-item"
        />
      </div>
      <v-skeleton-loader
        v-if="isLoading && !form.description"
        type="card"
        class="input-skeleton"
      />
      <BaseMarkdownEditor
        v-else
        v-model="form.description"
        name="name"
        :placeholder="
          $t('folder.records.createAndEditModal.messagePlaceHolder')
        "
        :with-link="false"
        :error-messages="descriptionErrors"
      />
    </div>
    <span class="sub-title" v-text="`Fichiers`" />
    <div class="input-fields">
      <v-skeleton-loader v-if="isLoading" type="card" class="input-skeleton" />
      <folder-offer-file-drag
        v-else
        :offer-files="newOffer.files"
        :has-error="filesError"
        is-multiple
        :is-signature="isSignatureType"
        @file-droped="fileInserted"
        @delete-offer-file="removeFile"
      />
    </div>
    <div class="action-container">
      <BaseButton class="ml-auto" type="primary" @click="moveNextStep">
        <span class="mr-2" v-text="$t('utils.next')" />
        <Base-icon small icon="$mdiArrowRightThick" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { OFFER_TYPES } from "@/core/Utils/types.utils";
import { validationMixin } from "vuelidate";
import { fileURLToFile, getDataBlob } from "@/core/Utils/file.utils";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import FolderOfferSelectePad from "../FolderOfferSelectPad/FolderOfferSelectePad.vue";
import FolderOfferFileDrag from "./FolderOfferFileDrag.vue";

export default {
  name: "FolderOfferStep1",

  components: { FolderOfferSelectePad, FolderOfferFileDrag },
  mixins: [validationMixin],

  data() {
    return {
      offerTypes: [
        {
          title: "Consultation",
          icon: "$mdiEye",
          value: OFFER_TYPES.CONSULT,
        },
        {
          title: "Validation",
          icon: "$mdiCheck",
          value: OFFER_TYPES.VALIDATION,
        },
        {
          title: "Signature electronique",
          icon: "$mdiPencilOutline",
          value: OFFER_TYPES.SIGNATURE,
        },
        {
          title: "Offre Mixte",
          icon: "$mdiTag",
          value: OFFER_TYPES.MIXTE,
        },
      ],
      dragover: false,
      form: {
        type: null,
        name: "",
        description: "",
        files: [],
      },
      offerDefaultValue: null,
      templateSelected: null,
      isLoading: false,
    };
  },
  validations() {
    let validations = {};
    validations.form = {
      name: {
        required,
        maxLength: maxLength(255),
      },
      description: {
        required,
      },
      type: {
        required,
      },
      files: {
        required,
        minLength: minLength(1),
        $each: {
          required,
        },
      },
    };
    return validations;
  },
  computed: {
    ...mapState({
      newOffer: (state) => state.offer.newOffer,
      offerTemplates: (state) => state.offer.offerTemplates,
      activeFolder: (state) => state.folder.activeFolder,
      defaultOfferTemplate: (state) => state.offer.activeOfferTemplateId,
      dialogState: (state) =>
        state.offer.modals.isFolderOfferCreateAndEditDialogIsOpen,
    }),
    isSignatureType() {
      return this.form.type === OFFER_TYPES.SIGNATURE;
    },
    filteredOfferTemplates() {
      if (!this.offerTemplates) return [];
      return this.offerTemplates.filter(
        (ot) => ot.offerType === this.form.type
      );
    },
    hasClient() {
      return !this.activeFolder?.client?.isTemporary;
    },
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("folder.records.createAndEditModal.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("folder.records.createAndEditModal.nameMaxLength");
      }
      return "";
    },
    descriptionErrors() {
      if (!this.$v.form.description.$dirty) return [];
      if (!this.$v.form.description.required) {
        return [this.$t("utils.descriptionRequired")];
      }
      return [];
    },
    typeError() {
      if (!this.$v.form.type.$dirty) {
        return false;
      }
      if (!this.$v.form.type.required) {
        return true;
      }
      return false;
    },
    filesError() {
      if (!this.$v.form.files.$dirty) {
        return false;
      }
      if (!this.$v.form.files.required) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    reset() {
      this.form.name = null;
      this.form.description = "";
      this.templateSelected = null;
      this.$store.commit("offer/setNewOffer", {
        files: [],
        fileSignaturePosition: [],
      });
    },
    async initialize() {
      await this.loadOffer();
      if (this.defaultOfferTemplate) {
        this.templateSelected = this.defaultOfferTemplate;
        return this.setTemplateOffer();
      }
    },
    async loadOffer() {
      await this.$store.dispatch("offer/getOfferTemplates", {
        organizationId: this.$route.params.organizationId,
      });
    },
    getOfferType(offertype) {
      switch (offertype) {
        case OFFER_TYPES.MIXTE:
          return 3;
        case OFFER_TYPES.SIGNATURE:
          return 2;
        case OFFER_TYPES.VALIDATION:
          return 1;
        default:
          return 0;
      }
    },
    async fileInserted({ value }) {
      const fileType = [
        ".xls",
        ".xlsx",
        ".doc",
        ".docx",
        ".ppt",
        ".pptx",
        ".pdf",
      ];

      if (
        this.isSignatureType &&
        [...value].some(
          (file) => !fileType.includes(`.${file.name.split(".").pop()}`)
        )
      ) {
        return this.$store.dispatch("snackbar/active", {
          message: "Format de fichier non-supporté.",
          type: "ERROR",
        });
      }
      // insert file locally to use vuelidate+vuex
      let fileList = [];
      for (const file of value) fileList.push(file);

      if (this.checkIfSameNameFile(fileList)) {
        fileList = [
          ...fileList.filter(
            (file) =>
              !this.newOffer.files.some((item) => item.name === file.name)
          ),
        ];
        this.$store.dispatch("snackbar/active", {
          message: "Fichier identique",
          type: "ERROR",
        });
      }
      this.$store.commit("offer/setNewOfferFiles", fileList);
      this.form.files = [...this.newOffer.files];
      this.$v.form.files.$touch();
    },
    checkIfSameNameFile(files) {
      return files.some((file) =>
        this.newOffer.files.some((item) => item.name === file.name)
      );
    },
    removeFile(index) {
      this.$store.commit("offer/removeNewOfferFile", index);
      this.form.files.splice(index, 1);
    },
    async setTemplateOffer() {
      this.$store.commit("offer/resetNewOfferFiles");
      if (!this.templateSelected) {
        this.form = {
          ...this.form,
          name: "",
          description: "",
        };
      } else {
        this.isLoading = true;
        const template = await this.$store.dispatch(
          "offer/getOfferTemplateDetails",
          {
            organizationId: this.$route.params.organizationId,
            offerId: this.templateSelected,
          }
        );
        let filesPromises = [];
        template.files.forEach((file) => {
          return filesPromises.push(getDataBlob(file.offerFile));
        });
        this.offerDefaultValue = this.getOfferType(template.offerType);
        const files = [];
        Promise.all(filesPromises).then(async (blobFiles) => {
          for (const [index, blobFile] of blobFiles.entries())
            files.push(
              await fileURLToFile(blobFile, template.files[index].name)
            );

          this.$store.commit("offer/setNewOfferFiles", files);
          this.form = {
            ...this.form,
            name: template.title,
            type: template.offerType,
            description: template.description,
            files: [...files],
            defaultSignedFileDatatype: template.signedFileDatatype,
            signatures_sequential: template.isSequential,
          };
          this.isLoading = false;
        });
      }
    },

    moveNextStep() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.$store.commit("offer/setNewOffer", {
        ...this.newOffer,
        ...this.form,
      });
      this.$store.commit("offer/setOfferStep", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
.input-fields {
  padding: 16px 16px 0 16px;
  margin-bottom: 24px;
  .input_label {
    color: #353542;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
  }
}
.offer-nature-container {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  .offer-item {
    flex: 1;
  }
}
.file-drag {
  width: 400px;
  height: 100px;
  background: lightgrey;
}
::v-deep .v-menu__content {
  top: 44px !important;
}

::v-deep .v-skeleton-loader__text {
  height: 40px;
}

::v-deep .v-skeleton-loader__heading .v-skeleton-loader__bone {
  display: none;
}
.action-container {
  display: flex;
  align-items: center;
  margin-top: 32px;
}
</style>
