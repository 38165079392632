<template>
  <div class="cosmoform_container">
    <div v-if="isTokenExpired" class="content mt-4">
      <div class="bord" v-text="$t('cosmoform.title')" />
      <div class="record_archived_alert">
        <div class="alert_icon">
          <BaseIcon icon="$mdiLock" />
        </div>
        <div class="alert_text">
          <div v-text="$tc('utils.cosmoformArchivedAlert', 1)" />
          <div v-text="$tc('utils.cosmoformArchivedAlert', 2)" />
        </div>
      </div>
    </div>
    <div v-else-if="!isTokenExpired && isFetchCosmoform" class="content_full">
      <BaseProgressCircular color="primary" size="56" indeterminate />
    </div>
    <div v-else class="content">
      <div
        class="bord"
        v-text="cosmoformOrganization && cosmoformOrganization.title"
      />
      <div
        class="banner"
        :class="{
          banner_center:
            !cosmoformOrganization || !cosmoformOrganization.organization.logo,
        }"
      >
        <div
          v-if="
            cosmoformOrganization && cosmoformOrganization.organization.logo
          "
          class="logo_container"
        >
          <img :src="cosmoformOrganization.organization.logo" class="logo" />
        </div>
        <div class="label">
          <BaseMarkdownViewer
            :content="
              cosmoformOrganization && cosmoformOrganization.description
            "
          />
        </div>
      </div>
      <v-expansion-panels :value="panel" multiple>
        <v-expansion-panel
          v-for="(group, index) in cosmoformsGroupedByCategory"
          :key="`${group.id}-${index}`"
        >
          <v-expansion-panel-header>
            <div class="d-flex">
              <BaseIcon icon="$mdiLock" grey class="mr-4" />
              <BaseFrontOfficeSubtitle v-text="group.name" />
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pannel_content">
              <PreviewCosmoform
                v-for="(cosmoform, i) in group.cosmoforms"
                :key="`cf-${i}`"
                :cosmoform="cosmoform"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="actions">
        <v-btn
          :color="'primary'"
          :loading="isSubmiting"
          rounded
          x-large
          :disabled="isInvalid"
          @click="submitCosmoform()"
          v-text="$t('utils.send')"
        />
      </div>
    </div>

    <!-- DIALOG -->
    <FillCosmoformDialog />
  </div>
</template>

<script>
import FillCosmoformDialog from "../Components/FillCosmoformDialog.vue";
import PreviewCosmoform from "../Components/PreviewCosmoform.vue";
import { mapState } from "vuex";

import Oauth from "@/core/Models/Oauth";
import { setBearerToken } from "@/core/Api/cosmoAxiosInstance";
import Cosmoform from "@/core/Models/Cosmoform";

export default {
  name: "FillCosmoform",
  components: {
    FillCosmoformDialog,
    PreviewCosmoform,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFetchCosmoform: true,
      isSubmiting: false,
      isTokenExpired: false,
      cosmoformCategories: [],
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  computed: {
    ...mapState({
      cosmoforms: (state) => state.cosmoform.cosmoforms,
      cosmoformOrganization: (state) => state.cosmoform.cosmoFormOrganization,
      user: (state) => state.account.user,
    }),

    cosmoformsGroupedByCategory() {
      const groupedCosmoforms = [];
      this.cosmoforms.forEach((cosmoform) => {
        if (cosmoform.question.category?.id) {
          const group = groupedCosmoforms.find(
            (gc) => gc.id === cosmoform.question.category?.id
          );
          if (group) {
            group.cosmoforms.push(cosmoform);
            group.cosmoforms = group.cosmoforms.sort(
              (a, b) => a.question.order - b.question.order
            );
          } else {
            groupedCosmoforms.push({
              ...cosmoform.question.category,
              cosmoforms: [cosmoform],
            });
          }
        }
      });
      const withoutCategoryGroup = {
        id: null,
        order: 0,
        name: this.$t("category.uncategorized"),
        cosmoforms: this.cosmoforms
          .filter(
            (cosmoform) =>
              !cosmoform.question.category || !cosmoform.question.category.id
          )
          .sort((a, b) => a.question.order - b.question.order),
      };
      if (withoutCategoryGroup.cosmoforms.length) {
        groupedCosmoforms.unshift(withoutCategoryGroup);
      }
      return groupedCosmoforms.sort((a, b) => a.order - b.order);
    },
    isInvalid() {
      for (const cosmoform of this.cosmoforms) {
        if (
          cosmoform.question.required &&
          (!cosmoform.answer ||
            (!cosmoform.answer.content && !cosmoform.answer.choices?.length))
        )
          return true;
      }
      return false;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const oauth = Oauth.localStorage;
        if (oauth) {
          setBearerToken(oauth.accessToken);
          await this.$store.dispatch("account/fetchUser");
        }
        if (this.token) {
          await this.$store.dispatch(
            "cosmoform/fetchCosmoFormOrganization",
            this.token
          );
          await this.$store.dispatch("cosmoform/fetchCosmoforms", {
            token: this.token,
          });
          await this.initCosmoformAnswer();
        } else {
          this.isTokenExpired = true;
        }
      } catch (error) {
        if (error?.response?.status === 403)
          return this.$router.push({ name: "WalletReserved" });

        if (
          error?.response?.status === 404 ||
          (error?.response?.status === 500 &&
            (error?.response?.data.includes("CosmoFrom expired") ||
              error?.response?.data.includes("does not exist")))
        ) {
          return (this.isTokenExpired = true);
        }
        if (
          error?.response?.status === 401 ||
          (error?.response?.status === 400 &&
            error?.response?.data.code.includes("Login is required"))
        ) {
          return location.replace(
            `${
              this.$route.query.has_account === "True"
                ? "/wallet/auth/login/"
                : "/wallet/auth/signup"
            }?next=${this.$route.fullPath}`
          );
        }
      }
      this.isFetchCosmoform = false;
    },
    async initCosmoformAnswer() {
      const cosmoforms = [];
      for (const cosmoform of this.cosmoforms) {
        let tmpCosmoform = new Cosmoform(JSON.parse(JSON.stringify(cosmoform)));
        if (
          tmpCosmoform.answer &&
          /^FILE|MULTIPLE_FILE$/.test(tmpCosmoform.question.typeQuestion)
        ) {
          const values = tmpCosmoform.answer.content.map(async (content) => ({
            name: content.name,
            url: await this.getDataBlob(content.url),
          }));
          tmpCosmoform.answer.content = await Promise.all(values);
        }
        cosmoforms.push(tmpCosmoform);
      }
      this.$store.commit("cosmoform/SET_COSMOFORMS", cosmoforms);
    },
    async submitCosmoform() {
      this.isSubmiting = true;
      try {
        await this.$store.dispatch("cosmoform/submitCosmoformsAnswer", {
          token: this.token,
        });
        this.isSubmiting = false;
        this.$router.push({
          name: "ThanksPage",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
    },
    async parseURI(d) {
      var reader = new FileReader();
      reader.readAsDataURL(d);
      return new Promise((res) => {
        reader.onload = (e) => {
          res(e.target.result);
        };
      });
    },
    async getDataBlob(url) {
      var res = await fetch(url);
      var blob = await res.blob();
      var uri = await this.parseURI(blob);
      return uri;
    },
  },
};
</script>

<style lang="scss" scoped>
.cosmoform_container {
  width: 100%;
  min-height: 100vh;
  background: #f9f8fb;
  padding: 32px;
  position: relative;
  .record_archived_alert {
    text-align: center;
    padding: 16px 32px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    border: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
      0px 10px 10px rgba(46, 45, 193, 0.06);
    margin-bottom: 24px;
    .alert_icon {
      padding: 8px;
      &::v-deep {
        .v-icon {
          color: #3c3b8e;
        }
      }
    }
    .alert_text {
      padding: 8px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #3c3b8e;
      margin: auto;
    }
  }
  .content_full {
    width: 100%;
    height: calc(100vh - 64px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    max-width: 1130px;
    margin: auto;
    & > * {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .bord {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
      0px 10px 10px rgba(46, 45, 193, 0.06);
    border-radius: 20px;
    color: #4f4f4f;
    font-size: 24px;
    line-height: 1.5em;
  }

  .banner {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    min-height: 90px;
    &.banner_center {
      justify-content: center;
    }
    .logo_container {
      height: 80px;
      border-radius: 5px;
      flex: none;
      .logo {
        width: 200px;
        height: 100%;
        object-fit: contain;
      }
    }
    .label {
      margin: 0 16px;
      flex: 1;
      font-family: "Inter";
      font-size: 16px !important;
    }
    @media (max-width: 600px) {
      .label {
        margin: 0;
        margin-top: 16px;
        width: calc(100% - 32px);
        flex: none;
      }
      .logo_container {
        width: 100%;
        height: auto;
        .logo {
          width: 200px;
          height: auto;
        }
      }
    }
  }
  .CA_custom {
    color: white;
  }
  .pannel_content {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    @media (min-width: 600px) {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: repeat(2, calc(50% - 8px));
    }
    @media (min-width: 1130px) {
      grid-column-gap: calc(4% / 2);
      grid-row-gap: 16px;
      grid-template-columns: repeat(3, 32%);
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
}

::v-deep .v-expansion-panel {
  border-radius: 15px !important;
  &::before {
    box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
      0px 6px 10px rgba(46, 45, 193, 0.06) !important;
  }
}

::v-deep .v-expansion-panel-header {
  background-color: #ebeffe !important;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 16px;
}
</style>
