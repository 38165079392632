<template>
  <BaseFrontOfficeDialog
    v-if="record"
    :value="dialogState"
    persistent
    max-width="500"
  >
    <template #modal>
      <BaseFrontOfficeCard>
        <template #title>
          <BaseFrontOfficeSubtitle
            class="my-4"
            v-text="$t('wallet.record.shareData')"
          />
        </template>
        <template>
          <div class="my-8">
            <div class="d-flex justify-center my-4">
              <BaseFrontOfficeImage
                :src="$findStatic('agreementImageModal')"
                max-width="258"
              />
            </div>
            <BaseFrontOfficeParagraph>
              <i18n v-if="isActive" path="wallet.record.shareDataActiveMessage">
                <template #organizationName>
                  <b>
                    {{ record.campaign.course.organization.name }}
                  </b>
                </template>
              </i18n>
              <i18n v-else path="wallet.record.unshareDataActiveMessage">
                <template #organizationName>
                  <b>
                    {{ record.campaign.course.organization.name }}
                  </b>
                </template>
              </i18n>
            </BaseFrontOfficeParagraph>
          </div>
          <div class="d-flex justify-end mt-4">
            <BaseFrontOfficeButton
              type="secondary"
              class="mr-4"
              :disabled="isSubmitting"
              @click="cancelAction()"
            >
              {{
                isActive
                  ? $t("wallet.record.continueSharing")
                  : $t("wallet.record.continueUnsharing")
              }}
            </BaseFrontOfficeButton>
            <BaseFrontOfficeButton :loading="isSubmitting" @click="submit()">
              {{
                isActive
                  ? $t("wallet.record.stopSharing")
                  : $t("wallet.record.stopUnsharing")
              }}
            </BaseFrontOfficeButton>
          </div>
        </template>
      </BaseFrontOfficeCard>
    </template>
  </BaseFrontOfficeDialog>
</template>

<script>
import RecordAPI from "@/modules/Wallet/Services/record.api";

export default {
  name: "WalletSafeRecordToggleAgreementDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isSubmitting: false,
  }),
  computed: {
    isActive() {
      return this.record.agreements[0].active;
    },
  },
  methods: {
    cancelAction() {
      this.$emit("update-agreement", this.isActive);
    },
    async submit() {
      this.isSubmitting = true;
      try {
        await RecordAPI.updateRecordAgreement(this.record.id, !this.isActive);
        this.$emit("update-agreement", !this.isActive);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("wallet.record.success"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("wallet.record.error"),
          type: "ERROR",
        });
      }
      this.isSubmitting = false;
    },
  },
};
</script>
