var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.question)?_c('div',{class:_vm.statusSheetClass},[(
      _vm.question.answer &&
      (_vm.question.answer.content ||
        !isNaN(parseInt(_vm.question.answer.content, 10)))
    )?_c('div',{staticClass:"question_answer"},[(/^DATE$/.test(_vm.question.typeQuestion))?_c('div',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.question.answer.content), 'short'))}}):(/^DATETIME$/.test(_vm.question.typeQuestion))?_c('div',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.question.answer.content), 'full'))}}):(/MULTIPLE_FILE/.test(_vm.question.typeQuestion))?_vm._l((_vm.question.answer.content),function(content){return _c('div',{key:content.id},[_c('span',{staticClass:"record_question_container",attrs:{"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return _vm.openMultiFile(content)}}},[_c('a',{staticClass:"label link"},[_vm._v(_vm._s(content.label || content.name))])])])}):(/SIGNATURE/.test(_vm.question.typeQuestion))?_c('a',{staticClass:"label link",attrs:{"href":_vm.question.answer.content[0] && _vm.question.answer.content[0].url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return _vm.openFiles.apply(null, arguments)}}},[_vm._v(" question ")]):(/FILE/.test(_vm.question.typeQuestion))?_c('a',{staticClass:"label",attrs:{"href":_vm.question.answer.content[0] && _vm.question.answer.content[0].url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t("answer.seeFolder", { name: _vm.question.name }))+" ")]):(
        /DATAGRID|ROW_DATAGRID/.test(_vm.question.typeQuestion) &&
        Array.isArray(_vm.question.answer.content)
      )?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("answer.seeTable"))+" ")]):(
        /TEXT|TEXTEARA|NUMBER|SELECT|RADIOBUTTON|CHECKBOX/.test(
          _vm.question.typeQuestion
        )
      )?_c('div',{staticClass:"label",domProps:{"textContent":_vm._s(
        !!_vm.question.answer.freeOptionContent &&
        _vm.question.typeQuestion !== 'CHECKBOX'
          ? _vm.question.answer.freeOptionContent
          : _vm.question.answer.content
      )}}):_vm._e()],2):_c('BaseDescription',{attrs:{"truncate":"","italic":""},domProps:{"textContent":_vm._s(_vm.$t('answer.noContent'))}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }