<template>
  <div>
    <Board>
      <template slot="header">
        <div class="d-flex justify-space-between align-center">
          <BaseTitle class="d-block" v-text="$tc('workflow.statistic.title')" />
        </div>
      </template>
      <template slot="main">
        <BaseExpensionPanels class="mb-4" multiple>
          <BaseExpensionPanel
            v-for="(workflow, index) in statisticsWorkflow"
            :key="index"
            expand
            :eager="true"
          >
            <template slot="header">
              <StatisticsPanelHeader
                :key="`statisticHeaderPanel-${index}`"
                :workflow="workflow"
                :folders-total="foldersTotal"
              />
            </template>
            <template slot="content">
              <StatisticsPanelContent
                v-for="(status, i) in workflow.status"
                :key="`statisticContent-${i}`"
                :status="status"
                :workflow="workflow"
              />
            </template>
          </BaseExpensionPanel>
        </BaseExpensionPanels>
      </template>
    </Board>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Board from "../Board";
import StatisticsPanelHeader from "./Statistic/StatisticsPanelHeader";
import StatisticsPanelContent from "./Statistic/StatisticsPanelContent";

export default {
  name: "OrganizationDashboardStatistics",
  components: {
    Board,
    StatisticsPanelHeader,
    StatisticsPanelContent,
  },
  computed: {
    ...mapState({
      statisticsWorkflow: (state) => state.organization.statisticWorkflows,
    }),
    foldersTotal() {
      if (!this.statisticsWorkflow.length) return 0;
      let total = 0;
      this.statisticsWorkflow.map(
        (statistic) => (total = total + statistic.foldersCount)
      );
      return total;
    },
  },
  async created() {
    await this.setData();
  },
  methods: {
    async setData() {
      await this.$store.dispatch(
        "organization/getOrganizationStatistics",
        this.$route.params.organizationId
      );
    },
  },
};
</script>
