<template>
  <div id="pdf-container" class="pdf-view">
    <pdf v-if="fileSrc" :src="fileSrc" :page="page ? page : 1" :scale="0.75">
      <template slot="loading">
        {{ loadingMessage }}
      </template>
    </pdf>
    <div v-for="(signatory, index) in signatories" :key="index">
      <div
        v-if="
          signatory &&
          selectedSignatory.some((sign) => sign === signatory.email)
        "
        :id="`mydiv${index}`"
        :identifier="index"
        class="mydiv"
        style="top: 370px; left: 325px;"
      >
        <div
          id="mydivheader"
          class="mydivheader"
          :style="`background-color:${getColor(index)}`"
          @mousedown="dragElement(index)"
        >
          <base-icon
            icon="$mdiAccountCircle"
            medium
            color="primary"
            class="mr-2"
          />
          <span v-text="`${signatory.firstName} ${signatory.lastName}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const SIGNATORY_COLOR = [
  "#BDA0ED33",
  "#E2ECFF",
  "#beecbe",
  "#DFCCFB",
  "#FFF3DA",
  "#F6FFA6",
  "#F6FFA6",
];
import { VignetteSignatureProperty } from "@/core/Utils/types.utils";
import pdf from "pdfvuer";
export default {
  name: "BasePdfViewerSignature",
  components: { pdf },
  props: {
    pdf: {
      type: [String, File],
      required: true,
    },
    page: {
      type: [String, Number],
      default: 1,
    },
    loadingMessage: {
      type: String,
      default: "En attente de chargement",
    },
    signatories: {
      type: Array,
      default: () => [],
    },
    selectedSignatory: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inserted: false,
      offsetX: null,
      offsetY: null,
      ele: null,
    };
  },
  computed: {
    fileSrc() {
      return this.pdf;
    },
  },

  methods: {
    getColor(index) {
      return SIGNATORY_COLOR[index];
    },
    // dragelement
    dragElement(id) {
      const elmnt = document.getElementById(`mydiv${id}`);
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      elmnt.onmousedown = dragMouseDown;
      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();

        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        const newTopPosition = elmnt.offsetTop - pos2;
        const newLeftPosition = elmnt.offsetLeft - pos1;

        if (
          newLeftPosition < 0 ||
          newLeftPosition + VignetteSignatureProperty.width >
            document.getElementById("pdf-container").offsetWidth ||
          newTopPosition < 0 ||
          newTopPosition + VignetteSignatureProperty.height >
            +document.getElementById("pdf-container").offsetHeight
        )
          return;
        // set the element's new position:
        elmnt.style.top = newTopPosition + "px";
        elmnt.style.left = newLeftPosition + "px";
        // getPosition(elmnt.offsetLeft - pos1, elmnt.offsetTop - pos2);
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  },
};
</script>

<style scoped>
.pdf-view {
  position: relative;
  display: flex;
  width: auto;
  justify-content: center;
}
.mydiv {
  position: absolute;
  top: 370px;
  left: 325px;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
}

.mydivheader {
  padding: 10px;
  height: 70px;
  width: 180px;
  cursor: move;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2196f3;
  color: #2536cc;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
