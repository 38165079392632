<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('utils.deleteLabel.title')"
    is-unique
    @close="reset()"
  >
    <template #modal>
      <div class="text-label">
        {{ $t("utils.deleteLabel.confirmation") }}
      </div>
    </template>
    <template #actions>
      <BaseButton
        type="primary"
        class="mx-2 ml-auto"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import Question from "@/core/Models/Question";
export default {
  name: "FolderFileConfirmDeleteDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    fileToDelete: {
      type: [Object, Number],
      default: () => {},
    },
    question: {
      type: Question,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      activeQuestions: (state) => state.folder.activeQuestions,
    }),
  },
  methods: {
    reset() {
      this.$emit("close");
      this.isLoading = false;
    },
    async submit() {
      this.isLoading = true;
      if (!this.fileToDelete) return this.reset();
      try {
        let payload = {};
        if (this.fileToDelete.deletionEndpoint?.includes("answer-file")) {
          payload = {
            personal_data_owner: this.activeFolder.client.id,
            organization: this.$route.params.organizationId,
            folder: this.activeFolder.id,
          };
          await this.$store.dispatch("folder/removeRecordFromQuestion", {
            endpoint: this.fileToDelete.deletionEndpoint,
            payload,
            question: this.question,
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.deleteLabel.successfully"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: error.message || "error",
          type: "ERROR",
        });
      }
      this.$emit("delete");
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-label {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
