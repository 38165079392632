<template>
  <div>
    <BaseFrontOfficeParagraph class="d-block mb-4" v-text="label" />
    <div class="d-flex align-start">
      <v-file-input
        v-model="image"
        outlined
        :error-messages="imageErrors"
        :error-count="2"
        prepend-icon="$mdiUpload"
        persistent-placeholder
        :placeholder="$t('account.chooseAFile')"
        :accept="types"
      />
      <div class="d-flex align-center">
        <a
          v-if="image && imageErrors.length === 0"
          :href="localImage ? localImage : image"
          target="blank"
          class="flex-grow-0 ml-4"
        >
          <BaseImage
            :src="localImage ? localImage : image"
            height="56px"
            width="56px"
            class="rounded-circle"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFieldImage",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    types: {
      type: String,
      default: "image/jpeg,image/png,image/jpg",
    },
    maxSize: {
      type: Number,
      default: 8000000, // 8 Mo
    },
    reset: {
      type: Boolean,
    },
    modelValue: {
      type: [String, File],
      default: null,
    },
  },
  data() {
    return {
      image: null,
      localImage: "",
      errorMessages: "",
    };
  },
  computed: {
    imageErrors() {
      let errors = [];
      if (this.image) {
        if (
          !(
            this.types.includes("image/jpg") && /.jpg$/.test(this.image.type)
          ) &&
          !(
            this.types.includes("image/jpeg") && /.jpeg$/.test(this.image.type)
          ) &&
          !(this.types.includes("image/png") && /.png$/.test(this.image.type))
        ) {
          let tempImageTexts = this.types.split(",");
          tempImageTexts = tempImageTexts.map((tempImageText) => {
            return tempImageText.replace("image/", "").toUpperCase();
          });
          tempImageTexts = tempImageTexts.join(", ");
          errors.push(
            this.$t("utils.pleaseProvideAnImageError", {
              types: tempImageTexts,
            })
          );
        }
      }
      if (this.image && this.image.size > this.maxSize) {
        errors.push(
          this.$t("utils.imageTooBig", { size: this.maxSize / 1000000 })
        );
      }
      return errors;
    },
  },
  watch: {
    image(newVal) {
      if (newVal && !this.imageErrors.length) {
        this.localImage = URL.createObjectURL(newVal);
        this.$emit("changeValue", this.image);
      } else {
        this.localImage = "";
        this.$emit("changeValue", null);
      }
    },
    reset() {
      this.image = null;
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot {
  min-width: 250px;
  border-radius: 25px;
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    #ff7074 !important;
}
::v-deep .v-input__slot fieldset {
  border-radius: 25px;
  margin: 1px 2px 2px 2px !important;
  border: hidden;
  background-color: white;
}
::v-deep .v-input__slot .v-text-field__slot {
  margin-left: 10px;
}
</style>
