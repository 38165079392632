<template>
  <pdf v-if="pdf" :src="pdf" :page="page ? page : 1">
    <template slot="loading">
      {{ loadingMessage }}
    </template>
  </pdf>
</template>

<script>
import pdf from "pdfvuer";
export default {
  name: "BasePdfViewer",
  components: { pdf },
  props: {
    pdf: {
      type: String,
      required: true,
    },
    page: {
      type: [String, Number],
      default: 1,
    },
    loadingMessage: {
      type: String,
      default: "En attente de chargement",
    },
  },
};
</script>

<style scoped></style>
