import OfferApi from "../Services/offer.api";

import Offer from "@/core/Models/Offer";
import { OfferClient } from "@/core/Models/OfferClient";

export default {
  namespaced: true,
  state: {
    offerList: [],
    offerListPromise: null,
    activeOffer: null,
    newOffer: { files: [], fileSignaturePosition: [] },
    activeOfferTemplateId: null,
    offerTemplates: [],
    offerTemplateDetails: null,
    offerStep: 1,
    modals: {
      isFolderOfferCreateAndEditDialogIsOpen: false,
      isWalletOrganizationOfferShowDialogIsOpen: false,
    },
  },
  mutations: {
    //#region Offer
    setOfferStep(state, payload) {
      state.offerStep = payload;
    },
    setNewOffer(state, payload) {
      state.newOffer = payload;
    },
    removeNewOfferFile(state, file) {
      return state.newOffer.files.splice(file, 1);
    },
    resetNewOfferFiles(state) {
      return (state.newOffer.files = []);
    },
    setNewOfferFiles(state, payload) {
      return (state.newOffer.files = [...state.newOffer.files, ...payload]);
    },
    setOfferMemberAndSignedFile(state, { offerId, member, signedFiles }) {
      const offer = state.offerList.find((offer) => offer.id === offerId);
      offer.yousignMembers = member.map((youMember, index) => ({
        ...youMember,
        ...offer.yousignMembers[index],
      }));
      offer.signedFiles = signedFiles;
    },
    setOfferList(state, payload) {
      state.offerList = payload
        ? payload
            .map((offer) => new Offer(offer))
            .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
        : null;
    },
    setOfferListPromise(state, payload) {
      state.offerListPromise = payload ? new Offer(payload) : null;
    },

    setOfferListOffer(state, { offerId, payload }) {
      state.offerList.splice(
        state.offerList.findIndex((o) => o?.id == offerId),
        1,
        new Offer(payload)
      );
    },
    setOfferTemplates(state, payload) {
      state.offerTemplates = payload;
    },
    setOfferTemplateDetails(state, payload) {
      state.offerTemplateDetails = payload;
    },
    setActiveOffer(state, payload) {
      state.activeOffer = payload;
    },
    SET_OFFER_CLIENT(state, { offerClient }) {
      state.offerList = state.offerList.map((o) => {
        if (o.id == offerClient.offer) {
          o.clients = o.clients.map((oc) =>
            oc.id == offerClient.id ? new OfferClient({ ...offerClient }) : oc
          );
        }
        return o;
      });
    },
    //#endregion
    //#region Modals
    setFolderOfferCreateAndEditDialog(state, payload) {
      state.modals.isFolderOfferCreateAndEditDialogIsOpen = payload;
    },
    setWalletOrganizationOfferShowDialog(state, payload) {
      state.modals.isWalletOrganizationOfferShowDialogIsOpen = payload;
    },
    //#endregion
    SET_ACTIVE_OFFER_TEMPLATE_ID(state, payload) {
      state.activeOfferTemplateId = payload;
    },
  },
  actions: {
    // procedure create offer to sign
    async createOfferProcedureToSign(
      _,
      {
        procedureId,
        organizationId,
        files,
        signatories,

        fileSignaturePosition,
      }
    ) {
      // Manage signatories promises
      let signatoriesPromises = [];
      signatories.forEach((signatory) => {
        const payload = {
          first_name: signatory.first_name,
          last_name: signatory.last_name,
          email: signatory.email,
          phone_number: signatory.phone_number,
          order: signatory.order + 1,
        };

        signatoriesPromises.push(
          OfferApi.postYouSignMemberAndSignature({
            organizationId,
            procedureId: procedureId,
            procedureType: "member",
            payload,
          })
        );
      });
      const signatoriesAllPromises = Promise.all(signatoriesPromises).then(
        (signatoriesResults) => {
          return signatoriesResults.map((res) => res.data);
        }
      );
      // create you sign files
      let filesPromises = [];
      files.forEach((file) => {
        const payload = new FormData();
        payload.append("name", file.name);
        payload.append("pdf", file);
        filesPromises.push(
          OfferApi.postYouSignFile({
            organizationId,
            procedureId: procedureId,
            payload,
          })
        );
      });
      const filesAllPromises = Promise.all(filesPromises).then(
        (filesResults) => {
          return filesResults.map((res) => res.data);
        }
      );
      const { filesResults, signatoriesResults } = await Promise.all([
        filesAllPromises,
        signatoriesAllPromises,
      ]).then((results) => {
        return {
          filesResults: results[0],
          signatoriesResults: results[1],
        };
      });

      // create yousign signature per file
      let signaturePromises = [];
      filesResults.forEach(async (file, indexFile) => {
        const { signaturePage } = fileSignaturePosition[indexFile];
        // create signature per signatory
        signatoriesResults.forEach(async (signatory, index) => {
          const signatoryPosition =
            fileSignaturePosition[indexFile]?.signaturePositions[index];
          signaturePromises.push(
            OfferApi.postYouSignMemberAndSignature({
              organizationId,
              procedureType: "signature",
              procedureId: procedureId,
              payload: {
                page_number: signaturePage,
                member: signatory.id,
                file_yousign: file.id,
                ...signatoryPosition,
              },
            })
          );
        });
      });
      return await Promise.all(signaturePromises);
    },

    //#region Offer
    async fetchOfferList({ commit, state }, { organizationId, folderId }) {
      commit("setOfferList", []);

      let page = 1;
      do {
        const promise = OfferApi.getOrganizationFolderOfferList(
          organizationId,
          folderId,
          page
        );
        if (page == 1) {
          commit("setOfferListPromise", promise);
        }
        const response = await promise;
        commit("setOfferList", [...response.data?.results]);
        page = response.data?.next;
      } while (page);
      commit("setOfferListPromise", null);
      return state.offerList;
    },
    async postOffer(_, { organizationId, folderId, payload }) {
      let data = new FormData();
      const { newOffer } = this.state.offer;

      data.append("title", payload.title);
      data.append("description", payload.description);
      data.append("offer_type", payload.offerType);
      data.append("auto_reminder", payload.auto_reminder);
      data.append("is_public", true);

      payload.scheduler && data.append("scheduler", payload.scheduler);
      if (payload.publishDate) data.append("publish_date", payload.publishDate);
      if (payload.offerType == "SIGNATURE") {
        data.append("sequential_signatures", payload.signatures_sequential);
      }
      if (payload.offerType == "MIXTE") {
        const isSigned = newOffer.signatories.some(
          (signatory) => signatory.action === "signer"
        );
        data.append("sequential_signatures", isSigned);
      }
      if (payload.signedFileDatatype)
        data.append("signed_file_datatype", payload.signedFileDatatype);
      const res = await OfferApi.postOrganizationFolderOffer(
        organizationId,
        folderId,
        data
      );

      if (payload.offerType !== "SIGNATURE" && res) {
        const getAction = (action) => {
          if (action === "consulter") return "CONSULT";
          if (action === "valider") return "VALIDATION";
          if (action === "signer") return "SIGNATURE";
        };
        newOffer.signatories.forEach(async (signatory, index) => {
          if (signatory.action !== "signer") {
            const payload = {
              email: signatory.email,
              action: getAction(signatory.action),
              order: index + 1,
            };
            const offerId = res.data.id;

            offerId &&
              (await OfferApi.postOfferClient({
                organizationId,
                folderId,
                offerId,
                payload,
              }));
          }
        });
      }
      return res.data;
    },
    // patch offer
    async patchOffer(
      { commit, state },
      { organizationId, folderId, payload, offerId }
    ) {
      let data = new FormData();

      if (payload.title) data.append("title", payload.title);
      if (payload.description) data.append("description", payload.description);
      if (payload.offerType) data.append("offer_type", payload.offerType);
      if (payload.status) data.append("status", payload.status);
      if (payload.publishDate) data.append("publish_date", payload.publishDate);
      if (payload.reasonDecline)
        data.append("reason_decline", payload.reasonDecline);
      const res = await OfferApi.patchOrganizationFolderOffer(
        organizationId,
        folderId,
        offerId,
        data
      );
      let offerList = [...state.offerList];
      offerList.splice(
        state.offerList.findIndex((offer) => offer.id === offerId),
        1,
        res.data
      );
      commit("setOfferList", [...offerList]);

      return res.data;
    },
    async addClientsToOffer(_, { organizationId, folderId, offerId, payload }) {
      await OfferApi.patchOfferClients({
        organizationId,
        folderId,
        offerId,
        payload,
      });
    },
    async submitOffer(_, { organizationId, folderId, offerId }) {
      const res = await OfferApi.postOrganizationFolderOfferSubmit(
        organizationId,
        folderId,
        offerId
      );
      return res;
    },
    //#endregion
    //#region Offer File
    async deleteOfferFile(
      { commit, state },
      { organizationId, folderId, offerId, offerFileId }
    ) {
      await OfferApi.deleteOrganizationFolderOfferFiles({
        organizationId,
        folderId,
        offerId,
        offerFileId,
      });
      // construct new offer files
      const oldFiles = [...state.activeOffer.files];
      const newOffer = {
        ...state.activeOffer,
        files: oldFiles.filter((file) => file.id !== offerFileId),
      };
      // replace offerList
      let offerList = [...state.offerList];
      offerList.splice(
        state.offerList.findIndex((offer) => offer.id === offerId),
        1,
        newOffer
      );
      commit("setOfferList", [...offerList]);
    },
    async fetchOfferFileList(
      { commit, state },
      { organizationId, folderId, offerId }
    ) {
      let page = 1;
      let payload = new Offer({
        ...state.offerList.find((o) => o.id === offerId),
      });
      do {
        const res = await OfferApi.getOrganizationFolderOfferFileList(
          organizationId,
          folderId,
          offerId,
          page
        );
        const offerFilesPage = res.data.results;
        payload.files = [...payload.files, ...offerFilesPage];
        page = res.data.next;
      } while (page);
      commit("setOfferListOffer", { offerId, payload });
      return state.offerList;
    },
    async fetchOfferMemberAndSignedFiles(
      { commit },
      { organizationId, procedureId, page, offerId }
    ) {
      const memberResponse = await OfferApi.getOrganizationFolderOfferMember(
        organizationId,
        procedureId,
        page
      );
      const fileSignedResponse = await OfferApi.getOrganizationFolderOfferSignedFile(
        organizationId,
        procedureId,
        page
      );
      commit("setOfferMemberAndSignedFile", {
        member: [...memberResponse.data.results],
        signedFiles: [...fileSignedResponse.data.results],
        offerId,
      });
    },
    async getOfferTemplates({ commit }, { organizationId, page = 1 }) {
      const response = await OfferApi.getFolderOfferTemplate(
        organizationId,
        page
      );
      const offers = [
        ...this.state.offer.offerTemplates,
        ...response.data.results,
      ];

      commit("setOfferTemplates", offers);
    },
    async getOfferTemplateDetails(
      { commit },
      { organizationId, page = 1, offerId }
    ) {
      const response = await OfferApi.getFolderOfferTemplateDetails(
        organizationId,
        page,
        offerId
      );
      commit("setOfferTemplateDetails", response.data);
      return response.data;
    },
    async postOfferFile(_, { organizationId, folderId, offerId, payload }) {
      let data = new FormData();
      data.append("name", payload.name);
      data.append("offer_file", payload.offer_file);
      const res = await OfferApi.postOrganizationFolderOfferFile({
        organizationId,
        folderId,
        offerId,
        payload: data,
      });

      return res;
    },
    async patchOfferFile(
      { state, commit },
      { organizationId, folderId, offerId, payload, fileId }
    ) {
      let data = new FormData();
      data.append("name", payload.name);
      if (payload.offer_file) data.append("offer_file", payload);
      const res = await OfferApi.patchOrganizationFolderOfferFile({
        organizationId,
        folderId,
        offerId,
        payload: data,
        fileId,
      });
      const oldFiles = [...state.activeOffer.files];
      oldFiles.splice(
        state.offerList.findIndex((offer) => offer.id === offerId),
        1,
        res.data
      );
      commit("setActiveOffer", { ...state.activeOffer, files: [...oldFiles] });
      return res;
    },
    async fetchWalletOrganizationFolderOfferFileList(
      { commit, state },
      { organizationId, folderId, offerId }
    ) {
      let page = 1;
      do {
        const promise = OfferApi.getOrganizationFolderOfferFileList(
          organizationId,
          folderId,
          offerId,
          page
        );
        const res = await promise;
        const offerFilesPage = res.data.results;
        let payload = new Offer({
          ...state.offerList.find((o) => o.id == offerId),
        });
        payload.files = [...payload.files, ...offerFilesPage];
        commit("setOfferListOffer", { offerId, payload });
        page = res.data.next;
      } while (page);
      return state.offerList;
    },

    async cancelSignatureProcedure(
      { state, commit },
      { organizationId, procedureId, data }
    ) {
      const res = await OfferApi.cancelSignature(
        organizationId,
        procedureId,
        data
      );

      const offerId = res.data.id;
      let offer = new Offer({
        ...state.offerList.find((o) => o.id == offerId),
      });
      offer.status = res.data.status;
      const offerList = state.offerList.map((item) => {
        if (item.id === offer.id) {
          item = { ...offer };
        }
        return item;
      });

      commit("setOfferList", [...offerList]);
    },

    //#endregion
  },
};
