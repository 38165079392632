<template>
  <BaseDialog
    max-width="700"
    :persistent="isSubmiting"
    :value="dialogState"
    @click:outside="close()"
  >
    <template #modal>
      <BaseCosmoCard v-if="signatory">
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('utils.removingSavedSignatory')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="close()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div
            class="alert_danger"
            v-text="
              $t('utils.confirmRemovingSignatory', {
                email: signatory.email,
              })
            "
          />
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="close()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="removeSignatory()"
          >
            {{ $t("utils.delete") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { Signatory } from "@/core/Models/Signatory";

export default {
  name: "SignatoryRemoveDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    signatory: {
      type: Signatory,
      default: () => null,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async removeSignatory() {
      this.isSubmiting = true;
      try {
        await DashboardAPI.deleteOrganizationSignatory({
          organizationId: this.organizationId,
          signatoryId: this.signatory.id,
        });
        this.$emit("signatoryRemoved", this.signatory);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.deleteSuccess"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding: 24px;
  .alert_danger {
    padding: 0 8px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ff5267;
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    margin-left: 16px;
  }
}
</style>
