import { render, staticRenderFns } from "./OrganizationSignatoryList.vue?vue&type=template&id=17b7f486&scoped=true"
import script from "./OrganizationSignatoryList.vue?vue&type=script&lang=js"
export * from "./OrganizationSignatoryList.vue?vue&type=script&lang=js"
import style0 from "./OrganizationSignatoryList.vue?vue&type=style&index=0&id=17b7f486&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b7f486",
  null
  
)

export default component.exports