<template>
  <div class="record_item">
    <BaseAvatar
      :width="56"
      :height="56"
      :src="organizationAvatar || $findStatic('cosmoAvatar')"
      contain
    />
    <div class="record_info">
      <BaseFrontOfficeParagraph
        v-text="record.campaign && record.campaign.name"
      />
      <div class="course_description" v-text="subtitleText" />
    </div>
    <BaseFrontOfficeButton
      @click="goToLink()"
      v-text="
        $t(
          record.agreements.length
            ? 'wallet.assistant.see'
            : 'wallet.assistant.continue'
        )
      "
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WalletAssistantRecord",
  props: {
    record: {
      type: Object,
      required: true,
    },
    organizationAvatar: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      activeWalletFolder: (state) => state.walletCourse.activeWalletFolder,
    }),
    subtitleText() {
      if (!this.record.agreements.length) {
        return `${this.$t("wallet.assistant.startedOn")} ${this.$d(
          new Date(this.record.dateCreated),
          "veryshort"
        )}`;
      }
      return `${this.$t("wallet.assistant.endedOn")} ${this.$d(
        new Date(
          this.record.agreements && this.record.agreements[0]?.dateUpdated
        ),
        "veryshort"
      )}`;
    },
  },
  methods: {
    goToLink() {
      if (this.record.agreements.length) {
        return this.$router.push({
          name: "WalletSafeRecordDetail",
          params: {
            recordId: this.record.id,
          },
        });
      }
      if (
        this.record.campaign.organizationRequired &&
        !this.user.activeOrganization
      ) {
        return this.$router.push({
          path: `/campaign/${this.record.campaign.slug}/welcome/?record_pk=${this.record.id}`,
        });
      }

      return this.$router.push({
        name: "WalletCourse",
        params: {
          recordId: this.record.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.record_item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 24px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 6px 10px rgba(46, 45, 193, 0.06),
    inset 0px 2px 4px rgba(46, 45, 193, 0.1) !important;
  .record_info {
    flex-grow: 1;
    margin: 16px;
    .course_description {
      color: #a3a3ad !important;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
</style>
