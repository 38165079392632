import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";
export default {
  readAllCampaign(organization, status) {
    return cosmoAPI.get(
      "/campaign/" +
        (organization || status ? "?" : "") +
        (organization ? "course__organization=" + organization : "") +
        (organization & status ? "&" : "") +
        (status ? "status=" + status : "")
    );
  },
  getOrganizationCampaigns(organizationId) {
    return cosmoAPI.get(`/organization/${organizationId}/campaign/`);
  },
  getOrganizationCampaingnServices(organizationId) {
    return cosmoAPI.get(`/wallet/organization/${organizationId}/campaign/`);
  },
  postCreateCopyCampaignAnonymousLink({
    organizationId,
    courseId,
    campaignId,
    settings,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/link/`,
      {},
      settings
    );
  },

  postRelaunchInvitationRecordDisagreedFromCampaign({
    organizationId,
    courseId,
    recordId,
    campaignId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/records-disagreed/${recordId}/remind/`,
      payload
    );
  },
  postRelaunchInvitationRecordInProgressFromCampaign({
    organizationId,
    courseId,
    campaignId,
    recordId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/course/${courseId}/campaign/${campaignId}/records-in-progress/${recordId}/remind/`,
      payload
    );
  },
};
