<template>
  <div>
    <BaseFrontOfficeTitle v-text="$t('wallet.signIn.title')" />
    <BaseFrontOfficeDivider type="grey" class="my-4" />
    <v-form>
      <BaseFrontOfficeTextField
        v-model="form.email"
        autofocus
        :tab-index="1"
        :label="`${$tc('utils.email', 1)}*`"
        :error-messages="emailError"
        placeholder="exemple@mail.com"
        @change="$v.form.email.$touch()"
        @blur="$v.form.email.$touch()"
      />
      <BaseFrontOfficeTextField
        v-model="form.password"
        :tab-index="2"
        :label="`${$t('account.password')}*`"
        :type="showPassword ? 'text' : 'password'"
        :error-messages="passwordErrors"
        :append-icon="showPasswordIcon"
        placeholder="•••••••••"
        :autofocus="$route.params.invitedUserEmail ? true : false"
        @click:append="showPassword = !showPassword"
        @change="$v.form.password.$touch()"
        @blur="$v.form.password.$touch()"
        @keydown.enter="submit"
      />
      <BaseFrontOfficeAlert v-if="submitError" type="error">
        {{ submitError }}
      </BaseFrontOfficeAlert>
      <div class="d-flex justify-start mb-4">
        <BaseFrontOfficeHyperlink
          v-if="$route.fullPath.indexOf('/wallet') != -1"
          :to="{
            name: 'WalletPasswordReset',
            query: {
              next: $route.query.next,
            },
            params: {
              accessCampaign: $route.params.accessCampaign,
            },
          }"
        >
          {{ $t("auth.reset.forgottenPassword") }}
        </BaseFrontOfficeHyperlink>
        <BaseFrontOfficeHyperlink
          v-else
          :to="{
            name: 'PasswordReset',
          }"
        >
          {{ $t("auth.reset.forgottenPassword") }}
        </BaseFrontOfficeHyperlink>
      </div>
      <div class="button-container">
        <BaseFrontOfficeButton
          tabindex="3"
          class="ml-auto"
          icon="$mdiCheck"
          :disabled="$v.form.$invalid"
          @click="submit()"
        >
          {{ $t("wallet.signIn.submit") }}
        </BaseFrontOfficeButton>
        <BaseFrontOfficeButton
          class="ml-auto connect"
          tabindex="4"
          @click.prevent="expanded = !expanded"
        >
          <div class="d-flex align-center action">
            <BaseImage
              :src="$findStatic('microsoftLogo')"
              height="24px"
              width="24px"
            />
            <span class="ml-3">
              {{ $t("wallet.signIn.submitMicrosoft") }}
            </span>
            <div>
              <BaseIcon
                :icon="expanded ? '$mdiChevronUp' : '$mdiChevronDown'"
                color="white"
              />
            </div>
            <div v-show="expanded && SSOLists.length" class="menu__sso">
              <div
                v-for="item in SSOLists"
                :key="item.aadSlug"
                class="list__item"
                @click="connectMicrocrosft(item.aadUrlAuthorize)"
              >
                {{ item.aadSlug }}
              </div>
            </div>
          </div>
        </BaseFrontOfficeButton>
      </div>
      <WalletHasToRegisterVue />
    </v-form>
  </div>
</template>

<script>
import WalletHasToRegisterVue from "../../Components/WalletHasToRegister.vue";
import { getToken } from "@/core/Api/cosmoAxiosInstance";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { WelcomeActionStatus } from "@/core/Utils/types.utils";
import RecordApi from "@/modules/Wallet/Services/record.api";
const errorCodes = {
  ORGANISATION_NOT_AUTHORIZED: "organization-not-authorized-for-sso",
  NOT_USER_COSMO: "user-not-found-in-cosmo",
  NOT_REGISTERED: "user-not-registered-yet-in-cosmo",
  ERROR_OAUTH: "oauth-application-not-found",
};

export default {
  name: "WalletLogin",
  components: { WalletHasToRegisterVue },
  mixins: [validationMixin],
  props: {
    accessCampaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
      showPassword: false,
      submitError: "",
      expanded: false,
      SSOLists: [],
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    // password icon
    showPasswordIcon() {
      if (this.form.password === "") return "";
      return this.showPassword ? "$mdiEye" : "$mdiEyeOff";
    },
    ...mapState({
      activeUser: (state) => state.account.user,
    }),
    //#region Form errors
    emailError() {
      if (!this.$v.form.email.$dirty) {
        return "";
      }
      if (!this.$v.form.email.required) {
        return this.$t("wallet.signup.errors.emailRequired");
      }
      if (!this.$v.form.email.email) {
        return this.$t("folder.askInformationForm.validation.emailInvalid");
      }
      return "";
    },
    passwordErrors() {
      if (!this.$v.form.password.$dirty) {
        return "";
      }
      if (!this.$v.form.password.required) {
        return this.$t("wallet.signup.errors.passwordRequired");
      }
      return "";
    },
    //#endregion
  },
  watch: {
    "$route.query.sso_error": {
      handler() {
        if (!this.$route.query.sso_error) return;
        let msg = "";
        switch (this.$route.query.sso_error) {
          case errorCodes.ERROR_OAUTH:
            msg = this.$t("utils.sso.oauthError");
            break;
          case errorCodes.NOT_REGISTERED:
            msg = this.$t("utils.sso.userNonregistered");
            break;
          case errorCodes.NOT_USER_COSMO:
            msg = this.$t("utils.sso.nonUser");
            break;
          case errorCodes.ORGANISATION_NOT_AUTHORIZED:
            msg = this.$t("utils.sso.organizationNonAuthorized");
            break;
        }
        this.$store.dispatch("snackbar/active", {
          message: msg,
          type: "Error",
          needClose: false,
        });
      },
      immediate: true,
    },
  },
  created() {
    this.initialize();
    if (this.$route.params.invitedUserEmail) {
      this.form.email = this.$route.params.invitedUserEmail;
    } else if (this.$route.query?.email) {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    async initialize() {
      this.SSOLists = await this.$store.dispatch("organization/getSSOLists");
    },
    connectMicrocrosft(url) {
      open(url);
    },
    async createRecord() {
      if (this.$route.params.recordId) {
        // we dont create a record if pk record id
        return this.$router.push({
          name: "WalletCourse",
          params: {
            recordId: this.$route.params.recordId,
          },
        });
      }
      const res = await RecordApi.postRecord(
        this.$route.params.accessCampaign.campaign.slug
      );
      const record = res.data;
      this.$router.push({
        name: "WalletCourse",
        params: {
          recordId: record.id,
        },
      });
    },
    async submit() {
      this.$v.form.$touch();
      this.submitError = "";
      if (this.$v.form.$invalid) {
        return;
      }
      this.isLoading = true;
      try {
        await getToken(this.form.email, this.form.password);
        await this.$store.dispatch("account/fetchUser");

        this.$store.commit("core/SET_HAS_LOGGED_NORMALY", true);
        if (/^\/auth/.test(this.$route.fullPath)) {
          return this.$router.push({
            name: "dashboard",
          });
        }

        // LOGIN FROM CAMPAIGN LINK
        if (this.$route.params.accessCampaign) {
          // We need to get the latest access campaign
          const res = await this.$store.dispatch(
            "walletCourse/getAccessCampaign",
            {
              campaignSlug: this.$route.params.accessCampaign.campaign.slug,
              recordId: this.$route.params.recordId,
            }
          );

          switch (res.data.action) {
            case WelcomeActionStatus.CreateRecord:
              await this.createRecord();
              return;
            case WelcomeActionStatus.Answer:
              this.$router.push({
                name: "WalletCourse",
                params: {
                  recordId: this.$route.params.recordId,
                },
              });
              return;
          }
        }
        if (this.$route.query.next) {
          this.$router.push(this.$route.query.next);
          return;
        }

        this.$router.push({ name: "WalletHome" });
      } catch (error) {
        if (error.response.data.error === "invalid_grant") {
          this.submitError = this.$t("wallet.signIn.errorInvalidGrant");
        }
        if (error?.response) {
          this.$store.dispatch("snackbar/active", {
            message: error.response.data.error_description,
            type: "ERROR",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.action {
  gap: 16px;
  padding: 13px 24px;
}
.connect {
  position: relative;
  .menu__sso {
    position: absolute;
    width: 190px;
    padding: 8px;
    background: #f4f7ff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    right: 0;
    bottom: 0;
    transform: translate(16px, calc(100% + 4px));
    .list__item {
      height: 32px;
      padding: 3px 8px;
      color: var(--Light---primary-text-color, #323338);
      /* Bouton/Base */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 4px;
      display: flex;
      align-items: center;
    }
  }
  .list__item:hover {
    background: #eeeef7;
  }
}
</style>
