<template>
  <BaseModal
    :value="isOpen"
    :modal-title="
      !activeCourse
        ? $t('course.createDialogTitle')
        : $t('course.editDialogTitle')
    "
    is-complex
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <div>
        <BaseTextField
          v-model="name"
          autofocus
          :error-messages="nameErrors"
          :has-error="nameErrors.length > 0"
          :label="`${$t('course.forms.create.nameLabel')}*`"
          color="primary"
        />
        <BaseMarkdownEditor
          v-model="description"
          :label="$t('course.forms.create.descriptionLabel')"
          :placeholder="$t('course.forms.create.descriptionPlaceholder')"
          :with-link="false"
        />
        <v-switch
          v-model="anonymous"
          :disabled="!!activeCourse"
          :label="$t('course.forms.create.anonymousLabel')"
          inset
          hide-details
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="
          () => {
            $emit('close');
            clear();
          }
        "
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        color="primary"
        type="primary"
        :loading="submiting"
        :submit="true"
        @click="submit"
      >
        {{ !activeCourse ? $t("course.create") : $t("utils.edit") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "CourseCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      anonymous: false,
      description: "",
      name: "",
      submiting: false,
    };
  },

  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
  },
  computed: {
    ...mapState({
      activeCourse: (state) => state.course.course,
    }),
    nameErrors() {
      let errors = "";
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required &&
        (errors = this.$t("course.forms.create.nameRequired"));
      !this.$v.name.maxLength &&
        (errors = this.$t("course.forms.create.nameMaxLength", { n: 100 }));
      return errors;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.setCourse();
      }
    },
  },
  methods: {
    clear() {
      this.$v.$reset();
      this.name = "";
      this.description = "";
      this.anonymous = false;
    },
    setCourse() {
      if (this.activeCourse) {
        this.anonymous = this.activeCourse.anonymous;
        this.description = this.activeCourse.description;
        this.name = this.activeCourse.name;
      }
    },
    async submit() {
      this.$v.name.$touch();
      if (this.$v.$invalid) return;
      this.submiting = true;
      if (!this.activeCourse) {
        await this.createCourse();
      } else {
        await this.editCourse();
      }
      this.submiting = false;
      this.$emit("close");
      this.clear();
    },
    async createCourse() {
      try {
        const course = await this.$store.dispatch("course/createCourse", {
          organizationId: this.$route.params.organizationId,
          payload: {
            name: this.name,
            description: this.description,
            anonymous: this.anonymous,
          },
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.createConfirmation"),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "courseDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            courseId: course.id,
          },
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async editCourse() {
      try {
        await this.$store.dispatch("course/editCourse", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          payload: {
            name: this.name,
            description: this.description,
            anonymous: this.anonymous,
          },
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.editConfirmation"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isEditingCourse = false;
    },
  },
};
</script>
