<template>
  <div class="cb_container">
    <div v-if="!hideAvatar" class="chat_bot_img">
      <img :src="avatarSource" />
    </div>
    <div>
      <div v-if="loading" class="chat_bot_message wave">
        <span v-for="i in 3" :key="i" class="wave_dot" />
      </div>
      <template v-else>
        <div class="chat_bot_message">
          <BaseFrontOfficeParagraph>
            {{ questionLabel }}
          </BaseFrontOfficeParagraph>
          <BaseFrontOfficeImage
            v-if="messageImage"
            :src="messageImage"
            min-height="100px"
            width="300px"
          />
        </div>
        <div class="help_text">
          <div
            v-for="(help, i) in helpText"
            :key="`${i}-helpText`"
            class="value"
            v-text="help"
          />
        </div>
        <a v-if="helpFile" :href="helpFile" target="_blank" class="help_file">
          {{ $t("course.viewHelpFile") }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import Question from "@/core/Models/Question";

export default {
  name: "WalletCourseChatBot",
  props: {
    question: {
      type: Question,
      default: () => null,
    },
    loading: {
      type: Boolean,
    },
    hideAvatar: {
      type: Boolean,
    },
    hideHelpText: {
      type: Boolean,
    },
    avatar: {
      type: String,
      default: "",
    },
  },
  computed: {
    questionLabel() {
      if (!this.question) return "";
      return this.question.required
        ? this.question.label + "*"
        : this.question.label;
    },
    messageImage() {
      if (!this.question) return "";
      if (this.question.typeQuestion === "MESSAGE" && this.question.image)
        return this.question.image;
      return "";
    },
    helpFile() {
      if (!this.question || this.hideHelpText) return "";
      return this.question.helpFile;
    },
    helpText() {
      if (!this.question?.helpText || this.hideHelpText) return [];
      return this.question.helpText.split("\n");
    },
    avatarSource() {
      return this.avatar || this.$findStatic("cosmoAvatar");
    },
  },
};
</script>

<style lang="scss" scoped>
.cb_container {
  display: flex;
  align-items: flex-start;
  font-family: "Nunito";
  .chat_bot_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
    flex: none;
    & > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .chat_bot_message {
    background-color: #f7f7f7;
    border-radius: 0px 20px 20px 20px;
    padding: 16px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
    color: #4f4f4f;
    &.wave {
      .wave_dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 3px;
        background: #4f4f4f;
        animation: wave 1.3s linear infinite;
      }
      .wave_dot:nth-child(2) {
        animation-delay: -1.1s;
      }

      .wave_dot:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }
  .help_text {
    display: flex;
    flex-direction: column;
    .value {
      color: var(--v-walletGrey-base);
      font-size: 14px;
      line-height: 18px;
      font-family: var(--cosmo-font-nunito);
    }
  }
  .help_file {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2536cc;
  }
}
@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-10px);
  }
}
</style>
