import ClientAPI from "./client.api";

export function getClients(organizationId, page) {
  return ClientAPI.getOrganizationClients(organizationId, page);
}

export function getClientDetails(organizationId, clientId) {
  return ClientAPI.getClientDetails(organizationId, clientId);
}

export function getOrganizationClientRecords(
  organizationId,
  clientId,
  page = 1
) {
  return ClientAPI.getOrganizationClientRecords(organizationId, clientId, page);
}

export function askInformation(organizationId, clientId, payload) {
  return ClientAPI.askInformation(organizationId, clientId, payload);
}

export function fetchOrganizationClientLogs(organizationId, clientId) {
  return ClientAPI.getOrganizationClientLogs(organizationId, clientId);
}
export function postRelaunchInvitationRecordFromClient({
  organizationId,
  clientId,
  recordId,
  payload,
}) {
  return ClientAPI.postRelaunchInvitationRecordFromClient({
    organizationId,
    clientId,
    recordId,
    payload,
  });
}
