import Question from "@/core/Models/Question";
import CosmoformAPI from "./cosmoform.api";

export function getCosmoforms(token) {
  return CosmoformAPI.getCosmoforms(token);
}
export function getCosmoformOrganization(token) {
  return CosmoformAPI.getCosmoformOrganization(token);
}

/**
 * Return cosmoformDetail.
 *
 * @param {number} organizationId organizationId.
 * @param {number} folderId folderId.
 * @return {Array<Question>}
 */
export async function getCosmoformDetail(organizationId, folderId) {
  const res = await CosmoformAPI.getCosmoformDetail(organizationId, folderId);
  const cosmoformDetail = [];
  if (res) {
    res.data.results.forEach((detail) => {
      cosmoformDetail.push(
        new Question({
          ...detail.question,
          category: detail.question.category,
          answer: detail.answer ? detail.answer : null,
          hasFreeOption: detail.has_free_option,
        })
      );
    });
  }
  return cosmoformDetail;
}

export async function submitCosmoformAnswer({ token, cosmoforms }) {
  return new Promise((resolve) => {
    const answerPromise = [];
    for (const cosmoform of cosmoforms) {
      if (cosmoform.answer && !cosmoform.question.readOnly) {
        let setContentValue;
        if (
          ["FILE", "MULTIPLE_FILE"].includes(cosmoform.question.typeQuestion)
        ) {
          setContentValue = cosmoform.answer.content.map((file) => {
            return {
              name: file.name,
              encoded_file: file.url ? file.url : file.encoded_file,
            };
          });
        } else if (cosmoform.question.typeQuestion === "SIGNATURE") {
          setContentValue = [
            {
              name: "Fichier.png",
              encoded_file: cosmoform.answer.content,
            },
          ];
        } else if (["RADIOBUTTON"].includes(cosmoform.question.typeQuestion)) {
          if (
            cosmoform.question.hasFreeOption &&
            cosmoform.answer.freeOptionContent
          ) {
            const _temp = [];
            _temp.push(`free__${cosmoform.answer.freeOptionContent}`);
            setContentValue = _temp;
          } else
            setContentValue = cosmoform.answer.choices
              ? cosmoform.answer.choices
              : [];
        } else if (
          ["SELECT", "CHECKBOX"].includes(cosmoform.question.typeQuestion)
        ) {
          setContentValue = cosmoform.answer.choices
            ? cosmoform.answer.choices
            : [];
        } else {
          setContentValue = cosmoform.answer.content
            ? [cosmoform.answer.content]
            : [];
        }
        const postPromise = CosmoformAPI.postAnsweredCosmoform({
          token,
          questionId: cosmoform.question.id,
          payload: { set_content: setContentValue },
        });
        answerPromise.push(postPromise);
      }
    }

    Promise.all(answerPromise).then(async () => {
      resolve(
        (await CosmoformAPI.postExpiredCosmoformToken({ token }))?.data
          ?.folderId
      );
    });
  });
}
