<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiDotsVertical } from "@mdi/js";
export default {
  name: "MdiDotsVertical",
  data() {
    return {
      svgPath: mdiDotsVertical,
    };
  },
};
</script>
