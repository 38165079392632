<template>
  <div>
    <BaseFrontOfficeWalletWrapper
      small
      :mobile-title-image="$findStatic('walletAuthLogin')"
      go-back-active
      :go-back-path="{ name: 'WalletAccount' }"
    >
      <template #title>
        <BaseFrontOfficeTitle v-text="$t('client.account.deleteTitle')" />
      </template>
      <BaseFrontOfficeBoard>
        <div>
          <BaseFrontOfficeSubtitle
            class="title"
            v-text="`${$t('account.password')}*`"
          />
          <BaseFrontOfficeTextField
            v-model="$v.password.$model"
            reference="oldPasswordRef"
            autofocus
            :type="showPassword ? 'text' : 'password'"
            :error-messages="oldPasswordErrors"
            :append-icon="showPasswordIcon"
            @click:append="showPassword = !showPassword"
            @change="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <v-checkbox
            v-model="$v.valid.$model"
            :label="`${$t('account.confirmDeleteAccount')}`"
            @change="$v.valid.$touch()"
            @blur="$v.valid.$touch()"
          />
        </div>
        <div>
          <BaseFrontOfficeButton
            min-height="45"
            style="width: 100%"
            :disabled="$v.$invalid || !valid"
            @click="submitDeleteAccount"
            v-text="$t('utils.validate')"
          />
        </div>
      </BaseFrontOfficeBoard>
    </BaseFrontOfficeWalletWrapper>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import { deleteUserAccount } from "../../../Authentication/Services/authentication.service";

export default {
  name: "WalletDeleteAccount",
  mixins: [validationMixin],

  data() {
    return {
      password: "",
      valid: false,
      validationState: true,
      showPassword: false,
    };
  },
  computed: {
    viewWidth() {
      return innerWidth;
    },
    // password icon
    showPasswordIcon() {
      if (this.password === "") return "";
      return this.showPassword ? "$mdiEye" : "$mdiEyeOff";
    },
    // password errors
    oldPasswordErrors() {
      if (!this.$v.password.$dirty) return [];
      let errors = [];
      if (!this.$v.password.required) {
        errors = [
          ...errors,
          this.$t("account.editForm.errors.passwordRequiredToDeleteAccount"),
        ];
      }
      return errors;
    },
  },
  validations: {
    password: {
      required,
    },
    valid: {
      required,
      sameAs: sameAs("validationState"),
    },
  },
  methods: {
    async submitDeleteAccount() {
      try {
        await deleteUserAccount(this.password);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.deleteForm.accountDeletetion"),
          type: "SUCCESS",
        });
        location.replace("/wallet/auth/login/");
      } catch (error) {
        if (
          error.response.data.password &&
          error.response.data.password[0] === "Mot de passe invalide"
        ) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("client.account.wrongPassword"),
            type: "ERROR",
          });
        }
      }
    },
  },
};
</script>
