var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offer-file",class:{ 'wallet-file': _vm.isWallet, 'cosmo-file': _vm.isCosmoForm }},[(_vm.needIteration && !_vm.isWallet && _vm.nature === 'secondary')?_c('div',{staticClass:"input-label",domProps:{"textContent":_vm._s(((_vm.question && _vm.question.name) + " " + (_vm.indexFile + 1)))}}):_vm._e(),_c('div',{staticClass:"offer-file-name"},[(!_vm.isWallet)?_c('base-icon',{attrs:{"icon":"$mdiFileDocument","medium":"","color":_vm.isDisabled ? '#666666' : '#9BBDFF'}}):_vm._e(),(_vm.isEditing)?_c('v-text-field',{staticClass:"input-name",attrs:{"single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
          _vm.isEditing = !_vm.isEditing;
          if (!_vm.isEditing) {
            _vm.$emit('file-name-changed', { value: _vm.fileName, index: _vm.indexFile });
          }
        }).apply(null, arguments)}},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}}):_c('div',{staticClass:"label mr-4",class:{ 'disabled-label': _vm.isDisabled, 'not-hoverable': _vm.nonEditable },domProps:{"textContent":_vm._s(_vm.fileName)},on:{"dblclick":_vm.enableEdit}}),(!_vm.isDisabled && !_vm.isWallet)?_c('base-button',{staticClass:"btn-edit",attrs:{"height":"24","outlined":""},on:{"click":function($event){$event.stopPropagation();return (function () {
          _vm.isEditing = !_vm.isEditing;
          if (!_vm.isEditing) {
            _vm.$emit('file-name-changed', { value: _vm.fileName, index: _vm.indexFile });
          }
        }).apply(null, arguments)}}},[_c('base-icon',{staticClass:"mr-2",attrs:{"icon":"$mdiPencilOutline","size":"16px"}}),_c('label',{domProps:{"textContent":_vm._s(_vm.isEditing ? 'Valider' : 'Editer')}})],1):_vm._e()],1),(!_vm.isDisabled)?_c('div',{staticClass:"icon-container"},[_c('base-icon',{staticClass:"icon",attrs:{"icon":"$mdiEye","medium":"","color":"#707080"},on:{"click":_vm.open}}),_c('base-icon',{staticClass:"icon",attrs:{"icon":"$mdiClose","medium":"","color":"#707080"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(_vm.indexFile)}}})],1):_vm._e(),_c('folder-file-confirm-delete-dialog',{attrs:{"dialog-state":_vm.dialogState,"file-to-delete":_vm.fileToDelete},on:{"delete":function($event){return _vm.$emit('delete-offer-file', _vm.indexToDelete)},"close":function($event){_vm.dialogState = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }