<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('utils.sentToGed')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="d-flex align-center justify-center">
        <div class="reference-container">
          <span class="reference mr-1" v-text="referenceQuestion" />
          <span class="danger-text" v-text="$t('utils.noContent')" />
        </div>
      </div>
    </template>
    <template #actions>
      <BaseButton class="ml-auto" type="primary" @click="$emit('close')">
        {{ $t("navigation.close") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "FolderSendToGedWarningDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    referenceQuestion() {
      return `${this.activeOrganization?.enoxa?.reference?.name} :`;
    },
  },
};
</script>

<style lang="scss" scoped>
.reference-container {
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 42px;
  width: 100%;
  gap: 16px;
  background: #eaecf0;
  border-radius: 5px;
  border: solid 1px #707080;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  .reference {
    color: #353542;
  }
  .danger-text {
    color: #ff5267;
  }
}
</style>
