import { render, staticRenderFns } from "./WorkflowStatusTargetStatusCreateAndEditDialog.vue?vue&type=template&id=498f7582&scoped=true"
import script from "./WorkflowStatusTargetStatusCreateAndEditDialog.vue?vue&type=script&lang=js"
export * from "./WorkflowStatusTargetStatusCreateAndEditDialog.vue?vue&type=script&lang=js"
import style0 from "./WorkflowStatusTargetStatusCreateAndEditDialog.vue?vue&type=style&index=0&id=498f7582&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498f7582",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
