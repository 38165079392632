<template>
  <div class="text-description" :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseDescription",
  props: {
    bold: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: () => "primary",
      validator: function (value) {
        return ["primary", "secondary", "disabled"].indexOf(value) !== -1;
      },
    },
    underline: {
      type: Boolean,
    },
    uppercase: {
      type: Boolean,
    },
    italic: {
      type: Boolean,
    },
    truncate: {
      type: Boolean,
    },
    normalSize: {
      type: Boolean,
    },
  },
  computed: {
    classObject() {
      return {
        "text-uppercase": this.uppercase,
        [`${this.color ? this.color + "--text" : "text--" + this.text}`]: true,
        "font-italic": this.italic,
        "text-description--bold": this.bold,
        "text-truncate d-block": this.truncate,
        "text-description-normal": this.normalSize,
        "text-underline": this.underline,
      };
    },
  },
};
</script>
<style scoped>
.text-description {
  font-size: 0.75rem !important;
  font-weight: 400;
}
.text-description--bold {
  font-weight: 700;
}
.text-description-normal {
  font-family: "Inter";
  font-size: 1rem !important;
  font-weight: 500 !important;

}
.text-underline {
  text-decoration: underline;
}
</style>
