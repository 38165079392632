import Organization from "./Organization";

export default class Log {
  constructor({
    id = Number.NaN,
    objectId = Number.NaN,
    organization = Number.NaN,
    action = "",
    actionDisplay = "",
    contentType = Number.NaN,
    data = null,
    dateCreated = new Date(),
    dateUpdated = new Date(),
  } = {}) {
    this.id = Number.parseInt(id);
    this.objectId = Number.parseInt(objectId);
    this.organization = organization
      ? new Organization({ id: organization })
      : new Organization();
    this.action = action;
    this.actionDisplay = actionDisplay;
    this.contentType = Number.parseInt(contentType);
    this.data = data;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
  }
}
