export default class Option {
  constructor({
    id = Number.NaN,
    order = Number.NaN,
    name = "",
    slug = "",
  } = {}) {
    this.id = Number.parseInt(id);
    this.order = order;
    this.name = name;
    this.slug = slug;
  }
}
