<template>
  <div>
    <BaseTitle class="mb-6" v-text="$tc('organization.folder', 1)" />
    <Board>
      <template #header class="mb-6">
        <div class="header">
          <div class="header_left">
            <FolderCreateAndEditDialog />
          </div>
          <div class="header_center">
            <div v-if="activeFilters.length" class="filters">
              <div class="filter_icon">
                <BaseIcon icon="$mdiFilterOutline" />
              </div>
              <div
                v-for="(filter, i) in activeFilters"
                :key="`f-${i}`"
                class="filter_item"
              >
                <div v-if="filter.prependIcon" class="filter_item_icon">
                  <BaseIcon :icon="filter.prependIcon" />
                </div>
                <div class="filter_item_label">
                  {{ filter.label }}
                </div>
                <div class="close_icon" @click="removeFilter(filter)">
                  <BaseIcon icon="$mdiClose" />
                </div>
              </div>
            </div>
          </div>
          <div class="header_right">
            <v-text-field
              v-model="queryString"
              outlined
              :label="$t('folder.search')"
              prepend-inner-icon="$mdiMagnify"
              dense
              hide-details
              @input="searchFolderWithQueryString()"
            />
          </div>
        </div>
      </template>
      <template #main>
        <v-tabs v-model="activeTab" class="tab_header">
          <v-tab class="tab_header_item" @click="changeTab('OPEN')">
            <BaseIcon icon="$mdiCheck" small class="pr-3" />
            <span>{{ folderActiveTabLabel }}</span>
          </v-tab>
          <v-tab class="tab_header_item" @click="changeTab('CLOSED')">
            <BaseIcon icon="$mdiArchive" small class="pr-3" />
            <span>{{ folderArchivedTabLabel }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" class="tab_items">
          <v-tab-item>
            <FolderListTable
              v-model="filters"
              :folder-detail="activeFolder.detail"
              :loading="activeFolder.loading"
              :organization-id="organizationId"
              need-contact
              @inputValue="handleInputFilter()"
              @changeValue="handleChangeFilter('OPEN')"
            />
          </v-tab-item>
          <v-tab-item>
            <FolderListTable
              v-model="filters"
              :folder-detail="archivedFolder.detail"
              :loading="archivedFolder.loading"
              :organization-id="organizationId"
              need-contact
              @inputValue="handleInputFilter()"
              @changeValue="handleChangeFilter('CLOSED')"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </Board>
  </div>
</template>
<script>
import Board from "@/core/Components/Board";
import FolderCreateAndEditDialog from "../Components/FolderCreateAndEditDialog";
import FolderListTable from "../Components/FolderListTable";
import { findOrganizationFolders } from "../Services/folder.service";
import Folder from "@/core/Models/Folder";
export default {
  name: "FolderList",
  components: {
    Board,
    FolderCreateAndEditDialog,
    FolderListTable,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      activeFolder: {
        loading: true,
        detail: {
          count: 0,
          next: 0,
          previous: 0,
          folders: [],
        },
      },
      filters: {
        pageSize: 10,
        page: 1,
        ordering: "",
        q: "",
        name: "",
        status: [],
        assign: [],
        workflow: [],
        client: [],
        activeFolderPage: 1,
        archivedFolderPage: 1,
      },
      archivedFolder: {
        loading: true,
        detail: {
          count: 0,
          next: 0,
          previous: 0,
          folders: [],
        },
      },
      queryString: "",
      searchTimeoutRef: null,
    };
  },
  computed: {
    folderActiveTabLabel() {
      const count = this.activeFolder.detail.count;
      return `${this.$tc("utils.activeFolder", count ? count : 1)} (${count})`;
    },
    folderArchivedTabLabel() {
      const count = this.archivedFolder.detail.count;
      return `${this.$tc(
        "utils.archivedFolders",
        count ? count : 1
      )} (${count})`;
    },
    activeFilters() {
      const filters = [];
      if (this.filters.ordering) {
        const orderFilter = {
          prependIcon: this.filters.ordering.startsWith("-")
            ? "$mdiArrowDown"
            : "$mdiArrowUp",
          label: "",
          key: "ordering",
        };
        switch (this.filters.ordering) {
          case "name":
          case "-name":
            orderFilter.label = "Dossier";
            break;
          case "client__email":
          case "-client__email":
            orderFilter.label = "Contact";
            break;
          case "workflow__name":
          case "-workflow__name":
            orderFilter.label = "Workflow";
            break;
          case "status__name":
          case "-status__name":
            orderFilter.label = "Status";
            break;
          case "assign__email":
          case "-assign__email":
            orderFilter.label = "Collaborateur";
            break;
          case "date_updated":
          case "-date_updated":
            orderFilter.label = "Date de modification";
            break;
        }
        filters.push(orderFilter);
      }
      if (this.filters.q) {
        filters.push({
          label: `Dossier : ${this.filters.q}`,
          key: "q",
        });
      }
      if (this.filters.name) {
        filters.push({
          label: `Dossier : ${this.filters.name}`,
          key: "name",
        });
      }
      if (this.filters.client?.length) {
        filters.push({
          label: `${this.filters.client?.length} Contact${
            this.filters.client?.length > 1 ? "s" : ""
          } : ${this.filters.client[0]}${
            this.filters.client.length > 1 ? ".." : ""
          }`,
          key: "client",
        });
      }
      if (this.filters.workflow?.length) {
        filters.push({
          label: `${this.filters.workflow?.length} Workflow${
            this.filters.workflow?.length > 1 ? "s" : ""
          } : ${this.filters.workflow[0]}${
            this.filters.workflow.length > 1 ? ".." : ""
          }`,
          key: "workflow",
        });
      }
      if (this.filters.status?.length) {
        filters.push({
          label: `${this.filters.status?.length} Statut${
            this.filters.status?.length > 1 ? "s" : ""
          } : ${this.filters.status[0]}${
            this.filters.status.length > 1 ? ".." : ""
          }`,
          key: "status",
        });
      }
      if (this.filters.assign?.length) {
        filters.push({
          label: `${this.filters.assign?.length} Collaborateur${
            this.filters.assign?.length > 1 ? "s" : ""
          } : ${this.filters.assign[0]}${
            this.filters.assign.length > 1 ? ".." : ""
          }`,
          key: "assign",
        });
      }
      return filters;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.$route.query.status && this.$route.query.workflow)
        this.initFiltersByRouteQuery();
      else this.initFilters(this.organizationId);
      this.fetchFolders();
    },
    initFiltersByRouteQuery() {
      if (this.$route.query.status && this.$route.query.workflow) {
        this.filters.status.push(this.$route.query.status);
        this.filters.workflow.push(this.$route.query.workflow);
      }
    },
    initFilters(organizationId) {
      let organizationsFilters = localStorage.getItem("organizationsFilters");
      if (!organizationsFilters || !organizationsFilters.length) return;
      organizationsFilters = JSON.parse(organizationsFilters);
      const tmpFilters = organizationsFilters.find(
        (of) => of.organizationId == organizationId
      );
      if (!tmpFilters?.filters) return;
      this.filters = tmpFilters.filters;
    },
    saveFilters(organizationId) {
      let organizationsFilters = localStorage.getItem("organizationsFilters");
      const newFilters = {
        organizationId: organizationId,
        filters: this.filters,
      };
      if (!organizationsFilters || !organizationsFilters.length)
        organizationsFilters = [newFilters];
      else {
        organizationsFilters = JSON.parse(organizationsFilters);
        if (
          organizationsFilters.some((of) => of.organizationId == organizationId)
        )
          organizationsFilters = organizationsFilters.map((of) =>
            of.organizationId == organizationId ? newFilters : of
          );
        else organizationsFilters.push(newFilters);
      }
      localStorage.setItem(
        "organizationsFilters",
        JSON.stringify(organizationsFilters)
      );
    },
    async fetchFolders(saveFilter = true) {
      try {
        if (saveFilter) this.saveFilters(this.organizationId);
        await Promise.all([
          this.fetchActiveFolders(),
          this.fetchArchivedFolders(),
        ]);
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async fetchActiveFolders() {
      this.activeFolder.loading = true;
      const res = await findOrganizationFolders({
        organizationId: this.organizationId,
        filters: {
          ...this.filters,
          page: this.filters.activeFolderPage
            ? this.filters.activeFolderPage
            : 1,
          state: "OPEN",
        },
      });
      this.activeFolder.detail = {
        ...res.data,
        folders: res.data.results.map((f) => {
          const res = new Folder(f);
          return res;
        }),
      };
      this.activeFolder.loading = false;
    },
    async fetchArchivedFolders() {
      this.archivedFolder.loading = true;
      const res = await findOrganizationFolders({
        organizationId: this.organizationId,
        filters: {
          ...this.filters,
          page: this.filters.archivedFolderPage
            ? this.filters.archivedFolderPage
            : 1,
          state: "CLOSED",
        },
      });
      this.archivedFolder.detail = {
        ...res.data,
        folders: res.data.results.map((f) => {
          const res = new Folder(f);
          return res;
        }),
      };
      this.archivedFolder.loading = false;
    },
    removeFilter(filter) {
      if (filter.key == "q") this.queryString = "";
      this.filters[filter.key] = Array.isArray(this.filters[filter.key])
        ? []
        : "";
      if (
        (this.$route.query.status || this.$route.query.workflow) &&
        ["status", "workflow"].includes(filter.key)
      ) {
        this.saveFilters(this.organizationId);
        this.$router.push({
          name: "folderList",
          params: { organizationId: this.organizationId },
        });
        return;
      }
      this.fetchFolders();
    },
    handleInputFilter() {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        this.fetchFolders();
      }, 500);
    },
    handleChangeFilter(folderState) {
      switch (folderState) {
        case "OPEN":
          this.filters.activeFolderPage = this.filters.page;
          break;
        case "CLOSED":
          this.filters.archivedFolderPage = this.filters.page;
          break;
      }
      this.fetchFolders();
    },
    searchFolderWithQueryString() {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        this.filters.page = 1;
        this.filters.activeFolderPage = 1;
        this.filters.archivedFolderPage = 1;
        this.filters.q = this.queryString;
        this.fetchFolders();
      }, 500);
    },
    changeTab(tab) {
      this.activeTabLabel = tab;
      switch (tab) {
        case "OPEN":
          this.filters.page = this.filters.activeFolderPage;
          break;
        case "CLOSED":
          this.filters.page = this.filters.archivedFolderPage;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  .header_left {
    width: 167px;
    flex: none;
  }
  .header_right {
    width: 250px;
    flex: none;
  }
  .header_center {
    width: calc(100% - 417px);
    overflow: hidden;
  }
  .filters {
    display: flex;
    padding: 0 24px;
    align-items: center;
    .filter_icon {
      flex: none;
      background: #f4f7ff;
      border-radius: 9px;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::v-deep {
        .v-icon {
          height: 20px;
          width: 20px;
          color: #242533;
        }
      }
    }

    .filter_item {
      border-radius: 36px;
      background: #f4f7ff;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      margin-left: 8px;
      .filter_item_icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
            color: #707080;
          }
        }
      }
      .filter_item_label {
        color: #242533;
        font-family: "Inter";
        font-size: 12px;
        line-height: 20px;
        font-style: normal;
        font-weight: 500;
      }
      .close_icon {
        width: 18px;
        height: 18px;
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
            color: #2536cc;
          }
        }
      }
    }
  }
}
.tab_items {
  overflow: visible;
}
</style>
