<template>
  <BaseDialog
    max-width="600"
    :value="dialogState"
    @click:outside="$emit('close')"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('folder.logs.history')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="$emit('close')"
            />
          </div>
        </template>
        <div v-if="offerClient" class="modal_content">
          <BaseDataTable
            :headers="headers"
            :items="offerClient.reminders"
            :items-per-page="15"
            class="mb-4 elevation-1"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <div class="log_item">
                    <div
                      class="log_label"
                      v-text="
                        `${$d(new Date(item.dateCreated), 'veryshort')} ${$d(
                          new Date(item.dateCreated),
                          'time'
                        )}`
                      "
                    />
                    <div class="log_label">
                      <span v-text="`${$t('utils.sentTo')} : `" />
                      <span class="strong" v-text="item.collaborator.email" />
                      (<span
                        class="ml-1"
                        v-text="item.collaborator.firstname"
                      />
                      <span class="ml-1" v-text="item.collaborator.lastname" />)
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </BaseDataTable>
        </div>
        <div class="modal_footer">
          <BaseButton olor="primary" type="primary" @click="$emit('close')">
            {{ $t("utils.back") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  name: "FolderEmailRelaunchLogDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    offerClient: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("utils.sendDate"),
          value: "dateCreated",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .log_item {
    padding: 16px 0;
    .log_label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #707080;
      .strong {
        color: #353542;
        text-decoration: underline;
      }
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    margin-left: 16px;
  }
}
</style>
