<template>
  <div class="record_item_container">
    <div class="item_header">
      <div class="icon_left">
        <v-skeleton-loader class="skeleton" type="text" />
      </div>
      <div class="sections">
        <div class="section_left">
          <div class="section_title">
            <v-skeleton-loader class="skeleton" type="text" />
          </div>
          <div class="section_content">
            <v-skeleton-loader class="skeleton" type="text" />
          </div>
        </div>
        <div class="section_center">
          <div>
            <div class="section_title">
              <v-skeleton-loader class="skeleton" type="text" />
            </div>
            <div class="section_content">
              <v-skeleton-loader class="skeleton" type="text" />
            </div>
            <div class="section_footer">
              <v-skeleton-loader class="skeleton" type="text" />
            </div>
          </div>
        </div>
        <div class="section_right">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
      </div>
      <div class="icon_right">
        <v-btn icon disabled>
          <BaseIcon icon="$mdiChevronDown" />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FolderRecordItemSkeleton",
};
</script>

<style lang="scss" scoped>
.record_item_container {
  background: #fff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;
  .item_header {
    display: flex;
    .icon_left {
      width: 32px;
      background: #fff;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      .skeleton {
        height: 100%;
        width: 100%;
        ::v-deep {
          .v-skeleton-loader__bone {
            border-radius: 0;
            height: 100%;
          }
        }
      }
    }
    .sections {
      padding: 16px;
      display: flex;
      align-items: center;
      flex: 1;
      .section_left {
        padding: 8px;
        flex: 1;
        .section_title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #4f4f4f;
          .skeleton {
            height: 28px;
            width: 80%;
            ::v-deep {
              .v-skeleton-loader__bone {
                height: 24px;
              }
            }
          }
        }
        .section_content {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
          .skeleton {
            height: 16px;
            width: 70%;
            ::v-deep {
              .v-skeleton-loader__bone {
                height: 12px;
              }
            }
          }
        }
      }
      .section_center {
        padding: 8px;
        flex: 1;
        display: flex;
        justify-content: center;
        .section_title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #707080;
          .skeleton {
            height: 12px;
            width: 80px;
            ::v-deep {
              .v-skeleton-loader__bone {
                height: 10px;
              }
            }
          }
        }
        .section_content {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #353542;
          margin-top: 6px;
          .skeleton {
            height: 14px;
            width: 140px;
            ::v-deep {
              .v-skeleton-loader__bone {
                height: 16px;
              }
            }
          }
        }
        .section_footer {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
          .skeleton {
            height: 14px;
            width: 120px;
            ::v-deep {
              .v-skeleton-loader__bone {
                height: 16px;
              }
            }
          }
        }
      }
      .section_right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .skeleton {
          height: 24px;
          width: 150px;
          ::v-deep {
            .v-skeleton-loader__bone {
              height: 24px;
            }
          }
        }
      }
    }
    .icon_right {
      flex: none;
      width: 76px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
