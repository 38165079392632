<template>
  <div class="qt_container">
    <div class="header">
      <div
        class="header_title"
        v-text="$t('utils.managmentOfQuestionTemplate')"
      />
      <BaseButton
        type="primary"
        icon="$mdiPlus"
        @click="dialogState.createAndEditQuestionTemplate = true"
      >
        {{ $t("utils.create") }}
      </BaseButton>
    </div>
    <BaseDataTable
      :headers="TABLE_HEADER"
      :items="questionTemplate.items"
      :loading="questionTemplateFilter.loading"
      :options="{
        page: questionTemplateFilter.page,
        itemsPerPage: questionTemplateFilter.pageSize,
      }"
      :server-items-length="questionTemplate.count"
      :loading-text="$t('utils.loadingData')"
      @update:options="onPaginationChange"
    >
      <template #item="{ item }">
        <tr class="tab_row">
          <td>
            <div class="name_section">
              <div class="question_label" v-text="item.label" />
              <div class="question_name" v-text="item.name" />
            </div>
          </td>
          <td>
            <div class="slug" v-text="item.slug" />
          </td>
          <td>
            <div class="type_question" v-text="item.typeQuestion" />
          </td>
          <td>
            <div class="help_section">
              <BaseIcon
                icon="$mdiHelpCircle"
                color="primary"
                :class="{ help_disable: !item.helpText }"
              />
              <BaseIcon
                icon="$mdiFileQuestion"
                color="primary"
                :class="{ help_disable: !item.helpFile }"
              />
            </div>
          </td>
          <td>
            <div class="action_section">
              <BaseButtonIcon
                small
                color="primary"
                icon="$mdiPencilOutline"
                @click="editQuestionTemplate(item)"
              />
              <BaseButtonIcon
                small
                color="primary"
                icon="$mdiReloadAlert"
                @click="propagateQuestionTemplate(item)"
              />
            </div>
          </td>
        </tr>
      </template>
    </BaseDataTable>
    <QuestionTemplateCreateAndEditDialog
      :dialog-state="dialogState.createAndEditQuestionTemplate"
      :question-template="activeQuestionTemplate"
      :organization-id="organizationId"
      @questionTemplateCreated="onQuestionTemplateCreated()"
      @questionTemplateEdited="onQuestionTemplateEdited($event)"
      @close="onCloseModal()"
    />
    <QuestionTemplatePropagateDialog
      :dialog-state="dialogState.propagateQuestionTemplate"
      :question-template="activeQuestionTemplate"
      :organization-id="organizationId"
      @close="onCloseModal()"
    />
  </div>
</template>

<script>
import { fetchQuestionTemplates } from "@/core/Services/questionTemplate.service";
import QuestionTemplateCreateAndEditDialog from "./QuestionTemplateCreateAndEditDialog";
import QuestionTemplatePropagateDialog from "./QuestionTemplatePropagateDialog";

export default {
  name: "QuestionTemplateList",
  components: {
    QuestionTemplateCreateAndEditDialog,
    QuestionTemplatePropagateDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      TABLE_HEADER: [
        {
          text: this.$t("utils.field"),
          value: "label",
          sortable: false,
        },
        {
          text: this.$t("utils.slug"),
          value: "slug",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.type", 2),
          value: "type",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("utils.helpers"),
          value: "helpers",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.action", 3),
          value: "action",
          align: "end",
          sortable: false,
        },
      ],
      activeQuestionTemplate: null,
      dialogState: {
        createAndEditQuestionTemplate: false,
        propagateQuestionTemplate: false,
      },
      questionTemplate: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      questionTemplateFilter: {
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.fetchQuestionTemplates();
  },
  methods: {
    async fetchQuestionTemplates() {
      this.questionTemplateFilter.loading = true;
      try {
        this.questionTemplate = await fetchQuestionTemplates({
          organizationId: this.organizationId,
          page: this.questionTemplateFilter.page,
          pageSize: this.questionTemplateFilter.pageSize,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.questionTemplateFilter.loading = false;
    },
    onPaginationChange(pagination) {
      this.questionTemplateFilter.page = pagination.page;
      this.questionTemplateFilter.pageSize =
        pagination.itemsPerPage < 0
          ? this.questionTemplate.count
          : pagination.itemsPerPage;
      this.fetchQuestionTemplates();
    },
    editQuestionTemplate(questionTemplate) {
      this.activeQuestionTemplate = questionTemplate;
      this.dialogState.createAndEditQuestionTemplate = true;
    },
    propagateQuestionTemplate(questionTemplate) {
      this.activeQuestionTemplate = questionTemplate;
      this.dialogState.propagateQuestionTemplate = true;
    },
    onQuestionTemplateCreated() {
      this.onCloseModal();
      this.questionTemplate.page = 1;
      this.fetchQuestionTemplates();
    },
    onQuestionTemplateEdited(editedQuestion) {
      this.questionTemplate.items = this.questionTemplate.items.map((qt) => {
        if (qt.id != editedQuestion.id) return qt;
        return editedQuestion;
      });
      this.onCloseModal();
    },
    onCloseModal() {
      this.dialogState.createAndEditQuestionTemplate = false;
      this.dialogState.propagateQuestionTemplate = false;
      this.activeQuestionTemplate = null;
    },
  },
};
</script>

<style scoped lang="scss">
.qt_container {
  padding: 0 8px;
  .header {
    margin-bottom: 32px;
    .header_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #353542 !important;
      margin-top: 8px;
      margin-bottom: 32px;
    }
  }
}
.tab_row {
  .name_section {
    padding: 16px 0;
    font-family: "Inter";
    font-style: normal;
    .question_label {
      color: #2536cc;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }
    .question_name {
      color: #707080;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
  .slug {
    color: #707080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
  .type_question {
    color: #707080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
  .help_section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .help_disable {
      opacity: 0.6;
    }
  }
  .action_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
  }
}
</style>
