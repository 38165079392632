<template>
  <div>
    <!-- creation dossier -->
    <i18n
      v-if="folderLogs.action === 'CREATE_FOLDER'"
      path="folder.logs.folderCreate"
    >
      <template #folderName>
        <b v-text="`${folderLogs.actionDisplayLogs.folderName}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- email cosmoform -->

    <i18n
      v-if="folderLogs.action === 'SUBMIT_COSMOFORM_EMAIL'"
      path="folder.logs.cosmoformEmail"
    >
      <template #collaboratorEmail>
        <b v-text="`${folderLogs.actionDisplayLogs.clientEmail}`" />
      </template>
      <template #cosmoForm>
        <b v-text="`${folderLogs.actionDisplayLogs.formular}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>

    <!-- sms cosmoform -->

    <i18n
      v-if="folderLogs.action === 'SUBMIT_COSMOFORM_SMS'"
      path="folder.logs.cosmoformSMS"
    >
      <template #collaboratorNumber>
        <b v-text="`${folderLogs.actionDisplayLogs.clientNumber}`" />
      </template>
      <template #cosmoForm>
        <b v-text="`${folderLogs.actionDisplayLogs.formular}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- answered cosmoform -->

    <i18n
      v-if="folderLogs.action === 'COSMOFORM_ANSWERED'"
      path="folder.logs.cosmoformAnswered"
    >
      <template #cosmoForm>
        <b v-text="`${folderLogs.actionDisplayLogs.formular}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>

    <!-- agreement change -->
    <i18n
      v-if="folderLogs.action === 'STATUS_AGREEMENT'"
      path="folder.logs.agreementChange"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- assignation de collaborateur -->
    <i18n
      v-else-if="folderLogs.action === 'ASSIGN_COLLABORATOR'"
      path="folder.logs.assignCollaborator"
    >
      <template #assignedName>
        <b v-text="`${folderLogs.actionDisplayLogs.assignedName}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
      <template #folderName>
        <b v-text="`${folderLogs.actionDisplayLogs.folderName}`" />
      </template>
    </i18n>
    <!-- consentement record -->
    <i18n
      v-else-if="folderLogs.action === 'NEW_RECORD'"
      path="folder.logs.newRecord"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
      <template #courseName>
        <b v-text="`${folderLogs.actionDisplayLogs.courseName}`" />
      </template>
    </i18n>
    <!-- demande d'information -->
    <i18n
      v-else-if="folderLogs.action === 'REQUEST_INFORMATION'"
      path="folder.logs.requestInformation"
    >
      <template #assignedName>
        <b v-text="`${folderLogs.actionDisplayLogs.assignedName}`" />
      </template>
      <template #courseName>
        <b
          class="user_action"
          v-text="`${folderLogs.actionDisplayLogs.courseName}`"
        />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- demande d'info sur une donne -->
    <div v-else-if="folderLogs.action === 'UPDATE_ANSWER_VALIDATION'">
      <i18n
        v-if="folderLogs.data.status === 'UPDATE_REQUESTED'"
        path="folder.logs.updateValidationRequested"
      >
        <template #assigned>
          <b v-text="`${folderLogs.actionDisplayLogs.assigned}`" />
        </template>
        <template #questions>
          <b v-text="`${folderLogs.actionDisplayLogs.questions}`" />
        </template>
        <template #collaboratorName>
          <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
        </template>
      </i18n>
      <i18n
        v-else-if="folderLogs.data.status === 'VALIDATED'"
        path="folder.logs.answerValidated"
      >
        <template #questions>
          <b v-text="`${folderLogs.actionDisplayLogs.questions}`" />
        </template>
        <template #collaboratorName>
          <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
        </template>
      </i18n>
      <i18n v-else path="folder.logs.answerRequestInformation">
        <template #assigned>
          <b v-text="`${folderLogs.actionDisplayLogs.assigned}`" />
        </template>
        <template #questions>
          <b v-text="`${folderLogs.actionDisplayLogs.questions}`" />
        </template>
      </i18n>
    </div>
    <!-- lancement signature -->
    <i18n
      v-else-if="folderLogs.action === 'SIGNATURE_REQUEST'"
      path="folder.logs.signatureRequest"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!--  signature signee-->
    <i18n
      v-else-if="folderLogs.action === 'SIGNATURE_SIGNED'"
      path="folder.logs.signatureSigned"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
      <template #clientName>
        <b v-text="`${folderLogs.actionDisplayLogs.client}`" />
      </template>
    </i18n>
    <!-- creation custom field -->
    <i18n
      v-else-if="folderLogs.action === 'CREATE_CUSTOM_FIELD'"
      path="folder.logs.createCSField"
    >
      <template #fieldName>
        <b v-text="`${folderLogs.actionDisplayLogs.fieldName}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- reponse custom field -->
    <i18n
      v-else-if="folderLogs.action === 'ANSWER_CUSTOM_FIELD'"
      path="folder.logs.cSFieldAnswered"
    >
      <template #fieldName>
        <b v-text="`${folderLogs.actionDisplayLogs.fieldName}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- modification cs field -->
    <i18n
      v-else-if="folderLogs.action === 'MODIFIED_CUSTOM_FIELD'"
      path="folder.logs.modifyCSField"
    >
      <template #fieldName>
        <b v-text="`${folderLogs.actionDisplayLogs.fieldName}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- changement de status -->
    <i18n
      v-else-if="folderLogs.action === 'STATUS_CHANGE'"
      path="folder.logs.statusChange"
    >
      <template #statusFrom>
        <b v-text="`${folderLogs.actionDisplayLogs.statusFrom}`" />
      </template>
      <template #statusTo>
        <b v-text="`${folderLogs.actionDisplayLogs.statusTo}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- genereation pdf -->
    <i18n
      v-else-if="folderLogs.action === 'GENERATE_PDF'"
      path="folder.logs.generatePdf"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- submisstion folder cosmoform -->
    <i18n
      v-else-if="folderLogs.action === 'SUBMIT_COSMOFORM'"
      path="folder.logs.cosmoformSubmission"
    >
      <template #emailClient>
        <b v-text="`${folderLogs.actionDisplayLogs.clientEmail}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
      <template #questions>
        <b v-text="`${folderLogs.actionDisplayLogs.questions}`" />
      </template>
    </i18n>
    <!-- relaunc cosmofor -->
    <!-- submisstion folder cosmoform -->
    <i18n
      v-else-if="folderLogs.action === 'RELAUNCH_CLIENT'"
      path="folder.logs.relaunchClient"
    >
      <template #emailClient>
        <b v-text="`${folderLogs.actionDisplayLogs.clientEmail}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <!-- generation pdf -->
    <i18n
      v-else-if="folderLogs.action === 'SIGNED_DOCUMENT'"
      path="folder.logs.signedDocument"
    >
      <template #folderName>
        <b v-text="`${folderLogs.actionDisplayLogs.folderName}`" />
      </template>
    </i18n>
    <!-- document refuse -->
    <i18n
      v-else-if="folderLogs.action === 'REFUSED_DOCUMENT'"
      path="folder.logs.signedDocument"
    >
      <template #folderName>
        <b v-text="`${folderLogs.actionDisplayLogs.folderName}`" />
      </template>
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.clientEmail}`" />
      </template>
    </i18n>
    <i18n
      v-else-if="folderLogs.action === 'FOLDER_ARCHIVED'"
      path="folder.logs.archivedFolders"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
    <i18n
      v-else-if="folderLogs.action === 'FOLDER_UNARCHIVED'"
      path="folder.logs.unArchivedFolders"
    >
      <template #collaboratorName>
        <b v-text="`${folderLogs.actionDisplayLogs.collaboratorName}`" />
      </template>
    </i18n>
  </div>
</template>

<script>
export default {
  name: "LogsLabel",
  props: {
    folderLogs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.user_action {
  text-transform: capitalize;
  margin: 0 2px;
}
</style>
