<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('utils.deleteAnswer')"
    is-unique
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-label">
        {{ $t("utils.deleteAnswerConfirmation") }}
      </div>
    </template>
    <template #actions>
      <BaseButton type="primary" class="mx-2 ml-auto" @click="reset">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "FolderFileConfirmDeleteDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
  },

  methods: {
    reset() {
      this.$emit("delete");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-label {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
