<template>
  <div v-if="question">
    <div
      :class="{
        'workflow-question':
          isWorkflowQuestion && (question.helpText || question.helpFile),
      }"
    >
      <div
        v-if="question.helpText || question.helpFile"
        class="help_section d-flex mb-6"
      >
        <BaseIcon icon="$mdiInformation" color="#CFCFDC" />

        <div class="d-flex flex-column ml-3">
          <div v-if="question.helpText" class="help_text">
            <div
              v-for="(row, index) in question.helpText.split(/\n/)"
              :key="index"
              v-text="row"
            />
          </div>
          <div v-if="question.helpFile" class="help_file_section">
            <a class="help_file" :href="question.helpFile" target="_blank">
              {{ fileName }}
            </a>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :class="{
          question_field: questionField && needLabel,
          focused_field: questionField && needLabel && isFocused,
          signature: /^SIGNATURE$/.test(question.typeQuestion),
        }"
        @mouseenter="isFocused = true"
        @mouseleave="isFocused = false"
      >
        <span
          v-if="questionField && needLabel"
          class="input-label"
          v-text="question.required ? `${question.name} *` : question.name"
        />

        <div v-if="!!valueErrors" class="error-messages" v-text="valueErrors" />
        <template v-if="/^TEXT$/.test(question.typeQuestion)">
          <base-text-field
            v-model="form.value"
            :placeholder="`Renseigner &quot;${question.name}&quot;`"
            :label="question.name"
            :hide-details="!!valueErrors"
            outlined
            :error-messages="valueErrors"
            :has-error="!!valueErrors"
            :autofocus="focus"
            :readonly="isArchivedFolder"
            @input="$v.form.value.$touch()"
            @change="onChange"
            @blur="$v.form.value.$touch()"
          />
        </template>
        <template v-else-if="/^TEXTAREA$/.test(question.typeQuestion)">
          <base-text-area
            v-model="form.value"
            :label="question.name"
            :placeholder="`Renseigner &quot;${question.name}&quot;`"
            :hide-details="!!valueErrors"
            :autofocus="focus"
            :readonly="isArchivedFolder"
            @change="onChange"
            @blur="$v.form.value.$touch()"
          />
        </template>
        <div
          v-else-if="/^DATE$/.test(question.typeQuestion)"
          class="d-flex justify-start pb-2"
        >
          <BaseDateInput
            :value-date="form.value"
            :placeholder="`Renseigner &quot;${question.name}&quot;`"
            locale="fr-fr"
            :first-day-of-week="1"
            :label="question.name"
            :has-error="valueErrors !== ''"
            :hide-details="!valueErrors"
            :error-messages="valueErrors"
            :readonly="isArchivedFolder"
            class="date-field"
            @update-value="onChange"
            @blur="$v.form.value.$touch()"
          />
        </div>
        <div
          v-else-if="/^DATETIME$/.test(question.typeQuestion)"
          class="d-flex justify-space-between align-center"
        >
          <BaseDateInput
            :value-date="form.value"
            locale="fr-fr"
            :first-day-of-week="1"
            :label="question.name"
            :hide-details="!valueErrors"
            :error-messages="valueErrors"
            :readonly="isArchivedFolder"
            :has-error="valueErrors !== ''"
            class="date-item"
            @update-value="onChange"
            @blur="$v.form.value.$touch()"
          />
          <BaseTimeInput
            :value-time="form.time"
            height="200"
            format="24hr"
            class="date-item"
            :hide-details="!valueErrors"
            :error-messages="valueErrors"
            :readonly="isArchivedFolder"
            @update-value="onTimeChange"
            @blur="$v.form.time.$touch()"
          />
        </div>
        <template v-else-if="/^NUMBER$/.test(question.typeQuestion)">
          <base-text-field
            v-model="form.value"
            :placeholder="`Renseigner &quot;${question.name}&quot;`"
            type="number"
            :hide-details="!valueErrors"
            outlined
            :error="!!valueErrors"
            :has-error="!!valueErrors"
            :autofocus="focus"
            :readonly="isArchivedFolder"
            @input="$v.form.value.$touch()"
            @change="onChange"
            @blur="$v.form.value.$touch()"
          />
        </template>
        <template v-else-if="/^SELECT$/.test(question.typeQuestion)">
          <BaseSelect
            v-model="form.value"
            :items="question.choices"
            :label="question.name"
            clearable
            outlined
            solo
            item-text="name"
            item-value="id"
            :autofocus="focus"
            :placeholder="`Renseigner &quot;${question.name}&quot;`"
            :hide-details="!valueErrors"
            :error-messages="valueErrors"
            :readonly="isArchivedFolder"
            style="z-index: 12"
            @change="onChange"
            @blur="$v.form.value.$touch()"
          />
        </template>
        <template v-else-if="/^RADIOBUTTON$/.test(question.typeQuestion)">
          <div
            class="group-container"
            :class="{ 'container-focused': isFocused }"
            @mouseenter="isFocused = true"
            @mouseleave="isFocused = false"
          >
            <span
              class="label-title"
              v-text="question.required ? `${question.name}*` : question.name"
            />
            <v-radio-group
              v-model="form.value"
              :placeholder="`Renseigner &quot;${question.name}&quot;`"
              :hide-details="!valueErrors"
              :error-messages="valueErrors"
              :readonly="isArchivedFolder"
              @change="onChange"
              @blur="$v.form.value.$touch()"
            >
              <v-radio
                v-for="choice in question.choices"
                :key="choice.id"
                :label="choice.name"
                :value="choice.id"
                @click="onClickRadioButton(form.value, choice.name)"
              />
            </v-radio-group>
            <div v-if="question.hasFreeOption" class="d-flex flex-row mt-2">
              <div class="other-radio">
                <input
                  id="other"
                  class="radio"
                  type="radio"
                  :class="{ checked: selectedOption }"
                  :value="selectedOption"
                  @click="otherClick"
                />
                <label for="other" class="ml-2">Autre:</label>
              </div>

              <v-text-field
                ref="other"
                v-model="otherValue"
                :disabled="!selectedOption"
                class="input-other"
                variant="underlined"
                :readonly="isArchivedFolder"
                @change="onChangeField"
              />
            </div>
          </div>
        </template>
        <template v-else-if="/^CHECKBOX$/.test(question.typeQuestion)">
          <div
            class="group-container"
            :class="{ 'container-focused': isFocused }"
            @mouseenter="isFocused = true"
            @mouseleave="isFocused = false"
          >
            <span
              class="label-title"
              v-text="question.required ? `${question.name}*` : question.name"
            />
            <v-checkbox
              v-for="(choice, i) in question.choices"
              :key="i"
              v-model="form.value"
              :label="choice.name"
              :value="choice.id"
              :hide-details="!valueErrors"
              :error-messages="
                question.choices.length == i + 1
                  ? valueErrors
                  : valueErrors != ''
                  ? ' '
                  : null
              "
              :readonly="isArchivedFolder"
              @change="onChange($event, choice.name)"
              @blur="$v.form.value.$touch()"
            />
            <div v-if="question.hasFreeOption" class="d-flex">
              <v-checkbox
                v-model="selectedOption"
                label="Autre :"
                :readonly="isArchivedFolder"
                @change="$refs.other.focus(), (otherValue = '')"
                @blur="$v.form.value.$touch()"
              />
              <v-text-field
                ref="other"
                v-model="otherValue"
                :disabled="!selectedOption"
                style="width: 250px"
                variant="underlined"
                :readonly="isArchivedFolder"
                @change="onChangeField"
              />
            </div>
          </div>
        </template>
        <template v-else-if="/^FILE$/.test(question.typeQuestion)">
          <v-file-input
            key="file"
            v-model="form.value"
            truncate-length="50"
            :label="question.name"
            append-icon="$mdiDownload"
            :placeholder="`Renseigner &quot;${question.name}&quot;`"
            :hide-details="!valueErrors"
            :disabled="isArchivedFolder"
            :has-error="valueErrors !== ''"
            outlined
            @change="onChange"
            @blur="$v.form.value.$touch()"
          />
        </template>
        <template v-else-if="/^MULTIPLE_FILE$/.test(question.typeQuestion)">
          <folder-offer-file-drag
            :offer-files="form.value"
            nature="secondary"
            :is-disabled="isArchivedFolder"
            :question="question"
            :need-iteration="false"
            :initial-files="initialFiles"
            is-not-offer
            @delete-offer-file="removeFile"
            @file-droped="fileInserted"
            @file-name-changed="updateFileName"
          />
        </template>
        <div
          v-else-if="/^DATAGRID|ROW_DATAGRID$/.test(question.typeQuestion)"
          class="my-2"
        >
          <BaseTableEditor
            v-model="form.value"
            :headers="tableHeaders"
            variant="secondary"
            :horizontal="/^ROW_DATAGRID$/.test(question.typeQuestion)"
            @changeValue="onChange"
          />
        </div>
        <div
          v-else-if="/^SIGNATURE$/.test(question.typeQuestion)"
          class="d-flex justify-center relative"
        >
          <VueSignaturePad
            ref="signaturePad"
            v-model="form.value"
            class="elevation-4"
            height="150px"
            :options="signaturePadOptions"
            @input="onChange"
          />
          <div class="image-action">
            <BaseIcon
              icon="$mdiArrowURightTopBold"
              medium
              color="grey"
              @click="
                () => {
                  $refs.signaturePad.undoSignature();
                  signaturePadOnEnd();
                }
              "
            />
          </div>
          <div class="image-action mt-6">
            <BaseIcon
              icon="$mdiTrashCanOutline"
              color="secondary"
              medium
              @click="
                () => {
                  $refs.signaturePad.clearSignature();
                  signaturePadOnEnd();
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import FolderOfferFileDrag from "./FolderOffer/FolderOfferFileDrag.vue";
import {
  testEmail,
  testEntryByConditions,
} from "@/core/Utils/custom.control.utils";

export default {
  name: "WorkflowQuestionField",
  components: { FolderOfferFileDrag },
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      required: true,
    },
    focus: {
      type: Boolean,
    },
    isWorkflowQuestion: {
      type: Boolean,
    },
    needLabel: {
      type: Boolean,
    },
  },
  data() {
    return {
      previouslyRadioOptionSelected: null,
      form: {
        value: null,
        table: [],
        time: null,
      },
      signaturePadOptions: {
        penColor: "#000",
        onEnd: this.signaturePadOnEnd,
      },
      isFocused: false,
      selectedOption: false,
      otherValue: "",
      initialFiles: [],
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    valueErrors() {
      if (!this.$v.form.value.$dirty) {
        return "";
      }
      if (this.question.required && !this.$v.form.value.required) {
        return "Ce champ est requis *";
      }
      if (
        /^NUMBER$/.test(this.question.typeQuestion) &&
        !this.$v.form.value.integer
      ) {
        return "Ce champ doit être un nombre entier";
      }
      // regex control
      if (this.question.conditions !== "" && !this.$v.form.value.isValid) {
        return this.question.errorMessage !== ""
          ? this.question.errorMessage
          : this.$t("utils.incorrectFormat");
      }
      if (
        this.question.datatype.toString().includes("EMAIL") &&
        !this.$v.form.value.isValidEmail
      ) {
        return this.$t("folder.workflow.emailIncorrect");
      }
      return "";
    },
    newDataGridRow() {
      let defaultObject = {};
      for (const choice of this.question.choices) {
        defaultObject[choice?.id] = "";
      }
      return defaultObject;
    },
    fileName() {
      if (!this.question.helpFile) return "";
      return this.question.helpFile.split("?")[0].split("/")[
        this.question.helpFile.split("/").length - 1
      ];
    },
    questionField() {
      // TODO ???
      const values = [
        "RADIOBUTTON",
        "CHECKBOX",
        "DATAGRID",
        "ROW_DATAGRID",
        "SIGNATURE",
      ];
      return values.includes(this.question?.typeQuestion);
    },
    tableHeaders() {
      if (!/^DATAGRID|ROW_DATAGRID$/.test(this.question.typeQuestion))
        return [];
      return this.question.choices.map((choice) => ({
        label: choice.name,
        field: choice.name,
      }));
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit("formValidityChange", this.$v.form.$invalid);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initialize();
  },
  validations() {
    let validations = {
      form: {
        value: {},
        time: {},
      },
    };
    if (this.question.required) {
      validations.form.value = {
        required,
      };
      if (/^DATETIME$/.test(this.question.typeQuestion)) {
        validations.form.time = {
          required,
        };
      }
    }
    if (/^DATETIME$/.test(this.question.typeQuestion)) {
      if (this.form.value) {
        validations.form.time = {
          required,
        };
      }
      if (this.form.time) {
        validations.form.value = {
          required,
        };
      }
    }
    if (/^NUMBER$/.test(this.question.typeQuestion)) {
      validations.form.value = {
        ...validations.form.value,
        integer,
      };
    }
    // regex control
    if (this.question.conditions && this.question.conditions !== "") {
      validations.form.value = {
        ...validations.form.value,
        isValid: (value) =>
          testEntryByConditions(this.question.conditions, value),
      };
    }
    if (this.question.datatype.toString().includes("EMAIL")) {
      validations.form.value = {
        ...validations.form.value,
        isValidEmail: (value) => testEmail(value),
      };
    }
    return validations;
  },
  methods: {
    async onClickRadioButton(selected) {
      if (this.previouslyRadioOptionSelected == selected) {
        this.form.value = [];
        this.previouslyRadioOptionSelected = null;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        this.$emit("input", await this.buildAnswer());
        return;
      } else {
        this.otherValue = "";
        this.selectedOption = false;
        this.previouslyRadioOptionSelected = selected;
        this.$emit("input", await this.buildAnswer());
      }
    },
    fileInserted({ value }) {
      // insert file locally to use vuelidate+vuex
      let fileList = [];
      for (const file of value) {
        fileList.push(file);
      }

      if (Array.isArray(this.form.value)) {
        this.form.value = [...this.form.value, ...value];
        return this.onChange();
      }
      this.form.value = fileList;
      return this.onChange();
    },
    updateFileName({ value, index }) {
      const newFile = new File([this.form.value[index]], `${value}`, {
        type: this.form.value[index].type,
      });
      this.form.value.splice(index, 1, newFile);
      return this.onChange();
    },
    removeFile(index) {
      if (index < this.initialFiles.length) {
        // delete logics

        //
        this.initialFiles.splice(index, 1);
        this.$emit("set-initial-file", this.initialFiles);
      }
      this.form.value.splice(index, 1);
      this.onChange();
    },
    otherClick() {
      this.selectedOption = !this.selectedOption;
      this.form.value = [];

      this.$nextTick(async function () {
        if (!this.selectedOption) this.otherValue = "";
        else this.$refs.other.focus();
        this.$emit("input", await this.buildAnswer());
      });
    },
    async initialize() {
      /**
       * Workaround because signaturePad size sets to 0 if in a dialog.
       * When page is initialized, we set it to right size
       * Link https://github.com/neighborhood999/vue-signature-pad/issues/62#issuecomment-458891526
       */
      if (/^SIGNATURE$/.test(this.question.typeQuestion)) {
        this.$nextTick(function () {
          this.$refs.signaturePad.resizeCanvas();
        });
      }
      /**
       * if there is already an answer we set the right input to it's actual
       * value
       */
      if (this.question.answer) {
        switch (this.question.typeQuestion) {
          case "TEXT":
          case "TEXTAREA":
          case "NUMBER":
            this.form.value = this.question.answer.content;
            break;
          case "DATE":
            {
              this.form.value = this.question.answer.content.substr(0, 10);
            }
            break;
          case "SELECT":
          case "RADIOBUTTON":
            if (this.question.answer.choices.length) {
              this.form.value = this.question.answer.choices[0].id;
              this.previouslyRadioOptionSelected = this.form.value;
            } else if (
              this.question.hasFreeOption &&
              this.question.answer?.freeOptionContent.length
            ) {
              this.otherValue = this.question.answer.freeOptionContent;
              this.selectedOption =
                this.question.hasFreeOption &&
                this.question.answer?.freeOptionContent.length;
            }

            break;
          case "CHECKBOX":
            this.form.value = this.question.answer.choices.map(
              (choice) => choice.id
            );
            if (this.question.hasFreeOption) {
              this.otherValue = this.question.answer.freeOptionContent;
              this.selectedOption =
                this.question.hasFreeOption &&
                this.question.answer?.freeOptionContent.length;
            }
            break;
          case "DATETIME":
            {
              const timeContent = this.question.answer.content.replace(
                ":00+00:00",
                ""
              );
              this.form.value = timeContent.substr(0, 10);
              this.form.dateField = `${this.form.value.substr(
                8,
                2
              )}/${this.form.value.substr(5, 2)}/${this.form.value.substr(
                0,
                4
              )}`;
              this.form.time = timeContent.substr(11, 5);
            }
            break;
          case "DATAGRID":
          case "ROW_DATAGRID":
            {
              this.form.value = [];
              if (this.question.answer.content) {
                this.question.answer.content.rows.forEach((row) => {
                  const tmp = {};
                  this.question.answer.content.headers.forEach((key, i) => {
                    tmp[key] = row[i];
                  });
                  this.form.value.push(tmp);
                });
              } else {
                this.form.value = [];
              }
            }
            break;
          case "SIGNATURE":
            this.form.value = this.dataURLtoFile(
              await this.getDataBlob(this.question.answer.content[0].url),
              "Fichier.png"
            );
            break;
          case "FILE":
            if (this.question.answer.content) {
              this.form.value = this.dataURLtoFile(
                await this.getDataBlob(this.question.answer.content[0].url),
                this.question.answer.content[0].name,
                true
              );
            }
            break;
          case "MULTIPLE_FILE":
            if (this.question.answer.content) {
              this.initialFiles = [...this.question.answer.content];
              this.$emit("set-initial-file", this.initialFiles);
              const value = this.question.answer.content.map(async (file) =>
                this.dataURLtoFile(
                  await this.getDataBlob(file.url),
                  file.name,
                  true
                )
              );
              this.form.value = await Promise.all(value);
            }
            break;
          default:
            this.form.value = this.question.answer.choices;
            break;
        }
      } else {
        if (/^CHECKBOX$/.test(this.question.typeQuestion)) {
          this.form.value = [];
        } else if (/^DATAGRID|ROW_DATAGRID$/.test(this.question.typeQuestion)) {
          this.form.value = [];
        }
      }
    },
    async onTimeChange(timeValue) {
      this.form.time = timeValue;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.$emit("input", await this.buildAnswer());
    },
    async onChange(inputValue) {
      if (inputValue && inputValue !== null) {
        this.form.value = inputValue;
      }
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.$emit("input", await this.buildAnswer());
    },
    async onChangeField(inputValue) {
      this.otherValue = inputValue;
      this.$emit("input", await this.buildAnswer());
    },
    async buildAnswer() {
      let payload = {};
      switch (this.question.typeQuestion) {
        case "TEXT":
        case "TEXTAREA":
        case "DATE":
        case "NUMBER":
        case "SIGNATURE":
          payload.content = this.form.value ? this.form.value : "";
          break;
        case "FILE":
          {
            if (this.form.value) {
              const base64 = this.form.value
                ? await this.fileToBase64(this.form.value)
                : null;
              payload.content = base64;
              payload.fileName = this.form.value?.name;
            } else payload.content = [];
          }
          break;
        case "MULTIPLE_FILE":
          {
            const values = this.form.value.map(async (file) => ({
              name: file.name,
              content: await this.fileToBase64(file),
            }));
            const result = await Promise.all(values);
            payload.content = result;
          }
          break;
        case "SELECT":
          if (Number.isSafeInteger(this.form.value)) {
            payload.choices = [this.form.value];
          } else {
            payload.choices = this.form.value ? this.form.value : [];
          }
          break;
        case "RADIOBUTTON":
        case "CHECKBOX":
          if (!this.form.value && this.otherValue.length > 0) {
            payload.choices = [`free__${this.otherValue}`];
          } else if (Number.isSafeInteger(this.form.value)) {
            payload.choices =
              this.question.hasFreeOption &&
              this.otherValue.length > 0 &&
              this.selectedOption
                ? [this.form.value, `free__${this.otherValue}`]
                : [this.form.value];
          } else
            payload.choices =
              this.question.hasFreeOption && this.otherValue.length > 0
                ? [...this.form.value, `free__${this.otherValue}`]
                : this.form.value;

          break;
        case "DATETIME":
          payload.content =
            this.form.value.substr(0, 10) + " " + this.form.time;
          break;
        case "DATAGRID":
        case "ROW_DATAGRID":
          if (!this.form.value?.length) {
            payload.content = {
              headers: this.tableHeaders.map((th) => th.label),
              rows: [this.tableHeaders.map(() => "")],
            };
          } else {
            payload.content = {
              headers: this.tableHeaders.map((th) => th.label),
              rows: this.form.value.map((row) => {
                const data = [];
                for (const th of this.tableHeaders) {
                  const value = row[th.label];
                  data.push(value ? value : "");
                }
                return data;
              }),
            };
          }
          break;
        default:
          break;
      }
      return {
        question: this.question,
        content: { ...payload },
      };
    },
    updateTable() {
      if (/^ROW_DATAGRID$/.test(this.question.typeQuestion)) {
        this.form.value = this.form.table.map((elem) => {
          delete elem.$id;
          return elem;
        });
        this.onChange();
        return;
      }
      this.form.value = this.form.table.map((row) => {
        const rowKeys = Object.keys(row).filter((k) => k !== "$id");
        let objectToReturn = {};
        for (const key of rowKeys) {
          const choice = this.question.choices.find(
            (c) => c.id == Number.parseInt(key)
          );
          if (choice.name) objectToReturn[choice.name] = row[key];
        }
        return objectToReturn;
      });
      this.onChange();
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    signaturePadOnEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.form.value = isEmpty ? null : data;
      this.onChange();
    },

    dataURLtoFile(dataurl, filename, useDirectName = false) {
      if (!dataurl) return;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const extension = mime.split("/");
      let name = `${filename}.${extension[1]}`;
      if (useDirectName) {
        name = filename;
      }
      return new File([u8arr], name, { type: mime });
    },
    async parseURI(d) {
      var reader = new FileReader();
      reader.readAsDataURL(d);
      return new Promise((res) => {
        reader.onload = (e) => {
          res(e.target.result);
        };
      });
    },
    async getDataBlob(url) {
      var res = await fetch(url);
      var blob = await res.blob();
      var uri = await this.parseURI(blob);
      return uri;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-other {
  position: relative;
  bottom: 20px;
}
.other-radio {
  margin-left: 2px;
  margin-top: 2px;

  label {
    position: relative;
    bottom: 4px;
    font-size: 16px;
    color: #707080;
  }
}
.radio {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  border: 3px solid #707080;
  &.checked {
    background: #2536cc;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #2536cc;
  }
}
.question_name {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(32px, -50%);
  max-width: calc(100% - 80px);
  display: flex;
  align-items: center;
  .label {
    font-size: 12px;
    line-height: 12px;
    padding: 4px 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    margin: 4px;
  }
}
.error-messages {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #ff5267;
  margin-bottom: 3px;
}
.signature {
  padding: 16px !important;
}
.question_field {
  position: relative;
  width: 100%;
  padding: 8px 16px 24px;
  gap: 10px;
  border: 1px solid #cfcfdc;
  border-radius: 5px;
  .input-label {
    position: absolute;
    z-index: 4;
    left: 12px;
    top: -13px;
    color: #707080;
    font-size: 14px;
    padding: 0px 3px;
    background: #fff;
  }

  .input {
    color: #2536cc;
    height: 100%;
    width: 100%;
    width: 100%;
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    padding: 10px 0;
    width: 100%;
    outline: none;
    border-bottom: 2px solid #919191;
    transition: color 0.3s ease-in-out;
    &:focus {
      border-bottom: 2px solid #2536cc;
    }
  }
  .signed_pad_container {
    display: flex;
    position: relative;
    margin: 16px 0;
    .signed_pad {
      border-radius: 5px;
      background: #fff;
      width: 100%;
      aspect-ratio: 1/1;
      box-shadow: 0px 0px 2px #c4c4c4;
    }
    .signed_pad_action {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

.help_section {
  padding: 16px;
  gap: 16px;
  background: #f9f9f9;
  border-radius: 5px;
  .help_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .help_file_section {
    margin-top: 4px;
    word-break: break-word;
    .help_file_title {
      font-family: "Inter";
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      color: #8b8b98;
    }
  }
}
.workflow-question {
  padding: 24px;
  border-radius: 5px;
  border: 1px solid #cfcfdc;
}
.date-item {
  width: 48%;
}
.focused_field {
  border: 2px solid #2536cc !important;
  box-shadow: 0px 0px 12px 4px #ebf2ff !important;
  .input-label {
    color: #2536cc !important;
  }
}
.date-field {
  width: 100%;
}
.image-action {
  position: absolute;
  right: 0px;
  top: 5px;
}
.group-container {
  position: relative;
  width: 100%;
  padding: 0 10px 18px;
  border: 1px solid #cfcfdc;
  border-radius: 5px;
  .label-title {
    position: absolute;
    z-index: 4;
    left: 12px;
    top: -13px;
    color: #707080;
    font-size: 14px;
    padding: 0px 3px;
    background: #fff;
  }
}
.container-focused {
  border: 2px solid #2536cc;
  box-shadow: 0px 0px 12px 4px #ebf2ff;
  .label-title {
    color: #2536cc;
  }
}
</style>
