<template>
  <div class="container">
    <a
      v-if="/^FILE|SIGNATURE$/.test(question.typeQuestion) && content"
      :href="imageContent"
      target="_blank"
    >
      <BaseFrontOfficeImage
        v-if="isImage"
        :src="imageContent"
        :class="{
          full_image: fullImage,
          thumb: !fullImage,
          'mid-size': midSizeImage,
        }"
        @error="isImage = false"
      />
      <BaseFrontOfficeParagraph
        v-else
        bold
        color="primary"
        v-text="$t('answer.seeFolder', { name: name })"
      />
    </a>
    <div
      v-else-if="/^MULTIPLE_FILE$/.test(question.typeQuestion) && content"
      class="offer_files"
    >
      <div v-for="(file, i) in content" :key="i" class="offer_file_item">
        <div class="file" @click.stop="openFile(file)">
          <BasePdfViewer
            v-if="file.url && file.url.includes('.pdf')"
            :pdf="file.url"
          />
          <BaseFrontOfficeImage v-else :src="file.url" />
        </div>
        <div class="file_name" v-text="file.name" />
      </div>
    </div>
    <QuestionDatagridPreviwer
      v-else-if="
        /^DATAGRID|ROW_DATAGRID$/.test(question.typeQuestion) && content
      "
      :question="question"
    />
    <BaseFrontOfficeParagraph
      v-else-if="/DATE/.test(question.typeQuestion) && content"
      truncate
      bold
      color="primary"
      v-text="$d(new Date(content), 'date')"
    />
    <BaseFrontOfficeParagraph
      v-else-if="/DATETIME/.test(question.typeQuestion) && content"
      truncate
      bold
      color="primary"
      v-text="$d(new Date(content), 'full')"
    />
    <BaseFrontOfficeParagraph
      v-else-if="
        /TEXT|TEXTEARA|SELECT|RADIOBUTTON|CHECKBOX/.test(
          question.typeQuestion
        ) && content
      "
      color="primary"
      bold
      v-text="content"
    />
    <BaseFrontOfficeParagraph
      v-else-if="
        /NUMBER/.test(question.typeQuestion) && !isNaN(parseInt(content, 10))
      "
      color="primary"
      bold
      v-text="content"
    />
    <BaseFrontOfficeParagraph
      v-else
      truncate
      italic
      v-text="$t('answer.noContent')"
    />
  </div>
</template>

<script>
import QuestionDatagridPreviwer from "@/core/Components/QuestionDatagridPreviwer";

export default {
  name: "WalletPreviewData",
  components: { QuestionDatagridPreviwer },
  props: {
    question: {
      type: Object,
      required: true,
    },
    fullImage: {
      type: Boolean,
    },
    midSizeImage: {
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isImage: true,
    };
  },

  computed: {
    content() {
      return this.question.answer
        ? this.question.answer.content
        : this.question.datatype && this.question.datatype.personalData
        ? this.question.datatype.personalData.content
        : null;
    },
    imageContent() {
      if (!this.question.answer) return null;
      return this.question.answer?.content[0]?.url;
    },
  },
  methods: {
    openFile(file) {
      open(file?.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f7f7f7f7;
  min-height: 48px;
  padding: 6px 8px;
  border-radius: 10px;
}
.container-error {
  border: 4px #ff5b60 solid !important;
}
.full_image {
  width: 100%;
  height: auto;
}
.thumb {
  height: 32px;
  width: 72px;
}
.mid-size {
  height: 150px;
  width: 150px;
}
.offer_files {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  grid-template-rows: auto;
  @media (min-width: 600px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
  @media (min-width: 1066px) {
    grid-column-gap: calc(4% / 2);
    grid-row-gap: 16px;
    grid-template-columns: repeat(3, 32%);
  }
  .offer_file_item {
    width: 100%;
    cursor: pointer;
    .file {
      border: 1px solid #e5e5e5;
      height: 95%;
      min-height: 200px;
      overflow: hidden;
      width: 100%;

      & > * {
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin: 0;
      }
    }
    .file_name {
      padding: 0 8px;
      width: 100%;
      font-size: 10px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #333;
      background: #fff;
      text-align: center;
    }
  }
}
</style>
