<template>
  <div class="input_group">
    <v-autocomplete
      ref="autocompleteInput"
      v-model="selectedContact"
      :items="filteredContacts"
      item-value="id"
      :filter="filter"
      :label="$t('utils.contactSelectorLabel')"
      :hide-no-data="!queryText || !!emailError"
      :search-input.sync="queryText"
      :loading="isFetchingContacts || isAdding"
      :error-messages="emailError"
      :menu-props="{ offsetY: true }"
      hide-selected
      dense
      small-chips
      outlined
      active
      @change="selectedContactUpdated()"
      @keypress.enter="addContact()"
    >
      <template #no-data>
        <div class="no_data_section">
          <div class="label" v-text="`${$t('utils.create')} : `" />
          <div class="value" v-text="queryText" />
          <BaseButton
            type="primary"
            small
            :loading="isAdding"
            @click.stop.prevent="addContact()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </template>
      <template #selection="{ item }">
        <v-chip color="primary" label small>
          <span class="pr-2">
            {{ item.email }}
          </span>
          <v-icon small @click="deselectContact()">
            {{ "$mdiClose" }}
          </v-icon>
        </v-chip>
      </template>
      <template #item="{ item }">
        <div class="contact_item">
          <div>
            {{ item.email }}
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ClientAPI from "@/modules/Client/Services/client.api";
export default {
  name: "OrganizationContactSelector",
  props: {
    value: {
      type: Number,
      default: null,
    },
    organizationId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isFetchingContacts: false,
    selectedContact: null,
    emailError: "",
    isAdding: false,
    queryText: "",
    contacts: [],
    pageSize: 100,
    page: 1,
    next: null,
  }),
  computed: {
    filteredContacts() {
      return [...this.contacts].sort((a, b) => a.id - b.id);
    },
  },
  watch: {
    value(val) {
      this.selectedContact = val;
    },
    queryText() {
      this.emailError = "";
    },
    organizationId: {
      handler() {
        this.fetchOrganizationContacts();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchOrganizationContacts() {
      this.isFetchingContacts = true;
      do {
        await this.getOrganizationByPage(this.page);
        this.page++;
      } while (this.next);
      this.isFetchingContacts = false;
    },
    async getOrganizationByPage(page) {
      if (!isNaN(this.organizationId)) {
        const res = await ClientAPI.getOrganizationClients(
          this.organizationId,
          page,
          this.pageSize
        );
        this.contacts = [...this.contacts, ...res.data.results];
        this.next = res.data.next;
      }
    },
    filter(item, queryText) {
      if (!queryText) return true;
      return (
        item.email &&
        item.email.toLowerCase().includes(queryText.trim().toLowerCase())
      );
    },
    async addContact() {
      if (!this.validateEmail(this.queryText))
        return (this.emailError = this.$t(
          "folder.askInformationForm.validation.emailInvalid"
        ));
      this.isAdding = true;
      const newContact = (
        await ClientAPI.postOrganizationCreateClient(this.organizationId, {
          email: this.queryText.toLowerCase(),
        })
      ).data;
      this.contacts.push(newContact);
      this.selectedContact = newContact.id;
      this.selectedContactUpdated();
      this.queryText = "";
      this.isAdding = false;
      this.$refs.autocompleteInput?.blur();
    },
    deselectContact() {
      this.selectedContact = null;
      this.selectedContactUpdated();
    },
    selectedContactUpdated() {
      this.$emit("input", this.selectedContact ? this.selectedContact : null);
    },
    validateEmail(email) {
      if (!email) return false;
      return email.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.input_group {
  .label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1em;
    color: #cfcfdc;
  }
  ::v-deep {
    .v-input__control .v-input__slot {
      & > fieldset {
        border: 1px solid #cfcfdc;
      }
    }
  }
}
.no_data_section {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  .value {
    margin: 0 8px;
    flex-grow: 1;
  }
}
.contact_item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: center;
    &::v-deep {
      .label {
        font-size: 12px;
      }
    }
  }
}

.v-select--is-menu-active {
  ::v-deep .v-input__slot > fieldset {
    border: 2px solid #2536cc !important;
  }
}
</style>
