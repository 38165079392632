var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.record && _vm.question)?_c('BaseModal',{attrs:{"value":_vm.dialogState,"modal-title":_vm.getName,"need-action":_vm.askModificationClicked},on:{"close":function($event){return _vm.reset()}},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('form',[(_vm.question.answer && _vm.question.answer.extractedData)?[_c('FolderQuestionWizideeData',{attrs:{"wizidee-data":_vm.question.answer && _vm.question.answer.extractedData,"question":_vm.question}})]:(_vm.question.answer)?_c('BaseRow',[_c('BaseCol',[(
              /^DATAGRID|ROW_DATAGRID$/.test(_vm.question.typeQuestion) &&
              _vm.question.answer.content
            )?_c('QuestionDatagridPreviwer',{staticClass:"mb-1",attrs:{"question":_vm.question}}):_c('RecordQuestionValidation',{attrs:{"question":_vm.question,"ask-modification-clicked":_vm.askModificationClicked}})],1)],1):_vm._e(),(
          _vm.question.validation == 'PENDING' || _vm.question.validation == 'UPDATED'
        )?_c('div',{staticClass:"d-flex justify-end mt-4"},[(
            !(_vm.record.course && _vm.record.course.anonymous) &&
            !(_vm.askModificationClicked || _vm.isArchivedFolder)
          )?_c('BaseButton',{staticClass:"mx-2",attrs:{"type":"primary","icon":"$mdiPlaylistEdit"},on:{"click":function($event){_vm.askModificationClicked = true}}},[_vm._v(" "+_vm._s(_vm.$t("folder.validationForm.askModification"))+" ")]):_vm._e(),(
            !_vm.askModificationClicked &&
            _vm.question.validation !== 'VALIDATED' &&
            !_vm.recordAnonymous
          )?_c('BaseButton',{staticClass:"mx-2",attrs:{"disabled":_vm.isArchivedFolder,"loading":_vm.isSubmiting,"icon":"$mdiCheck","type":"primary"},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")]):_vm._e()],1):_vm._e(),(_vm.askModificationClicked)?_c('div',{staticClass:"email-container"},[_c('div',{staticClass:"title-container"},[_c('span',{staticClass:"text sub-text",domProps:{"textContent":_vm._s(((_vm.$tc('utils.recipient', 3)) + " : "))}}),_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.record.client.email)}})]),_c('BaseMarkdownEditor',{attrs:{"placeholder":'Corps du mail',"with-link":false},on:{"change":function($event){return _vm.$v.formData.content.$touch()},"blur":function($event){return _vm.$v.formData.content.$touch()}},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1):_vm._e()],2)]},proxy:true},(_vm.askModificationClicked && !_vm.recordAnonymous)?{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"mx-2 ml-auto",attrs:{"text":"","type":"secondary","color":"#707080"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")]),_c('BaseButton',{attrs:{"disabled":_vm.$v.formData.$invalid || _vm.isArchivedFolder,"loading":_vm.isSubmiting,"type":"primary"},on:{"click":function($event){return _vm.invalidate()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])]},proxy:true}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }