<template>
  <v-expansion-panel
    class="expansion-panel elevation-1"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-expansion-panel-header :class="`${type}--bg--color`">
      <slot name="header" />
    </v-expansion-panel-header>
    <v-expansion-panel-content :eager="eager" :class="`${type}--bg--color`">
      <slot name="content" />
    </v-expansion-panel-content>
    <slot name="footer" />
  </v-expansion-panel>
</template>

<script>
export default {
  name: "BaseFrontOfficeExpansionPanel",
  props: {
    eager: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.expansion-panel {
  border-radius: 15px !important;
  margin-top: 16px;
  overflow: hidden;
}
.expansion-panel::before {
  box-shadow: none;
}
.expansion-panel::after {
  border-top: none !important;
}
::v-deep .v-expansion-panel-header {
  background-color: #ebeffe !important;
}
::v-deep .v-expansion-panel-content {
  padding-top: 16px;
}
.primary--bg--color {
  background: var(--v-walletPrimary-lighten5) !important;
}
</style>
