export default class WalletFolders {
  constructor({
    id = Number.NaN,
    dateCreated = new Date(),
    dateUpdated = new Date(),
    name = "",
  } = {}) {
    this.id = Number.parseInt(id);
    this.dateCreated = new Date(dateCreated);
    this.dateUpdate = new Date(dateUpdated);
    this.name = name;
  }
}
