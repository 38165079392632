<template>
  <div class="field_container">
    <div v-if="label" class="question_name" v-text="label" />
    <div class="question_field">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseField",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.field_container {
  position: relative;
  padding: 8px 24px 0px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  border: 3px solid #b6bdff;
  border-radius: 10px;

  .question_name {
    font-size: 12px;
    line-height: 12px;
    padding: 4px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(32px, -50%);
    max-width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .question_field {
    width: 100%;
    padding-bottom: 8px;
    &::v-deep {
      .v-input {
        margin: 0px !important;
      }
    }
  }
}
</style>
