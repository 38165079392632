<template>
    <v-icon>
      {{ svgPath }}
    </v-icon>
  </template>
  
  <script>
 import { mdiLogout } from "@mdi/js";
  export default {
    name: "MdiLogout",
    data() {
      return {
        svgPath: mdiLogout,
      };
    },
  };
  </script>
  