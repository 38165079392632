import VueI18n from "vue-i18n";
import fr from "vuetify/es5/locale/fr";
import en from "vuetify/es5/locale/en";

const messages = {
  en: {
    $vuetify: en,
  },
  fr: {
    $vuetify: fr,
  },
};
export const Locale = new VueI18n({
  locale: "fr",
  messages,
});
