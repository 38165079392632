var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cosmoform_container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"bord",domProps:{"textContent":_vm._s(_vm.$t('cosmoform.thanks.title'))}}),_c('div',{staticClass:"banner",class:{
        banner_center:
          !_vm.cosmoformOrganization || !_vm.cosmoformOrganization.organization.logo,
      }},[(
          _vm.cosmoformOrganization && _vm.cosmoformOrganization.organization.logo
        )?_c('div',{staticClass:"logo_container"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.cosmoformOrganization.organization.logo}})]):_vm._e(),_c('div',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.$t('cosmoform.thanks.message'))}})]),(
        _vm.user &&
        _vm.cosmoformOrganization &&
        !_vm.cosmoformOrganization.isPublic &&
        _vm.folderId
      )?_c('div',{staticClass:"actions"},[_c('v-btn',{attrs:{"color":"primary","rounded":"","x-large":"","to":{
          name: 'WalletOrganizationRecordsFolder',
          params: {
            organizationId: _vm.cosmoformOrganization.organization.id,
            folderID: _vm.folderId,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("utils.cancel"))+" ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }