export default class Oauth {
  static GrantType = {
    password: "password",
    refreshToken: "refresh_token",
  };
  static clientId = process.env.VUE_APP_OAUTH_CLIENT_ID;
  static clientSecret = process.env.VUE_APP_OAUTH_CLIENT_SECRET;

  static get localStorage() {
    let oauth = JSON.parse(localStorage.getItem("oauth"));
    oauth = oauth ? new Oauth(oauth) : null;
    return oauth;
  }
  static set localStorage(value) {
    if (value) {
      localStorage.setItem("oauth", JSON.stringify(value));
    } else {
      localStorage.removeItem("oauth");
    }
  }

  constructor({
    accessToken = "",
    refreshToken = "",
    expiresIn = Number.NaN,
    tokenType = "",
    scope = "",
  } = {}) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.expiresIn = expiresIn;
    this.tokenType = tokenType;
    this.scope = scope;
  }
}
