<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiDotsHorizontal } from "@mdi/js";
export default {
  name: "MdiDotsHorizontal",
  data() {
    return {
      svgPath: mdiDotsHorizontal,
    };
  },
};
</script>
