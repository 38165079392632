let plugins = [];

// Import Stores
import Course from "./course";

let stores = {
  course: Course,
};

export { plugins, stores };
