<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { setBearerToken } from "../Api/cosmoAxiosInstance";
import Oauth from "../Models/Oauth";

export default {
  name: "SimpleLayout",
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (Oauth.localStorage) {
        const oauth = Oauth.localStorage;
        try {
          setBearerToken(oauth.accessToken);
        } catch (error) {
          this.$store.dispatch("core/resetApp");
        }
      }
    },
  },
};
</script>
