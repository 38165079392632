import Status from "./Status";

export default class TargetStatus {
  constructor({
    id = Number.NaN,
    label = "",
    statusFrom = new Status(),
    statusTo = new Status(),
    dateCreated = new Date(),
    dateUpdated = new Date(),
  } = {}) {
    this.id = Number.parseInt(id);
    this.label = label;
    this.statusFrom = statusFrom ? new Status(statusFrom) : null;
    this.statusTo = statusTo ? new Status(statusTo) : null;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
  }
}
