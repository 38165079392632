<template>
  <v-container
    class="cosmo-wallet-main-wrapper pt-0"
    :class="{ 'wallet-wrapper-fluid': fluid, 'wallet-wrapper-small': small }"
  >
    <div class="cosmo-wallet-main-title mx-n0 mx-sm-n1">
      <BaseFrontOfficeBoard type="title">
        <BaseFrontOfficeImage
          v-if="mobileTitleImage"
          class="d-block d-sm-none cosmo-wallet-main-title-image"
          width="90"
          height="90"
          :src="mobileTitleImage"
          style="border-radius: 50%; overflow: hidden"
        />
        <div
          class="d-flex align-center"
          :class="{
            'justify-space-between': goBackActive,
            'justify-center': !goBackActive,
          }"
        >
          <BaseFrontOfficeIcon
            v-if="goBackActive"
            icon="$mdiChevronLeftCircleOutline"
            color="#444393"
            @click.prevent="goBack"
          />
          <slot name="title" />
          <div v-if="goBackActive" style="width: 24px; height: 24px" />
        </div>
        <div v-if="mobileTitleMessage" class="d-block d-sm-none">
          <BaseDivider class="my-4" grey />
          <span v-text="mobileTitleMessage" />
        </div>
      </BaseFrontOfficeBoard>
    </div>
    <div class="cosmo-wallet-content">
      <slot />
    </div>
  </v-container>
</template>

<script>
export default {
  name: "BaseFrontOfficeWalletWrapper",
  props: {
    mobileTitleImage: {
      type: String,
      default: null,
    },
    mobileTitleMessage: {
      type: String,
      default: "",
    },
    goBackActive: {
      type: Boolean,
    },
    goBackPath: {
      type: Object,
      default: null,
    },
    fluid: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
  },
  methods: {
    goBack() {
      this.$router.push(this.goBackPath);
    },
  },
};
</script>

<style scoped>
.cosmo-wallet-main-wrapper {
  position: relative;
  width: 95%;
  margin-top: -15px !important;
}

.wallet-wrapper-small {
  width: 40%;
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.cosmo-wallet-main-title {
  position: sticky;
  top: 80px;
  z-index: 101;
}
.cosmo-wallet-main-title-image {
  position: relative;
  margin: auto;
  margin-top: -62px;
  width: 90px;
}
.cosmo-wallet-wrapper * {
  z-index: 101 !important;
}
@media (max-width: 1264px) {
  .cosmo-wallet-main-wrapper {
    max-width: 95%;
    margin: auto;
    min-width: 95%;
  }
}
@media all and (max-width: 600px) {
  .cosmo-wallet-main-wrapper {
    width: 95vw;
    margin: auto;
    margin-bottom: 84px;
    margin-top: -40px !important;
  }
  .cosmo-wallet-main-title {
    position: initial;
    margin-top: 100px;
  }
}
</style>
