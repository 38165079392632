<template>
  <div>
    <Board>
      <template #header>
        <BaseTitle class="mb-4" v-text="$tc('organization.campaign', 10)" />
        <BaseParagraph
          text="secondary"
          v-text="$t('course.campaignDescription')"
        />
      </template>
      <template #main>
        <BaseDataTable
          :items="campaigns"
          :loading="isFetchingCourseCampaign"
          :headers="CAMPAIGNS_HEADER"
          class="elevation-1"
          :no-data-text="$tc('organization.campaign', 0)"
        >
          <template #item="{ item }">
            <tr
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'campaignDetail',
                  params: {
                    organizationId: $route.params.organizationId,
                    courseId: $route.params.courseId,
                    campaignId: item.id,
                  },
                })
              "
            >
              <td>
                <BaseDescription v-text="item.name" />
              </td>
              <td class="text-center">
                <div v-if="Number.parseInt(item.assign.id)">
                  <BaseDescription v-text="item.assign.email" />
                </div>
                <div v-else>
                  <BaseDescription
                    text="disabled"
                    italic
                    v-text="$t('answer.noContent')"
                  />
                </div>
              </td>
              <td class="text-center">
                <BaseDescription
                  v-if="item.description"
                  v-text="
                    item.description.length > 50
                      ? item.description.substring(0, 49) + '...'
                      : item.description
                  "
                />
                <BaseDescription
                  v-else
                  text="disabled"
                  italic
                  v-text="$t('answer.noContent')"
                />
              </td>
              <td class="text-center">
                <BaseChip v-if="/^DRAFT$/.test(item.status)" type="tertiary">
                  {{ $tc("campaign.status.draft") }}
                </BaseChip>
                <BaseChip
                  v-else-if="/^IN_PROGRESS$/.test(item.status)"
                  type="secondary"
                >
                  {{ $tc("campaign.status.launched") }}
                </BaseChip>
                <BaseChip
                  v-else-if="/^CLOSED$/.test(item.status)"
                  type="primary"
                >
                  {{ $tc("campaign.status.closed") }}
                </BaseChip>
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </template>
      <template #footer>
        <div class="d-flex justify-center">
          <BaseButton
            v-if="!course.archived"
            :disabled="!course.isValid"
            icon="$mdiPlus"
            @click="
              () => {
                $store.commit('course/setCampaignCreateAndEditDialog', true);
              }
            "
          >
            {{ $t("campaign.new") }}
          </BaseButton>
        </div>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import { mapState } from "vuex";

export default {
  name: "CourseDetailCampaign",
  components: {
    Board,
  },
  data() {
    return {
      CAMPAIGNS_HEADER: [
        {
          text: this.$tc("organization.campaign", 10),
          value: "name",
          sortable: false,
        },
        {
          text: this.$tc("utils.personInCharge", 1),
          value: "assign",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.description", 1),
          value: "typeQuestion",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.status", 1),
          value: "logicjumps",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      course: (state) => state.course.course,
      campaigns: (state) => state.course.campaigns,
      isFetchingCourseCampaign: (state) =>
        state.course.isFetchingCourseCampaign,
      user: (state) => state.account.user,
    }),
  },
};
</script>
