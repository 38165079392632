var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item_header",on:{"click":function($event){$event.stopPropagation();return _vm.changeExpandStatus()}}},[_c('div',{class:_vm.classObject},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiLogin"}})],1),_c('div',{staticClass:"sections"},[_c('div',{staticClass:"section_top"},[_c('div',{staticClass:"section_left"},[_c('div',{staticClass:"section_title",domProps:{"textContent":_vm._s(_vm.record.courseName)}}),_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(
            _vm.$t('folder.createdAt', {
              date: _vm.$d(new Date(_vm.record.dateCreated), 'detailed'),
            })
          )}}),(_vm.statusNotAgreed && _vm.record.reminders.length > 1)?_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(
            _vm.$t('folder.lastRelaunched', {
              date: _vm.$d(new Date(_vm.getLastRelaunchDate()), 'detailed'),
            })
          )}}):_vm._e()]),_c('div',{staticClass:"section_center"},[_c('div',{staticClass:"section_title",domProps:{"textContent":_vm._s(_vm.$tc('utils.contact', 1))}}),(_vm.record.client && _vm.record.client.fullName)?_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(_vm.record.client.fullName)}}):_vm._e(),_c('div',{staticClass:"section_footer",class:{ text_primary: _vm.record.status != 'INVITED' },domProps:{"textContent":_vm._s(_vm.record.email)}})]),_c('div',{staticClass:"section_right"},[_c('div',{staticClass:"chips",class:_vm.recordStatus.chipsClass},[_vm._v(" "+_vm._s(_vm.recordStatus.label)+" ")])])]),(_vm.lastEmailStatus)?_c('div',{staticClass:"email_status"},[_c('div',{staticClass:"email_status_label",domProps:{"textContent":_vm._s(_vm.$tc('utils.emailStatus', 1))}}),_c('div',{staticClass:"email_status_chips",class:_vm.lastEmailStatus.className},[_c('div',{staticClass:"chips_icon"},[_c('BaseIcon',{attrs:{"small":"","icon":_vm.lastEmailStatus.icon}})],1),_c('div',{staticClass:"chips_label",domProps:{"textContent":_vm._s(_vm.lastEmailStatus.label)}})])]):_vm._e()]),_c('div',{staticClass:"icon_right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeExpandStatus()}}},[_c('BaseIcon',{attrs:{"icon":_vm.expanded ? '$mdiChevronUp' : '$mdiChevronDown'}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }