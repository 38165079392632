<template>
  <BaseFrontOfficeDialog
    :value="dialogState"
    max-width="850"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <template #modal>
      <div v-if="personalData" class="modal_container">
        <template>
          <div class="d-flex justify-end">
            <BaseFrontOfficeButtonIcon icon="$mdiClose" @click="close()" />
          </div>
          <div class="chat_item">
            <BaseImage class="chat_avatar" :src="$findStatic('cosmoAvatar')" />
            <div
              class="chat_content"
              :class="{ full_width: computedQuestion.wizideeData }"
            >
              <template>
                <div class="mb-3">
                  <BaseFrontOfficeParagraph v-text="computedQuestion.label" />
                </div>
                <div>
                  <WalletPreviewWizideeData
                    v-if="
                      /^FILE|SIGNATURE$/.test(computedQuestion.typeQuestion) &&
                      computedQuestion.wizideeData
                    "
                    :question="computedQuestion"
                    :wizidee-data="computedQuestion.wizideeData"
                  />
                  <WalletPreviewData
                    v-else
                    :name="computedQuestion.name"
                    full-image
                    :question="computedQuestion"
                  />
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </BaseFrontOfficeDialog>
</template>

<script>
import WalletPreviewData from "../Components/WalletPreviewData";
import WalletPreviewWizideeData from "./WalletPreviewWizideeData.vue";
export default {
  name: "WalletDisplayPersonalDataDialog",
  components: {
    WalletPreviewData,
    WalletPreviewWizideeData,
  },
  props: {
    dialogState: {
      type: Boolean,
    },
    personalData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    computedQuestion() {
      if (this.personalData) {
        const personalData = { ...this.personalData };
        return {
          typeQuestion: personalData.datatype.typeQuestion,
          choices: personalData.datatype.choices,
          name: this.personalData.datatype.name,
          helpText: "",
          label: personalData.datatype.name,
          required: false,
          datatypeId: personalData.datatype.id,
          datatype: { ...personalData.datatype, personalData: personalData },
          wizideeData: personalData.wizideeData,
          answer: null,
        };
      }
      return {};
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_container {
  padding: 24px;
  background: #fff;
  .chat_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .chat_avatar {
      width: 32px;
      height: 32px !important;
      flex: none;
    }
    .chat_content {
      background-color: #f7f7f7;
      border-radius: 0px 20px 20px 20px;
      padding: 16px 24px;
      position: relative;
      font-size: 18px !important;
      line-height: 22px;
      font-family: var(--cosmo-font-nunito);
      color: #4f4f4f;
      min-width: 50%;
      margin: 0 12px;
      &.full_width {
        width: calc(100% - 64px);
      }
      @media (max-width: 600px) {
        width: calc(100% - 24px);
      }
    }
  }
}
</style>
