import { render, staticRenderFns } from "./FolderSendDataToDPAEDialog.vue?vue&type=template&id=6d046992&scoped=true"
import script from "./FolderSendDataToDPAEDialog.vue?vue&type=script&lang=js"
export * from "./FolderSendDataToDPAEDialog.vue?vue&type=script&lang=js"
import style0 from "./FolderSendDataToDPAEDialog.vue?vue&type=style&index=0&id=6d046992&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d046992",
  null
  
)

export default component.exports