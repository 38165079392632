<template>
  <v-autocomplete
    v-bind="$attrs"
    outlined
    dense
    :class="{ focused: isFocused, 'error-input': hasError }"
    @focusin="isFocused = true"
    @focusout="isFocused = false"
    v-on="$listeners"
  >
    <template v-if="needSelection" #item="{ item }">
      <slot name="item" :item="item" />
    </template>
    <template #append-item>
      <slot name="append-item" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "BaseAutoComplete",
  props: {
    hasError: {
      type: Boolean,
    },
    needSelection: {
      type: Boolean,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__title {
  font-size: 14px !important;
}
::v-deep .v-input__slot {
  box-shadow: none !important;
}
.focused {
  ::v-deep .v-input__slot {
    color: #2536cc !important;
    box-shadow: 0px 0px 12px 4px #ebf2ff !important;
  }
}
.error-input {
  ::v-deep .v-input__slot {
    color: #ff5267 !important;
    box-shadow: 0px 0px 8px 2px #ffc6c6 !important;
  }
}
::v-deep .v-list-item--link {
  margin: 8px;
  &:hover {
    background: rgba(155, 189, 255, 0.2);
    border-radius: 5px;
    overflow: hidden;
  }
}
::v-deep .v-list-item--active {
  background: rgba(155, 189, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
</style>
