<template #header>
  <div class="stepper_header_container">
    <template v-if="loading">
      <div class="stepper_header_title">
        <v-skeleton-loader class="skeleton" type="text" />
      </div>
    </template>
    <template v-else>
      <div class="stepper_header_title" v-text="title" />
    </template>
    <div class="stepper_steps">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseStepperHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lastStep: 0,
    };
  },
  computed: {
    consultedStep: {
      set: function (value) {
        if (this.$parent) {
          this.$parent.consultedStep = value;
        }
      },
      get: function () {
        return this.$parent ? this.$parent.consultedStep : 0;
      },
    },
    loading() {
      return this.$parent?.loading;
    },
  },
  mounted() {
    if (this.$children) {
      this.lastStep = Math.max(
        ...this.$children.map((child) => (child.step ? child.step : 0))
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.stepper_header_container {
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border: 0.5px solid #ecebf1;
  border-radius: 5px;
  margin-bottom: 24px;
  .stepper_header_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #707080;
    margin-bottom: 24px;
    .skeleton {
      height: 16px;
      width: 70px;
      ::v-deep {
        .v-skeleton-loader__bone {
          height: 12px;
        }
      }
    }
  }
  .stepper_steps {
    width: 100%;
    display: flex;
  }
}
</style>
