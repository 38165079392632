var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input_group"},[_c('v-autocomplete',{attrs:{"items":_vm.filteredQuestionCategories,"filter":_vm.filter,"placeholder":_vm.placeHolder,"hide-selected":"","dense":"","item-value":"id","hide-no-data":!_vm.queryText || !!_vm.newCategoryNameError,"search-input":_vm.queryText,"loading":_vm.isFetchingCategories || _vm.isAdding,"error-messages":_vm.newCategoryNameError,"small-chips":"","outlined":"","menu-props":{ offsetY: true },"active":true,"label":_vm.$t('utils.category')},on:{"update:searchInput":function($event){_vm.queryText=$event},"update:search-input":function($event){_vm.queryText=$event},"change":function($event){return _vm.selectedCategoryUpdated()},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addCategory()},"focus":function($event){return _vm.onFocus()},"focusout":function($event){return _vm.onFocus()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"no_data_section"},[_c('div',{staticClass:"label",domProps:{"textContent":_vm._s(((_vm.$t('utils.create')) + " : "))}}),_c('div',{staticClass:"value",domProps:{"textContent":_vm._s(_vm.queryText)}}),_c('BaseButton',{attrs:{"disabled":!!_vm.newCategoryNameError,"type":"primary","small":"","loading":_vm.isAdding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addCategory()}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","label":"","small":""}},[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deselectCategory()}}},[_vm._v(" "+_vm._s("$mdiClose")+" ")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caterogy_item"},[[(_vm.editingCategory && _vm.editingCategory.id === item.id)?[_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"click":function($event){$event.stopPropagation();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.editCategory(item)}},model:{value:(_vm.editCategoryName),callback:function ($$v) {_vm.editCategoryName=$$v},expression:"editCategoryName"}}),_c('BaseButton',{attrs:{"disabled":!_vm.editCategoryName,"type":"primary","small":"","loading":_vm.editingCategory.id === item.id && _vm.isSubmiting},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editCategory(item)}}},[_vm._v(" "+_vm._s(_vm.$t("utils.validate"))+" ")])]:[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('BaseIcon',{attrs:{"icon":"$mdiDotsHorizontal"}})],1)],1)]}}],null,true)},[_c('div',{staticClass:"menu_list"},[_c('v-btn',{staticClass:"menu_list_item",attrs:{"block":"","text":""},on:{"click":function($event){return _vm.changeToEditMode(item)}}},[_c('BaseIcon',{attrs:{"small":"","left":"","icon":"$mdiPencilOutline","color":"primary"}}),_c('span',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.$t('utils.edit'))}})],1),_c('v-btn',{staticClass:"menu_list_item",attrs:{"block":"","text":""},on:{"click":function($event){return _vm.removeCategory(item)}}},[_c('BaseIcon',{attrs:{"small":"","left":"","icon":"$mdiTrashCanOutline","color":"secondary"}}),_c('span',{staticClass:"label",domProps:{"textContent":_vm._s(_vm.$t('utils.delete'))}})],1)],1)])]]],2)]}}]),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }