<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiClipboardEditOutline } from "@mdi/js";
export default {
  name: "MdiClipboardEditOutline",
  data() {
    return {
      svgPath: mdiClipboardEditOutline,
    };
  },
};
</script>
