<template>
  <div>
    <v-form ref="form" @submit.prevent="submit()">
      <div v-if="/^TEXT$/.test(question.typeQuestion)">
        <BaseTextField
          v-model="form.value"
          :autofocus="focus"
          :label="question.name"
          :error-messages="valueErrors"
          :has-error="!!valueErrors"
          :hint="question.helpText"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^TEXTAREA$/.test(question.typeQuestion)">
        <BaseTextArea
          v-model="form.value"
          :autofocus="focus"
          :label="question.name"
          :error-messages="valueErrors"
          :has-error="!!valueErrors"
          :hint="question.helpText"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div
        v-else-if="/^DATE$/.test(question.typeQuestion)"
        class="d-flex justify-center"
      >
        <v-date-picker
          v-model="form.value"
          class="d-inline-block"
          locale="fr-fr"
          :first-day-of-week="1"
          :label="question.name"
          :error-messages="valueErrors"
          :hint="question.helpText"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div
        v-else-if="/^DATETIME$/.test(question.typeQuestion)"
        class="d-flex justify-center"
      >
        <v-date-picker
          v-model="form.value"
          class="d-inline-block mr-2"
          locale="fr-fr"
          :first-day-of-week="1"
          :label="question.name"
          :error-messages="valueErrors"
          :hint="question.helpText"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
        <v-time-picker
          v-model="form.time"
          class="d-inline-block"
          height="200"
          format="24hr"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^NUMBER$/.test(question.typeQuestion)">
        <BaseTextField
          v-model="form.value"
          :autofocus="focus"
          type="number"
          :label="question.name"
          :error-messages="valueErrors"
          :has-error="!!valueErrors"
          :hint="question.helpText"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^SELECT$/.test(question.typeQuestion)">
        <BaseSelect
          v-model="form.value"
          :items="question.choices"
          :autofocus="focus"
          item-text="name"
          item-value="id"
          :label="question.name"
          :error-messages="valueErrors"
          :has-error="!!valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^RADIOBUTTON$/.test(question.typeQuestion)">
        <v-radio-group
          v-model="form.value"
          :label="question.name"
          :hint="question.helpText"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        >
          <v-radio
            v-for="(choice, index) in question.choices"
            :key="index"
            :label="choice.name"
            :value="choice.id"
          />
        </v-radio-group>
      </div>
      <div v-else-if="/^CHECKBOX$/.test(question.typeQuestion)">
        <BaseParagraph type="primary" v-text="question.name" />
        <v-checkbox
          v-for="(choice, index) in question.choices"
          :key="index"
          v-model="form.value"
          :label="choice.name"
          :value="choice.id"
          :error-messages="
            question.choices.length == index + 1
              ? valueErrors
              : valueErrors != ''
              ? ' '
              : null
          "
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div v-else-if="/^FILE$/.test(question.typeQuestion)">
        <BaseFileInput
          :val="form.value"
          truncate-length="50"
          append-icon="$mdiDownload"
          prepend-icon=""
          show-size
          :clearable="false"
          outlined
          :label="question.name"
          :hint="question.helpText"
          @blur="$v.form.value.$touch()"
          @changed="fileChange"
        />
      </div>
      <div v-else-if="/^MULTIPLE_FILE$/.test(question.typeQuestion)">
        <folder-offer-file-drag
          :offer-files="form.value"
          nature="secondary"
          :need-iteration="false"
          @file-droped="fileInserted"
          @file-name-changed="updateFileName"
          @delete-offer-file="removeFile"
        />
      </div>
      <div
        v-else-if="/^SIGNATURE$/.test(question.typeQuestion)"
        class="d-flex justify-center"
      >
        <VueSignaturePad
          ref="signaturePad"
          v-model="form.value"
          style="position: relative"
          class="elevation-4"
          width="400px"
          height="400px"
          :options="signaturePadOptions"
        />
        <BaseSheet
          color="grey lighten-3"
          class="image-action edit rounded-b-circle rounded-t-circle elevation-4"
        >
          <BaseButtonIcon
            :disabled="!form.value"
            icon="$mdiUndoVariant"
            color="primary"
            @click="
              () => {
                $refs.signaturePad.undoSignature();
                signaturePadOnEnd();
              }
            "
          />
        </BaseSheet>
        <BaseSheet
          color="grey lighten-3"
          class="image-action mt-14 edit rounded-b-circle rounded-t-circle elevation-4"
        >
          <BaseButtonIcon
            :disabled="!form.value"
            icon="$mdiTrashCanOutline"
            color="secondary"
            @click="
              () => {
                $refs.signaturePad.clearSignature();
                signaturePadOnEnd();
              }
            "
          />
        </BaseSheet>
      </div>
    </v-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";
import FolderOfferFileDrag from "./FolderOffer/FolderOfferFileDrag.vue";

export default {
  name: "QuestionForm",
  components: { FolderOfferFileDrag },
  mixins: [validationMixin],
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    focus: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [Object, String, Number, Boolean],
      default: null,
    },
  },
  data() {
    return {
      form: {
        value: null,
        time: null,
      },
      signaturePadOptions: {
        penColor: "#000",
        onEnd: this.signaturePadOnEnd,
      },
    };
  },
  computed: {
    valueErrors() {
      if (!this.$v.form.value.$dirty) {
        return "";
      }
      if (this.question.required && !this.$v.form.value.required) {
        return "Le champ est requis";
      }
      if (
        /^NUMBER$/.test(this.question.typeQuestion) &&
        !this.$v.form.value.integer
      ) {
        return "Le champ doit être un nombre entier";
      }
      return "";
    },
  },
  watch: {
    question(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
    "form.value"() {
      this.submit();
    },
    "form.time"() {
      this.submit();
    },
  },
  validations() {
    let validations = {
      form: {
        value: {},
        time: {},
      },
    };
    if (this.question.required) {
      validations.form.value = {
        required,
      };
      if (/^DATETIME$/.test(this.question.typeQuestion)) {
        validations.form.time = {
          required,
        };
      }
    }
    if (/^DATETIME$/.test(this.question.typeQuestion)) {
      if (this.form.value) {
        validations.form.time = {
          required,
        };
      }
      if (this.form.time) {
        validations.form.value = {
          required,
        };
      }
    }
    if (/^NUMBER$/.test(this.question.typeQuestion)) {
      validations.form.value = {
        ...validations.form.value,
        integer,
      };
    }
    return validations;
  },

  mounted() {
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.$v?.form?.$reset();
      this.form = {
        value: null,
        time: null,
      };
    },
    initialize() {
      /**
       * Workaround because signaturePad size sets to 0 if in a dialog.
       * When page is initialized, we set it to right size
       * Link https://github.com/neighborhood999/vue-signature-pad/issues/62#issuecomment-458891526
       */
      if (/^SIGNATURE$/.test(this.question.typeQuestion)) {
        this.$nextTick(function () {
          this.$refs.signaturePad.resizeCanvas();
        });
      }
      /**
       * if there is already an answer we set the right input to it's actual
       * value
       */
      if (this.question.answer) {
        switch (this.question.typeQuestion) {
          case "TEXT":
          case "TEXTAREA":
          case "DATE":
          case "NUMBER":
            this.form.value = this.question.answer.content;
            break;
          case "SELECT":
          case "RADIOBUTTON":
            this.form.value = this.question.answer.choices[0].id;
            break;
          case "CHECKBOX":
            this.form.value = this.question.answer.choices.map(
              (choice) => choice.id
            );
            break;
          case "DATETIME":
            {
              const timeContent = this.question.answer.content.replace(
                ":00+00:00",
                ""
              );
              this.form.value = timeContent.substr(0, 10);
              this.form.time = timeContent.substr(-5);
            }
            break;
          case "SIGNATURE":
          case "FILE":
            this.$store.commit("folder/setCanBeSentToGed", false);
            break;
          default:
            this.form.value = this.question.answer.choices;
            break;
        }
      } else {
        if (/^CHECKBOX$/.test(this.question.typeQuestion)) {
          this.form.value = [];
        }
      }
    },
    isContentTypeQuestion() {
      return /^TEXT|TEXTAREA|DATE|DATETIME|NUMBER|FILE|SIGNATURE$/.test(
        this.question.typeQuestion
      );
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    fileChange(event) {
      if (!event?.value) return;
      this.form.value = event.value;
      this.$v.form.value.$touch();
    },
    fileInserted({ value }) {
      // insert file locally to use vuelidate+vuex
      let fileList = [];
      for (const file of value) {
        fileList.push(file);
      }
      if (Array.isArray(this.form.value)) {
        this.form.value = [...this.form.value, ...value];
      }
      return (this.form.value = fileList);
    },
    updateFileName({ value, index }) {
      const newFile = new File([this.form.value[index]], `${value}`, {
        type: this.form.value[index].type,
      });
      this.form.value.splice(index, 1, newFile);
    },
    removeFile(index) {
      this.form.value.splice(index, 1);
    },
    /**
     * Function to build the question payload in order to make it ready for the endpoints
     */
    async buildAnswer() {
      let payload = {};
      switch (this.question.typeQuestion) {
        case "TEXT":
        case "TEXTAREA":
        case "DATE":
        case "NUMBER":
        case "SIGNATURE":
          payload.content = this.form.value ? this.form.value : null;
          break;
        case "FILE":
          {
            const base64 = this.form.value
              ? await this.fileToBase64(this.form.value)
              : null;
            payload.content = base64;
            payload.fileName = this.form.value?.name;
          }
          break;
        case "MULTIPLE_FILE":
          {
            const values = this.form.value.map(async (file) => ({
              name: file.name,
              content: await this.fileToBase64(file),
            }));
            const result = await Promise.all(values);
            payload.content = result;
          }
          break;
        case "SELECT":
        case "RADIOBUTTON":
        case "CHECKBOX":
          payload.choices = this.form.value;
          break;
        case "DATETIME":
          payload.content =
            this.form.value.substr(0, 10) + " " + this.form.time;
          break;
        default:
          break;
      }
      return payload;
    },
    async submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        // If the value is empty
        if (!this.form.value) {
          this.$emit("changeValue", null);
        } else {
          const payload = await this.buildAnswer();
          this.$emit("changeValue", payload);
        }
      }
    },
    signaturePadOnEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.form.value = isEmpty ? null : data;
    },
  },
};
</script>

<style scoped>
.image-action {
  position: absolute;
  margin-left: 350px;
  margin-top: 10px;
}
</style>
