<template>
  <div class="sl_container">
    <div class="header">
      <div class="signatory_title">
        <span class="label" v-text="$t('utils.organizationDirectory')" />
        <BaseIcon class="icon" icon="$mdiDomain" />
      </div>
      <BaseButton
        class="mb-2"
        type="primary"
        icon="$mdiPlus"
        @click="openSignatoryCreateAndEditDialog()"
      >
        {{ $tc("utils.add", 4) }}
      </BaseButton>
    </div>
    <BaseDataTable
      :headers="SIGNATORIES_HEADER"
      :items="signatories"
      :loading="fetchingSignatories"
      :loading-text="$t('utils.loadingData')"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <BaseParagraph v-text="item.lastName" />
          </td>
          <td>
            <BaseParagraph v-text="item.firstName" />
          </td>
          <td class="text-center">
            <BaseParagraph v-text="item.email" />
          </td>
          <td class="text-center">
            <BaseParagraph v-text="item.phoneNumber" />
          </td>
          <td>
            <div class="d-flex justify-end">
              <span class="mx-4" />
              <BaseButtonIcon
                x-small
                color="primary"
                icon="$mdiPencilOutline"
                class="mr-2"
                @click="openSignatoryCreateAndEditDialog(item)"
              />
              <BaseButtonIcon
                x-small
                color="secondary"
                icon="$mdiTrashCanOutline"
                @click="openSignatoryRemoveDialog(item)"
              />
            </div>
          </td>
        </tr>
      </template>
    </BaseDataTable>

    <!-- MODAL -->
    <OrganizationSignatoryCreateAndEditDialog
      :dialog-state="createAndEditSignatoryDialogState"
      :signatory="selectedSignatory"
      :organization-id="organizationId"
      @close="createAndEditSignatoryDialogState = false"
      @signatoryAdded="onSignatoryAdded"
      @signatoryEdited="onSignatoryEdited"
    />
    <OrganizationSignatoryRemoveDialog
      :dialog-state="removeSignatoryDialogState"
      :signatory="selectedSignatory"
      :organization-id="organizationId"
      @close="removeSignatoryDialogState = false"
      @signatoryRemoved="onSignatoryRemoved"
    />
  </div>
</template>

<script>
import OrganizationSignatoryCreateAndEditDialog from "./OrganizationSignatoryCreateAndEditDialog";
import OrganizationSignatoryRemoveDialog from "./OrganizationSignatoryRemoveDialog";

import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { Signatory } from "@/core/Models/Signatory";

export default {
  name: "OrganizationSignatoryList",

  components: {
    OrganizationSignatoryCreateAndEditDialog,
    OrganizationSignatoryRemoveDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      SIGNATORIES_HEADER: [
        {
          text: this.$tc("utils.lastName", 1),
          value: "lastName",
        },
        {
          text: this.$t("utils.firstName"),
          value: "firstName",
        },
        {
          text: this.$tc("utils.email", 1),
          value: "email",
          align: "center",
        },
        {
          text: this.$tc("utils.phoneNumber", 1),
          value: "phoneNumber",
          align: "center",
        },
        {
          text: this.$tc("utils.action", 3),
          value: "action",
          align: "end",
          sortable: false,
        },
      ],
      signatories: [],
      selectedSignatory: null,
      fetchingSignatories: true,
      createAndEditSignatoryDialogState: false,
      removeSignatoryDialogState: false,
    };
  },
  created() {
    this.fetchSignatories();
  },
  methods: {
    async fetchSignatories() {
      try {
        this.signatories = (
          await DashboardAPI.getOrganizationSignatories({
            organizationId: this.organizationId,
          })
        ).data.results.map((s) => new Signatory(s));
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.fetchingSignatories = false;
    },
    onSignatoryAdded(signatory) {
      this.createAndEditSignatoryDialogState = false;
      this.signatories.push(signatory);
    },
    onSignatoryEdited(signatory) {
      this.createAndEditSignatoryDialogState = false;
      this.signatories = this.signatories.map((s) =>
        s.id == signatory.id ? signatory : s
      );
    },
    openSignatoryCreateAndEditDialog(selectedSignatory = null) {
      this.selectedSignatory = selectedSignatory;
      this.createAndEditSignatoryDialogState = true;
    },
    openSignatoryRemoveDialog(selectedSignatory = null) {
      this.selectedSignatory = selectedSignatory;
      this.removeSignatoryDialogState = true;
    },
    onSignatoryRemoved(signatory) {
      this.removeSignatoryDialogState = false;
      this.signatories = this.signatories.filter((s) => s.id != signatory.id);
    },
  },
};
</script>

<style scoped lang="scss">
.sl_container {
  padding: 0 8px;
  .header {
    margin-bottom: 32px;
    .signatory_title {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 32px;
      .label {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #353542 !important;
      }
      .icon {
        margin-left: 8px;
      }
    }
  }
}
</style>
