import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getOrganizationCampaigns(organizationId, page) {
    return cosmoAPI.get(`/organization/${organizationId}/campaign/`, {
      params: {
        page,
      },
    });
  },
  getStatisticWorkflow(organizationID, page) {
    return cosmoAPI.get(`/organization/${organizationID}/statistic/workflow/`, {
      params: {
        page,
      },
    });
  },

  getOrganizationCollaborators(organizationId, page) {
    return cosmoAPI.get(`/organization/${organizationId}/collaborator/`, {
      params: {
        page,
      },
    });
  },
  getOrganizationCourses(organizationId, page) {
    return cosmoAPI.get(`/organization/${organizationId}/course/`, {
      params: {
        page,
      },
    });
  },
  getOrganizationInvitations(organizationId) {
    return cosmoAPI.get(`/organization/${organizationId}/invitation/`);
  },
  inviteCollaborator(organizationId, data) {
    return cosmoAPI.post(`organization/${organizationId}/invitation/`, data);
  },
  postOrganizationInvitationRemind({ organizationId, invitationId }) {
    return cosmoAPI.post(
      `organization/${organizationId}/invitation/${invitationId}/remind/`
    );
  },
};
