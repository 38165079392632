let staticFiles = null;

export default {
  install(Vue) {
    Vue.prototype.$findStatic = function (file) {
      return staticFiles[file];
    };
    Vue.prototype.$setStatic = function (payload) {
      staticFiles = payload;
    };
  },
};
