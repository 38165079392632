<template>
  <BaseModal
    :value="isOpen"
    :modal-title="$t('course.deleteStep')"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="$t('course.deleteStepActionAsk')" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="deleteStep">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "CourseStepDeleteDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    deleteStep() {
      this.$store
        .dispatch("course/deleteStep", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.$store.state.course.activeStep.id,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.deleteStepConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.$emit("close");
    },
  },
};
</script>
