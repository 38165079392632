<template>
  <div v-if="isLoading" class="wallet-manager-space-container">
    <BaseSkeletonLoader height="200px" class="skeleton" />
    <BaseSkeletonLoader height="700px" class="skeleton-body" />
  </div>
  <div v-else class="wallet-manager-space-container">
    <div class="body">
      <WalletManagerFile
        :list-files="formatedFiles"
        :selected-file="selectedFile"
        @select-file="selectFile"
      />
      <WalletManagerOfferDetail
        :client="client"
        :organization="organization"
        :client-list="offer.clients"
        :offer="offer"
        @update="updateOffer"
        @submit="submitComment"
        @onchange="onChange"
      />
    </div>
  </div>
</template>
<script>
import pdf from "pdfvuer";

import WalletManagerFile from "../Components/WalletManagerFile.vue";
import WalletManagerOfferDetail from "../Components/WalletManagerOfferDetail.vue";

import OfferApi from "../../Folder/Services/offer.api";
import Offer from "@/core/Models/Offer";
import { FILE_TYPE } from "@/core/Utils/types.utils";
import {
  getFileTypeByName,
  fileURLToFile,
  convertFileToPdf,
} from "@/core/Utils/file.utils";

export default {
  name: "WalletManagerSpace",
  components: {
    WalletManagerFile,
    WalletManagerOfferDetail,
  },
  data() {
    return {
      isLoading: true,
      selectedFile: null,
      offer: null,
      formatedFiles: [],
      organization: null,
      comment: null,
    };
  },
  computed: {
    client() {
      const { email } = this.$route.query;
      if (!email || !this.offer) return;
      return this.offer.clients.find((client) => client.email === email);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isLoading = true;
      try {
        const { uuid } = this.$route.params;
        const { email } = this.$route.query;
        const res = await OfferApi.getOfferDetail(uuid, email);
        if (!res?.data) return;
        this.offer = new Offer(res.data);
        this.organization = res.data.organization;
        for (const file of this.offer.files) {
          const type = getFileTypeByName(file.name);
          if (FILE_TYPE.IMG == type) {
            this.formatedFiles.push({ ...file, type });
            continue;
          }
          const convertedFile = file;
          if (FILE_TYPE.OTHER == type) {
            const tmp = await this.convertFileToPdf(file);
            convertedFile.offerFile = tmp.offerFile;
            convertedFile.name = tmp.name;
          }
          const totalPage = await this.getTotalPage(convertedFile.offerFile);
          this.formatedFiles.push({ ...file, type: FILE_TYPE.PDF, totalPage });
        }
        this.selectedFile = this.formatedFiles[0];
        if (
          this.client.action === "CONSULT" &&
          this.client.status === "PENDING"
        ) {
          await this.updateOffer("CONSULTED");
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          return this.$router.push("/404");
        }
        if (error?.response?.status === 410) {
          return this.$router.push({
            name: "PageNotFound",
            query: { link_expired: true },
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: error.message,
          type: "ERROR",
        });
      }
      this.isLoading = false;
    },
    async convertFileToPdf(file) {
      const officeFileExtensions = [
        ".xls",
        ".xlsx",
        ".doc",
        ".docx",
        ".ppt",
        ".pptx",
      ];
      const lowerCaseFilename = file.name.toLowerCase();
      if (!officeFileExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
        return file;
      const tmpFile = await fileURLToFile(file.offerFile, file.name);
      const convertedFile = await convertFileToPdf(tmpFile);
      return {
        offerFile: convertedFile,
        name: `${file.name.split(".").shift()}.pdf`,
      };
    },
    async getTotalPage(url) {
      try {
        if (!url) return;
        const data = await pdf.createLoadingTask(url);
        if (!data) return;
        const res = await data.numPages;
        return res;
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: error ? error.message : "Invalid PDF structure",
          type: "ERROR",
        });
      }
      return 0;
    },
    selectFile(item) {
      this.selectedFile = null;
      setTimeout(() => {
        this.selectedFile = item;
        this.page = 1;
      }, 100);
    },
    async submitComment() {
      const { uuid } = this.$route.params;

      if (this.comment?.length) {
        const payload = { comment: this.comment, email: this.client.email };
        await Promise.all([
          OfferApi.createComment({ payload, uuid }),
          this.updateOffer("DECLINE"),
        ]);
      }

      this.$store.dispatch("snackbar/active", {
        message: this.$t("folder.offerStatus.signatureRefused"),
        type: "SUCCESS",
      });
    },
    onChange(event) {
      this.comment = event;
    },

    async updateOffer(status) {
      const { uuid } = this.$route.params;
      const offerClientId = this.client ? this.client?.id : null;
      const { email } = this.$route.query;
      const client = this.offer.clients.filter(
        (client) => client.email === email
      )[0];
      let payload = { status };
      let _message = "";
      if (status === "CONSULTED")
        _message = this.$tc("folder.offerStatus.offerConsulted", 0);
      if (status === "ACCEPT")
        _message = this.$tc("folder.offerStatus.offerValidated", 0);
      if (status === "DECLINE") {
        _message = this.$t("folder.offerStatus.signatureRefused");
        payload.reason_decline = this.comment;
      }
      try {
        if (offerClientId) {
          await OfferApi.updateOffer({
            payload,
            uuid,
            offerClientId,
          });

          if (client.action === "CONSULT") client.status = "CONSULTED";
          else
            client.action === "VALIDATION" && status === "DECLINE"
              ? (client.status = "DECLINE")
              : (client.status = "ACCEPT");
        }
        this.offer.clients = this.offer.clients.map((item) => {
          if (item.id === client.id) return { ...item, status: client.status };
          else return item;
        });
        this.$store.dispatch("snackbar/active", {
          message: _message,
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wallet-manager-space-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 50px;
  width: 100vw;
  height: 100vh;
  background: #f4f8ff;
  position: relative;
  @media (max-width: 1980px) {
    padding: 20px;
    overflow: hidden;
  }
  @media (max-width: 480px) {
    padding: 10px;
  }

  .skeleton {
    background: #cfcfdc;
    padding: 24px 32px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }
  .skeleton-body {
    background: #cfcfdc;
    height: 100%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }
  .body {
    display: flex;
    background: #fff;
    height: 100%;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    @media (max-width: 960px) {
      height: auto;
      flex-direction: column !important;
      gap: 67px;
      overflow: auto;
    }
  }
}
</style>
