import Datatype from "./Datatype";
import Option from "./Option";
import WizideeData from "./WizideeData";

export default class PersonalData {
  constructor({
    id = Number.NaN,
    content = "",
    choices = [],
    datatype = null,
    extractedData = null,
  } = {}) {
    this.id = Number.parseInt(id);
    this.content = content;
    this.choices = choices ? choices.map((choice) => new Option(choice)) : [];
    this.datatype = datatype ? new Datatype(datatype) : null;
    this.extractedData =
      extractedData && JSON.stringify(extractedData) !== "{}"
        ? new WizideeData(extractedData)
        : null;
  }
}
