let plugins = [];

// Import Stores
import Folder from "./folder";
import Offer from "./offer";

let stores = {
  folder: Folder,
  offer: Offer,
};

export { plugins, stores };
