<template>
  <BaseModal
    v-if="isOrganizationInformationModalOpen.status"
    :value="isOrganizationInformationModalOpen.status"
    :is-unique="isOrganizationInformationModalOpen.type === 'logo'"
    :modal-title="title"
    @close="reset"
  >
    <template #modal>
      <div class="d-flex flex-column justify-center">
        <BaseFileInput
          v-if="isOrganizationInformationModalOpen.type === 'logo'"
          ref="imageInput"
          :val="logo"
          truncate-length="50"
          append-icon="$mdiDownload"
          prepend-icon=""
          :placeholder="$t('organization.uploadLogo')"
          :clearable="false"
          outlined
          accept="image/jpeg,image/png,image/jpg"
          @change="imageChanged"
        />
        <BaseMarkdownEditor
          v-else
          v-model="payload"
          :placeholder="$t('organization.formDescription')"
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click.stop="submit">
        {{ $t("folder.statusForm.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "OrganizationInformationEditDialog",
  data() {
    return {
      payload: null,
      displayDirectImage: false,
      logo: null,
    };
  },

  computed: {
    ...mapState({
      isOrganizationInformationModalOpen: (state) =>
        state.organization.isOrganizationInformationModalOpen,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    title() {
      switch (this.isOrganizationInformationModalOpen.type) {
        case "location":
          return this.$t("organization.location");
        case "description":
          return this.$t("organization.description");
        default:
          return this.$t("organization.logo");
      }
    },
  },
  watch: {
    isOrganizationInformationModalOpen: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    "activeOrganization.logo": {
      async handler() {
        if (!this.activeOrganization.logo) return;
        await this.getAvatar(this.activeOrganization.logo);
      },
      immediate: true,
    },
  },
  methods: {
    imageChanged(value) {
      this.payload = value;
    },
    async getAvatar(value) {
      const res = await fetch(value);
      const blob = await res.blob();
      const extension = blob.type.split("/");
      this.logo = new File([blob], `logo.${extension[1]}`, {
        type: blob.type,
      });
    },
    reset() {
      this.$store.commit(
        "organization/setIsOrganizationEditInformationModalOpen",
        { status: false }
      );
      this.payload = null;
    },
    async initialize() {
      this.payload =
        this.isOrganizationInformationModalOpen.type === "location"
          ? this.activeOrganization?.contact
          : this.activeOrganization?.description;
    },
    async getPayload() {
      switch (this.isOrganizationInformationModalOpen.type) {
        case "location":
          return { contact: this.payload };
        case "description":
          return { description: this.payload };
        default:
          return { logo: this.payload };
      }
    },
    async submit() {
      const payload = await this.getPayload();
      try {
        await this.$store.dispatch("organization/patchOrganization", {
          organizationId: this.$route.params.organizationId,
          payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.editError"),
          type: "ERROR",
        });
      }
      this.reset();
    },
  },
};
</script>
