<template>
  <v-data-table :locale="'fr'" v-bind="$attrs" v-on="$listeners">
    <slot />
    <template #item="{ item }">
      <slot name="item" :item="item" />
    </template>
    <template #expanded-item="{ item }">
      <slot name="expanded-item" :item="item" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "BaseFrontOfficeDataTable",
};
</script>
