<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "MdiClose",
  data() {
    return {
      svgPath: mdiClose,
    };
  },
};
</script>
