<template>
  <BaseDialog
    :value="dialogState"
    max-width="700"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <template #modal>
      <BaseCosmoCard v-if="activeFolder">
        <template #title>
          <div class="modal_header">
            <BaseTitle class="modal_header_title" v-text="dialogTitle" />
            <BaseButtonIcon icon="$mdiClose" @click="close()" />
          </div>
        </template>
        <div class="modal_content">
          <template v-if="activeFolder.state == 'OPEN'">
            <div
              class="bo_text text_danger"
              v-text="$t('utils.folderArchiveAlert')"
            />
            <div
              class="bo_text"
              v-text="$t('utils.folderArchiveConfirmation')"
            />
          </template>
          <template v-else>
            <div
              class="bo_text text-center"
              v-text="$t('utils.folderUnarchiveAlert')"
            />
          </template>
        </div>
        <div class="modal_footer">
          <BaseButton color="gray" type="secondary" @click="close()">
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :color="activeFolder.state == 'OPEN' ? 'secondary' : 'primary'"
            :loading="isSubmiting"
            @click="submit()"
          >
            {{
              activeFolder.state == "OPEN"
                ? $t("utils.archive")
                : $t("utils.unarchive")
            }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FolderArchiveDialog",
  data() {
    return {
      isSubmiting: false,
    };
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      dialogState: (state) => state.folder.modals.isArchiveDialogOpen,
    }),
    dialogTitle() {
      return this.$t(
        this.activeFolder?.state == "OPEN"
          ? "utils.folderArchiveDialogTitle"
          : "utils.folderUnarchiveDialogTitle",
        { name: this.activeFolder.name }
      );
    },
  },
  methods: {
    close() {
      this.$store.commit("folder/SET_MODAL_FOLDER_ARCHIVE_STATUS", false);
    },
    async submit() {
      this.isSubmiting = true;
      try {
        if (this.activeFolder.state == "OPEN") {
          await this.$store.dispatch("folder/updateFolder", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$store.state.folder.activeFolder.id,
            payload: {
              state: "CLOSED",
            },
          });
        } else {
          await this.$store.dispatch("folder/unarchiveFolder", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$store.state.folder.activeFolder.id,
          });
        }
        this.$store.dispatch("snackbar/active", {
          message:
            this.activeFolder.state == "OPEN"
              ? this.$t("utils.snackbar.folderUnarchivedSuccessfully")
              : this.$t("utils.snackbar.folderArchivedSuccessfully"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 16px;
  flex-wrap: wrap;
  .modal_header_title {
    flex: 1;
  }
}
.modal_content {
  padding: 24px 10px;

  & > * {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .bo_text {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #353542;
  }
  .text_danger {
    color: #ff5267;
  }
}
.modal_footer {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
}
</style>
