<template>
  <BaseFrontOfficeDialog
    v-if="activeWalletFolder"
    max-width="1000px"
    :value="isFolderDialogOpen"
    @click:outside="closeModal"
  >
    <template #modal>
      <div class="folder_dialog">
        <div class="folder_dialog_header">
          <div class="header_title">
            <BaseFrontOfficeTitle
              white
              uppercase
              xlarge-size
              v-text="activeWalletFolder.name"
            />
            <div
              class="sub_title"
              v-text="
                $t('folder.proposedDateAt', {
                  dateCreated: $d(activeWalletFolder.dateCreated, 'detailed'),
                })
              "
            />
          </div>
          <BaseFrontOfficeIcon
            class="close_btn"
            icon="$mdiClose"
            color="white"
            icon-type="xlarge"
            @click="closeModal"
          />
        </div>
        <div class="folder_dialog_content">
          <v-tabs v-model="tab">
            <v-tab v-if="walletOffers.length">
              {{ $tc("utils.offer", 2) }}
            </v-tab>
            <v-tab>
              {{ $tc("utils.data", 3) }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="tab_item">
            <v-tab-item v-if="walletOffers.length" class="py-4 px-1">
              <WalletOffers />
            </v-tab-item>
            <v-tab-item class="py-4 px-1">
              <WalletFolderRecords />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </template>
  </BaseFrontOfficeDialog>
</template>

<script>
import { mapState } from "vuex";
import WalletFolderRecords from "./WalletFolderRecords.vue";
import WalletOffers from "./WalletOffers.vue";
export default {
  name: "WalletFolderDialog",
  components: { WalletOffers, WalletFolderRecords },
  data() {
    return {
      tab: 0,
      isFetchingOffers: true,
    };
  },

  computed: {
    ...mapState({
      isFolderDialogOpen: (state) =>
        state.walletCourse.modals.isFolderDialogOpen,
      activeWalletFolder: (state) => state.walletCourse.activeWalletFolder,
      activeOrganization: (state) => state.organization.activeOrganization,
      folderRecords: (state) => state.walletCourse.folderRecords,
      walletOffers: (state) => state.walletCourse.folderOffers,
    }),
  },
  watch: {
    activeWalletFolder: {
      async handler() {
        if (this.activeWalletFolder) await this.initalize();
      },
      deep: true,
      immediate: true,
    },
    isFetchingOffers: {
      handler() {
        if (this.isFetchingOffers) return;
        this.tab = 1;
      },
    },
  },
  destroyed() {
    this.closeModal(false);
  },
  methods: {
    closeModal(isWithRedirection = true) {
      this.$store.commit("walletCourse/setIsFolderDialogOpen", false);
      this.$store.commit("walletCourse/setFolderOffers", []);
      this.$store.commit("walletCourse/setActiveWalletFolder", null);
      this.tab = 0;
      this.isFetchingOffers = true;
      if (isWithRedirection)
        return this.$router.push({ name: "WalletOrganizationRecords" });
    },
    async initalize() {
      this.isFetchingOffers = true;
      await this.$store.dispatch("walletCourse/getWalletOffer", {
        organizationId: this.activeOrganization.id,
        folderId: this.activeWalletFolder.id,
      });
      this.isFetchingOffers = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.folder_dialog {
  background: #fff;
  .folder_dialog_header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 32px;
    background: linear-gradient(
      147.45deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    );
    .header_title {
      margin-left: 24px;
      .sub_title {
        margin-top: 8px;
        font-size: 12px;
        line-height: 18px;
        font-style: italic;
        color: #fff;
        font-family: var(--cosmo-font-nunito);
      }
    }
    .close_btn {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  .folder_dialog_content {
    padding: 24px;
    height: calc(100vh - calc(100px + 30vh));
    .tab_item {
      height: calc(100% - 54px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #eeeef7;
      }

      &::-webkit-scrollbar-thumb {
        background: #b6bdff;
        border-radius: 3px;
      }
      ::v-deep {
        .v-window__container {
          height: 100%;
        }
      }
    }
  }
}
</style>
