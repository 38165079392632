<template>
  <BaseModal
    v-if="activeFolder"
    :value="dialogState"
    :modal-title="$t('folder.logs.history')"
    @close="reset"
  >
    <template #modal>
      <BaseDataTable
        :headers="headers"
        class="elevation-1 ma-6"
        :items="folderLogsTemp"
        :items-per-page="itemPerPage"
        :options.sync="options"
        :loading="isFetchingFolderLogs"
        :server-items-length="folderLogsCount"
        :loading-text="$t('utils.loadingData')"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <div class="d-flex flex-column align-start">
                <BaseParagraph
                  text="secondary"
                  class="ml-auto mr-auto d-block mb-1"
                  v-text="
                    `${$d(new Date(item.dateCreated), 'veryshort')} ${$d(
                      new Date(item.dateCreated),
                      'time'
                    )}`
                  "
                />
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-start py-5">
                <LogsLabel :folder-logs="item" />
              </div>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </template>
    <template #actions>
      <BaseButton text class="ml-auto" color="#707080" @click="reset()">
        {{ $t("utils.cancel") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import LogsLabel from "./LogsLabel.vue";

export default {
  name: "FolderLogsShowDialog",
  components: { LogsLabel },
  data() {
    return {
      nameValue: "",
      pageAlreadyFetched: 1,
      options: {},
      itemPerPage: 15,
      page: 1,
      headers: [
        {
          text: this.$t("folder.logs.creationDate"),
          align: "center",
          value: "dateCreated",
        },
        {
          text: this.$t("utils.category"),
          align: "center",
          value: "data.actionDisplay",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isFolderLogsDialogOpen,
      activeFolder: (state) => state.folder.activeFolder,
      isFetchingFolderLogs: (state) => state.folder.isFetchingFolderLogs,
      folderLogsCount: (state) => state.folder.folderLogsCount,
    }),
    folderLogs() {
      if (!this.activeFolder?.logs?.length) return [];
      const folderLogs = this.activeFolder.logs?.map((log) => {
        switch (log.action) {
          // creation dossier
          case "CREATE_FOLDER": {
            return {
              ...log,
              actionDisplayLogs: {
                folderName: this.activeFolder?.name,
                collaboratorName:
                  this.findName(log.data?.collaborator?.firstname) ||
                  log.data?.collaborator,
              },
            };
          }
          // assignation dossier && changement de status with assignation
          case "ASSIGN_COLLABORATOR": {
            return {
              ...log,
              actionDisplayLogs: {
                folderName: this.activeFolder?.name,
                collaboratorName: this.findName(
                  log.data?.assignFrom?.firstname
                ),
                assignedName: this.findName(log.data?.assignTo?.firstname),
              },
            };
          }
          // reponse a un parcours
          case "NEW_RECORD": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(log.data?.client?.firstname),
                courseName: log.data?.record?.campaign?.course?.name,
              },
            };
          }
          // demande de modification champ  && reponse niveau wallet
          case "UPDATE_ANSWER_VALIDATION": {
            const questionsName = log.data.answers.map(
              (a) => this.findAnswerQuestion(log.data.record, a).name
            );
            if (log.data.status === "UPDATE_REQUESTED") {
              return {
                ...log,
                actionDisplayLogs: {
                  assigned: this.findName(log.data?.client?.firstname),
                  collaboratorName: this.findName(
                    log.data?.collaborator?.firstname
                  ),
                  questions: questionsName.join(","),
                },
              };
            } else if (log.data?.status === "UPDATED") {
              return {
                ...log,
                actionDisplayLogs: {
                  assigned: this.findName(log.data?.client?.firstname),
                  questions: questionsName.join(","),
                },
              };
            } else {
              return {
                ...log,
                actionDisplayLogs: {
                  assigned: this.findName(log.data?.client?.firstname),
                  collaboratorName: this.findName(
                    log.data?.collaborator?.firstname
                  ),
                  questions: questionsName.join(","),
                },
              };
            }
          }
          case "REQUEST_INFORMATION": {
            return {
              ...log,
              actionDisplayLogs: {
                assigned: log.data?.email,
                courseName: log.data?.record?.campaign?.course?.name,
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
              },
            };
          }
          case "SIGNATURE_REQUEST": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                client: this.findName(log.data?.folder?.assign?.email),
              },
            };
          }
          case "SIGNATURE_SIGNED": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
              },
            };
          }
          case "SIGNED_DOCUMENT": {
            return {
              ...log,
              actionDisplayLogs: {
                message: this.$t("folder.logs.signatureSigned"),
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
              },
              folderName: log.data?.folder?.name,
            };
          }
          case "CREATE_CUSTOM_FIELD": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                fieldName: log.data?.question?.name,
              },
            };
          }
          case "ANSWER_CUSTOM_FIELD": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                fieldName: log.data?.question?.name,
              },
            };
          }
          case "MODIFIED_CUSTOM_FIELD": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                fieldName: log.data?.question?.name,
              },
            };
          }
          case "STATUS_CHANGE": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                statusTo:
                  log.data?.statusTo?.name || this.$t("utils.undefined"),
                statusFrom:
                  log.data?.statusFrom?.name || this.$t("utils.undefined"),
              },
            };
          }
          case "GENERATE_PDF": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
              },
            };
          }
          case "STATUS_AGREEMENT": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(log.data?.client?.firstname),
              },
            };
          }
          case "SUBMIT_COSMOFORM": {
            const question = log.data.questions.map(
              (question) => question.name
            );
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                clientEmail: log.data.email,
                questions: question.join(","),
              },
            };
          }
          case "RELAUNCH_CLIENT": {
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
                clientEmail: log.data.client.email,
              },
            };
          }
          case "SUBMIT_COSMOFORM_EMAIL": {
            return {
              ...log,

              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname || log.data.collaborator
                ),
                clientEmail: log.data.contact,
                formular: log.data.formular,
              },
            };
          }
          case "SUBMIT_COSMOFORM_SMS": {
            return {
              ...log,

              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname || log.data.collaborator
                ),
                clientNumber: log.data.contact,
                formular: log.data.formular,
              },
            };
          }
          case "COSMOFORM_ANSWERED": {
            return {
              ...log,

              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname || log.data.collaborator
                ),
                formular: log.data.formular,
              },
            };
          }
          case "REFUSED_DOCUMENT": {
            return {
              ...log,
              actionDisplayLogs: {
                clientEmail: this.findName(log.data?.folder?.client?.firstname),
                folderName: log.data?.folder?.name,
              },
            };
          }
          case "FOLDER_ARCHIVED":
          case "FOLDER_UNARCHIVED":
            return {
              ...log,
              actionDisplayLogs: {
                collaboratorName: this.findName(
                  log.data?.collaborator?.firstname
                ),
              },
            };
          default:
            return { ...log };
        }
      });
      this.$store.commit("folder/SET_ACTIVE_FOLDER", {
        ...this.activeFolder,
        logs: [...folderLogs],
      });
      return folderLogs;
    },
    folderLogsTemp() {
      if (!this.folderLogs?.length) return [];
      return this.folderLogs.slice(
        (this.page - 1) * this.itemPerPage,
        this.page * this.itemPerPage
      );
    },
  },
  watch: {
    options: {
      async handler(newVal) {
        this.itemPerPage = newVal.itemsPerPage;
        if (+newVal.page > this.pageAlreadyFetched) {
          await this.fetchAllFolderLogs(+newVal.page);
          this.pageAlreadyFetched = +newVal.page;
        }
        this.page = +newVal.page;
      },
      deep: true,
    },
    dialogState(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },

  methods: {
    reset() {
      this.$store.commit("folder/setFolderLogsDialogOpen", false);
    },
    initialize() {
      this.fetchAllFolderLogs(1);
    },
    async fetchAllFolderLogs(page) {
      await this.$store.dispatch("folder/getFolderLogs", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        page,
      });
    },
    findName(name) {
      return name ? name : "Anonyme";
    },
    findAnswerQuestion(record, answer) {
      let tmpQuestion;
      for (const step of record.steps) {
        for (const question of step.questions) {
          if (question?.answer?.id == answer?.id) {
            tmpQuestion = question;
            break;
          }
          if (tmpQuestion) break;
        }
      }
      return tmpQuestion;
    },
  },
};
</script>
