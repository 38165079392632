import Collaborator from "./Collaborator";

export default class CollaboratorAssignation {
  constructor({
    id = Number.NaN,
    collaborator = null,
    dateCreated = new Date(),
    dateUpdated = new Date(),
  } = {}) {
    this.id = Number.parseInt(id);
    this.collaborator = collaborator ? new Collaborator(collaborator) : null;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
  }
}
