<template>
  <div class="wc_container">
    <div class="section_left">
      <WalletSidebar :record="activeRecord" :active-step-id="activeStepId" />
    </div>
    <div v-if="!initializeCourse" class="section_center">
      <div class="course_header">
        <div class="header_card">
          <BaseFrontOfficeIcon
            v-if="goBackPath"
            class="back_btn"
            icon="$mdiChevronLeftCircleOutline"
            color="#444393"
            @click.prevent="goTo(goBackPath)"
          />
          <div
            class="course_title"
            v-text="activeRecord.campaign.course.name"
          />
        </div>
      </div>
      <div class="course_body">
        <div v-if="folderClosed" class="record_archived_alert">
          <div class="alert_icon">
            <BaseIcon icon="$mdiLock" />
          </div>
          <div class="alert_text">
            <div v-text="$tc('utils.recordArchivedAlert', 1)" />
            <div v-text="$tc('utils.recordArchivedAlert', 2)" />
          </div>
        </div>
        <template v-else>
          <div
            v-for="(step, stepIndex) in pastAndActiveSteps"
            :key="`setp-${stepIndex}`"
            class="course_step"
          >
            <div class="step_header">
              <div class="step_title" v-text="step.name" />
              <BaseFrontOfficeDivider />
            </div>
            <!-- TEMPLATE ANSWERED QUESTION -->
            <template v-for="(question, i) in step.questions">
              <div
                v-if="
                  isPastAndNotActiveQuestion({
                    questionStep: step,
                    question,
                  }) && isCourseQuestion(question)
                "
                :key="`q-${i}`"
                class="step_body"
              >
                <div v-if="isGroupHeader(step, i)" class="group_header">
                  <div class="group_title" v-text="question.groupName" />
                  <BaseFrontOfficeDivider type="tertiary" />
                </div>
                <div class="question_section">
                  <WalletCourseChatBot
                    :hide-avatar="i !== step.questions.length + 1"
                    :avatar="organizationAvatar"
                    :question="question"
                  />
                  <WalletQuestionAnswer
                    v-if="!/^MESSAGE$/.test(question.typeQuestion)"
                    :question="question"
                    @editQuestion="onEditQuestion($event)"
                  />
                </div>
                <div v-if="isEndOfGroup(step, i)" class="group_bottom">
                  <BaseFrontOfficeDivider type="tertiary" />
                </div>
              </div>
            </template>
            <!-- TEMPLATE ACTIVE QUESTION -->
            <div
              v-if="step.id == activeStepId && activeQuestion"
              class="step_body"
            >
              <template v-if="fetchingCurrentQuestion">
                <div class="question_section">
                  <WalletCourseChatBot :avatar="organizationAvatar" loading />
                </div>
              </template>
              <!-- ACTIVE GROUPED QUESTION -->
              <template v-else-if="activeQuestion.group">
                <div class="group_header">
                  <div class="group_title" v-text="activeGroupLabel" />
                  <BaseFrontOfficeDivider type="tertiary" />
                </div>
                <div
                  v-for="(groupQuestion, gi) in activeGroup.questions"
                  :key="`gq-${gi}`"
                  class="question_section"
                >
                  <WalletCourseChatBot
                    :avatar="organizationAvatar"
                    :question="groupQuestion"
                    :hide-avatar="gi !== 0"
                  />
                  <template
                    v-if="!/^MESSAGE$/.test(groupQuestion.typeQuestion)"
                  >
                    <RecordQuestionForm
                      v-model="groupQuestion.answer"
                      :question="groupQuestion"
                      :answer-suggestion="groupQuestion.suggestion"
                      :organization-id="organizationId"
                      :is-anonymous="isAnonymous"
                      focus
                    />
                  </template>
                </div>
              </template>
              <!-- ACTIVE SIMPLE QUESTION -->
              <template v-else>
                <div class="question_section">
                  <WalletCourseChatBot
                    :avatar="organizationAvatar"
                    :question="activeQuestion"
                  />
                  <template
                    v-if="!/^MESSAGE$/.test(activeQuestion.typeQuestion)"
                  >
                    <RecordQuestionForm
                      v-model="activeQuestion.answer"
                      :question="activeQuestion"
                      :answer-suggestion="activeQuestion.suggestion"
                      :organization-id="organizationId"
                      :is-anonymous="isAnonymous"
                      focus
                      @validateQuestion="submitForm"
                    />
                  </template>
                </div>
              </template>
              <div v-if="displayActions" class="form_actions">
                <v-btn
                  v-if="canPassQuestion"
                  class="pass_btn"
                  color="primary"
                  text
                  rounded
                  :disabled="isSubmitingForm"
                  @click="submitForm(true)"
                >
                  {{ $t("wallet.pass") }}
                </v-btn>
                <BaseFrontOfficeButton
                  v-if="displayValidation"
                  :loading="isSubmitingForm"
                  :disabled="!isValidForm"
                  type="primary"
                  @click="submitForm()"
                >
                  {{ $t("wallet.validate") }}
                </BaseFrontOfficeButton>
              </div>
            </div>
          </div>
          <div v-if="!activeQuestion && !fetchingCurrentQuestion">
            <WalletCourseShareData :record="activeRecord" />
          </div>
        </template>
        <div class="copyright">
          <span v-text="$t('wallet.myCoPowered')" />
        </div>
      </div>
    </div>
    <div v-else class="section_center">
      <div class="course_header">
        <div class="header_card">
          <BaseSkeletonLoader type="text@1" style="width: 80%" class="my-4" />
        </div>
      </div>
      <div class="course_body">
        <div class="course_step">
          <BaseSkeletonLoader type="table-heading" />
          <BaseSkeletonLoader
            type="list-item-avatar"
            class="mt-4"
            style="width: 30%"
          />
          <div class="d-flex align-end flex-column">
            <BaseSkeletonLoader type="text" class="mt-4" style="width: 50%" />
            <div class="d-flex mt-4" style="width: 50%">
              <BaseSkeletonLoader type="date-picker-days" />
              <BaseFrontOfficeButtonIcon
                disabled
                icon="$mdiSend"
                class="ml-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <WalletCourseEditQuestionDialog
      :dialog-state="dialogState.editQuestion"
      :question="questionToEdit"
      :record-id="recordId"
      @close="dialogState.editQuestion = false"
      @questionEdited="onQuestionEdited($event)"
    />
  </div>
</template>

<script>
import WalletSidebar from "../Components/WalletSidebar";
import WalletCourseChatBot from "../Components/WalletCourseChatBot";
import RecordQuestionForm from "../Components/RecordQuestionForm";
import WalletCourseShareData from "../Components/WalletCourseShareData";
import WalletQuestionAnswer from "../Components/WalletQuestionAnswer";
import WalletCourseEditQuestionDialog from "../Components/WalletCourseEditQuestionDialog";
import { desactivatePreviewMode } from "@/core/Utils/file.utils";
import RecordAPI from "../Services/record.api";

import Record from "@/core/Models/Record";
import Question from "@/core/Models/Question";

export default {
  name: "WalletCourse",
  components: {
    WalletSidebar,
    WalletCourseChatBot,
    RecordQuestionForm,
    WalletCourseShareData,
    WalletQuestionAnswer,
    WalletCourseEditQuestionDialog,
  },
  props: {
    recordId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      initializeCourse: true,
      fetchingCurrentQuestion: true,
      isSubmitingForm: false,
      activeQuestion: null,
      activeGroup: null,
      questionsAnswered: [],
      activeRecord: null,
      questionToEdit: null,
      questionJumps: [],
      dialogState: {
        editQuestion: false,
      },
    };
  },
  computed: {
    activeGroupLabel() {
      if (!this.activeGroup) return;
      return this.activeGroup.label || "";
    },
    organizationId() {
      if (!this.activeRecord) return NaN;
      return parseInt(this.activeRecord.campaign.course.organization.id, 10);
    },
    isAnonymous() {
      if (!this.activeRecord) return false;
      return this.activeRecord.campaign.course.anonymous;
    },
    displayValidation() {
      if (
        !this.activeQuestion.group &&
        /^RADIOBUTTON$/.test(this.activeQuestion.typeQuestion) &&
        !this.activeQuestion.suggestion
      )
        return false;
      return true;
    },
    displayActions() {
      if (!this.activeQuestion) return false;
      if (this.fetchingCurrentQuestion) return false;
      if (this.activeGroup)
        return this.activeGroup.questions.some(
          (q) => !/^MESSAGE$/.test(q.typeQuestion)
        );
      else return !/^MESSAGE$/.test(this.activeQuestion.typeQuestion);
    },
    pastAndActiveSteps() {
      if (!this.activeRecord) return [];
      if (this.activeQuestion) {
        const activeSteps = [];
        for (const step of this.activeRecord.steps) {
          if (step.id != this.activeQuestion.step) {
            if (
              step.questions.filter((question) => {
                return (
                  this.isPastAndNotActiveQuestion({
                    questionStep: step,
                    question,
                  }) && this.isCourseQuestion(question)
                );
              }).length
            ) {
              activeSteps.push(step);
            }
          } else activeSteps.push(step);
          if (step.id == this.activeQuestion.step) break;
        }
        return activeSteps;
      } else return this.activeRecord.steps;
    },
    activeStepId() {
      if (!this.activeQuestion) return null;
      else return this.activeQuestion.step;
    },
    isFromPreview() {
      return !!localStorage.isPreview && JSON.parse(localStorage.isPreview);
    },
    goBackPath() {
      if (this.isFromPreview && !!this.activeRecord)
        return {
          name: "courseDetail",
          params: {
            organizationId: this.activeRecord.campaign.course.organization.id,
            courseId: this.activeRecord.campaign.course.id,
          },
        };
      return null;
    },
    folderClosed() {
      if (this.activeRecord?.folderState == "CLOSED") return true;
      return false;
    },
    organizationAvatar() {
      if (!this.activeRecord) return "";
      const organizationAvatar =
        this.activeRecord?.campaign?.course?.organization?.avatar;
      return organizationAvatar ? organizationAvatar : "";
    },
    canPassQuestion() {
      if (!this.activeQuestion) return false;
      if (this.activeQuestion.group && this.activeGroup?.questions?.length)
        return !this.activeGroup.questions.some((q) => q.required);
      return !this.activeQuestion.required;
    },
    isValidForm() {
      if (!this.activeQuestion) return false;
      if (this.activeQuestion.group && this.activeGroup?.questions?.length) {
        let valid = true;
        for (const question of this.activeGroup?.questions) {
          if (question.required) {
            if (
              !question.answer ||
              (!question.answer.content && !question.answer.choices?.length)
            ) {
              valid = false;
              break;
            }
          }
        }
        return valid;
      }
      if (this.activeQuestion.required) {
        if (
          !this.activeQuestion.answer ||
          (!this.activeQuestion.answer.content &&
            !this.activeQuestion.answer.choices?.length)
        )
          return false;
      }
      return true;
    },
    excludeQuestions() {
      if (!this.activeRecord || !this.questionJumps?.length) return [];
      const excludeQuestions = [];
      for (const questionWithJumps of this.questionJumps) {
        const stepFrom = this.activeRecord.steps.find(
          (s) => s.id == questionWithJumps.step
        );
        const questionFrom = stepFrom.questions.find(
          (q) => q.id == questionWithJumps.id
        );
        if (!questionFrom.answer || !questionFrom.answer.choices?.length)
          continue;
        const jump = questionWithJumps.logicjumps.find(
          (qj) => qj.conditionChoice == questionFrom.answer.choices[0].id
        );
        if (!jump) continue;
        let stepTo;
        let questionTo;
        this.activeRecord.steps.forEach((step) => {
          const tmp = step.questions.find((q) => jump.questionTo == q.id);
          if (tmp) {
            questionTo = tmp;
            stepTo = step;
          }
        });
        this.activeRecord.steps.forEach((step) => {
          if (step.order < stepFrom.order || step.order > stepTo.order) return;
          if (stepFrom.id == stepTo.id) {
            return step.questions.forEach((q) => {
              if (q.order > questionFrom.order && q.order < questionTo.order)
                excludeQuestions.push(q.id);
            });
          }
          if (step.id == stepFrom.id) {
            return step.questions.forEach((q) => {
              if (q.order > questionFrom.order) excludeQuestions.push(q.id);
            });
          }
          if (step.id == stepTo.id) {
            return step.questions.forEach((q) => {
              if (q.order < questionTo.order) excludeQuestions.push(q.id);
            });
          }
          step.questions.forEach((q) => excludeQuestions.push(q.id));
        });
      }
      return excludeQuestions;
    },
  },
  created() {
    this.initialize();
    desactivatePreviewMode();
  },
  methods: {
    async initialize() {
      this.initializeCourse = true;
      try {
        await this.fetchActiveRecord(this.recordId);
        if (!this.folderClosed) {
          await this.fetchQuestionJumps();
        }
      } catch (error) {
        if (error.response?.status === 404) {
          window.location.replace("/404/");
        }
        if (error.response?.status == 401 || error.response?.status == 500) {
          return this.$router.push({
            name:
              this.$route.query.has_account == "True"
                ? "WalletLogin"
                : "WalletRegister",
            query: {
              next: this.$route.fullPath,
            },
          });
        }
      }
      if (this.activeRecord?.agreements?.length) {
        this.$router.push({
          name: "WalletSafe",
        });
        return;
      }
      this.initializeCourse = false;
      await this.fecthActiveQuestions(this.recordId);
      this.scrollIntoQuestion();
    },
    isPastAndNotActiveQuestion({ questionStep, question }) {
      if (this.initializeCourse) return false;
      if (!this.activeQuestion) return !!question.answer;
      if (
        this.activeGroup &&
        this.activeGroup.questions.some((q) => q.id == question.id)
      )
        return false;
      const activeStep = this.activeRecord.steps.find(
        (s) => s.id == this.activeQuestion.step
      );
      if (activeStep.order > questionStep.order) return !!question.answer;
      if (activeStep.order < questionStep.order) return false;
      return question.order < this.activeQuestion.order && !!question.answer;
    },
    isCourseQuestion(question) {
      return !this.excludeQuestions.some(
        (questionId) => questionId == question.id
      );
    },
    isGroupHeader(step, index) {
      if (!step.questions[index]?.group) return false;
      if (
        (step.questions[index].group && index < 1) ||
        step.questions[index].group !== step.questions[index - 1]?.group
      )
        return true;
      return false;
    },
    isEndOfGroup(step, index) {
      if (!step.questions[index]?.group) return false;
      if (!step.questions[index + 1]?.group) return true;
      if (step.questions[index]?.group !== step.questions[index + 1]?.group)
        return false;
    },
    async fetchQuestionJumps() {
      if (!this.activeRecord) return;
      for (const step of this.activeRecord.steps) {
        for (const question of step.questions) {
          if (!/SELECT|RADIOBUTTON/.test(question.typeQuestion)) continue;

          const params = {
            organizationId: this.activeRecord.campaign.course.organization.id,
            courseId: this.activeRecord.campaign.course.id,
            stepId: question.step,
            questionId: question.id,
          };
          if (this.$route.query.sc) {
            params["settings"] = { params: { sc: this.$route.query.sc } };
          }
          const questionJumps = (await RecordAPI.getCourseQuestionJump(params))
            ?.data?.results;
          if (questionJumps?.length)
            this.questionJumps.push({
              ...question,
              logicjumps: questionJumps,
            });
        }
      }
    },
    async fetchActiveRecord(recordId) {
      const payload = { recordId };
      if (this.$route.query.sc) {
        payload["settings"] = { params: { sc: this.$route.query.sc } };
      }
      const tmpRecord = (await RecordAPI.getRecord(payload)).data;
      this.activeRecord = new Record({ ...tmpRecord });
    },
    async fecthActiveQuestions(recordId) {
      this.fetchingCurrentQuestion = true;
      this.activeGroup = null;
      const payload = {
        recordId,
      };
      if (this.$route.query.sc) {
        payload["settings"] = { params: { sc: this.$route.query.sc } };
      }
      const question = (await RecordAPI.getRecordQuestionCurrent(payload)).data;
      if (question) {
        if (question.group) {
          await this.fetchQuestionGroup({
            recordId,
            groupId: question.group,
          });
        }
        this.activeQuestion = new Question({ ...question });
        await this.sleep();
        this.fetchingCurrentQuestion = false;
        await this.sleep();
        if (
          this.activeGroup?.questions.every((q) =>
            /^MESSAGE$/.test(q.typeQuestion)
          )
        )
          await this.submitForm(false);
        else if (/^MESSAGE$/.test(this.activeQuestion.typeQuestion))
          await this.submitForm(false);
      } else this.activeQuestion = null;
      this.fetchingCurrentQuestion = false;
    },
    sleep() {
      return new Promise((resolve) => {
        setTimeout(() => resolve(true), Math.random() * 800 + 600);
      });
    },
    scrollIntoQuestion() {
      this.$nextTick(() => {
        const activeQuestion =
          document.getElementsByClassName("question_section");
        const myDivElements = Array.prototype.filter.call(
          activeQuestion,
          (testElement) => testElement.nodeName === "DIV"
        );
        let lastQ = myDivElements.pop();
        if (!lastQ) return;
        lastQ.scrollIntoView();
      });
    },
    async fetchQuestionGroup({ recordId, groupId }) {
      const params = {
        recordId,
        groupId: groupId,
      };
      if (this.$route.query.sc) {
        params["settings"] = { params: { sc: this.$route.query.sc } };
      }
      const tmpGroup = (await RecordAPI.getRecordQuestionGroup(params)).data;
      this.activeGroup = {
        ...tmpGroup,
        questions: tmpGroup.questions.map((q) => new Question({ ...q })),
      };
      for (const question of this.activeGroup.questions) {
        const answeredQuestion = this.activeRecord.steps
          .find((s) => s.id == question.step)
          .questions.find((q) => q.id == question.id);
        if (answeredQuestion) question.answer = answeredQuestion.answer;
      }
    },
    async submitForm(skipped = false) {
      try {
        if (this.isSubmitingForm) return;
        this.isSubmitingForm = true;
        const answeredQuestions = [];
        if (this.activeQuestion.group && this.activeGroup) {
          for (const question of this.activeGroup.questions) {
            if (skipped) question.answer = { content: null };
            question.answer = await this.submitQuestion({
              question,
              payload: question.answer,
              skipped,
            });
            answeredQuestions.push(question);
          }
        } else {
          if (skipped) this.activeQuestion.answer = { content: null };
          this.activeQuestion.answer = await this.submitQuestion({
            question: this.activeQuestion,
            payload: this.activeQuestion.answer,
            skipped,
          });
          answeredQuestions.push(this.activeQuestion);
        }
        this.isSubmitingForm = false;
        answeredQuestions.forEach((question) => {
          this.setQuestionAnswer({
            question: question,
            answer: question?.answer,
          });
        });
        await this.fecthActiveQuestions(this.recordId);
        this.scrollIntoQuestion();
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.tryLater"),
          type: "ERROR",
        });
        this.isSubmitingForm = false;
      }
    },
    getPayload(payload, question) {
      if (!payload) return [];
      if (question.typeQuestion === "MULTIPLE_FILE") {
        return payload.content ? payload.content : [];
      }
      if (["FILE", "SIGNATURE"].includes(question.typeQuestion)) {
        return payload.content
          ? [
              {
                encoded_file: payload.content,
                name: payload.fileName,
              },
            ]
          : [];
      }
      if (payload.choices) {
        return payload.choices;
      }
      if (payload.content) {
        return [payload.content];
      }

      if (payload.freeOptionContent) {
        return [`free__${payload.freeOptionContent}`];
      }

      return [];
    },
    async submitQuestion({ question, payload, skipped }) {
      try {
        let setContentValue = [];
        setContentValue = this.getPayload(payload, question);
        const params = {
          recordId: this.recordId,
          questionId: question.id,
          payload: {
            set_content: setContentValue,
            skipped,
          },
        };
        if (this.$route.query.sc) {
          params["settings"] = { params: { sc: this.$route.query.sc } };
        }
        const test = (await RecordAPI.postRecordQuestionAnswer(params)).data;
        return test;
      } catch (error) {
        if (
          error?.response?.data?.code == "upload-error" &&
          error?.response?.data?.details?.file_with_error
        ) {
          const name = error?.response?.data?.details?.file_with_error;
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.uploadFileError", { name }),
            type: "ERROR",
          });
        } else {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.tryLater"),
            type: "ERROR",
          });
        }
      }
      return {
        id: NaN,
        content: null,
      };
    },
    setQuestionAnswer({ question, answer }) {
      if (!this.activeRecord.steps?.length) return;
      const tempStep = this.activeRecord.steps.find(
        (step) => step.id === question.step
      );
      const tempQuestion =
        tempStep && tempStep.questions?.length
          ? tempStep.questions.find((qest) => qest.id === question.id)
          : null;
      if (tempQuestion) tempQuestion.answer = answer;
    },
    onEditQuestion(question) {
      this.questionToEdit = question;
      this.dialogState.editQuestion = true;
    },
    async onQuestionEdited(question) {
      this.dialogState.editQuestion = false;
      this.questionToEdit = null;
      this.setQuestionAnswer({
        question,
        answer: question?.answer,
      });
      if (
        /SELECT|RADIOBUTTON/.test(question.typeQuestion) &&
        this.questionJumps.some((qj) => qj.id == question.id)
      ) {
        await this.fecthActiveQuestions(this.recordId);
      }
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.wc_container {
  font-family: "Nunito";
  height: calc(100vh - 98px);
  display: flex;
  padding: 0px 16px 24px;
  overflow: auto;
  .section_left {
    width: 300px;
    position: sticky;
    top: 0;
  }
  .section_center {
    flex: 1;
    padding: 0 16px;
    .course_header {
      position: sticky;
      top: 0;
      background: #fff;
      border-radius: 0 0px 20px 20px;
      z-index: 10;
      .header_card {
        width: 100%;
        padding: 24px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #ebebeb;
        border-radius: 20px;
        box-shadow: 0px 2px 4px rgb(46 45 193 / 10%),
          0px 10px 10px rgb(46 45 193 / 6%);
        -webkit-box-shadow: 0px 2px 4px rgb(46 45 193 / 10%),
          0px 10px 10px rgb(46 45 193 / 6%);
        .back_btn {
          cursor: pointer;
        }
        .course_title {
          font-family: "Nunito";
          color: #3c3b8e;
          margin: auto;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .section_center {
      width: 100%;
      padding: 0px;
      .course_header {
        background: transparent;
      }
    }
  }
  @media (max-width: 1264px) {
    .section_left {
      display: none;
    }
  }
}
.course_body {
  .record_archived_alert {
    text-align: center;
    padding: 16px 32px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    margin-bottom: 24px;
    .alert_icon {
      padding: 8px;
      &::v-deep {
        .v-icon {
          color: #3c3b8e;
        }
      }
    }
    .alert_text {
      padding: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #3c3b8e;
      margin: auto;
    }
  }
  .course_step {
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    margin-bottom: 16px;
    .step_header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .step_title {
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        margin-right: 16px;
      }
    }
    .step_body {
      margin-bottom: 8px;
      @media (max-width: 500px) {
        margin-bottom: 16px;
      }
      .group_header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .group_title {
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          margin-right: 16px;
        }
      }
      .group_bottom {
        display: flex;
        align-items: center;
        margin: 16px 0;
      }
      .form_actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .pass_btn {
          text-decoration: underline;
          margin-right: 8px;
          font-family: "Nunito";
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .copyright {
    font-family: "Nunito";
    text-align: end;
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 16px;
  }
}
.question_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
::v-deep .v-input {
  min-width: 350px;
}
@media (max-width: 500px) {
  ::v-deep .v-input {
    min-width: 300px;
  }
}
</style>
