export const light = {
  primary: {
    base: "#2536CC",
    lighten2: "#6997F1",
    lighten3: "#F2EBFF",
    lighten5: "#F4F7FF",
    darken5: "#101967",
  },
  secondary: {
    base: "#FF5267",
    lighten2: "#FFA7A7",
    lighten3: "#F2EBFF",
    lighten5: "#FEF9FA",
    darken5: "#460F15",
  },
  accent: {
    base: "#702DDE",
    lighten5: "#E6D6FF",
  },
  success: {
    base: "#058503",
    lighten5: "#F0FFD6",
  },
  purple: {
    base: "#702DDE",
    lighten5: "#F2EBFF",
  },
  done: {
    base: "#353542",
    lighten3: "#E0E0E0",
    lighten5: "#EEEEF7",
  },
  warning: {
    base: "#FF6B00",
    lighten5: "#FFF3F0",
  },
  grey: {
    base: "#4F4F4F",
    lighten1: "#8B8B98",
    lighten2: "#C4C4C4",
    lighten3: "#EBEBEB",
    lighten4: "#F7F7F7",
    lighten5: "#FFFFFF",
  },
  walletPrimary: {
    base: "#3C3B8E",
    lighten3: "#EBEFFE",
    lighten5: "#F9F8FB",
  },
  walletAccent: {
    base: "#FF7074",
    lighten3: "#FFC9CA",
    lighten5: "#FFC9CA",
  },
  walletGrey: {
    base: "#4F4F4F",
    lighten1: "#8B8B98",
    lighten2: "#C4C4C4",
    lighten3: "#EBEBEB",
    lighten4: "#F7F7F7",
    lighten5: "#FFFFFF",
  },
  walletAlertInfo: {
    base: "#42B0FF",
    darken5: "#004E86",
    lighten5: "#D5EDFF",
  },
  walletAlertSuccess: {
    base: "#70FF98",
    darken5: "#008B27",
    lighten5: "#B5FFCA",
  },
  walletAlertWarning: {
    base: "#FFC670",
    darken5: "#CB7A01",
    lighten5: "#FFF4E4",
  },
  walletAlertError: {
    base: "#FF4242",
    darken5: "#9C0000",
    lighten5: "#FFADAD",
  },
};
