<template>
  <span class="text-caption" :class="classObject" />
</template>

<script>
export default {
  name: "BaseCaption",
  props: {
    bold: {
      type: Boolean,
    },
    text: {
      type: String,
      default: () => "primary",
      validator: function (value) {
        return ["primary", "secondary", "disabled"].indexOf(value) !== -1;
      },
    },
    uppercase: {
      type: Boolean,
      default: () => false,
    },
    italic: {
      type: Boolean,
      dafault: () => false,
    },
    truncate: {
      type: Boolean,
      dafault: () => false,
    },
    normalSize: {
      type: Boolean,
      dafault: () => false,
    },
  },
  data() {
    return {
      classObject: {
        "text-uppercase": this.uppercase,
        [`text--${this.text}`]: true,
        "font-italic": this.italic,
        "text-caption--bold": this.bold,
        "text-truncate d-inline-block": this.truncate,
        "text-normal-size": this.normalSize,
      },
    };
  },
};
</script>
<style scoped>
.text-caption {
  font-size: 0.625rem !important;
  font-weight: 400;
}
.text-caption--bold {
  font-weight: 700;
}
.text-normal-size {
  font-size: 12px !important;
  font-weight: 500;
}
</style>
