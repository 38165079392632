<template>
  <div>
    <BaseSnackbar
      :color="snackBarColor"
      right
      :timeout="snackbar.timeout"
      :value="snackbar.active"
      @input="onClick"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-progress-circular
            v-if="snackbar.type === 'LOADING'"
            color="primary lighten5"
            indeterminate
          />
          <BaseIcon
            v-else-if="snackbar.type === 'SUCCESS'"
            icon="$mdiCheck"
            color="success"
          />
          <BaseIcon v-else icon="$mdiAlertDecagramOutline" color="secondary" />
          <BaseDescription class="ml-4" v-text="snackbar.message" />
        </div>
        <BaseButtonIcon
          v-if="snackbar.needClose"
          icon="$mdiClose"
          color="grey"
          @click="onClick"
        />
      </div>
    </BaseSnackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TheSnackbar",
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar,
    }),
    snackBarColor() {
      let color = "";
      switch (this.snackbar.type) {
        case "SUCCESS":
          color = "success lighten-5";
          break;
        case "LOADING":
          color = "#F4F7FF";
          break;
        default:
          color = "secondary lighten-5";
          break;
      }
      return color;
    },
  },
  methods: {
    onClick() {
      this.$store.dispatch("snackbar/reset");
    },
  },
};
</script>
