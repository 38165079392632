<template>
  <BaseDialog
    max-width="800"
    :persistent="isSubmiting"
    :value="dialogState"
    @click:outside="reset()"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$tc('label.createFolder', 1)" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div class="input_group">
            <div class="label" v-text="`${$tc('utils.name', 1)}*`" />
            <BaseTextField
              v-model="form.name"
              autofocus
              :placeholder="`${$tc('utils.name', 1)}*`"
              :error-messages="nameErrors"
              :has-error="!!nameErrors"
              @change="$v.form.$touch()"
            />
          </div>
          <div class="input_group">
            <div class="label" v-text="'Workflow'" />
            <base-autocomplete
              v-model="form.workflow"
              :items="filteredWorkflow"
              :loading="isFetchingWorkflow"
              placeholder="Workflow"
              item-text="name"
              item-value="id"
              outlined
            />
          </div>
          <div class="input_group">
            <div class="label" v-text="`Contact assigné`" />
            <BaseTextField :value="client.email" disabled />
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import FolderAPI from "@/modules/Folder/Services/folder.api";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "ClientCreateFolderDialog",
  mixins: [validationMixin],
  props: {
    dialogState: {
      type: Boolean,
    },
    client: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      isFetchingWorkflow: false,
      form: {
        name: "",
        workflow: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapState({
      workflows: (state) => state.workflow.workflows,
    }),
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("folder.createAndEditForm.validation.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("folder.createAndEditForm.validation.nameTooLong");
      }
      return "";
    },
    filteredWorkflow() {
      return this.workflows
        ? this.workflows.filter((wf) => wf.primaryStatus != null)
        : [];
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isFetchingWorkflow = true;
      await this.$store.dispatch(
        "workflow/fetchWorkflows",
        this.organizationId
      );
      this.isFetchingWorkflow = false;
    },
    reset() {
      if (this.isSubmiting) return;
      this.form = {
        name: "",
        workflow: null,
        client: null,
      };
      this.$v.form.$reset();
      this.$emit("close");
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmiting = true;
      try {
        const folder = (
          await FolderAPI.createFolder(this.organizationId, {
            ...this.form,
            client: this.client.id,
          })
        ).data;
        if (this.form.workflow) {
          await FolderAPI.setFolderWorkflow(this.organizationId, folder.id, {
            workflow: this.form.workflow,
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.successCreate"),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "folderDetail",
          params: {
            organizationId: this.organizationId,
            folderId: folder.id,
          },
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.errorCreate"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .input_group {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    .label {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
      color: #2536cc;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  gap: 16px;
}
</style>
