<template>
  <v-btn
    dark
    icon
    :class="type + '-button-icon'"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon dark>
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseFrontOfficeButtonIcon",
  props: {
    icon: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style scoped>
.primary-button-icon {
  background: var(--cosmo-gradient-primary);
  width: 41px;
  height: 41px;
}
.secondary-button-icon {
  color: var(--v-walletPrimary-base) !important;
  width: 41px;
  height: 41px;
}
.v-btn--disabled {
  opacity: 0.5;
}
</style>
