<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('workflow.targetStatus.deleteForm.title')"
    @click:outside="reset"
    @keydown.esc="reset"
    @close="reset"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="$t('workflow.targetStatus.deleteForm.label')" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("workflow.targetStatus.deleteForm.submit") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WorkflowStatusTargetStatusDeleteDialog",
  computed: {
    ...mapState({
      activeStatus: (state) => state.workflow.activeStatus,
      activeTargetStatus: (state) => state.workflow.activeTargetStatus,
      dialogState: (state) =>
        state.workflow.modals.isDeleteStatusTargetStatusDialogOpen,
    }),
  },
  methods: {
    reset() {
      this.$store.commit("workflow/setActiveStatus", null);
      this.$store.commit("workflow/setActiveTargetStatus", null);
      this.$store.commit("workflow/setDeleteStatusTargetStatusDialog", false);
    },
    submit() {
      this.$store
        .dispatch("workflow/deleteTargetStatus", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.$route.params.workflowId,
          statusId: this.activeStatus.id,
          targetStatusId: this.activeTargetStatus.id,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("workflow.targetStatus.deleteForm.success"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.reset();
    },
  },
};
</script>
