<template>
  <BaseModal
    :value="isOpen"
    :modal-title="title"
    persistant
    is-unique
    @click:outside="$emit('close')"
    @close="$emit('close')"
  >
    <template #modal>
      <div>
        <BaseFileInput
          key="file"
          :val="file"
          dense
          truncate-length="50"
          append-icon="$mdiDownload"
          prepend-icon=""
          :placeholder="placeHolder"
          :clearable="false"
          outlined
          @change="(value) => $emit('change', value)"
        />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        type="primary"
        :disabled="isDisabled"
        @click="$emit('validate')"
      >
        {{ validateBtnText }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "BaseModalFileUpload",
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    validateBtnText: {
      type: String,
      default: () => "Modifier l'avatar",
    },
    title: {
      type: String,
      default: () => "",
    },
    file: {
      type: [String, File],
      default: () => null,
    },
    placeHolder: {
      type: String,
      default: () => "",
    },
  },
};
</script>
