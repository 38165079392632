export default class Action {
  constructor({
    id = Number.NaN,
    name = "",
    slug = "",
    dateCreated = new Date(),
    dateUpdated = new Date(),
    enable = false,
    extra = null,
    typeAction = null,
    campaign = null,
    offerTemplate = null,
    validationSchemas = [],
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.slug = slug;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
    this.enable = enable;
    this.extra = extra;
    this.typeAction = typeAction;
    this.campaign = campaign;
    this.offerTemplate = offerTemplate;
    this.validationSchemas = validationSchemas;
  }
}
