<template>
  <div class="c-record-answer-text">
    <div v-if="content || !isNaN(parseInt(content, 10))">
      <div v-if="/^SIGNATURE$/.test(question.typeQuestion)">
        <BaseImage :src="content[0].url" max-width="300px" max-height="300px" />
      </div>
      <div v-else-if="/^FILE$/.test(question.typeQuestion)">
        <a target="_blank" @click.stop="openFiles">Voir le fichier</a>
      </div>
      <template v-else-if="question.typeQuestion === 'MULTIPLE_FILE'">
        <div v-for="c in content" :key="c.id">
          <span
            class="record_question_container"
            target="_blank"
            @click.stop="openMultiFile(c)"
            ><a class="label link">{{ c.label || c.name }}</a></span
          >
        </div>
      </template>
      <div v-else-if="/^DATE$/.test(question.typeQuestion)">
        <BaseFrontOfficeParagraph v-text="$d(new Date(content), 'short')" />
      </div>
      <div v-else-if="/^DATETIME$/.test(question.typeQuestion)">
        <BaseFrontOfficeParagraph v-text="$d(new Date(content), 'long')" />
      </div>
      <QuestionDatagridPreviwer
        v-else-if="/^DATAGRID|ROW_DATAGRID$/.test(question.typeQuestion)"
        :question="builtQuestion"
      />
      <div v-else>
        <BaseFrontOfficeParagraph v-text="content" />
      </div>
    </div>
    <div v-else>
      <BaseFrontOfficeParagraph v-text="$t('utils.notAnswered')" />
    </div>
  </div>
</template>

<script>
import camelCase from "lodash.camelcase";
import Question from "@/core/Models/Question";

import QuestionDatagridPreviwer from "@/core/Components/QuestionDatagridPreviwer";

export default {
  name: "WalletQuestionAnswerContent",
  components: {
    QuestionDatagridPreviwer,
  },
  props: {
    question: {
      type: [Question, Object],
      default: new Question(),
    },
  },
  computed: {
    content() {
      if (this.question.hasFreeOption) return this.question.answer.content;
      else if (
        this.question.answer?.content !== null &&
        (typeof this.question.answer?.content !== "object" ||
          Object.keys(this.question.answer?.content).length !== 0)
      ) {
        return this.question.answer?.content;
      }
      return null;
    },

    builtQuestion() {
      return {
        ...this.question,
        answer: {
          ...this.question.answer,
          content: this.content,
        },
      };
    },
  },
  methods: {
    openFiles() {
      this.content.forEach((file) => {
        open(file.url);
      });
    },
    openMultiFile(file) {
      open(file.url);
    },
    questionName(name) {
      return camelCase(name);
    },
  },
};
</script>

<style scoped>
.c-record-answer-text {
  font-style: italic;
  padding: 16px;
  font-size: 16px;
}
::v-deep thead .first-col span {
  display: none;
}
a {
  text-decoration: underline;
}
.record_question_container {
  position: relative;
  padding: 8px 16px 8px 24px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  height: 42px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #cfcfdc;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff;
  }
}
</style>
