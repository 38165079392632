<template>
  <div
    class="container d-flex align-center py-2"
    :class="{
      'container-error': isError,
      'suggestion-container': type === 'secondary',
      'white-container': isWhiteContainer,
    }"
    v-on="$listeners"
  >
    <BaseIcon
      v-if="type === 'primary'"
      icon="$mdiLock"
      class="my-auto mr-4"
      small
    />
    <div class="flex-grow-1 d-flex flex-column justify-center">
      <a
        v-if="/^FILE|SIGNATURE$/.test(typeQuestion) && content"
        :href="imageContent"
        target="_blank"
      >
        <BaseFrontOfficeImage
          v-if="isImage"
          :src="imageContent"
          height="32px"
          width="72px"
          @error="isImage = false"
        />
        <BaseFrontOfficeParagraph
          v-else
          bold
          color="primary"
          v-text="$t('answer.seeFolder', { name: name })"
        />
      </a>

      <template v-else-if="/MULTIPLE_FILE/.test(typeQuestion) && content">
        <div v-for="c in content" :key="c.id">
          <span
            class="record_question_container"
            target="_blank"
            @click.stop="openMultiFile(c)"
            ><a class="label link">{{ c.label || c.name }}</a></span
          >
        </div>
      </template>
      <a
        v-else-if="/DATAGRID|ROW_DATAGRID/.test(typeQuestion) && content"
        @click="$emit('show')"
      >
        <BaseFrontOfficeParagraph
          bold
          color="primary"
          style="text-decoration: underline"
          v-text="$t('answer.seeTable')"
        />
      </a>
      <BaseFrontOfficeParagraph
        v-else-if="/DATE/.test(typeQuestion) && content"
        truncate
        bold
        color="primary"
        v-text="$d(new Date(content), 'date')"
      />
      <BaseFrontOfficeParagraph
        v-else-if="/DATETIME/.test(typeQuestion) && content"
        truncate
        bold
        color="primary"
        v-text="$d(new Date(content), 'full')"
      />
      <BaseFrontOfficeParagraph
        v-else-if="
          /TEXT|TEXTEARA|SELECT|RADIOBUTTON|CHECKBOX/.test(typeQuestion) &&
          content
        "
        :color="type === 'primary' ? 'primary' : ''"
        bold
        v-text="content"
      />
      <BaseFrontOfficeParagraph
        v-else-if="/NUMBER/.test(typeQuestion) && !isNaN(parseInt(content, 10))"
        color="primary"
        bold
        v-text="content"
      />
      <BaseFrontOfficeParagraph
        v-else
        truncate
        italic
        v-text="$t('answer.noContent')"
      />
      <BaseCaption uppercase text="disabled" v-text="name" />
    </div>
    <BaseFrontOfficeIcon
      v-if="!folderClosed && !hideAction"
      class="ml-4"
      icon="$mdiChevronRightCircleOutline"
      @click="$emit('show')"
    />
  </div>
</template>

<script>
export default {
  name: "WalletLabelData",
  props: {
    typeQuestion: {
      type: String,
      required: true,
    },
    content: {
      type: [String, Array, Object, Boolean, Number],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "primary",
    },
    isWhiteContainer: {
      type: Boolean,
    },
    folderClosed: {
      type: Boolean,
    },
    hideAction: {
      type: Boolean,
    },
  },
  data() {
    return {
      isImage: true,
    };
  },
  computed: {
    imageContent() {
      if (!this.content.length) return "";
      return this.content[0]?.url;
    },
  },

  methods: {
    openFiles() {
      this.content.forEach((file) => {
        open(file.url);
      });
    },
    openMultiFile(file) {
      open(file.url);
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #f7f7f7f7;
  min-height: 48px;
  padding: 6px 8px;
  border-radius: 10px;
}
.white-container {
  background-color: white !important;
}
.container-error {
  border: 4px #ff5b60 solid !important;
}
.suggestion-container {
  background-color: white !important;
  cursor: pointer;
  max-width: 70% !important;
}

.record_question_container {
  position: relative;
  padding: 8px 16px 8px 24px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  height: 42px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #cfcfdc;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff;
  }
}
</style>
