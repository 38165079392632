<template>
  <v-sheet
    :light="true"
    :rounded="`${borderRadius}`"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: "BaseSheet",
  props: {
    borderRadius: {
      type: Number,
      default: () => 5,
    },
  },
};
</script>
