<template>
  <div class="subtitle my-4" :class="type !== '' ? `${type}--subtitle` : ''">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseCosmoSubtitle",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.subtitle {
  color: var(--typography--primary--color);
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.primary--subtitle {
  color: var(--typography--grey--color);
  font-weight: 400;
  line-height: 1px;
}
</style>
