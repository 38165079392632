import dashboardAPI from "./dashboard.api";

export function getUserInvitations() {
  return dashboardAPI.getUserInvitations();
}

export function acceptInvitation(invitationId) {
  return dashboardAPI.acceptInvitation(invitationId);
}

export function declineInvitation(invitationId) {
  return dashboardAPI.declineInvitation(invitationId);
}
