<template>
  <div
    class="file-viewer"
    :class="{
      'is-icon': isDocx,
    }"
  >
    <base-pdf-viewer v-if="isPdf" :pdf="source" />
    <base-icon
      v-else-if="isDocx"
      icon="$mdiFileDocument"
      color="primary"
      class="text-center"
    />
    <v-img v-else :src="source" class="img-view" />
    <div class="icon-containers">
      <BaseIcon
        icon="$mdiPencilOutline"
        color="success"
        medium
        class="mr-1"
        @click="$emit('change-file')"
      />
      <BaseIcon
        icon="$mdiDelete"
        color="error"
        medium
        @click="$emit('deleted')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFileViewer",
  props: {
    src: {
      type: File,
      required: true,
    },
  },
  computed: {
    isPdf() {
      return this.src.type.includes("pdf");
    },
    isDocx() {
      return [".docx", ".doc", ".pptx"].some((v) => this.src.type.includes(v));
    },
    source() {
      return URL.createObjectURL(this.src);
    },
  },
};
</script>

<style lang="scss" scoped>
.is-icon {
  display: flex;
  justify-content: center;
  align-content: center;
}
.file-viewer {
  width: 100%;
  overflow: hidden;
  position: relative;

  height: 100%;
  border: 2px solid #eeeef7;
  border-radius: 5px;
  .img-view {
    width: 100%;
    height: 100%;
  }
  .icon-containers {
    position: absolute;
    align-items: center;
    display: flex;
    z-index: 7;
    right: 5px;
    bottom: 5px;
  }
}
</style>
