<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('folder.workflow.deleteWorkflow')"
    @click:outside="close"
    @keydown.esc="close"
    @close="close"
  >
    <template #modal>
      <div class="text-normal text-center">
        <span v-text="$t('folder.workflow.deleteWorkflowConfirmation')" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="close"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WorkflowDeleteDialog",
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
      dialogState: (state) => state.workflow.modals.isDeleteWorkflowDialogOpen,
    }),
  },
  methods: {
    close() {
      this.$store.commit("workflow/setModalDeleteWorkflowDialog", false);
      this.$emit("close");
    },
    async submit() {
      try {
        await this.$store.dispatch("workflow/deleteWorkflow", {
          organizationId: this.$route.params.organizationId,
          workflowId: this.activeWorkflow.id,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "folder.workflow.snackbar.success.workflowDeletedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "folder.workflow.snackbar.error.errorDeletingWorkflow"
          ),
          type: "ERROR",
        });
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.text-normal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
</style>
