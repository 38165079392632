var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bo_title mb-6",domProps:{"textContent":_vm._s('Gestion des workflows')}}),_c('Board',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){return _vm.$store.commit(
              'workflow/setModalCreateAndEditWorkflowDialog',
              true
            )}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('workflow.createNewWorkflow'))}})]),_c('div',[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('folder.search'),"prepend-inner-icon":"$mdiMagnify","dense":"","hide-details":""},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1)]},proxy:true},{key:"main",fn:function(){return [_c('div',[(_vm.workflows.length > 0)?_c('BaseRow',_vm._l((_vm.filteredWorkflows),function(workflow,index){return _c('BaseCol',{key:index,attrs:{"cols":"4"}},[_c('BaseSheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"workflow-sheet elevation-3 pa-6"},[_c('router-link',{attrs:{"to":{
                  name: 'workflowDetail',
                  params: {
                    workflowId: ("" + (workflow.id)),
                  },
                }}},[_c('div',{staticClass:"text",attrs:{"text":"primary","truncate":""},domProps:{"textContent":_vm._s(workflow.name)}}),_c('BaseDivider',{staticClass:"my-4",attrs:{"grey":""}}),_c('div',{staticClass:"text status_info"},[_c('div',{staticClass:"mb-1",attrs:{"type":"primary","truncate":""},domProps:{"textContent":_vm._s(_vm.$tc('utils.status', 3))}}),_c('div',{attrs:{"type":"secondary","truncate":""},domProps:{"textContent":_vm._s(workflow.statuses.length)}})]),_c('BaseDivider',{staticClass:"my-4",attrs:{"grey":""}}),_c('div',{staticClass:"d-flex justify-end"},[_c('BaseButtonIcon',{attrs:{"color":"secondary","x-small":"","icon":"$mdiTrashCanOutline"},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.preventDefault();return (function () {
                        _vm.$store.commit(
                          'workflow/SET_ACTIVE_WORKFLOW',
                          workflow
                        );
                        _vm.$store.commit(
                          'workflow/setModalDeleteWorkflowDialog',
                          true
                        );
                      }).apply(null, arguments)}}})],1)],1)],1)],1)}),1):(_vm.isFetchingWorkflow)?_c('div',[_c('BaseRow',_vm._l(([1, 2, 3, 4, 5]),function(index){return _c('BaseCol',{key:index,attrs:{"cols":"4"}},[_c('BaseSheet',{staticClass:"elevation-3 pa-4"},[_c('BaseSkeletonLoader',{attrs:{"type":"article, actions"}})],1)],1)}),1)],1):_c('div',{staticClass:"d-flex justify-center"},[_c('BaseParagraph',{attrs:{"type":"primary"},domProps:{"textContent":_vm._s(_vm.$t('workflow.noWorkflowAvailable'))}})],1)],1)]},proxy:true}])}),_c('WorkflowCreateAndEditDialog'),_c('WorkflowDeleteDialog',{on:{"close":function () {
        _vm.$store.commit('workflow/SET_ACTIVE_WORKFLOW', null);
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }