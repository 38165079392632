<template>
  <div>
    <BaseModal
      :value="dialogState"
      modal-title="Changer le workflow"
      is-unique
      @close="reset"
    >
      <template #modal>
        <v-form  @submit.prevent="submit()">
          <base-autocomplete
            v-if="!isFetchingWorkflow"
            v-model="form.workflow"
            class="input"
            :items="filteredWorkflow"
            item-text="name"
            item-value="id"        
          />
          <div v-else>
            <BaseProgressCircular color="primary" size="40" indeterminate />
          </div>
        </v-form>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="submit">
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FolderEditWorkflowDialog",
  data() {
    return {
      form: {
        workflow: null,
      },
      isFetchingWorkflow: true,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isFolderEditWorkflowOpen,
      activeFolder: (state) => state.folder.activeFolder,
      workflows: (state) => state.workflow.workflows,
    }),
    organizationId() {
      return this.$route.params.organizationId;
    },
    filteredWorkflow() {
      return this.workflows.filter((wf) => wf.primaryStatus != null);
    },
  },
  watch: {
    organizationId() {
      this.reset();
    },
    dialogState(newVal) {
      if (newVal && this.activeFolder) {
        this.form.workflow = this.activeFolder.workflow;
        this.initialize();
      }
    },
  },
  mounted() {
    this.reset();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.form = {
        workflow: null,
      };
      this.$store.commit("folder/SET_MODAL_FOLDER_EDIT_WORKFLOW", false);
    },
    initialize() {
      this.isFetchingWorkflow = true;
      this.$store
        .dispatch("workflow/fetchWorkflows", this.$route.params.organizationId)
        .then(() => (this.isFetchingWorkflow = false));
    },
    submit() {
      this.editFolder();
      this.reset();
    },
    editFolder() {
      if (this.form.workflow) {
        const setWorkflowPayload = {
          workflow: this.form.workflow,
        };
        try {
          this.$store.dispatch("folder/setWorkflow", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$store.state.folder.activeFolder.id,
            payload: setWorkflowPayload,
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$t(
              "folder.workflow.snackbar.success.workflowEditedSuccessfully"
            ),
            type: "SUCCESS",
          });
        } catch {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.snackbar.editError"),
            type: "ERROR",
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.input {
  margin-bottom: 0;
}
</style>