import Vue from "vue/dist/vue.esm.js";
import Vuex from "vuex";

import { plugins as coresPlugins, stores as coreStores } from "@/core/Store";
import {
  plugins as AuthenticationPlugins,
  stores as AuthenticationStores,
} from "@/modules/Authentication/Store";

import {
  plugins as editorPlugins,
  stores as editorStores,
} from "@/modules/Editor/Store";

import {
  plugins as folderPlugins,
  stores as folderStores,
} from "@/modules/Folder/Store";

import {
  plugins as adminPlugins,
  stores as adminStores,
} from "@/modules/Admin/Store";

import {
  plugins as clientPlugins,
  stores as clientStores,
} from "@/modules/Client/Store";

import {
  plugins as walletPlugins,
  stores as walletStores,
} from "@/modules/Wallet/Store";

import {
  plugins as cosmoformPlugins,
  stores as cosmoformStores,
} from "@/modules/Cosmoform/Store";

Vue.use(Vuex);

let store = new Vuex.Store({
  plugins: [
    ...coresPlugins,
    ...AuthenticationPlugins,
    ...editorPlugins,
    ...folderPlugins,
    ...adminPlugins,
    ...clientPlugins,
    ...walletPlugins,
    ...cosmoformPlugins,
  ],
  modules: {
    ...coreStores,
    ...AuthenticationStores,
    ...editorStores,
    ...folderStores,
    ...adminStores,
    ...walletStores,
    ...clientStores,
    ...cosmoformStores,
  },
  strict: process.env.NODE_ENV !== "production",
});

export default {
  store,
  install(Vue) {
    //resetting the default store to use this store
    Vue.prototype.$store = store;
  },
};
