<template>
  <div class="w_container">
    <div class="left_section">
      <div class="multiple__file__container">
        <base-icon
          v-if="question.answer.content.length > 1"
          color="primary"
          class="carouse__left__icon"
          :class="{ disabled: activeCarousel <= 1 }"
          icon="$mdiChevronLeft"
          @click.stop="changeActiveImg('PREVIOUS')"
        />
        <base-icon
          v-if="question.answer.content.length > 1"
          color="primary"
          class="carouse__right__icon"
          icon="$mdiChevronRight"
          :class="{
            disabled: activeCarousel >= question.answer.content.length,
          }"
          @click.stop="changeActiveImg('NEXT')"
        />

        <a class="img_container">
          <div class="menu__item" @click.stop="">
            <v-menu offset-y @click.stop="">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" @click.stop="">
                  <div class="menu__activator">
                    <BaseIcon icon="$mdiDotsHorizontal" color="dark" />
                  </div>
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-for="(template, index) in [
                    {
                      key: 'download',
                      title: 'Télécharger le fichier',
                      icon: '$mdiDownload',
                      disabled: false,
                    },
                    {
                      key: 'delete',
                      title: 'Supprimer le fichier',
                      icon: '$mdiDelete',
                      disabled: true,
                    },
                    {
                      key: 'sendGed',
                      title: $t('utils.sendToGed'),
                      icon: '$mdiFileCog',
                      disabled: true,
                    },
                  ]"
                  :key="index"
                  class="item-list"
                >
                  <div
                    class="d-flex align-items-center"
                    :class="{ disabled: template.disabled }"
                    @click.stop="
                      !template.disabled && activeAction(template.key)
                    "
                  >
                    <BaseIcon :icon="template.icon" color="black" :size="18" />
                    <div class="label__menu" v-text="template.title" />
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <BasePdfViewer
            v-if="isFilePdf"
            :pdf="imgSrc"
            @click.stop="open(imgSrc)"
          />
          <img v-else :src="imgSrc" @click.stop="open(imgSrc)" />
        </a>
      </div>
      <div v-if="question.answer.content.length > 1" class="slider__indication">
        <div
          v-for="x in question.answer.content.length"
          :key="x"
          class="indicator"
          :class="{ active: x == activeCarousel }"
        />
      </div>
    </div>
    <div class="rigth_section">
      <div class="item">
        <div
          v-for="(data, index) in wizideeDataDataList"
          :key="index"
          class="content_field"
        >
          <div class="label">{{ data.key }}</div>
          <div
            v-if="data.isImage"
            class="response url"
            @click="goToFile(data.value)"
          >
            {{ $t("utils.seeFile") }}
          </div>
          <div v-else class="response">{{ data.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePdfViewer from "@/core/Components/UI/PdfViewer/BasePdfViewer.vue";
import { validationMixin } from "vuelidate";
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
  name: "WorkflowWezideeQuestionField",
  components: {
    BasePdfViewer,
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeCarousel: 1,
      imgSrc: null,
      isSendingToGed: false,
    };
  },
  computed: {
    ...mapState({
      activeStatus: (state) => state.workflow.activeStatus,
    }),

    isDisabled() {
      return false;
    },
    hasEnoxa() {
      return this.activeOrganization?.enoxa?.serverName;
    },
    wizideeDataDataList() {
      return [
        {
          key: this.$t("utils.authority"),
          value: this.question.answer.extractedData.authority,
        },
        {
          key: this.$t("utils.id"),
          value: this.question.answer.extractedData.permitId,
        },
        {
          key: this.$t("utils.permitList"),
          value: this.question.answer.extractedData.permitList,
        },
        {
          key: this.$t("utils.useName"),
          value: this.question.answer.extractedData.useName,
        },
        {
          key: this.$tc("utils.name", 1),
          value: this.question.answer.extractedData.name,
        },
        {
          key: this.$t("utils.firstName"),
          value: this.question.answer.extractedData.firstName,
        },
        {
          key: this.$t("utils.birthDate"),
          value: this.question.answer.extractedData.birthDate,
        },
        {
          key: this.$t("utils.birthPlace"),
          value: this.question.answer.extractedData.birthPlace,
        },
        {
          key: this.$t("utils.height"),
          value: this.question.answer.extractedData.height,
        },
        {
          key: this.$t("utils.addressFullText"),
          value: this.question.answer.extractedData.addressFullText,
        },
        {
          key: this.$t("utils.validityDate"),
          value: this.question.answer.extractedData.validityDate,
        },
        {
          key: this.$tc("utils.releaseDate", 1),
          value: this.question.answer.extractedData.releaseDate,
        },
        {
          key: this.$tc("utils.iban"),
          value: this.question.answer.extractedData.iban,
        },
        {
          key: this.$tc("utils.bic"),
          value: this.question.answer.extractedData.bic,
        },
        {
          key: this.$tc("utils.holder"),
          value: this.question.answer.extractedData.holder,
        },
        {
          key: this.$tc("utils.socialNumber"),
          value: this.question.answer.extractedData.socialNumber,
        },
        {
          key: this.$t("utils.signature"),
          value: this.question.answer.extractedData.signature,
          isImage: true,
        },
        {
          key: this.$t("utils.photo"),
          value: this.question.answer.extractedData.photo,
          isImage: true,
        },
      ].filter((wd) => wd.value);
    },
    isFilePdf() {
      return this.imgSrc?.split(",")[0].split(";")[0].includes("pdf");
    },
  },

  mounted() {
    this.initialize();
  },
  methods: {
    changeActiveImg(action) {
      switch (action) {
        case "PREVIOUS": {
          if (this.activeCarousel <= 1) return;
          this.imgSrc =
            this.question.answer.content[this.activeCarousel - 2].url;
          return this.activeCarousel--;
        }
        case "NEXT": {
          if (this.activeCarousel >= this.question.answer.content.length)
            return;
          this.imgSrc = this.question.answer.content[this.activeCarousel].url;
          return this.activeCarousel++;
        }
        default:
          break;
      }
    },
    open(url) {
      window.open(url);
    },
    async goToFile(value) {
      const file = await this.dataURLtoFile(value, "file");
      open(URL.createObjectURL(file));
    },
    async activeAction(action) {
      switch (action) {
        case "download": {
          return saveAs(
            this.imgSrc,
            this.question.answer.content[this.activeCarousel - 1].name
          );
        }
        case "delete":
          break;
        case "sendGed":
          break;
      }
    },
    async initialize() {
      this.imgSrc = this.question.answer.content[this.activeCarousel - 1].url;
    },

    dataURLtoFile(dataurl, filename) {
      if (!dataurl) return;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const extension = mime.split("/");

      return new File([u8arr], `${filename}.${extension[1]}`, { type: mime });
    },
    async parseURI(d) {
      var reader = new FileReader();
      reader.readAsDataURL(d);
      return new Promise((res) => {
        reader.onload = (e) => {
          res(e.target.result);
        };
      });
    },
    async getDataBlob(url) {
      var res = await fetch(url);
      var blob = await res.blob();
      var uri = await this.parseURI(blob);
      return uri;
    },
  },
};
</script>

<style lang="scss" scoped>
.w_container {
  --img-width: 294px;
  --img-height: 200px;
  position: relative;
  width: calc(100% - 10px);
  margin: 10px 5px;
  display: flex;
  .left_section {
    flex: none;
    width: var(--img-width);
    .multiple__file__container {
      position: relative;
      .carouse__left__icon {
        left: -30px;
        top: calc(var(--img-height) / 2);
        cursor: pointer;
        color: #2536cc;
      }
      .carouse__right__icon {
        position: absolute;
        right: -30px;
        top: calc(var(--img-height) / 2);
        cursor: pointer;
        color: #2536cc;
        &.disabled {
          color: #707080;
        }
      }
      .img_container {
        display: block;
        position: relative;
        cursor: pointer;
        width: 100%;
        height: var(--img-height);
        border: 3px solid #b6bdff;
        border-radius: 10px;
        overflow: hidden;
        .menu__item {
          position: absolute;
          z-index: 12;
          right: 12px;
          top: 12px;
          opacity: 0.2;
          &:hover {
            opacity: 1;
          }
          .menu__activator {
            background: white;
            border-radius: 5px;
            width: 31px;
            height: 31px;
            display: flex;
            align-content: center;
            justify-content: center;
          }
        }
        & > img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .slider__indication {
      margin-top: 12px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 4px;
      height: 14px;
      .indicator {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #949494;
        transition: 250ms ease-in;
        &.active {
          width: 11px;
          height: 11px;
          background: #2536cc;
        }
      }
    }
    .file_action {
      display: flex;
      margin-top: 10px;
      justify-content: flex-end;
      position: relative;
      .input {
        display: none;
      }
    }
  }
  .rigth_section {
    display: flex;
    width: 100%;
    margin-left: 64px;
    justify-content: space-between;
    .item {
      flex: 1;
      padding: 14px;
      display: flex;
      gap: 16px;
      max-height: 250px;
      overflow-y: auto;
      flex-wrap: wrap;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #eeeef7;
      }

      &::-webkit-scrollbar-thumb {
        background: #b6bdff;
        border-radius: 3px;
      }
      .content_field {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 46%;
        .label {
          color: #707080;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 100% */
          letter-spacing: 0.018px;
        }
        .response {
          color: #353542;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &.url {
            color: #2536cc;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.label__menu {
  color: var(--Principale-Gris-Texte, #707080);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
}
.item-list {
  &:hover {
    background: #f5f8ff;
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.2;
}
</style>
