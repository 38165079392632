<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiArrowLeftThick } from "@mdi/js";
export default {
  name: "MdiArrowRightThick",
  data() {
    return {
      svgPath: mdiArrowLeftThick,
    };
  },
};
</script>
