<template>
  <div>
    <BaseFrontOfficeParagraph
      class="d-block mb-4"
      v-text="$t('auth.reset.resetForm.message')"
    />
    <v-form @submit.prevent="submit()">
      <BaseFrontOfficeTextField
        v-model="form.email"
        autofocus
        :label="`${$tc('utils.email', 1)}*`"
        :error-messages="emailError"
        placeholder="exemple@mail.com"
        @change="$v.form.email.$touch()"
        @blur="$v.form.email.$touch()"
        @keydown.enter.stop="submit()"
      />
    </v-form>
    <div class="d-flex flex-column mb-4">
      <BaseFrontOfficeButton
        class="ml-auto"
        icon="$mdiSend"
        :disabled="$v.form.$invalid"
        @click="submit()"
      >
        {{ $t("utils.send") }}
      </BaseFrontOfficeButton>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "WalletPasswordReset",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
      },
      submitError: "",
      isLoading: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    //#region Form errors
    emailError() {
      if (!this.$v.form.email.$dirty) {
        return "";
      }
      if (!this.$v.form.email.required) {
        return this.$t("wallet.signup.errors.emailRequired");
      }
      if (!this.$v.form.email.email) {
        return this.$t("folder.askInformationForm.validation.emailInvalid");
      }
      return "";
    },
    //#endregion
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      this.submitError = "";
      if (this.$v.form.$invalid) {
        return;
      }
      this.isLoading = true;
      try {
        return await this.$store.dispatch(
          "account/passwordReset",
          this.form.email
        );
      } catch (error) {
        const { data } = error?.response;
        this.$store.dispatch("snackbar/active", {
          message: data?.email.toString() || "error",
          type: "ERROR",
        });
      }

      if (this.$route.fullPath.indexOf("/wallet") != -1) {
        this.$router.push({
          name: "WalletPasswordResetDone",
          query: {
            next: this.$route.query.next,
          },
          params: {
            accessCampaign: this.$route.params.accessCampaign,
          },
        });
      } else {
        this.$router.push({ name: "PasswordResetDone" });
      }
    },
  },
};
</script>
