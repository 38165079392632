<template>
  <div>
    <div class="table-header my-2">
      <BaseParagraph v-text="cartTitle" />
    </div>
    <BaseDataTable
      v-if="itemData.length"
      :headers="headers"
      :items-per-page="-1"
      hide-default-footer
      :items="itemData"
    >
      <template #item="{ item }">
        <tr v-if="isOfferClient">
          <td class="blue-cell primary-row">
            <BaseParagraph text="primary" v-text="item.text" />
          </td>
          <td class="blue-cell">
            <BaseFrontOfficeParagraph
              color="primary"
              bold
              v-text="item.value"
            />
          </td>
        </tr>
        <tr v-else-if="isOfferFileClient">
          <td class="blue-cell primary-row">
            <BaseParagraph text="primary" v-text="item.name" />
          </td>
          <td class="blue-cell">
            <!-- display signedFile if there's signedFile -->
            <a
              :href="
                item.signedPdf || item.signedFile || item.offerFile || item.pdf
              "
              target="_blank"
            >
              <BaseFrontOfficeParagraph
                bold
                color="primary"
                v-text="$t('folder.records.seeFile')"
              />
            </a>
          </td>
        </tr>
        <tr v-else>
          <td class="blue-cell primary-row">
            <BaseParagraph text="primary" v-text="item.name" />
          </td>
          <td class="blue-cell primary-row">
            <BaseParagraph
              class="mb-2 d-block"
              v-text="item.category ? item.category.name : 'Non defini'"
            />
          </td>
          <td class="blue-cell">
            <WalletPreviewData
              class="my-2"
              :name="item.name"
              :question="item"
              mid-size-image
            />
          </td>
        </tr>
      </template>
    </BaseDataTable>
  </div>
</template>

<script>
import WalletPreviewData from "../../Wallet/Components/WalletPreviewData.vue";
export default {
  name: "FullDataTable",
  components: { WalletPreviewData },

  props: {
    cartTitle: {
      type: String,
      default: "",
    },
    isOfferClient: {
      type: Boolean,
    },
    isOfferFileClient: {
      type: Boolean,
    },
    isOfferDetail: {
      type: Boolean,
    },
    itemData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [{ label: 1, category: "commentaire", text: "Champ" }],
    };
  },
  computed: {
    headers() {
      if (!this.isOfferDetail) {
        return [
          {
            id: 1,
            text: this.$t("workflow.targetStatus.createAndEditForm.label"),
            value: "label",
            sortable: false,
          },
          {
            id: 2,
            text: this.$t("utils.category"),
            value: "category",
            sortable: false,
          },
          {
            id: 3,
            text: this.$t("utils.cosmoContent"),
            value: "content",
            sortable: false,
          },
        ];
      }
      return [
        {
          id: 1,
          text: this.$t("workflow.targetStatus.createAndEditForm.label"),
          sortable: false,
        },
        { id: 2, text: this.$t("utils.cosmoContent"), sortable: false },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 4px;
  height: 33px;
  background: #eee;
}
::v-deep .v-data-table-header {
  background: #eee;
  th {
    border: solid 1px #2536cc;
    width: 400px;
  }
}
.blue-cell {
  border: solid 1px #2536cc;
}
.primary-row {
  width: 350px;
}
</style>
