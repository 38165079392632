import { render, staticRenderFns } from "./FullDataTable.vue?vue&type=template&id=1d177211&scoped=true"
import script from "./FullDataTable.vue?vue&type=script&lang=js"
export * from "./FullDataTable.vue?vue&type=script&lang=js"
import style0 from "./FullDataTable.vue?vue&type=style&index=0&id=1d177211&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d177211",
  null
  
)

export default component.exports