<template>
  <svg class="c_icon" viewBox="0 0 15 15" fill="none">
    <g clip-path="url(#clip0_3213_14948)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.34938 1.875C7.34165 1.8751 7.33663 1.87753 7.33379 1.87972L2.39172 5.68381L7.63879 9.75349C7.64266 9.75367 7.6468 9.75378 7.65119 9.75378C7.66306 9.75377 7.67409 9.75301 7.68383 9.75186L12.7537 5.81963L7.36547 1.8795C7.36235 1.87723 7.35711 1.87491 7.34938 1.875ZM13.125 7.11354V13.2692L13.1236 13.2702C13.1236 13.2702 13.1215 13.2715 13.1202 13.272C13.118 13.2729 13.1146 13.2739 13.109 13.2739H1.89103C1.88542 13.2739 1.88197 13.2729 1.87979 13.272C1.8778 13.2712 1.87619 13.2702 1.875 13.2692V6.86495L6.91134 10.7712C6.95897 10.8081 7.04909 10.8743 7.17183 10.9206C7.47778 11.0359 7.83269 11.0233 8.10979 10.9321C8.25185 10.8854 8.35726 10.8114 8.42012 10.7627L13.125 7.11354ZM1.875 6.10708L1.92415 6.04372L1.87504 6.08151L1.875 6.10708ZM6.57133 0.889179C7.01996 0.543858 7.64656 0.5365 8.1033 0.870496L13.8625 5.08192C14.1817 5.31534 14.375 5.68644 14.375 6.08642V13.2739C14.375 13.9791 13.7932 14.5239 13.109 14.5239H1.89103C1.20683 14.5239 0.625 13.9791 0.625 13.2739V6.0768C0.625 5.68796 0.807763 5.32561 1.11259 5.09097L6.57133 0.889179Z"
        fill="#FF5267"
      />
      <path
        d="M8.125 3.4375H6.875L6.84682 6.25H8.1033L8.125 3.4375Z"
        fill="#FF5267"
      />
      <circle cx="7.5" cy="7.5" r="0.625" fill="#FF5267" />
    </g>
    <defs>
      <clipPath id="clip0_3213_14948">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "EmailSpamIcon",
};
</script>
