<template>
  <div
    class="form_container"
    :class="{
      'grid-mobile': ['DATAGRID', 'ROW_DATAGRID'].includes(
        question.typeQuestion
      ),
    }"
  >
    <form ref="form" @submit.prevent="submit()">
      <div v-if="/^TEXT$/.test(question.typeQuestion)">
        <v-text-field
          v-model="form.value"
          outlined
          dense
          :autofocus="focus"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        />
      </div>
      <div v-else-if="/^TEXTAREA$/.test(question.typeQuestion)">
        <v-textarea
          v-model="form.value"
          outlined
          auto-grow
          rows="3"
          row-height="25"
          :autofocus="focus"
          :error-messages="valueErrors"
          @keydown.enter.stop=""
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
        />
      </div>
      <div
        v-else-if="/^DATE$/.test(question.typeQuestion)"
        class="d-flex flex-column align-end"
      >
        <v-text-field
          v-model="form.dateField"
          outlined
          dense
          :autofocus="focus"
          :error-messages="dateFieldErrors"
          @blur="editDateField()"
          @input="editDateField()"
          @keydown.enter="$emit('validateQuestion')"
        />
        <v-date-picker
          v-model="form.value"
          class="d-inline-block"
          color="none"
          locale="fr-fr"
          :first-day-of-week="1"
          :error-messages="valueErrors"
          @change="
            () => {
              form.dateField = form.value.split('-').reverse().join('/');
              $v.form.value.$touch();
            }
          "
          @blur="$v.form.value.$touch()"
          @input="dateMenu = false"
        />
      </div>
      <div
        v-else-if="/^DATETIME$/.test(question.typeQuestion)"
        class="d-flex justify-center"
      >
        <v-date-picker
          v-model="form.value"
          class="d-inline-block mr-2"
          color="none"
          locale="fr-fr"
          :first-day-of-week="1"
          :autofocus="focus"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        />
        <v-time-picker
          v-model="form.time"
          class="d-inline-block"
          color="none"
          height="200"
          format="24hr"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        />
      </div>
      <div v-else-if="/^NUMBER$/.test(question.typeQuestion)">
        <v-text-field
          v-model="form.value"
          outlined
          :autofocus="focus"
          type="number"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        />
      </div>
      <div v-else-if="/^SELECT$/.test(question.typeQuestion)">
        <v-autocomplete
          v-model="form.value"
          outlined
          :items="question.choices"
          :autofocus="focus"
          item-text="name"
          item-value="id"
          clearable
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        />
      </div>
      <div v-else-if="/^RADIOBUTTON$/.test(question.typeQuestion)">
        <v-radio-group
          v-model="form.value"
          :error-messages="valueErrors"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        >
          <v-radio
            v-for="choice in question.choices"
            :key="choice.id"
            :label="choice.name"
            :value="choice.id"
            @click="onClickRadioButton(form.value)"
          >
            <template #label>
              <div>
                <span v-text="choice.name" />
                <BaseIcon
                  v-show="form.value === choice && choice.id"
                  icon="$mdiCheck"
                  color="#ff7f79"
                  large
                />
              </div>
            </template>
          </v-radio>
          <div
            v-if="question.hasFreeOption"
            :value="selectedOption"
            class="input"
            :class="selectedOption ? 'checked' : ''"
            @click.prevent="onClickOther"
          >
            <input
              id="input"
              ref="inputFree"
              v-model="otherValue"
              :type="selectedOption ? 'text' : 'radio'"
              @change="onChangeField($event)"
              @blur="checkedOther = true"
            />
            <label class="label" for="input">{{
              otherValue.length && selectedOption ? "" : "Autre"
            }}</label>
            <BaseIcon
              v-if="selectedOption && checkedOther"
              class="icon"
              icon="$mdiCheck"
              color="#ff7f79"
              large
            />
          </div>
        </v-radio-group>
      </div>
      <div
        v-else-if="/^CHECKBOX$/.test(question.typeQuestion)"
        class="checkboxes"
      >
        <v-checkbox
          v-for="(choice, index) in question.choices"
          :key="index"
          v-model="form.value"
          :value="choice.id"
          :error-messages="
            question.choices.length == index + 1
              ? valueErrors
              : valueErrors != ''
              ? ' '
              : null
          "
          prepend-icon="$mdiPlus"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @click="checkOther = false"
          @keydown.enter="$emit('validateQuestion')"
        >
          <template #label>
            <div>
              <span v-text="choice.name" />
              <BaseIcon
                v-show="form.value && form.value.includes(choice && choice.id)"
                icon="$mdiCheck"
                color="#ff7f79"
                large
              />
            </div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-if="question.hasFreeOption && !selectedOption"
          label="Autre"
          @change="onCheck"
          @blur="$v.form.value.$touch()"
        />
        <div class="input-wrapper" @click="onCheck">
          <input
            v-if="selectedOption"
            id="input"
            ref="inputCheck"
            v-model="otherValue"
            class="input-checkbox checked"
            type="text"
            @change="onChangeField($event)"
            @blur="checkedOther = true"
          />
          <BaseIcon
            v-if="selectedOption && checkedOther"
            class="icon"
            icon="$mdiCheck"
            color="#ff7f79"
            large
          />
        </div>
      </div>
      <div v-else-if="/^FILE$/.test(question.typeQuestion)">
        <v-file-input
          v-model="form.value"
          outlined
          truncate-length="50"
          show-size
          append-icon="$mdiFileOutline"
          :placeholder="$t('wallet.form.fileInputPlaceholder')"
          @change="$v.form.value.$touch()"
          @blur="$v.form.value.$touch()"
          @keydown.enter="$emit('validateQuestion')"
        />
      </div>
      <div v-else-if="/^MULTIPLE_FILE$/.test(question.typeQuestion)">
        <folder-offer-file-drag
          :offer-files="form.value"
          nature="secondary"
          is-wallet
          is-course
          @file-droped="fileInserted"
          @file-name-changed="updateFileName"
          @delete-offer-file="removeFile"
          @keydown.enter="$emit('validateQuestion')"
        />
      </div>
      <div
        v-else-if="/^SIGNATURE$/.test(question.typeQuestion)"
        class="d-flex justify-center"
      >
        <VueSignaturePad
          ref="signaturePad"
          v-model="form.value"
          class="elevation-4 position-relative"
          width="400px"
          height="400px"
          :options="signaturePadOptions"
          @keydown.enter="$emit('validateQuestion')"
        />
        <BaseSheet
          color="grey lighten-3"
          class="image-action edit rounded-b-circle rounded-t-circle elevation-4"
        >
          <BaseButtonIcon
            :disabled="!form.value"
            icon="$mdiUndoVariant"
            color="primary"
            @click="
              () => {
                $refs.signaturePad.undoSignature();
                signaturePadOnEnd();
              }
            "
          />
        </BaseSheet>
        <BaseSheet
          color="grey lighten-3"
          class="image-action mt-14 edit rounded-b-circle rounded-t-circle elevation-4"
        >
          <BaseButtonIcon
            :disabled="!form.value"
            icon="$mdiTrashCanOutline"
            color="secondary"
            @click="
              () => {
                $refs.signaturePad.clearSignature();
                signaturePadOnEnd();
              }
            "
          />
        </BaseSheet>
      </div>
      <div
        v-else-if="/^DATAGRID$/.test(question.typeQuestion)"
        class="d-flex justify-end grid"
      >
        <vue-excel-editor
          v-model="form.table"
          class="excel-editor"
          no-footer
          new-if-bottom
          no-header-edit
          enter-to-south
          no-paging
          no-finding
          no-finding-next
          disable-panel-filter
          disable-panel-setting
          @update="updateTable()"
        >
          <vue-excel-column
            v-for="(choice, index) in question.choices"
            :key="index"
            :field="choice.id.toString()"
            :label="choice.name"
            width="150px"
          />
        </vue-excel-editor>
      </div>
      <div
        v-else-if="/^ROW_DATAGRID$/.test(question.typeQuestion)"
        class="d-flex justify-end grid"
      >
        <vue-excel-editor
          v-model="form.table"
          class="excel-editor"
          no-footer
          no-header-edit
          no-paging
          no-finding
          no-finding-next
          disable-panel-filter
          disable-panel-setting
          @update="updateTable()"
        >
          <vue-excel-column field="name" :label="question.name" width="250px" />
          <vue-excel-column field="" label="" width="250px" />
        </vue-excel-editor>
      </div>
    </form>
  </div>
</template>

<script>
import camelCase from "lodash.camelcase";
import { fromURL, blobToURL } from "image-resize-compress";
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";
import { fileToBase64 } from "@/core/Utils/file.utils";

import answerAPI from "../Services/answer.api";
import FolderOfferFileDrag from "../../Folder/Components/FolderOffer/FolderOfferFileDrag.vue";

export default {
  name: "RecordQuestionForm",
  components: { FolderOfferFileDrag },
  mixins: [validationMixin],
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    focus: {
      type: Boolean,
    },
    isAnonymous: {
      type: Boolean,
    },
    modelValue: {
      type: [Object, String, Number, Boolean],
      default: null,
    },
    organizationId: {
      type: Number,
      default: () => NaN,
    },
    answerSuggestion: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      previouslyRadioOptionSelected: null,
      form: {
        value: null,
        time: null,
        table: [],
        dateField: null,
      },
      signaturePadOptions: {
        penColor: "#000",
        onEnd: this.signaturePadOnEnd,
      },
      dateMenu: false,
      showDatePicker: false,
      showTimePicker: false,
      selectedOption: false,
      checkedOther: false,
      otherValue: "",
    };
  },
  computed: {
    newDataGridRow() {
      let defaultObject = {};
      for (const choice of this.question.choices) {
        defaultObject[choice?.id] = "";
      }
      return defaultObject;
    },
    valueErrors() {
      if (!this.$v.form.value.$dirty) {
        return "";
      }
      if (this.question.required && !this.$v.form.value.required) {
        return "Le champ est requis";
      }
      if (
        /^NUMBER$/.test(this.question.typeQuestion) &&
        !this.$v.form.value.integer
      ) {
        return "Le champ doit être un nombre entier";
      }
      return "";
    },
    dateFieldErrors() {
      if (!this.$v.form.dateField.$dirty) {
        return "";
      }
      if (this.question.required && !this.$v.form.dateField.required) {
        return "Le champ est requis";
      }
      if (!this.dateIsValid) {
        return "Le champ doit être une date valide (DD/MM/YYYY)";
      }
      return "";
    },
    dateIsValid() {
      return /^(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))$/.test(
        this.form.dateField
      );
    },
  },
  watch: {
    "form.value"() {
      this.formValueChange();
    },
    "form.time"() {
      this.formValueChange();
    },
  },
  validations() {
    let validations = {
      form: {
        value: {},
        time: {},
        dateField: {},
        other: "",
      },
    };
    if (this.question.required) {
      validations.form.value = {
        required,
      };
      if (/^DATE/.test(this.question.typeQuestion)) {
        validations.form.dateField = {
          required,
        };
      }
      if (/^DATETIME$/.test(this.question.typeQuestion)) {
        validations.form.time = {
          required,
        };
      }
    }
    if (/^NUMBER$/.test(this.question.typeQuestion)) {
      validations.form.value = {
        ...validations.form.value,
        integer,
      };
    }
    return validations;
  },
  mounted() {
    this.initialize();
  },
  methods: {
    fileInserted({ value }) {
      // insert file locally to use vuelidate+vuex
      let fileList = [];
      for (const file of value) {
        fileList.push(file);
      }

      if (Array.isArray(this.form.value)) {
        return (this.form.value = [...this.form.value, ...value]);
      }
      return (this.form.value = fileList);
    },
    updateFileName({ value, index }) {
      const newFile = new File([this.form.value[index]], `${value}`, {
        type: this.form.value[index].type,
      });
      this.form.value.splice(index, 1, newFile);
    },
    removeFile(index) {
      this.form.value.splice(index, 1);
    },
    async onClickRadioButton(selected) {
      if (this.previouslyRadioOptionSelected == selected) {
        this.form.value = null;
        this.previouslyRadioOptionSelected = null;
        return;
      }
      this.checkedOther = false;
      this.selectedOption = false;
      this.otherValue = "";
      this.previouslyRadioOptionSelected = selected;
      this.radioButtonChange(true);
    },
    onClickOther() {
      this.selectedOption = !this.selectedOption;
      this.form.value = null;
      this.$nextTick(() => {
        if (!this.selectedOption) this.otherValue = "";
        else this.$refs.inputFree.focus();
        this.radioButtonChange();
      });
    },
    onCheck() {
      this.selectedOption = !this.selectedOption;
      this.$nextTick(async () => {
        if (!this.selectedOption) this.otherValue = "";
        else this.$refs.inputCheck.focus();
        await this.formValueChange();
      });
    },
    async onChangeField(event) {
      this.otherValue = event.target.value;
      await this.formValueChange();
    },
    async initialize() {
      /**
       * Workaround because signaturePad size sets to 0 if in a dialog.
       * When page is initialized, we set it to right size
       * Link https://github.com/neighborhood999/vue-signature-pad/issues/62#issuecomment-458891526
       */
      if (/^SIGNATURE$/.test(this.question.typeQuestion)) {
        this.$nextTick(function () {
          this.$refs.signaturePad.resizeCanvas();
        });
      }
      if (this.question.answer?.id) {
        this.initializeForm(
          this.question.typeQuestion,
          this.question.answer.content,
          this.question.answer.choices
        );
      } else {
        if (
          !this.isAnonymous &&
          this.question.datatype &&
          !this.answerSuggestion
        ) {
          this.dataTypeSuggestion = (
            await answerAPI.getRecordQuestionSuggestion({
              organizationId: this.organizationId,
              datatypeSlug: this.question.datatype,
            })
          ).data;
        }
        if (this.answerSuggestion) {
          this.initializeForm(
            this.question.typeQuestion,
            this.answerSuggestion.content,
            this.answerSuggestion.choices
          );
        } else if (this.dataTypeSuggestion?.length) {
          this.initializeForm(
            this.question.typeQuestion,
            this.dataTypeSuggestion[0],
            this.dataTypeSuggestion[0]
          );
        } else {
          if (/^CHECKBOX$/.test(this.question.typeQuestion)) {
            this.form.value = [];
          } else if (/^DATE$/.test(this.question.typeQuestion)) {
            this.form.dateField = null;
            this.editDateField();
          } else if (/^DATETIME$/.test(this.question.typeQuestion)) {
            this.form.value = null;
            this.form.time = null;
          } else if (/^DATAGRID$/.test(this.question.typeQuestion)) {
            this.form.table = [this.newDataGridRow];
          } else if (/^ROW_DATAGRID$/.test(this.question.typeQuestion)) {
            let tablePayload = this.question.choices.map((choice) => {
              let payload = {
                name: choice.name,
                "": "",
              };
              return payload;
            });
            this.form.table = tablePayload;
          }
        }
      }
    },
    async initializeForm(typeQuestion, content, choices) {
      switch (typeQuestion) {
        case "TEXT":
        case "TEXTAREA":
        case "NUMBER":
          this.form.value = content;
          break;
        case "DATE":
          if (content) {
            this.form.value = content.substr(0, 10);
            this.form.dateField = new Date(this.form.value).toLocaleDateString(
              "fr",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            );
            this.editDateField();
          }
          break;
        case "DATETIME":
          {
            if (content) {
              const timeContent = content.replace(":00+00:00", "");
              this.form.value = timeContent.substr(0, 10);
              this.form.dateField = new Date(
                this.form.value
              ).toLocaleDateString("fr", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              this.form.time = timeContent.substr(11, 5);
            }
          }
          break;
        case "SELECT":
        case "RADIOBUTTON":
          {
            if (choices.length > 0) {
              const choice = this.question.choices.find(
                (c) => c.name == choices[0].name
              );
              this.form.value = choice?.id;
            }
            if (
              this.question.hasFreeOption &&
              this.question.answer.freeOptionContent.length
            ) {
              this.otherValue = this.question.answer.freeOptionContent;
              this.selectedOption = true;
              this.checkedOther = true;
            }
            this.previouslyRadioOptionSelected = this.form.value;
          }
          break;
        case "CHECKBOX":
          {
            const choicesIds = [];
            for (const selectedChoice of choices) {
              const choice = this.question.choices.find(
                (c) => c.name == selectedChoice.name
              );
              choicesIds.push(choice?.id);
            }
            this.form.value = choicesIds;
            if (this.question.hasFreeOption) {
              this.otherValue = this.question.answer.freeOptionContent;
              this.selectedOption =
                this.question.answer.freeOptionContent.length;
            }
          }
          break;
        case "FILE":
          {
            if (!content) return;
            const { url, name } = content[0];
            this.form.value = this.dataURLtoFile(
              await this.getBase64FileByUrl(url),
              name
            );
          }
          break;
        case "SIGNATURE":
          if (!content) return;
          this.form.value = content;

          break;
        case "MULTIPLE_FILE":
          {
            if (!content) {
              this.form.value = null;
               return;
            }
            const values = content.map(async (file) => {
              return this.dataURLtoFile(
                await this.getBase64FileByUrl(file.url),
                file.name
              );
            });
            this.form.value = await Promise.all(values);
          }
          break;
        case "DATAGRID":
          if (content && Object.keys(content).length > 0) {
            this.form.table = content.map((row) => {
              const keys = Object.keys(row);
              let payload = {};
              keys.forEach((key) => {
                const choice = this.question.choices.find(
                  (c) => camelCase(c.name) == camelCase(key)
                );
                if (choice?.id) return (payload[choice?.id] = row[key]);
              });
              return payload;
            });
          } else {
            this.form.table = [this.newDataGridRow];
          }
          this.updateTable();
          break;
        case "ROW_DATAGRID":
          if (content && Object.keys(content).length > 0) {
            this.form.table = content.map((elem) => {
              const key = Object.keys(elem).find((q) => q != "");
              let payload = {};
              payload.name = elem[key];
              payload[""] = elem[""];
              return payload;
            });
          } else {
            let tablePayload = this.question.choices.map((choice) => {
              let payload = {
                name: choice?.name,
                "": "",
              };
              return payload;
            });
            this.form.table = tablePayload;
            this.updateTable();
          }
          break;
        default:
          break;
      }
    },
    editDateField() {
      let newVal = this.form.dateField;
      if (!newVal) {
        this.form.value = null;
        return;
      }
      if (newVal.length > 10) {
        this.$nextTick(() => {
          this.form.dateField = newVal.substr(0, 10);
          newVal = this.form.dateField;
        });
      }
      this.$v.form.dateField.$touch();
      this.form.value = this.dateIsValid
        ? newVal.split("/").reverse().join("-")
        : null;
      this.$v.form.value.$touch();
    },
    /**
     * Function hooked to the signature pad for the data signature only.
     * It updates the value of the form with the file data of the signature
     */
    signaturePadOnEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.form.value = isEmpty ? null : data;
    },
    updateTable() {
      if (/^ROW_DATAGRID$/.test(this.question.typeQuestion)) {
        this.form.value = this.form.table.map((elem) => {
          delete elem.$id;
          return elem;
        });
        return;
      }
      this.form.value = this.form.table.map((row) => {
        const rowKeys = Object.keys(row).filter((k) => k !== "$id");
        let objectToReturn = {};
        for (const key of rowKeys) {
          const choice = this.question.choices.find(
            (c) => c?.id == Number.parseInt(key)
          );
          if (choice.name) objectToReturn[choice.name] = row[key];
        }
        return objectToReturn;
      });
    },
    async buildAnswer() {
      let payload = {
        id: this.question.answer?.id,
      };
      switch (this.question.typeQuestion) {
        case "TEXT":
        case "TEXTAREA":
        case "DATE":
        case "NUMBER":
        case "SIGNATURE": {
          payload.content = this.form.value;
          payload.fileName = "Fichier.png";
          break;
        }
        case "FILE":
          {
            const fileType = this.form.value?.type;
            const fileSize = this.form.value?.size;
            let image = await fileToBase64(this.form.value);
            if (
              (fileType == "image/jpeg" ||
                fileType == "image/jpg" ||
                fileType == "image/png") &&
              fileSize > 2000000
            ) {
              image = await fromURL(image, 60);
              image = await blobToURL(image);
            }
            payload.content = image;
            payload.fileName = this.form.value?.name;
          }
          break;
        case "MULTIPLE_FILE":
          {
            const values = this.form.value.map(async (file) => ({
              name: file.name,
              encoded_file: await fileToBase64(file),
            }));
            const result = await Promise.all(values);
            payload.content = result;
          }
          break;
        case "RADIOBUTTON":
          if (this.question.hasFreeOption && this.otherValue.length) {
            payload.freeOptionContent = this.otherValue;
          } else payload.choices = this.form.value ? [this.form.value] : null;
          break;
        case "CHECKBOX":
          if (Number.isSafeInteger(this.form.value))
            payload.choices =
              this.question.hasFreeOption && this.selectedOption
                ? [this.form.value, `free__${this.otherValue}`]
                : [this.form.value];
          else {
            payload.choices =
              this.question.hasFreeOption && this.selectedOption
                ? [...this.form.value, `free__${this.otherValue}`]
                : this.form.value;
          }
          payload.freeOptionContent = this.otherValue;
          break;
        case "SELECT":
          if (Number.isSafeInteger(this.form.value)) {
            payload.choices = [this.form.value];
          } else {
            payload.choices = this.form.value;
          }
          break;
        case "DATETIME":
          payload.content =
            this.form.value.substr(0, 10) + " " + this.form.time;
          break;
        case "DATAGRID":
          payload.content = this.form.value;
          break;
        case "ROW_DATAGRID":
          payload.content = this.form.value;
          break;
        default:
          break;
      }
      return payload;
    },
    async formValueChange() {
      if (this.otherValue.length) {
        const payload = await this.buildAnswer();
        this.$emit("changeValue", payload);
      } else {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (!this.form.value) {
            const grid = ["DATAGRID", "ROW_DATAGRID"];

            const value = grid.includes(this.question.typeQuestion)
              ? this.form.table
              : null;
            this.$emit("changeValue", { content: value });
          } else {
            const payload = await this.buildAnswer();
            this.$emit("changeValue", payload);
          }
        } else {
          this.$emit("changeValue", null);
        }
      }
    },
    async radioButtonChange(isSimpleRadioButton) {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return this.$emit("changeValue", null);
      const payload = await this.buildAnswer();
      this.$emit("changeValue", payload);
      this.$emit("submit", true);
      if (isSimpleRadioButton && !this.answerSuggestion) {
        this.$emit("validateQuestion");
      }
    },
    async submit() {
      await this.formValueChange().then(() => {
        this.$emit("submit");
      });
    },
    async getBase64FileByUrl(url) {
      var res = await fetch(url);
      var blob = await res.blob();
      var uri = await this.parseURI(blob);
      return uri;
    },
    async parseURI(d) {
      var reader = new FileReader();
      reader.readAsDataURL(d);
      return new Promise((res) => {
        reader.onload = (e) => {
          res(e.target.result);
        };
      });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style scoped lang="scss">
.excel-editor {
  max-width: 700px;
}
.input-wrapper {
  position: relative;
}
.input-checkbox {
  border-radius: 25px;
  border: 1px solid #ebebeb;
  display: inline;
  min-width: 128px;
  height: 48px;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
  #input {
    width: 100%;
    height: 45px;
    position: relative;
    bottom: 10px;
    background: red;
  }
  .label {
    color: #3c3b8e;
    font-family: "Nunito";
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
    text-align: center;
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  & input[type="checkbox"] {
    opacity: 0.011;
  }
  &.checked {
    transition: 0s ease;
    color: white !important;
    background: linear-gradient(
        165.56deg,
        #2f479d 19.48%,
        rgba(47, 71, 157, 0) 144.95%
      ),
      #ff5b60 !important;
    border-color: #ffffff;
  }

  ::v-deep input {
    color: #fff;
  }
}
.form_container {
  display: flex;
  justify-content: flex-end;
}
.image-action {
  position: absolute;
  margin-left: 350px;
  margin-top: 10px;
}

.icon {
  position: absolute;
  top: 3px;
  right: 5px;
}
.input {
  position: relative;
  border-radius: 25px;
  border: 1px solid #ebebeb;
  min-width: 128px;
  height: 48px;
  margin-left: 4px;
  background-color: #fff;
  display: block;

  cursor: pointer;

  #input {
    top: 12px;
    left: 12px;
    position: relative;
    width: 100%;
  }
  .label {
    color: #54539c;
    font-family: "Nunito";
    font-size: 16px;
    line-height: 48px;
    font-weight: normal;
    text-align: center;
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
  & input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }
  &.checked {
    transition: 0s ease;
    color: white !important;
    background: linear-gradient(
        165.56deg,
        #2f479d 19.48%,
        rgba(47, 71, 157, 0) 144.95%
      ),
      #ff5b60 !important;
    border-color: #ffffff;
  }

  ::v-deep input {
    color: #fff;
  }
}

::v-deep .v-input__slot {
  border-radius: 25px;
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    #ff7074 !important;
}
::v-deep .v-input--checkbox .v-input__slot {
  background: none !important;
}
::v-deep .v-input__slot fieldset {
  border-radius: 25px;
  margin: 1px 2px 2px 2px !important;
  border: hidden;
  background-color: white;
}
::v-deep .v-input__slot .v-text-field__slot {
  margin-left: 10px;
}
::v-deep .v-textarea .v-text-field__slot {
  min-width: min(400px, 100vw - 200px);
}

::v-deep .v-picker__title {
  height: 100px;
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    #ff7074 !important;
}
::v-deep .v-input--radio-group--column .v-input__slot {
  background: none !important;
  min-width: auto !important;
  border-radius: auto !important;
}
::v-deep .v-input--selection-controls__input {
  display: none;
}
::v-deep .v-input--radio-group__input .v-radio,
::v-deep .v-input--checkbox .v-input__slot {
  box-sizing: border-box;
  height: 48px;
  min-width: 128px;
  border-radius: 25px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 0px;
  border: 1px solid #ebebeb;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #3c3b8e !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}
::v-deep .v-input--radio-group__input .v-radio {
  background: #fff;
  color: #54539c;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
::v-deep .v-radio.v-item--active,
::v-deep .v-input--checkbox.v-input--is-label-active .v-input__slot {
  transition: 0s ease;
  color: white !important;
  background: linear-gradient(
      165.56deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    ),
    #ff5b60 !important;
  border-color: #ffffff;
}
::v-deep .theme--light.v-label {
  color: inherit !important;
}
::v-deep .v-input__prepend-outer {
  display: none;
}
.checkboxes {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-end;
}
::v-deep .v-input--checkbox {
  margin: 0px 4px 0px 4px;
}
::v-deep .v-input--radio-group .v-input__control,
::v-deep .v-input--radio-group .v-input__slot {
  display: block;
}

::v-deep .v-input--radio-group .v-input--radio-group__input {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
::v-deep .v-radio {
  margin: 0px 4px 0px 4px;
}

::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
::v-deep .v-date-picker-table button {
  color: #353542 !important;
}

::v-deep thead .first-col span {
  display: none;
}
::v-deep .vue-excel-editor {
  min-width: 60%;
  font-size: 16px !important;
}
::v-deep .v-input__icon--append > span {
  color: #ff7074;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  cursor: pointer;
}
.calendar_icon {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  padding: 20px;
  right: 25px;
  ::v-deep .v-icon__svg {
    fill: #ff7074;
  }
}
.form_modal {
  .checkboxes {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .v-input--checkbox {
    margin: 0px 8px 0px 0px;
  }
  &::v-deep {
    .v-input--radio-group__input {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
  }
  .v-radio {
    margin: 0px 8px 0px 4px;
  }
  .full_textarea {
    width: 100% !important;
    ::v-deep .v-text-field__slot {
      min-width: auto !important;
      max-width: auto !important;
      width: 100% !important;
    }
  }
  .signed_pad_container {
    display: flex;
    position: relative;
    .signed_pad {
      border-radius: 5px;
      background: #fff;
      width: 100%;
      aspect-ratio: 1/1;
      box-shadow: 0px 0px 2px #c4c4c4;
    }
    .signed_pad_action {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
.grid {
  margin-bottom: 24px;
}
@media (max-width: 500px) {
  .grid-mobile {
    display: flex;
    justify-content: start;
    overflow-x: auto;
  }
}
</style>
