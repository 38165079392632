export default class OfferFile {
  constructor({
    id = Number.NaN,
    name = "",
    offerFile = null,
    dateCreated = null,
    dateUpdated = null,
    signedFile = null,
    signaturePage = 1,
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.offerFile = offerFile;
    this.dateCreated = dateCreated ? new Date(dateCreated) : null;
    this.dateUpdated = dateUpdated ? new Date(dateUpdated) : null;
    this.signedFile = signedFile || null;
    this.signaturePage = signaturePage;
  }
}
