<template>
  <BaseModal
    v-if="isOpen"
    :value="isOpen"
    modal-title="Envoi en DPAE"
    @click:outside="close"
    @close="close"
  >
    <template #modal>
      <div class="modal-container">
        <div class="d-flex align-center">
          <BaseIcon
            icon="$mdiAlertCircleOutline"
            color="#FF5267"
            class="mr-1"
          />
          <span class="subtitle" v-text="$t('folder.missingFields')" />
        </div>
        <div class="subheader mt-3" v-text="$t('folder.sendDPAEWarning')" />

        <div class="missing-container mt-3">
          <div
            v-for="(value, index) in missingValues"
            :key="index"
            class="missing-item mandatory mx-2"
            v-text="value"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <BaseButton class="ml-auto" type="primary" @click.stop="close">
        {{ $t("navigation.close") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "FolderSendDataToDPAEDialog",
  computed: {
    ...mapState({
      isOpen: (state) => state.folder.modals.isSendToDPAEDialogOpen,
      missingValues: (state) => state.folder.dpaeMissingFields,
    }),
  },
  methods: {
    close() {
      this.$store.commit("folder/SET_DPAE_DIALOG_OPEN", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  font-family: "Inter";
  font-style: normal;
  line-height: 19px;
  font-weight: 400;
  .subtitle {
    font-size: 16px;
    color: #353542;
  }
  .subheader {
    font-size: 14px;
    line-height: 17px;
    color: #707080;
  }
  .missing-item {
    display: flex;
    align-content: center;
    margin: 0px 4px 4px;
    justify-content: top;
    padding: 6px 8px;
    gap: 16px;
    height: 31px;
    color: #707080;
    font-size: 16px;
  }

  .mandatory {
    border: 1px solid #ff5267;
    border-radius: 5px;
  }
  .missing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
  }
}
</style>
