var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"container d-flex align-center py-2",class:{
    'container-error': _vm.isError,
    'suggestion-container': _vm.type === 'secondary',
    'white-container': _vm.isWhiteContainer,
  }},_vm.$listeners),[(_vm.type === 'primary')?_c('BaseIcon',{staticClass:"my-auto mr-4",attrs:{"icon":"$mdiLock","small":""}}):_vm._e(),_c('div',{staticClass:"flex-grow-1 d-flex flex-column justify-center"},[(/^FILE|SIGNATURE$/.test(_vm.typeQuestion) && _vm.content)?_c('a',{attrs:{"href":_vm.imageContent,"target":"_blank"}},[(_vm.isImage)?_c('BaseFrontOfficeImage',{attrs:{"src":_vm.imageContent,"height":"32px","width":"72px"},on:{"error":function($event){_vm.isImage = false}}}):_c('BaseFrontOfficeParagraph',{attrs:{"bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('answer.seeFolder', { name: _vm.name }))}})],1):(/MULTIPLE_FILE/.test(_vm.typeQuestion) && _vm.content)?_vm._l((_vm.content),function(c){return _c('div',{key:c.id},[_c('span',{staticClass:"record_question_container",attrs:{"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return _vm.openMultiFile(c)}}},[_c('a',{staticClass:"label link"},[_vm._v(_vm._s(c.label || c.name))])])])}):(/DATAGRID|ROW_DATAGRID/.test(_vm.typeQuestion) && _vm.content)?_c('a',{on:{"click":function($event){return _vm.$emit('show')}}},[_c('BaseFrontOfficeParagraph',{staticStyle:{"text-decoration":"underline"},attrs:{"bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('answer.seeTable'))}})],1):(/DATE/.test(_vm.typeQuestion) && _vm.content)?_c('BaseFrontOfficeParagraph',{attrs:{"truncate":"","bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.content), 'date'))}}):(/DATETIME/.test(_vm.typeQuestion) && _vm.content)?_c('BaseFrontOfficeParagraph',{attrs:{"truncate":"","bold":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.content), 'full'))}}):(
        /TEXT|TEXTEARA|SELECT|RADIOBUTTON|CHECKBOX/.test(_vm.typeQuestion) &&
        _vm.content
      )?_c('BaseFrontOfficeParagraph',{attrs:{"color":_vm.type === 'primary' ? 'primary' : '',"bold":""},domProps:{"textContent":_vm._s(_vm.content)}}):(/NUMBER/.test(_vm.typeQuestion) && !isNaN(parseInt(_vm.content, 10)))?_c('BaseFrontOfficeParagraph',{attrs:{"color":"primary","bold":""},domProps:{"textContent":_vm._s(_vm.content)}}):_c('BaseFrontOfficeParagraph',{attrs:{"truncate":"","italic":""},domProps:{"textContent":_vm._s(_vm.$t('answer.noContent'))}}),_c('BaseCaption',{attrs:{"uppercase":"","text":"disabled"},domProps:{"textContent":_vm._s(_vm.name)}})],2),(!_vm.folderClosed && !_vm.hideAction)?_c('BaseFrontOfficeIcon',{staticClass:"ml-4",attrs:{"icon":"$mdiChevronRightCircleOutline"},on:{"click":function($event){return _vm.$emit('show')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }