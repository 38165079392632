<template>
  <div class="file_container">
    <div v-if="loading" class="loading_container">
      <div class="loading_label" v-text="`${$t('utils.loading')}...`" />
    </div>
    <template v-else>
      <div class="file_section">
        <!-- HEADER SECTION -->
        <div class="header_section">
          <div class="pdf_name">
            <BaseIcon icon="$mdiFileDocument" color="#9BBDFF" class="icon" />
            <div class="name" v-text="name" />
          </div>
          <div v-if="fileType == FILE_TYPE.PDF" class="pagination">
            <BaseButtonIcon
              icon="$mdiArrowLeft"
              :disabled="pageNumber === 1"
              @click="goToPrevPage()"
            />
            <input
              v-model.number="pageNumber"
              type="number"
              min="1"
              :max="totalPages"
              @input="handlePageChange()"
              @focus="pageInputFocused = true"
              @blur="pageInputFocused = false"
            />
            <div class="pages" v-text="`/ ${totalPages}`" />
            <BaseButtonIcon
              icon="$mdiArrowRight"
              :disabled="pageNumber === totalPages"
              @click="goToNextPage()"
            />
          </div>
          <div class="action">
            <v-menu offset-y left>
              <template #activator="{ on, attrs }">
                <BaseButtonIcon
                  icon="$mdiDotsHorizontal"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <div class="menu_list">
                <v-btn
                  x-large
                  class="menu_list_item"
                  block
                  text
                  @click="downloadFile()"
                >
                  <BaseIcon class="icon" icon="$mdiDownload" />
                  <span class="label" v-text="$t('utils.downloadFile')" />
                </v-btn>
                <v-btn
                  v-if="displayGed"
                  x-large
                  class="menu_list_item"
                  block
                  text
                  @click="$emit('sendToGed')"
                >
                  <BaseIcon class="icon" icon="$mdiSend" />
                  <span class="label" v-text="$t('utils.sendToGed')" />
                </v-btn>
                <v-btn
                  x-large
                  class="menu_list_item"
                  block
                  text
                  @click="$emit('deleteFile')"
                >
                  <BaseIcon class="icon icon_danger" icon="$mdiTrashCan" />
                  <span class="label" v-text="$t('utils.deleteFile')" />
                </v-btn>
              </div>
            </v-menu>
          </div>
        </div>
        <!-- IMG SECTION -->
        <div v-if="fileType == FILE_TYPE.IMG" class="img_section">
          <img :src="url" />
        </div>
        <!-- PDF SECTION -->
        <div
          v-else-if="fileType == FILE_TYPE.PDF"
          ref="pdfContainer"
          class="pdf_section"
          @scroll="handleScroll()"
        >
          <div
            v-for="pageIndex in totalPages"
            :key="pageIndex"
            class="pdf_page"
          >
            <canvas :ref="'pdfCanvas_' + pageIndex" />
          </div>
        </div>
        <div v-else class="other_section">
          <div class="other_label" v-text="$t('utils.previewNotAvailable')" />
          <BaseIcon icon="$mdiEyeOff" color="#707080" class="icon" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { FILE_TYPE } from "@/core/Utils/types.utils";
import { saveAs } from "file-saver";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: "BaseFilePreviewer",
  props: {
    url: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    displayGed: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      FILE_TYPE: FILE_TYPE,
      pdfDocument: null,
      pageNumber: 1,
      totalPages: 0,
      pageInputFocused: false,
      isScrollingByMethod: false,
      pageMargin: 20,
    };
  },
  computed: {
    fileType() {
      return this.getFileType(this.name);
    },
  },
  watch: {
    url: {
      handler(val) {
        if (!val) return;
        this.initialize();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      if (this.fileType == FILE_TYPE.PDF) await this.loadPdf(this.url);
      this.loading = false;
    },
    async loadPdf(pdfUrl) {
      try {
        this.pdfDocument = await pdfjsLib.getDocument(pdfUrl).promise;
        this.totalPages = this.pdfDocument.numPages;
        this.renderAllPages();
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    // Rendre toutes les pages du PDF
    async renderAllPages() {
      for (let i = 1; i <= this.totalPages; i++) {
        this.renderPage(i);
      }
    },
    // Rendre une page spécifique
    async renderPage(pageNumber) {
      const page = await this.pdfDocument.getPage(pageNumber);

      // Obtenir la largeur du conteneur
      const containerWidth = this.$refs.pdfContainer.clientWidth;

      // Calculer le scale en fonction de la largeur du conteneur
      const viewport = page.getViewport({ scale: 1 });
      const scale = (containerWidth - 32) / viewport.width;
      const scaledViewport = page.getViewport({ scale });

      // Rendre la page dans le canevas
      const canvas = this.$refs[`pdfCanvas_${pageNumber}`][0];
      const context = canvas.getContext("2d");
      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };
      await page.render(renderContext).promise;
    },
    goToNextPage() {
      this.isScrollingByMethod = true;
      if (this.pageNumber < this.totalPages) {
        this.pageNumber++;
        this.scrollToPage(this.pageNumber);
      }
      setTimeout(() => {
        this.isScrollingByMethod = false;
      }, 300);
    },
    goToPrevPage() {
      this.isScrollingByMethod = true;
      if (this.pageNumber > 1) {
        this.pageNumber--;
        this.scrollToPage(this.pageNumber);
      }
      setTimeout(() => {
        this.isScrollingByMethod = false;
      }, 300);
    },
    handlePageChange() {
      this.isScrollingByMethod = true;
      if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.scrollToPage(this.pageNumber);
      }
      setTimeout(() => {
        this.isScrollingByMethod = false;
      }, 300);
    },
    handleScroll() {
      if (this.isScrollingByMethod) return;
      const pdfContainer = this.$refs.pdfContainer;
      const scrollTop = pdfContainer.scrollTop;
      const pageHeight =
        this.$refs.pdfCanvas_1[0].offsetHeight + this.pageMargin;
      const currentPage =
        Math.floor((scrollTop + this.pageMargin) / pageHeight) + 1;
      if (currentPage !== this.pageNumber) {
        this.pageNumber = currentPage;
      }
    },
    scrollToPage(pageNumber) {
      const pdfContainer = this.$refs.pdfContainer;
      const pageElement = this.$refs[`pdfCanvas_${pageNumber}`][0];
      const pageOffsetTop = pageElement.offsetTop;
      const marginOffset = this.pageMargin * (pageNumber - 1);
      pdfContainer.scrollTo({
        top: pageOffsetTop - marginOffset,
        behavior: "smooth",
      });
    },
    getFileType(fileName) {
      const lowerCaseFilename = fileName.toLowerCase();
      const pdfExtensions = [".pdf"];
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".svg",
        ".webp",
        ".apng",
        ".ico",
      ];
      if (pdfExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
        return this.FILE_TYPE.PDF;
      if (imageExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
        return this.FILE_TYPE.IMG;
      return this.FILE_TYPE.OTHER;
    },
    downloadFile() {
      saveAs(this.url, this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.file_container {
  padding: 16px;
  border-radius: 5px;
  background: #f4f7ff;
  width: 100%;
  height: 100%;
  .loading_container {
    color: #707080;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
  .file_section {
    width: 100%;
    height: 100%;
    .header_section {
      width: 100%;
      height: 40px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      .pdf_name {
        display: flex;
        align-items: center;
        width: 30%;
        flex: none;
        height: 40px;
        .icon {
          ::v-deep {
            .v-icon__svg {
              width: 16px;
              height: 16px;
            }
          }
        }
        .name {
          color: #707080;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
        }
      }
      .pagination {
        width: 40%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        input {
          width: 36px;
          height: 16px;
          padding: 2px;
          color: #707080;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
          text-align: right;
        }
        .pages {
          width: 36px;
          color: #707080;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
        }
      }
      .action {
        width: 30%;
        flex: none;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
      }
    }
    .img_section {
      width: 100%;
      height: calc(100% - 40px);
      padding: 0 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .pdf_section {
      width: 100%;
      height: calc(100% - 40px);
      padding: 16px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #eeeef7;
      }

      &::-webkit-scrollbar-thumb {
        background: #b6bdff;
        border-radius: 3px;
      }
      .pdf_page {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .other_section {
      width: 100%;
      height: calc(100% - 40px);
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      .other_label {
        color: #707080;
        font-size: 32px;
        line-height: 40px;
      }
      .icon {
        ::v-deep {
          .v-icon__svg {
            width: 44px;
            height: 44px;
            flex: none;
          }
        }
      }
    }
  }
}
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    &::v-deep {
      .icon {
        margin-right: 8px;
        color: #2536cc;
        &.icon_danger {
          color: #ff5267;
        }
        .v-icon__svg {
          width: 16px;
          height: 16px;
          flex: none;
        }
      }
      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #353542;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      &::v-deep {
        .label {
          color: #2536cc;
        }
      }
    }
    &.v-btn--disabled {
      &::v-deep {
        .icon {
          .v-icon {
            color: #cfcfdc !important;
          }
        }
        .label {
          color: #cfcfdc;
        }
      }
    }
  }
}
</style>
