<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="15" height="13" fill="#FF5267" />
    <rect
      id="Rectangle 8"
      x="-284"
      y="-357"
      width="764"
      height="673"
      rx="35"
      fill="#F1F3F6"
    />
    <g id="Statut des mails">
      <rect x="-157" y="-7" width="191.31" height="27" rx="5" fill="white" />
      <g id="Frame 5430">
        <g id="Calque 2">
          <g id="Calque 1">
            <path
              id="Vector"
              d="M7.87 10.8599C7.87185 10.615 7.89527 10.3707 7.94 10.1299H1.43V2.98988L7.16 6.56988L12.88 2.98988V6.62988C13.3826 6.7104 13.8668 6.87971 14.31 7.12988V1.55988C14.31 1.18062 14.1593 0.816897 13.8912 0.54872C13.623 0.280543 13.2593 0.129883 12.88 0.129883L1.43 0.129883C1.05074 0.129883 0.687014 0.280543 0.418837 0.54872C0.15066 0.816897 0 1.18062 0 1.55988L0 10.1299C0 10.5091 0.15066 10.8729 0.418837 11.141C0.687014 11.4092 1.05074 11.5599 1.43 11.5599H7.94C7.8975 11.3289 7.87408 11.0947 7.87 10.8599ZM12.87 1.55988L7.16 5.12988L1.43 1.55988H12.87Z"
              fill="#707080"
            />
            <path
              id="Vector_2"
              d="M11.8401 12.38C12.3035 12.3819 12.7533 12.2229 13.1124 11.93C13.4716 11.6372 13.7179 11.2287 13.8093 10.7743C13.9007 10.32 13.8314 9.84807 13.6134 9.43912C13.3954 9.03018 13.0422 8.70964 12.614 8.53227C12.1859 8.3549 11.7094 8.33171 11.2661 8.46667C10.8228 8.60162 10.4401 8.88635 10.1834 9.27219C9.92668 9.65803 9.81195 10.121 9.85878 10.5821C9.90561 11.0431 10.1111 11.4736 10.4401 11.8C10.8126 12.1694 11.3154 12.3778 11.8401 12.38ZM11.8401 7.92996C12.1664 7.92834 12.4896 7.99297 12.7901 8.11996C13.09 8.24048 13.3622 8.42081 13.5901 8.64996C13.8195 8.88151 13.9998 9.15702 14.1201 9.45996C14.2471 9.75699 14.3118 10.0769 14.3101 10.4C14.3101 11.055 14.0499 11.6833 13.5867 12.1465C13.1235 12.6097 12.4952 12.87 11.8401 12.87C11.185 12.87 10.5568 12.6097 10.0936 12.1465C9.63035 11.6833 9.37012 11.055 9.37012 10.4C9.37261 9.74503 9.631 9.11702 10.0901 8.64996C10.3202 8.42081 10.5931 8.23927 10.8934 8.11573C11.1937 7.99218 11.5154 7.92906 11.8401 7.92996ZM12.0001 9.12996V10.42L13.1201 11.09L12.9301 11.39L11.6301 10.61V9.12996H12.0001Z"
              fill="#707080"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmailSpamIcon",
};
</script>
