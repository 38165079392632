<template>
  <v-icon>
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiUngroup } from "@mdi/js";
export default {
  name: "MdiUngroup",
  data() {
    return {
      svgPath: mdiUngroup,
    };
  },
};
</script>
