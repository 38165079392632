<template>
  <v-app>
    <div class="base-app-container">
      <slot />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheFrontOfficeBaseApp",
  computed: {
    ...mapGetters({
      isAppReady: "core/isAppReady",
    }),
  },
};
</script>

<style scoped>
@media all and (min-width: 600px) {
  .base-app-container {
    display: flex;
    height: 100vh;
    width: 100vw;
  }
}
</style>
