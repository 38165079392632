<template>
  <div>
    <BaseFrontOfficeWalletWrapper
      small
      :mobile-title-image="$findStatic('walletAuthLogin')"
      go-back-active
      :go-back-path="{ name: 'WalletAccount' }"
    >
      <template #title>
        <BaseFrontOfficeTitle v-text="$t('wallet.assistant.passwordChange')" />
      </template>

      <BaseFrontOfficeBoard>
        <div>
          <BaseFrontOfficeSubtitle
            class="title"
            v-text="`${$t('account.editForm.oldPassword')}*`"
          />
          <BaseFrontOfficeTextField
            v-model="$v.form.oldPassword.$model"
            reference="oldPasswordRef"
            autofocus
            :type="show.oldPassword ? 'text' : 'password'"
            :error-messages="oldPasswordErrors"
            :append-icon="showOldPasswordIcon"
            @click:append="show.oldPassword = !show.oldPassword"
            @change="$v.form.oldPassword.$touch()"
            @blur="$v.form.oldPassword.$touch()"
          />
          <BaseFrontOfficeSubtitle
            class="title"
            v-text="`${$t('account.editForm.newPassword')}*`"
          />
          <BaseFrontOfficeTextField
            v-model="$v.form.newPassword1.$model"
            :type="show.newPassword1 ? 'text' : 'password'"
            :append-icon="showNewPassword1Icon"
            :error-messages="newPassword1Errors"
            @click:append="show.newPassword1 = !show.newPassword1"
            @change="$v.form.newPassword1.$touch()"
            @blur="$v.form.newPassword1.$touch()"
          />
          <ul>
            <li
              v-for="(warning, index) in passwordWarnings"
              :key="`${warning.id}-${index}`"
              style="font-size: 0.9em !important; color: #a3a3ad !important"
              v-text="warning.value"
            />
          </ul>
        </div>
        <div>
          <BaseFrontOfficeSubtitle
            class="title"
            v-text="`${$t('account.editForm.confirmNewPassword')}*`"
          />
          <BaseFrontOfficeTextField
            v-model="$v.form.newPassword2.$model"
            :type="show.newPassword2 ? 'text' : 'password'"
            :error-messages="newPassword2Errors"
            :append-icon="showNewPassword2Icon"
            @click:append="show.newPassword2 = !show.newPassword2"
            @change="$v.form.newPassword2.$touch()"
            @blur="$v.form.newPassword2.$touch()"
          />
        </div>
        <div>
          <BaseFrontOfficeButton
            class="mb-4"
            min-height="45"
            style="width: 100%"
            :disabled="$v.$invalid"
            @click="submitChangePassword()"
            v-text="$t('utils.validate')"
          />
          <BaseFrontOfficeButton
            type="secondary"
            min-height="45"
            style="width: 100%"
            @click.prevent="$router.push({ name: 'WalletAccount' }).catch(err => {})"
            v-text="$t('utils.cancel')"
          />
        </div>
      </BaseFrontOfficeBoard>
    </BaseFrontOfficeWalletWrapper>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { setUserPassword } from "../../../Authentication/Services/authentication.service";

export default {
  name: "WalletPasswordChange",
  mixins: [validationMixin],

  data() {
    return {
      form: {
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      show: {
        oldPassword: false,
        newPassword1: false,
        newPassword2: false,
      },
      passwordWarnings: [
        {
          value: this.$t(
            "account.editForm.warning.passwordDifferentFromPersonalData"
          ),
          id: 0,
        },
        { value: this.$t("account.editForm.errors.newPasswordLength"), id: 1 },
        {
          value: this.$t("account.editForm.warning.passwordShouldBeUnique"),
          id: 2,
        },
        {
          value: this.$t("account.editForm.warning.passwordNotAllNumeric"),
          id: 3,
        },
      ],
    };
  },
  computed: {
    // password icon
    showOldPasswordIcon() {
      if (this.form.oldPassword === "") return "";
      return this.show.oldPassword ? "$mdiEye" : "$mdiEyeOff";
    },
    showNewPassword1Icon() {
      if (this.form.newPassword1 === "") return "";
      return this.show.newPassword1 ? "$mdiEye" : "$mdiEyeOff";
    },
    showNewPassword2Icon() {
      if (this.form.newPassword2 === "") return "";
      return this.show.newPassword2 ? "$mdiEye" : "$mdiEyeOff";
    },
    // password errors
    oldPasswordErrors() {
      if (!this.$v.form.oldPassword.$dirty) return [];
      let errors = [];
      if (!this.$v.form.oldPassword.required) {
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordRequired"),
        ];
      }
      return errors;
    },
    newPassword1Errors() {
      if (!this.$v.form.newPassword1.$dirty) return [];

      let errors = [];
      if (!this.$v.form.newPassword1.required)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordRequired"),
        ];

      if (!this.$v.form.newPassword1.minLength)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordLength"),
        ];

      return errors;
    },
    newPassword2Errors() {
      if (!this.$v.form.newPassword2.$dirty) return [];

      let errors = [];
      if (!this.$v.form.newPassword2.required)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordRequired"),
        ];

      if (!this.$v.form.newPassword2.minLength)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordLength"),
        ];

      if (!this.$v.form.newPassword2.sameAsPassword) {
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordConfirm"),
        ];
      }
      return errors;
    },
  },
  validations: {
    form: {
      oldPassword: {
        required,
      },
      newPassword1: {
        required,
        minLength: minLength(8),
      },
      newPassword2: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs("newPassword1"),
      },
    },
  },
  methods: {
    async submitChangePassword() {
      this.$v.form.$touch();

      try {
        await setUserPassword(
          this.form.oldPassword,
          this.form.newPassword1,
          this.form.newPassword2
        );
        this.$store.dispatch("snackbar/active", {
          message: this.$t("client.account.editForm.successSnackbar"),
          type: "SUCCESS",
        });
        location.replace("/wallet/auth/login/");
      } catch (error) {
        const errorMessage = this.$t(
          error.response?.data?.password_old &&
            error.response?.data?.password_old[0] === "Mot de passe invalide"
            ? "client.account.wrongPassword"
            : "client.account.editForm.error"
        );
        this.$store.dispatch("snackbar/active", {
          message: errorMessage,
          type: "ERROR",
        });
        this.form.oldPassword === "";
        this.$v.form.$touch();
      }
    },
  },
};
</script>
