<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('client.create')"
    is-complex
    width="900"
    @close="reset"
  >
    <template #modal>
      <div class="form__container">
        <div class="inputs__container">
          <BaseTextField
            v-model="form.email"
            autofocus
            :label="$t('client.label')"
            :has-error="clientError.length > 0"
            :error-messages="clientError"
            @change="$v.form.email.$touch()"
            @blur="$v.form.email.$touch()"
          />
          <BaseTextField
            v-model="form.phone"
            :label="$t('client.phone')"
          />
        </div>
        <div class="inputs__container">
          <BaseTextField
            v-model="form.firstname"
            :label="$t('client.firstName')"
          />
          <BaseTextField
            v-model="form.lastname"
            :label="$t('client.lastName')"
          />
        </div>
        <div class="inputs__container">
          <BaseTextField
            v-model="form.society_name"
            :label="$t('client.societyName')"
          />
          <BaseTextField v-model="form.siret" :label="$t('client.siret')" />
        </div>
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton :loading="isSubmitting" type="primary" @click="addContact">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

import ClientAPI from "@/modules/Client/Services/client.api";

export default {
  name: "ClientCreateDialog",
  mixins: [validationMixin],
  data: function () {
    return {
      isSubmitting: false,
      form: {
        email: null,
        phone: "",
        firstname: "",
        lastname: "",
        society_name: "",
        siret: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.client.modals.isClientCreateDialogOpen,
    }),
    clientError() {
      if (!this.$v.form.email.$dirty) {
        return "";
      }
      if (!this.$v.form.email.required) {
        return this.$t("client.clientRequired");
      }
      if (!this.$v.form.email.email) {
        return this.$t("client.clientEmailError");
      }
      return "";
    },
  },

  methods: {
    reset() {
      this.form = {
        email: null,
        phone: null,
        firstname: null,
        lastname: null,
        society_name: null,
        siret: null,
      };
      this.$store.commit("client/setDisplayClientDialog", false);
    },
    removeNullUndefinedEmpty(obj) {
      const result = {};
      for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
          result[key] = obj[key];
        }
      }
      return result;
    },
    async addContact() {
      if (this.$v.form.email.$invalid) return;
      this.isSubmitting = true;
      try {
        this.isAdding = true;
        const payload = this.removeNullUndefinedEmpty(this.form);
        const newContact = (
          await ClientAPI.postOrganizationCreateClient(
            this.$route.params.organizationId,
            payload
          )
        )?.data;
        this.$router.push({
          name: "clientDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            clientId: newContact.id,
          },
        });
      } catch (error) {
        const { statusText } = error?.response;
        this.$store.dispatch("snackbar/active", {
          message: statusText,
          type: "ERROR",
        });
      }
      this.isSubmitting = false;
    },
  },
};
</script>
<style scoped>
.select-width {
  width: 100%;
}
.inputs__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
</style>
