<template>
  <div class="file-viewer">
    <div class="list-doc">
      <div
        v-for="(file, i) in listFiles"
        :key="file.name + i"
        class="doc"
        :class="{ select: selectedFile && selectedFile.name === file.name }"
        @click.stop="$emit('select-file', file)"
      >
        <div class="item">
          <BaseIcon color="#9BBDFF" icon="$mdiFileDocument" />
          <span class="name mr-2"> {{ file.name }}</span>
          <a
            :href="file.offerFile"
            @click.prevent="downloadFile(file.offerFile, file.name)"
            ><BaseIcon icon="$mdiDownload"
          /></a>
        </div>
      </div>
    </div>

    <div v-if="selectedFile" class="pdf-view">
      <template v-if="FILE_TYPE.PDF == selectedFile.type">
        <div class="pdf-container">
          <div id="file" class="file">
            <pdf
              v-for="i in selectedFile.totalPage"
              :id="i"
              :key="i"
              class="pdf"
              :page="i"
              :src="selectedFile.offerFile"
              :annotation="true"
              :resize="true"
            />
          </div>
        </div>
        <div class="pagination d-flex justify-center">
          <BasePagination
            v-model="page"
            :total-visible="5"
            :length="selectedFile.totalPage"
            :nb-pages="selectedFile.totalPage"
          />
        </div>
      </template>
      <div v-else class="pdf-container">
        <img class="img" :src="selectedFile.offerFile" />
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "pdfvuer";
import { saveAs } from "file-saver";
import { FILE_TYPE } from "@/core/Utils/types.utils";

export default {
  name: "WalletManagerFile",
  components: { pdf },
  props: {
    listFiles: {
      type: Array,
      default: () => [],
    },
    selectedFile: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      page: 1,
      FILE_TYPE: FILE_TYPE,
    };
  },
  watch: {
    page: function (p) {
      p && document.getElementById(p).scrollIntoView();
    },
  },
  methods: {
    downloadFile(fileUrl, name = "download") {
      saveAs(fileUrl, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-viewer {
  width: calc(100% - 355px);
  border-right: 1px solid #cfcfdc;
  height: 100%;
  padding: 24px;

  @media (max-width: 960px) {
    width: 100%;
    height: 50%;
    padding: 12px;
  }
  .list-doc {
    display: flex;
    overflow-x: auto;
    overflow-y: clip;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #eeeef7;
    }

    &::-webkit-scrollbar-thumb {
      background: #ebebeb;
      border-radius: 3px;
    }
    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      padding-inline: 8px;
      width: 100%;
    }
    .doc {
      display: flex;
      height: 100%;
      align-items: center;
      flex-grow: 1;
      background: #fff;
      border: 1px solid #eeeef7;
      padding: 10px 16px;

      @media screen and (max-width: 960px) {
        padding: 5px 8px;
        flex-grow: 1;
      }
      &.select {
        background: #f4f7ff !important;
      }
      .item {
        display: flex;
        align-items: center;
        flex-grow: 1;
        .name {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 300;
          line-height: 18px;
          margin-left: 8px;
        }
        a {
          margin-left: auto;
        }
      }
    }
  }

  .pdf-view {
    height: calc(100% - 45px);
    width: 100%;
    overflow: hidden;
    position: relative;
    @media (max-width: 480px) {
      height: 300px;
    }

    .pagination {
      background-color: #fff;
      position: absolute;
      padding-inline: 16px;
      z-index: 100;
      right: 0;
      left: 0;
      bottom: 0px;

      @media (max-width: 480px) {
        width: calc(100% - 17px);
        bottom: 0px;
        left: 5px;
      }
    }

    .pdf-container {
      width: 100%;
      height: 100%;

      .file {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: 480px) {
          padding: 0px 5px;

          .pagination {
            bottom: 10px !important;
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 101px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          height: 101px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 9px;
          background: #ebebeb;
          height: 101px;
        }

        .pdf {
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        }
      }

      .img {
        width: 100%;
      }
    }
  }
}
</style>
