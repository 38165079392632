<template>
  <v-list-item-group v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-list-item-group>
</template>

<script>
export default {
  name: "BaseListItemGroup",
};
</script>
