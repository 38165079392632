<template>
  <div class="d-flex justify-center mt-8">
    <BaseButton
      icon="$mdiPlus"
      small
      class="mr-4"
      @click="$emit('create-question', 'QUESTION')"
    >
      {{ $t("course.addQuestion") }}
    </BaseButton>
    <BaseButton
      icon="$mdiPlus"
      small
      @click="$emit('create-question', 'MESSAGE')"
    >
      {{ $t("course.addMessage") }}
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: "CourseDetailStepActions",
};
</script>
