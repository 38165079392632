import Question from "./Question";

export const DOCUMENT_CONTROL_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  ERROR: "ERROR",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
};

export class DocumentControlGroup {
  constructor({
    id = Number.NaN,
    name = "",
    verboseName = "",
    description = "",
    type = "",
    controls = [],
  }) {
    this.id = Number.parseInt(id);
    this.verboseName = verboseName;
    this.name = name;
    this.type = type;
    this.description = description;
    this.controls = controls ? controls.map((c) => new DocumentControl(c)) : [];
  }

  get lastControl() {
    if (!this.controls?.length) return null;
    return this.controls[0];
  }
}

export class DocumentControl {
  constructor({
    id = Number.NaN,
    reasons = [],
    status = DOCUMENT_CONTROL_STATUS.PENDING,
    dateCreated = new Date(),
    dateUpdated = new Date(),
    validationSchema = "",
    verboseName = "",
    type = "",
    questions = [],
  } = {}) {
    this.id = Number.parseInt(id);
    this.reasons = reasons;
    this.status = status;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
    this.validationSchema = validationSchema;
    this.verboseName = verboseName;
    this.type = type;
    this.questions = questions?.length
      ? questions.map((q) => new Question(q))
      : [];
  }

  get isError() {
    return [
      DOCUMENT_CONTROL_STATUS.ERROR,
      DOCUMENT_CONTROL_STATUS.FAILED,
    ].includes(this.status);
  }

  get isPending() {
    return [
      DOCUMENT_CONTROL_STATUS.PENDING,
      DOCUMENT_CONTROL_STATUS.IN_PROGRESS,
    ].includes(this.status);
  }

  get isSuccess() {
    return this.status == DOCUMENT_CONTROL_STATUS.SUCCESS;
  }
}
