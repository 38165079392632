<template>
  <div class="preview_container">
    <template v-if="answerDocuments.length">
      <div
        v-for="(document, i) in answerDocuments"
        :key="`f-${i}`"
        class="doc_container"
        @click="openFile(document.url)"
      >
        <div class="doc_title" v-text="document.title" />
        <div class="file_container" :class="containerClass">
          <div class="file">
            <BasePdfViewer
              v-if="document.fileType == FILE_TYPE.PDF"
              :pdf="document.url"
            />
            <img
              v-else-if="document.fileType == FILE_TYPE.IMG"
              :src="document.url"
            />
            <base-icon v-else icon="$mdiFileDocument" color="primary" />
          </div>
          <v-btn
            class="down_btn"
            icon
            small
            @click.stop.prevent="downloadFile(document.url, document.name)"
          >
            <BaseIcon icon="$mdiDownload" />
          </v-btn>
        </div>
        <div class="file_name">
          <span class="name_label" v-text="$t('utils.fileName')" />
          <span class="name" v-text="document.name" />
        </div>
      </div>
    </template>
    <div v-else class="document_item">
      <div class="doc_container">
        <div class="doc_title" v-text="question.name" />
        <div class="file_container" :class="containerClass">
          <div class="missing_file">
            <img
              class="missing_file_thumb"
              :src="$findStatic('missingFileThumb')"
            />
            <div class="file_caption" v-text="$t('utils.missingFile')" />
          </div>
        </div>
        <div class="file_name">
          <span class="name_label" v-text="$t('utils.fileName')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentControl } from "@/core/Models/DocumentControl";
import { saveAs } from "file-saver";
import { FILE_TYPE } from "@/core/Utils/types.utils";

export default {
  name: "ControlPreviewDocument",
  props: {
    question: {
      type: Object,
      required: true,
    },
    control: {
      type: DocumentControl,
      required: true,
    },
  },
  data() {
    return {
      FILE_TYPE: FILE_TYPE,
    };
  },
  computed: {
    containerClass() {
      if (this.control.isError) return "doc_error";
      if (this.control.isSuccess) return "doc_success";
      return "doc_pending";
    },
    answerDocuments() {
      if (!this.question.answer?.content?.length) return [];
      return this.question.answer.content.map((tmpContent) => ({
        title: this.question.name,
        fileType: this.getFileType(tmpContent.name),
        formatedName: this.$t("utils.fileName", { name: tmpContent.name }),
        ...tmpContent,
      }));
    },
  },
  methods: {
    getFileType(fileName) {
      const lowerCaseFilename = fileName.toLowerCase();
      const pdfExtensions = [".pdf"];
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".svg",
        ".webp",
        ".apng",
        ".ico",
      ];
      if (pdfExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
        return this.FILE_TYPE.PDF;
      if (imageExtensions.some((ext) => lowerCaseFilename.endsWith(ext)))
        return this.FILE_TYPE.IMG;
      return this.FILE_TYPE.OTHER;
    },
    downloadFile(fileUrl, name = "download") {
      saveAs(fileUrl, name);
    },
    openFile(link) {
      open(`${link}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview_container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .doc_container {
    width: 300px;
    cursor: pointer;
    .doc_title {
      color: #4f4f4f;
      font-family: "Inter";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .file_container {
      position: relative;
      border-radius: 5px;
      background: #f5f5f5;
      padding: 10px;
      height: auto;
      width: 100%;
      &.doc_error {
        background: #ff5267;
      }
      &.doc_success {
        background: #18a300;
      }
      &.doc_pending {
        background: #f5f5f5;
      }
      .file {
        border-radius: 5px;
        height: 157px;
        width: 100%;
        overflow: hidden;
        background: #fff;
        & > * {
          height: 100%;
          width: 100%;
          object-fit: cover;
          margin: 0;
        }
      }
      .missing_file {
        background: #fff;
        height: 157px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .missing_file_thumb {
          width: 83px;
          height: 83px;
          object-fit: cover;
        }
        .file_caption {
          margin-top: 8px;
          color: #353542;
          text-align: center;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .down_btn {
        background: #f4f7ff;
        position: absolute;
        right: 18px;
        bottom: 18px;
        box-shadow: 0px 2px 4px rgba(46, 45, 193, 0.1),
          0px 10px 10px rgba(46, 45, 193, 0.06);
        &::v-deep {
          .v-icon {
            width: 20px;
            height: 20px;
            color: #2536cc !important;
          }
        }
      }
    }
    .file_name {
      color: #4f4f4f;
      font-family: "Inter";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;
      .name_label {
        text-transform: uppercase;
      }
      .name {
        font-weight: 700;
      }
    }
  }
}
</style>
