import { render, staticRenderFns } from "./WorkflowQuestionCategory.vue?vue&type=template&id=63bd8cd2&scoped=true"
import script from "./WorkflowQuestionCategory.vue?vue&type=script&lang=js"
export * from "./WorkflowQuestionCategory.vue?vue&type=script&lang=js"
import style0 from "./WorkflowQuestionCategory.vue?vue&type=style&index=0&id=63bd8cd2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bd8cd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VChip,VIcon,VListItemAction,VMenu,VTextField})
