<template>
  <BaseDialog
    :value="isOpen"
    width="720px"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <template #modal>
      <BaseCard class="pa-6">
        <BaseTitle>{{ $tc("course.logicjump", 10) }}</BaseTitle>
        <BaseDivider :grey="true" class="my-4" />
        <div v-if="!isFetchingElibledLogicalJumps && questionOptions.length">
          <v-form @submit.prevent="submit()">
            <div v-for="(jump, index) in logicalJumps" :key="jump.optionId">
              <BaseParagraph
                class="mb-2"
                text="secondary"
                v-text="
                  `${$tc('navigation.option', 1)} n°${
                    index + 1
                  } : ${getOptionName(jump.optionId)}`
                "
              />
              <v-select
                v-model="jump.questionId"
                :items="eligibledQuestions"
                item-text="label"
                item-value="id"
              />
            </div>
          </v-form>
          <div class="d-flex justify-end mt-6">
            <BaseButton
              color="gray"
              type="secondary"
              @click="
                () => {
                  $emit('close');
                }
              "
            >
              {{ $t("utils.cancel") }}
            </BaseButton>
            <BaseButton
              color="primary"
              type="primary"
              class="ml-4"
              @click="submit()"
            >
              {{ $t("utils.validate") }}
            </BaseButton>
          </div>
        </div>
        <div v-else-if="isFetchingElibledLogicalJumps">
          <div v-for="n in 2" :key="n" class="mb-2">
            <BaseSkeletonLoader type="chip" class="mb-2" />
            <BaseSkeletonLoader type="image" height="48px" />
          </div>
          <div class="d-flex justify-end mt-6">
            <BaseSkeletonLoader
              v-for="n in 2"
              :key="n"
              type="button"
              class="ml-4"
            />
          </div>
        </div>
        <div v-else>
          {{ $tc("navigation.option", 0) }}
        </div>
      </BaseCard>
    </template>
  </BaseDialog>
</template>

<script>
import {
  findEligibledLogicalJumps,
  findQuestionLogicalJumps,
  findQuestionOptions,
} from "../Services/course.service";

import { mapState } from "vuex";

export default {
  name: "CourseQuestionLogicJumpEditor",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isFetchingElibledLogicalJumps: false,
      eligibledQuestions: [],
      questionOptions: [],
      logicalJumps: [],
    };
  },
  computed: {
    ...mapState({
      step: (state) => state.course.activeStep,
      question: (state) => state.course.activeQuestion,
    }),
    questionType() {
      if (!this.question) return "";
      return this.question?.typeQuestion;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.isFetchingElibledLogicalJumps = true;
      const responses = await Promise.all([
        findEligibledLogicalJumps(
          this.$route.params.organizationId,
          this.$route.params.courseId,
          this.step.id,
          this.question.id
        ),
        findQuestionOptions(
          this.$route.params.organizationId,
          this.$route.params.courseId,
          this.step.id,
          this.question.id,
          1
        ),
        findQuestionLogicalJumps(
          this.$route.params.organizationId,
          this.$route.params.courseId,
          this.step.id,
          this.question.id
        ),
      ]);
      if (responses && responses.length > 0) {
        this.eligibledQuestions = [
          {
            label: this.$t("course.chooseAQuestion"),
            id: "",
          },
          ...responses[0]?.map((question) => ({
            ...question,
            label: question.group
              ? `${question.group?.label} (Question groupée)`
              : question.label,
          })),
        ];
        if (/SELECT|RADIOBUTTON/.test(this.questionType)) {
          this.questionOptions = responses[1];
        } else {
          this.questionOptions = [
            {
              id: 0,
              name: this.$t("course.logicJumpNotChoice"),
              order: 0,
            },
          ];
        }
        responses[2].forEach((jump) => {
          this.logicalJumps.push({
            optionId: /SELECT|RADIOBUTTON/.test(this.questionType)
              ? jump.conditionChoice
              : this.$t("course.logicJumpNotChoice"),
            questionId: isNaN(jump.questionTo) ? "" : jump.questionTo,
            id: jump.id,
            jumpOrder: /SELECT|RADIOBUTTON/.test(this.questionType)
              ? this.questionOptions.find((o) => {
                  return jump.conditionChoice == o.id;
                })?.order
              : 0,
          });
        });
        this.logicalJumps = this.logicalJumps.sort((j1, j2) => {
          return j1.jumpOrder - j2.jumpOrder;
        });

        const notEmptyOptions = responses[2].map(
          (jump) => jump.conditionChoice
        );
        if (/SELECT|RADIOBUTTON/.test(this.questionType)) {
          this.questionOptions
            .filter((option) => !notEmptyOptions.some((id) => id == option.id))
            .forEach((option) => {
              this.logicalJumps.push({
                optionId: option.id,
                questionId: "",
                id: NaN,
                jumpOrder: NaN,
              });
            });
        } else {
          if (this.logicalJumps.length == 0) {
            this.logicalJumps.push({
              optionId: 0,
              questionId: "",
              id: NaN,
              jumpOrder: NaN,
            });
          }
        }
      }

      this.isFetchingElibledLogicalJumps = false;
    },
    getOptionName(optionId) {
      return this.questionOptions
        .filter((option) => option.id == optionId)
        .map((option) => option.name);
    },
    submit() {
      const logicalJumps = this.logicalJumps.map((jump) => {
        return {
          questionId: jump.questionId ? jump.questionId : NaN,
          optionId: /SELECT|RADIOBUTTON/.test(this.questionType)
            ? jump.optionId
            : null,
          id: jump.id,
        };
      });
      this.$store
        .dispatch("course/editLogicalJumps", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.step.id,
          questionId: this.question.id,
          logicalJumps,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("course.editQuestionConfirmation"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.$emit("close");
    },
  },
};
</script>
