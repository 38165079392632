<template>
  <BaseFrontOfficeWalletWrapper
    :mobile-title-image="$findStatic('cosmoWalletAuthImage')"
    :mobile-title-message="$t('wallet.assistant.assistantMessage')"
  >
    <template #title>
      <BaseFrontOfficeTitle v-text="$t('wallet.mySpace')" />
    </template>
    <div class="d-flex flex-column align-center" style="height: 100%">
      <!-- content -->
      <BaseFrontOfficeBoard class="hidden-sm-and-down">
        <div class="d-flex align-center">
          <BaseFrontOfficeImage
            class="mr-6"
            max-width="120"
            max-height="72"
            :src="$findStatic('cosmoWalletCloudData')"
          />
          <span v-text="$t('wallet.assistant.assistantMessage')" />
        </div>
      </BaseFrontOfficeBoard>

      <!-- body-->
      <div class="records_container">
        <div class="d-flex align-center mt-3 mb-9">
          <BaseFrontOfficeTitle
            class="pr-4"
            v-text="$t('wallet.assistant.unfinishedRecords')"
          />
          <BaseFrontOfficeDivider />
        </div>
        <!-- parcours list -->
        <template
          v-if="recordsNotCompleted.length || isFetchingRecordsNotCompleted"
        >
          <BaseFrontOfficeCardItem
            v-for="(record, i) in recordsNotCompleted"
            :key="i"
            class="mb-7"
          >
            <template #start>
              <div>
                <BaseFrontOfficeImage
                  v-if="record.campaign.course.getImage"
                  height="40"
                  :src="record.campaign.course.getImage"
                  width="40"
                  contain
                  class="my-2"
                />
                <BaseFrontOfficeImage
                  v-else
                  :src="$findStatic('defaultAvatar')"
                  width="40"
                  height="40"
                />
              </div>
            </template>
            <template #middle>
              <div class="d-flex flex-column justify-center">
                <BaseFrontOfficeParagraph
                  v-text="record.campaign.course.name"
                />
                <BaseFrontOfficeDescription
                  uppercase
                  text="disabled"
                  v-text="getRecordDate(record)"
                />
              </div>
            </template>
            <template #end>
              <div class="d-flex justify-end align-center">
                <BaseFrontOfficeButton @click="goToLink(record)">
                  {{ $t("wallet.assistant.continue") }}
                </BaseFrontOfficeButton>
              </div>
            </template>
          </BaseFrontOfficeCardItem>
        </template>
        <WalletCourseAllCompleted v-else />
      </div>
    </div>
  </BaseFrontOfficeWalletWrapper>
</template>

<script>
import { mapState } from "vuex";

import WalletCourseAllCompleted from "../Components/Assistant/WalletCourseAllCompleted.vue";

export default {
  name: "WalletAssistant",
  components: { WalletCourseAllCompleted },
  data() {
    return {
      RECORD_LIST_HEADER: [
        {
          text: "",
          sortable: false,
        },
        {
          text: this.$tc("organization.course", 1),
          sortable: false,
        },
        {
          align: "end",
          sortable: false,
        },
      ],
      isFetchingRecordsNotCompleted: false,
    };
  },
  computed: {
    ...mapState({
      recordsNotCompleted: (state) => state.walletCourse.recordsNotCompleted,
      user: (state) => state.account.user,
    }),
  },
  async created() {
    this.reset();
    await this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    goToLink(record) {
      if (
        record.campaign.organizationRequired &&
        !this.user.activeOrganization
      ) {
        return this.$router.push({
          path: `/campaign/${record.campaign.slug}/welcome/?record_pk=${record.id}`,
        });
      }
      return this.$router.push({
        name: "WalletCourse",
        params: {
          recordId: record.id,
        },
      });
    },
    reset() {
      this.$store.commit("walletCourse/setRecordsNotCompleted", []);
    },
    async initialize() {
      this.isFetchingRecordsNotCompleted = true;
      await this.$store.dispatch("walletCourse/fetchRecordsNotCompleted");
      this.isFetchingRecordsNotCompleted = false;
    },
    getRecordDate(record) {
      if (!record.agreements.length) {
        return `${this.$t("wallet.assistant.startedOn")} ${this.$d(
          new Date(record.campaign.dateCreated),
          "veryshort"
        )}`;
      }
      return `${this.$t("wallet.assistant.endedOn")} ${this.$d(
        record.agreements && new Date(record.agreements[0]?.dateUpdated),
        "veryshort"
      )}`;
    },
  },
};
</script>

<style scoped>
.records_container {
  width: 100%;
}
</style>
