<template v-slot="scope">
  <div
    :class="{
      last: isLast,
    }"
    class="step_container"
  >
    <template v-if="loading">
      <button class="step_btn">
        <v-skeleton-loader class="skeleton" type="avatar" />
      </button>
      <v-skeleton-loader
        v-if="!isLast"
        :class="{ past: isPast }"
        class="divider skeleton"
        type="text"
      />
    </template>
    <template v-else>
      <button
        :class="{
          consulted: isConsulted,
          active: active,
          complete: complete,
        }"
        class="step_btn"
        @click="changeConsultedStep()"
      >
        <span v-if="complete" class="step_icon">
          <BaseIcon icon="$mdiCheck" />
        </span>
        <div v-else class="step_icon" v-text="step" />
        <div class="step_label" v-text="label" />
      </button>
      <div v-if="!isLast" :class="{ past: isPast }" class="divider" />
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseStepperStep",
  props: {
    step: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
    },
    complete: {
      type: Boolean,
    },
  },
  computed: {
    isConsulted() {
      return this.$parent?.consultedStep == this.step;
    },
    isLast() {
      return this.$parent?.lastStep == this.step;
    },
    isPast() {
      return this.$parent?.consultedStep > this.step;
    },
    loading() {
      if (this.$parent) {
        return this.$parent.loading;
      }
      return false;
    },
  },
  methods: {
    changeConsultedStep() {
      if (this.$parent) this.$parent.consultedStep = this.step;
    },
  },
};
</script>
<style lang="scss" scoped>
.step_container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  &.last {
    flex-grow: initial;
  }
  .step_btn {
    border-radius: 24px;
    display: flex;
    align-items: center;
    padding: 0;
    color: #fff;
    transition: all 0.3s ease-out;
    border: 4px solid #fff;
    background: #fff;

    .step_icon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: #cfcfdc;
      transition: all 0.3s ease-out;
      flex: none;
      line-height: 40px;
      color: #ffffff;
    }

    .step_label {
      line-height: 18px;
      text-decoration-line: underline;
      color: #2536cc;
      display: inline-block;
      padding: 0;
      width: 0;
      overflow: hidden;
      transition: all 0.3s ease-out;
      display: inline-block;
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.consulted {
      border-color: #2536cc;
      .step_icon {
        background: transparent;
        color: #2536cc;
      }
      .step_label {
        padding: 0 16px;
        width: auto;
        background: transparent;
        text-decoration: underline;
        color: #2536cc;
      }
    }
    &.active {
      border-color: #2536cc;
      background: #2536cc;
      .step_icon {
        background: transparent;
        color: #fff;
      }
      .step_label {
        background: transparent;
        color: #fff;
      }
      &.consulted {
        .step_label {
          border-bottom-color: #fff !important;
        }
      }
    }
    &.complete {
      border-color: #2536cc;
      background: #2536cc;
      .step_icon {
        background: transparent;
        color: #fff;
        &::v-deep {
          .v-icon {
            color: #fff;
          }
        }
      }
      .step_label {
        background: transparent;
        color: #fff;
      }
      &.consulted {
        .step_label {
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }
  .divider {
    flex: 1;
    height: 2px;
    border-radius: 1px;
    margin: 0 16px;
    background: #4f4f4f;
    transition: all 0.3s ease-out;
    &.past {
      background: #2536cc;
    }
    &.skeleton {
      height: 4px;
      width: 100%;
      background: transparent;
      ::v-deep {
        .v-skeleton-loader__bone {
          height: 4px;
        }
      }
    }
  }
}
</style>
