import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Record > Question - API
  getCampaignAccess(slug, recordId = null, scValue) {
    let settings = {};
    if (scValue) {
      settings = {
        params: { sc: scValue },
      };
    }
    if (recordId) {
      settings = {
        params: { record_pk: recordId, ...settings.params },
      };
    }
    return cosmoAPI.get(`/wallet/campaign/${slug}/welcome/`, settings);
  },
  //#endregion
};
