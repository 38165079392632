import User from "./User";
import { TransactionalEmail } from "./TransactionalEmail";

export default class Remind {
  constructor({
    id = Number.NaN,
    collaborator = null,
    dateCreated = new Date(),
    dateUpdated = new Date(),
    transactionalEmail = null,
    email = "",
  } = {}) {
    this.id = id ? Number.parseInt(id) : null;
    this.email = email;
    this.collaborator = collaborator ? new User(collaborator) : null;
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
    this.transactionalEmail = transactionalEmail
      ? new TransactionalEmail(transactionalEmail)
      : null;
  }
}
