import Option from "./Option";
import PersonalData from "./PersonalData";
import Universe from "./Universe";

export default class Datatype {
  constructor({
    id = Number.NaN,
    name = "",
    slug = "",
    label = "",
    typeQuestion = "",
    choices = [],
    personalData = null,
    universes = [],
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.slug = slug;
    this.label = label;
    this.typeQuestion = typeQuestion;
    this.choices = choices ? choices.map((choice) => new Option(choice)) : [];
    if (personalData?.length >= 0) {
      personalData = personalData.length ? personalData[0] : null;
    }
    this.personalData = personalData ? new PersonalData(personalData) : null;
    this.universes = universes
      ? universes.map((universe) => new Universe(universe))
      : [];
  }

  get isPersonalDataValid() {
    return !!(
      this.personalData &&
      this.personalData.content !== null &&
      this.personalData.content !== ""
    );
  }
}
