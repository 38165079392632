<template>
  <v-expansion-panels v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-expansion-panels>
</template>

<script>
export default {
  name: "BaseFrontOfficeExpansionPanels",
};
</script>
