<template>
  <v-divider
    v-bind="$attrs"
    :style="{
      borderColor: grey ? '' : $vuetify.theme.themes[theme][color].base,
    }"
  />
</template>

<script>
export default {
  name: "BaseDivider",
  props: {
    color: {
      type: String,
      default: () => "primary",
    },
    grey: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
