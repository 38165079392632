var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"preview_cosmoform",on:{"click":function () {
      _vm.$store.commit('cosmoform/SET_Fill_COSMOFORM_DIALOG_STATE', true);
      _vm.$store.commit('cosmoform/SET_SELECTED_COSMOFORM', _vm.cosmoform);
    }}},[_c('div',{staticClass:"question_name"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.cosmoform.question.label)+" "),_c('span',{staticClass:"required"},[_vm._v(_vm._s(_vm.cosmoform.question.required ? "*" : ""))])]),(_vm.cosmoform.question.readOnly)?_c('BaseIcon',{staticClass:"icon",attrs:{"small":"","icon":"$mdiPencilOffOutline"}}):_vm._e()],1),(_vm.content)?_c('div',{staticClass:"content"},[(
        /^FILE|MULTIPLE_FILE|SIGNATURE$/.test(_vm.cosmoform.question.typeQuestion)
      )?[_vm._v(" "+_vm._s(_vm.$t("utils.file"))+" ")]:(
        /^DATAGRID|ROW_DATAGRID$/.test(_vm.cosmoform.question.typeQuestion)
      )?[_vm._v(" "+_vm._s(_vm.$t("utils.table"))+" ")]:(/^DATE$/.test(_vm.cosmoform.question.typeQuestion))?[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.content), "short"))+" ")]:[_vm._v(" "+_vm._s(_vm.content)+" ")]],2):_c('div',{staticClass:"no_content",domProps:{"textContent":_vm._s(_vm.$t('utils.noContent'))}})])}
var staticRenderFns = []

export { render, staticRenderFns }