<template>
  <div>
    <div v-if="client" class="cd_header">
      <div class="header_item">
        <div class="d-flex align-items-center">
          <div class="header_title">
            {{ $tc("utils.contact", 1) }}
          </div>

          <base-icon
            v-if="client.inOrganizationSignatories"
            icon="$mdiDomain"
            small
            color="primary"
            class="ml-2"
          />
          <div
            v-else
            class="icon__container ml-2"
            @click="
              {
                if (!client.inOrganizationSignatories) {
                  dialogState = true;
                }
              }
            "
          >
            <div class="tool_tip_container">
              <div class="msg_container">
                <span
                  class="text__tooltip"
                  v-text="$t('utils.addSignatoryTip')"
                />
                <v-icon class="arrow_down">$arrowDown</v-icon>
              </div>
            </div>
            <base-icon icon="$mdiPlus" small color="white" />
          </div>
        </div>

        <div class="sub_title" v-text="client.email" />
        <div v-if="client.fullName" class="item_content">
          <div class="item_key" v-text="client.fullName" />
        </div>
        <div class="item_content">
          <div class="item_key" v-text="`${$t('utils.phone')} :`" />
          <div class="item_value" v-text="client.phone" />
        </div>
        <div class="item_content">
          <div class="item_key" v-text="`${$t('utils.registration')} :`" />
          <div class="item_value" v-text="client.matricule" />
        </div>
        <div class="avatar_container">
          <img v-if="client.identityPhoto" :src="client.identityPhotoUrl" />
          <span v-else class="first_char" v-text="client.firstChar" />
        </div>
        <div class="header_action">
          <BaseButton icon="$mdiPlus" @click="$emit('createFolder')">
            {{ $tc("organization.createFolder", 1) }}
          </BaseButton>
        </div>
      </div>
      <div class="header_item">
        <div class="header_title" v-text="$t('utils.generalInformation')" />
        <div class="item_content">
          <div class="item_key" v-text="`${$t('utils.startOfContract')} :`" />
          <div
            class="item_value"
            v-text="
              client.contractStartDate
                ? $d(new Date(client.contractStartDate), 'veryshort')
                : ''
            "
          />
        </div>
        <div class="item_content">
          <div class="item_key" v-text="`${$t('utils.lastAction')} :`" />
          <div class="item_value" v-text="lastAction" />
        </div>
      </div>
    </div>
    <OrganizationSignatoryCreateAndEditDialog
      :dialog-state="dialogState"
      :signatory="clientSignatory"
      :client="client"
      from-client
      :organization-id="organizationId"
      @close="dialogState = false"
    />
  </div>
</template>

<script>
import { SIGNATORY_TYPE } from "@/core/Models/Signatory";
import OrganizationSignatoryCreateAndEditDialog from "@/modules/Admin/Components/OrganizationSignatoryCreateAndEditDialog.vue";

export default {
  name: "ClientDetailHeader",
  components: { OrganizationSignatoryCreateAndEditDialog },
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialogState: false,
    };
  },
  computed: {
    organizationId() {
      return this.$route.params.organizationId;
    },
    lastAction() {
      if (!this.client?.lastAction) return "";
      switch (this.client.lastAction.log.action) {
        case "PERSONALDATA_MODIFIED":
          return this.$t("client.personalDataModified");
        case "NEW_RECORD_CLIENT":
          return this.$t("client.newClientRecord");
        case "ANSWER_MODIFIED":
          return this.$t("client.newClientRecord");
        case "STATUS_AGREEMENT":
          return this.$t("client.statusAgreement");
        case "RELAUNCH_CLIENT":
          return this.$t("client.relaunchClient");
      }
      return "";
    },
    clientSignatory() {
      if (!this.client) return {};
      const { email, firstname, lastname, phone } = this.client;
      return {
        email,
        firstName: firstname,
        lastName: lastname,
        phoneNumber: phone,
        type: SIGNATORY_TYPE.ORGANIZATION_SIGNATORY,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cd_header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  .header_item {
    border-radius: 5px;
    flex: 1;
    position: relative;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #ecebf1;
    position: relative;
    width: 100%;
    word-break: keep-all;
    word-wrap: break-word;
    .icon__container {
      background: #2536cc;
      position: relative;
      border-radius: 13px;
      height: 14px;
      width: 14px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        .tool_tip_container {
          transition: 125ms ease-in-out;
          display: flex;
        }
      }
      .tool_tip_container {
        display: none;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, -50px);
        width: 215px;
        height: 39px;

        padding: 12px 6px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 10px;
        background: #666;

        .msg_container {
          position: relative;
          display: flex;
          align-items: center;
          .arrow_down {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 100%);
          }
        }
        .text__tooltip {
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .header_title {
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
      text-transform: uppercase;
      color: #707080;
    }
    .sub_title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #353542;
      margin-bottom: 14px;
    }
    .item_content {
      display: flex;
      margin-top: 6px;
      .item_key {
        margin-right: 6px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #707080;
      }
      .item_value {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #707080;
      }
    }
    .avatar_container {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #f7fafb;
      border: 2px solid #eeeef7;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      overflow: hidden;
      .first_char {
        font-weight: bold;
        font-weight: 600;
        font-size: 38px;
        font-family: "Inter";
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .header_action {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
