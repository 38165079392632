<template>
  <div v-if="isReady && activeOrganization" class="records_container">
    <div class="record_header">
      <div class="header_top_section">
        <div class="logo_container">
          <img :src="logo" class="logo" />
        </div>
        <div v-if="contact" class="contact">
          <BaseMarkdownViewer :content="contact" />
        </div>
      </div>
      <div v-if="description" class="header_bottom_section">
        <BaseMarkdownViewer :content="description" />
      </div>
    </div>

    <div class="records_section">
      <div class="records_section_title">
        <BaseFrontOfficeTitle v-text="$t('wallet.folderTitle')" />
      </div>
      <div class="records_section_items">
        <WalletOrganizationFolders />
      </div>
    </div>

    <OrganizationServices
      v-if="organizationCampaignsServices.length"
      :services="organizationCampaignsServices"
    />

    <!-- MODAL -->
    <WalletFolderDialog />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganizationServices from "../Components/OrganizationServices.vue";
import WalletFolderDialog from "../Components/WalletFolderDialog.vue";
import WalletOrganizationFolders from "../Components/WalletOrganizationFolders.vue";

export default {
  name: "WalletOrganizationRecords",
  components: {
    WalletOrganizationFolders,
    WalletFolderDialog,
    OrganizationServices,
  },
  props: {
    organizationId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
      organizationCampaignsServices: (state) =>
        state.course.organizationCampaignsServices,
    }),
    logo() {
      return (
        (this.activeOrganization && this.activeOrganization?.logo) ||
        this.$findStatic("cosmoWalletCloudData")
      );
    },
    description() {
      return this.activeOrganization
        ? this.activeOrganization?.description
        : null;
    },
    contact() {
      return this.activeOrganization ? this.activeOrganization?.contact : null;
    },
  },
  watch: {
    organizationId: {
      handler(newVal) {
        if (newVal) this.setData(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async setData(organizationId) {
      this.isReady = false;
      try {
        await Promise.all([
          this.$store.dispatch(
            "organization/fetchWalletOrganization",
            organizationId
          ),
          this.$store.dispatch("walletCourse/getWalletFolder", {
            organizationId,
          }),
          this.$store.dispatch("course/getOrganizationCampaignsServices", {
            organizationId,
          }),
        ]);
      } catch {
        this.$router.push({
          name: "WalletLogin",
          query: {
            next: this.$route.fullPath,
          },
        });
      }
      this.isReady = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.records_container {
  width: 100%;
  padding: 16px 24px;
  & > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    padding: 16px;
  }
  .record_header {
    padding: 24px;
    background: rgba(46, 45, 193, 0.06);
    border-radius: 15px;
    .header_top_section {
      display: flex;
      .logo_container {
        height: 60px;
        flex: none;
        .logo {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
      .contact {
        margin-left: auto;
        font-family: "Nunito";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: #4f4f4f;
      }
    }
    .header_bottom_section {
      margin-top: 16px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #4f4f4f;
    }
  }
  .records_section {
    background: #fff;
    border-radius: 20px;
    .records_section_title {
      padding: 16px;
    }
  }
}
</style>
