import { render, staticRenderFns } from "./RecordQuestionForm.vue?vue&type=template&id=68ec4316&scoped=true"
import script from "./RecordQuestionForm.vue?vue&type=script&lang=js"
export * from "./RecordQuestionForm.vue?vue&type=script&lang=js"
import style0 from "./RecordQuestionForm.vue?vue&type=style&index=0&id=68ec4316&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ec4316",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VAutocomplete,VCheckbox,VDatePicker,VFileInput,VRadio,VRadioGroup,VTextField,VTextarea,VTimePicker})
