function testEmail(email) {
  if (!email) return true;
  const regexEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+[a-zA-Z0-9-]/;
  return regexEmail.test(email);
}
function testPhoneNumber(phone) {
  if (!phone) return true;
  return /^\d{10}$/.test(phone);
}
function testSecurityNumber(value) {
  if (!value) return true;
  return /^\d{13}$/.test(value);
}
function testNumberPositive(number) {
  if (!number && number != 0) return true;
  const sign = Math.sign(number);
  return sign === 1;
}
function testNumberNegative(number) {
  if (!number && number != 0) return true;
  const sign = Math.sign(number);
  return sign === -1;
}
function testNumberNegativeOrZero(number) {
  if (!number && number != 0) return true;
  const sign = Math.sign(number);
  return sign === -1 || sign === 0;
}
function testNumberPositiveOrZero(number) {
  if (!number && number != 0) return true;
  const sign = Math.sign(number);
  return sign === 1 || sign === 0;
}
function testByRegex(regex, value) {
  if (!value) return true;
  const reg = new RegExp(regex);
  return reg.test(value);
}
function testEntryByConditions(conditions, value) {
  switch (conditions) {
    case ">0":
      return testNumberPositive(parseInt(value));
    case ">=0":
      return testNumberPositiveOrZero(parseInt(value));
    case "<0":
      return testNumberNegative(parseInt(value));
    case "<=0":
      return testNumberNegativeOrZero(parseInt(value));
    default:
      return testByRegex(conditions, value);
  }
}
export {
  testEmail,
  testPhoneNumber,
  testSecurityNumber,
  testByRegex,
  testNumberPositive,
  testNumberNegative,
  testEntryByConditions
};
