<template>
  <div>
    <BaseDialog
      :value="dialogState"
      :modal-title="modalTitle"
      persistent
      max-width="1200"
      @keydown.esc="reset()"
    >
      <template #modal>
        <BaseCosmoCard>
          <template #title>
            <div class="modal_header">
              <BaseTitle class="modal_title" v-text="modalTitle" />
              <div v-if="visibleClient" class="visible_icon">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <BaseIcon icon="$mdiEye" color="primary" v-on="on" />
                  </template>
                  <span v-text="$t('utils.visibleByClient')" />
                </v-tooltip>
              </div>
              <div class="answer_validation">
                <WorkflowAnswerValidation
                  v-if="questionToValided"
                  :question="questionToValided"
                  :status-id="activeStatus.id"
                  @updateSubmiting="isSubmittingValidation = $event"
                />
              </div>
            </div>
          </template>
          <form
            class="modal_content"
            :class="{
              full_height: displayFilePreviewer,
            }"
            @submit.prevent="onSave"
          >
            <div v-if="isSendingToGed" class="progression">
              <v-progress-linear indeterminate />
            </div>
            <template v-if="displayFilePreviewer">
              <QuestionFileEditor
                v-model="form[`question${activeQuestions[0].id}`]"
                :question="activeQuestions[0]"
                :display-ged="hasEnoxa"
                @sendToGed="sendToGed(activeQuestions[0])"
              />
            </template>
            <template v-else>
              <div
                v-for="(category, index) in questionsCategorised"
                :key="`category${index}`"
              >
                <div class="input-fields">
                  <div
                    v-for="(question, i) in category.questions"
                    :key="`wqf${i}${question.id}`"
                    :class="{
                      inputs: !question.typeQuestion.includes(
                        'DATE',
                        'DATETIME'
                      ),
                    }"
                  >
                    <template
                      v-if="
                        question.answer &&
                        question.answer.extractedData &&
                        isSiretSiren(question)
                      "
                    >
                      <WorkflowQuestionField
                        v-model="form[`question${question.id}`]"
                        :focus="activeQuestions.length === 1"
                        :question="question"
                        is-workflow-question
                        :need-label="questionsCategorised.length > 1"
                        @set-initial-file="(index) => (initialFile = index)"
                        @formValidityChange="
                          ($event) => {
                            onFormValidityChange(question.id, $event);
                          }
                        "
                      />
                      <WorkflowExtractedDataFields
                        v-model="form[`question${question.id}`]"
                        :extracted-data="question.answer.extractedData"
                        :question="question"
                        @formValidityChange="
                          ($event) => {
                            onFormValidityChange(question.id, $event);
                          }
                        "
                      />
                    </template>
                    <template
                      v-else-if="
                        question.answer &&
                        question.answer.extractedData &&
                        !editMultifile
                      "
                    >
                      <WorkflowWezideeQuestionField
                        v-model="form[`question${question.id}`]"
                        :question="question"
                        @triger-edit-multifile="editMultifile = true"
                        @formValidityChange="
                          ($event) => {
                            onFormValidityChange(question.id, $event);
                          }
                        "
                        @close="reset"
                      />
                    </template>

                    <template v-else>
                      <WorkflowQuestionField
                        v-model="form[`question${question.id}`]"
                        :focus="activeQuestions.length === 1"
                        :question="question"
                        is-workflow-question
                        :need-label="questionsCategorised.length > 1"
                        @set-initial-file="(index) => (initialFile = index)"
                        @formValidityChange="
                          ($event) => {
                            onFormValidityChange(question.id, $event);
                          }
                        "
                      />
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </form>
          <div class="modal_footer">
            <BaseButton
              text
              class="mx-2 ml-auto"
              type="secondary"
              color="#707080"
              :disabled="isSubmittingValidation || isSendingToGed"
              @click.prevent="reset"
            >
              {{ $t("utils.cancel") }}
            </BaseButton>
            <BaseButton
              type="primary"
              :loading="isSubmitting"
              :disabled="
                isArchivedFolder ||
                isSubmittingValidation ||
                !!invalidFormIds.length
              "
              @click.prevent="onSave"
            >
              {{ $t("utils.validate") }}
            </BaseButton>
          </div>
        </BaseCosmoCard>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import WorkflowQuestionField from "./WorkflowQuestionField.vue";
import WorkflowExtractedDataFields from "./WorkflowExtractedDataFields";
import WorkflowWezideeQuestionField from "./WorkflowWezideeQuestionField.vue";
import WorkflowAnswerValidation from "./WorkflowAnswerValidation.vue";
import QuestionFileEditor from "./QuestionFileEditor.vue";

import { mapState, mapGetters } from "vuex";
export default {
  name: "FolderWorkflowAnwserQuestionsDialog",
  components: {
    WorkflowQuestionField,
    WorkflowWezideeQuestionField,
    WorkflowExtractedDataFields,
    WorkflowAnswerValidation,
    QuestionFileEditor,
  },
  data() {
    return {
      isSubmitting: false,
      form: {},
      invalidFormIds: [],
      initialFile: 0,
      isSubmittingValidation: false,
      editMultifile: false,
      isSendingToGed: false,
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isAnswerQuestionsOpen,
      activeQuestions: (state) => state.folder.activeQuestions,
      activeStatus: (state) => state.workflow.activeStatus,
      workflowId: (state) => state.folder.activeFolder.workflow.id,
      activeFolder: (state) => state.folder.activeFolder,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    modalTitle() {
      if (!this.activeQuestions?.length) return "";
      if (this.activeQuestions?.length > 1)
        return this.$tc("utils.resignSelectedFields", 2);
      return `${this.activeQuestions[0]?.name} ${
        this.activeQuestions[0]?.required ? "*" : ""
      }`;
    },
    questionsCategorised() {
      if (!this.activeQuestions) return [];
      const allCategory = this.activeQuestions.map(
        (question) => question.category?.name
      );
      const category = [...new Set(allCategory)];
      const questionCategorised = category.map((category) => ({
        name: category ? category : "",
        questions: this.activeQuestions.filter(
          (question) => question.category?.name == category
        ),
      }));
      return questionCategorised;
    },
    questionToValided() {
      if (this.activeQuestions.length != 1) return;
      return this.activeQuestions[0].answer ? this.activeQuestions[0] : null;
    },
    visibleClient() {
      if (!this.activeQuestions?.length) return false;
      return this.activeQuestions.some((q) => q.visibleClient);
    },
    displayFilePreviewer() {
      if (this.activeQuestions?.length != 1) return false;
      if (!this.activeQuestions[0].answer?.content?.length) return false;
      if (
        ["MULTIPLE_FILE", "FILE"].includes(this.activeQuestions[0].typeQuestion)
      )
        return true;
      return false;
    },
    hasEnoxa() {
      return !!this.activeOrganization?.enoxa?.serverName;
    },
  },

  watch: {
    activeQuestions(questions) {
      this.form = {};
      if (questions) {
        questions.forEach((question) => {
          this.form[`question${question.id}`] = {
            content: null,
            question,
          };
        });
      }
    },
  },
  methods: {
    setValidationError(state) {
      this.hasValidationError = state;
    },
    isSiretSiren(question) {
      return ["SIREN", "SIRET"].includes(question.datatype);
    },
    reset() {
      this.$store.commit("folder/SET_MODAL_ANWER_QUESTION_STATUS", false);
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", []);
      this.invalidFormIds = [];
    },
    async onSave() {
      if (this.isArchivedFolder) return;
      this.isSubmitting = true;
      try {
        const allPromise = [];
        for (const key in this.form) {
          if (this.form[key].content) {
            if (
              !this.form[key].question.answer ||
              isNaN(this.form[key].question?.answer?.id)
            ) {
              allPromise.push(this.createAnswer(this.form[key]));
            } else {
              allPromise.push(this.editAnswer(this.form[key]));
            }
          }
        }
        await Promise.all(allPromise);
        await this.updateFolderStatusActions();
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.editSuccess"),
          type: "SUCCESS",
        });
      } catch (e) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.editError"),
          type: "ERROR",
        });
        throw e;
      }
      this.isSubmitting = false;
      this.reset();
    },
    async createAnswer(payload) {
      if (!payload.content) {
        return;
      }
      await this.$store.dispatch("workflow/postQuestionAnswer", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        statusId: this.activeStatus.id,
        questionId: payload.question?.id,
        payload: this.buildContent(payload),
      });
    },
    buildContent(payload) {
      const withChoices = ["RADIOBUTTON", "SELECT", "CHECKBOX"];
      if (withChoices.includes(payload.question.typeQuestion)) {
        return { set_content: payload.content.choices };
      }
      if (this.displayFilePreviewer) {
        return {
          set_content: payload.content.map((file) => ({
            name: file.name,
            encoded_file: file.url,
          })),
        };
      }
      if (["FILE", "SIGNATURE"].includes(payload.question.typeQuestion)) {
        const content = payload.content.content.length
          ? [
              {
                name: payload.content?.fileName || "Fichier.png",
                encoded_file: payload.content.content,
              },
            ]
          : [];
        return {
          set_content: content,
        };
      }
      if (payload.question.typeQuestion === "MULTIPLE_FILE") {
        const fileToUpload = payload.content.content.splice(
          this.initialFile.length,
          payload.content.content.length
        );
        return {
          set_content: fileToUpload.map((file) => ({
            name: file.name,
            encoded_file: file.content,
          })),
        };
      }
      return { set_content: [payload.content.content] };
    },
    async editAnswer(payload) {
      await this.$store.dispatch("workflow/patchQuestionAnswer", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        statusId: this.activeStatus.id,
        questionId: payload.question?.id,
        payload: this.buildContent(payload),
        answerId: payload.question?.answer?.id,
      });
    },
    async deleteAnswer(payload) {
      await this.$store.dispatch("workflow/deleteQuestionAnswer", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        statusId: this.activeStatus.id,
        questionId: payload.question?.id,
        answerId: payload.question?.answer?.id,
      });
    },
    onFormValidityChange(id, invalid) {
      if (invalid) this.invalidFormIds.push(id);
      else
        this.invalidFormIds = this.invalidFormIds.filter(
          (tmpId) => tmpId != id
        );
    },
    updateFolderStatusActions() {
      if (
        !this.activeStatus.actions.some(
          (action) => action.typeAction == "FOLDER_EMAIL"
        )
      )
        return;
      this.$store.dispatch("workflow/fetchFolderStatusActions", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
        statusId: this.activeStatus.id,
      });
    },
    async sendToGed(question) {
      this.isSendingToGed = true;
      try {
        await this.$store.dispatch(
          "folder/postOrganizationFolderStatusQuestionAnswerGed",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            workflowStatusId: this.activeStatus.id,
            questionId: question.id,
            answerId: question.answer.id,
          }
        );
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.workflow.action.folderSentSuccessfully"),
          type: "SUCCESS",
        });
      } catch (error) {
        const { code } = error.response.data;
        let msg = "Oups, une erreur est survenue";
        switch (code) {
          case "organization-does-not-have-enoxa":
            msg = "Votre organisation n'est pas client de Enoxa";
            break;
          case "missing-matricule-for-enoxa":
            msg = "Il n'y a pas de matricule dans le dossier";
            break;
          case "enoxa-login-error":
            msg =
              "Il y a eu une erreur lors du login enoxa pour obtenir un token";
        }
        return this.$store.dispatch("snackbar/active", {
          message: msg,
          type: "ERROR",
        });
      }
      this.isSendingToGed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  gap: 16px;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
    margin-right: auto;
  }
}
.modal_content {
  margin: 24px 0;
  max-height: calc(83vh - 130px);
  overflow-y: auto;
  position: relative;
  .progression {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &.full_height {
    height: calc(83vh - 130px);
  }
  .item_section {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    .label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 1em;
      color: #353542;
    }
    ::v-deep {
      .v-input__control .v-input__slot {
        & > fieldset {
          border: 1px solid #b6bdff;
        }
      }
    }
    .desc {
      padding: 16px;
      background: #f9f9f9;
      border-radius: 5px;
      line-height: 17px;
      color: #707080;
    }
    .files {
      padding: 16px;
      background: #f9f9f9;
      border-radius: 5px;
      line-height: 20px;
      color: #353542;
      text-decoration: underline;
    }
    .item_footer {
      margin-top: 10px;
      padding: 5px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: #707080;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  & > * {
    &:first-child {
      margin: 0;
      margin-right: auto;
    }
    margin-left: 16px;
  }
}
.history {
  padding: 0 16px;
  &::v-deep {
    .v-expansion-panel {
      border-radius: 10px !important;
      border: 3px solid #b6bdff;
      &::before {
        box-shadow: none;
      }
    }
  }
  .history_item {
    font-size: 14px;
    line-height: 20px;
    color: #707080;
    margin-bottom: 0.9em;
  }
}
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
  text-transform: capitalize;
}
.input-fields {
  padding: 24px 24px 0 24px;
  .inputs {
    margin-bottom: 35px;
  }
}
</style>
