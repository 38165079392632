<template>
  <div>
    <div v-if="!isProfile">
      <BaseFrontOfficeTitle v-text="$t('auth.reset.title')" />
      <BaseFrontOfficeDivider type="grey" class="my-4" />
      <router-view />
      <div class="d-flex justify-end mb-4">
        <router-link
          v-if="$route.fullPath.indexOf('/wallet') != -1"
          :to="{
            name: 'WalletLogin',
            query: {
              next: $route.query.next,
            },
            params: {
              accessCampaign: $route.params.accessCampaign,
            },
          }"
          class="link_signup"
          v-text="$t('auth.reset.goBack')"
        />
        <router-link
          v-else
          :to="{ name: 'Login' }"
          class="link_signup"
          v-text="$t('auth.reset.goBack')"
        />
      </div>
      <WalletHasToRegisterVue />
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import WalletHasToRegisterVue from "../../Components/WalletHasToRegister.vue";

export default {
  name: "WalletAuthPassword",
  components: { WalletHasToRegisterVue },
  computed: {
    isProfile() {
      return this.$route.fullPath.indexOf("/password/change") != -1;
    },
  },
};
</script>

<style scoped>
.link_signup {
  text-decoration: none;
}
.link_signup:hover {
  transition: all 0.75s ease;
  color: var(--v-walletAccent-base);
  text-decoration: underline;
}
</style>
