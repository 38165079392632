import RecordAPI from "./record.api";
import Question from "@/core/Models/Question";
import Record from "@/core/Models/Record";

export async function fecthRecord(recordId) {
  const record = (await RecordAPI.getWalletRecord(recordId)).data;
  return record ? new Record(record) : null;
}

export async function fecthRecordQuestion(recordId) {
  let page = 1;
  let results = [];
  while (page) {
    const res = await RecordAPI.getRecordQuestion({
      recordId,
      page: 1,
    });
    results = [...results, ...res.data.results];
    page = res.data.next;
  }
  return results.map((r) => new Question(r));
}
