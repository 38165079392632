export const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    date: {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    },
    full: {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    detailed: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    veryshort: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      day: "2-digit",
      month: "short",
      year: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    date: {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    },
    full: {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    detailed: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    veryshort: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  },
};
