<template>
  <BaseSheet class="pa-6" :elevation="elevation" :color="color">
    <slot name="header" />
    <div :class="{ 'mt-6': $slots.header, 'mb-6': $slots.footer }">
      <slot name="main" />
      <slot />
    </div>
    <slot name="footer" />
  </BaseSheet>
</template>

<script>
export default {
  name: "Board",
  props: {
    elevation: {
      type: String,
      default: "0",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
