<template>
  <v-app class="c-app" v-bind="$attrs" v-on="$listeners">
    <div v-if="isAppReady">
      <slot />
    </div>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width: 100vw; height: 100vh"
    >
      <div>
        <BaseCard :outlined="true" :loading="true">
          <div class="pa-4">
            <BaseTitle v-text="$t('messages.help.loading')" />
          </div>
        </BaseCard>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TheBaseApp",
  computed: {
    ...mapState({
      csrfToken: (state) => state.core.csrfToken,
    }),
    ...mapGetters({
      isAppReady: "core/isAppReady",
    }),
  },
  methods: {
    findCookie(name) {
      return document.cookie
        .split("; ")
        .find((row) => row.startsWith(name))
        .split("=")[1];
    },
  },
};
</script>

<style scoped>
.c-app {
  background: var(--v-primary-lighten5) !important;
}
</style>
<style>
:root {
  --typography--grey--color: #707080; /* gris text */
  --typography--primary--color: #353542; /* texte black */
}
</style>
