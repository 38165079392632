<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="activators" />
      </div>
    </template>
    <v-list>
      <v-list-item class="item-list justify-center">
        <div class="title-container">
          <BaseCaption
            text="secondary"
            normal-size
            v-text="
              docxTemplates.length
                ? $t('utils.chooseTemplate')
                : $t('utils.noTemplate')
            "
          />
          <div v-if="docxTemplates.length" class="underline-div" />
        </div>
      </v-list-item>
      <v-list-item
        v-for="(template, index) in docxTemplates"
        :key="index"
        class="item-list"
      >
        <div class="content" @click.stop="generateDocument(template)">
          <BaseParagraph type="primary" v-text="template.name" />
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import Oauth from "../../../core/Models/Oauth";
export default {
  name: "FolderGenerateDocxMenu",
  props: {
    status: {
      type: Object,
      required: true,
    },
    activeFolder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      docxTemplates: (state) => state.organization.organizationDocxTemplates,
    }),
  },

  methods: {
    openConfirmationModal() {
      this.$store.commit("folder/setModalGenerateDocxOpen", true);
    },
    async downloadDirect(data) {
      const oauth = Oauth.localStorage;
      const myHeaders = new Headers({
        Authorization: `Bearer ${oauth.accessToken}`,
      });
      const payload = new FormData();
      payload.append("template", data.id);
      const myInit = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };

      const response = await fetch(
        `/api/web/organization/${this.$route.params.organizationId}/folder/${this.$route.params.folderId}/generate-document/`,
        myInit
      );
      saveAs(await response.blob(), `${data.name}`);
    },
    async updateStatusQuestion() {
      await this.$store.dispatch("workflow/fetchFolderQuestions", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.activeFolder.workflow,
        folderId: this.$route.params.folderId,
        statusId: this.status.id,
      });
    },
    async generateDocument(data) {
      this.$store.commit("snackbar/SET_OVERLAY", true);
      this.$store.dispatch("snackbar/active", {
        message: this.$t("folder.generatingDocument"),
        type: "LOADING",
      });
      const payload = {
        template: data.id,
      };
      try {
        const response = await this.$store.dispatch(
          "organization/generateDocx",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            payload,
          }
        );
        if (response.status === 206) {
          this.$store.commit("folder/setMissingValuesInDocx", {
            ...response.data.details,
            template: data,
          });
          return this.openConfirmationModal();
        }
        // update question status
        await this.downloadDirect(data);
        let msg = this.$tc("folder.documentGenerated",1);
        if (data.destination && data.destination != "") {
          await this.updateStatusQuestion();
          msg = this.$tc("folder.documentGenerated", 2, {
            stdData: data.destination,
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: msg,
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.errorDocumentGenerated"),
          type: "ERROR",
        });
      }
      this.$store.commit("snackbar/SET_OVERLAY", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  display: flex;
  align-items: center;
  height: 33px;
  width: 220px;
}
.title-container {
  position: relative;
}
.underline-div {
  position: absolute;
  left: 30%;
  width: 60px;
  height: 0px;
  border: 0.5px solid #707080;
}
.content {
  width: 100%;
  padding: 2%;
  &:hover {
    background: rgba(155, 189, 255, 0.2);
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
