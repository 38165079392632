var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseDataTable',{attrs:{"items":_vm.status.targetStatuses,"headers":_vm.TARGET_STATUSES_HEADERS,"itemps-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('BaseParagraph',{attrs:{"type":"primary"},domProps:{"textContent":_vm._s(item.statusTo.name)}})],1),_c('td',[_c('BaseParagraph',{attrs:{"type":"primary"},domProps:{"textContent":_vm._s(item.label)}})],1),_c('td',[_c('div',{staticClass:"d-flex justify-end"},[_c('BaseButtonIcon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary","icon":"$mdiPencilOutline"},on:{"click":function () {
                  _vm.$store.commit('workflow/setActiveStatus', _vm.status);
                  _vm.$store.commit('workflow/setActiveTargetStatus', item);
                  _vm.$store.commit(
                    'workflow/setStatusTargetStatusCreateAndEditDialog',
                    true
                  );
                }}}),_c('BaseButtonIcon',{attrs:{"color":"secondary","x-small":"","icon":"$mdiTrashCanOutline"},on:{"click":function () {
                  _vm.$store.commit('workflow/setActiveStatus', _vm.status);
                  _vm.$store.commit('workflow/setActiveTargetStatus', item);
                  _vm.$store.commit(
                    'workflow/setDeleteStatusTargetStatusDialog',
                    true
                  );
                }}})],1)])])]}}])}),_c('BaseDivider',{staticClass:"my-2",attrs:{"grey":""}}),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('BaseButton',{staticClass:"mb-2",attrs:{"color":"primary","icon":'$mdiClipboardEditOutline',"small":""},on:{"click":function () {
          _vm.$store.commit('workflow/setActiveStatus', _vm.status);
          _vm.$store.commit(
            'workflow/setStatusTargetStatusCreateAndEditDialog',
            true
          );
        }}},[_vm._v(" "+_vm._s(_vm.$t("workflow.status.addStatusCible"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }