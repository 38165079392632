var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"px-6 py-8 navigation-drawer",attrs:{"app":true,"height":"100vh","permanent":"","floating":""}},[_c('v-overlay',{attrs:{"contained":""},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}}),_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('BaseImage',{staticClass:"mx-10 mt-6 mb-7",attrs:{"src":_vm.$findStatic('cosmoLogo')}})],1),_c('section',{staticClass:"my-2"},[(_vm.isLoadingOrganizations)?_c('div',[_c('BaseSkeletonLoader',{staticClass:"mb-4",attrs:{"type":"text"}}),_c('BaseSkeletonLoader',{staticClass:"mb-4",attrs:{"type":"chip"}}),_c('BaseSkeletonLoader',{attrs:{"type":"chip"}})],1):(_vm.organizations.length)?_c('div',[_c('BaseDivider',{attrs:{"color":"primary"}}),(_vm.organizations.length > 1)?_c('span',{staticClass:"text-caption font-weight-regular text-uppercase primary--text mt-2",domProps:{"textContent":_vm._s(_vm.$tc('organization.organization', _vm.organizations.length))}}):_vm._e(),_c('BaseList',_vm._l((_vm.organizations),function(organization,index){return _c('BaseListGroup',{key:index,staticClass:"drawer",class:{
            'custom__list-group--active':
              _vm.$route.params.organizationId == organization.id,
          },attrs:{"color":"primary","value":_vm.$route.params.organizationId == organization.id,"opened":_vm.organizations.length === 1},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('BaseListItemContent',[_c('BaseListItemTitle',[_c('div',{staticClass:"primary--text sub_title",domProps:{"textContent":_vm._s(organization.name)}})])],1)]},proxy:true}],null,true)},[_c('BaseListItem',{attrs:{"to":{
              name: 'organizationDashboard',
              params: { organizationId: organization.id },
            }}},[_c('BaseListItemContent',[_c('BaseListItemTitle',[_c('div',{staticClass:"primary--text text_sm ml-6",domProps:{"textContent":_vm._s(_vm.$tc('organization.dashboard', 2))}})])],1)],1),_c('BaseListItem',{attrs:{"to":{
              name: 'courseList',
              params: { organizationId: organization.id },
            }}},[_c('BaseListItemContent',[_c('BaseListItemTitle',[_c('div',{staticClass:"primary--text text_sm ml-6",domProps:{"textContent":_vm._s(_vm.$tc('organization.course', 10))}})])],1)],1),_c('BaseListItem',{attrs:{"to":{
              name: 'clientList',
              params: { organizationId: organization.id },
            }}},[_c('BaseListItemContent',[_c('BaseListItemTitle',[_c('div',{staticClass:"primary--text text_sm ml-6",domProps:{"textContent":_vm._s(_vm.$tc('organization.client', 2))}})])],1)],1),_c('BaseListItem',{attrs:{"to":{
              name: 'folderList',
              params: { organizationId: organization.id },
            }}},[_c('BaseListItemContent',[_c('BaseListItemTitle',[_c('div',{staticClass:"primary--text text_sm ml-6",domProps:{"textContent":_vm._s(_vm.$tc('organization.folder', 2))}})])],1)],1)],1)}),1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }