import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Collaborators > Workflow - Api
  getCollaboratorsOrganization(organizationId) {
    return cosmoAPI.get(`/organization/${organizationId}/collaborator/`);
  },
  //#endregion
  //#region Organization > Workflow - Api
  getOrganizationCollaborators({ organizationId, page = 1 }) {
    return cosmoAPI.get(`/organization/${organizationId}/collaborator/`, {
      params: {
        page,
      },
    });
  },
  getOrganizationWorkflow(organizationId, workflowId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/`
    );
  },
  getOrganizationWorkflows(organizationId, page = 1) {
    return cosmoAPI.get(`/organization/${organizationId}/workflow/`, {
      params: {
        page,
      },
    });
  },
  postOrganizationWorkflow(organizationId, payload) {
    return cosmoAPI.post(`/organization/${organizationId}/workflow/`, payload);
  },
  patchOrganizationWorkflow(organizationId, workflowId, payload) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/workflow/${workflowId}/`,
      payload
    );
  },
  deleteOrganizationWorkflow(organizationId, workflowId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status - Api
  postOrganizationWorkflowStatus(organizationId, workflowId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/`,
      payload
    );
  },
  patchOrganizationWorkflowStatus(
    organizationId,
    workflowId,
    statusId,
    payload
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/`,
      payload
    );
  },
  deleteOrganizationWorkflowStatus(organizationId, workflowId, statusId) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Collaborator - Api
  addCollaboratorToStatus(collaborator, organizationId, statusId, workflowId) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/collaborator/`,
      { collaborator }
    );
  },
  fetchAssignedCollaboratorStatus(organizationId, workflowId, statusId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/collaborator/`
    );
  },
  delCollaboratorToStatus(
    organizationId,
    workflowId,
    statusId,
    assignStatusId
  ) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/collaborator/${assignStatusId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Question - Api
  fetchorganizationWorkflowStatusQuestions(
    organizationId,
    workflowId,
    statusId,
    page
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/`,
      {
        params: {
          page,
        },
      }
    );
  },
  postOrganizationWorkflowStatusQuestion(
    organizationId,
    workflowId,
    statusId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postOrganisationWorkflowStatusDatatypeQuestion({
    organizationId,
    workflowId,
    statusId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/datatype/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postOrganisationWorkflowStatusMove({
    organizationId,
    workflowId,
    statusId,
    action,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/${action}/`
    );
  },
  patchOrganizationWorkflowStatusQuestion(
    organizationId,
    workflowId,
    statusId,
    questionId,
    payload
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  deleteOrganizationWorkflowStatusQuestion(
    organizationId,
    workflowId,
    statusId,
    questionId
  ) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Category - Api
  getWorkflowStatusCategories({
    organizationId,
    workflowId,
    statusId,
    page = 1,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/category/`,
      {
        params: {
          page,
        },
      }
    );
  },
  postWorkflowStatusCategory({
    organizationId,
    workflowId,
    statusId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/category/`,
      payload
    );
  },
  patchWorkflowStatusCategory({
    organizationId,
    workflowId,
    statusId,
    categoryId,
    payload,
  }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/category/${categoryId}/`,
      payload
    );
  },
  deleteWorkflowStatusCategory({
    organizationId,
    workflowId,
    statusId,
    categoryId,
  }) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/category/${categoryId}/`
    );
  },
  postWorkflowStatusCategoryOrder({
    organizationId,
    workflowId,
    statusId,
    categoryId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/category/${categoryId}/order/`,
      payload
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Question - Api
  getWorkflowStatusQuestionCategory({
    organizationId,
    workflowId,
    statusId,
    questionId,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Question > Answer - Api
  postOrganizationFolderStatusQuestionAnswer({
    organizationId,
    folderId,
    statusId,
    questionId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/${questionId}/answer/`,
      payload
    );
  },
  patchOrganizationFolderStatusQuestionAnswer({
    organizationId,
    folderId,
    statusId,
    questionId,
    answerId,
    payload,
  }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/${questionId}/answer/${answerId}/`,
      payload
    );
  },
  deleteOrganizationFolderStatusQuestionAnswer(
    organizationId,
    folderId,
    statusId,
    questionId,
    answerId
  ) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/${questionId}/answer/${answerId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Question > Option - Api
  getOrganizationWorkflowStatusQuestionOptions(
    organizationId,
    workflowId,
    statusId,
    questionId
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}/option/`
    );
  },
  postOrganizationWorkflowStatusQuestionOption(
    organizationId,
    workflowId,
    statusId,
    questionId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}/option/`,
      payload
    );
  },
  patchOrganizationWorkflowStatusQuestionOption(
    organizationId,
    workflowId,
    statusId,
    questionId,
    optionId,
    payload
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}/option/${optionId}/`,
      payload
    );
  },
  deleteOrganizationWorkflowStatusQuestionOption(
    organizationId,
    workflowId,
    statusId,
    questionId,
    optionId
  ) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/question/${questionId}/option/${optionId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > targetStatus - Api
  postOrganizationWorkflowStatusTargetStatus(
    organizationId,
    workflowId,
    statusId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/next-status/`,
      payload
    );
  },
  getOrganizationWorkflowStatusTargetStatuses(
    organizationId,
    workflowId,
    statusId
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/next-status/`
    );
  },
  patchOrganizationWorkflowStatusTargetStatus(
    organizationId,
    workflowId,
    statusId,
    targetStatusId,
    payload
  ) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/next-status/${targetStatusId}/`,
      payload
    );
  },
  deleteOrganizationWorkflowStatusTargetStatus(
    organizationId,
    workflowId,
    statusId,
    targetStatusId
  ) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/next-status/${targetStatusId}/`
    );
  },
  //#endregion
  //#region Organization > Workflow > Status > Action - Api
  getOrganizationWorkflowStatusActions({
    organizationId,
    workflowId,
    statusId,
    page,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/action/`,
      {
        params: {
          page,
        },
      }
    );
  },
  postOrganizationWorkflowStatusAction({
    organizationId,
    workflowId,
    statusId,
    actionId,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/action/${actionId}/add/`
    );
  },
  deleteOrganizationWorkflowStatusAction({
    organizationId,
    workflowId,
    statusId,
    actionId,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/workflow/${workflowId}/status/${statusId}/action/${actionId}/remove/`
    );
  },
  postOrganizationFolderCosmoformSubmit({ organizationId, folderId, payload }) {
    if (!payload.message) delete payload.message;
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/cosmoform/`,
      payload
    );
  },
  getOrganizationCosmoformTemplate({ organizationId }) {
    return cosmoAPI.get(`/organization/${organizationId}/template/cosmoform/`);
  },
  getOrganizationCosmoformTemplateDetail({
    organizationId,
    cosmoformTemplateId,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/template/cosmoform/${cosmoformTemplateId}/`
    );
  },
};
