<template>
  <div>
    <BaseFrontOfficeWalletWrapper
      small
      :mobile-title-image="$findStatic('walletAuthLogin')"
    >
      <template #title>
        <BaseFrontOfficeTitle v-text="'500'" />
      </template>

      <BaseFrontOfficeBoard>
        <div class="d-block error_container">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              <BaseFrontOfficeSubtitle
                class="error_message"
                v-text="`${$t('pageNotFound.OopsError')}`"
              />
              <BaseFrontOfficeParagraph
                class="error_sub_message"
                v-text="`${$t('internalServerError.errorMessage')}`"
              />
              <BaseFrontOfficeParagraph
                class="error_sub_message"
                v-text="`${$t('internalServerError.errorSubMessage')}`"
              />
              <a href="mailto:contact@myco.coop"> contact@myco.coop.</a>
            </div>

            <BaseFrontOfficeImage
              :src="$findStatic('cosmoWalletInternalServerError')"
              contain
            />
          </div>

          <div class="d-flex flex-column flex-sm-row justify-center mt-5">
            <BaseFrontOfficeButton
              class="mr-0 mr-sm-3 mb-2 mb-sm-0"
              type="secondary"
              @click.prevent="goToMyCoSite()"
              v-text="$t('account.myCoKnowMore')"
            />
            <BaseFrontOfficeButton
              :to="{ name: 'WalletHome' }"
              v-text="$t('pageNotFound.backToHome')"
            />
          </div>
        </div>
      </BaseFrontOfficeBoard>
    </BaseFrontOfficeWalletWrapper>
  </div>
</template>
<script>
export default {
  name: "InternalServerError",
  methods: {
    goToMyCoSite() {
      open("https://www.myco.coop/");
    },
  },
};
</script>
<style scoped>
.error_message {
  font-size: 24px !important;
  margin-bottom: 4px;
}
.error_container {
  padding: 16px;
}
</style>
