<template>
  <v-data-table :locale="'fr'" v-bind="$attrs" v-on="$listeners">
    <slot />
    <BaseDraggable>
      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </BaseDraggable>
  </v-data-table>
</template>

<script>
export default {
  name: "BaseDraggableDataTable",
};
</script>
