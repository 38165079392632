<template>
  <v-divider :class="'front-office-divider-' + type">
    <slot />
  </v-divider>
</template>

<script>
export default {
  name: "BaseFrontOfficeDivider",
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style scoped>
.front-office-divider-primary {
  border-top: 3px solid var(--v-walletAccent-base);
  border-left: 3px solid var(--v-walletAccent-base);
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    var(--v-walletAccent-base);
}
.front-office-divider-secondary {
  border-top: 2px solid #3c3b8e;
}
.front-office-divider-tertiary {
  border-top: 1px solid var(--v-walletAccent-base);
  border-left: 1px solid var(--v-walletAccent-base);
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    var(--v-walletAccent-base);
}
.front-office-divider-grey {
  border-top: 1px solid var(--v-walletGrey-lighten3);
  border-left: 1px solid var(--v-walletGrey-lighten3);
  background: linear-gradient(
      180deg,
      rgba(252, 192, 15, 0) 11.43%,
      rgba(252, 192, 15, 0.5) 55.71%
    ),
    var(--v-walletAccent-lighten3);
}
</style>
