import FolderList from "../Pages/FolderList.vue";
import FolderDetail from "../Pages/FolderDetail.vue";

export default [
  {
    path: "folder/",
    name: "folderList",
    component: FolderList,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "folderList",
          disabled: true,
          label: "utils.folder",
        },
      ],
    },
  },
  {
    path: "folder/:folderId/",
    name: "folderDetail",
    component: FolderDetail,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "folderList",
          disabled: false,
          label: "utils.folder",
        },
        {
          name: "folderDetail",
          disabled: true,
          label: "utils.specificFolder",
        },
      ],
    },
  },
];
