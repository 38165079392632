<template>
  <label class="switch" for="checkbox">
    <input
      id="checkbox"
      type="checkbox"
      :class="{ checked: modelValue }"
      :value="modelValue"
      @change="
        $emit('changeValue', !modelValue) && $emit('change', !modelValue)
      "
    />
    <div class="slider round" />
  </label>
</template>

<script>
export default {
  name: "BaseFrontOfficeSwitch",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input.checked + .slider {
  background: linear-gradient(
      147.45deg,
      #2f479d 19.48%,
      rgba(47, 71, 157, 0) 144.95%
    ),
    #ff5b60;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input.checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
