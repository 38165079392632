import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getClientOwnfolder(organizationId, folderId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/client/`
    );
  },
  getOrganizationFolders({ organizationId, filters }) {
    const params = {};
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const filterValue = filters[key];
        if (!filterValue || (Array.isArray(filterValue) && !filterValue.length))
          continue;
        if (Array.isArray(filterValue)) {
          params[key] = filterValue.join("|");
        } else if (key == "pageSize") params.page_size = filterValue;
        else params[key] = filterValue;
      }
    }
    return cosmoAPI.get(`/organization/${organizationId}/folder/`, {
      params: params,
    });
  },
  // fetch organization folder without pagination
  getAllOrganizationFolders(organizationId, params = "") {
    return cosmoAPI.get(`/organization/${organizationId}/folder/${params}`);
  },
  getFolderRecords({ organizationId, folderId, page = 1 }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/record/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getFolderCosmoformList({ organizationId, folderId }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/cosmoform/`,
      {
        params: {
          page: 1,
        },
      }
    );
  },
  postRelaunchCosmoform({ organizationId, folderId, cosmoformId, payload }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/cosmoform/${cosmoformId}/remind/`,
      payload
    );
  },
  postFolderDataToDPAE({ organizationId, folderId }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/dpae/`
    );
  },
  getFolderCosmoformDetail({ organizationId, folderId, cosmoformId }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/cosmoform/${cosmoformId}/`
    );
  },
  readFolder(organizationId, folderId) {
    return cosmoAPI.get(`/organization/${organizationId}/folder/${folderId}/`);
  },
  updateFolder(organizationId, folderId, data) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/folder/${folderId}/`,
      data
    );
  },
  postUnarchiveFolder({ organizationId, folderId }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/unarchive/`
    );
  },
  inviteFolder(organizationId, folderId, data) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/invite/`,
      data
    );
  },
  createFolder(organizationId, data) {
    return cosmoAPI.post(`/organization/${organizationId}/folder/`, data);
  },
  setFolderWorkflow(organizationId, folderId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/set_workflow/`,
      payload
    );
  },
  readAllCampaign(organization, status) {
    return cosmoAPI.get(
      "/campaign/" +
        (organization || status ? "?" : "") +
        (organization ? "course__organization=" + organization : "") +
        (organization & status ? "&" : "") +
        (status ? "status=" + status : "")
    );
  },
  getOrganizationCampaigns(organizationId, page) {
    return cosmoAPI.get("/campaign/", {
      params: {
        page,
        status: "IN_PROGRESS",
        course__organization: organizationId,
      },
    });
  },
  getDatatype(datatypeId) {
    return cosmoAPI.get(`/datatype/${datatypeId}`);
  },
  postInvalidateRecord(organizationId, folderId, recordId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/record/${recordId}/invalidate/`,
      payload
    );
  },
  postValidateRecord({ organizationId, folderId, recordId, payload }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/record/${recordId}/validate/`,
      payload
    );
  },
  postRelaunchInvitationRecordFromFolder({
    organizationId,
    folderId,
    recordId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/record/${recordId}/remind/`,
      payload
    );
  },
  dateRecord(organizationId, folderId, recordId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/record/${recordId}/validate/`,
      payload
    );
  },
  getOrganizationCollaborators(organizationId, page = 1) {
    return cosmoAPI.get(`/organization/${organizationId}/collaborator/`, {
      params: {
        page,
      },
    });
  },
  getFolderLogs(organizationId, folderId, page = 1) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/log/`,
      {
        params: {
          page,
        },
      }
    );
  },

  getOrganizationFolderStatusQuestions(
    organizationId,
    folderId,
    statusId,
    page = 1
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/`,
      {
        params: {
          page,
        },
      }
    );
  },
  postOrganizationFolderStatusQuestionAnswer(
    organizationId,
    folderId,
    statusId,
    questionId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/${questionId}/answer/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postStatusQuestion(organizationId, folderId, statusId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postStatusDatatypeQuestion(organizationId, folderId, statusId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/datatype/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postStatusQuestionOption(
    organizationId,
    folderId,
    statusId,
    questionId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/question/${questionId}/option/`,
      payload
    );
  },
  getOrganizationFolderStatusActions({
    organizationId,
    folderId,
    statusId,
    page,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/action/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getOrganizationFolderOffersRecordsCosmoforms({
    organizationId,
    folderId,
    page,
  }) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/ranking/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getDataRecord(organizationId, folderId, recordId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/record/${recordId}/`
    );
  },
  getFolderFiles(organizationId, folderId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/files/`
    );
  },
  postFolderCollaboratorStatus(organizationId, folderId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/set_status/`,
      payload
    );
  },
  postOrganizationFolderStatusQuestionAnswerGed(
    organizationId,
    folderId,
    workflowStatusId,
    questionId,
    answerId
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${workflowStatusId}/question/${questionId}/answer/${answerId}/to_ged/`
    );
  },
  //#endregion
  postFolderExport({ organizationId, folderId, payload }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/export/`,
      payload
    );
  },

  getClientFullData(organizationId, folderId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/client_full_data/`
    );
  },

  sendMail(data) {
    const { organizationId, folderId, statusId, actionId, payload } = data;
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/action/${actionId}/email/submit/`,
      payload
    );
  },

  postEmailRemainder(data) {
    const { organizationId, folderId, statusId, actionId, payload } = data;
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/status/${statusId}/action/${actionId}/email/remind/`,
      payload
    );
  },
};
