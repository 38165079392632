import Datatype from "./Datatype";
export default class Universe {
  constructor({
    id = Number.NaN,
    name = "",
    slug = "",
    datatypeList = [],
    dateCreated = new Date(),
    dateUpdated = new Date(),
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.slug = slug;
    this.datatypeList = datatypeList
      ? datatypeList.map((datatype) => new Datatype(datatype))
      : [];
    this.dateCreated = new Date(dateCreated);
    this.dateUpdated = new Date(dateUpdated);
  }
}
