<template>
  <BaseFrontOfficeDialog
    max-width="850"
    :value="dialogState"
    @click:outside="closeModal()"
    @keydown.esc="closeModal()"
  >
    <template #modal>
      <div v-if="tmpQuestion" class="modal_content">
        <div class="d-flex justify-end">
          <BaseFrontOfficeButtonIcon icon="$mdiClose" @click="closeModal()" />
        </div>
        <div>
          <WalletCourseChatBot hide-help-text :question="tmpQuestion" />
          <RecordQuestionForm
            v-model="tmpQuestion.answer"
            :question="tmpQuestion"
            focus
          />
        </div>
        <div class="actions">
          <BaseButton
            :loading="editingQuestion"
            :disabled="!isValidForm"
            @click="editQuestion()"
          >
            {{ $t("utils.save") }}
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseFrontOfficeDialog>
</template>

<script>
import WalletCourseChatBot from "./WalletCourseChatBot";
import RecordQuestionForm from "./RecordQuestionForm";

import RecordAPI from "../Services/record.api";
import Question from "@/core/Models/Question";

export default {
  name: "WalletCourseEditQuestionDialog",
  components: { RecordQuestionForm, WalletCourseChatBot },
  props: {
    dialogState: {
      type: Boolean,
    },
    recordId: {
      type: [String, Number],
      required: true,
    },
    question: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tmpQuestion: null,
      editingQuestion: false,
    };
  },
  computed: {
    isValidForm() {
      if (!this.tmpQuestion) return false;
      if (this.tmpQuestion.required) {
        if (
          !this.tmpQuestion.answer ||
          (!this.tmpQuestion.answer.content &&
            !this.tmpQuestion.answer.choices?.length)
        )
          return false;
      }
      return true;
    },
  },
  watch: {
    dialogState(val) {
      this.tmpQuestion = val ? new Question({ ...this.question }) : null;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getPayload(payload, question) {
      if (!payload) return [];
      if (question.typeQuestion === "MULTIPLE_FILE") {
        return payload.content ? payload.content : [];
      }
      if (["FILE", "SIGNATURE"].includes(question.typeQuestion)) {
        return payload.content
          ? [
              {
                encoded_file: payload.content,
                name: payload.fileName,
              },
            ]
          : [];
      }
      if (payload.choices) {
        return payload.choices;
      }
      if (payload.content) {
        return [payload.content];
      }

      if (payload.freeOptionContent) {
        return [`free__${payload.freeOptionContent}`];
      }

      return [];
    },
    async editQuestion() {
      this.editingQuestion = true;
      let setContentValue = [];
      if (this.tmpQuestion.typeQuestion === "MULTIPLE_FILE") {
        setContentValue = this.tmpQuestion.answer.content
          ? this.tmpQuestion.answer.content
          : [];
      } else if (
        ["FILE", "SIGNATURE"].includes(this.tmpQuestion.typeQuestion)
      ) {
        setContentValue = this.tmpQuestion.answer.content
          ? [
              {
                name:
                  this.tmpQuestion.answer.content?.name ||
                  this.tmpQuestion.answer?.fileName ||
                  "Fichier joint",
                encoded_file: this.tmpQuestion.answer.content,
              },
            ]
          : [];
      } else if (
        this.tmpQuestion.answer &&
        !this.tmpQuestion.answer.freeOptionContent
      ) {
        setContentValue = this.tmpQuestion.answer.choices
          ? this.tmpQuestion.answer.choices
          : [this.tmpQuestion.answer.content];
      } else if (
        this.tmpQuestion.answer.choices &&
        this.tmpQuestion.answer.freeOptionContent
      ) {
        setContentValue = this.tmpQuestion.answer.choices;
      } else
        setContentValue = [
          `free__${this.tmpQuestion.answer.freeOptionContent}`,
        ];
      const params = {
        recordId: this.recordId,
        questionId: this.tmpQuestion.id,
        payload: {
          set_content: setContentValue,
          skipped: false,
        },
      };
      if (this.$route.query.sc) {
        params["settings"] = { params: { sc: this.$route.query.sc } };
      }
      this.tmpQuestion.answer = await this.submitQuestion(params);
      this.editingQuestion = false;
      this.$emit("questionEdited", this.tmpQuestion);
    },
    async submitQuestion(params) {
      try {
        return (await RecordAPI.postRecordQuestionAnswer(params)).data;
      } catch (error) {
        if (
          error?.response?.data?.code == "upload-error" &&
          error?.response?.data?.details?.file_with_error
        ) {
          const name = error?.response?.data?.details?.file_with_error;
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.uploadFileError", { name }),
            type: "ERROR",
          });
        } else {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.tryLater"),
            type: "ERROR",
          });
        }
      }
      return {
        id: NaN,
        content: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_content {
  padding: 24px;
  background: #fff;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
