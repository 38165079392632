<template>
  <div>
    <div v-if="activeClient.personalData.length > 0">
      <v-tabs v-model="activeTab" class="tab_header">
        <v-tab class="tab_header_item">
          {{ $t("utils.all") }}
        </v-tab>
        <v-tab
          v-for="(univers, i) in personalDataPerUniverses"
          :key="`sh-${i}`"
          class="tab_header_item"
        >
          {{ univers.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <!-- ALL QUESTIONS -->
        <v-tab-item>
          <div class="tab_item">
            <div
              v-for="(univers, i) in personalDataPerUniverses"
              :key="`as-${i}`"
              class="step_questions"
            >
              <div class="step_label" v-text="univers.name" />
              <div class="step_questions_grid">
                <personal-data-previewer
                  v-for="(personalData, qi) in univers.personalData"
                  :key="`q-${qi}`"
                  :personal-data="personalData"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <!-- QUESTIONS BY STEP -->
        <v-tab-item
          v-for="(univers, i) in personalDataPerUniverses"
          :key="`sti-${i}`"
        >
          <div class="tab_item">
            <div class="step_questions">
              <div class="step_questions_grid">
                <personal-data-previewer
                  v-for="(personalData, qi) in univers.personalData"
                  :key="`q-${qi}`"
                  :personal-data="personalData"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div v-else class="d-flex justify-center">
      <BaseParagraph text="secondary" v-text="'Aucune donnée personnelle'" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PersonalDataPreviewer from "./PersonalDataPreviewer.vue";

export default {
  name: "ClientPersonalDataTable",
  components: { PersonalDataPreviewer },
  data() {
    return {
      activeTab: 1,
    };
  },
  computed: {
    ...mapState({
      activeClient: (state) => state.client.activeClient,
    }),
    personalDataPerUniverses() {
      if (!this.activeClient.personalData?.length) return [];

      const allUniverses = this.activeClient.personalData
        .filter((p) => p.datatype.universes.length)
        .map((personal) => personal.datatype.universes[0].id);
      const filteredUnivers = [...new Set(allUniverses)];

      const grouped = filteredUnivers.map((univers) => ({
        id: univers,
        name: this.getUniversesName(
          this.activeClient.personalData.find(
            (personal) => personal.datatype.universes[0]?.id === univers
          )
        ),
        personalData: this.activeClient.personalData.filter(
          (personal) => personal.datatype.universes[0]?.id === univers
        ),
      }));

      const otherUnivers = {
        id: null,
        name: this.$t("otherUnivers.Autre"),
        personalData: this.activeClient.personalData.filter(
          (personal) => !personal.datatype.universes?.length
        ),
      };
      if (otherUnivers.personalData?.length) grouped.push(otherUnivers);

      return grouped;
    },
  },
  methods: {
    getUniversesName(item) {
      return item.datatype?.universes[0]?.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.tab_header {
  margin-bottom: 15px;
  .tab_header_item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}
.tab_item {
  padding: 5px;
  .step_questions {
    width: 100%;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 8px;
    }
    .step_label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 16px;
      color: #353542;
    }
    .step_questions_grid {
      display: grid;
      padding-right: 8px;
      padding-bottom: 4px;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: 100%;
      max-height: 248px;
      overflow-y: auto;
      overflow-x: hidden;
      @media (min-width: 1200px) {
        grid-template-columns: repeat(2, calc(calc(100% - 16px) / 2));
      }
      @media (min-width: 1400px) {
        grid-template-columns: repeat(3, calc(calc(100% - 32px) / 3));
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
        border: 1px solid #eeeef7;
      }

      &::-webkit-scrollbar-thumb {
        background: #b6bdff;
        border-radius: 3px;
      }
    }
  }
}
</style>
