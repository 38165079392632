import { render, staticRenderFns } from "./FolderEditWorkflowDialog.vue?vue&type=template&id=c70ed60a&scoped=true"
import script from "./FolderEditWorkflowDialog.vue?vue&type=script&lang=js"
export * from "./FolderEditWorkflowDialog.vue?vue&type=script&lang=js"
import style0 from "./FolderEditWorkflowDialog.vue?vue&type=style&index=0&id=c70ed60a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c70ed60a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
