<template>
  <BaseModal
    :value="isOpen"
    :modal-title="$t('course.forms.groupDelete.title')"
    @close="$emit('close')"
  >
    <template #modal>
      <div class="text-center">
        <BaseParagraph v-text="$t('course.forms.groupDelete.message')" />
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton color="secondary" type="primary" @click="submit">
        {{ $t("utils.delete") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "CourseQuestionGroupDeleteDialog",
  props: {
    isOpen: {
      type: Boolean,
    },
    group: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async submit() {
      if (this.group) {
        let stepId = this.$store.state.course.activeStep.id;
        await this.$store.dispatch("course/deleteGroup", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId,
          groupId: this.group.id,
        });
        for (const question of this.group.questions) {
          this.$store
            .dispatch("course/patchQuestion", {
              organizationId: this.$route.params.organizationId,
              courseId: this.$route.params.courseId,
              stepId,
              questionId: question.id,
              question: {
                group: null,
              },
            })
            .then(() => {
              this.$store.dispatch("course/fetchStep", {
                organizationId: this.$route.params.organizationId,
                courseId: this.$route.params.courseId,
                stepId,
              });
            });
        }
      }
      this.$emit("close");
    },
  },
};
</script>
