<template>
  <v-btn :dark="dark" icon v-bind="$attrs" v-on="$listeners">
    <v-icon :dark="dark">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButtonIcon",
  props: {
    icon: {
      type: String,
      default: () => "",
    },
    dark: {
      type: Boolean,
    },
  },
};
</script>
