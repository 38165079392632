<template>
  <section class="mb-6">
    <div class="bo_title mb-6" v-text="$tc('organization.course', 10)" />
    <Board class="mb-3">
      <template #header class="mb-6">
        <div class="d-flex justify-space-between align-center">
          <BaseButton icon="$mdiPlus" @click="isCreateCourseDialogOpen = true">
            {{ $t("course.create") }}
          </BaseButton>
          <div>
            <v-text-field
              v-model="search"
              class="ma-0 pa-0"
              outlined
              :label="$t('course.search')"
              prepend-inner-icon="$mdiMagnify"
              dense
              hide-details
              must-sort
            />
          </div>
        </div>
      </template>
      <template #main>
        <v-tabs v-model="tab" light @change="changeTab">
          <v-tab background-color="primary">
            <BaseIcon icon="$mdiCheck" small class="pr-2" />
            {{ $t("course.active") }}
            <span class="lowercase pl-2">({{ activeCoursesAmount }})</span>
          </v-tab>
          <v-tab>
            <BaseIcon icon="$mdiArchive" small class="pr-2" />
            {{ $t("course.archives") }}
            <span class="lowercase pl-2"> ({{ archivedCoursesAmount }}) </span>
          </v-tab>
        </v-tabs>
        <BaseDataTable
          :items="displayedCourses"
          :headers="COURSES_LIST_HEADER"
          :loading="
            (displayActiveCourses && activeCoursesLoading) ||
            (!displayActiveCourses && isArchivedCoursesLoading)
          "
          :loading-text="$t('utils.loadingData')"
          class="elevation-1 mb-6"
          sort-by="dateCreated"
          sort-desc
          must-sort
          :no-data-text="$tc('organization.course', 0)"
        >
          <template #item="{ item }">
            <tr
              class="row--link"
              @click.stop="
                $router.push({
                  name: 'courseDetail',
                  params: {
                    organizationId: organizationId,
                    courseId: item.id,
                  },
                })
              "
            >
              <td>
                <span class="text" v-text="item.name" />
              </td>
              <td class="text-center">
                <BaseIcon
                  v-if="item.anonymous"
                  icon="$mdiCheckboxMarked"
                  color="primary"
                />
                <BaseIcon v-else icon="$mdiCloseBox" color="grey lighten-2" />
              </td>
              <td class="text-center">
                <span class="text" v-text="item.campaignsCount" />
              </td>
              <td class="text-center">
                <span class="text" v-text="item.activeRecordsCount" />
              </td>
              <td v-if="item.campaignsCount" class="text-center">
                <BaseButtonIcon
                  icon="$mdiArchive"
                  color="primary"
                  x-small
                  @click.stop="
                    () => {
                      $store.commit('course/SET_COURSE', item);
                      isCourseToggleArchivedDialogOpen = true;
                    }
                  "
                />
              </td>
              <td v-else class="text-center">
                <BaseButtonIcon
                  icon="$mdiTrashCanOutline"
                  color="secondary"
                  x-small
                  @click.stop="
                    () => {
                      $store.commit('course/SET_COURSE', item);
                      isDeleteCourseDialogOpen = true;
                    }
                  "
                />
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </template>
    </Board>
    <CourseDeleteDialog
      :is-open="isDeleteCourseDialogOpen"
      @deleted="onDelete"
      @close="isDeleteCourseDialogOpen = false"
    />
    <CourseCreateAndEditDialog
      :is-open="isCreateCourseDialogOpen"
      @close="isCreateCourseDialogOpen = false"
    />
    <CourseToggleArchiveDialog
      :is-open="isCourseToggleArchivedDialogOpen"
      @close="
        () => {
          $store.commit('course/SET_COURSE', null);
          isCourseToggleArchivedDialogOpen = false;
        }
      "
    />
  </section>
</template>

<script>
import Board from "@/core/Components/Board.vue";
import CourseDeleteDialog from "../Components/CourseDeleteDialog";
import CourseCreateAndEditDialog from "../Components/CourseCreateAndEditDialog";
import CourseToggleArchiveDialog from "../Components/CourseToggleArchiveDialog";

import { mapState, mapGetters } from "vuex";

export default {
  name: "EditorList",
  components: {
    Board,
    CourseDeleteDialog,
    CourseCreateAndEditDialog,
    CourseToggleArchiveDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isDeleteCourseDialogOpen: false,
      isCreateCourseDialogOpen: false,
      isCourseToggleArchivedDialogOpen: false,
      search: "",
      COURSES_LIST_HEADER: [
        { text: this.$tc("organization.course", 10), value: "name" },
        {
          text: this.$t("course.anonymous"),
          value: "anonymous",
          align: "center",
        },
        {
          text: this.$tc("organization.campaign", 10),
          value: "campaignsCount",
          align: "center",
        },
        {
          text: this.$tc("organization.record", 10),
          value: "activeRecordsCount",
          align: "center",
        },
        {
          text: this.$tc("utils.action", 10),
          align: "center",
          sortable: false,
          filterable: false,
        },
      ],
      tab: null,
    };
  },
  computed: {
    ...mapState({
      course: (state) => state.course.course,
      activeCourses: (state) => state.course.activeCourses,
      archivedCourses: (state) => state.course.archivedCourses,
      displayActiveCourses: (state) => state.course.displayActiveCourses,
      activeCoursesAmount: (state) => state.course.activeCoursesAmount,
      archivedCoursesAmount: (state) => state.course.archivedCoursesAmount,
    }),
    ...mapGetters({
      activeCoursesLoading: "course/isActiveCoursesLoading",
      isArchivedCoursesLoading: "course/isArchivedCoursesLoading",
    }),
    displayedCourses() {
      let listToDisplay = this.displayActiveCourses
        ? this.activeCourses
        : this.archivedCourses;
      if (!this.search) {
        return listToDisplay;
      }
      return listToDisplay.filter((course) =>
        course.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    organizationId() {
      this.reset();
      this.initialize();
    },
    displayActiveCourses(newVal) {
      this.tab = newVal ? 0 : 1;
    },
  },
  created() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.$store.dispatch("course/resetActiveCourses");
      this.$store.dispatch("course/resetArchivedCourses");
      this.$store.dispatch("course/resetCourse");
    },
    initialize() {
      /* Fetch all courses */
      this.$store.dispatch("course/fetchCourses", {
        organizationId: this.organizationId,
        archived: false,
      });
      this.$store.dispatch(
        "course/fetchArchivedCoursesAmount",
        this.organizationId
      );
    },
    changeTab(event) {
      if (event === 0) {
        this.$store.commit("course/setDisplayActiveCourses", true);
        this.$store.dispatch("course/fetchCourses", {
          organizationId: this.organizationId,
          archived: false,
        });
      } else {
        this.$store.commit("course/setDisplayActiveCourses", false);
        this.$store.dispatch("course/fetchCourses", {
          organizationId: this.organizationId,
          archived: true,
        });
      }
    },
    onDelete() {
      if (this.displayActiveCourses) {
        const filteredCourses = this.activeCourses.filter(
          (c) => c.id !== this.course.id
        );
        this.$store.commit("course/SET_ACTIVE_COURSES", filteredCourses);
      } else {
        const filteredCourses = this.archivedCourses.filter(
          (c) => c.id !== this.course.id
        );
        this.$store.commit("course/SET_ARCHIVED_COURSES", filteredCourses);
      }
      this.$store.commit("course/SET_COURSE", null);
      this.isDeleteCourseDialogOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  cursor: pointer;
}
</style>
