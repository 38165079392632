<template>
  <svg class="c_icon" viewBox="0 0 12 14" fill="none">
    <path
      d="M3.36913 3.36915L3.36913 5.31706L7.91663 5.31706L7.91663 7.91665L3.36913 7.91665L3.36913 9.86456L0.124959 6.61331L3.36913 3.36915Z"
      fill="white"
    />
    <path
      d="M4.67247 13.1088C4.32539 13.1088 3.99956 12.9742 3.75164 12.7263C3.51081 12.4854 3.36914 12.1525 3.36914 11.8054V10.5092H4.66539V11.8054L10.5162 11.8054L10.5162 1.42125L4.67247 1.42125V2.7175H3.37622L3.37622 1.42125C3.37622 1.07417 3.51081 0.748333 3.75872 0.500417C3.99247 0.259583 4.32539 0.125 4.67247 0.125L10.5162 0.125C10.8633 0.125 11.1891 0.259583 11.4371 0.5075C11.6779 0.748333 11.8196 1.08125 11.8196 1.42833L11.8196 11.8196C11.8196 12.1667 11.685 12.4925 11.4371 12.7404C11.1962 12.9813 10.8633 13.1229 10.5162 13.1229L4.67247 13.1229V13.1088Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "OfferIcon",
};
</script>
