import { render, staticRenderFns } from "./BaseStepperStep.vue?vue&type=template&id=05ff5b9d&scoped=true&v-slot=scope"
import script from "./BaseStepperStep.vue?vue&type=script&lang=js"
export * from "./BaseStepperStep.vue?vue&type=script&lang=js"
import style0 from "./BaseStepperStep.vue?vue&type=style&index=0&id=05ff5b9d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ff5b9d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
