<template>
  <div
    class="text-subtitle"
    :class="{
      'text-uppercase': uppercase,
      'text--title-white': white,
      'text--title-error': error,
      'text--color--primary': color === 'primary',
      'text--color--secondary': color === 'secondary',
    }"
  />
</template>

<script>
export default {
  name: "BaseFrontOfficeSubtitle",
  props: {
    uppercase: {
      type: Boolean,
    },
    white: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.text-subtitle {
  color: var(--v-walletGrey-base);
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
  font-family: var(--cosmo-font-nunito);
}
.text--title-white {
  color: var(--v-walletGrey-lighten5);
}
.text--title-error {
  color: var(--v-walletAlertError-darken5) !important;
}
.text--color--primary {
  color: var(--v-primary-base);
}
.text--color--secondary {
  color: #9c0000;
}
</style>
