import CourseList from "../Pages/EditorList.vue";
import CourseDetail from "../Pages/EditorDetail.vue";
import CampaignDetail from "../Pages/CampaignDetail.vue";

export default [
  {
    path: "course/",
    name: "courseList",
    component: CourseList,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "courseList",
          disabled: true,
          label: "utils.course",
        },
      ],
    },
  },
  {
    path: "course/:courseId/",
    name: "courseDetail",
    component: CourseDetail,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "courseList",
          disabled: false,
          label: "utils.course",
        },
        {
          name: "courseDetail",
          disabled: true,
          label: "utils.specificCourse",
        },
      ],
    },
  },
  {
    path: "course/:courseId/campaign/:campaignId/",
    name: "campaignDetail",
    component: CampaignDetail,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "courseList",
          disabled: false,
          label: "utils.course",
        },
        {
          name: "courseDetail",
          disabled: false,
          label: "utils.specificCourse",
        },
        {
          name: "campaignDetail",
          disabled: true,
          label: "utils.specificCompaign",
        },
      ],
    },
  },
];
