<template>
  <v-dialog v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, attrs }">
      <div>
        <slot name="toggle" v-bind="attrs" v-on="on" />
      </div>
    </template>

    <slot name="modal" />
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
};
</script>
<style scoped>
::v-deep hr[role="separator"] {
  margin-inline: 24px !important;
}
</style>
