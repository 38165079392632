<template>
  <BaseModal
    v-if="record && question"
    :value="dialogState"
    :modal-title="getName"
    :need-action="askModificationClicked"
    @close="reset()"
  >
    <template #modal>
      <form>
        <template v-if="question.answer && question.answer.extractedData">
          <FolderQuestionWizideeData
            :wizidee-data="question.answer && question.answer.extractedData"
            :question="question"
          />
        </template>
        <BaseRow v-else-if="question.answer">
          <BaseCol>
            <QuestionDatagridPreviwer
              v-if="
                /^DATAGRID|ROW_DATAGRID$/.test(question.typeQuestion) &&
                question.answer.content
              "
              :question="question"
              class="mb-1"
            />
            <RecordQuestionValidation
              v-else
              :question="question"
              :ask-modification-clicked="askModificationClicked"
            />
          </BaseCol>
        </BaseRow>
        <div
          v-if="
            question.validation == 'PENDING' || question.validation == 'UPDATED'
          "
          class="d-flex justify-end mt-4"
        >
          <BaseButton
            v-if="
              !(record.course && record.course.anonymous) &&
              !(askModificationClicked || isArchivedFolder)
            "
            class="mx-2"
            type="primary"
            icon="$mdiPlaylistEdit"
            @click="askModificationClicked = true"
          >
            {{ $t("folder.validationForm.askModification") }}
          </BaseButton>
          <BaseButton
            v-if="
              !askModificationClicked &&
              question.validation !== 'VALIDATED' &&
              !recordAnonymous
            "
            :disabled="isArchivedFolder"
            :loading="isSubmiting"
            class="mx-2"
            icon="$mdiCheck"
            type="primary"
            @click="validate()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
        <div v-if="askModificationClicked" class="email-container">
          <div class="title-container">
            <span
              class="text sub-text"
              v-text="`${$tc('utils.recipient', 3)} : `"
            />
            <span class="text" v-text="record.client.email" />
          </div>

          <BaseMarkdownEditor
            v-model="formData.content"
            :placeholder="'Corps du mail'"
            :with-link="false"
            @change="$v.formData.content.$touch()"
            @blur="$v.formData.content.$touch()"
          />
        </div>
      </form>
    </template>

    <template v-if="askModificationClicked && !recordAnonymous" #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :disabled="$v.formData.$invalid || isArchivedFolder"
        :loading="isSubmiting"
        type="primary"
        @click="invalidate()"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import RecordQuestionValidation from "../Components/RecordQuestionValidation";
import QuestionDatagridPreviwer from "@/core/Components/QuestionDatagridPreviwer";
import FolderQuestionWizideeData from "./FolderQuestionWizideeData.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";

export default {
  name: "FolderQuestionValidationDialog",
  components: {
    RecordQuestionValidation,
    QuestionDatagridPreviwer,
    FolderQuestionWizideeData,
  },
  mixins: [validationMixin],
  data() {
    return {
      formData: {
        content: "",
      },
      askModificationClicked: false,
      isSubmiting: false,
    };
  },

  computed: {
    ...mapState({
      record: (state) => state.folder.activeRecord,
      question: (state) => state.folder.activeQuestion,
      dialogState: (state) => state.folder.modals.isValidationDialogOpen,
    }),
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    getName() {
      return this.askModificationClicked
        ? `Demande de modification - ${this.question?.name}`
        : this.question?.name;
    },
    recordAnonymous() {
      return this.record.course.anonymous;
    },
    contentErrors() {
      if (!this.$v.formData.content.$dirty) {
        return "";
      }
      if (!this.$v.formData.content.required) {
        return this.$t(
          "folder.invalidateMultipleForm.validation.contentRequired"
        );
      }
      return "";
    },
  },
  validations: {
    formData: {
      content: {
        required,
      },
    },
  },
  methods: {
    reset() {
      this.$store.dispatch("folder/setActiveRecord", null);
      this.$v.formData.$reset();
      this.isSubmiting = false;
      this.askModificationClicked = false;
      this.$store.dispatch("folder/setQuestionValidationModal", false);
    },
    async invalidate() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$invalid) {
        this.isSubmiting = true;
        try {
          await this.$store.dispatch("folder/invalidateRecordAnswers", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            recordId: this.record.id,
            questions: [this.question],
            payload: {
              answers: [this.question.answer.id],
              message: this.formData.content,
            },
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.invalidateMultipleForm.success"),
            type: "SUCCESS",
          });
        } catch {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.invalidateMultipleForm.error"),
            type: "ERROR",
          });
        }
        this.reset();
      }
    },
    async validate() {
      this.isSubmiting = true;
      try {
        if (this.question.validation === "PENDING") {
          await this.$store.dispatch("folder/validateRecordAnswers", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            recordId: this.record.id,
            questions: [this.question],
          });
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.validationForm.success"),
            type: "SUCCESS",
          });
        }
      } catch (e) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.validationForm.error"),
          type: "ERROR",
        });
      }
      this.reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-form {
  margin-top: -10px;
}
.normal-row {
  display: flex !important;
}
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 0 0 0;
}
.modal_content {
  & > * {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
.modal_actions {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  & > * {
    margin-left: 16px;
  }
}
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
.sub-text {
  font-weight: 600;
}
.title-container {
  margin-bottom: 16px;
}
.email-container {
  margin-top: -16px;
}
</style>
